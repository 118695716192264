<div class="alarm_screen alarm_screen_box">
  <div class="alarm_container">
    <div class="alarm_header">
      <h4 class="alarm_header_text">User</h4>
    </div>
    <div
      class="alarm_body alarm_padding"
      [ngStyle]="{ display: !viewPolicy ? 'block' : 'none' }"
    >
      <div class="form-group mb-2">
        <label for="exampleInputEmail1">Name</label>
        <input
          autocomplete="off"
          type="text"
          class="form-control"
          placeholder="Enter User Name"
          [(ngModel)]="userDetails.userName"
          *ngIf="action == 'add'"
          name="username"
          [ngClass]="{ 'error-input': errors['userName']['error'] }"
        />
        <input
          autocomplete="off"
          type="text"
          class="form-control"
          placeholder="Enter User Name"
          [ngModel]="userDetails.userName"
          *ngIf="action == 'update'"
          disabled
          name="username"
          [ngClass]="{ 'error-input': errors['userName']['error'] }"
        />
        <span class="error-msg" *ngIf="errors['userName']['error']">
          {{ errors["userName"]["msg"] }}
        </span>
      </div>
      <div class="form-group mb-2">
        <label for="exampleInputEmail1">Company Name</label>
        <input
          autocomplete="off"
          type="text"
          class="form-control"
          *ngIf="action == 'add'"
          [(ngModel)]="userDetails.company"
          placeholder="Enter Company Name"
          name="company"
        />
        <input
          autocomplete="off"
          type="text"
          class="form-control"
          *ngIf="action == 'update'"
          disabled
          [ngModel]="userDetails.company"
          name="company"
        />
      </div>
      <div class="form-group mb-2">
        <label for="exampleInputEmail1">Email ID</label>
        <input
          autocomplete="off"
          type="text"
          class="form-control"
          placeholder="Enter Email ID"
          *ngIf="action == 'add'"
          [(ngModel)]="userDetails.email"
          name="emailid"
          [ngClass]="{ 'error-input': errors['email']['error'] }"
        />
        <input
          autocomplete="off"
          type="text"
          class="form-control"
          disabled
          *ngIf="action == 'update'"
          [ngClass]="{ 'error-input': errors['email']['error'] }"
          [ngModel]="userDetails.email"
          name="emailid"
        />

        <span class="error-msg" *ngIf="errors['email']['error']">
          {{ errors["email"]["msg"] }}</span
        >
      </div>
      <div class="form-group mb-2">
        <label for="exampleInputEmail1">Phone Number</label>
        <input
          autocomplete="off"
          class="form-control"
          id="card-email"
          placeholder="Enter Phone Number"
          (keydown)="phoneNumberValidate($event)"
          name="mobile"
          type="number"
          required
          minlength="4"
          maxlength="50"
          [(ngModel)]="userDetails.mobile"
          [ngClass]="{ 'error-input': errors['mobile']['error'] }"
        />
        <span class="error-msg" *ngIf="errors['mobile']['error']">
          {{ errors["mobile"]["msg"] }}</span
        >
      </div>
      <!-- <div class="form-group mb-2" [ngStyle]="{display: urlPrefix == 'admin'? 'block' : 'none'}">
          <label for="exampleInputEmail1">Account Group</label>
          <select id="accountGroupLists" class="form-control" multiple data-live-search="true">
            <option *ngFor="let account of accountGroups" value="{{account.groupName}}">{{account.groupName}}</option>
          </select>se
        </div> -->
        <!-- <div class="form-group mb-2"
          >
          <label for="exampleInputEmail1">Role Type</label>
          <div class="flex-start">
            <input autocomplete="off" type="radio" [(ngModel)]="policyType" value="manual"
              id="manualPolicy" name="policyType">
            <label for="manualPolicy">Manual Role</label>
          </div>
          <div class="flex-start">
            <input autocomplete="off" type="radio" [(ngModel)]="policyType" value="predefined" id="predefinedPolity"
              name="policyType">
            <label for="predefinedPolity">Policy Group</label>
          </div>
        </div> -->
        <div class="mb-2"
          [ngStyle]="{'display': policyType == 'predefined' ? 'block' : 'none'}">
          <label for="exampleInputEmail1">Role Type</label>
          <div >
            <select id="policyList" [ngStyle]="{display: polices.length > 0 ? 'block': 'none'}" class="form-control"
              [(ngModel)]="userDetails.policyName" data-live-search="true">
              <option *ngFor="let policy of polices" value="{{policy['policyName']}}">{{policy['policyName']}}</option>
            </select>
            <span class="error-msg" *ngIf="errors['policy']['error']">
              {{errors['policy']['msg']}}</span>
            <div *ngIf="userDetails.policyName">
              <span class="nav-link" (click)="selectPolicy()">View Role ({{ userDetails.policyName }})</span>
            </div>
            <div class="mb-2" [ngStyle]="{display: polices.length == 0 ? 'block': 'none'}">
              No Policies Created <a routerLink="/dash/settings/policies">Create Role</a>
            </div>
          </div>
        </div>
      <!-- <div class="mb-2" *ngIf="services.length > 0 && policyType == 'manual'">
        <label for="exampleInputEmail1">Select Service List</label>
        <div class="pt-2">
          <menu-selector
            [edit]="true"
            [menus]="services"
            [selected]="userDetails['mList']"
          ></menu-selector>
        </div> -->
        <!-- <div class="tags-container mb-2 form-group"
          [ngStyle]="{display: urlPrefix == 'client' && userType == 'user' && policyType == 'manual' ? 'block' : 'none'}">
          <div class="container-header flex-between">
            <label for="exampleInputEmail1">Tags</label>
            <div class="flex-start" (click)="userDetails.tagsEnabled = userDetails.tagsEnabled ? false : true">
              <input autocomplete="off" type="checkbox" class="pointer-none-class" name="" id=""
                [attr.checked]="!userDetails.tagsEnabled ? true : null">
              <span>
                All Tags
              </span>
            </div>
          </div>
          <div class="container-body" [ngStyle]="{display: userDetails.tagsEnabled ? 'block' : 'none'}">
            <div class="account-conntainers" *ngFor="let account of userDetails.accountsList">
              <div class="account-header">
                {{account.accountId}}
              </div>
              <div class="account-body">
                <ng-container *ngIf="getObjectKeys(account['accountId']).length > 0">
                  <div class="account-conntainers"
                    *ngFor="let region of getObjectKeys(tagKeyValuesObj[account['accountId']])">
                    <div class="account-header flex-between">
                      <div>
                        {{region}}
                      </div>
                      <div class="pointer" (click)="removeTagRegion(tagKeyValuesObj[account['accountId']], region)">
                        <i class="fa fa-times"></i>
                      </div>
                    </div>
                    <div class="account-body" *ngIf="getObjectKeys(tagsState[account['accountId']][region]).length > 0">
                      <ng-container *ngFor="let key of getObjectKeys(tagKeyValuesObj[account['accountId']][region])">
                        <div class="flex-start">
                          <select class="single-select" data-live-search="true"
                            (change)="newKeySet(key, $event, account['accountId'], region)">
                            <option value="{{ key }}" selected>{{key}}</option>
                            <option *ngFor="let key_ of keysToSelect(account['accountId'], region)" [value]="key_">{{
                              key_
                              }}</option>
                          </select>
                          <div>
                            <ng-select [items]="tagsState[account['accountId']][region][key]" [addTag]="true"
                              [virtualScroll]="true" bindLabel="id" bindValue="id" [multiple]="true"
                              placeholder="Select Values"
                              [(ngModel)]="tagKeyValuesObj[account['accountId']][region][key]">
                            </ng-select>
                          </div>
                          <div class="flex-start">
                            <input autocomplete="off" type="checkbox" name="" id=""
                              (click)="selectAllValues($event, account['accountId'], region, key)">
                            <label for="" class="nowrap">Select All</label>
                          </div>
                          <button class="btn btn-light" (click)="removeKeySet(account['accountId'], region, key)">
                            <i class="fa fa-minus"></i>
                          </button>
                          <img src="../assets/img/loading_2.svg" alt="loading scans"
                            [ngStyle]="{display: loadingTags ? 'block' : 'none'}" class="loading_tags" />
                        </div>
                      </ng-container>
                      <div class="center" *ngIf="keysToSelect(account['accountId'], region).length > 0">
                        <button class=" btn btn-light" (click)="newKeySet(null, 'FETCH', account['accountId'], region)">
                          <i class="fa fa-plus"></i> Select Tag
                        </button>
                      </div>
                    </div>
                    <div class="account-body center"
                      *ngIf="getObjectKeys(tagsState[account['accountId']][region]).length == 0 && !loadingTags">
                      No Tags Found
                    </div>
                    <div class="account-body center"
                      *ngIf="getObjectKeys(tagsState[account['accountId']][region]).length == 0 && loadingTags">
                      Looking for Tags...
                    </div>
                  </div>
                </ng-container>
                <ng-container>
                  <div class="flex-start">
                    <select class="form-select"
                      (change)="addTagRegion(tagsState[account['accountId']], account['accountId'], $event)">
                      <option value="-">Select Region</option>
                      <option [value]="region.id" *ngFor="let region of getUnselectedRegion(account['accountId'])">{{
                        region.name }}</option>
                    </select>
                    <img src="../assets/img/loading_2.svg" alt="loading scans"
                      [ngStyle]="{display: loadingTags ? 'block' : 'none'}" class="tags_width" />
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div> -->
      <!-- </div> -->
      <div
        class="form-group mb-2"
        [ngStyle]="{ display: type == 'clients' ? 'block' : 'none' }"
      >
        <label for="exampleInputEmail1">Two Factor Authentication</label>
        <select
          name=""
          [(ngModel)]="userDetails.otpReq"
          id=""
          class="form-select"
        >
          <option [ngValue]="true">Enable</option>
          <option [ngValue]="false">Disable</option>
        </select>
      </div>
    </div>
    <div class="alarm_body" *ngIf="viewPolicy">
      <app-policy-view
        [policyDetails]="viewPolicy"
        (hideModel)="viewPolicy = null"
      ></app-policy-view>
    </div>
    <div class="alarm_actions alarm_footer" *ngIf="!viewPolicy">
      <div class="alarm_div">
        <button class="btn alarm_btn" (click)="updateEntry()">
          {{ action == "add" ? "Create" : "Update" }}
        </button>
        <button class="btn btn-light" (click)="close()">Cancel</button>
      </div>
    </div>
  </div>
</div>
































































<!-- 
<div class="alarm_screen alarm_screen_box">
  <div class="alarm_container">
    <div class="alarm_header">
      <h4 class="alarm_header_text">Company Information</h4>
    </div>
    <div
      class="alarm_body alarm_padding"
      [ngStyle]="{ display: !viewPolicy ? 'block' : 'none' }"
    >
      <div class="form-group mb-2">
        <label for="exampleInputEmail1">Company Name</label>
        <input
          autocomplete="off"
          type="text"
          class="form-control"
          placeholder="Enter Company Name"
          [(ngModel)]="company.name"
          *ngIf="action == 'add'"
          name="name"
          [ngClass]="{ 'error-input': errors['name']['error'] }"
        />
        <input
          autocomplete="on"
          type="text"
          class="form-control"
          placeholder="Enter Company Code"
          [ngModel]="company.code"
          *ngIf="action == 'add'"
          name="code"
          [ngClass]="{ 'error-input': errors['code']['error'] }"
        />
        <span class="error-msg" *ngIf="errors['code']['error']">
          {{ errors["code"]["msg"] }}
        </span>
      </div>
      <div class="form-group mb-2">
        <label for="exampleInputEmail1">Company Name</label>
        <input
          autocomplete="off"
          type="text"
          class="form-control"
          *ngIf="action == 'add'"
          [(ngModel)]="userDetails.company"
          name="company"
        />
        <input
          autocomplete="off"
          type="text"
          class="form-control"
          *ngIf="action == 'update'"
          disabled
          [ngModel]="userDetails.company"
          name="company"
        />
      </div>
      <div class="form-group mb-2">
        <label for="exampleInputEmail1">Email ID</label>
        <input
          autocomplete="off"
          type="text"
          class="form-control"
          placeholder="Enter Email ID"
          *ngIf="action == 'add'"
          [(ngModel)]="userDetails.email"
          name="emailid"
          [ngClass]="{ 'error-input': errors['email']['error'] }"
        />
        <input
          autocomplete="off"
          type="text"
          class="form-control"
          disabled
          *ngIf="action == 'update'"
          [ngClass]="{ 'error-input': errors['email']['error'] }"
          [ngModel]="userDetails.email"
          name="emailid"
        />

        <span class="error-msg" *ngIf="errors['email']['error']">
          {{ errors["email"]["msg"] }}</span
        >
      </div>
      <div class="form-group mb-2">
        <label for="exampleInputEmail1">Phone Number</label>
        <input
          autocomplete="off"
          class="form-control"
          id="card-email"
          placeholder="Enter Phone Number"
          (keydown)="phoneNumberValidate($event)"
          name="mobile"
          type="number"
          required
          minlength="4"
          maxlength="50"
          [(ngModel)]="userDetails.mobile"
          [ngClass]="{ 'error-input': errors['mobile']['error'] }"
        />
        <span class="error-msg" *ngIf="errors['mobile']['error']">
          {{ errors["mobile"]["msg"] }}</span
        >
      </div>

        <div class="form-group mb-2"
          >
          <label for="exampleInputEmail1">Select Policy</label>
          <div class="flex-start">
            <input autocomplete="off" type="radio" [(ngModel)]="policyType" value="manual"
              id="manualPolicy" name="policyType">
            <label for="manualPolicy">Manual Role</label>
          </div>
          <div class="flex-start">
            <input autocomplete="off" type="radio" [(ngModel)]="policyType" value="predefined" id="predefinedPolity"
              name="policyType">
            <label for="predefinedPolity">Policy Group</label>
          </div>
        </div>
        <div class="mb-2"
          [ngStyle]="{'display': policyType == 'predefined' ? 'block' : 'none'}">
          <label for="exampleInputEmail1">Select Policy</label>
          <div>
            <select id="policyList" [ngStyle]="{display: polices.length > 0 ? 'block': 'none'}" class="form-control"
              [(ngModel)]="userDetails.policyName" data-live-search="true">
              <option *ngFor="let policy of polices" value="{{policy['policyName']}}">{{policy['policyName']}}</option>
            </select>
            <span class="error-msg" *ngIf="errors['policy']['error']">
              {{errors['policy']['msg']}}</span>
            <div *ngIf="userDetails.policyName">
              <span class="nav-link" (click)="selectPolicy()">View Policy ({{ userDetails.policyName }})</span>
            </div>
            <div class="mb-2" [ngStyle]="{display: polices.length == 0 ? 'block': 'none'}">
              No Policies Created <a routerLink="/dash/settings/policies">Create Policy</a>
            </div>
          </div>
        </div>
      <div class="mb-2" *ngIf="services.length > 0 && policyType == 'manual'">
        <label for="exampleInputEmail1">Select Service List</label>
        <div class="pt-2">
          <menu-selector
            [edit]="true"
            [menus]="services"
            [selected]="userDetails['mList']"
          ></menu-selector>
        </div>
      </div>
      <div
        class="form-group mb-2"
        [ngStyle]="{ display: type == 'clients' ? 'block' : 'none' }"
      >
        <label for="exampleInputEmail1">Two Factor Authentication</label>
        <select
          name=""
          [(ngModel)]="userDetails.otpReq"
          id=""
          class="form-select"
        >
          <option [ngValue]="true">Enable</option>
          <option [ngValue]="false">Disable</option>
        </select>
      </div>
    </div>
    <div class="alarm_body" *ngIf="viewPolicy">
      <app-policy-view
        [policyDetails]="viewPolicy"
        (hideModel)="viewPolicy = null"
      ></app-policy-view>
    </div>
    <div class="alarm_actions alarm_footer" *ngIf="!viewPolicy">
      <div class="alarm_div">
        <button class="btn alarm_btn" (click)="updateEntry()">
          {{ action == "add" ? "Create" : "Update" }}
        </button>
        <button class="btn btn-light" (click)="close()">Cancel</button>
      </div>
    </div>
  </div>
</div> -->
