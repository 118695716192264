import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { APIService } from "./api.service";

@Injectable({ providedIn: "root" })
export class WhitelabelService {
  constructor(private http: HttpClient, private apiServer: APIService) {}
  loadSettings(): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set("Content-Type", "application/json; charset=utf-8");
    let data = { a: "fetchProductDetails", pn: 'dev.app.swayam.cloud' };
    let url = `https://api.swayam.cloud/v3/globals/fetchproductinfo`;
    //let result = await this.apiServer.postData(apiURL, data, header);
    return this.http.post(url, JSON.stringify(data), { headers: headers });
  }
}
