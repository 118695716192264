import { Component, EventEmitter, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APIService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { environment } from 'src/environments/environment';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ElementRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import * as XLSX from 'xlsx';
declare let Jhxlsx: any;
declare let $: any;

@Component({
  selector: 'app-client-routes',
  templateUrl: './client-routes.component.html',
  styleUrls: ['./client-routes.component.css']
})
export class ClientRoutesComponent implements OnInit{
      @ViewChild('fileInput', { read: ElementRef }) fileInput!: ElementRef;
      myForm: any;
      minEndDate: string = '';
      documentInputErrors: { [key: string]: string | null } = {};
      isOpen: boolean = false;
      isSubmitted: boolean = false;
      urlPrefix: string | null = null;
      config: any;
      addcom: boolean = false;
      result:any;
      selectedCompany: any;
      showModal: boolean = false;
      isEditMode: boolean = false;
      loading: boolean= false;
      clientNamesInfo: any;
      clientNamesList: any
      pagination: any = { perPage: 10, page: 1, };
      pagination2: any = { perPage: 10, page: 1, };
      pages: any = [10, 50, 100, 200];
      pageChange: EventEmitter<number> | undefined;
      exportVar: any = [];
      expandedButton: boolean = false;
      bulkFileUpload: boolean = false;
      submitted: boolean = false;
      contentList: any[] = [];
      index: any = 0;
      popUp: boolean = false;
      popup_header: any = '';
      popup_subtitle: any = '';
      modalMessage: string = '';
      access = localStorage.getItem('acT');

      constructor(private fb: FormBuilder, private apiService: APIService,
        public notifier: NotifierService,
        private route: ActivatedRoute) {
          this.config = this.route.snapshot.data['config'];
        }


        headers: any = [
          {
            id: "client_routes.client_name",
            name: "Client Name",
            // filter: true,
            sort: { sort: true, direction: "Asc" },
          },
          {
            id: "client_routes.parking_location",
            name: "Parking Location",
            // filter: true,
            sort: { sort: true, direction: "Asc" },
          },
          {
            id: "client_routes.route_name",
            name: "Route Name",
            // filter: true,
            sort: { sort: true, direction: "Asc" },
          },
          {
            id: "client_routes.shift_type",
            name: "Shift",
            // filter: true,
            sort: { sort: true, direction: "Asc" },
          },
          {
            id: "client_routes.working_days",
            name: "Working Days",
            // filter: true,
            sort: { sort: true, direction: "Asc" },
          },
          {
            id: "client_routes.total_kms",
            name: "Total KM'S",
            // filter: true,
            sort: { sort: true, direction: "Asc" },
          },
          {
            id: "client_routes.client_location",
            name: "Client Location",
            // filter: true,
            sort: { sort: true, direction: "Asc" },
          }
        ];


        headers2: any = [
          {
            id: "Client Name",
            name: "Client Name",
            // filter: true,
            sort: { sort: true, direction: "Asc" },
          },
          {
            id: "Parking Location",
            name: "Parking Location",
            // filter: true,
            sort: { sort: true, direction: "Asc" },
          },
          {
            id: "Route Name",
            name: "Route Name",
            // filter: true,
            sort: { sort: true, direction: "Asc" },
          },
          {
            id: "Shifts",
            name: "Shift",
            // filter: true,
            sort: { sort: true, direction: "Asc" },
          },
          {
            id: "Working Days",
            name: "Working Days",
            // filter: true,
            sort: { sort: true, direction: "Asc" },
          },
          {
            id: "Total Kilometers",
            name: "Total KM'S",
            // filter: true,
            sort: { sort: true, direction: "Asc" },
          },
          {
            id: "Client Location",
            name: "Client Location",
            // filter: true,
            sort: { sort: true, direction: "Asc" },
          }
        ];

      ngOnInit() {
        this.fetchclientroute();
        this.clients_info();

        this.myForm = this.fb.group({
          clientName: [null, [Validators.required, Validators.maxLength(100)]],
          clientCode: ['', Validators.required],
          client_location: ['', [Validators.required, Validators.maxLength(500)]],
          route_name:['', Validators.required],
          shift_type:['Gen', [Validators.required]],
          one_way_kms: ['', [Validators.required]],
          empty_kms: ['', [Validators.required]],
          wor_days: ['', [Validators.required]],
          park_kms: ['', [Validators.required]],
          tot_kms: [''],
          park_loc: ['', [Validators.required, Validators.maxLength(500)]],
        });
      }

      calculateTotalKms(): number {
        const emptyKms = Number(this.myForm.get('empty_kms').value);
        const oneWayKms = Number(this.myForm.get('one_way_kms').value);
        const parkingKms = Number(this.myForm.get('park_kms').value);
        const workingDays = Number(this.myForm.get('wor_days').value);

        // return workingDays * (emptyKms + oneWayKms + parkingKms);
        return (workingDays * emptyKms) + oneWayKms + parkingKms
      }

      updateEndDateMin(startFieldName: string, endFieldName: string): void {
        const startDate = this.myForm.get(startFieldName)?.value;
        if (startDate) {
          const endDateControl = this.myForm.get(endFieldName);
          if (endDateControl) {
            endDateControl.enable(); // Enable the end date field if it was previously disabled
            endDateControl.setValidators([Validators.min(startDate)]); // Set minimum selectable date
            endDateControl.updateValueAndValidity(); // Update validity of the end date field
          }
        }
      }

    populateForm(selectedCompany: any): void {

      console.log("inside the populate form");
      console.log(selectedCompany);
      console.log(selectedCompany.client_routes);
      if (selectedCompany) {
        // let companyDetails = this.selectedCompany.company_details;
        // console.log(companyDetails);
        // let bankDetails = this.selectedCompany.bank_details;
        // let licenseDetails = this.selectedCompany.license_details;
        // console.log("inside the populate form");


  console.log("inside the populate form");
        console.log('selectedCompany$$$$$$$$$$$$$$$$$$$$',selectedCompany);
//         const [emisF, emisT] = company_details.vehicle_emi_duration.split(' to ');
//         console.log("EMI duration",emisF, emisT)

        this.myForm.patchValue({
          clientName: selectedCompany.client_routes.client_name,
          clientCode: selectedCompany.client_id,
          companyCode: selectedCompany.client_routes.company_code,
          client_location: selectedCompany.client_routes.company_address,
          ad_name: selectedCompany.client_routes.admin.admin_name,
          ad_cont_num: selectedCompany.client_routes.admin.admin_contact_number,
          ad_email: selectedCompany.client_routes.admin.admin_email,
          op_name: selectedCompany.client_routes.operations.operations_name,
          op_cont_num: selectedCompany.client_routes.operations.operations_contact_number,
          op_email: selectedCompany.client_routes.operations.operations_email,
          route_name: selectedCompany.client_routes.route_name,
          shift_type: selectedCompany.client_routes.shift_type,
          one_way_kms: selectedCompany.client_routes.one_way_kms,
          empty_kms: selectedCompany.client_routes.empty_kms,
          wor_days: selectedCompany.client_routes.working_days,
          park_kms: selectedCompany.client_routes.parking_kms,
          tot_kms: selectedCompany.client_routes.total_kms,
          park_loc: selectedCompany.client_routes.parking_location
        });
        // this.myForm.get('companyCode')?.disable();
//         console.log("disabled comp code",this.myForm.get('companyCode'));
//
//         console.log('Company Name:', this.myForm.get('companyName').value);
//   console.log('Company Code:', this.myForm.get('companyCode').value);

        // Patch auditors and chartered accountants arrays if available
        // const auditorsArray = selectedCompany.auditors;


    }
  }

  clearForm(): void{

    this.myForm.reset();
    this.isEditMode = false;
    this.addcom = false;
    this.showModal = false;
    // this.myForm.get('clientName')?.enable();
    this.enableForModal()
  }



  toggleSidenav() {
    this.isOpen = !this.isOpen;
  }


  onSubmit(){
        Object.values(this.myForm.controls).forEach((control:any) => {
      control.markAsTouched();
    });
        this.isSubmitted = true;
        console.log("Forms values",this.myForm.value);
        console.log("Form",this.myForm);
        console.log("Form Status",this.myForm.status);
        if (this.bulkFileUpload && this.isEditMode) {
          console.log('onSubmit1111111111111111')
          if (this.myForm.valid) {
            this.updateValue(this.myForm.value);
            this.isEditMode = false;
            this.addcom = false;
            this.documentInputErrors = {};
          } else {
            this.notifier.alert(
              'Info',
              '',
              'Please provide the mandatory fields',
              'info',
              5000
            );
          }
        }else if (this.isEditMode) {
          this.updateClientroute();
        } else {
          this.addclientroute();
        }
        this.pagination = { perPage: 10, page: 1, };
        this.pagination2 = { perPage: 10, page: 1, };
        this.fetchclientroute();
      }


      async addclientroute() {
        if (this.myForm.valid) {
          const formValues = this.myForm.value;
          console.log("formValues:",formValues)
          let data: any = {
            action: 'add',
            // company_code: formValues.companyCode,
            client_id: formValues.clientCode,
            client_name: formValues.clientName,
            client_location: formValues.client_location,
            route_name: formValues.route_name,
            shift_type: formValues.shift_type,
            one_way_kms: formValues.one_way_kms,
            empty_kms: formValues.empty_kms,
            working_days: formValues.wor_days,
            parking_kms: formValues.park_kms,
            total_kms: this.calculateTotalKms(),
            parking_location: formValues.park_loc
          };

          let header = {
            Authorization: localStorage.getItem('t'),
            
          };

          //https://api.swayam.cloud/v3/admin/users/fetchaccountgroupsv2
          let apiURL = `${this.config.apiURL}/admin/clientRoutes`;


          let result = await this.apiService.postDataPromis(apiURL, data, header);

          if (result.s == '1' || result.status == '1') {
            // console.log(result);
            this.showPopup(
              'Client Route Added',
              'The Route has been successfully added.'
            );
            this.addcom = false;
            this.isEditMode = false;
            this.clearForm();
            this.fetchclientroute();

            // this.notifier.alert('Success', '', result.data, 'success', 5000);

          } else {
            this.notifier.alert('Info', '', result.error, 'info', 5000);
          }

      }
      else{
        this.notifier.alert('Info', '', 'Please provide mandatory fields', 'info', 5000);
      }
    }

    async updateClientroute(){
  if (this.myForm.valid) {
          const formValues = this.myForm.value;
          console.log('formValues.tot_kms',formValues.tot_kms)
          let data: any = {
          action: 'update',
          // company_code: formValues.companyCode,
          client_id: formValues.clientCode,
          client_name: formValues.clientName,
          client_location: formValues.client_location,
          route_name: formValues.route_name,
          shift_type: formValues.shift_type,
          one_way_kms: formValues.one_way_kms,
          empty_kms: formValues.empty_kms,
          working_days: formValues.wor_days,
          parking_kms: formValues.park_kms,
          total_kms: this.calculateTotalKms(),
          parking_location: formValues.park_loc
          };

          let header = {
            Authorization: localStorage.getItem('t'),
            
          };

          //https://api.swayam.cloud/v3/admin/users/fetchaccountgroupsv2
          let apiURL = `${this.config.apiURL}/admin/clientRoutes`;


          let result = await this.apiService.postDataPromis(apiURL, data, header);

          if (result.s == '1' || result.status == '1') {
            // console.log(result);
            this.showPopup(
              'Client Route Updated',
              'The Route has been successfully Updated.'
            );
            this.addcom = false;
            this.isEditMode = false;
            this.clearForm();
            this.fetchclientroute();
            // this.notifier.alert('Success', '', result.data, 'success', 5000);

          } else {
            this.notifier.alert('Info', '', result.error, 'info', 5000);
          }

      }
      else{
        this.notifier.alert('Info', '', 'Please provide mandatory fields', 'info', 5000);
      }
    }

    async bulkUpload(clientRoutes_info: any) {
      this.notifier.loading(true)
      let data: any = {
        action: 'bulk_upload',
        clientRoutes: clientRoutes_info,
        clients_info: this.clientNamesInfo,
      };
      let header = {
        Authorization: localStorage.getItem('t'),
        // 'X-Api-Key': localStorage.getItem('clientid'),
      };
  
      // //https://api.swayam.cloud/v3/admin/users/fetchaccountgroupsv2
      let apiURL = `${this.config.apiURL}/admin/clientRoutes`;
  
      let result = await this.apiService.postDataPromis(apiURL, data, header);
  
      if (result.s == '1' || result.status == '1') {
        // console.log(result);
        this.addcom = false;
        this.isEditMode = false;
        this.submitted = false;
        // this.fileInfoArray = [];
        this.clearForm();
        this.fetchclientroute();
        this.bulkFileUpload = false;
  
        this.notifier.alert('', '', result.message, 'success', 5000);
      } 
      else {
        this.notifier.alert('Info', '', result.error_message, 'info', 5000);
      }
      this.notifier.loading(false)
    }


    client_details_auto_fill(client_name: any): void {
      const clientname = this.myForm.get(client_name)?.value;
      console.log("###################", clientname);
      console.log("@@@@@@@@@@@@@@@@@@@",this.clientNamesInfo);
      console.log("&&&&&&&&&&&&&&&",this.clientNamesInfo[clientname])
      let selectedClient = this.clientNamesInfo;

      this.myForm.patchValue({
//           clientName: selectedCompany.client_routes.client_name,
//           clientName:
          clientCode: this.clientNamesInfo[clientname]['client_id'],
          companyCode: this.clientNamesInfo[clientname]['company_code'],
          client_location: this.clientNamesInfo[clientname]['company_address'],
          ad_name: this.clientNamesInfo[clientname]['admin_name'],
          ad_cont_num: this.clientNamesInfo[clientname]['admin_contact_number'],
          ad_email: this.clientNamesInfo[clientname]['admin_email'],
          op_name: this.clientNamesInfo[clientname]['operations_name'],
          op_cont_num: this.clientNamesInfo[clientname]['operations_contact_number'],
          op_email: this.clientNamesInfo[clientname]['operations_email'],
          wor_days: this.clientNamesInfo[clientname]['working_days'],
        });
    }

    // async fetchclientroute(){

    //   this.notifier.loading(true);

    //   let data: any = {
    //   action: 'fetch_all'
    //   }
    //   let header = {
    //     Authorization: localStorage.getItem('t'),
        
    //   };

    //   //https://api.swayam.cloud/v3/admin/users/fetchaccountgroupsv2
    //   let apiURL = `${this.config.apiURL}/admin/clientRoutes`;


    //   let result = await this.apiService.postDataPromis(apiURL, data, header);

    //   if (result.s == '1' || result.status == '1') {
    //     // console.log(result);

    //     this.result = result.message;
    //     this.filteredData = result.message;
    //     this.exportVar = result.message;
    //     console.log("fetch in clients",result);

    //     // this.notifier.alert('Success', '', result.data, 'success', 5000);

    //   } else {
    //     this.notifier.alert('Info', '', result.error, 'info', 5000);
    //   }
    //   this.notifier.loading(false);

    // }

    async fetchclientroute(){

      if (this.pagination2.page === 1){
        this.notifier.loading(true);
        this.filteredData = []
      }

      let data: any = {
      action: 'fetch_by_pagination',
      'page': this.pagination2.page,
      'perPage': this.pagination2.perPage
      }
      let header = {
        Authorization: localStorage.getItem('t'),
        
      };
      let apiURL = `${this.config.apiURL}/admin/clientRoutes`;
      let result = await this.apiService.postDataPromis(apiURL, data, header);
      if (result.s == '1' || result.status == '1') {
        this.pagination2.nextPage = result.nextPage;
        this.filteredData = this.filteredData.concat(result.data);
        this.result = this.filteredData
        this.exportVar = this.filteredData
        if (this.pagination2.page === 1){
          this.notifier.loading(false);
        }
        if (this.pagination2.nextPage) {
          this.pagination2.page = this.pagination2.nextPage;
          this.fetchclientroute();
        }else{
          this.pagination2 = { perPage: 10, page: 1 };
          this.pagination = { perPage: 10, page: 1 };
        }
      } else {
        this.notifier.alert('Info', '', result.error, 'info', 5000);
      }
      this.notifier.loading(false);

    }

    fetchAllRoutes() {
      if (this.pagination2.nextPage) {
        this.pagination2.page = this.pagination2.nextPage;
        this.fetchclientroute();
      }
    }


    openModal(company: any) {
      this.isEditMode = true
      this.selectedCompany = company;
      this.populateForm(this.selectedCompany)
      this.showModal = true;
      this.disableForModal()
    }

    disableForModal() {
      Object.keys(this.myForm.controls).forEach(control => {
        this.myForm.get(control)?.disable();
      });
    }
  
    // Function to enable all form fields
    enableForModal() {
      Object.keys(this.myForm.controls).forEach(control => {
        this.myForm.get(control)?.enable();
      });
    }

    hideModal() {
      this.showModal = false;
    }
    toggleEditMode(): void {
      this.isEditMode = !this.isEditMode;
    }

    editClientroute(selectedCompany: any): void {
      console.log("inside edit client");
      this.isEditMode = true;
      this.populateForm(selectedCompany); // Pass selected company object to populateForm
      this.myForm.get('clientName').enable({ onlySelf: false });
      this.addcom = true; // Set flag to indicate edit mode
      this.expandedIndex = null;
    }


    async clients_info(){
      // this.notifier.loading(true);

      let data: any = {
        action: "clients_details",
      }
      let header = {
        Authorization: localStorage.getItem('t'),
        
      };

      //https://api.swayam.cloud/v3/admin/users/fetchaccountgroupsv2
      let apiURL = `${this.config.apiURL}/admin/clientRoutes`;


      let result = await this.apiService.postDataPromis(apiURL, data, header);

      if (result.s == '1' || result.status == '1') {
        // console.log(result);

        this.clientNamesInfo = result.message;
        console.log("client name's", this.clientNamesInfo);
        this.clientNamesList = Object.keys(this.clientNamesInfo);


        // this.notifier.alert('Success', '', result.data, 'success', 5000);

      } else {
        this.notifier.alert('Info', '', result.error, 'info', 5000);
      }
      // this.notifier.loading(false);
    }

  
    filteredData: any = [];
    queryResult(event: any) {
      console.log('SSSSSSSSSS')
      let queryString: string = (event.target.value || '').toLowerCase();
      if (!queryString) {
        this.filteredData = this.result;
        return;
      }
  
      this.filteredData = this.result.filter((item: any) => {
        return this.headers.filter((h: any) => {
          return (
            h.id.includes('client_routes')
              ? item.client_routes[h.id.split('.')[1]]
              : item[h.id]
          )
          .toString()
            .toLowerCase()
            .includes(queryString);
        }).length
      });
    }


    headers3: any = [
      { "id": "client_id", "name": "Client Code" },
      { "id": "client_name", "name": "Client Name" },
      { "id": "client_location", "name": "Client Location" },
      { "id": "route_name", "name": "Route Name" },
      { "id": "shift_type", "name": "Shifts" },
      { "id": "one_way_kms", "name": "One Way Kms" },
      { "id": "empty_kms", "name": "Empty Kms" },
      { "id": "working_days", "name": "Working Days" },
      { "id": "parking_kms", "name": "Parking Kms" },
      { "id": "total_kms", "name": "Total Kms" },
      { "id": "parking_location", "name": "Parking Location" },
    ]
    
    exportEach(maps: any) {
      this.notifier.loading(true);
      let client_route_detail: any;
      let data: any = [
        {
          sheetName: 'Client Routes',
          data: [
            this.headers3.map((h: any) => {
              return { text: h.name };
            }),
          ],
        },
      ];
      let sheetData: any = maps;
      let headers: any = this.headers3;
  
      sheetData.map((item: any) => {
        client_route_detail = item.client_routes;
        let data_: any = [];
        headers.forEach((h: any) => {
          // data_.push({ text: h.alter ? h.alter(item, h.id) : item[h.id] });
          if(h.sub){
            data_.push({ text: client_route_detail[h.sub][h.id] });
          }else{
            data_.push({ text: client_route_detail[h.id] });
          }
        });

        console.log('each data',data_);
        data[0]['data'].push(data_);
      });
  
      var options = {
        fileName: 'Client Routes Details',
      };
      console.log('data' + JSON.stringify(data));
  
      Jhxlsx.export(data, options);
      this.notifier.loading(false);
    }
    

  expandedIndex: number | null = null;

  toggleOptions(index: number) {
    this.expandedIndex = this.expandedIndex === index ? null : index;
  }
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    if (!target.closest('.options-container')) {
      this.expandedIndex = null;
    }
  }

  toggleOptionsExport() {
    console.log('toggling..');
    this.expandedButton = !this.expandedButton;
    console.log('this.expandedButton', this.expandedButton);
  }

  async get_excel_url() {
    this.notifier.loading(true);

    let data: any = {
      action: 'get_file',
    };
    let header = {
      Authorization: localStorage.getItem('t'),
      // 'X-Api-Key': localStorage.getItem('clientid'),
    };

    //https://api.swayam.cloud/v3/admin/users/fetchaccountgroupsv2
    let apiURL = `${this.config.apiURL}/admin/clientRoutes`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.s == '1' || result.status == '1') {
      // console.log(result);

      let pre_url = result.data;
      console.log('Presign Url for', pre_url);
      let a = document.createElement('a');
      a.href = pre_url;
      a.download = 'true';
      a.setAttribute('target', '_blank');
      a.click();
      // this.notifier.alert('Success', '', result.data, 'success', 5000);
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
    this.notifier.loading(false);
  }

  openFileExplorer() {
    this.fileInput.nativeElement.click();
  }

  fileSelected(event: any) {
    console.log('event is ', event);
    const selected_File: File = event.target.files[0];
    this.fileInput.nativeElement.value = '';
    console.log('selected File is ', selected_File);

    if (selected_File) {
      console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!');
      // Get the file name and extension
      const fileName: string = selected_File.name;
      const fileExtension: string = fileName.split('.').pop() || '';

      // Check if the file extension is valid
      if (fileExtension === 'xlsx' || fileExtension === 'xls') {
        console.log('Valid Excel file selected');
        // Read the Excel file
        const reader = new FileReader();
        reader.onload = (e: ProgressEvent<FileReader>) => {
          const data_of_file: any | ArrayBuffer | null = e.target?.result;
          if (typeof data_of_file === 'string') {
            // Parse the Excel file content
            const workbook = XLSX.read(data_of_file, { type: 'binary' });
            // Assuming there's only one sheet, you can change this according to your requirement
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];
            // Convert the sheet to JSON
            const jsonData = XLSX.utils.sheet_to_json(worksheet);
            // Now you can validate the content as per your requirements
            console.log('Excel content:', jsonData);
            let isnotValid = this.validateData(jsonData);
            console.log('isnotValid', isnotValid);
            if (isnotValid.length !== 0) {
              this.notifier.alert(
                'Info',
                '',
                'Please provide all Fields and with correct Information',
                'info',
                5000
              );
              //   return;
            } else {
              this.bulkFileUpload = true;
              console.log('&&&&&&&&&&&&&&&', jsonData);
              this.contentList = this.assingValueforExcel(jsonData);
              console.log('this.contentList', this.contentList);
            }
            // Here you can perform validation on jsonData
            // For example, check if certain columns or values are present
          } else {
            console.error('Failed to read the file.');
          }
        };
        reader.readAsBinaryString(selected_File);
      } else {
        console.error('Invalid file format. Please select an Excel file.');
      }
      // this.fileInput.nativeElement.value = '';
    } else {
      console.error('No file selected.');
    }
  }


  validateData(data: any[]): string[] {
    const errors: string[] = [];
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      const requiredFields = ['Client Name', 'Route Name', 'Shifts', 'One Way Kilometers', 'Empty Kilometers', 'Parking Kilometer', 'Parking Location'];
      for (const field of requiredFields) {
        if (
          !(field in item) ||
          item[field] === null ||
          item[field] === undefined ||
          item[field] === ''
        ) {
          errors.push(`Row ${i + 1}: '${field}' is required.`);
        }
      }
    }
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      for (const key in item) {
        if (item[key] === undefined || item[key] === null || item[key] === '') {
          errors.push(`Row ${i + 1}: Empty value found in '${key}'`);
        }
      }
    }
    return errors;
  }

  assingValueforExcel(data: any) {
    console.log('@@@@@@@@', data)
    for (let i = 0; i < data.length; i++) {
      console.log('@@@@@@@@', data[i]['Client Name']);
      let name = data[i]['Client Name'];
      data[i]['Client ID'] = this.clientNamesInfo[name]['client_id'];
      data[i]['Company Code'] = this.clientNamesInfo[name]['company_code']
      data[i]['Client Location'] = this.clientNamesInfo[name]['company_address']
      data[i]['Admin Name'] = this.clientNamesInfo[name]['admin_name']
      data[i]['Admin Contact Number'] = this.clientNamesInfo[name]['admin_contact_number']
      data[i]['Admin Email'] = this.clientNamesInfo[name]['admin_email']
      data[i]['Operations Name'] = this.clientNamesInfo[name]['operations_name']
      data[i]['Operations Contact Number'] = this.clientNamesInfo[name]['operations_contact_number']
      data[i]['Operations Email'] = this.clientNamesInfo[name]['operations_email']
      data[i]['Working Days'] = this.clientNamesInfo[name]['working_days']
      data[i]['Total Kilometers'] = (this.clientNamesInfo[name]['working_days'] * data[i]['Empty Kilometers']) + data[i]['One Way Kilometers'] + data[i]['Parking Kilometer'];

    }
    return data;
  }

  gotToHomePage() {
    this.addcom = false;
    this.isEditMode = false;
    this.submitted = false;
    this.clearForm();
    this.fetchclientroute();
    this.bulkFileUpload = false;
  }

  editClientRouteForBulkUpload(selectedCompany: any): void {
    console.log('inside vehicle edit in bulk upload:', selectedCompany);
    this.populateFormOfBulkUpload(selectedCompany); // Pass selected company object to populateForm
    this.isEditMode = true;
    this.addcom = true; // Set flag to indicate edit mode
    let client_name = selectedCompany['Client Name'];
    this.index = this.contentList.findIndex(item => JSON.stringify(item) === JSON.stringify(selectedCompany));
    console.log('Selected client route index.', this.index)
  }

  async populateFormOfBulkUpload(selectedCompany: any): Promise<void> {
    console.log('inside the populate form of bulkUpload');
    console.log(selectedCompany)
    if (selectedCompany) {
      this.myForm.patchValue({
          clientName: selectedCompany['Client Name'],
          clientCode: selectedCompany['Client ID'],
          companyCode: selectedCompany['Company Code'],
          client_location: selectedCompany['Client Location'],
          ad_name: selectedCompany['Admin Name'],
          ad_cont_num: selectedCompany['Admin Contact Number'],
          ad_email: selectedCompany['Admin Email'],
          op_name: selectedCompany['Operations Name'],
          op_cont_num: selectedCompany['Operations Contact Number'],
          op_email: selectedCompany['Operations Email'],
          route_name: selectedCompany['Route Name'],
          shift_type: selectedCompany['Shifts'],
          one_way_kms: selectedCompany['One Way Kilometers'],
          empty_kms: selectedCompany['Empty Kilometers'],
          wor_days: selectedCompany['Working Days'],
          park_kms: selectedCompany['Parking Kilometer'],
          tot_kms: selectedCompany['Total Kilometers'],
          park_loc: selectedCompany['Parking Location']
      });
    }
  }

  updateValue(data: any) {
    this.contentList[this.index]['Client ID'] = data.clientCode;
    this.contentList[this.index]['Company Code'] = data.companyCode;
    this.contentList[this.index]['Client Location'] = data.client_location;
    // this.contentList[this.index]['Admin Name'] = data.ad_name;
    // this.contentList[this.index]['Admin Contact Number'] = data.ad_cont_num;
    // this.contentList[this.index]['Admin Email'] = data.ad_email;
    // this.contentList[this.index]['Operations Name'] = data.op_name;
    // this.contentList[this.index]['Operations Contact Number'] = data.op_cont_num;
    // this.contentList[this.index]['Operations Email'] = data.op_email;
    this.contentList[this.index]['Route Name'] = data.route_name;
    this.contentList[this.index]['Shifts'] = data.shift_type;
    this.contentList[this.index]['One Way Kilometers'] = data.one_way_kms;
    this.contentList[this.index]['Empty Kilometers'] = data.empty_kms;
    this.contentList[this.index]['Working Days'] = data.wor_days;
    this.contentList[this.index]['Parking Kilometer'] = data.park_kms;
    this.contentList[this.index]['Total Kilometers'] = data.tot_kms;
    this.contentList[this.index]['Parking Location'] = data.park_loc;
  }

  async onSubmitAllDataBulkUp(files?: any) {
    this.bulkUpload(this.contentList);
  }

  hidepopup(event: any) {
    this.popUp = false;
  }

  showPopup(header: any, title: any) {
    this.popup_header = header;
    this.popup_subtitle = title;
    this.popUp = true;
  }


  goBack(){
    this.modalMessage = 'Are you sure you want to discard the changes??';
  }
  
  clearMessage(){
    this.modalMessage = 'This will discarded the changes?';
  }

  refershFunction(){
    this.pagination = {perPage: 10, page: 1, };
    this.pagination2 = {perPage: 10, page: 1, };
    this.fetchclientroute()
  }



  }


