import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  ActivatedRoute,
  CanDeactivate,
} from "@angular/router";
import { Observable } from "rxjs";


declare let window: any;

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {}



  checkAuthorization: any = {
    'admin': () => {
      let currentRole: string | null = localStorage.getItem('role');
      if (currentRole === 'Admin') {
        return true;
      } else {
        return false;
      }
    },
    'superadmin': () => {
      let currentRole: string | null = localStorage.getItem('role');
      if (currentRole === 'Superadmin') {
        return true;
      } else {
        return false;
      }
    }
  }
  
  // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {



  //   let currentUser: string | null = localStorage.getItem('t');
  
  //   if (currentUser) {
  //     let currentRouth: any = route?.routeConfig?.path;
  //     let result: boolean = this.checkAuthorization[currentRouth]();
  //     if(result) {
  //       return true;
  //     } else {
  //       this.router.navigate(['/auth/login']);
  //       return false;
  //     }
  //   } else {
  //     this.router.navigate(['/auth/login']);  
  //     return false;;
  //   }
  // }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const token = localStorage.getItem('t');
    if (!token) {
      this.router.navigate(['/auth/login']);
      return false;
    }

    const currentRole = localStorage.getItem('role');
    if (!currentRole || (currentRole !== 'Admin' && currentRole !== 'Superadmin')) {
      this.router.navigate(['/auth/login']);
      return false;
    }

    if (currentRole === 'Admin') {
      const currentPath = state.url;
      const mapping: { [key: string]: string } = {
        '/admin/company': 'Companies',
        '/admin/admin_client': 'Clients',
        '/admin/driver': 'Drivers',
        '/admin/fuel_stations': 'Fuel Stations',
        '/admin/vehicles': 'Vehicles',
        '/admin/client_routes': 'Client Routes',
        '/admin/staff_onboarding': 'Staff OnBoard',
        '/admin/attenders': 'Attenders',
        '/admin/mechanics': 'Mechanics',
        '/admin/staff': 'Staff',
      };

      const currentMenuList = mapping[currentPath];
      if (currentMenuList) {
        const menuList = JSON.parse(localStorage.getItem('menuList') || '{}');
        const menuKeys = Object.keys(menuList);
        if (!menuKeys.includes(currentMenuList)) {
          // Find the first accessible path based on menuList
          const firstAccessiblePath = Object.keys(mapping).find(path => menuKeys.includes(mapping[path]));
          if (firstAccessiblePath) {
            this.router.navigate([firstAccessiblePath]);
          } else {
            this.router.navigate(['/auth/login']);
          }
          return false;
        } else {
          return true;
        }
      } else {
        this.router.navigate(['/auth/login']);
        return false;
      }
    } else if (currentRole === 'Superadmin') {
      return true;  // Assuming Superadmin can access all routes
    }

    return false;
  }


  qs(key: string) {
    key = key.replace(/[*+?^$.\[\]{}()|\\\/]/g, "\\$&"); // escape RegEx meta chars
    var match = location.search.match(
      new RegExp("[?&]" + key + "=([^&]+)(&|$)")
    );
    return match && decodeURIComponent(match[1].replace(/\+/g, " "));
  }
}

export interface CanDeactivateComponent {
  canDeactivate: () => Observable<boolean> | boolean;
}

@Injectable()
export class canDeactivateGuard
  implements CanDeactivate<CanDeactivateComponent>
{
  canDeactivate(component: CanDeactivateComponent) {
    return component.canDeactivate ? component.canDeactivate() : true;
  }
}
