import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { APIService } from 'src/app/services/api.service';
import * as moment from 'moment';
import { NotifierService } from 'src/app/services/notifier.service';
import { ActivatedRoute } from '@angular/router';

// declares
declare let $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {
  races: any = [];
  loading: boolean = false;

  headers: any = [
    {
      id: 'SquentialRaceOrderNumber',
      name: 'Race Number',
      type: 'string',
      filter: true,
      show: true,
    },
    {
      id: 'eventName',
      name: 'Venue',
      type: 'string',
      show: true,
      filter: true,
      link: {
        href: '/dash/venue-details',
        queryParamsKey: 'queryParamsVenue',
      },
    },
    {
      id: 'ExternalRaceId',
      name: 'Race ID',
      type: 'string',
      show: true,
      filter: true,
      link: {
        href: '/dash/race-details',
        queryParamsKey: 'queryParamsRace',
      },
    },
    {
      id: 'RaceName',
      name: 'Race Name',
      type: 'string',
      filter: true,
      show: true,
    },
    {
      id: 'raceStatus',
      name: 'Race Status',
      type: 'component',
      filter: true,
      component: {
        name: 'StatusViewComponent',
        data: [
          {
            type: 'success',
            value: 'FINISHED',
          },
          {
            type: 'pending',
            value: 'Planned',
          },
        ],
      },
      show: true,
    },
    {
      id: 'RaceTime',
      name: 'Race Time',
      type: 'string',
      show: true,
      datepicker: {
        class: 'datetimepicker',
      },
    },
    {
      id: 'RaceTime',
      name: 'Actual Start Time',
      type: 'string',
      show: true,
      filter: true,
    },
    {
      id: 'TrackID',
      name: 'Track Config ID',
      type: 'string',
      show: true,
      filter: true,
    },
  ];

  config: any;

  constructor(
    private apiService: APIService,
    private notifier: NotifierService,
    private router: ActivatedRoute
  ) {
    this.config = this.router.snapshot.data['config'];
  }

  default(event: Event) {
    // event.preventDefault();
    event.stopPropagation();
  }

  hideMenu(event: Event, h: any) {
    event.stopPropagation();
    h.show = !h.show;
  }

  async ngOnInit(): Promise<void> {}

  async loadData(): Promise<void> {
    this.loading = true;
    this.notifier.loading(true);

    this.races = [];
    let date: string = $('.datetimepicker').val();
    if (date) {
      await this.loadEventsBasedOnDates(
        moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD')
      );
    }
    if (this.races && this.races.length > 0) {
      this.races = this.races.sort((a: any, b: any): number => {
        return (
          moment(a['RaceTime'], 'YYYY-MM-DD-hh-mm-ss').valueOf() -
          moment(b['RaceTime'], 'YYYY-MM-DD-hh-mm-ss').valueOf()
        );
      });
    }
    this.loading = false;
    this.notifier.loading(false);
  }

  async ngAfterViewInit(): Promise<void> {
    $('.datetimepicker').val(moment().format('DD-MM-YYYY'));
    $('.datetimepicker')
      .datepicker({
        autoclose: true,
        // minViewMode: 1,
        format: 'dd-mm-yyyy',
        orientation: 'bottom auto',
      })
      .on('changeDate', (selected: any) => {
        this.loadData();
      });
    setTimeout(() => {
      this.loadData();
    }, 10);
  }

  async loadEventsBasedOnDates(date: string): Promise<boolean> {
    return new Promise(async (resolve: any) => {
      let apiURL: string = `${this.config.raceAPI}/event-list?formatted_date=${date}`;

      let result: any = await this.apiService.getDataPromis(apiURL, {}, {});

      if (result.eventNames && result.eventNames.length > 0) {
        let promises: any = [];
        result.eventNames.forEach((event: any) => {
          promises.push(
            this.loadRacesBasedOnEvent(
              event['external_event_id'],
              event['external_venue_id'],
              event['name'].split('--')[0]
            )
          );
        });
        if (promises.length > 0) {
          Promise.all(promises).then((values: any) => {
            resolve(true);
          });
        } else {
          resolve(true);
        }
      } else {
        resolve(true);
      }
    });
  }

  async loadRacesBasedOnEvent(
    event_id: string,
    venue_id: string,
    event_name: string
  ): Promise<boolean> {
    return new Promise(async (resolve: any) => {
      let apiURL: string = `${this.config.raceAPI}/getracedetails?ExternalVenueId=${venue_id}&ExternalEventId=${event_id}`;

      let result: any = await this.apiService.getDataPromis(apiURL, {}, {});

      if (result && Array.isArray(result) && result.length > 0) {
        result.forEach((race: any) => {
          race['eventName'] = event_name;
          race['eventDate'] = $('.datetimepicker').val();
          race['queryParamsVenue'] = {
            event_id: race['ExternalEventId'],
            venue_id: race['ExternalVenueId'],
            date: race['eventDate'],
            event_name: race['eventName'],
          };
          race['queryParamsRace'] = {
            race_id: race['ExternalRaceId'],
          };
        });
        await this.getRaceResult(event_id, venue_id, result);
      }

      resolve(true);
    });
  }

  async getRaceResult(event_id: string, venue_id: string, races: any) {
    return new Promise(async (resolve: any) => {
      let apiURL: string = `${this.config.raceAPI}/getraceresults?ExternalEventId=${event_id}&ExternalVenueId=${venue_id}&${races
        .map((race: any) => {
          return `raceIds%5B%5D=${race['ExternalRaceId']}`;
        })
        .join('&')}`;

      let result: any = await this.apiService.getDataPromis(apiURL, {}, {});

      if (result && typeof result == 'object') {
        races.forEach((race: any) => {
          if (result[race['ExternalRaceId']] != undefined) {
            race['raceStatus'] = result[race['ExternalRaceId']];
          }
        });
      }

      this.races = [...this.races, ...races];
      resolve(true);
    });
  }

  ngOnDestroy(): void {}
}
