<div *ngIf="addcom || isEditMode">
  <div class=" sticky flex-between">
    <div class="flex-start ml-4 mt-3">
      <button class="btn btn-light" (click)="closeEditMode()" *ngIf="readOnlyVar">
        <i class="fa fa-arrow-left " aria-hidden="true"></i>
      </button>
      <button class="btn btn-light" (click)="openModalForConfirm('staticBackdrop')" *ngIf="!readOnlyVar">
        <i class="fa fa-arrow-left " aria-hidden="true"></i>
      </button>
      <h4 class="mb-0 pb-0 font-weight-bold">
        {{ isEditMode ? "Intercity Transport" : "New Company" }}
      </h4>
    </div>
  </div>

  <div class="card mt-3 ml-4 mr-0 box-shadow">
    <ul class="nav nav-tabs d-flex w-100" id="myTab" role="tablist">
      <li class="nav-item flex-fill">
        <a class="nav-link text-center w-100" id="company-details-tab" (click)="onTabChange('company-details')"
          [class.active]="selectedTab === 'company-details'" data-toggle="tab" role="tab"
          aria-controls="company-details" aria-selected="selectedTab === 'company-details'">Company Details</a>
      </li>
      <li class="nav-item flex-fill">
        <a class="nav-link text-center w-100" id="shareholder-tab" (click)="onTabChange('shareholder')"
          [class.active]="selectedTab === 'shareholder'" data-toggle="tab" role="tab" aria-controls="shareholder"
          aria-selected="selectedTab === 'shareholder'">Share Holder</a>
      </li>
      <li class="nav-item flex-fill">
        <a class="nav-link text-center w-100" id="fin-info-tab" (click)="onTabChange('fin-info')"
          [class.active]="selectedTab === 'fin-info'" data-toggle="tab" role="tab" aria-controls="fin-info"
          aria-selected="selectedTab === 'fin-info'">Financial Information</a>
      </li>
      <li class="nav-item flex-fill">
        <a class="nav-link text-center w-100" id="stat-comp-tab" (click)="onTabChange('stat-comp')"
          [class.active]="selectedTab === 'stat-comp'" data-toggle="tab" role="tab" aria-controls="stat-comp"
          aria-selected="selectedTab === 'stat-comp'">Statutory Compliance</a>
      </li>
      <li class="nav-item flex-fill">
        <a class="nav-link text-center w-100" id="auditors-tab" (click)="onTabChange('auditors')"
          [class.active]="selectedTab === 'auditors'" data-toggle="tab" role="tab" aria-controls="auditors"
          aria-selected="selectedTab === 'auditors'">Auditors</a>
      </li>
      <li class="nav-item flex-fill">
        <a class="nav-link text-center w-100" id="char-accountant-tab" (click)="onTabChange('char-accountant')"
          [class.active]="selectedTab === 'char-accountant'" data-toggle="tab" role="tab"
          aria-controls="char-accountant" aria-selected="selectedTab === 'char-accountant'">Chartered Accountants</a>
      </li>
    </ul>


    <div class="tab-content mt-2 fl">
      <div class="tab-content mt-2 fl" [ngStyle]="{display: selectedTab === 'company-details'? 'block' : 'none'}"
        id="company-details" role="tabpanel" aria-labelledby="company-details-tab">
        <form [formGroup]="myForm" autocomplete="off">
          <div class="card-body">
            <div class="form-row justify-content-start">
              <div class="form-group mb-2 col-md-3 fl mr-5">
                <label for="companyName">Company Name*</label>
                <input type="text" id="companyName" class="inputOfForm" formControlName="companyName" />
                <div *ngIf="
                      myForm.get('companyName')?.invalid &&
                      myForm.get('companyName')?.touched
                    ">
                  <span *ngIf="myForm.get('companyName')?.errors?.['required']" class="error-message">Company Name is
                    required</span>
                  <span *ngIf="myForm.get('companyName')?.errors?.['maxlength']" class="error-message">Max length of
                    100.</span>
                </div>
              </div>

              <div class="form-group mb-2 col-md-3 fl mr-5">
                <label for="companyCode">Company Code*</label>
                <input type="text" id="companyCode" formControlName="companyCode" class="inputOfForm"
                  [readonly]="isEditMode" />
                <div *ngIf="
                      myForm.get('companyCode')?.invalid &&
                      myForm.get('companyCode')?.touched
                    ">
                  <span class="error-message">Company Code is required</span>
                </div>
              </div>

              <div class="form-group mb-2 col-md-3 fl mr-5">
                <label for="dateOfIncorporation">Date of Incorporation*</label>
                <input type="date" id="dateOfIncorporation" formControlName="dateOfIncorporation" class="inputOfForm" />
                <div *ngIf="
                      myForm.get('dateOfIncorporation')?.invalid &&
                      myForm.get('dateOfIncorporation')?.touched
                    ">
                  <span class="error-message">Date of Incorporation is required and should be within the last
                    50 years</span>
                </div>
              </div>
            </div>

            <div class="form-row justify-content-start">
              <div class="form-group mb-2 col-md-3 fl mr-5">
                <label for="typeOfCompany">Type of Company*</label>
                <select id="typeOfCompany" formControlName="typeOfCompany">
                  <option value="PVT LTD">PVT LTD</option>
                  <option value="PUBLIC LIMITED">PUBLIC LIMITED</option>
                  <option value="PARTNERSHIP">PARTNERSHIP</option>
                  <option value="PROPRIETORSHIP">PROPRIETORSHIP</option>
                  <option value="LLP">LLP</option>
                </select>
                <div *ngIf="
                      myForm.get('typeOfCompany')?.invalid &&
                      myForm.get('typeOfCompany')?.touched
                    ">
                  <span class="error-message">Type of Company is required</span>
                </div>
              </div>

              <div class="form-group mb-2 col-md-3 fl mr-5">
                <label for="status">Status*</label>
                <select id="status" formControlName="status">
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
                <div *ngIf="
                      myForm.get('status')?.invalid && myForm.get('status')?.touched
                    ">
                  <span class="error-message">Status is required</span>
                </div>
              </div>

              <div class="form-group mb-2 col-md-3 fl mr-5">
                <label for="companyContactNumber">Company Contact Number*</label>
                <input type="text" id="companyContactNumber" formControlName="companyContactNumber"
                  class="inputOfForm" />
                <div *ngIf="myForm.get('companyContactNumber')?.invalid &&
                      myForm.get('companyContactNumber')?.touched
                      ">
                  <span class="error-message" *ngIf="myForm.get('companyContactNumber')?.errors?.['required']">Contact
                    Number
                    is required</span>
                  <span class="error-message" *ngIf="myForm.get('companyContactNumber')?.errors?.['pattern']">Contact
                    Number
                    must contain only numbers and must contain 10 or more digits</span>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>


      <div class="tab-content mt-2 fl card-body" [ngStyle]="{display: selectedTab === 'shareholder'? 'block' : 'none'}"
        id="shareholder" role="tabpanel" aria-labelledby="shareholder-tab">
        <div class="d-flex justify-content-start mb-4" *ngIf="editShareholder">
          <button type="button" class="btn btn-light ml-4" (click)="openModalForConfirm('staticBackDropForSH')">
            <i class="fa fa-arrow-left"></i>
          </button>
          <p class="share-holder-title mb-0">{{ shareholderTitle }}</p>
        </div>

        <div class="ml-4">
          <form [formGroup]="newShareholderForm" (ngSubmit)="onSubmitNewShareholder()" *ngIf="editShareholder"
            autocomplete="off">
            <div class="row">
              <div class="form-group mb-2 col-md-3 fl">
                <label for="new_shareholder_name">Shareholder Name*</label>
                <input id="new_shareholder_name" type="text" formControlName="shareholder_name" class="inputOfForm " />
                <div
                  *ngIf="newShareholderForm.get('shareholder_name')?.invalid && newShareholderForm.get('shareholder_name')?.touched">
                  <span class="error-message" *ngIf="newShareholderForm.get('shareholder_name')?.errors?.['required']">
                    Shareholder Name is required.
                  </span>
                </div>
              </div>
              <div class="form-group mb-2 col-md-3 fl">
                <label for="new_primary_contact_num">Primary Contact Number*</label>
                <input id="new_primary_contact_num" type="text" formControlName="primary_contact_num"
                  class="inputOfForm" />
                <div
                  *ngIf="newShareholderForm.get('primary_contact_num')?.invalid && (newShareholderForm.get('primary_contact_num')?.dirty || newShareholderForm.get('primary_contact_num')?.touched)">
                  <span class="error-message"
                    *ngIf="newShareholderForm.get('primary_contact_num')?.errors?.['required']">
                    Primary Contact Number is required.
                  </span>
                  <span class="error-message"
                    *ngIf="newShareholderForm.get('primary_contact_num')?.errors?.['pattern']">
                    Invalid Primary Contact Number.
                  </span>
                </div>
              </div>
              <div class="form-group mb-2 col-md-3 fl">
                <label for="new_secondary_contact_num">Secondary Contact Number</label>
                <input id="new_secondary_contact_num" type="text" formControlName="secondary_contact_num"
                  class="inputOfForm " />
                <div
                  *ngIf="newShareholderForm.get('secondary_contact_num')?.invalid && (newShareholderForm.get('secondary_contact_num')?.dirty || newShareholderForm.get('secondary_contact_num')?.touched)">
                  <span class="error-message"
                    *ngIf="newShareholderForm.get('secondary_contact_num')?.errors?.['pattern']">
                    Invalid Secondary Contact Number.
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group mb-2 col-md-3 fl">
                <label for="new_din_no">DIN Number*</label>
                <input id="new_din_no" type="text" formControlName="din_no" class="inputOfForm " />
                <div *ngIf="newShareholderForm.get('din_no')?.invalid && newShareholderForm.get('din_no')?.touched">
                  <span class="error-message" *ngIf="newShareholderForm.get('din_no')?.errors?.['required']">
                    DIN Number is required.
                  </span>
                  <span class="error-message"
                    *ngIf="!newShareholderForm.get('din_no')?.errors?.['required'] && newShareholderForm.get('din_no')?.errors?.['invalidDIN']">
                    Invalid DIN number. e.g: 12345678
                  </span>
                </div>
              </div>
              <div class="form-group mb-2 col-md-3 fl">
                <label for="new_pan_no">PAN Number*</label>
                <input id="new_pan_no" type="text" formControlName="pan_no" class="inputOfForm " />
                <div *ngIf="newShareholderForm.get('pan_no')?.invalid && newShareholderForm.get('pan_no')?.touched">
                  <span class="error-message" *ngIf="newShareholderForm.get('pan_no')?.errors?.['required']">
                    PAN Number is required.
                  </span>
                  <span class="error-message"
                    *ngIf="!newShareholderForm.get('pan_no')?.errors?.['required'] && newShareholderForm.get('pan_no')?.errors?.['invalidPan']">
                    PAN Number is invalid.
                  </span>
                </div>
              </div>
              <div class="form-group mb-2 col-md-3 fl">
                <label for="new_aadhar_no">Aadhaar Number*</label>
                <input id="new_aadhar_no" type="text" formControlName="aadhar_no" class="inputOfForm " />
                <div
                  *ngIf="newShareholderForm.get('aadhar_no')?.invalid && newShareholderForm.get('aadhar_no')?.touched">
                  <span class="error-message" *ngIf="newShareholderForm.get('aadhar_no')?.errors?.['required']">
                    Aadhaar Number is required.
                  </span>
                  <span class="error-message"
                    *ngIf="!newShareholderForm.get('aadhar_no')?.errors?.['required'] && newShareholderForm.get('aadhar_no')?.errors?.['invalidAadhaar']">
                    Aadhaar Number is invalid.
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group mb-2 col-md-3 fl">
                <label for="new_status">Status*</label>
                <select id="new_status" formControlName="status">
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
                <div *ngIf="newShareholderForm.get('status')?.invalid && newShareholderForm.get('status')?.touched">
                  <span class="error-message" *ngIf="newShareholderForm.get('status')?.errors?.['required']">
                    Status is required.
                  </span>
                </div>
              </div>
            </div>

            <div class=" d-flex form-group justify-content-end">
              <button type="button" class="btn btn-success-soft clear"
                (click)="openModalForConfirm('staticBackDropForSH')">Clear</button>
              <button type="submit" class="btn svbtn ml-3 mr-4">Save</button>
            </div>
          </form>
        </div>

        <div class="table-responsive mt-4" *ngIf="!editShareholder">

          <div class="d-flex justify-content-between align-items-center mb-3">
            <p class="share-holder-title mb-0 ml-2">Share Holders</p>
            <div class="d-flex ">
              <div class="search-container">
                <i class="fas fa-search p-2 icon-blue"></i>
                <input type="text" placeholder="Search" [(ngModel)]="searchQuery">
              </div>
              <button class="btn new-button ml-3" (click)="initiateAddShareholder()"
                *ngIf="!editShareholder && !readOnlyVar">
                <i class="fa-solid fa-plus"></i> New
              </button>
              <button class="btn btn-success-soft export ml-3 mr-4" *ngIf="isEditMode && !readOnlyVar"
                (click)="shareholdersDataExport()">Export</button>
            </div>
          </div>
          <table class="table">
            <thead>
              <tr>
                <th>Shareholder Name</th>
                <th>Primary Contact Number</th>
                <th>Secondary Contact Number</th>
                <th>DIN Number</th>
                <th>PAN Number</th>
                <th>Aadhar Number</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngIf="filteredShareholders().length === 0">
                <td colspan="8" class="text-center">No Data Found</td>
              </tr>

              <tr *ngFor="let shareholder of filteredShareholders(); let i = index">
                <td>{{ shareholder.shareholder_name }}</td>
                <td>{{ shareholder.primary_contact_num }}</td>
                <td>{{ shareholder.secondary_contact_num }}</td>
                <td>{{ shareholder.din_no }}</td>
                <td>{{ shareholder.pan_no }}</td>
                <td>{{ shareholder.aadhar_no }}</td>
                <td>
                  <span
                    [ngClass]="{'badge-success': shareholder.status === 'Active', 'badge-danger': shareholder.status === 'Inactive'}">
                    {{ shareholder.status }}
                  </span>
                </td>
                <td *ngIf="!readOnlyVar">
                  <button type="button" class="btn btn-light p-1" (click)="onEditShareholder(shareholder,i)">
                    <i class="fas fa-edit"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="tab-content mt-2 fl" [ngStyle]="{display: selectedTab === 'fin-info'? 'block' : 'none'}">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a class="nav-link banks-tab" id="char-accountant-tab" (click)="onSubTabChange('bank')"
              [class.active]="selectedSubTab === 'bank'" data-toggle="tab" role="tab" aria-controls="char-accountant"
              aria-selected="false">Banks</a>
          </li>
          <li class="nav-item">
            <a class="nav-link emis-tab" id="char-accountant-tab" (click)="onSubTabChange('emi')"
              [class.active]="selectedSubTab === 'emi'" data-toggle="tab" role="tab" aria-controls="char-accountant"
              aria-selected="false">EMIs</a>
          </li>
        </ul>

        <div class="tab-content mt-2 fl card-body" [ngStyle]="{display: selectedSubTab === 'bank'? 'block' : 'none'}">
          <div class="d-flex justify-content-start mb-4" *ngIf="editBankAccount">
            <button type="button" class="btn btn-light ml-4" (click)="openModalForConfirm('staticBackDropForBank')">
              <i class="fas fa-arrow-left"></i>
            </button>
            <p class="bank-account-title mb-0">{{ bankAccountTitle }}</p>
          </div>

          <div class="ml-4">
            <form [formGroup]="newBankAccountForm" (ngSubmit)="onSubmitNewBankAccount()" *ngIf="editBankAccount"
              autocomplete="off">
              <div class="row">
                <div class="form-group mb-2 col-md-3 fl">
                  <label for="account_number">Account Number*</label>
                  <input id="account_number" type="text" formControlName="account_number" class="inputOfForm" />
                  <div
                    *ngIf="newBankAccountForm.get('account_number')?.invalid && newBankAccountForm.get('account_number')?.touched">
                    <span class="error-message" *ngIf="newBankAccountForm.get('account_number')?.errors?.['required']">
                      Account Number is required.
                    </span>
                    <span class="error-message"
                      *ngIf="!newBankAccountForm.get('account_number')?.errors?.['required'] && newBankAccountForm.get('account_number')?.invalid">
                      Invalid Account Number. e.g: 123456789012
                    </span>
                  </div>
                </div>
                <div class="form-group mb-2 col-md-3 fl">
                  <label for="ifsc_code">IFSC Code*</label>
                  <input id="ifsc_code" type="text" formControlName="ifsc_code" class="inputOfForm " />
                  <div
                    *ngIf="newBankAccountForm.get('ifsc_code')?.invalid && newBankAccountForm.get('ifsc_code')?.touched">
                    <span class="error-message" *ngIf="newBankAccountForm.get('ifsc_code')?.errors?.['required']">
                      IFSC Code is required.
                    </span>
                    <span class="error-message"
                      *ngIf="!newBankAccountForm.get('ifsc_code')?.errors?.['required'] && newBankAccountForm.get('ifsc_code')?.invalid">
                      Invalid IFSC Code. e.g: ABCD0123456
                    </span>
                  </div>
                </div>
                <div class="form-group mb-2 col-md-3 fl">
                  <label for="bank_name">Bank Name*</label>
                  <input id="bank_name" type="text" formControlName="bank_name" class="inputOfForm " />
                  <div
                    *ngIf="newBankAccountForm.get('bank_name')?.invalid && newBankAccountForm.get('bank_name')?.touched">
                    <span class="error-message" *ngIf="newBankAccountForm.get('bank_name')?.errors?.['required']">
                      Bank Name is required.
                    </span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group mb-2 col-md-3 fl">
                  <label for="bank_address">Bank Address*</label>
                  <input id="bank_address" type="text" formControlName="bank_address" class="inputOfForm " />
                  <div
                    *ngIf="newBankAccountForm.get('bank_address')?.invalid && newBankAccountForm.get('bank_address')?.touched">
                    <span class="error-message" *ngIf="newBankAccountForm.get('bank_address')?.errors?.['required']">
                      Bank Address is required.
                    </span>
                  </div>
                </div>
                <div class="form-group mb-2 col-md-3 fl">
                  <label for="name_as_per_bank_account">Name as per Bank Account*</label>
                  <input id="name_as_per_bank_account" type="text" formControlName="name_as_per_bank_account"
                    class="inputOfForm " />
                  <div
                    *ngIf="newBankAccountForm.get('name_as_per_bank_account')?.invalid && newBankAccountForm.get('name_as_per_bank_account')?.touched">
                    <span class="error-message"
                      *ngIf="newBankAccountForm.get('name_as_per_bank_account')?.errors?.['required']">
                      Name is required.
                    </span>
                  </div>
                </div>
              </div>
              <div class="d-flex form-group justify-content-end">
                <button type="button" class="btn btn-success-soft clear"
                  (click)="openModalForConfirm('staticBackDropForBank')">Clear</button>
                <button type="submit" class="btn svbtn ml-3 mr-4">Save</button>
              </div>
            </form>
          </div>

          <div class="table-responsive mt-4" *ngIf="!editBankAccount">
            <div class="d-flex justify-content-between align-items-center mb-3">
              <p class="bank-account-title mb-0 ml-2">Bank Accounts</p>
              <div class="search-container">
                <i class="fas fa-search p-2 icon-blue"></i>
                <input type="text" placeholder="Search" [(ngModel)]="searchBankAccountQuery">
              </div>
            </div>
            <table class="table">
              <thead>
                <tr>
                  <th>Account Number</th>
                  <th>IFSC Code</th>
                  <th>Bank Name</th>
                  <th>Bank Address</th>
                  <th>Name as per Bank Account</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngIf="filteredBankAccounts().length === 0">
                  <td colspan="12" class="text-center">No Data Found</td>
                </tr>
                <tr *ngFor="let bankAccount of filteredBankAccounts(); let i = index" class="bank-acc-table">
                  <td>{{ bankAccount.account_number }}</td>
                  <td>{{ bankAccount.ifsc_code }}</td>
                  <td>{{ bankAccount.bank_name }}</td>
                  <td>{{ bankAccount.bank_address }}</td>
                  <td>{{ bankAccount.name_as_per_bank_account }}</td>
                  <td class="p-0" *ngIf="!readOnlyVar">
                    <button type="button" class="btn btn-light p-1 mr-5" (click)="onEditBankAccount(bankAccount,i)"
                      *ngIf="!editBankAccount"><i class="fas fa-edit"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <button class="btn add-bank-button ml-2" (click)="initiateAddBankAccount()"
              *ngIf="!editBankAccount && !readOnlyVar"> Add
              Bank
              Account
            </button>
          </div>
        </div>


        <div class=" tab-content mt-2 fl card-body" [ngStyle]="{display: selectedSubTab === 'emi'? 'block' : 'none'}">
          <!-- EMI Content -->
          <div class="d-flex justify-content-start mb-4" *ngIf="editEmi">
            <button type="button" class="btn btn-light ml-4" (click)="openModalForConfirm('staticBackDropForEmi')">
              <i class="fas fa-arrow-left"></i>
            </button>
            <p class="emi-title mb-0">{{ emiTitle }}</p>
          </div>

          <div class="ml-4">
            <form [formGroup]="newEmiForm" (ngSubmit)="onSubmitNewEmi()" *ngIf="editEmi" autocomplete="off">
              <div class="row">
                <div class="form-group mb-2 col-md-3 fl">
                  <label for="vehicle_numbers">Vehicle Numbers*</label>
                  <input id="vehicle_numbers" type="text" formControlName="vehicle_numbers" class="inputOfForm " />
                  <div *ngIf="newEmiForm.get('vehicle_numbers')?.invalid && newEmiForm.get('vehicle_numbers')?.touched">
                    <span class="error-message">Vehicle Numbers are required.</span>
                  </div>
                </div>
                <div class="form-group mb-2 col-md-3 fl">
                  <label for="total_vehicles">Total Vehicles*</label>
                  <input id="total_vehicles" type="text" formControlName="total_vehicles" class="inputOfForm " />
                  <div *ngIf="newEmiForm.get('total_vehicles')?.invalid && newEmiForm.get('total_vehicles')?.touched">
                    <span class="error-message" *ngIf="newEmiForm.get('total_vehicles')?.errors?.['required']">Total
                      Vehicles are
                      required.</span>
                    <span class="error-message"
                      *ngIf="!newEmiForm.get('total_vehicles')?.errors?.['required'] && newEmiForm.get('total_vehicles')?.errors?.['digitsOnly']">Invalid
                      input.
                      Please enter digits only.</span>
                  </div>
                </div>
                <div class="form-group mb-2 col-md-3 fl">
                  <label for="financer">Financer*</label>
                  <input id="financer" type="text" formControlName="financer" class="inputOfForm " />
                  <div *ngIf="newEmiForm.get('financer')?.invalid && newEmiForm.get('financer')?.touched">
                    <span class="error-message" *ngIf="newEmiForm.get('financer')?.errors?.['required']">Financer
                      is
                      required.</span>
                    <span class="error-message"
                      *ngIf="!newEmiForm.get('financer')?.errors?.['required'] && newEmiForm.get('financer')?.errors?.['alphabetOnly']">Invalid
                      input. Please
                      enter alphabets only.</span>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="form-group mb-2 col-md-3 fl">
                  <label for="loan_number">Loan Number*</label>
                  <input type="text" formControlName="loan_number" class="inputOfForm " />
                  <div *ngIf="newEmiForm.get('loan_number')?.invalid && newEmiForm.get('loan_number')?.touched">
                    <span class="error-message" *ngIf="newEmiForm.get('loan_number')?.errors?.['required']">Loan
                      Number is
                      required.</span>
                    <span class="error-message"
                      *ngIf="!newEmiForm.get('loan_number')?.errors?.['required'] && newEmiForm.get('loan_number')?.errors?.['digitsOnly']">Invalid
                      input.
                      Please enter digits only.</span>
                  </div>
                </div>
                <div class="form-group mb-2 col-md-3 fl">
                  <label for="bank_name">Bank Name*</label>
                  <input id="bank_name" type="text" formControlName="bank_name" class="inputOfForm " />
                  <div *ngIf="newEmiForm.get('bank_name')?.invalid && newEmiForm.get('bank_name')?.touched">
                    <span class="error-message" *ngIf="newEmiForm.get('bank_name')?.errors?.['required']">Bank
                      Name is
                      required.</span>
                    <span class="error-message"
                      *ngIf="!newEmiForm.get('bank_name')?.errors?.['required'] && newEmiForm.get('bank_name')?.errors?.['alphabetOnly']">Invalid
                      input.
                      Please enter alphabets only.</span>
                  </div>
                </div>
                <div class="form-group mb-2 col-md-3 fl">
                  <label for="account_number">Account Number*</label>
                  <input id="account_number" type="text" formControlName="account_number" class="inputOfForm " />
                  <div *ngIf="newEmiForm.get('account_number')?.invalid && newEmiForm.get('account_number')?.touched">
                    <span class="error-message" *ngIf="newEmiForm.get('account_number')?.errors?.['required']">Account
                      Number is
                      required.</span>
                    <span class="error-message"
                      *ngIf="!newEmiForm.get('account_number')?.errors?.['required'] && newEmiForm.get('account_number')?.errors?.['digitsOnly']">Invalid
                      input.
                      Please enter digits only.</span>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="form-group mb-2 col-md-3 fl">
                  <label for="loan_amount">Loan Amount*</label>
                  <input id="loan_amount" type="text" formControlName="loan_amount" class="inputOfForm " />
                  <div *ngIf="newEmiForm.get('loan_amount')?.invalid && newEmiForm.get('loan_amount')?.touched">
                    <span class="error-message" *ngIf="newEmiForm.get('loan_amount')?.errors?.['required']">Loan
                      Amount is
                      required.</span>
                    <span class="error-message"
                      *ngIf="!newEmiForm.get('loan_amount')?.errors?.['required'] && newEmiForm.get('loan_amount')?.errors?.['digitsOnly']">Invalid
                      input.
                      Please enter digits only.</span>
                  </div>
                </div>
                <div class="form-group mb-2 col-md-3 fl">
                  <label for="interest_amount">Interest Amount*</label>
                  <input id="interest_amount" type="text" formControlName="interest_amount" class="inputOfForm " />
                  <div *ngIf="newEmiForm.get('interest_amount')?.invalid && newEmiForm.get('interest_amount')?.touched">
                    <span class="error-message" *ngIf="newEmiForm.get('interest_amount')?.errors?.['required']">Interest
                      Amount is
                      required.</span>
                    <span class="error-message"
                      *ngIf="!newEmiForm.get('interest_amount')?.errors?.['required'] && newEmiForm.get('interest_amount')?.errors?.['digitsOnly']">Invalid
                      input.
                      Please enter digits only.</span>
                  </div>
                </div>
                < <div class="form-group mb-2 col-md-3 fl">
                  <label for="total_amount">Total Amount*</label>
                  <input id="total_amount" type="text" formControlName="total_amount" class="inputOfForm " />
                  <div *ngIf="newEmiForm.get('total_amount')?.invalid && newEmiForm.get('total_amount')?.touched">
                    <span class="error-message" *ngIf="newEmiForm.get('total_amount')?.errors?.['required']">Total
                      Amount is
                      required.</span>
                    <span class="error-message"
                      *ngIf="!newEmiForm.get('total_amount')?.errors?.['required'] && newEmiForm.get('total_amount')?.errors?.['digitsOnly']">Invalid
                      input.
                      Please enter digits only.</span>
                  </div>
              </div>
          </div>

          <div class="row">
            <div class="form-group mb-2 col-md-3 fl">
              <label for="flat_rate">Flat Rate*</label>
              <input id="flat_rate" type="text" formControlName="flat_rate" class="inputOfForm " />
              <div *ngIf="newEmiForm.get('flat_rate')?.invalid && newEmiForm.get('flat_rate')?.touched">
                <span class="error-message">Flat Rate is required.</span>
              </div>
            </div>
            <div class="form-group mb-2 col-md-3 fl">
              <label for="no_of_paid_months">No. of Paid Months*</label>
              <input id="no_of_paid_months" type="text" formControlName="no_of_paid_months" class="inputOfForm " />
              <div *ngIf="newEmiForm.get('no_of_paid_months')?.invalid && newEmiForm.get('no_of_paid_months')?.touched">
                <span class="error-message" *ngIf="newEmiForm.get('no_of_paid_months')?.errors?.['required']">No.
                  of Paid
                  Months are required.</span>
                <span class="error-message"
                  *ngIf="!newEmiForm.get('no_of_paid_months')?.errors?.['required'] && newEmiForm.get('no_of_paid_months')?.errors?.['digitsOnly']">Invalid
                  input.
                  Please enter digits only.</span>
              </div>
            </div>
            <div class="form-group mb-2 col-md-3 fl">
              <label for="no_of_due_months">No. of Due Months*</label>
              <input id="no_of_due_months" type="text" formControlName="no_of_due_months" class="inputOfForm " />
              <div *ngIf="newEmiForm.get('no_of_due_months')?.invalid && newEmiForm.get('no_of_due_months')?.touched">
                <span class="error-message" *ngIf="newEmiForm.get('no_of_due_months')?.errors?.['required']">No.
                  of Due Months
                  are required.</span>
                <span class="error-message"
                  *ngIf="!newEmiForm.get('no_of_due_months')?.errors?.['required'] && newEmiForm.get('no_of_due_months')?.errors?.['digitsOnly']">Invalid
                  input.
                  Please enter digits only.</span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="form-group mb-2 col-md-3 fl">
              <label for="total_installments">Total Installments*</label>
              <input id="total_installments" type="text" formControlName="total_installments" class="inputOfForm " />
              <div
                *ngIf="newEmiForm.get('total_installments')?.invalid && newEmiForm.get('total_installments')?.touched">
                <span class="error-message" *ngIf="newEmiForm.get('total_installments')?.errors?.['required']">Total
                  Installments are required.</span>
                <span class="error-message"
                  *ngIf="!newEmiForm.get('total_installments')?.errors?.['required'] && newEmiForm.get('total_installments')?.errors?.['digitsOnly']">Invalid
                  input. Please enter digits only.</span>
              </div>
            </div>
            <div class="form-group mb-2 col-md-3 fl">
              <label for="emi_starting_date">EMI Starting Date*</label>
              <input id="emi_starting_date" type="date" formControlName="emi_starting_date" class="inputOfForm " />
              <div *ngIf="newEmiForm.get('emi_starting_date')?.invalid && newEmiForm.get('emi_starting_date')?.touched">
                <span class="error-message">EMI Starting Date is required.</span>
              </div>
            </div>
            <div class="form-group mb-2 col-md-3 fl">
              <label for="emi_ending_date">EMI Ending Date*</label>
              <input id="emi_ending_date" type="date" formControlName="emi_ending_date" class="inputOfForm " />
              <div *ngIf="newEmiForm.get('emi_ending_date')?.invalid && newEmiForm.get('emi_ending_date')?.touched">
                <span class="error-message">EMI Ending Date is required.</span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="form-group mb-2 col-md-3 fl">
              <label for="total_emi">Total EMI*</label>
              <input id="total_emi" type="text" formControlName="total_emi" class="inputOfForm " />
              <div *ngIf="newEmiForm.get('total_emi')?.invalid && newEmiForm.get('total_emi')?.touched">
                <span class="error-message" *ngIf="newEmiForm.get('total_emi')?.errors?.['required']">Total
                  EMI is
                  required.</span>
                <span class="error-message"
                  *ngIf="!newEmiForm.get('total_emi')?.errors?.['required'] && newEmiForm.get('total_emi')?.errors?.['digitsOnly']">Invalid
                  input. Please
                  enter digits only.</span>
              </div>
            </div>
            <div class="form-group mb-2 col-md-3 fl">
              <label for="total_outstanding">Total Outstanding*</label>
              <input id="total_outstanding" type="text" formControlName="total_outstanding" class="inputOfForm " />
              <div *ngIf="newEmiForm.get('total_outstanding')?.invalid && newEmiForm.get('total_outstanding')?.touched">
                <span class="error-message" *ngIf="newEmiForm.get('total_outstanding')?.errors?.['required']">Total
                  Outstanding is required.</span>
                <span class="error-message"
                  *ngIf="!newEmiForm.get('total_outstanding')?.errors?.['required'] && newEmiForm.get('total_outstanding')?.errors?.['digitsOnly']">Invalid
                  input.
                  Please enter digits only.</span>
              </div>
            </div>
            <div class="form-group mb-2 col-md-3 fl">
              <label for="emi_date">EMI Date*</label>
              <input id="emi_date" type="date" formControlName="emi_date" class="inputOfForm " />
              <div *ngIf="newEmiForm.get('emi_date')?.invalid && newEmiForm.get('emi_date')?.touched">
                <span class="error-message">EMI Date is required.</span>
              </div>
            </div>
          </div>

          <div class="d-flex form-group justify-content-end">
            <button type="button" class="btn btn-success-soft clear"
              (click)="openModalForConfirm('staticBackDropForEmi')">Clear</button>
            <button type="submit" class="btn svbtn ml-3 mr-4">Save</button>
          </div>
          </form>

        </div>

        <div class="container-fluid mt-4" *ngIf="!editEmi">
          <div>
            <div class="row justify-content-between align-items-center mb-3">
              <div class="col-auto">
                <p class="emi-title mb-0 ml-2">EMIs</p>
              </div>
              <div class="col-auto">
                <div class="d-flex">
                  <div class="search-container">
                    <i class="fas fa-search p-2 icon-blue"></i>
                    <input type="text" placeholder="Search" [(ngModel)]="searchEmiQuery">
                  </div>
                  <button class="btn new-button ml-3" (click)="initiateAddEmi()" *ngIf="!editEmi && !readOnlyVar">
                    <i class="fa-solid fa-plus"></i> New
                  </button>
                  <button class="btn btn-success-soft export ml-3 mr-4" *ngIf="isEditMode && !readOnlyVar"
                    (click)="emiDataExport()">Export</button>
                </div>
              </div>
            </div>
          </div>

          <div class="table-responsive-scrollable">
            <table class="table emi-table">
              <thead>
                <tr>
                  <th class="sticky-column custom-width shadow-to-table-col">Vehicle Numbers</th>
                  <th class="custom-width">Total Vehicles</th>
                  <th class="custom-width">Financer</th>
                  <th class="custom-width">Loan Number</th>
                  <th class="custom-width">Bank Name</th>
                  <th class="custom-width">Account Number</th>
                  <th class="custom-width">Loan Amount</th>
                  <th class="custom-width">Interest Amount</th>
                  <th class="custom-width">Total Amount</th>
                  <th class="custom-width">Flat Rate</th>
                  <th class="custom-width">No. of Paid Months</th>
                  <th class="custom-width">No. of Due Months</th>
                  <th class="custom-width">Total Installments</th>
                  <th class="custom-width">EMI Starting Date</th>
                  <th class="custom-width">EMI Ending Date</th>
                  <th class="custom-width">Total EMI</th>
                  <th class="custom-width">Total Outstanding</th>
                  <th class="custom-width">EMI Date</th>
                  <th class="custom-width"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngIf="filteredEmis().length === 0">
                  <td colspan="12" class="text-center">No Data Found</td>
                </tr>
                <tr *ngFor="let emi of filteredEmis(); let i = index">
                  <td class="sticky-column custom-width shadow-to-table-col">{{ emi.vehicle_numbers }}</td>
                  <td class="custom-width">{{ emi.total_vehicles }}</td>
                  <td class="custom-width">{{ emi.financer }}</td>
                  <td class="custom-width">{{ emi.loan_number }}</td>
                  <td class="custom-width">{{ emi.bank_name }}</td>
                  <td class="custom-width">{{ emi.account_number }}</td>
                  <td class="custom-width">{{ emi.loan_amount }}</td>
                  <td class="custom-width">{{ emi.interest_amount }}</td>
                  <td class="custom-width">{{ emi.total_amount }}</td>
                  <td class="custom-width">{{ emi.flat_rate }}</td>
                  <td class="custom-width">{{ emi.no_of_paid_months }}</td>
                  <td class="custom-width">{{ emi.no_of_due_months }}</td>
                  <td class="custom-width">{{ emi.total_installments }}</td>
                  <td class="custom-width">{{ emi.emi_starting_date }}</td>
                  <td class="custom-width">{{ emi.emi_ending_date }}</td>
                  <td class="custom-width">{{ emi.total_emi }}</td>
                  <td class="custom-width">{{ emi.total_outstanding }}</td>
                  <td class="custom-width">{{ emi.emi_date }}</td>
                  <td class="custom-width" *ngIf="!readOnlyVar">
                    <button type="button" class="btn btn-light p-1" (click)="onEditEmi(emi, i)">
                      <i class="fas fa-edit"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>

      </div>

    </div>

    <div class="tab-content mt-2 fl" [ngStyle]="{display: selectedTab === 'stat-comp'? 'block' : 'none'}">
      <!-- Shareholder Content -->
      <form [formGroup]="myForm" autocomplete="off">
        <div class="card-body">
          <h5 class="font-weight-bold mt-3">GST</h5>
          <div class="form-row justify-content-start mt-3">
            <div class="form-group mb-2 col-md-3 fl mr-2">
              <label for="gstNumber">GST Number*</label>
              <input type="text" id="gstNumber" formControlName="gstNumber" class="inputOfForm" />
              <div *ngIf="
                        myForm.get('gstNumber')?.invalid &&
                        myForm.get('gstNumber')?.touched
                      ">
                <span class="error-message" *ngIf="myForm.get('gstNumber')?.errors?.['required']">GST Number is
                  required</span>
                <span class="error-message"
                  *ngIf="!myForm.get('gstNumber')?.errors?.['required'] && myForm.get('gstNumber')?.errors?.['invalidGstin']">Invalid
                  GST Number. e.g:27ABCDE1234F1Z5</span>
              </div>
            </div>



            <div class="form-group mb-2 col-md-3 fl mr-2">
              <label>Upload*</label>
              <div>
                <span [ngStyle]="{display: !(isEditMode && !updated['gst_cert']) ? 'inline' : 'none'}">
                  <input type="file" id="gstCertificateUpload" #gstCertificateIn
                    (change)="onFileSelected('gstCertificateUpload', 'gst_cert', $event, true, gstCertificateIn); gstCertificateTouched = true"
                    class="upldFile" />
                  <button *ngIf="(gstCertificateIn.files)?.length == 0 && !isEditMode" class="  btn btn-primary  ml-4  "
                    type="button" (click)="gstCertificateIn.click()" [disabled]="readOnlyVar">
                    <i class="fa-solid fa-magnifying-glass mrgn-r-10"></i>Browse File
                  </button>

                  <div *ngIf="!updateForm">
                    <div
                      *ngIf="(gstCertificateIn.files || []).length > 0 && updated['gst_cert'] && progress['gst_cert']">
                      <div class="progress">
                        <div class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100"
                          [style.width.%]="progressValue"></div>
                      </div>
                      <p class="upldTxt">uploading...</p>
                    </div>
                    <div class="button-container"
                      *ngIf="(gstCertificateIn.files || []).length > 0 && updated['gst_cert'] && !progress['gst_cert']">
                      <span class="del" (click)="deleteDocument('gst_cert', gstCertificateIn)">Delete</span>
                      <div class="line"></div>
                      <span class="pre" (click)="previewDocument(gstCertificateIn, 'gst_cert')">Preview</span>
                    </div>
                  </div>
                </span>

                <ng-container *ngIf="isEditMode">
                  <input class="upldFile" type="file" id="gst_cert"
                    (change)="onFileSelected('gstCertificateUpload', 'gst_cert', $event, true, gstCertificateIn); gstCertificateTouched = true"
                    #gstCertificateIn />
                  <button *ngIf="this.fileNames[4] === undefined  && !updated['gst_cert']"
                    class="  btn btn-primary ml-4   " type="button" (click)="gstCertificateIn.click()"
                    [disabled]="readOnlyVar">
                    <i class="fa-solid fa-magnifying-glass mrgn-r-10 "></i>Browse File
                  </button>
                  <span *ngIf="this.fileNames[4] !== undefined || updated['gst_cert']">
                    <div *ngIf="updateForm">
                      <div *ngIf="progress['gst_cert']">
                        <div class="progress">
                          <div class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100"
                            [style.width.%]="progressValue"></div>
                        </div>
                        <p class="upldTxt">uploading...</p>
                      </div>
                      <div class="button-container" *ngIf="!progress['gst_cert']">
                        <span class="del" (click)="deleteDocumentInUpdate('gst_cert')">Delete</span>
                        <div class="line"></div>
                        <span class="pre" (click)="previewDocument(gstCertificateIn, 'gst_cert')">Preview</span>
                      </div>
                    </div>
                  </span>
                </ng-container>
                <div>
                  <span class="file-size-info">Max File size is 20MB</span>
                </div>
              </div>
              <span *ngIf="documentInputErrors['gstCertificateUpload']" class="error-message">
                {{ documentInputErrors["gstCertificateUpload"] }}
              </span>
              <div
                *ngIf="!isEditMode && myForm.get('gstCertificateUpload')?.invalid && myForm.get('gstCertificateUpload')?.touched">
                <span class="error-message">Upload PAN Card.</span>
              </div>
            </div>


            <div class="form-group mb-2 col-md-3 fl mr-2">
              <label for="panNumber">PAN Number*</label>
              <input type="text" id="panNumber" formControlName="panNumber" class="inputOfForm" />

              <div *ngIf="myForm.get('panNumber')?.invalid && myForm.get('panNumber')?.touched">
                <span class="error-message" *ngIf="myForm.get('panNumber')?.errors?.['required']">PAN Number is
                  required</span>
                <span class="error-message"
                  *ngIf="!myForm.get('panNumber')?.errors?.['required'] && myForm.get('panNumber')?.errors?.['invalidPan']">Invalid
                  PAN Number. e.g :ABCDE7654G</span>
              </div>
            </div>


            <div class="form-group mb-2 col-md-2 fl mr-2">
              <label>Upload*</label>
              <div>
                <span [ngStyle]="{display: !(isEditMode && !updated['pan_card']) ? 'inline' : 'none'}">
                  <input type="file" id="panCardUpload" #panCardIn
                    (change)="onFileSelected('panCardUpload', 'pan_card', $event, true, panCardIn); panCardTouched = true"
                    class="upldFile" />
                  <button *ngIf="(panCardIn.files)?.length == 0 && !isEditMode" class="  btn btn-primary  ml-4  "
                    type="button" (click)="panCardIn.click()" [disabled]="readOnlyVar">
                    <i class="fa-solid fa-magnifying-glass mrgn-r-10 "></i>Browse File
                  </button>
                  <div *ngIf="!updateForm">
                    <div *ngIf="(panCardIn.files || []).length > 0 && updated['pan_card'] && progress['pan_card']">
                      <div class="progress">
                        <div class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100"
                          [style.width.%]="progressValue"></div>
                      </div>
                      <p class="upldTxt">uploading...</p>
                    </div>
                    <div class="button-container"
                      *ngIf="(panCardIn.files || []).length > 0 && updated['pan_card'] && !progress['pan_card']">
                      <span class="del" (click)="deleteDocument('pan_card', panCardIn)">Delete</span>
                      <div class="line"></div>
                      <span class="pre" (click)="previewDocument(panCardIn, 'pan_card')">Preview</span>
                    </div>
                  </div>
                </span>
                <ng-container *ngIf="isEditMode">
                  <input class="upldFile" type="file" id="pan_card"
                    (change)="onFileSelected('panCardUpload', 'pan_card', $event, true, panCardIn); panCardTouched = true"
                    #panCardIn />
                  <button *ngIf="this.fileNames[5] === undefined && !updated['pan_card']"
                    class="  btn btn-primary ml-4   " type="button" (click)="panCardIn.click()"
                    [disabled]="readOnlyVar">
                    <i class="fa-solid fa-magnifying-glass mrgn-r-10 "></i>Browse File
                  </button>
                  <span *ngIf="this.fileNames[5] !== undefined || updated['pan_card']">
                    <div *ngIf="updateForm">
                      <div *ngIf="progress['pan_card']">
                        <div class="progress">
                          <div class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100"
                            [style.width.%]="progressValue"></div>
                        </div>
                        <p class="upldTxt">uploading...</p>
                      </div>
                      <div class="button-container" *ngIf="!progress['pan_card']">
                        <span class="del" (click)="deleteDocumentInUpdate('pan_card')">Delete</span>
                        <div class="line"></div>
                        <span class="pre" (click)="previewDocument(panCardIn, 'pan_card')">Preview</span>
                      </div>
                    </div>
                  </span>
                </ng-container>
                <div>
                  <span class="file-size-info">Max File size is 20MB</span>
                </div>
              </div>

              <span *ngIf="documentInputErrors['panCardUpload']" class="error-message">
                {{ documentInputErrors["panCardUpload"] }}
              </span>
              <div *ngIf="!isEditMode && myForm.get('panCardUpload')?.invalid && myForm.get('panCardUpload')?.touched">
                <span class="error-message">Upload PAN Card.</span>
              </div>
            </div>
          </div>

          <h5 class="font-weight-bold mt-3">Labour License</h5>
          <div class="form-row justify-content-start mt-3">
            <div class="form-group mb-2 col-md-3 fl mr-2">
              <label for="labourLicenseRegistrationNumber">Registration Number*</label>
              <input type="text" id="labourLicenseRegistrationNumber" formControlName="labourLicenseRegistrationNumber"
                class="inputOfForm" />
              <!-- Add error message if needed -->
              <div *ngIf="
                              myForm.get('labourLicenseRegistrationNumber')?.invalid &&
                              myForm.get('labourLicenseRegistrationNumber')?.touched
                            ">
                <span class="error-message">Registration Number is required</span>
              </div>
            </div>

            <div class="form-group mb-2 col-md-3 fl mr-2">
              <label for="labourLicenseDateOfIssue">Date of Issue*</label>
              <input type="date" id="labourLicenseDateOfIssue" formControlName="labourLicenseDateOfIssue" (change)="
                              updateEndDateMin(
                                'labourLicenseDateOfIssue',
                                'labourLicenseExpiryDate'
                              ) 
                            " class="inputOfForm" />
              <!-- Add error message if needed -->
              <div *ngIf="
                              myForm.get('labourLicenseDateOfIssue')?.invalid &&
                              myForm.get('labourLicenseDateOfIssue')?.touched
                            ">
                <span class="error-message">Date of Issue is required</span>
              </div>
            </div>

            <div class="form-group mb-2 col-md-3 fl mr-2">
              <label for="labourLicenseExpiryDate">Expiry Date*</label>
              <input type="date" id="labourLicenseExpiryDate" formControlName="labourLicenseExpiryDate"
                [min]="myForm.get('labourLicenseDateOfIssue')?.value" class="inputOfForm" />
              <!-- Add error message if needed -->
              <div *ngIf="
                              myForm.get('labourLicenseExpiryDate')?.invalid &&
                              myForm.get('labourLicenseExpiryDate')?.touched
                            ">
                <span class="error-message">Expiry Date is required</span>
              </div>
            </div>


            <div class="form-group mb-2 col-md-2 fl mr-0">
              <label>Upload*</label>
              <div>
                <span [ngStyle]="{display: !(isEditMode && !updated['comp_labr_lic']) ? 'inline' : 'none'}">
                  <input type="file" id="labourLicenseUpload" #labourLicenseIn
                    (change)="onFileSelected('labourLicenseUpload', 'comp_labr_lic', $event, true, labourLicenseIn); labourLicenseTouched = true"
                    class="upldFile" />
                  <button *ngIf="(labourLicenseIn.files)?.length == 0 && !isEditMode" class="  btn btn-primary   ml-4 "
                    type="button" (click)="labourLicenseIn.click()" [disabled]="readOnlyVar">
                    <i class="fa-solid fa-magnifying-glass mrgn-r-10 "></i>Browse File
                  </button>
                  <div *ngIf="!updateForm">
                    <div
                      *ngIf="(labourLicenseIn.files || []).length > 0 && updated['comp_labr_lic'] && progress['comp_labr_lic']">
                      <div class="progress">
                        <div class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100"
                          [style.width.%]="progressValue"></div>
                      </div>
                      <p class="upldTxt">uploading...</p>
                    </div>
                    <div class="button-container"
                      *ngIf="(labourLicenseIn.files || []).length > 0 && updated['comp_labr_lic'] && !progress['comp_labr_lic']">
                      <span class="del" (click)="deleteDocument('comp_labr_lic', labourLicenseIn)">Delete</span>
                      <div class="line"></div>
                      <span class="pre" (click)="previewDocument(labourLicenseIn, 'comp_labr_lic')">Preview</span>
                    </div>
                  </div>
                </span>
                <ng-container *ngIf="isEditMode">
                  <input class="upldFile" type="file" id="comp_labr_lic"
                    (change)="onFileSelected('labourLicenseUpload', 'comp_labr_lic', $event, true, labourLicenseIn); labourLicenseTouched = true"
                    #labourLicenseIn />
                  <button *ngIf="this.fileNames[0] === undefined && !updated['comp_labr_lic']"
                    class="  btn btn-primary ml-4   " type="button" (click)="labourLicenseIn.click()"
                    [disabled]="readOnlyVar">
                    <i class="fa-solid fa-magnifying-glass mrgn-r-10 "></i>Browse File
                  </button>
                  <span *ngIf="this.fileNames[0] !== undefined || updated['comp_labr_lic']">
                    <div *ngIf="updateForm">
                      <div *ngIf="progress['comp_labr_lic']">
                        <div class="progress">
                          <div class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100"
                            [style.width.%]="progressValue"></div>
                        </div>
                        <p class="upldTxt">uploading...</p>
                      </div>
                      <div class="button-container" *ngIf="!progress['comp_labr_lic']">
                        <span class="del" (click)="deleteDocumentInUpdate('comp_labr_lic')">Delete</span>
                        <div class="line"></div>
                        <span class="pre" (click)="previewDocument(labourLicenseIn, 'comp_labr_lic')">Preview</span>
                      </div>
                    </div>
                  </span>
                </ng-container>
                <div>
                  <span class="file-size-info">Max File size is 20MB</span>
                </div>
              </div>
              <span *ngIf="documentInputErrors['labourLicenseUpload']" class="error-message">
                {{ documentInputErrors["labourLicenseUpload"] }}
              </span>
              <div
                *ngIf="!isEditMode && myForm.get('labourLicenseUpload')?.invalid && myForm.get('labourLicenseUpload')?.touched">
                <span class="error-message">Upload Licence.</span>
              </div>
            </div>
          </div>

          <h5 class="font-weight-bold mt-3">Professional Tax</h5>
          <div class="form-row justify-content-start mt-3">
            <div class="form-group mb-2 col-md-3 fl mr-2">
              <label for="ptNumber">PT Number*</label>
              <input type="text" id="ptNumber" formControlName="ptNumber" class="inputOfForm" />
              <!-- Add error message if needed -->
              <div *ngIf="
                          myForm.get('ptNumber')?.invalid && myForm.get('ptNumber')?.touched
                        ">
                <span class="error-message">PT Number is required.</span>
              </div>
            </div>

            <div class="form-group mb-2 col-md-3 fl mr-2">
              <label for="ptExpiryDate">Date of Issue*</label>
              <input type="date" id="ptExpiryDate" formControlName="ptExpiryDate" class="inputOfForm" />
              <!-- Add error message if needed -->
              <div *ngIf="
                          myForm.get('ptExpiryDate')?.invalid &&
                          myForm.get('ptExpiryDate')?.touched
                        ">
                <span class="error-message">PT Date of Issue is required.</span>
              </div>
            </div>

            <div class="form-group mb-2 col-md-3 fl mr-0">
              <label>Upload*</label>
              <div>
                <span [ngStyle]="{display: !(isEditMode && !updated['prof_tax_cert']) ? 'inline' : 'none'}">
                  <input type="file" id="ptCertificateUpload" #ptCertificateIn
                    (change)="onFileSelected('ptCertificateUpload', 'prof_tax_cert', $event, true, ptCertificateIn); ptCertificateTouched = true"
                    class="upldFile" />
                  <button *ngIf="(ptCertificateIn.files)?.length == 0 && !isEditMode" class="  btn btn-primary   ml-4 "
                    type="button" (click)="ptCertificateIn.click()" [disabled]="readOnlyVar">
                    <i class="fa-solid fa-magnifying-glass mrgn-r-10 "></i>Browse File
                  </button>
                  <div *ngIf="!updateForm">
                    <div
                      *ngIf="(ptCertificateIn.files || []).length > 0 && updated['prof_tax_cert'] && progress['prof_tax_cert']">
                      <div class="progress">
                        <div class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100"
                          [style.width.%]="progressValue"></div>
                      </div>
                      <p class="upldTxt">uploading...</p>
                    </div>
                    <div class="button-container"
                      *ngIf="(ptCertificateIn.files || []).length > 0 && updated['prof_tax_cert'] && !progress['prof_tax_cert']">
                      <span class="del" (click)="deleteDocument('prof_tax_cert',ptCertificateIn)">Delete</span>
                      <div class="line"></div>
                      <span class="pre" (click)="previewDocument(ptCertificateIn, 'prof_tax_cert')">Preview</span>
                    </div>
                  </div>
                </span>
                <ng-container *ngIf="isEditMode">
                  <input class="upldFile" type="file" id="prof_tax_cert"
                    (change)="onFileSelected('ptCertificateUpload', 'prof_tax_cert', $event, true, ptCertificateIn); ptCertificateTouched = true"
                    #ptCertificateIn />
                  <button *ngIf="this.fileNames[1] === undefined && !updated['prof_tax_cert']"
                    class="  btn btn-primary ml-4   " type="button" (click)="ptCertificateIn.click()"
                    [disabled]="readOnlyVar">
                    <i class="fa-solid fa-magnifying-glass mrgn-r-10 "></i>Browse File
                  </button>
                  <span *ngIf="this.fileNames[1] !== undefined || updated['prof_tax_cert']">
                    <div *ngIf="updateForm">
                      <div *ngIf="progress['prof_tax_cert']">
                        <div class="progress">
                          <div class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100"
                            [style.width.%]="progressValue"></div>
                        </div>
                        <p class="upldTxt">uploading...</p>
                      </div>
                      <div class="button-container" *ngIf="!progress['prof_tax_cert']">
                        <span class="del" (click)="deleteDocumentInUpdate('prof_tax_cert')">Delete</span>
                        <div class="line"></div>
                        <span class="pre" (click)="previewDocument(ptCertificateIn, 'prof_tax_cert')">Preview</span>
                      </div>
                    </div>
                  </span>
                </ng-container>
                <div>
                  <span class="file-size-info">Max File size is 20MB</span>
                </div>
              </div>
              <span *ngIf="documentInputErrors['ptCertificateUpload']" class="error-message">
                {{ documentInputErrors["ptCertificateUpload"] }}
              </span>
              <div
                *ngIf="!isEditMode && myForm.get('ptCertificateUpload')?.invalid && myForm.get('ptCertificateUpload')?.touched">
                <span class="error-message">Upload Certificate.</span>
              </div>
            </div>

          </div>

          <h5 class="font-weight-bold mt-3">Udhayam Certificate</h5>
          <div class="form-row justify-content-start mt-3">
            <div class="form-group mb-2 col-md-3 fl mr-2">
              <label for="udyamRegistrationNumber">Registration Number*</label>
              <input type="text" id="udyamRegistrationNumber" formControlName="udyamRegistrationNumber"
                class="inputOfForm" />
              <!-- Add error message if needed -->
              <div *ngIf="
                            myForm.get('udyamRegistrationNumber')?.invalid &&
                            myForm.get('udyamRegistrationNumber')?.touched
                          ">
                <span class="error-message">Registration Number is required.</span>
              </div>
            </div>

            <div class="form-group mb-2 col-md-3 fl mr-2">
              <label for="udyamExpiryDate">Date of Issue*</label>
              <input type="date" id="udyamExpiryDate" formControlName="udyamExpiryDate" class="inputOfForm" />
              <!-- Add error message if needed -->
              <div *ngIf="
                            myForm.get('udyamExpiryDate')?.invalid &&
                            myForm.get('udyamExpiryDate')?.touched
                          ">
                <span class="error-message">Date of Issue is required.</span>
              </div>
            </div>

            <div class="form-group mb-2 col-md-3 fl mr-0">
              <label>Upload*</label>
              <div>
                <span [ngStyle]="{display: !(isEditMode && !updated['udyam_cert']) ? 'inline' : 'none'}">
                  <input type="file" id="udyamCertificateUpload" #udyamCertificateIn
                    (change)="onFileSelected('udyamCertificateUpload', 'udyam_cert', $event, true, udyamCertificateIn); udyamCertificateTouched = true"
                    class="upldFile" />
                  <button *ngIf="(udyamCertificateIn.files)?.length == 0 && !isEditMode"
                    class="  btn btn-primary  ml-4  " type="button" (click)="udyamCertificateIn.click()"
                    [disabled]="readOnlyVar">
                    <i class="fa-solid fa-magnifying-glass mrgn-r-10 "></i>Browse File
                  </button>
                  <div *ngIf="!updateForm">
                    <div
                      *ngIf="(udyamCertificateIn.files || []).length > 0 && updated['udyam_cert'] && progress['udyam_cert']">
                      <div class="progress">
                        <div class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100"
                          [style.width.%]="progressValue"></div>
                      </div>
                      <p class="upldTxt">uploading...</p>
                    </div>
                    <div class="button-container"
                      *ngIf="(udyamCertificateIn.files || []).length > 0 && updated['udyam_cert'] && !progress['udyam_cert']">
                      <span class="del" (click)="deleteDocument('udyam_cert', udyamCertificateIn)">Delete</span>
                      <div class="line"></div>
                      <span class="pre" (click)="previewDocument(udyamCertificateIn, 'udyam_cert')">Preview</span>
                    </div>
                  </div>
                </span>
                <ng-container *ngIf="isEditMode">
                  <input class="upldFile" type="file" id="udyam_cert"
                    (change)="onFileSelected('udyamCertificateUpload', 'udyam_cert', $event, true, udyamCertificateIn); udyamCertificateTouched = true"
                    #udyamCertificateIn />
                  <button *ngIf="this.fileNames[2] === undefined && !updated['udyam_cert']"
                    class=" btn btn-primary ml-4   " type="button" (click)="udyamCertificateIn.click()"
                    [disabled]="readOnlyVar">
                    <i class="fa-solid fa-magnifying-glass mrgn-r-10"></i>Browse File
                  </button>
                  <span *ngIf="this.fileNames[2] !== undefined || updated['udyam_cert']">
                    <div *ngIf="updateForm">
                      <div *ngIf="progress['udyam_cert']">
                        <div class="progress">
                          <div class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100"
                            [style.width.%]="progressValue"></div>
                        </div>
                        <p class="upldTxt">uploading...</p>
                      </div>
                      <div class="button-container" *ngIf="!progress['udyam_cert']">
                        <span class="del" (click)="deleteDocumentInUpdate('udyam_cert')">Delete</span>
                        <div class="line"></div>
                        <span class="pre" (click)="previewDocument(udyamCertificateIn, 'udyam_cert')">Preview</span>
                      </div>
                    </div>
                  </span>
                </ng-container>
                <div>
                  <span class="file-size-info">Max File size is 20MB</span>
                </div>
              </div>
              <span *ngIf="documentInputErrors['udyamCertificateUpload']" class="error-message">
                {{ documentInputErrors["udyamCertificateUpload"] }}
              </span>
              <div
                *ngIf="!isEditMode && myForm.get('udyamCertificateUpload')?.invalid && myForm.get('udyamCertificateUpload')?.touched">
                <span class="error-message">Upload Certificate.</span>
              </div>
            </div>

          </div>

          <h5 class="font-weight-bold mt-3">MTW</h5>
          <div class="form-row justify-content-start mt-3">
            <div class="form-group mb-2 col-md-3 fl mr-2">
              <label for="mtwRegistrationNumber">Registration Number*</label>
              <input type="text" id="mtwRegistrationNumber" formControlName="mtwRegistrationNumber"
                class="inputOfForm" />
              <!-- Add error message if needed -->
              <div *ngIf="
              myForm.get('mtwRegistrationNumber')?.invalid &&
              myForm.get('mtwRegistrationNumber')?.touched
            ">
                <span class="error-message">MTW Registration Number is required.</span>
              </div>
            </div>

            <div class="form-group mb-2 col-md-3 fl mr-2">
              <label for="mtwDateOfIssue">Date of Issue*</label>
              <input type="date" id="mtwDateOfIssue" formControlName="mtwDateOfIssue"
                (change)="updateEndDateMin('mtwDateOfIssue', 'mtwExpiryDate')" class="inputOfForm" />
              <!-- Add error message if needed -->
              <div *ngIf="
              myForm.get('mtwDateOfIssue')?.invalid &&
              myForm.get('mtwDateOfIssue')?.touched
            ">
                <span class="error-message">MTW Date of Issue is required.</span>
              </div>
            </div>

            <div class="form-group mb-2 col-md-3 fl mr-2">
              <label for="mtwExpiryDate">Expiry Date*</label>
              <input type="date" id="mtwExpiryDate" formControlName="mtwExpiryDate"
                [min]="myForm.get('mtwDateOfIssue')?.value" class="inputOfForm" />
              <!-- Add error message if needed -->
              <div *ngIf="
              myForm.get('mtwExpiryDate')?.invalid &&
              myForm.get('mtwExpiryDate')?.touched
            ">
                <span class="error-message">MTW Expiry Date is required.</span>
              </div>
            </div>

            <div class="form-group mb-2 col-md-2 fl mr-0">
              <label>Upload*</label>
              <div>
                <span [ngStyle]="{display: !(isEditMode && !updated['mtw_card']) ? 'inline' : 'none'}">
                  <input type="file" id="mtwCertificateUpload" #mtwCertificateIn
                    (change)="onFileSelected('mtwCertificateUpload', 'mtw_card', $event, true, mtwCertificateIn); mtwCertificateTouched = true"
                    class="upldFile" />
                  <button *ngIf="(mtwCertificateIn.files)?.length == 0 && !isEditMode" class="  btn btn-primary  ml-4  "
                    type="button" (click)="mtwCertificateIn.click()" [disabled]="readOnlyVar">
                    <i class="fa-solid fa-magnifying-glass mrgn-r-10"></i>Browse File
                  </button>
                  <div *ngIf="!updateForm">
                    <div
                      *ngIf="(mtwCertificateIn.files || []).length > 0 && updated['mtw_card'] && progress['mtw_card']">
                      <div class="progress">
                        <div class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100"
                          [style.width.%]="progressValue"></div>
                      </div>
                      <p class="upldTxt">uploading...</p>
                    </div>
                    <div class="button-container"
                      *ngIf="(mtwCertificateIn.files || []).length > 0 && updated['mtw_card'] && !progress['mtw_card']">
                      <span class="del" (click)="deleteDocument('mtw_card', mtwCertificateIn)">Delete</span>
                      <div class="line"></div>
                      <span class="pre" (click)="previewDocument(mtwCertificateIn, 'mtw_card')">Preview</span>
                    </div>
                  </div>
                </span>
                <ng-container *ngIf="isEditMode">
                  <input class="upldFile" type="file" id="mtw_card"
                    (change)="onFileSelected('mtwCertificateUpload', 'mtw_card', $event, true, mtwCertificateIn); mtwCertificateTouched = true"
                    #mtwCertificateIn />
                  <button *ngIf="this.fileNames[6] === undefined && !updated['mtw_card']"
                    class="btn btn-primary   ml-4 " type="button" (click)="mtwCertificateIn.click()"
                    [disabled]="readOnlyVar">
                    <i class="fa-solid fa-magnifying-glass mrgn-r-1"></i>Browse File
                  </button>
                  <span *ngIf="this.fileNames[6] !== undefined || updated['mtw_card']">
                    <div *ngIf="updateForm">
                      <div *ngIf="progress['mtw_card']">
                        <div class="progress">
                          <div class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100"
                            [style.width.%]="progressValue"></div>
                        </div>
                        <p class="upldTxt">uploading...</p>
                      </div>
                      <div class="button-container" *ngIf="!progress['mtw_card']">
                        <span class="del" (click)="deleteDocumentInUpdate('mtw_card')">Delete</span>
                        <div class="line"></div>
                        <span class="pre" (click)="previewDocument(mtwCertificateIn, 'mtw_card')">Preview</span>
                      </div>
                    </div>
                  </span>
                </ng-container>
                <div>
                  <span class="file-size-info">Max File size is 20MB</span>
                </div>
              </div>
              <span *ngIf="documentInputErrors['mtwCertificateUpload']" class="error-message">
                {{ documentInputErrors["mtwCertificateUpload"] }}
              </span>
              <div
                *ngIf="!isEditMode && myForm.get('mtwCertificateUpload')?.invalid && myForm.get('mtwCertificateUpload')?.touched">
                <span class="error-message">Upload Certificate.</span>
              </div>
            </div>

          </div>


          <h5 class="font-weight-bold mt-3">Incorporation Certificate</h5>
          <div class="form-row justify-content-start mt-3">
            <div class="form-group mb-2 col-md-3 fl mr-2">
              <label for="incorpCertNo">Incorporation Certificate Number*</label>
              <input type="text" id="incorpCertNo" formControlName="incorpCertNo" class="inputOfForm" />
              <!-- Add error message if needed -->
              <div *ngIf="
                            myForm.get('incorpCertNo')?.invalid &&
                            myForm.get('incorpCertNo')?.touched
                          ">
                <span class="error-message">Incorporation Certificate Number is required.</span>
              </div>
            </div>

            <div class="form-group mb-2 col-md-3 fl mr-2">
              <label for="incorporationCertificateDateOfIssue">Date of Incorporation*</label>
              <input type="date" id="incorporationCertificateDateOfIssue"
                formControlName="incorporationCertificateDateOfIssue" class="inputOfForm" />
              <!-- Add error message if needed -->
              <div *ngIf="
                          myForm.get('incorporationCertificateDateOfIssue')?.invalid &&
                          myForm.get('incorporationCertificateDateOfIssue')?.touched
                        ">
                <span class="error-message">Date of Incorporation is required.</span>
              </div>
            </div>

            <div class="form-group mb-2 col-md-3 fl mr-0">
              <label>Upload*</label>
              <div>
                <span [ngStyle]="{display: !(isEditMode && !updated['comp_incorp_cert']) ? 'inline' : 'none'}">
                  <input type="file" id="incorporationCertificateUpload" #incorporationCertificateIn
                    (change)="onFileSelected('incorporationCertificateUpload', 'comp_incorp_cert', $event, true, incorporationCertificateIn); incorporationCertificateTouched = true"
                    class="upldFile" />
                  <button *ngIf="(incorporationCertificateIn.files)?.length == 0 && !isEditMode"
                    class="  btn btn-primary ml-4   " type="button" (click)="incorporationCertificateIn.click()"
                    [disabled]="readOnlyVar">
                    <i class="fa-solid fa-magnifying-glass mrgn-r-1"></i>Browse File
                  </button>
                  <div *ngIf="!updateForm">
                    <div
                      *ngIf="(incorporationCertificateIn.files || []).length > 0 && updated['comp_incorp_cert'] && progress['comp_incorp_cert']">
                      <div class="progress">
                        <div class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100"
                          [style.width.%]="progressValue"></div>
                      </div>
                      <p class="upldTxt">uploading...</p>
                    </div>
                    <div class="button-container"
                      *ngIf="(incorporationCertificateIn.files || []).length > 0 && updated['comp_incorp_cert'] && !progress['comp_incorp_cert']">
                      <span class="del"
                        (click)="deleteDocument('comp_incorp_cert', incorporationCertificateIn)">Delete</span>
                      <div class="line"></div>
                      <span class="pre"
                        (click)="previewDocument(incorporationCertificateIn, 'comp_incorp_cert')">Preview</span>
                    </div>
                  </div>
                </span>
                <ng-container *ngIf="isEditMode">
                  <input class="upldFile" type="file" id="comp_incorp_cert"
                    (change)="onFileSelected('incorporationCertificateUpload', 'comp_incorp_cert', $event, true, incorporationCertificateIn); incorporationCertificateTouched = true"
                    #incorporationCertificateIn />
                  <button *ngIf="this.fileNames[3] === undefined && !updated['comp_incorp_cert']"
                    class=" btn btn-primary ml-4" type="button" (click)="incorporationCertificateIn.click()"
                    [disabled]="readOnlyVar">
                    <i class="fa-solid fa-magnifying-glass mrgn-r-10"></i>Browse File
                  </button>
                  <span *ngIf="this.fileNames[3] !== undefined || updated['comp_incorp_cert']">
                    <div *ngIf="updateForm">
                      <div *ngIf="progress['comp_incorp_cert']">
                        <div class="progress">
                          <div class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100"
                            [style.width.%]="progressValue"></div>
                        </div>
                        <p class="upldTxt">uploading...</p>
                      </div>
                      <div class="button-container" *ngIf="!progress['comp_incorp_cert']">
                        <span class="del" (click)="deleteDocumentInUpdate('comp_incorp_cert')">Delete</span>
                        <div class="line"></div>
                        <span class="pre"
                          (click)="previewDocument(incorporationCertificateIn, 'comp_incorp_cert')">Preview</span>
                      </div>
                    </div>
                  </span>
                </ng-container>
                <div>
                  <span class="file-size-info">Max File size is 20MB</span>
                </div>
              </div>
              <span *ngIf="documentInputErrors['incorporationCertificateUpload']" class="error-message">
                {{ documentInputErrors["incorporationCertificateUpload"] }}
              </span>
              <div
                *ngIf="!isEditMode && myForm.get('incorporationCertificateUpload')?.invalid && myForm.get('incorporationCertificateUpload')?.touched">
                <span class="error-message">Upload Certificate.</span>
              </div>
            </div>

          </div>

          <h5 class="font-weight-bold mt-3">EPFO</h5>
          <div class="form-row justify-content-start mt-3">
            <div class="form-group mb-2 col-md-3 fl mr-2">
              <label for="companyPfNumber">Company PF Number*</label>
              <input type="text" id="companyPfNumber" formControlName="companyPfNumber" class="inputOfForm" />
              <!-- Add error message if needed -->
              <div *ngIf="
                          myForm.get('companyPfNumber')?.invalid &&
                          myForm.get('companyPfNumber')?.touched
                        ">
                <span class="error-message">PF Number is required.</span>
              </div>
            </div>

            <div class="form-group mb-2 col-md-3 fl mr-2">
              <label for="epfoDateOfIssue">Date of Issue*</label>
              <input type="date" id="epfoDateOfIssue" formControlName="epfoDateOfIssue" class="inputOfForm" />
              <!-- Add error message if needed -->
              <div *ngIf="
                                        myForm.get('epfoDateOfIssue')?.invalid &&
                                        myForm.get('epfoDateOfIssue')?.touched
                                      ">
                <span class="error-message">PT Date of Issue is required.</span>
              </div>
            </div>

            <div class="form-group mb-2 col-md-2 fl mr-0">
              <label>Upload*</label>
              <div>
                <span [ngStyle]="{display: !(isEditMode && !updated['epfo_cert']) ? 'inline' : 'none'}">
                  <input type="file" id="epfoCertificateUpload" #epfoCertificateIn
                    (change)="onFileSelected('epfoCertificateUpload', 'epfo_cert', $event, true, epfoCertificateIn); epfoCertificateTouched = true"
                    class="upldFile" />
                  <button *ngIf="(epfoCertificateIn.files)?.length == 0 && !isEditMode" class="btn btn-primary  ml-4"
                    type="button" (click)="epfoCertificateIn.click()" [disabled]="readOnlyVar">
                    <i class="fa-solid fa-magnifying-glass mrgn-r-"></i>Browse File
                  </button>
                  <div *ngIf="!updateForm">
                    <div
                      *ngIf="(epfoCertificateIn.files || []).length > 0 && updated['epfo_cert'] && progress['epfo_cert']">
                      <div class="progress">
                        <div class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100"
                          [style.width.%]="progressValue"></div>
                      </div>
                      <p class="upldTxt">uploading...</p>
                    </div>
                    <div class="button-container"
                      *ngIf="(epfoCertificateIn.files || []).length > 0 && updated['epfo_cert'] && !progress['epfo_cert']">
                      <span class="del" (click)="deleteDocument('epfo_cert', epfoCertificateIn)">Delete</span>
                      <div class="line"></div>
                      <span class="pre" (click)="previewDocument(epfoCertificateIn, 'epfo_cert')">Preview</span>
                    </div>
                  </div>
                </span>
                <ng-container *ngIf="isEditMode">
                  <input class="upldFile" type="file" id="epfo_cert"
                    (change)="onFileSelected('epfoCertificateUpload', 'epfo_cert', $event, true, epfoCertificateIn); epfoCertificateTouched = true"
                    #epfoCertificateIn />
                  <button *ngIf="this.fileNames[8] === undefined && !updated['epfo_cert']"
                    class="  btn btn-primary ml-4   " type="button" (click)="epfoCertificateIn.click()"
                    [disabled]="readOnlyVar">
                    <i class="fa-solid fa-magnifying-glass mrgn-r-10"></i>Browse File
                  </button>
                  <span *ngIf="this.fileNames[8] !== undefined || updated['epfo_cert']">
                    <div *ngIf="updateForm">
                      <div *ngIf="progress['epfo_cert']">
                        <div class="progress">
                          <div class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100"
                            [style.width.%]="progressValue"></div>
                        </div>
                        <p class="upldTxt">uploading...</p>
                      </div>
                      <div class="button-container" *ngIf="!progress['epfo_cert']">
                        <span class="del" (click)="deleteDocumentInUpdate('epfo_cert')">Delete</span>
                        <div class="line"></div>
                        <span class="pre" (click)="previewDocument(epfoCertificateIn, 'epfo_cert')">Preview</span>
                      </div>
                    </div>
                  </span>
                </ng-container>
                <div>
                  <span class="file-size-info">Max File size is 20MB</span>
                </div>
              </div>
              <span *ngIf="documentInputErrors['epfoCertificateUpload']" class="error-message">
                {{ documentInputErrors["epfoCertificateUpload"] }}
              </span>
              <div
                *ngIf="!isEditMode && myForm.get('epfoCertificateUpload')?.invalid && myForm.get('epfoCertificateUpload')?.touched">
                <span class="error-message">Upload Certificate.</span>
              </div>
            </div>

          </div>

          <h5 class="font-weight-bold mt-3">ESI</h5>
          <div class="form-row justify-content-start mt-3">
            <div class="form-group mb-2 col-md-3 fl mr-2">
              <label for="esiNumber">ESI Number*</label>
              <input type="text" id="esiNumber" formControlName="esiNumber" class="inputOfForm" />
              <!-- Add error message if needed -->
              <div *ngIf="
                            myForm.get('esiNumber')?.invalid &&
                            myForm.get('esiNumber')?.touched
                          ">
                <span class="error-message">ESI Number is required.</span>
              </div>
            </div>
            <div class="form-group mb-2 col-md-3 fl mr-2">
              <label for="esiDateOfIssue">Date of Issue*</label>
              <input type="date" id="esiDateOfIssue" formControlName="esiDateOfIssue" class="inputOfForm" />
              <!-- Add error message if needed -->
              <div *ngIf="
                                                      myForm.get('esiDateOfIssue')?.invalid &&
                                                      myForm.get('esiDateOfIssue')?.touched
                                                    ">
                <span class="error-message">ESI Date of Issue is required.</span>
              </div>
            </div>

            <div class="form-group mb-2 col-md-3 fl mr-0">
              <label>Upload*</label>
              <div>
                <span [ngStyle]="{display: !(isEditMode && !updated['esi_cert']) ? 'inline' : 'none'}">
                  <input type="file" id="esiCertificateUpload" #esiCertificateIn
                    (change)="onFileSelected('esiCertificateUpload', 'esi_cert', $event, true, esiCertificateIn); esiCertificateTouched = true"
                    class="upldFile" />
                  <button *ngIf="(esiCertificateIn.files)?.length == 0 && !isEditMode" class="  btn btn-primary  ml-4  "
                    type="button" (click)="esiCertificateIn.click()" [disabled]="readOnlyVar">
                    <i class="fa-solid fa-magnifying-glass mrgn-r-10"></i>Browse File
                  </button>
                  <div *ngIf="!updateForm">
                    <div
                      *ngIf="(esiCertificateIn.files || []).length > 0 && updated['esi_cert'] && progress['esi_cert']">
                      <div class="progress">
                        <div class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100"
                          [style.width.%]="progressValue"></div>
                      </div>
                      <p class="upldTxt">uploading...</p>
                    </div>
                    <div class="button-container"
                      *ngIf="(esiCertificateIn.files || []).length > 0 && updated['esi_cert'] && !progress['esi_cert']">
                      <span class="del" (click)="deleteDocument('esi_cert', esiCertificateIn)">Delete</span>
                      <div class="line"></div>
                      <span class="pre" (click)="previewDocument(esiCertificateIn, 'esi_cert')">Preview</span>
                    </div>
                  </div>
                </span>
                <ng-container *ngIf="isEditMode">
                  <input class="upldFile" type="file" id="esi_cert"
                    (change)="onFileSelected('esiCertificateUpload', 'esi_cert', $event, true, esiCertificateIn); esiCertificateTouched = true"
                    #esiCertificateIn />
                  <button *ngIf="this.fileNames[9] === undefined && !updated['esi_cert']"
                    class="  btn btn-primary ml-4   " type="button" (click)="esiCertificateIn.click()"
                    [disabled]="readOnlyVar">
                    <i class="fa-solid fa-magnifying-glass mrgn-r-10"></i>Browse File
                  </button>
                  <span *ngIf="this.fileNames[9] !== undefined || updated['esi_cert']">
                    <div *ngIf="updateForm">
                      <div *ngIf="progress['esi_cert']">
                        <div class="progress">
                          <div class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100"
                            [style.width.%]="progressValue"></div>
                        </div>
                        <p class="upldTxt">uploading...</p>
                      </div>
                      <div class="button-container" *ngIf="!progress['esi_cert']">
                        <span class="del" (click)="deleteDocumentInUpdate('esi_cert')">Delete</span>
                        <div class="line"></div>
                        <span class="pre" (click)="previewDocument(esiCertificateIn, 'esi_cert')">Preview</span>
                      </div>
                    </div>
                  </span>
                </ng-container>
                <div>
                  <span class="file-size-info">Max File size is 20MB</span>
                </div>
              </div>
              <span *ngIf="documentInputErrors['esiCertificateUpload']" class="error-message">
                {{ documentInputErrors["esiCertificateUpload"] }}
              </span>
              <div
                *ngIf="!isEditMode && myForm.get('esiCertificateUpload')?.invalid && myForm.get('esiCertificateUpload')?.touched">
                <span class="error-message">Upload Certificate.</span>
              </div>
            </div>

          </div>

          <h5 class="font-weight-bold mt-3">TAN</h5>
          <div class="form-row justify-content-start mt-3">
            <div class="form-group mb-2 col-md-3 fl mr-2">
              <label for="tdsNumber">TAN Number*</label>
              <input type="text" id="tdsNumber" formControlName="tdsNumber" class="inputOfForm" />
              <!-- Add error message if needed -->
              <div *ngIf="
                          myForm.get('tdsNumber')?.invalid &&
                          myForm.get('tdsNumber')?.touched
                        ">
                <span class="error-message">TAN Number is required.</span>
              </div>
            </div>

            <div class="form-group mb-2 col-md-3 fl mr-2">
              <label for="tanDateOfIssue">Date of Issue:</label>
              <input type="date" id="tanDateOfIssue" formControlName="tanDateOfIssue" class="inputOfForm" />
              <!-- Add error message if needed -->
              <div *ngIf="
                                                                    myForm.get('tanDateOfIssue')?.invalid &&
                                                                    myForm.get('tanDateOfIssue')?.touched
                                                                  ">
                <span class="error-message">TAN Date of Issue is required.</span>
              </div>
            </div>

            <div class="form-group mb-2 col-md-3 fl mr-0">
              <label>Upload*</label>
              <div>
                <span [ngStyle]="{display: !(isEditMode && !updated['tds_cert']) ? 'inline' : 'none'}">
                  <input type="file" id="statutoryCompliancesUpload" #statutoryCompliancesIn
                    (change)="onFileSelected('statutoryCompliancesUpload', 'tds_cert', $event, true, statutoryCompliancesIn); statutoryCompliancesTouched = true"
                    class="upldFile" />
                  <button *ngIf="(statutoryCompliancesIn.files)?.length == 0 && !isEditMode"
                    class="  btn btn-primary ml-4   " type="button" (click)="statutoryCompliancesIn.click()"
                    [disabled]="readOnlyVar">
                    <i class="fa-solid fa-magnifying-glass mrgn-r-10"></i>Browse File
                  </button>
                  <div *ngIf="!updateForm">
                    <div
                      *ngIf="(statutoryCompliancesIn.files || []).length > 0 && updated['tds_cert'] && progress['tds_cert']">
                      <div class="progress">
                        <div class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100"
                          [style.width.%]="progressValue"></div>
                      </div>
                      <p class="upldTxt">uploading...</p>
                    </div>
                    <div class="button-container"
                      *ngIf="(statutoryCompliancesIn.files || []).length > 0 && updated['tds_cert'] && !progress['tds_cert']">
                      <span class="del" (click)="deleteDocument('tds_cert', statutoryCompliancesIn)">Delete</span>
                      <div class="line"></div>
                      <span class="pre" (click)="previewDocument(statutoryCompliancesIn, 'tds_cert')">Preview</span>
                    </div>
                  </div>
                </span>
                <ng-container *ngIf="isEditMode">
                  <input class="upldFile" type="file" id="tds_cert"
                    (change)="onFileSelected('statutoryCompliancesUpload', 'tds_cert', $event, true, statutoryCompliancesIn); statutoryCompliancesTouched = true"
                    #statutoryCompliancesIn />
                  <button *ngIf="this.fileNames[7] === undefined && !updated['tds_cert']"
                    class=" btn btn-primary   ml-4 " type="button" (click)="statutoryCompliancesIn.click()"
                    [disabled]="readOnlyVar">
                    <i class="fa-solid fa-magnifying-glass mrgn-r-"></i>Browse File
                  </button>
                  <span *ngIf="this.fileNames[7] !== undefined || updated['tds_cert']">
                    <div *ngIf="updateForm">
                      <div *ngIf="progress['tds_cert']">
                        <div class="progress">
                          <div class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100"
                            [style.width.%]="progressValue"></div>
                        </div>
                        <p class="upldTxt">uploading...</p>
                      </div>
                      <div class="button-container" *ngIf="!progress['tds_cert']">
                        <span class="del" (click)="deleteDocumentInUpdate('tds_cert')">Delete</span>
                        <div class="line"></div>
                        <span class="pre" (click)="previewDocument(statutoryCompliancesIn, 'tds_cert')">Preview</span>
                      </div>
                    </div>
                  </span>
                </ng-container>
                <div>
                  <span class="file-size-info">Max File size is 20MB</span>
                </div>
              </div>
              <span *ngIf="documentInputErrors['statutoryCompliancesUpload']" class="error-message">
                {{ documentInputErrors["statutoryCompliancesUpload"] }}
              </span>
              <div
                *ngIf="!isEditMode && myForm.get('statutoryCompliancesUpload')?.invalid && myForm.get('statutoryCompliancesUpload')?.touched">
                <span class="error-message">Upload Document.</span>
              </div>
            </div>



          </div>

        </div>
      </form>
    </div>

    <div class="tab-content mt-2 fl card-body" [ngStyle]="{display: selectedTab === 'auditors'? 'block' : 'none'}">
      <!-- Auditors Content -->
      <div class="d-flex justify-content-start mb-4" *ngIf="editAuditor">
        <button type="button" class="btn btn-light ml-4" (click)="openModalForConfirm('staticBackDropForAuditors')">
          <i class="fas fa-arrow-left"></i>
        </button>
        <p class="auditor-title mb-0 ml-2">{{ auditorTitle }}</p>
      </div>

      <div class="ml-4" *ngIf="editAuditor">
        <form [formGroup]="newAuditorForm" (ngSubmit)="onSubmitNewAuditor()" autocomplete="off">
          <div class="row">
            <div class="form-group mb-2 col-md-3 fl">
              <label for="new_auditor_name">Auditor Name*</label>

              <input id="new_auditor_name" type="text" formControlName="auditorsName" class="inputOfForm " />
              <div *ngIf="newAuditorForm.get('auditorsName')?.invalid && newAuditorForm.get('auditorsName')?.touched">
                <span class="error-message" *ngIf="newAuditorForm.get('auditorsName')?.errors?.['required']">
                  Auditor Name is required.
                </span>
              </div>
            </div>
            <div class="form-group mb-2 col-md-3 fl">
              <label for="new_auditor_contact">Contact Number*</label>
              <input id="new_auditor_contact" type="text" formControlName="auditorsContactNumber"
                class="inputOfForm " />
              <div
                *ngIf="newAuditorForm.get('auditorsContactNumber')?.invalid && (newAuditorForm.get('auditorsContactNumber')?.dirty || newAuditorForm.get('auditorsContactNumber')?.touched)">
                <span class="error-message" *ngIf="newAuditorForm.get('auditorsContactNumber')?.errors?.['required']">
                  Contact Number is required.
                </span>
                <span class="error-message" *ngIf="newAuditorForm.get('auditorsContactNumber')?.errors?.['pattern']">
                  Contact Number must contain only numbers and must contain 10 or more digits .
                </span>
              </div>
            </div>
            <div class="form-group mb-2 col-md-3 fl">
              <label for="new_auditor_email">Email ID*</label>
              <input id="new_auditor_email" type="email" formControlName="auditorsEmail" class="inputOfForm " />
              <div
                *ngIf="newAuditorForm.get('auditorsEmail')?.invalid && (newAuditorForm.get('auditorsEmail')?.dirty || newAuditorForm.get('auditorsEmail')?.touched)">
                <span class="error-message" *ngIf="newAuditorForm.get('auditorsEmail')?.errors?.['required']">
                  Email ID is required.
                </span>
                <span class="error-message" *ngIf="newAuditorForm.get('auditorsEmail')?.errors?.['email']">
                  Invalid email format.
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group mb-2 col-md-3 fl">
              <label for="new_auditor_company">Company Name*</label>
              <input id="new_auditor_company" type="text" formControlName="auditorsCompanyName" class="inputOfForm " />
              <div
                *ngIf="newAuditorForm.get('auditorsCompanyName')?.invalid && newAuditorForm.get('auditorsCompanyName')?.touched">
                <span class="error-message" *ngIf="newAuditorForm.get('auditorsCompanyName')?.errors?.['required']">
                  Company Name is required.
                </span>
              </div>
            </div>
            <div class="form-group mb-2 col-md-3 fl">
              <label for="new_auditor_working_from">Working Period (From)*</label>
              <input id="new_auditor_working_from" type="date" formControlName="auditorsWorkingPeriodFrom"
                class="inputOfForm " />
              <div
                *ngIf="newAuditorForm.get('auditorsWorkingPeriodFrom')?.invalid && newAuditorForm.get('auditorsWorkingPeriodFrom')?.touched">
                <span class="error-message"
                  *ngIf="newAuditorForm.get('auditorsWorkingPeriodFrom')?.errors?.['required']">
                  Working Period(From) is required.
                </span>
              </div>
            </div>
            <div class="form-group mb-2 col-md-3 fl">
              <label for="new_auditor_working_to">Working Period (To)*</label>
              <input id="new_auditor_working_to" type="date" formControlName="auditorsWorkingPeriodTo"
                class="inputOfForm " />
              <div
                *ngIf="newAuditorForm.get('auditorsWorkingPeriodTo')?.invalid && newAuditorForm.get('auditorsWorkingPeriodTo')?.touched">
                <span class="error-message" *ngIf="newAuditorForm.get('auditorsWorkingPeriodTo')?.errors?.['required']">
                  Working Period(To) is required.
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group mb-2 col-md-3 fl">
              <label for="new_auditor_assign_company">Assigned Company*</label>
              <ng-select [items]="companyNamesList" bindLabel="company" formControlName="auditorsAssignCompany"
                [closeOnSelect]="false" [multiple]="true">
              </ng-select>
              <div
                *ngIf="newAuditorForm.get('auditorsAssignCompany')?.invalid && newAuditorForm.get('auditorsAssignCompany')?.touched">
                <span class="error-message" *ngIf="newAuditorForm.get('auditorsAssignCompany')?.errors?.['required']">
                  Assigned Company Name is required.
                </span>
              </div>
            </div>
            <div class="form-group mb-2 col-md-3 fl">
              <label for="new_auditor_company">Check Feasibility*</label>
              <input id="new_auditor_company" type="text" formControlName="auditorsCheckFeasibility"
                class="inputOfForm " />
              <div
                *ngIf="newAuditorForm.get('auditorsCheckFeasibility')?.invalid && newAuditorForm.get('auditorsCheckFeasibility')?.touched">
                <span class="error-message"
                  *ngIf="newAuditorForm.get('auditorsCheckFeasibility')?.errors?.['required']">
                  Check Feasibility is required.
                </span>
              </div>
            </div>
          </div>
          <div class="d-flex form-group justify-content-end">
            <button type="button" class="btn btn-success-soft clear"
              (click)="openModalForConfirm('staticBackDropForAuditors')">Clear</button>
            <button type="submit" class="btn svbtn ml-3 mr-4">Save</button>
          </div>
        </form>
      </div>

      <div class="table-responsive mt-4" *ngIf="!editAuditor ">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <p class="auditor-title mb-0 ml-2">Auditors</p>
          <div class="d-flex">
            <div class="search-container">
              <i class="fas fa-search p-2 icon-blue"></i>
              <input type="text" placeholder="Search" [(ngModel)]="searchAuditorQuery">
            </div>
            <button class="btn new-button ml-3" *ngIf="!readOnlyVar" (click)="initiateAddAuditor()">
              <i class="fa-solid fa-plus"></i> New
            </button>
          </div>
        </div>
        <table class="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Contact Number</th>
              <th>Email ID</th>
              <th>Company Name</th>
              <th>Working Period (From)</th>
              <th>Working Period (To)</th>
              <th>Assigned Company Name</th>
              <th>Check Feasibility</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="filteredAuditors().length === 0">
              <td colspan="8" class="text-center">No Data Found</td>
            </tr>

            <tr *ngFor="let auditor of filteredAuditors(); let i = index">
              <td>{{ auditor.auditorsName }}</td>
              <td>{{ auditor.auditorsContactNumber }}</td>
              <td>{{ auditor.auditorsEmail }}</td>
              <td>{{ auditor.auditorsCompanyName }}</td>
              <td>{{ auditor.auditorsWorkingPeriodFrom }}</td>
              <td>{{ auditor.auditorsWorkingPeriodTo }}</td>
              <td>{{ auditor.auditorsAssignCompany }}</td>
              <td>{{ auditor.auditorsCheckFeasibility }}</td>
              <td *ngIf="!readOnlyVar">
                <button type="button" class="btn btn-light p-1" (click)="onEditAuditor(auditor, i)">
                  <i class="fas fa-edit"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>


    <div class="tab-content mt-2 fl card-body"
      [ngStyle]="{display: selectedTab === 'char-accountant'? 'block' : 'none'}">
      <!-- Chartered Accountant Content -->
      <div class="d-flex justify-content-start mb-4" *ngIf="editCA">
        <button type="button" class="btn btn-light ml-4" (click)="openModalForConfirm('staticBackDropForCAs')">
          <i class="fas fa-arrow-left"></i>
        </button>
        <p class="ca-title mb-0 ml-2">{{ caTitle }}</p>
      </div>

      <div class="ml-4" *ngIf="editCA">
        <form [formGroup]="newCAForm" (ngSubmit)="onSubmitNewCA()" autocomplete="off">
          <div class="row">
            <div class="form-group mb-2 col-md-3 fl">
              <label for="new_ca_name">Name*</label>
              <input id="new_ca_name" type="text" formControlName="charteredAccountantName" class="inputOfForm " />
              <div
                *ngIf="newCAForm.get('charteredAccountantName')?.invalid && newCAForm.get('charteredAccountantName')?.touched">
                <span class="error-message" *ngIf="newCAForm.get('charteredAccountantName')?.errors?.['required']">
                  Name is required.
                </span>
              </div>
            </div>
            <div class="form-group mb-2 col-md-3 fl">
              <label for="new_ca_contact">Contact Number*</label>
              <input id="new_ca_contact" type="text" formControlName="charteredAccountantContactNumber"
                class="inputOfForm " />
              <div
                *ngIf="newCAForm.get('charteredAccountantContactNumber')?.invalid && (newCAForm.get('charteredAccountantContactNumber')?.dirty || newCAForm.get('charteredAccountantContactNumber')?.touched)">
                <span class="error-message"
                  *ngIf="newCAForm.get('charteredAccountantContactNumber')?.errors?.['required']">
                  Contact Number is required.
                </span>
                <span class="error-message"
                  *ngIf="newCAForm.get('charteredAccountantContactNumber')?.errors?.['pattern']">
                  Contact Number must contain only numbers and must contain 10 or more digits.
                </span>
              </div>
            </div>
            <div class="form-group mb-2 col-md-3 fl">
              <label for="new_ca_email">Email ID*</label>
              <input id="new_ca_email" type="email" formControlName="charteredAccountantEmail" class="inputOfForm " />
              <div
                *ngIf="newCAForm.get('charteredAccountantEmail')?.invalid && (newCAForm.get('charteredAccountantEmail')?.dirty || newCAForm.get('charteredAccountantEmail')?.touched)">
                <span class="error-message" *ngIf="newCAForm.get('charteredAccountantEmail')?.errors?.['required']">
                  Email ID is required.
                </span>
                <span class="error-message" *ngIf="newCAForm.get('charteredAccountantEmail')?.errors?.['email']">
                  Invalid email format.
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group mb-2 col-md-3 fl">
              <label for="new_ca_company">Company Name*</label>
              <input id="new_ca_company" type="text" formControlName="charteredAccountantCompanyName"
                class="inputOfForm " />
              <div
                *ngIf="newCAForm.get('charteredAccountantCompanyName')?.invalid && newCAForm.get('charteredAccountantCompanyName')?.touched">
                <span class="error-message"
                  *ngIf="newCAForm.get('charteredAccountantCompanyName')?.errors?.['required']">
                  Company Name is required.
                </span>
              </div>
            </div>
            <div class="form-group mb-2 col-md-3 fl">
              <label for="new_ca_working_from">Working Period (From)*</label>
              <input id="new_ca_working_from" type="date" formControlName="charteredAccountantWorkingPeriodFrom"
                class="inputOfForm " />
            </div>
            <div class="form-group mb-2 col-md-3 fl">
              <label for="new_ca_working_to">Working Period (To)*</label>
              <input id="new_ca_working_to" type="date" formControlName="charteredAccountantWorkingPeriodTo"
                class="inputOfForm " />
            </div>
          </div>

          <div class="row">
            <div class="form-group mb-2 col-md-3 fl">
              <label for="new_ca_assign_company">Assigned Company*</label>
              <ng-select [items]="companyNamesList" bindLabel="company"
                formControlName="charteredAccountantAssignCompany" [closeOnSelect]="false" [multiple]="true"
                placeholder="Please Select">
              </ng-select>
              <div
                *ngIf="newCAForm.get('charteredAccountantAssignCompany')?.invalid && newCAForm.get('charteredAccountantAssignCompany')?.touched">
                <span class="error-message"
                  *ngIf="newCAForm.get('charteredAccountantAssignCompany')?.errors?.['required']">
                  Assigned Company Name is required.
                </span>
              </div>
            </div>

            <div class="form-group mb-2 col-md-3 fl">
              <label for="new_ca_company">Check Feasibility*</label>
              <input id="new_ca_company" type="text" formControlName="charteredAccountantCheckFeasibility"
                class="inputOfForm " />
              <div
                *ngIf="newCAForm.get('charteredAccountantCheckFeasibility')?.invalid && newCAForm.get('charteredAccountantCheckFeasibility')?.touched">
                <span class="error-message"
                  *ngIf="newCAForm.get('charteredAccountantCheckFeasibility')?.errors?.['required']">
                  Check Feasibility is required.
                </span>
              </div>
            </div>
          </div>
          <div class="d-flex form-group justify-content-end">
            <button type="button" class="btn btn-success-soft clear" data-bs-target="#staticBackdrop"
              (click)="openModalForConfirm('staticBackDropForCAs')">Clear</button>
            <button type="submit" class="btn svbtn ml-3 mr-4">Save</button>
          </div>
        </form>
      </div>

      <div class="table-responsive mt-4" *ngIf="!editCA">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <p class="ca-title mb-0 ml-2">Chartered Accountants</p>
          <div class="d-flex">
            <div class="search-container">
              <i class="fas fa-search p-2 icon-blue"></i>
              <input type="text" placeholder="Search" [(ngModel)]="searchCAQuery">
            </div>
            <button class="btn new-button ml-3" (click)="initiateAddCA()" *ngIf="!readOnlyVar">
              <i class="fa-solid fa-plus"></i> New
            </button>
          </div>
        </div>
        <table class="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Contact Number</th>
              <th>Email ID</th>
              <th>Company Name</th>
              <th>Working Period (From)</th>
              <th>Working Period (To)</th>
              <th>Assigned Company Name</th>
              <th>Check Feasibility</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="filteredCAs().length === 0">
              <td colspan="8" class="text-center">No Data Found</td>
            </tr>

            <tr *ngFor="let ca of filteredCAs(); let i = index">
              <td>{{ ca.charteredAccountantName }}</td>
              <td>{{ ca.charteredAccountantContactNumber }}</td>
              <td>{{ ca.charteredAccountantEmail }}</td>
              <td>{{ ca.charteredAccountantCompanyName }}</td>
              <td>{{ ca.charteredAccountantWorkingPeriodFrom }}</td>
              <td>{{ ca.charteredAccountantWorkingPeriodTo }}</td>
              <td>{{ ca.charteredAccountantAssignCompany }}</td>
              <td>{{ ca.charteredAccountantCheckFeasibility }}</td>
              <td *ngIf="!readOnlyVar">
                <button type="button" class="btn btn-light p-1" (click)="onEditCA(ca, i)">
                  <i class="fas fa-edit"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <form [formGroup]="myForm" (ngSubmit)="onSubmit()" autocomplete="off"
      *ngIf="!(editCA || editAuditor || editBankAccount || editEmi || editShareholder)">
      <div class="margin-left mb-5 mt-3" *ngIf="!readOnlyVar">
        <div>
          <button class="btn btn-danger-soft m-6 clrbttn" type="button" data-bs-toggle="modal"
            (click)="openModalForConfirm('staticBackdrop')">
            Clear
          </button>
        </div>

        <button class="btn btn-success-soft m-6 svbtn" type="submit">
          {{
          isEditMode ? "Update" : "Save"
          }}
        </button>

      </div>


    </form>

  </div>
</div>
</div>



<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog top-40">
    <div class="modal-content left-30 width-40">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel">
          <b>Are you sure you want to discard the changes?</b>
        </h1>
        <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
      </div>
      <!-- <div class="modal-body">
            ...
          </div> -->
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary okaybtn" data-bs-dismiss="modal">
          No
        </button>
        <button type="button" class="btn btn-primary okaybtn" data-bs-dismiss="modal" (click)="clearForm()">
          Yes
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="staticBackDropForSH" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackDropForSHLabel" aria-hidden="true">
  <div class="modal-dialog top-40">
    <div class="modal-content left-30 width-40">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackDropForSHLabel">
          <b>Are you sure you want to discard the changes?</b>
        </h1>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary okaybtn" data-bs-dismiss="modal">
          No
        </button>
        <button type="button" class="btn btn-primary okaybtn" data-bs-dismiss="modal" (click)="cancelAddShareholder()">
          Yes
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="staticBackDropForBank" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackDropForBankLabel" aria-hidden="true">
  <div class="modal-dialog top-40">
    <div class="modal-content left-30 width-40">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackDropForBankLabel">
          <b>Are you sure you want to discard the changes?</b>
        </h1>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary okaybtn" data-bs-dismiss="modal">
          No
        </button>
        <button type="button" class="btn btn-primary okaybtn" data-bs-dismiss="modal" (click)="cancelAddBankAccount()">
          Yes
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="staticBackDropForEmi" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackDropForEmiLabel" aria-hidden="true">
  <div class="modal-dialog top-40">
    <div class="modal-content left-30 width-40">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackDropForEmiLabel">
          <b>Are you sure you want to discard the changes?</b>
        </h1>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary okaybtn" data-bs-dismiss="modal">
          No
        </button>
        <button type="button" class="btn btn-primary okaybtn" data-bs-dismiss="modal" (click)="cancelAddEmi()">
          Yes
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="staticBackDropForAuditors" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackDropForEmiLabel" aria-hidden="true">
  <div class="modal-dialog top-40">
    <div class="modal-content left-30 width-40">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackDropForEmiLabel">
          <b>Are you sure you want to discard the changes?</b>
        </h1>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary okaybtn" data-bs-dismiss="modal">
          No
        </button>
        <button type="button" class="btn btn-primary okaybtn" data-bs-dismiss="modal" (click)="cancelAddAuditor()">
          Yes
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="staticBackDropForCAs" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackDropForEmiLabel" aria-hidden="true">
  <div class="modal-dialog top-40">
    <div class="modal-content left-30 width-40">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackDropForEmiLabel">
          <b>Are you sure you want to discard the changes?</b>
        </h1>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary okaybtn" data-bs-dismiss="modal">
          No
        </button>
        <button type="button" class="btn btn-primary okaybtn" data-bs-dismiss="modal" (click)="cancelAddCA()">
          Yes
        </button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!addcom && !isEditMode" class="card box-shadow-darker">
  <div class="card-header flex-between companies-header">
    <h4 class="font-weight-bold">Companies</h4>
    <div class="flex-start elements-height-in-container">
      <button class="btn btn-light" (click)="fetchcomp()">
        <i class="fa-solid fa-arrows-rotate"></i>
      </button>
      <div class="search-container">
        <i class="fas fa-search p-2 icon-blue"></i>
        <input type="text" placeholder="Search" name="" (keyup)="queryResult($event)">
      </div>
      <button class="btn btn-success-soft svbtn" (click)="addCom()" *ngIf="access==='readandwrite'">
        <i class="fa-solid fa-plus"></i> New
      </button>
      <button class="btn btn-success-soft export" (click)="fetchDataAndExport()">Export
      </button>
    </div>
  </div>

  <div class="card-body">
    <div class="overflow-auto">
      <table class="table table-docs table-hover table-striped">
        <thead>
          <tr>
            <th>S.N.</th>
            <th *ngFor="let h of headers">
              {{h.name}}
              <span *ngIf="h.sort && h.sort.sort" (click)="notifier.sortTable(h, filteredData)">

                <i class="fa fa-sort-amount-desc" aria-hidden="true" *ngIf="h.sort.direction == 'Des'"></i>
                <i class="fa fa-sort-amount-asc" aria-hidden="true" *ngIf="h.sort.direction == 'Asc'"></i>
              </span>

              <span *ngIf="h.filter" class="inputseach">
                <input autocomplete="off" type="text" [(ngModel)]="h.filterText" />
                <i class="fa fa-search" aria-hidden="true"></i>
              </span>
            </th>
            <th colspan="2" *ngIf="access==='readandwrite'">Actions</th>
          </tr>
        </thead>
        <tbody [ngStyle]="{
            display: filteredData.length > 0 ? 'table-row-group' : 'none'
          }">
          <ng-container
            *ngFor="let i of filteredData | paginate : { itemsPerPage: pagination['perPage'], currentPage: pagination['page'], totalItems: filteredData.length }; let j = index">
            <tr *ngIf="notifier.filterTableColums(headers,i)">
              <td>{{(pagination['page']-1) * (pagination['perPage']) + j+1}}</td>
              <td *ngFor="let header of headers" [ngClass]="{'status-cell': header.name === 'Status'}">
                <span *ngIf="header.name === 'Status'; else defaultCell"
                  [ngClass]="{'status-badge': true, 'status-active': getCellValue(header.id, i) === 'Active', 'status-inactive': getCellValue(header.id, i) !== 'Active', }">
                  {{ getCellValue(header.id, i) }}
                </span>
                <ng-template #defaultCell>
                  <span (click)="header.name === 'Company Name' ? readOnly(i) : null"
                    [ngClass]="{'first-column': header.name === 'Company Name'}">
                    {{ getCellValue(header.id, i) }}
                  </span>
                </ng-template>
              </td>
              <td class="text-center" *ngIf="access==='readandwrite'">
                <i class="fas fa-edit edit-icon" (click)="editCompany(i)"></i>
              </td>

            </tr>
          </ng-container>
        </tbody>


        <tbody [ngStyle]="{
            display: filteredData.length == 0 ? 'table-row-group' : 'none'
          }">
          <tr>
            <td [attr.colspan]="12">
              <div class="no_data text-center w-100">No Companies Found</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="paginator">
      <div class="flex-start">
        <span class="no-wrap">Per Page</span>
        <select class="form-select" [(ngModel)]="pagination['perPage']" (change)="pagination['page'] = 1">
          <option value="{{page}}" *ngFor="let page of pages">{{ page }}</option>
        </select>
      </div>
      <pagination-controls class="pagination" previousLabel="Prev" nextLabel="Next"
        (pageChange)="pagination['page'] = $event">
      </pagination-controls>
    </div>
  </div>
</div>

<div *ngIf="showModal" class="modal fade show" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"><strong>Company Details</strong></h5>
        <button type="button" class="close" aria-label="Close" (click)="hideModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <mat-tab-group>
          <mat-tab label="Company Details">
            <div class="card-body">
              <div class="mod">
                <span>Company Name:</span><span>{{ selectedCompany.company_name }}</span>
              </div>
              <div class="mod">
                <span>Company Code:</span><span>{{ selectedCompany.company_code }}</span>
              </div>
              <div class="mod">
                <span>Incorporation Date:</span><span>{{ selectedCompany.company_details.incorporation_date }}</span>
              </div>
              <div class="mod">
                <span>Status:</span><span>{{ selectedCompany.company_details.company_status }}</span>
              </div>
              <div class="mod">
                <span>Company Type:</span><span>{{ selectedCompany.company_details.company_type }}</span>
              </div>
              <div class="mod">
                <span>Company Contact Number:</span><span>{{ selectedCompany.company_details.company_contactnum
                  }}</span>
              </div>
              <div class="mod">
                <span>GST Number:</span><span>{{ selectedCompany.company_details.gst_number }}</span>
              </div>
              <div class="mod">
                <span>PAN Number:</span><span>{{ selectedCompany.company_details.pan_number }}</span>
              </div>
              <div class="mod">
                <span>PF Number:</span><span>{{ selectedCompany.company_details.pf_number }}</span>
              </div>
              <div class="mod">
                <span>ESI Number:</span><span>{{ selectedCompany.company_details.esi_number }}</span>
              </div>
              <div class="mod">
                <span>TAN Number:</span><span>{{ selectedCompany.company_details.tds_number }}</span>
              </div>
            </div>
          </mat-tab>

          <mat-tab label="Shareholders">
            <div class="card-body">
              <ul>
                <li *ngFor="let shareholder of selectedCompany.shareholders; let i = index">
                  <h6>Shareholder {{ i + 1 }}</h6>
                  <div class="mod">
                    <span>Name:</span><span>{{ shareholder.shareholder_name }}</span>
                  </div>
                  <div class="mod">
                    <span>Primary Contact Number:</span><span>{{ shareholder.primary_contact_num }}</span>
                  </div>
                  <div class="mod">
                    <span>Secondary Contact Number:</span><span>{{ shareholder.secondary_contact_num ?
                      shareholder.secondary_contact_num : '-' }}</span>
                  </div>
                  <div class="mod">
                    <span>DIN Number:</span><span>{{ shareholder.din_no }}</span>
                  </div>
                  <div class="mod">
                    <span>PAN Number:</span><span>{{ shareholder.pan_no }}</span>
                  </div>
                  <div class="mod">
                    <span>Aadhar Number:</span><span>{{ shareholder.aadhar_no }}</span>
                  </div>
                  <div class="mod">
                    <span>Status:</span><span>{{ shareholder.status }}</span>
                  </div>
                </li>
              </ul>
            </div>
          </mat-tab>

          <mat-tab label="Financial Information">
            <mat-tab-group>
              <mat-tab label="Banks">
                <div class="card-body">
                  <ul>
                    <li *ngFor="let bank of selectedCompany.bank_accounts; let i = index">
                      <h6>Bank Account {{ i + 1 }}</h6>
                      <div class="mod">
                        <span>Company Code:</span><span>{{ bank.company_code }}</span>
                      </div>
                      <div class="mod">
                        <span>Account Number:</span><span>{{ bank.account_number }}</span>
                      </div>
                      <div class="mod">
                        <span>IFSC Code:</span><span>{{ bank.ifsc_code }}</span>
                      </div>
                      <div class="mod">
                        <span>Bank Name:</span><span>{{ bank.bank_name }}</span>
                      </div>
                      <div class="mod">
                        <span>Bank Address:</span><span>{{ bank.bank_address }}</span>
                      </div>
                      <div class="mod">
                        <span>Name as per Bank Account:</span><span>{{ bank.name_as_per_bank_account }}</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </mat-tab>

              <mat-tab label="EMIs">
                <div class="card-body">
                  <ul>
                    <li *ngFor="let emi of selectedCompany.emis; let i = index">
                      <h6>EMI {{ i + 1 }}</h6>
                      <div class="mod">
                        <span>Vehicle Numbers:</span><span>{{ emi.vehicle_numbers }}</span>
                      </div>
                      <div class="mod">
                        <span>Total Vehicles:</span><span>{{ emi.total_vehicles }}</span>
                      </div>
                      <div class="mod">
                        <span>Financer:</span><span>{{ emi.financer }}</span>
                      </div>
                      <div class="mod">
                        <span>Loan Number:</span><span>{{ emi.loan_number }}</span>
                      </div>
                      <div class="mod">
                        <span>Bank Name:</span><span>{{ emi.bank_name }}</span>
                      </div>
                      <div class="mod">
                        <span>Account Number:</span><span>{{ emi.account_number }}</span>
                      </div>
                      <div class="mod">
                        <span>Loan Amount:</span><span>{{ emi.loan_amount }}</span>
                      </div>
                      <div class="mod">
                        <span>Interest Amount:</span><span>{{ emi.interest_amount }}</span>
                      </div>
                      <div class="mod">
                        <span>Total Amount:</span><span>{{ emi.total_amount }}</span>
                      </div>
                      <div class="mod">
                        <span>Flat Rate:</span><span>{{ emi.flat_rate }}</span>
                      </div>
                      <div class="mod">
                        <span>Number of Paid Months:</span><span>{{ emi.no_of_paid_months }}</span>
                      </div>
                      <div class="mod">
                        <span>Number of Due Months:</span><span>{{ emi.no_of_due_months }}</span>
                      </div>
                      <div class="mod">
                        <span>Total Installments:</span><span>{{ emi.total_installments }}</span>
                      </div>
                      <div class="mod">
                        <span>EMI Starting Date:</span><span>{{ emi.emi_starting_date }}</span>
                      </div>
                      <div class="mod">
                        <span>EMI Ending Date:</span><span>{{ emi.emi_ending_date }}</span>
                      </div>
                      <div class="mod">
                        <span>Total EMI:</span><span>{{ emi.total_emi }}</span>
                      </div>
                      <div class="mod">
                        <span>Total Outstanding:</span><span>{{ emi.total_outstanding }}</span>
                      </div>
                      <div class="mod">
                        <span>EMI Date:</span><span>{{ emi.emi_date }}</span>
                      </div>
                      <div class="mod">
                        <span>EMI Amount:</span><span>{{ emi.emi_amount }}</span>
                      </div>
                    </li>
                  </ul>
                </div>


              </mat-tab>

            </mat-tab-group>
          </mat-tab>

          <mat-tab label="Statutory Compliance">
            <div class="card-body">
              <ul>
                <!-- GST Section -->
                <li>
                  <h6>GST</h6>
                  <div class="mod">
                    <span>GST Number:</span><span>{{ selectedCompany.company_details.gst_number }}</span>
                  </div>
                </li>
                <li>
                  <h6>PAN</h6>
                  <div class="mod">
                    <span>PAN Number:</span><span>{{ selectedCompany.company_details.pan_number }}</span>
                  </div>
                </li>
                <li>
                  <h6>Labour License</h6>
                  <div class="mod">
                    <span>Registration Number:</span><span>{{ selectedCompany.license_details.registration_number
                      }}</span>
                  </div>
                  <div class="mod">
                    <span>Date of Issue:</span><span>{{ selectedCompany.license_details.issue_date }}</span>
                  </div>
                  <div class="mod">
                    <span>Expiry Date:</span><span>{{ selectedCompany.license_details.expiry_date }}</span>
                  </div>
                </li>
                <li>
                  <h6>Professional Tax</h6>
                  <div class="mod">
                    <span>PT Number:</span><span>{{ selectedCompany.company_details.prof_tax_cert_num
                      }}</span>
                  </div>
                  <div class="mod">
                    <span>Date of Issue:</span><span>{{ selectedCompany.company_details.prof_tax_cert_expiry }}</span>
                  </div>
                </li>
                <li>
                  <h6>Udhayam Certificate</h6>
                  <div class="mod">
                    <span>Registration Number:</span><span>{{ selectedCompany.company_details.udyam_cert_reg_num
                      }}</span>
                  </div>
                  <div class="mod">
                    <span>Date of Issue:</span><span>{{ selectedCompany.company_details.udyam_cert_expiry }}</span>
                  </div>
                </li>
                <li>
                  <h6>MTW</h6>
                  <div class="mod">
                    <span>Registration Number:</span><span>{{ selectedCompany.company_details.mtw_cert_reg_num
                      }}</span>
                  </div>
                  <div class="mod">
                    <span>Date of Issue:</span><span>{{ selectedCompany.company_details.mtw_cert_issue }}</span>
                  </div>
                  <div class="mod">
                    <span>Expiry Date:</span><span>{{ selectedCompany.company_details.mtw_cert_expiry }}</span>
                  </div>
                </li>
                <li>
                  <h6>Incorporation Certificate</h6>
                  <div class="mod">
                    <span>Incorporation Certificate Number:</span><span>{{
                      selectedCompany.company_details.incorp_cert_no
                      }}</span>
                  </div>
                  <div class="mod">
                    <span>Date of Incorporation:</span><span>{{ selectedCompany.company_details.incorp_cert_date
                      }}</span>
                  </div>
                </li>
                <li>
                  <h6>EPFO</h6>
                  <div class="mod">
                    <span>Company PF Number:</span><span>{{ selectedCompany.company_details.pf_number
                      }}</span>
                  </div>
                  <div class="mod">
                    <span>Date of Issue:</span><span>{{ selectedCompany.company_details.epfo_cert_issue }}</span>
                  </div>
                </li>
                <li>
                  <h6>ESI</h6>
                  <div class="mod">
                    <span>ESI Number:</span><span>{{ selectedCompany.company_details.esi_number
                      }}</span>
                  </div>
                  <div class="mod">
                    <span>Date of Issue:</span><span>{{ selectedCompany.company_details.esi_cert_issue }}</span>
                  </div>
                </li>
                <li>
                  <h6>TAN</h6>
                  <div class="mod">
                    <span>TAN Number:</span><span>{{ selectedCompany.company_details.tds_number
                      }}</span>
                  </div>
                  <div class="mod">
                    <span>Date of Issue:</span><span>{{ selectedCompany.company_details.tan_cert_issue }}</span>
                  </div>
                </li>
              </ul>
            </div>
          </mat-tab>

          <mat-tab label="Auditors">
            <div class="card-body">
              <ul>
                <li *ngFor="let auditor of selectedCompany.auditors; let i = index">
                  <h6>Auditor {{ i + 1 }}</h6>
                  <div class="mod">
                    <span>Auditor Name:</span><span>{{ auditor.auditor_name }}</span>
                  </div>
                  <div class="mod">
                    <span>Contact Number:</span><span>{{ auditor.contact_number }}</span>
                  </div>
                  <div class="mod">
                    <span>Email ID:</span><span>{{ auditor.email_id }}</span>
                  </div>
                  <div class="mod">
                    <span>Working Period From:</span><span>{{ auditor.working_period_from }}</span>
                  </div>
                  <div class="mod">
                    <span>Working Period To:</span><span>{{ auditor.working_period_to }}</span>
                  </div>
                  <div class="mod">
                    <span>Assigned Company Names:</span>
                    <ul>
                      <li *ngFor="let company of auditor.assigned_company_name">{{ company }}</li>
                    </ul>
                  </div>
                  <div class="mod">
                    <span>Check Feasibility:</span><span>{{ auditor.check_feasibility }}</span>
                  </div>
                </li>
              </ul>
            </div>
          </mat-tab>

          <mat-tab label="CAs">
            <div class="card-body">
              <ul>
                <li *ngFor="let ca of selectedCompany.chartered_accountants; let i = index">
                  <h6>CA {{ i + 1 }}</h6>
                  <div class="mod">
                    <span>CA Name:</span><span>{{ ca.ca_name }}</span>
                  </div>
                  <div class="mod">
                    <span>Contact Number:</span><span>{{ ca.contact_number }}</span>
                  </div>
                  <div class="mod">
                    <span>Email ID:</span><span>{{ ca.email_id }}</span>
                  </div>
                  <div class="mod">
                    <span>Working Period From:</span><span>{{ ca.ca_working_period_from }}</span>
                  </div>
                  <div class="mod">
                    <span>Working Period To:</span><span>{{ ca.ca_working_period_to }}</span>
                  </div>
                  <div class="mod">
                    <span>Assigned Company Names:</span>
                    <ul>
                      <li *ngFor="let company of ca.ca_assigned_company_name">{{ company }}</li>
                    </ul>
                  </div>
                  <div class="mod">
                    <span>Check Feasibility:</span><span>{{ ca.ca_check_feasibility }}</span>
                  </div>
                </li>
              </ul>
            </div>
          </mat-tab>

        </mat-tab-group>
      </div>
    </div>
  </div>
</div>


<app-submit-popup *ngIf="popUp" (hideModa)="hidepopup($event)" [header]="popup_header"
  [subtitle]="popup_subtitle"></app-submit-popup>