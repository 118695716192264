import { AfterViewInit, Component, EventEmitter, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APIService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { environment } from 'src/environments/environment';
import { FormArray, FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import { ElementRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import * as XLSX from 'xlsx';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
} from '@angular/common/http';
import { FileUploadService } from 'src/app/services/fileupload.service';
// import { number } from 'echarts';
declare let Jhxlsx: any;
declare let $: any;

@Component({
  selector: 'app-vehicles',
  templateUrl: './vehicles.component.html',
  styleUrls: ['./vehicles.component.css'],
})
export class VehiclesComponent implements OnInit {
  @ViewChild('fileInput', { read: ElementRef }) fileInput!: ElementRef;
  myForm: any;
  minEndDate: string = '';
  documentInputErrors: { [key: string]: string | null } = {};
  isOpen: boolean = false;
  isSubmitted: boolean = false;
  urlPrefix: string | null = null;
  config: any;
  addcom: boolean = false;
  result: any;
  selectedCompany: any;
  showModal: boolean = false;
  isEditMode: boolean = false;
  loading: boolean = false;
  clientNamesList: any;
  clientNamesInfo: any;
  submitted: boolean = false;
  regTouched: boolean = false;
  @ViewChild('regIn') regIn: any;
  updated: any = {};
  fileNames: any;
  fetchUrls: any;
  matchingUrls: any;
  orderedUrls: any[] = [];
  sendUrls: any[] = [];
  pagination: any = { perPage: 10, page: 1 };
  pagination2: any = { perPage: 10, page: 1 };
  pages: any = [10, 50, 100, 200];
  // isExpanded: any
  pageChange: EventEmitter<number> | undefined;
  bulkFileUpload: boolean = false;
  contentList: any[] = [];
  index: any = 0;
  selectedArrayForEdit: any = [];
  exportVar: any = [];
  expandedButton: boolean = false;
  popUp: boolean = false;
  popup_header: any = '';
  popup_subtitle: any = '';
  progressValue:any=0;
  progressBar: boolean = false;
  uploadedVar: boolean = false;
  updateForm: boolean = false;
  modalMessage: string = '';
  access = localStorage.getItem('acT');



  constructor(
    private fb: FormBuilder,
    private apiService: APIService,
    private uploadFile: FileUploadService,
    public notifier: NotifierService,
    private route: ActivatedRoute,
    private elementRef: ElementRef
  ) {
    this.config = this.route.snapshot.data['config'];
  }

  // constructor(private fb: FormBuilder, private apiService: APIService,
  //   public notifier: NotifierService,
  //   private route: ActivatedRoute) {
  //     this.config = this.route.snapshot.data['config'];

  //   }

  headers: any = [
    // {
    //   id: 'registration_number',
    //   name: 'Registration Number',
    //   // filter: true,
    //   sort: { sort: true, direction: 'Asc' },
    //   // click: true,
    //   click: (vehicle: any) => {
    //     return this.openModal(vehicle);
    //   },
    //   // filter: true
    //   // sort: { sort: true, direction: 'Asc' },
    // },
    {
        id: 'registration_number',
        name: 'Registration Number',
        // filter: true,
        sort: { sort: true, direction: 'Asc' },
      },
    {
      id: 'vehicles_details.vehicle_owner',
      name: 'Client Name',
      // filter: true,
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'vehicles_details.vehicle_manufacturer',
      name: 'Manufacturer',
      // filter: true,
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'vehicles_details.vehicle_manufacturer_year',
      name: 'Model Year',
      // filter: true,
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'vehicles_details.seating_capacity',
      name: 'Seating Capacity',
      // filter: true,
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'vehicles_details.vehicle_mode',
      name: "AC/Non AC",
      // filter: true,
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'vehicles_details.vehicle_type',
      name: 'Vehicle Type',
      // filter: true,vehicle_type
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'vehicles_details.seating_type',
      name: 'Seating Type',
      // filter: true,vehicle_type
      sort: { sort: true, direction: 'Asc' },
    }];

  headers2: any = [
    {
      id: 'Registration Number',
      name: 'Registration Number',
      // filter: true,
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'Vehicle Ownership',
      name: 'Client NameE',
      // filter: true,
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'Manufacturer',
      name: 'Manufacturer',
      sort: { sort: true, direction: 'Asc' },
    },
    // { id: "vehicles_details.engine_type", name: "Vehicle Model", filter: true,  sort: { sort: true, direction: "Asc" }, },
    {
      id: 'Model Year',
      name: 'Model Year',
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'Seating Capacity',
      name: 'Seating Capacity',
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'Vehicle Mode',
      name: "AC/Non AC",
      sort: { sort: true, direction: 'Asc' },
    },
    // { id: "vehicles_details.vehicle_type", name: "Vehicle Type", filter: true,  sort: { sort: true, direction: "Asc" }, },
    {
      id: 'Vehicle Type',
      name: 'Vehicle Type',
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'Seating Type',
      name: 'Seating Type',
      sort: { sort: true, direction: 'Asc' },
    },
  ];

  ngOnInit() {
    this.fetchvehicles();
    this.clients_info();

    this.myForm = this.fb.group({
      // reg_num: ['', [Validators.required, Validators.maxLength(10), this.noSpacesValidator, this.noSpecialCharsValidator]],
      reg_num: [
        { value: '', disabled: this.isEditMode }, 
        [Validators.required, Validators.maxLength(10), this.registrationNumberValidator()]
      ],
      // reg_plate_photo: ['', Validators.required],
      veh_name: ['', [Validators.required]],
      veh_own: ['', [Validators.required]],
      model: ['', [Validators.required]],
      manufacturer: ['', Validators.required],
      man_year: ['', [Validators.required]],
      // veh_reg_date: ['', [Validators.required]],
      // veh_reg_exp_date: ['', [Validators.required]],
      veh_type: ['', [Validators.required]],
      veh_col: ['', Validators.required],
      veh_mode: ['', [Validators.required]],
      // status: ['', [Validators.required]],
      ac_manufacturer: ['', [Validators.required]],
      seating_cap: ['', [Validators.required]],
      seating_type: ['', [Validators.required]],
      fuel_type: ['', [Validators.required]],
      fuel_tank_cap: ['', [Validators.required]],
      fav: ['', Validators.maxLength(12)],
      compatible_type: [''],
      no_of_tyers: ['', [Validators.pattern('^[0-9]+$')]],
    });
  }


  // dateValidator(): ValidatorFn {
  //   return (control: AbstractControl): ValidationErrors | null => {
  //     const regDate = control.get('veh_reg_date')?.value;
  //     const expDate = control.get('veh_reg_exp_date')?.value;
      
  //     if (regDate && expDate) {
  //       const regDateObj = new Date(regDate);
  //       const expDateObj = new Date(expDate);
        
  //       return expDateObj > regDateObj ? null : { invalidDate: true };
  //     }
  //     return null;
  //   };
  // }

  updateEndDateMin(regDateControlName: string, expDateControlName: string): void {
    const regDateControl = this.myForm.get(regDateControlName);
    const expDateControl = this.myForm.get(expDateControlName);
    if (regDateControl && expDateControl) {
      expDateControl.setValue('');
      expDateControl.updateValueAndValidity();
    }
  }


  registrationNumberValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) {
        return null;
      }
      
      // Regular expression to match the format with exactly two letters at the beginning followed by letters or digits
      const regex = /^[A-Za-z]{2}[A-Za-z0-9]*$/;
      
      const valid = regex.test(value);
      
      return valid ? null : { invalidRegistrationNumber: true };
    };
  }


  noSpacesValidator(control: AbstractControl): ValidationErrors | null {
    const hasSpaces = /\s/.test(control.value);
    return hasSpaces ? { noSpaces: true } : null;
  }
  noSpecialCharsValidator(control: AbstractControl): ValidationErrors | null {
    const hasSpecialChars = /[^a-zA-Z0-9]/.test(control.value);
    return hasSpecialChars ? { noSpecialChars: true } : null;
  }


  initiateRegIn() {
    if (!this.showModal){
      if (this.myForm.value.reg_num !== '' && this.myForm.value.reg_num !== null && this.myForm.value.reg_num !== undefined){
        console.log('inside initiateRegIn', this.myForm.value.reg_num)
        this.regIn.nativeElement.value = '';
        this.regIn.nativeElement.click();
      }else{
        this.showPopup(
          'Please provide Registration Number first before uploading the document',
          ''
        );
      }
    }
  }

  calculateTotalKms(): number {
    const emptyKms = Number(this.myForm.get('empty_kms').value);
    const oneWayKms = Number(this.myForm.get('one_way_kms').value);
    const parkingKms = Number(this.myForm.get('park_kms').value);
    const workingDays = Number(this.myForm.get('wor_days').value);

    return (workingDays * emptyKms + oneWayKms + parkingKms);
  }

  // updateEndDateMin(startFieldName: string, endFieldName: string): void {
  //   const startDate = this.myForm.get(startFieldName)?.value;
  //   if (startDate) {
  //     const endDateControl = this.myForm.get(endFieldName);
  //     if (endDateControl) {
  //       const startDateValue = new Date(startDate);
  //       endDateControl.setValidators([
  //         Validators.required,
  //         (control: AbstractControl) => {
  //           const endDateValue = new Date(control.value);
  //           return endDateValue > startDateValue ? null : { minDate: true };
  //         }
  //       ]);
  //       endDateControl.updateValueAndValidity();
  //     }
  //   }
  // }

  vehicle_owner: any = 'Minfy';
  async populateForm(selectedCompany: any): Promise<void> {
    console.log('inside the populate form');
    console.log('$$$$$$$$$$$$$$$$$$$$',selectedCompany);
    console.log(selectedCompany.company_details);
    if (selectedCompany) {
      // let companyDetails = this.selectedCompany.company_details;
      // console.log(companyDetails);
      // let bankDetails = this.selectedCompany.bank_details;
      // let licenseDetails = this.selectedCompany.license_details;
      // console.log("inside the populate form");
      //       const { company_details, bank_details, license_details } = selectedCompany;
      // console.log(company_details);
      console.log('inside the populate form');
      this.sendUrls = [];
      this.sendUrls.push(
        selectedCompany.vehicles_details.registration_image_s3_path
      );
      await this.fetch(this.sendUrls);
      console.log('Send urls', this.sendUrls);
      console.log('fileNames####:', this.fileNames);
      // const [emisF, emisT] = company_details.vehicle_emi_duration.split(' to ');
      // console.log("EMI duration",emisF, emisT)
      // this.vehicle_owner = 'shubham';
      // $('#veh_own').selectpicker('refresh');
      this.myForm.patchValue({
        reg_num: selectedCompany.vehicles_details.registration_number,
        // company_code: this.clientNamesInfo[formValues.veh_own]['company_code'],
        // client_id: this.clientNamesInfo[formValues.veh_own]['client_id'],
        reg_plate_photo: this.fileNames[0],
        veh_name: selectedCompany.vehicles_details.vehicle_name,
        veh_own: selectedCompany.vehicles_details.vehicle_owner,
        model: selectedCompany.vehicles_details.engine_type,
        manufacturer: selectedCompany.vehicles_details.vehicle_manufacturer,
        man_year: selectedCompany.vehicles_details.vehicle_manufacturer_year,
        // veh_reg_date:
        //   selectedCompany.vehicles_details.vehicle_registration_date,
        // veh_reg_exp_date:
        //   selectedCompany.vehicles_details.vehicle_registration_expiry_date,
        veh_type: selectedCompany.vehicles_details.vehicle_type,
        veh_col: selectedCompany.vehicles_details.vehicle_color,
        veh_mode: selectedCompany.vehicles_details.vehicle_mode,
        // status: selectedCompany.vehicles_details.vehicle_status,
        ac_manufacturer: selectedCompany.vehicles_details.ac_manufacturer,
        seating_cap: selectedCompany.vehicles_details.seating_capacity,
        seating_type: selectedCompany.vehicles_details.seating_type,
        fuel_type: selectedCompany.vehicles_details.fuel_type,
        fuel_tank_cap: parseFloat(
          selectedCompany.vehicles_details.fuel_tank_capacity
        ),
        fav: selectedCompany.vehicles_details.fav,
        compatible_type: selectedCompany.vehicles_details.compatible_tyre,
        no_of_tyers: selectedCompany.vehicles_details.no_tyres,
        // veh_own: $('#veh_own').val(selectedCompany.vehicles_details.vehicle_owner)
      });
      // console.log("selectedCompany.vehicles_details.vehicle_owner",selectedCompany.vehicles_details.vehicle_owner)
      // console.log('this.myForm.veh_own',this.myForm.value.veh_own)
      // console.log('this.myForm',this.myForm)
      // Patch auditors and chartered accountants arrays if available
      // const auditorsArray = selectedCompany.auditors;
    }
  }

  clearForm(): void {
    this.myForm.reset();
    this.isEditMode = false;
    this.addcom = false;
    this.updated = {};
    this.documentInputErrors = {};
    this.updateForm = false;
    this.showModal = false
    this.enableForModal()
  }

  previewDocument(input: HTMLInputElement, doc: string) {
    console.log('input', input);
    const file = input.files?.[0];
    if (file) {
      const fileType = file.name.split('.').pop()?.toLowerCase() || '';
      if (fileType === 'pdf' || this.isImageFile(fileType)) {
        const reader = new FileReader();
        reader.onload = () => {
          const blob = new Blob([reader.result as ArrayBuffer], {
            type: file.type,
          });
          const url = URL.createObjectURL(blob);
          const viewer = document.createElement('iframe');
          viewer.src = url;
          viewer.style.width = '100%';
          viewer.style.height = '100%';
          viewer.style.border = 'none';
          const newWindow = window.open();
          if (newWindow) {
            newWindow.document.body.appendChild(viewer);
            newWindow.document.title = file.name;
            newWindow.focus();
          } else {
            console.log('Failed to open preview window');
          }
        };
        reader.readAsArrayBuffer(file);
      } else {
        console.log('Unsupported file type for preview');
      }
    } else {
      // If no file is uploaded, show file from URL
      console.log('matchingUrls', this.matchingUrls);
      if (this.matchingUrls && this.matchingUrls[doc] && this.isEditMode) {
        const url = this.matchingUrls[doc];
        const viewer = document.createElement('iframe');
        viewer.style.width = '100%';
        viewer.style.height = '100%';
        viewer.style.border = 'none';
        const newWindow = window.open();
        if (newWindow) {
          newWindow.document.title = doc;
          viewer.src = url;
          newWindow.document.body.appendChild(viewer.cloneNode(true));
          newWindow.focus();
        } else {
          console.log('Failed to open preview window');
        }
      } else {
        console.log('No file selected and no URL available for preview');
      }
    }
  }

  deleteDocument(){
    
    this.fileNames = undefined;
    this.updated = {}
    // this.myForm.value.reg_plate_photo = ''
    // console.log(this.myForm.value)
    console.log('###############',this.updated, this.fileNames)
  }


  deleteDocumentInUpdate(){
    if (!this.showModal){
      this.fileNames[0] = 'NA';
      this.updated = {};
    }
  }

  deleteDocumentForBulkUpload(){
    // !contentList[index]['Registration Plate Photo'] &&
    //                     !updated['registration_plate_photo']
    delete this.contentList[this.index]['Registration Plate Photo'];
    this.updated = {};
  }


  reset(element: any, filename: string) {
    element.value = '';
    this.updated[filename] = undefined;
  }

  toggleSidenav() {
    this.isOpen = !this.isOpen;
  }

  async onFileSelected(fieldName: string, filename: string, event: any) {
    console.log('this.isEditMode:',this.isEditMode)
    console.log('&&&&&&&&&&updated', this.updated)
    this.progressBar = true;
    this.progressValue=0;
    console.log('this.updated', this.updated);
    console.log('Field Name:', fieldName);
    console.log('Event:', event);
    console.log('@@@@@@@@@@@@@@@@this.fileNames', this.fileNames);
    const file = event.target.files?.[0];
    console.log('Selected File:', file);
    if (file) {
      // Get the file name without extension
      const fileNameWithoutExtension = file.name
        .split('.')
        .slice(0, -1)
        .join('.');
      console.log('File Name:', fileNameWithoutExtension);

      // Get the file extension
      const fileExtension = file.name.split('.').pop()?.toLowerCase() || '';
      console.log('File Extension:', fileExtension);

      if (file.size > 20 * 1024 * 1024) {
        this.documentInputErrors[fieldName] =
          'File size exceeds the limit (20MB)';
      } else {
        if (
          !this.isImageFile(fileExtension) &&
          file.type !== 'application/pdf'
        ) {
          this.documentInputErrors[fieldName] =
            'Unsupported file type. Only PDF and images are allowed.';
        } else {
          this.updated[filename] = true;
          this.documentInputErrors[fieldName] = null; // Clear error if file is valid

          // Check if an item with the same fieldName already exists
          const existingIndex = this.fileInfoArray.findIndex(
            (item) => item.fieldName === fieldName
          );
          if (existingIndex !== -1) {
            // Replace the existing item with the new one
            this.fileInfoArray[existingIndex] = {
              file_name: filename,
              ext: fileExtension,
              file: file,
              fieldName: fieldName,
            };
          } else {
            // Push the new item into the fileInfoArray

            this.fileInfoArray.push({
              file_name: filename,
              ext: fileExtension,
              file: file,
              fieldName: fieldName,
            });
          }
          this.progressValue=45;
          setTimeout(async () => {
            console.log('WWWWWWWWWWWWWWWWWWWWWWW')
            await this.file_uploadWhenBrowse();
          }, 700);
          

          // Log the entire fileInfoArray
          console.log('File Info Array:', this.fileInfoArray);
        }
      }
    } else {
      this.documentInputErrors[fieldName] = 'No file selected';
      console.log('No file selected');
    }
    console.log(
      'this.documentInputErrors[fieldName]',
      this.documentInputErrors[fieldName]
    );
  }

  isImageFile(fileType: string): boolean {
    return ['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(fileType);
  }

  async onSubmit() {
    this.notifier.loading(true);
    this.pagination = { perPage: 10, page: 1 };
    this.pagination2 = { perPage: 10, page: 1 };
    this.submitted = true;
    console.log('addcom', this.addcom);
    console.log('isEditMode', this.isEditMode);
    console.log('this.myForm.valid', this.myForm.valid);
    Object.values(this.myForm.controls).forEach((control: any) => {
      control.markAsTouched();
    });
    // this.isSubmitted = true;
    console.log('Forms values', this.myForm.value);
    this.validateFormValues(this.myForm.value);
    console.log('Forms values after validate', this.myForm.value);
    console.log('Form', this.myForm);
    // console.log('Form Status', this.myForm.status);
    if (this.bulkFileUpload && this.isEditMode) {
      console.log(
        'this.regTouched',
        this.regTouched,
        'this.fileInfoArray.length',
        this.fileInfoArray.length
      );
      // if (this.regTouched && this.fileInfoArray.length !== 0) {
      //   Object.values(this.myForm.controls).forEach((control: any) => {
      //     control.markAsTouched();
      //   });
      //   this.assignAmendmentToBulkUp(this.myForm.value);
      // }
      if (this.myForm.valid) {
        this.updateValue(this.myForm.value);
        console.log(this.fileInfoArray.length);
        this.fileInfoArray = [];
        this.isEditMode = false;
        this.addcom = false;
        this.updated = {};
        this.regTouched = false;
        this.documentInputErrors = {};
      } else {
        this.notifier.alert(
          'Info',
          '',
          'Please provide the mandatory fields',
          'info',
          5000
        );
      }
    } else if (this.isEditMode) {
      // if (Object.values(this.updated).some((value) => value === true)) {
      //   await this.file_upload();
      // } else {
      //   await this.updateVehicle();
      // }

      console.log('!!!!!!!!!!!!!!!!!!!!!!!!!')
      if (this.uploadedVar){
        this.updateVehicle(this.fileInfoArray);
      } else{
        this.updateVehicle();
      }
      this.updateForm = false
    } else {
      console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@')
      console.log('this.uploadedVar',this.uploadedVar)
      console.log('this.fileInfoArray',this.fileInfoArray)
      // await this.file_upload();
      if (this.uploadedVar){

        this.addvehicles(this.fileInfoArray);
      } else{
        this.addvehicles('');
      }
    }
    this.notifier.loading(false);
  }


  async file_uploadWhenBrowse() {
    if (this.fileInfoArray.length !== 0) {
      this.progressValue=80;
      let data: any = {
        action: 'file_upload',
        registration_no: this.myForm.get('reg_num').value,
        files: this.fileInfoArray.map((file: any) => {
          return { file_name: file.file_name, ext: file.ext };
        }),
      };
      console.log('DATA:', data);
      let header = {
        Authorization: localStorage.getItem('t'),
        // 'X-Api-Key': localStorage.getItem('clientid'),
      };

      let apiURL = `${this.config.apiURL}/admin/vehicles`;

      let result = await this.apiService.postDataPromis(apiURL, data, header);

      if (result.s == '1' || result.status == '1') {
        this.uploadedVar = true
        console.log('RESULT:', result);
        this.progressValue=100;
        console.log('100%')
        setTimeout(() => {
          this.progressBar = false;
          console.log("myVariable is now false after 2 seconds");
        }, 2000);
        // setTimeout(() => {
        //   this.progressBar = false;
        // }, 5000);
        this.uploadFilesWhenBrowse(result.data, this.fileInfoArray);
      } else {
        this.notifier.alert('Info', '', result.error, 'info', 5000);
      }
    }  
  }


  uploadFilesWhenBrowse(data: any, files: any) {
    console.log('inside the upload file after browse the file.');
    
      let promises: Promise<any>[] = [];
      Object.keys(data).forEach((file_name: string, index: number) => {
        files[index]['formcontro_name'] = file_name;
        console.log('@@@@@@@@@@files:', files)
        files[index]['status'] = 'pending';
        console.log(data[file_name]['presigned_url']);
        promises.push(
          this.uploadFileAPIWhenBrowse(files[index], data[file_name]['presigned_url'])
        );
        this.fetch([file_name]);
      });
  }


  uploadFileAPIWhenBrowse(file: any, url: string): Promise<any> {
    console.log('inside the upload file API after browse file.');

    return new Promise(async (resolve: any, reject: any) => {
      this.uploadFile
        .uploadFile(url, file['file'])
        .subscribe(async (event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              break;
            case HttpEventType.ResponseHeader:
              break;
            case HttpEventType.UploadProgress:
              file['status'] = 'uploading';
              let progress = Math.round(
                (event.loaded / (event.total || file['file']['size'])) * 100
              );
              file.loaded = event.loaded;
              file.percentage = progress;
              console.log('#####file1',file)
              break;
            case HttpEventType.Response:
              file['status'] = 'completed';
              resolve(true);
          }
        });
    });
  }



  // async file_upload() {
  //   if (this.myForm.valid) {
  //     if (this.fileInfoArray.length !== 0) {
  //       let data: any = {
  //         action: 'file_upload',
  //         company_code:
  //           this.clientNamesInfo[(this.myForm.get('veh_own').value)[0]][
  //             'company_code'
  //           ],
  //         client_id:
  //           this.clientNamesInfo[this.myForm.get('veh_own').value]['client_id'],
  //         files: this.fileInfoArray.map((file: any) => {
  //           return { file_name: file.file_name, ext: file.ext };
  //         }),
  //       };
  //       console.log('DATA:', data);
  //       let header = {
  //         Authorization: localStorage.getItem('t'),
  //         // 'X-Api-Key': localStorage.getItem('clientid'),
  //       };

  //       let apiURL = `${this.config.apiURL}/admin/vehicles`;

  //       let result = await this.apiService.postDataPromis(apiURL, data, header);

  //       if (result.s == '1' || result.status == '1') {
  //         console.log('RESULT:', result);

  //         this.uploadFiles(result.data, this.fileInfoArray);
  //       } else {
  //         this.notifier.alert('Info', '', result.error, 'info', 5000);
  //       }
  //     } else {
  //       this.uploadFiles('', []);
  //     }
  //   } else {
  //     this.notifier.alert(
  //       'Info',
  //       '',
  //       'Please provide mandatory fields',
  //       'info',
  //       5000
  //     );
  //   }
  // }

  async file_upload() {
    if (this.myForm.valid) {
      if (this.fileInfoArray.length !== 0) {
        let data: any = {
          action: 'file_upload',
          registration_no: this.myForm.get('reg_num').value,
          files: this.fileInfoArray.map((file: any) => {
            return { file_name: file.file_name, ext: file.ext };
          }),
        };
        console.log('DATA:', data);
        let header = {
          Authorization: localStorage.getItem('t'),
          // 'X-Api-Key': localStorage.getItem('clientid'),
        };

        let apiURL = `${this.config.apiURL}/admin/vehicles`;

        let result = await this.apiService.postDataPromis(apiURL, data, header);

        if (result.s == '1' || result.status == '1') {
          console.log('RESULT:', result);
          this.uploadedVar = true

          this.uploadFiles(result.data, this.fileInfoArray);
        } else {
          this.notifier.alert('Info', '', result.error, 'info', 5000);
        }
      } else {
        this.uploadFiles('', []);
      }
    } else {
      this.notifier.alert(
        'Info',
        '',
        'Please provide mandatory fields',
        'info',
        5000
      );
    }
  }


  uploadFiles(data: any, files: any) {
    console.log('inside the upload file');
    if (data !== '') {
      let promises: Promise<any>[] = [];
      Object.keys(data).forEach((file_name: string, index: number) => {
        // const key = Object.keys(data).find(key => files[index].file_name.includes(key));
        // if (key) {
        //   console.log("object as filename : ", key);
        // }
        files[index]['formcontro_name'] = file_name;

        files[index]['status'] = 'pending';
        console.log(data[file_name]['presigned_url']);
        promises.push(
          this.uploadFileAPI(files[index], data[file_name]['presigned_url'])
        );
      });

      Promise.all(promises).then((values: any) => {
        // Save Company
        if (this.isEditMode) {
          this.updateVehicle(files);
        } else {
          if (!this.bulkFileUpload) {
            this.addvehicles(files);
          }
        }
      });
    } else {
      if (!this.bulkFileUpload) {
        if (this.isEditMode) {
          this.updateVehicle(files);
        } else {
          this.addvehicles('');
        }
      }
    }
  }

  uploadFileAPI(file: any, url: string): Promise<any> {
    console.log('inside the upload file API');

    return new Promise(async (resolve: any, reject: any) => {
      this.uploadFile
        .uploadFile(url, file['file'])
        .subscribe(async (event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              break;
            case HttpEventType.ResponseHeader:
              break;
            case HttpEventType.UploadProgress:
              file['status'] = 'uploading';
              let progress = Math.round(
                (event.loaded / (event.total || file['file']['size'])) * 100
              );
              file.loaded = event.loaded;
              file.percentage = progress;
              console.log('#####file2',file)
              break;
            case HttpEventType.Response:
              file['status'] = 'completed';
              resolve(true);
          }
        });
    });
  }

  async addvehicles(files: any) {
    // this.notifier.loading(true);
    if (this.myForm.valid) {
      let payload_files: any = {};
      if (files !== '') {
        files.forEach((file: any) => {
          // Iterate over each field in the form
          payload_files[file.fieldName] = file.formcontro_name;
        });
        console.log(payload_files);
      } else {
        payload_files['reg_plate_photo'] = 'NA';
      }
      console.log(
        "payload_files['reg_plate_photo']",
        payload_files['reg_plate_photo']
      );
      console.log('files:', files);
      const formValues = this.myForm.value;
      console.log('formValues:', formValues);
      let stringOfCompanyCode = '';
      let stringOfClientID = '';

      for (let i = 0; i < formValues.veh_own.length; i++) {
        stringOfCompanyCode +=
          this.clientNamesInfo[formValues.veh_own[i]]['company_code'] + ',';
        stringOfClientID +=
          this.clientNamesInfo[formValues.veh_own[i]]['client_id'] + ',';
      }
      let stringOfVehicleOwner = formValues.veh_own.join(', ');
      const upperCaseString = (formValues.reg_num || '').replace(/[a-z]/g, (match: string) => match.toUpperCase());

      console.log('upperCaseString:',upperCaseString)
      let data: any = {
        action: 'add',
        registration_number: upperCaseString,
        company_code: stringOfCompanyCode,
        client_id: stringOfClientID,
        registration_image_s3_path: payload_files['reg_plate_photo'],
        vehicle_name: formValues.veh_name,
        vehicle_owner: stringOfVehicleOwner,
        engine_type: formValues.model,
        vehicle_manufacturer: formValues.manufacturer,
        vehicle_manufacturer_year: formValues.man_year,
        // vehicle_registration_date: formValues.veh_reg_date,
        // vehicle_registration_expiry_date: formValues.veh_reg_exp_date,
        vehicle_type: formValues.veh_type,
        vehicle_color: formValues.veh_col,
        vehicle_mode: formValues.veh_mode,
        // vehicle_status: formValues.status,
        ac_manufacturer: formValues.ac_manufacturer,
        seating_capacity: formValues.seating_cap,
        seating_type: formValues.seating_type,
        fuel_type: formValues.fuel_type,
        fuel_tank_capacity: formValues.fuel_tank_cap,
        fav: formValues.fav,
        compatible_tyre: formValues.compatible_type,
        no_tyres: formValues.no_of_tyers,
      };

      let header = {
        Authorization: localStorage.getItem('t'),
        // 'X-Api-Key': localStorage.getItem('clientid'),
      };

      // //https://api.swayam.cloud/v3/admin/users/fetchaccountgroupsv2
      let apiURL = `${this.config.apiURL}/admin/vehicles`;

      let result = await this.apiService.postDataPromis(apiURL, data, header);

      if (result.s == '1' || result.status == '1') {
        // console.log(result);
        this.showPopup(
          'Vehicle Added',
          'The vehicle has been successfully added to the list.'
        );
        this.addcom = false;
        this.isEditMode = false;
        this.submitted = false;
        this.fileInfoArray = [];
        this.uploadedVar = false;
        this.clearForm();
        this.fetchvehicles();

        // this.notifier.alert('Success', '', result.data, 'success', 5000);
      } else {
        this.notifier.alert('Info', '', result.error, 'info', 5000);
      }
    } else {
      this.notifier.alert(
        'Info',
        '',
        'Please provide mandatory fields',
        'info',
        5000
      );
    }
    // this.notifier.loading(false);
  }

  fileInfoArray: {
    file_name: string;
    ext: string;
    file: File;
    fieldName: string;
  }[] = [];

  async updateVehicle(files?: any) {
    console.log('inside update vehicle');
    // this.notifier.loading(true);
    if (this.myForm.valid) {
      let payload_files: any = {};
      if (files) {
        files.forEach((file: any) => {
          // Iterate over each field in the form
          payload_files[file.fieldName] = file.formcontro_name;
        });
      }

      let registration_plate_photo: any;
      const formValues = this.myForm.value;

      if (this.isEditMode) {
        console.log('payload files', payload_files);
        console.log(Object.keys(this.updated).length);
        registration_plate_photo = this.fileNames[0];
        console.log(registration_plate_photo);
        console.log('this.updated is empty', Object.keys(this.updated).length);

        if (Object.keys(this.updated).length !== 0) {
          const updatedKeys = Object.keys(this.updated);
          console.log('Updated files', updatedKeys);
          updatedKeys.forEach((key) => {
            // if (payload_files.hasOwnProperty(key)) {
            switch (key) {
              case 'registration_plate_photo':
                registration_plate_photo = payload_files['reg_plate_photo'];
                break;
            }
            // }
          });

          const remainingKeys = Object.keys(this.fileNames).filter(
            (key) => !payload_files.hasOwnProperty(key)
          );

          remainingKeys.forEach((key, index) => {
            switch (key) {
              case 'registration_plate_photo':
                registration_plate_photo = this.fileNames[0];
                break;
            }
          });
        }
      }
      let stringOfCompanyCode = '';
      let stringOfClientID = '';
      let listOfOwner;
      console.log('@@@@formValues:', formValues)
      if (Array.isArray(formValues.veh_own)){
        listOfOwner = formValues.veh_own;
      }
      else{
        listOfOwner = formValues.veh_own.split(", ");
      }

      for (let i = 0; i < listOfOwner.length; i++) {
        stringOfCompanyCode +=
          this.clientNamesInfo[listOfOwner[i]]['company_code'] + ',';
        stringOfClientID +=
          this.clientNamesInfo[listOfOwner[i]]['client_id'] + ',';
      }
      
      
      let stringOfVehicleOwner = listOfOwner.join(', ');
      console.log('stringOfCompanyCode', stringOfCompanyCode);
      console.log('stringOfClientID', stringOfClientID);
      console.log('the path should be sent to DB', registration_plate_photo);
      let data: any = {
        action: 'update',
        registration_number: formValues.reg_num,
        company_code: stringOfCompanyCode,
        client_id: stringOfClientID,
        registration_image_s3_path: registration_plate_photo,
        vehicle_name: formValues.veh_name,
        vehicle_owner: stringOfVehicleOwner,
        engine_type: formValues.model,
        vehicle_manufacturer: formValues.manufacturer,
        vehicle_manufacturer_year: formValues.man_year,
        // vehicle_registration_date: formValues.veh_reg_date,
        // vehicle_registration_expiry_date: formValues.veh_reg_exp_date,
        vehicle_type: formValues.veh_type,
        vehicle_color: formValues.veh_col,
        vehicle_mode: formValues.veh_mode,
        // vehicle_status: formValues.status,
        ac_manufacturer: formValues.ac_manufacturer,
        seating_capacity: formValues.seating_cap,
        seating_type: formValues.seating_type,
        fuel_type: formValues.fuel_type,
        fuel_tank_capacity: formValues.fuel_tank_cap,
        fav: formValues.fav,
        compatible_tyre: formValues.compatible_type,
        no_tyres: formValues.no_of_tyers,
      };

      let header = {
        Authorization: localStorage.getItem('t'),
        // 'X-Api-Key': localStorage.getItem('clientid'),
      };

      //https://api.swayam.cloud/v3/admin/users/fetchaccountgroupsv2
      let apiURL = `${this.config.apiURL}/admin/vehicles`;

      let result = await this.apiService.postDataPromis(apiURL, data, header);

      if (result.s == '1' || result.status == '1') {
        // console.log(result);
        this.showPopup(
          'Vehicle Updated',
          'The vehicle has been successfully updated to the list.'
        );
        this.addcom = false;
        this.updated = {};
        this.isEditMode = false;
        this.uploadedVar = false;
        console.log(result);
        this.submitted = false;
        this.fileInfoArray = [];
        this.clearForm();
        this.fetchvehicles();
        // this.notifier.alert('Success', '', result.data, 'success', 5000);
      } else {
        this.notifier.alert('Info', '', result.error, 'info', 5000);
      }
    } else {
      this.notifier.alert(
        'Info',
        '',
        'Please provide mandatory fields',
        'info',
        5000
      );
    }
    // this.notifier.loading(false);
  }

  async bulkUpload(vehicle_info: any) {
    console.log('@@@@@@@vehicle_info', vehicle_info)
    let data: any = {
      action: 'bulk_upload',
      vehicles: vehicle_info,
      clients_info: this.clientNamesInfo,
    };
    let header = {
      Authorization: localStorage.getItem('t'),
      // 'X-Api-Key': localStorage.getItem('clientid'),
    };

    // //https://api.swayam.cloud/v3/admin/users/fetchaccountgroupsv2
    let apiURL = `${this.config.apiURL}/admin/vehicles`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.s == '1' || result.status == '1') {
      // console.log(result);
      this.addcom = false;
      this.isEditMode = false;
      this.submitted = false;
      this.fileInfoArray = [];
      this.clearForm();
      this.fetchvehicles();
      this.bulkFileUpload = false;

      this.notifier.alert('', '', result.message, 'success', 5000);
    } 
    else {
      this.notifier.alert('Info', '', result.error_message, 'info', 5000);
    }
  }

  queryResult(event: any) {
    console.log('SSSSSSSSSS')
    let queryString: string = (event.target.value || '').toLowerCase();
    if (!queryString) {
      this.filteredData = this.result;
      return;
    }

    this.filteredData = this.result.filter((item: any) => {
      return this.headers.filter((h: any) => {
        console.log('11111111111111',(
          h.id.includes('vehicles_details')
            ? item.vehicles_details[h.id.split('.')[1]]
            : item[h.id]
        ))
        return (
          h.id.includes('vehicles_details')
            ? item.vehicles_details[h.id.split('.')[1]]
            : item[h.id]
        )
        .toString()
          .toLowerCase()
          .includes(queryString);
      }).length;
    });
  }

  filteredData: any = [];
  // async fetchvehicles() {
  //   this.notifier.loading(true);
  //   let data: any = {
  //     action: 'fetch_all',
  //   };
  //   let header = {
  //     Authorization: localStorage.getItem('t'),
  //   };
  //   let apiURL = `${this.config.apiURL}/admin/vehicles`;
  //   let result = await this.apiService.postDataPromis(apiURL, data, header);
  //   if (result.s == '1' || result.status == '1') {
  //     this.result = result.data;
  //     this.filteredData = result.data;
  //     this.exportVar = result.data;
  //     console.log('fetch in companies', result);
  //   } else {
  //     this.notifier.alert('Info', '', result.error, 'info', 5000);
  //   }
  //   this.notifier.loading(false);
  // }

  async fetchvehicles() {
    if (this.pagination2.page === 1){
      this.notifier.loading(true);
      this.filteredData = [];
    }
    
    let data: any = {
      action: 'fetch_by_pagination',
      page: this.pagination2.page.toString(),
      perPage: this.pagination2.perPage.toString()
    };
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${this.config.apiURL}/admin/vehicles`;
    let result = await this.apiService.postDataPromis(apiURL, data, header);
    if (result.s == '1' || result.status == '1') {
      this.pagination2.nextPage = result.nextPage;
      this.filteredData = this.filteredData.concat(result.data);
      this.result = this.filteredData
      this.exportVar = this.filteredData

      if (this.pagination2.page === 1){
        this.notifier.loading(false);
      }
      if (this.pagination2.nextPage) {
        this.pagination2.page = this.pagination2.nextPage;
        this.fetchvehicles();
      }
      // console.log('fetch in companies', result);
    } else {
      // this.notifier.loading(false);
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
  }
  
  fetchAllVehicles() {
    if (this.pagination2.nextPage) {
      this.pagination2.page = this.pagination2.nextPage;
      this.fetchvehicles();
    }
  }


  async openModal(selectedCompany: any) {
    this.notifier.loading(true);
    this.showModal = true;
    this.updateForm = true
    await this.populateForm(selectedCompany); // Pass selected company object to populateForm
    this.isEditMode = true;
    this.notifier.loading(false);
    if (typeof selectedCompany.vehicles_details.vehicle_owner == 'string') {
      selectedCompany.vehicles_details.vehicle_owner =
        selectedCompany.vehicles_details.vehicle_owner.split(', ');
    }
    $('#veh_own').selectpicker();
    setTimeout(() => {
      $('#veh_own').val(selectedCompany.vehicles_details.vehicle_owner);
      setTimeout(() => {
        $('#veh_own').selectpicker('refresh');
      }, 1);
    }, 1);
    this.disableForModal()
  }

  
  disableForModal() {
    Object.keys(this.myForm.controls).forEach(control => {
      this.myForm.get(control)?.disable();
    });
  }

  // Function to enable all form fields
  enableForModal() {
    Object.keys(this.myForm.controls).forEach(control => {
      this.myForm.get(control)?.enable();
    });
  }


  hideModal() {
    this.showModal = false;
  }
  toggleEditMode(): void {
    this.isEditMode = !this.isEditMode;
  }
  async editvehicle(selectedCompany: any) {
    this.updateForm = true
    this.notifier.loading(true);
    console.log('inside edit client');
    console.log('selectedCompany', selectedCompany);
    console.log('this.updated', this.updated);
    await this.populateForm(selectedCompany); // Pass selected company object to populateForm
    this.addcom = true; // Set flag to indicate edit mode
    this.isEditMode = true;
    this.expandedIndex = null;
    console.log('this.fileNames[0]', this.fileNames[0]);
    this.notifier.loading(false);
    console.log('this.fileInfoArray', this.fileInfoArray);
    if (typeof selectedCompany.vehicles_details.vehicle_owner == 'string') {
      selectedCompany.vehicles_details.vehicle_owner =
        selectedCompany.vehicles_details.vehicle_owner.split(', ');
    }
    $('#veh_own').selectpicker();
    setTimeout(() => {
      $('#veh_own').val(selectedCompany.vehicles_details.vehicle_owner);
      setTimeout(() => {
        $('#veh_own').selectpicker('refresh');
      }, 1);
    }, 1);
  }

  // async viewVehicle(selectedCompany: any) {
  //   this.showModal = true;
  //   this.updateForm = true
  //   this.notifier.loading(true);
  //   console.log('inside edit client');
  //   console.log('selectedCompany', selectedCompany);
  //   console.log('this.updated', this.updated);
  //   await this.populateForm(selectedCompany); // Pass selected company object to populateForm
  //   this.addcom = true; // Set flag to indicate edit mode
  //   this.isEditMode = true;
  //   console.log('this.fileNames[0]', this.fileNames[0]);
  //   this.notifier.loading(false);
  //   console.log('this.fileInfoArray', this.fileInfoArray);
  //   if (typeof selectedCompany.vehicles_details.vehicle_owner == 'string') {
  //     selectedCompany.vehicles_details.vehicle_owner =
  //       selectedCompany.vehicles_details.vehicle_owner.split(', ');
  //   }
  //   $('#veh_own').selectpicker();
  //   setTimeout(() => {
  //     $('#veh_own').val(selectedCompany.vehicles_details.vehicle_owner);
  //     setTimeout(() => {
  //       $('#veh_own').selectpicker('refresh');
  //     }, 1);
  //   }, 1);
  // }

  addCompany() {
    this.myForm.reset()
    this.addcom = true;

    setTimeout(() => {
      $('#veh_own').selectpicker();
    }, 10)
  }

  // vehicle_name_auto_fill('reg_num')
  vehicle_name_auto_fill(reg_num: any): void {
    const reg_number = this.myForm.get(reg_num)?.value;
    const stringWithoutSpaces = reg_number.replace(/\s/g, '');
    const lastFourDigits = stringWithoutSpaces.slice(-4);
    this.myForm.patchValue({
      veh_name: lastFourDigits,
    });
  }

  async clients_info() {
    let data: any = {
      action: 'clients_details',
    };
    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${this.config.apiURL}/admin/clientRoutes`;
    let result = await this.apiService.postDataPromis(apiURL, data, header);
    if (result.s == '1' || result.status == '1') {
      this.clientNamesInfo = result.message;
      console.log("client name's", this.clientNamesInfo);
      this.clientNamesList = Object.keys(this.clientNamesInfo);
      this.clientNamesList.sort((a: any, b: any) =>
        a.localeCompare(b, 'en', { sensitivity: 'base' })
      );
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
  }

  async fetch(file_paths: Array<any>) {
    console.log('inside fetch file fn');
    let data: any = {
      action: 'fetch_file',
      // company_code: this.myForm.get('companyCode').value,
      file_names: file_paths,
    };
    let header = {
      Authorization: localStorage.getItem('t'),
      // 'X-Api-Key': localStorage.getItem('clientid'),
    };

    let apiURL = `${this.config.apiURL}/admin/vehicles`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.s == '1' || result.status == '1') {
      this.fetchUrls = result.data;
      // this.mapFetchedFileNames(this.fetchUrls)
      // interface MatchingUrls {
      //     [key: string]: string;
      // }
      // const fileNames
      this.fileNames = [];
      this.fileNames = Object.keys(this.fetchUrls);

      // Now keysArray contains only the keys of this.fetchUrls
      console.log('keys: ', this.fileNames);

      // console.log("Presigned urls for files:",this.fetchUrls)
      // Assuming this.matchingUrls is declared somewhere in your code
      this.matchingUrls = {};

      for (const key in this.fetchUrls) {
        if (Object.prototype.hasOwnProperty.call(this.fetchUrls, key)) {
          if (key.includes('registration_plate_photo')) {
            this.matchingUrls['registration_plate_photo'] = this.fetchUrls[key];
          }
        }
      }

      console.log('Matching URL:', this.matchingUrls);

      const order = ['registration_plate_photo'];

      // Create an array of objects based on the defined order
      this.orderedUrls = order.map((key) => ({
        [key]: this.matchingUrls[key],
      }));

      console.log('Ordered URLs:', this.orderedUrls);
    }

    // console.log("File URLs: ", this.fetchUrls)
    else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
  }

  // expandedIndex: number | null = null;

  // toggleOptions(index: number) {
  //   this.expandedIndex = this.expandedIndex === index ? null : index;
  // }

  expandedIndex: number | null = null;

  toggleOptions(index: number) {
    this.expandedIndex = this.expandedIndex === index ? null : index;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    if (!target.closest('.options-container')) {
      this.expandedIndex = null;
    }
  }

  toggleOptionsExport() {
    console.log('toggling..');
    this.expandedButton = !this.expandedButton;
    console.log('this.expandedButton', this.expandedButton);
  }

  validateFormValues(data: any) {
    // if (data['status'] === '' || data['status'] === null) {
    //   data['status'] = 'NA';
    // }
    if (data['compatible_type'] === '' || data['compatible_type'] === null) {
      data['compatible_type'] = 0;
    }
    if (data['fav'] === '' || data['fav'] === null) {
      data['fav'] = 'NA';
    }
    if (data['no_of_tyers'] === '' || data['no_of_tyers'] === null) {
      data['no_of_tyers'] = 0;
    }
  }

  openFileExplorer() {
    this.fileInput.nativeElement.click();
  }

  fileSelected(event: any) {
    console.log('event is ', event);
    const selected_File: File = event.target.files[0];
    this.fileInput.nativeElement.value = '';
    console.log('selected File is ', selected_File);

    if (selected_File) {
      console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!');
      // Get the file name and extension
      const fileName: string = selected_File.name;
      const fileExtension: string = fileName.split('.').pop() || '';

      // Check if the file extension is valid
      if (fileExtension === 'xlsx' || fileExtension === 'xls') {
        console.log('Valid Excel file selected');
        // Read the Excel file
        const reader = new FileReader();
        reader.onload = (e: ProgressEvent<FileReader>) => {
          const data_of_file: any | ArrayBuffer | null = e.target?.result;
          if (typeof data_of_file === 'string') {
            // Parse the Excel file content
            const workbook = XLSX.read(data_of_file, { type: 'binary' });
            // Assuming there's only one sheet, you can change this according to your requirement
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];
            // Convert the sheet to JSON
            const jsonData = XLSX.utils.sheet_to_json(worksheet);
            // Now you can validate the content as per your requirements
            console.log('Excel content:', jsonData);
            let isnotValid = this.validateData(jsonData);
            console.log('isnotValid', isnotValid);
            if (isnotValid.length !== 0) {
              this.notifier.alert(
                'Info',
                '',
                'Please provide all Fields and with correct Information',
                'info',
                5000
              );
              //   return;
            } else {
              this.bulkFileUpload = true;
              this.updated = {};
              this.fileNames = [];
              console.log('&&&&&&&&&&&&&&&');
              // this.assingValueforExcel(jsonData);
              // this.contentList = jsonData;
              this.contentList = this.assingValueforExcel(jsonData);
              console.log('this.contentList', this.contentList);
              // for (let i of this.contentList) {
              //   console.log(typeof i);
              // }
              // this.bulkUpload(jsonData)
            }
            // Here you can perform validation on jsonData
            // For example, check if certain columns or values are present
          } else {
            console.error('Failed to read the file.');
          }
        };
        reader.readAsBinaryString(selected_File);
      } else {
        console.error('Invalid file format. Please select an Excel file.');
      }
      // this.fileInput.nativeElement.value = '';
    } else {
      console.error('No file selected.');
    }
  }

  validateData(data: any[]): string[] {
    const errors: string[] = [];
    if (data.length !== 0){
      for (let i = 0; i < data.length; i++) {
        const item = data[i];
        // Check if any required field is missing or empty
        const requiredFields = [
          'AC Manufacturer',
          'Fuel Tank Capacity',
          'Fuel Type',
          'Manufacturer',
          'Model Year',
          'Engine Type',
          'Number of Tyres',
          'Registration Number',
          'Seating Capacity',
          'Seating Type',
          'Vehicle Colour',
          'Vehicle Mode',
          'Vehicle Ownership',
          'Vehicle Type',
        ];
        // 'Number of Tyres', 'Status','Compatible Tyre',

        for (const field of requiredFields) {
          if (
            !(field in item) ||
            item[field] === null ||
            item[field] === undefined ||
            item[field] === ''
          ) {
            errors.push(`Row ${i + 1}: '${field}' is required.`);
          }
        }
        if (item['Registration Number']){
          if (item['Registration Number'].length !== 10) {
            errors.push(`Row ${i + 1}: Registration Number must be 10 characters long.`);
          }
        }
      }
      
      

      for (let i = 0; i < data.length; i++) {
        const item = data[i];

        // Check if any value is empty
        for (const key in item) {
          if (item[key] === undefined || item[key] === null || item[key] === '') {
            errors.push(`Row ${i + 1}: Empty value found in '${key}'`);
          }
        }
      }
    }else{
      errors.push('No data found')
    }
    return errors;
  }

  serialToDate(serial: number): Date {
    const baseDate = new Date('1899-12-30');
    const millisecondsInDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
    const daysToAdd = serial - 1; // Excel uses 1-based indexing
    const millisecondsToAdd = daysToAdd * millisecondsInDay;
    return new Date(baseDate.getTime() + millisecondsToAdd);
  }

  editVehicleForBulkUpload(selectedCompany: any): void {
    console.log('inside vehicle edit in bulk upload:', selectedCompany);
    this.populateFormOfBulkUpload(selectedCompany); // Pass selected company object to populateForm
    this.isEditMode = true;

    this.addcom = true; // Set flag to indicate edit mode
    this.selectedArrayForEdit = selectedCompany;
    let registrationNumber = selectedCompany['Registration Number'];
    for (var i = 0; i < this.contentList.length; i++) {
      if (this.contentList[i]['Registration Number'] === registrationNumber) {
        this.contentList[i]['Vehicle Name'] = registrationNumber
          .replace(/\s/g, '')
          .slice(-4);
        this.index = i;
        break;
      }
    }
    console.log('selected index', this.index);
    $('#veh_own').selectpicker();
    setTimeout(() => {
      $('#veh_own').val(selectedCompany['Vehicle Ownership']);
      setTimeout(() => {
        $('#veh_own').selectpicker('refresh');
      }, 1);
    }, 1);
  }

  async populateFormOfBulkUpload(selectedCompany: any): Promise<void> {
    this.notifier.loading(true);
    console.log('inside the populate form of bulkUpload');
    console.log(selectedCompany)
    // console.log(
    //   '=====>',
    //   selectedCompany['Vehicle Registration Date'],
    //   selectedCompany['Vehicle Registration Expiry Date']
    // );
    console.log(selectedCompany);
    console.log(selectedCompany.company_details);
    // Giving vehicle name by taking last 4 Character of Registration Number
    let lastFourDigits = selectedCompany['Registration Number']
      .replace(/\s/g, '')
      .slice(-4);
    console.log('lastFourDigits', lastFourDigits);
    if (selectedCompany) {
      console.log('inside the populate form');
      let favValue;
      if ('FAV' in selectedCompany) {
        favValue = selectedCompany['FAV'];
      } else {
        favValue = 'NA';
      }
      console.log('inside the populate form');
      this.sendUrls = [];
      if (selectedCompany['Registration Plate Photo']!== undefined){
        this.sendUrls.push(
          selectedCompany['Registration Plate Photo'][0]['formcontro_name']
        );
      }
      await this.fetch(this.sendUrls);
      console.log('Send urls', this.sendUrls);
      console.log('fileNames####:', this.fileNames);
      // console.log(
      //   'this.stringDate(selectedCompany["Vehicle Registration Date"])',
      //   this.stringDate(selectedCompany['Vehicle Registration Date'])
      // );
      this.myForm.patchValue({
        reg_num: selectedCompany['Registration Number'],

        // reg_plate_photo: this.fileNames[0],
        veh_name: lastFourDigits,
        veh_own: selectedCompany['Vehicle Ownership'],
        model: selectedCompany['Engine Type'],
        manufacturer: selectedCompany['Manufacturer'],
        man_year: selectedCompany['Model Year'],
        // veh_reg_date: selectedCompany['Vehicle Registration Date'],
        // veh_reg_exp_date: selectedCompany['Vehicle Registration Expiry Date'],
        veh_type: selectedCompany['Vehicle Type'],
        veh_col: selectedCompany['Vehicle Colour'],
        veh_mode: selectedCompany['Vehicle Mode'],
        // status: selectedCompany['Status'],
        ac_manufacturer: selectedCompany['AC Manufacturer'],
        seating_cap: selectedCompany['Seating Capacity'],
        seating_type: selectedCompany['Seating Type'],
        fuel_type: selectedCompany['Fuel Type'],
        fuel_tank_cap: parseFloat(selectedCompany['Fuel Tank Capacity']),
        fav: favValue,
        compatible_type: selectedCompany['Compatible Tyre'],
        no_of_tyers: selectedCompany['Number of Tyres'],
      });
    }
    this.notifier.loading(false);
  }

  stringDate(serial: number): string {
    // Excel date value
    var excelDate = serial + 1;

    // Convert Excel date to standard date format
    var standardDate = new Date(
      (excelDate - 1) * 24 * 60 * 60 * 1000 + Date.UTC(1899, 11, 30, 0, 0, 0)
    );

    // Adjust the date according to local timezone
    var localDate = new Date(
      standardDate.getTime() + standardDate.getTimezoneOffset() * 60 * 1000
    );

    // Extract year, month, and day from the standard date
    var year = localDate.getFullYear();
    var month = ('0' + (localDate.getMonth() + 1)).slice(-2);
    var day = ('0' + localDate.getDate()).slice(-2);

    // Form the string date in "YYYY-MM-DD" format
    var stringDate = year + '-' + month + '-' + day;

    return stringDate; // Output: 2024-03-01
  }

  onFileSelectedForBulkUpload(fieldName: string, filename: string, event: any) {
    this.progressBar = true;
    this.progressValue=0;
    const file = event.target.files?.[0];
    console.log('Selected File:', file);
    if (file) {
      // Get the file name without extension
      const fileNameWithoutExtension = file.name
        .split('.')
        .slice(0, -1)
        .join('.');
      console.log('File Name:', fileNameWithoutExtension);

      // Get the file extension
      const fileExtension = file.name.split('.').pop()?.toLowerCase() || '';
      console.log('File Extension:', fileExtension);

      if (file.size > 20 * 1024 * 1024) {
        this.documentInputErrors[fieldName] =
          'File size exceeds the limit (20MB)';
      } else {
        if (
          !this.isImageFile(fileExtension) &&
          file.type !== 'application/pdf'
        ) {
          this.documentInputErrors[fieldName] =
            'Unsupported file type. Only PDF and images are allowed.';
        } else {
          this.updated[filename] = true;
          this.documentInputErrors[fieldName] = null; // Clear error if file is valid

          // Check if an item with the same fieldName already exists
          const existingIndex = this.fileInfoArray.findIndex(
            (item) => item.fieldName === fieldName
          );
          // if (existingIndex !== -1) {
          //   // Replace the existing item with the new one
          //   this.fileInfoArray[existingIndex] = {
          //     file_name: filename,
          //     ext: fileExtension,
          //     file: file,
          //     fieldName: fieldName,
          //   };
          // } else {
          this.fileInfoArray = [];
          this.fileNames = [];
          // Push the new item into the fileInfoArray
          this.fileInfoArray.push({
            file_name: filename,
            ext: fileExtension,
            file: file,
            fieldName: fieldName,
          });
          // console.log(
          //   'this.regTouched',
          //   this.regTouched,
          //   'this.fileInfoArray.length',
          //   this.fileInfoArray.length
          // );
          Object.values(this.myForm.controls).forEach((control: any) => {
            control.markAsTouched();
          });
          this.assignAmendmentToBulkUp(this.myForm.value);
          let object = this.contentList[this.index]
          if (this.validateUploadFiles(object)) {
            // this.file_uploadForBulkUpload(object);
            this.progressValue=45;
            setTimeout(async () => {
              await this.file_uploadForBulkUpload(object);;
            }, 700);
          }
          console.log('File Info Array:', this.fileInfoArray);
          console.log('qqqqqqqqqqqqqqqqqqqq', this.fileInfoArray[0].file.name);
        }
      }
    } else {
      this.documentInputErrors[fieldName] = 'No file selected';
      console.log('No file selected');
    }
    console.log('contentList', this.contentList);
  }

  async assignAmendmentToBulkUp(formValues: any, files?: any) {
    console.log('fileInfoArray', this.fileInfoArray);
    console.log('this.contentList', this.contentList);
    console.log('formValues:', formValues);
    console.log('files:', files);
    this.contentList[this.index]['Registration Plate Photo'] =
      this.fileInfoArray;
    this.selectedArrayForEdit = this.contentList[this.index];
    console.log('this.contentList2', this.contentList);
  }

  
  async onSubmitAllDataBulkUp(files?: any) {
    this.notifier.loading(true);
    for (const object of this.contentList) {
      this.validateUploadFiles(object)
    }
    try {
      console.log('-------------------before');
      console.log('All uploads completed successfully');
      console.log('this.contentList', this.contentList);
      await this.bulkUpload(this.contentList);
    } catch (error) {
      console.error('Error during file upload:', error);
      this.notifier.alert(
        'Error',
        '',
        'Error during file upload',
        'error',
        5000
      );
    } finally {
      this.notifier.loading(false); // Hide loading indicator regardless of upload success or failure
    }
  }

  validateUploadFiles(object: any) {
    // object['Vehicle Name'] =
    let lastFourDigits = object['Registration Number']
      .replace(/\s/g, '')
      .slice(-4);
    object['Vehicle Name'] = lastFourDigits;
    console.log('rrrrrrrrrrrrrrrrrrrrrr', this.index);
    if (object['Registration Plate Photo']) {
      let item = object['Registration Plate Photo'][0];
      console.log('item===>', item);
      if (
        !(
          typeof item.file_name === 'string' &&
          typeof item.ext === 'string' &&
          typeof item.file === 'object' &&
          typeof item.fieldName === 'string'
        )
      ) {
        console.log('yyyyyyyyyyyyyyyyyyyyy');
        return false;
      }
      // }
      console.log('uuuuuuuuuuuuuuuuuuuuu');
      return true;
    } else {
      return false;
    }
  }

  async file_uploadForBulkUpload(object: any) {
    this.progressValue=80;
    return new Promise(async (resolve: any, reject: any) => {
      console.log('Inside file upload of bulk upload.');
      console.log('this.clientNamesInfo', this.clientNamesInfo);
      console.log('this.myForm', this.myForm);
      let data: any = {
        action: 'file_upload',
        registration_no: object['Registration Number'],
        files: object['Registration Plate Photo'].map((file: any) => {
          return { file_name: file.file_name, ext: file.ext };
        }),
      };
      let header = {
        Authorization: localStorage.getItem('t'),
        // 'X-Api-Key': localStorage.getItem('clientid'),
      };

      let apiURL = `${this.config.apiURL}/admin/vehicles`;

      let result = await this.apiService.postDataPromis(apiURL, data, header);
      console.log('QQQQQQQQQQQQQresult', result);

      if (result.s == '1' || result.status == '1') {
        console.log(result);
        this.uploadFilesWhenBrowse(result.data, object['Registration Plate Photo']);
        this.progressValue=100;
        this.progressBar = false;
      } else {
        this.notifier.alert('Info', '', result.error, 'info', 5000);
      }
      resolve(true);
    });
  }

  gotToHomePage() {
    this.addcom = false;
    this.isEditMode = false;
    this.submitted = false;
    this.fileInfoArray = [];
    this.clearForm();
    this.fetchvehicles();
    this.updated = {};
    this.fileNames = [];
    this.bulkFileUpload = false;
  }

  updateValue(data: any) {
    this.contentList[this.index]['Registration Number'] = data.reg_num;
    this.contentList[this.index]['Vehicle Ownership'] = data.veh_own;
    this.contentList[this.index]['Engine Type'] = data.model;
    this.contentList[this.index]['Manufacturer'] = data.manufacturer;
    this.contentList[this.index]['Model Year'] = data.man_year;
    // this.contentList[this.index]['Vehicle Registration Date'] =
    //   data.veh_reg_date;
    // this.contentList[this.index]['Vehicle Registration Expiry Date'] =
    //   data.veh_reg_exp_date;
    this.contentList[this.index]['Vehicle Type'] = data.veh_type;
    this.contentList[this.index]['Vehicle Colour'] = data.veh_col;
    this.contentList[this.index]['Vehicle Mode'] = data.veh_mode;
    // if (data.status !== '') {
    //   this.contentList[this.index]['Status'] = data.status;
    // }
    this.contentList[this.index]['AC Manufacturer'] = data.ac_manufacturer;
    this.contentList[this.index]['Seating Capacity'] = data.seating_cap;
    this.contentList[this.index]['Seating Type'] = data.seating_type;
    this.contentList[this.index]['Fuel Type'] = data.fuel_type;
    this.contentList[this.index]['Fuel Tank Capacity'] = data.fuel_tank_cap;
    console.log();
    if (
      data['compatible_type'] === '' ||
      data['compatible_type'] === null ||
      data['compatible_type'] === undefined
    ) {
      this.contentList[this.index]['Compatible Tyre'] = 0;
    } else {
      this.contentList[this.index]['Compatible Tyre'] = parseInt(
        data.compatible_type
      );
    }
    if (
      data['fav'] === '' ||
      data['fav'] === null ||
      data['fav'] === undefined
    ) {
      this.contentList[this.index]['FAV'] = 'NA';
    } else {
      this.contentList[this.index]['FAV'] = data.fav;
    }
    if (
      data['no_of_tyers'] === '' ||
      data['no_of_tyers'] === null ||
      data['no_of_tyers'] === undefined
    ) {
      this.contentList[this.index]['Number of Tyres'] = 0;
    } else {
      this.contentList[this.index]['Number of Tyres'] = parseInt(
        data.no_of_tyers
      );
    }
  }

  assingValueforExcel(data: any) {
    for (let i = 0; i < data.length; i++) {
      if (!data[i]['Compatible Tyre']) {
        data[i]['Compatible Tyre'] = 0;
      }
      if (!data[i]['Number of Tyres']) {
        data[i]['Number of Tyres'] = 0;
      }
      if (!data[i]['FAV']) {
        data[i]['FAV'] = 'NA';
      }
    }
    return data;
  }

  headers3: any = [
    {
      id: 'client_id',
      name: 'Client ID',
    },
    {
      id: 'registration_number',
      name: 'Registration Number',
    },
    {
      id: 'registration_image_s3_path',
      name: 'Registration Image S3 Path',
    },
    {
      id: 'vehicle_name',
      name: 'Vehicle Name',
    },
    {
      id: 'vehicle_owner',
      name: 'Vehicle Owner',
    },
    {
      id: 'engine_type',
      name: 'Engine Type',
    },
    {
      id: 'vehicle_manufacturer',
      name: 'Vehicle Manufacturer',
    },
    {
      id: 'vehicle_manufacturer_year',
      name: 'Vehicle Manufacturer Year',
    },
    {
      id: 'vehicle_type',
      name: 'Vehicle Type',
    },
    {
      id: 'vehicle_color',
      name: 'Vehicle Colour',
    },
    {
      id: 'vehicle_mode',
      name: 'Vehicle Mode',
    },
    {
      id: 'ac_manufacturer',
      name: 'AC Manufacturer',
    },
    {
      id: 'seating_capacity',
      name: 'Seating Capacity',
    },
    {
      id: 'seating_type',
      name: 'Seating Type',
    },
    {
      id: 'fuel_type',
      name: 'Fuel Type',
    },
    {
      id: 'fuel_tank_capacity',
      name: 'Fuel Tank Capacity',
    },
    {
      id: 'fav',
      name: 'Favorite',
    },
    {
      id: 'compatible_tyre',
      name: 'Compatible Tyre',
    },
    {
      id: 'no_tyres',
      name: 'Number of Tyres',
    },
  ];

  exportEach(maps: any) {
    this.notifier.loading(true);
    let vech_detail: any;
    let data: any = [
      {
        sheetName: 'Vehicle Details',
        data: [
          this.headers3.map((h: any) => {
            return { text: h.name };
          }),
        ],
      },
    ];
    let sheetData: any = maps;
    let headers: any = this.headers3;

    sheetData.map((item: any) => {
      vech_detail = item.vehicles_details;
      let data_: any = [];
      headers.forEach((h: any) => {
        // data_.push({ text: h.alter ? h.alter(item, h.id) : item[h.id] });
        data_.push({ text: vech_detail[h.id] });
      });

      data[0]['data'].push(data_);
    });

    var options = {
      fileName: 'Vehicle Details',
    };
    console.log('data' + JSON.stringify(data));

    Jhxlsx.export(data, options);
    this.notifier.loading(false);
  }

  async get_excel_url() {
    this.notifier.loading(true);

    let data: any = {
      action: 'get_vehicle_csv',
    };
    let header = {
      Authorization: localStorage.getItem('t'),
      // 'X-Api-Key': localStorage.getItem('clientid'),
    };

    //https://api.swayam.cloud/v3/admin/users/fetchaccountgroupsv2
    let apiURL = `${this.config.apiURL}/admin/vehicles`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.s == '1' || result.status == '1') {
      // console.log(result);

      let pre_url = result.data;
      console.log('Presign Url for', pre_url);
      let a = document.createElement('a');
      a.href = pre_url;
      a.download = 'true';
      a.setAttribute('target', '_blank');
      a.click();
      // this.notifier.alert('Success', '', result.data, 'success', 5000);
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
    this.notifier.loading(false);
  }

  hidepopup(event: any) {
    this.popUp = false;
  }

  showPopup(header: any, title: any) {
    this.popup_header = header;
    this.popup_subtitle = title;
    this.popUp = true;
  }

  selectedOptions: any = [];
  // onOptionsSelected(event:any) {
  //   console.log('event:', event)
  //   // console.log('value',event.target.selectedOptions.option)
  //   this.selectedOptions = Array.from(event.target.selectedOptions, (option: any) => option.value);
  //   // this.selectedOptions.push(event.target.selectedOptions, (option: any) => option.value)
  //   console.log(this.selectedOptions)
  // }
  onOptionsSelected(event: any) {
    const selectedValues = Array.from(
      event.target.selectedOptions,
      (option: any) => option.value
    );
    this.selectedOptions.push(...selectedValues);
    console.log(this.selectedOptions);
  }


  goBack(){
    this.modalMessage = 'Are you sure you want to discard the changes?';
  }

  clearMessage(){
    this.modalMessage = 'This will discarded the changes?';
  }

  prevent(event:any){
    event.preventDefault();
  }

  refershFunction(){
    this.pagination = {perPage: 10, page: 1, };
    this.pagination2 = {perPage: 10, page: 1, };
    this.fetchvehicles()
  }
}