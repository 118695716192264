<div class="card mb-2">
  <h5 class="card-header">User Management</h5>
  <div class="card-body">
    <form>
      <div class="form-group mb-2">
        <label for="exampleInputEmail1">User Name</label>
        <input
          autocomplete="off"
          type="text"
          class="form-control"
          placeholder="Enter User Name"
          [(ngModel)]="userDetails.un"
          name="username"
        />
        <div *ngIf="errors['un']['error']" class="error-message">
          <i aria-hidden="true" class="fa fa-exclamation-triangle"></i>
          {{ errors["un"]["message"] }}
        </div>
      </div>
      <div class="form-group mb-2">
        <label for="exampleInputEmail1">Phone Number</label>
        <input
          autocomplete="off"
          type="text"
          class="form-control"
          placeholder="Enter Phone Number"
          [(ngModel)]="userDetails.ph"
          name="phonenumber"
        />
        <div *ngIf="errors['ph']['error']" class="error-message">
          <i aria-hidden="true" class="fa fa-exclamation-triangle"></i>
          {{ errors["ph"]["message"] }}
        </div>
      </div>
      <div class="form-group mb-2">
        <label for="exampleInputEmail1">Company</label>
        <input
          autocomplete="off"
          type="text"
          class="form-control"
          placeholder="Enter Company Name"
          [(ngModel)]="userDetails.com"
          name="company"
        />
        <div *ngIf="errors['com']['error']" class="error-message">
          <i aria-hidden="true" class="fa fa-exclamation-triangle"></i>
          {{ errors["com"]["message"] }}
        </div>
      </div>
      <div class="form-group mb-2">
        <label for="exampleInputEmail1">Designation</label>
        <input
          autocomplete="off"
          type="text"
          class="form-control"
          placeholder="Enter Designation"
          [(ngModel)]="userDetails.des"
          name="designation"
        />
        <div *ngIf="errors['des']['error']" class="error-message">
          <i aria-hidden="true" class="fa fa-exclamation-triangle"></i>
          {{ errors["des"]["message"] }}
        </div>
      </div>
      <button type="submit" class="btn btn-primary" (click)="updateUser()">
        Update
      </button>
    </form>
  </div>
</div>
<div class="card">
  <h5 class="card-header">Change Password</h5>
  <div class="card-body">
    <form>
      <div class="form-group mb-2">
        <label for="exampleInputEmail1">Existing Password</label>
        <input
          autocomplete="off"
          type="password"
          name="oldPass"
          [(ngModel)]="changePassword.oldPass"
          class="form-control"
          placeholder="Enter Old Password"
        />
        <div *ngIf="errors['oldPassword']['error']" class="error-message">
          <i aria-hidden="true" class="fa fa-exclamation-triangle"></i>
          {{ errors["oldPassword"]["message"] }}
        </div>
      </div>
      <div class="form-group mb-2">
        <label for="exampleInputEmail1">New Password</label>
        <input
          autocomplete="off"
          type="password"
          name="newPass"
          [(ngModel)]="changePassword.newPass"
          class="form-control"
          placeholder="Enter New Password"
        />
        <div *ngIf="errors['password']['error']" class="error-message">
          <i aria-hidden="true" class="fa fa-exclamation-triangle"></i>
          {{ errors["password"]["message"] }}
        </div>
      </div>
      <div class="password-policy-container">
        <div
          *ngFor="
            let policy_key of getKeys(
              passwordChecker(this.changePassword.newPass)['policies']
            );
            let i = index
          "
          [ngClass]="{
            correct: passwordChecker(this.changePassword.newPass)['policies'][
              policy_key
            ]
          }"
          class="password-policy flex-start flex-start-center"
        >
          <i
            *ngIf="
              !passwordChecker(this.changePassword.newPass)['policies'][
                policy_key
              ]
            "
            aria-hidden="true"
            class="fa fa-times-circle"
          ></i>
          <i
            *ngIf="
              passwordChecker(this.changePassword.newPass)['policies'][
                policy_key
              ]
            "
            aria-hidden="true"
            class="fa fa-check-circle"
          ></i>
          <span>
            {{ policy_key }}
          </span>
        </div>
      </div>
      <div class="form-group mb-2">
        <label for="exampleInputEmail1">Confirm Password</label>
        <input
          autocomplete="off"
          type="password"
          name="newConfPass"
          [(ngModel)]="changePassword.newConfPass"
          class="form-control"
          placeholder="Confirm New Password"
        />
        <div *ngIf="errors['confPassword']['error']" class="error-message">
          <i aria-hidden="true" class="fa fa-exclamation-triangle"></i>
          {{ errors["confPassword"]["message"] }}
        </div>
      </div>
      <button
        type="submit"
        class="btn btn-primary"
        (click)="changePasswordAction()"
      >
        Update
      </button>
    </form>
  </div>
</div>
