import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APIService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';

@Component({
  selector: 'app-new-menus',
  templateUrl: './new-menus.component.html',
  styleUrls: ['./new-menus.component.css'],
})
export class NewMenusComponent {
  userId: string | null = localStorage.getItem('eId');
  menus: any = [];

  router: any;
  type: string | null = null;
  config: any;

  constructor(
    private apiServive: APIService,
    public notifier: NotifierService,
    public activeRoute: ActivatedRoute
  ) {
    this.config = this.activeRoute.snapshot.data['config'];
  }

  ngOnInit(): void {
    this.load();
  }

  hasSpecialCharacters(input: string) {
    const regex = /[^a-zA-Z0-9]/;
    return regex.test(input);
  }

  async load() {
    this.notifier.loading(true);
    this.menus = [];
    let data: any = { a: 'fetchmenus' };

    let headers: any = {
      Authorization: localStorage.getItem('sessiontoken'),
      'X-Api-Key': localStorage.getItem('clientid'),
    };

    let apiURL: string = `${this.config.apiURL}/sa/clients`;

    let result: any = await this.apiServive.postDataPromis(
      apiURL,
      data,
      headers
    );

    if (result.s == '1' || result.status == '1') {
      Object.keys(result.mList || {}).forEach((menu: any) => {
        if (result.mList[menu].length == 1 && result.mList[menu][0] == menu) {
          result.mList[menu] = [];
        }
        this.menus.push({
          name: menu,
          sub_menus: result.mList[menu].map((sub_menu: any) => {
            return { name: sub_menu };
          }),
        });
      });
      this.menus = this.menus.sort((a: any, b: any) => {
        let a_str = Number(a['name'].split('_')[1]);
        let b_str = Number(b['name'].split('_')[1]);
        return a_str - b_str
      }).map((menu: any) => {
        menu['name'] = menu['name'].split('_')[0];
        return menu;
      })
    }

    this.notifier.loading(false);
  }

  changeName(menu: any) {
    menu['name'] = menu['edit'];
    menu['edit'] = undefined;
  }

  addMainMenu() {
    this.menus.push({
      name: 'Module Name',
      temp: true,
      sub_menus: [],
    });
  }

  removeMainMenu(index: number) {
    this.menus.splice(index, 1);
  }

  draging: any = null;

  drop(event: any, menu: any, type: any, menu_index: number, index: any) {
    if (
      (this.draging['menu_index'] == menu_index &&
      this.draging['type'] == 'menu') || !this.draging
    )
    {
      return;
    }
    if(this.draging['type'] == 'menu' && type == 'menu') {
      return;
    }
    if(this.draging['type'] == 'menu' && type == 'submenu') {
      return;
    }
    if (
      this.draging['menu_index'] != menu_index &&
      this.draging['type'] == 'submenu'
    ) {
      this.menus[menu_index]['sub_menus'].push(this.draging['menu']);
      this.menus[this.draging['menu_index']]['sub_menus'].splice(
        this.draging['index'],
        1
      );
    } else if (this.draging['type'] == 'submenu' && type == 'menu') {
      this.menus[menu_index]['sub_menus'].push(this.draging['menu']);
      this.menus[this.draging['menu_index']]['sub_menus'].splice(
        this.draging['index'],
        1
      );
    } else if (this.draging['type'] == 'submenu' && type == 'submenu') {
      // this.menus[menu_index]['sub_menus'][index] = this.draging['menu'];
      // this.menus[this.draging['menu_index']]['sub_menus'][this.draging['index']] = menu;
      this.menus[menu_index]['sub_menus'].splice(index, 0, this.draging['menu']);
      if(index < this.draging['index']) {
        this.menus[menu_index]['sub_menus'].splice(this.draging['index'] + 1, 1);
      } else {
        this.menus[menu_index]['sub_menus'].splice(this.draging['index'], 1);
      }
    } else if (this.draging['type'] == 'menu' && type == 'menuzone') {
      this.menus.splice(menu_index, 0, this.draging['menu']);
      if(menu_index < this.draging['menu_index']) {
        this.menus.splice(this.draging['menu_index'] + 1, 1);
      } else {
        this.menus.splice(this.draging['menu_index'], 1);
      }
      // this.menus[menu_index] = this.draging['menu'];
      // this.menus[this.draging['menu_index']] = menu; 
      // this.menus[menu_index] = this.draging['menu'];
      // this.menus.splice(this.draging['menu_index'], 1);
    }
    event.preventDefault();
    this.draging = null;

    event.stopPropagation();
    event.dataTransfer.getData('text/html');
  }

  dropStart(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.currentScrollPosition = document.body.scrollTop;
    event.dataTransfer.dropEffect = 'move';
  }

  endDrag(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.draging = null;
  }

  dragStart(event: any, menu: any, type: any, menu_index: number, index: any) {
    event.stopPropagation();
    event.dataTransfer.setData('text/html', event.target.innerHTML);
    event.dataTransfer.dropEffect = 'move';
    this.currentScrollPosition = document.body.scrollTop;

    setTimeout(() => {
      this.draging = {
        type: type,
        event: event.target,
        menu_index: menu_index,
        index: index,
        menu: menu,
      };
    }, 10);

  }

  currentScrollPosition: number = 0;
  checkMouseMove(event: any) {
   if(this.draging) {
    document.body.scrollTop = document.body.scrollTop - 10;
    this.currentScrollPosition = document.body.scrollTop;
   }
  }

  dragStop(event: any) {
    event.preventDefault();
  }

  async updateMenus() {
    let menuList: any = {};
    let check: any = null;
    this.menus.forEach((menu: any, menu_index: number) => {
      if (menu['name'].trim() == '') {
        check = 'Menu name is mandatory';
      }
      if (this.hasSpecialCharacters(menu['name'].replaceAll(' ', ''))) {
        check = 'Special characters are not allowed';
      }
      menuList[menu['name']+`_${menu_index}`] = menu['sub_menus'].map((sub_menu: any) => {
        if (sub_menu['name'].trim() == '') {
          check = 'Submenu name is mandatory';
        }
        if (this.hasSpecialCharacters(sub_menu['name'].replaceAll(' ', ''))) {
          check = 'Special characters are not allowed';
        }
        return sub_menu['name'].trim();
      });
      if (menuList[menu['name']+`_${menu_index}`].length == 0) {
        menuList[menu['name']+`_${menu_index}`] = [menu['name'].trim()];
      }
    });
    if (check) {
      this.notifier.alert('Info', '', check, 'info', 5000);
      return;
    }
    this.notifier.loading(true);

    let data: any = { a: 'updateMenu', mlist: menuList };

    let headers: any = {
      Authorization: localStorage.getItem('sessiontoken'),
      'X-Api-Key': localStorage.getItem('clientid'),
    };

    let apiURL: string = `${this.config.apiURL}/sa/clients`;

    let result: any = await this.apiServive.postDataPromis(
      apiURL,
      data,
      headers
    );

    if (result.s == '1' || result.status == '1') {
      this.notifier.alert('Success', '', result.msg, 'success', 5000);
      this.load();
    } else {
      this.notifier.alert('Info', '', result.msg, 'info', 5000);
    }

    this.notifier.loading(false);
  }

  addSubMenu(menu: any) {
    menu['sub_menus'].push({ name: 'Add Sub Module' });
  }

  removeSubMenu(menu: any, index: number) {
    menu['sub_menus'].splice(index, 1);
  }
}
