<div class="card">
  <div class="card-header">
    <h5>Upcoming Races</h5>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-4" *ngFor="let date of upcoming">
        <div class="card">
          <div class="card-header fw-bold bg-swayam-blue">
            {{ date["date"] }}
          </div>
          <div class="card-body">
            <table class="table table-docs">
              <thead>
                <tr>
                  <th>Event Name</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let event of date['events']">
                  <td><a routerLink="/dash/venue-details" [queryParams]="{event_id: event['external_event_id'], venue_id: event['external_venue_id'], date: event['dateStr'], event_name: event['name']}" class="nav-link">{{ event["name"] }}</a></td>
                  <td>{{ date["date"] }}</td>
                </tr>
                <tr *ngIf="date['events'].length == 0">
                  <td [attr.colspan]="2" class="center">No Events Found</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="card">
  <div class="card-header flex-between">
    <h5>Previous Races</h5>
    <div>
      <input type="text" class="form-control datepicker" [(ngModel)]="previous['date']" />
    </div>
  </div>
  <div class="card-body">
    <table class="table table-docs">
      <thead>
        <tr>
          <th>Event Name</th>
          <th>Date</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let event of previous['events']">
          <td><a routerLink="/dash/venue-details" [queryParams]="{event_id: event['external_event_id'], venue_id: event['external_venue_id'], date: event['dateStr'], event_name: event['name']}" class="nav-link">{{ event["name"] }}</a></td>
          <td>{{ previous["date"] }}</td>
        </tr>
        <tr *ngIf="previous['events'].length == 0">
          <td [attr.colspan]="2" class="center">No Events Found</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
