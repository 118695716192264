import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APIService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { environment } from 'src/environments/environment';

@Component({
  //moduleId: module.id.toString(),
  templateUrl: './manage.policies.component.html',
  styleUrls: ['./manage.policies.component.css'],
})
export class PoliciesComponenet implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  role: string | null = localStorage.getItem('role');
  urlPrefix: string | null = null;
  isUserAdmin: boolean = localStorage.getItem('isUserAdmin') == '1';
  writeAccess: boolean = true;
  loading = true;
  policies: any = [];
  updatePolicy: any;
  policyAction: any;

  accountId = localStorage.getItem('accountId');
  regionId = localStorage.getItem('regionId');
  currentMessage: any;
  headers: any = [
    {
      id: 'policyName',
      name: 'Role Name',
    },
    {
      id: 'mlistStr',
      name: 'Module List',
    },
    {
      id: 'reportingManager',
      name: 'Reporting Manager',
    },
    { id: 'accessType', name: 'Access Type' },

  ];

  config: any;

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService,
    private route: ActivatedRoute
  ) {
    this.config = this.route.snapshot.data['config'];
  }
  ngOnInit(): void {
    if (this.role == 'Admin') {
      this.urlPrefix = 'admin';
    }
    if (this.role == 'Client') {
      this.urlPrefix = 'client';
    }
    if (this.role == 'Superadmin') {
      this.urlPrefix = 'sa';
    }
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);

      if (d.value == null) {
        return;
      }

      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  services: any = [];
  policyNames: string[] = [];
  async load() {
    this.notifier.loading(true);
    let data = {
      a: 'fetchAll',
      clientId: this.userId,
      userType: this.urlPrefix,
    };

    let header: any = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL: string | null;

    if (this.urlPrefix == 'sa') {
      header['Authorization'] = localStorage.getItem('sessiontoken');
      header['X-Api-Key'] = localStorage.getItem('clientid');
      apiURL = `${this.config.apiURL}/${this.urlPrefix}/policies`;
    } else {
      apiURL = `${this.config.apiURL}/${this.urlPrefix}/Settings/policies`;
    }

    this.policyNames = [];

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.policies =
        result.policies.map((policy: any) => {
          this.policyNames.push(policy.policyName);
          policy['mlistStr'] = (
            policy['mList'] ? Object.keys(policy['mList']) : policy['menuList']
          ).join(', ');
          return policy;
        }) || [];
      this.services =
        this.urlPrefix === 'client'
          ? JSON.parse(localStorage.getItem('menuList') || '{}')
          : result.menuList;
    } else {
      this.notifier.alert('Error', '', result.error, 'error', 5000);
    }

    this.notifier.loading(false);
  }

  updatePolicyAction(group: any) {
    this.policyAction = 'update';
    this.updatePolicy = group;
  }

  async hideUpdatePolicy(event: any) {
    if (event) {
      this.updatePolicy = event;
    }
    await this.load();
    this.updatePolicy = null;
  }

  createPolicy() {
    this.policyAction = 'add';
    this.updatePolicy = {
      policyName: '',
      menuList: [],
      accessType: 'readandwrite',
      reportingManager: '',
    };
  }

  callFunction(name: any, param: any) {
    return;
  }
}
