import { NgModule } from '@angular/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { DashComponent } from './modules/globals/dash/dash.component';
import { SuperadminComponent } from './modules/globals/superadmin/superadmin.component';
import { feedbackComponent } from './modules/globals/dash/components/feedback/feedback.component';
import { HomeComponent } from './modules/globals/dash/modules/pages/components/home/home.component';
import { PreRaceComponent, prepare_loading_dialog, prepare_loading_dialog_out } from './modules/globals/dash/modules/pages/components/pre-race/app.component';
import { steps_dialog, invalid_dialog, DeputyComponent } from './modules/globals/dash/modules/pages/components/pre-race/deputy/deputy.component';
import { DeviceListComponent } from './modules/globals/dash/modules/pages/components/pre-race/device-list/device-list.component';
import { RaceDetailsComponent } from './modules/globals/dash/modules/pages/components/race-details/race-details.component';
import { RaceOverviewComponent } from './modules/globals/dash/modules/pages/components/race-overview/race-overview.component';
import { VenueDetailsComponent } from './modules/globals/dash/modules/pages/components/venve-details/venve-details.component';
import { UserProfileComponent } from './modules/globals/dash/modules/settings/components/user-profile/user-profile.component';
import { DisableComponentComponent } from './modules/globals/dash/modules/settings/components/users/components/disable-component/disable-component.component';
import { UpdateUserComponent } from './modules/globals/dash/modules/settings/components/users/components/update-user/update-user.component';
import { UserDetailsComponent } from './modules/globals/dash/modules/settings/components/users/components/user-details/user-details.component';
import { UsersComponent } from './modules/globals/dash/modules/settings/components/users/users.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NewMenusComponent } from './modules/globals/superadmin/components/new-menus/new-menus.component';
import { ClientsComponent } from './modules/globals/superadmin/components/clients/clients.component';
import { InviteClientComponent } from './modules/globals/superadmin/components/clients/components/invite-client/invite-client.component';
import { PolicyViewComponent } from './shared/components/policy-view/policy-view.component';
import { UpdatePoliciesComponent } from './shared/components/policies/components/update.policies.component';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { MenuSelectorComponent } from './modules/globals/dash/modules/settings/components/menu-selector/menu-selector.component';
import { PoliciesComponenet } from './shared/components/policies/manage.policies.component';
import { TableViewComponent } from './shared/components/table-view/table-view.component';
import { StatusViewComponent } from './shared/components/status-view/status-view.component';
import { RegisterComponent } from './modules/auth/components/register/register.component';
import { LoginComponent } from './modules/auth/components/login/login.component';
import { ForgotPasswordComponent } from './modules/auth/components/forgot-password/forgot.component';
import { AuthComponent } from './modules/auth/components/auth/auth.component';
import { AdminComponent } from './modules/globals/admin/admin.component';
import { CompanyComponent } from './modules/globals/admin/company/company.component';
import { ClientRoutesComponent } from './modules/globals/admin/client-routes/client-routes.component';
import { FuelStationsComponent } from './modules/globals/admin/fuel-stations/fuel-stations.component';
import { VehiclesComponent } from './modules/globals/admin/vehicles/vehicles.component';
import { DriversComponent } from './modules/globals/admin/drivers/drivers.component';
import { AdClientComponent } from './modules/globals/admin/ad-client/ad-client.component';
import { SubmitPopupComponent } from './modules/globals/admin/vehicles/components/submit-popup/submit-popup.component';
import { ClickedOutsideDirective } from './directives/clicked-outside.directive';
import { AttendersComponent } from './modules/globals/admin/attenders/attenders.component';
import { MechanicsComponent } from './modules/globals/admin/mechanics/mechanics.component';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { StaffComponent } from './modules/globals/admin/staff/staff.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AppRoleComponent } from './modules/globals/admin/ad-client/role-popup/role-popup';



@NgModule({
  declarations: [
    AppComponent,
    DashComponent,
    SuperadminComponent,
    feedbackComponent,
    HomeComponent,
    prepare_loading_dialog,
    prepare_loading_dialog_out,
    steps_dialog,
    invalid_dialog,
    DeviceListComponent,
    DeputyComponent,
    UsersComponent,
    UpdateUserComponent,
    UserProfileComponent,
    UserDetailsComponent,
    DisableComponentComponent,
    NewMenusComponent,
    ClientsComponent,
    // UpdateUserComponent,
    InviteClientComponent,
    PolicyViewComponent,
    UpdatePoliciesComponent,
    MenuSelectorComponent,
    UpdatePoliciesComponent,
    PoliciesComponenet,
    VenueDetailsComponent,
    RaceDetailsComponent,
    RaceOverviewComponent,
    PreRaceComponent,
    TableViewComponent,
    StatusViewComponent,
    RegisterComponent,
    LoginComponent,
    ForgotPasswordComponent,
    AuthComponent,
    AdminComponent,
    CompanyComponent,
    ClientRoutesComponent,
    FuelStationsComponent,
    VehiclesComponent,
    DriversComponent,
    AdClientComponent,
    SubmitPopupComponent,
    ClickedOutsideDirective,
    AttendersComponent,
    MechanicsComponent,
    StaffComponent,
    AppRoleComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    NgSelectModule,
    RouterModule,
    MatSelectModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatProgressBarModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    NgxPaginationModule,
    NgMultiSelectDropDownModule.forRoot(),
    MatAutocompleteModule,
    MatInputModule,
    MatFormFieldModule,
    MatTabsModule,
    MatCheckboxModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
