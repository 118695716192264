<div class="card">
  <div class="card-body">
    <div class="row">
      <div class="col-md-6 details-container">
        <h1 class="mb-4">{{ eventName }}</h1>
        <div class="flex-start mb-3">
          <label>Event Date: </label>
          <span>{{ date }}</span>
        </div>
        <div class="flex-start mb-3">
          <label>Venue ID: </label>
          <span>{{ venueId }}</span>
        </div>
        <div class="flex-start mb-3">
          <label>Event ID: </label>
          <span>{{ eventId }}</span>
        </div>
        <div class="flex-start mb-3">
          <label>Operator Name: </label>
          <span></span>
        </div>
      </div>
      <div class="col-md-6">
        <div class="flex-start mb-3">
          <label>Kit:</label>
          <span>
            <select class="form-select">
              <option value="RQLD Kit 4">RQLD Kit 4</option>
            </select>
          </span>
        </div>
        <div class="flex-start mb-3">
          <label>Barrier Tag Assignment:</label>
          <span>
            <select class="form-select">
              <option value="Ignore Kit">Ignore Kit</option>
            </select>
          </span>
        </div>
        <div class="flex-start mb-3">
          <label>Tag Assignment:</label>
          <span>
            <select class="form-select">
              <option value="Sequential">Sequential</option>
              <option value="Set">Set</option>
            </select>
          </span>
        </div>
        <div class="flex-start mb-3">
          <label>Rail:</label>
          <span>
            <select class="form-select">
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
              <option value="13">13</option>
              <option value="14">14</option>
              <option value="15">15</option>
              <option value="split">Split (Input custom text)</option>
              <option value="custom">
                Individual Rail Number (Input custom number)
              </option>
            </select>
          </span>
        </div>
      </div>
    </div>
    <div class="row p-2" *ngIf="event">
      <div
        *ngIf="event['pdf_status'] == 'GENERATED'"
        class="alert alert-success"
        role="alert"
      >
        Generated PDF, please click
        <a class="nav-link" [attr.href]="event['pdf_file_path']" target="_blank"
          >this link</a
        >
        to view the start list file.
      </div>
      <div
        *ngIf="event['pdf_status'] == 'NOT_GENERATED'"
        class="alert alert-warning"
        role="alert"
      >
        Please press generate button to generate the start list file
      </div>
      <div
        *ngIf="event['pdf_status'] == 'GENERATING'"
        class="alert alert-primary"
        role="alert"
      >
        Generating PDF, please wait. Please refrain from pressing generate
        button again.
      </div>
      <div
        *ngIf="event['pdf_status'] == 'FAILED'"
        class="alert alert-danger"
        role="alert"
      >
        Error encountered while generating: {{ event["pdf_error"] }}
      </div>
    </div>
    <div class="row pt-2">
      <div class="flex-end">
        <button class="btn btn-success-soft">Rail Calculator</button>
        <button class="btn btn-success-soft" (click)="generateStartList()">
          Generate Start List
        </button>
      </div>
    </div>
    <table-view
      [headers]="headers"
      [data]="races"
      [loading]="loading"
    ></table-view>
  </div>
</div>
