<div class="login-banner">Log in to your Account</div>
<div class="login-short-info">Welcome back!</div>
<ng-container *ngIf="!otpRequired">
  <div *ngIf="passwordUpdated" class="info">Password updated successfully</div>
  <div *ngIf="registered" class="info">Registered successfully</div>
  <div class="login-cred">
    <div class="line"></div>
    <div class="login-cred-text">Enter login credentials</div>
    <div class="line"></div>
  </div>
  <form (ngSubmit)="login()">
    <div class="margin-bottom-10">
      <div class="email-container">
        <i class="fa fa-envelope left-align"></i>
        <input
          [(ngModel)]="username"
          [attr.disabled]="loading['login'] ? true : null"
          [ngClass]="{ 'error-text': errors['username']['error'] }"
          [ngModelOptions]="{ standalone: true }"
          autocomplete="off"
          class="form-control"
          placeholder="Email"
          type="email"
        />
      </div>
      <div *ngIf="errors['username']['error']" class="error-message">
        <i aria-hidden="true" class="fa fa-exclamation-triangle"></i>
        {{ errors["username"]["message"] }}
      </div>
    </div>
    <div class="margin-bottom-10">
      <div class="password-container">
        <i class="fa fa-lock left-align"></i>
        <input
          [(ngModel)]="password"
          [attr.disabled]="loading['login'] ? true : null"
          [attr.type]="showPassword ? 'text' : 'password'"
          [ngClass]="{ 'error-text': errors['password']['error'] }"
          [ngModelOptions]="{ standalone: true }"
          autocomplete="off"
          class="form-control paddingleft"
          placeholder="Password"
        />
        <i
          (click)="alterPassword()"
          *ngIf="!showPassword"
          aria-hidden="true"
          class="fa fa-eye right-align"
        ></i>
        <i
          (click)="alterPassword()"
          *ngIf="showPassword"
          aria-hidden="true"
          class="fa fa-eye-slash right-align"
        ></i>
      </div>
      <div *ngIf="errors['password']['error']" class="error-message">
        <i aria-hidden="true" class="fa fa-exclamation-triangle"></i>
        {{ errors["password"]["message"] }}
      </div>
    </div>
    <div class="flex-end" *ngIf="role != 'Superadmin'">
      <a class="nav-link" routerLink="/auth/forgot-password">Forgot Password?</a>
    </div>
    <div class="login-actions">
      <button
        *ngIf="!loading['login']"
        class="btn btn-minfy-color"
        type="submit"
      >
      <!-- [attr.disabled]="tokenExpired['checking'] ? true : null" -->
        Log in
      </button>
      <img
        [ngStyle]="{ display: loading['login'] ? 'inline' : 'none' }"
        alt=""
        class="width-18"
        src="./assets/img/loading_2.svg"
      />
    </div>
  </form>
  <div class="flex-center font-0-8" *ngIf="role != 'Superadmin'">
    New to Intercity Transport ?
    <a routerLink="/auth/register" class="nav-link">Register</a>
  </div>
</ng-container>
<ng-container *ngIf="otpRequired">
  <div class="info">
    Please enter the OTP that you have received from <br />
    <span class="text-bold">{{ resend }}</span>
  </div>
  <form (ngSubmit)="validateOTP($event)">
    <div class="margin-bottom-10">
      <div class="email-container">
        <i class="fa fa-key left-align"></i>
        <input
          (keyup)="validateOTP()"
          [(ngModel)]="otpText"
          [attr.disabled]="loading['otp'] ? true : null"
          [ngModelOptions]="{ standalone: true }"
          autocomplete="off"
          class="form-control"
          placeholder="OTP"
          type="text"
        />
      </div>
      <div *ngIf="errors['otpText']['error']" class="error-message">
        <i aria-hidden="true" class="fa fa-exclamation-triangle"></i>
        {{ errors["otpText"]["message"] }}
      </div>
    </div>
    <div class="flex-end">
      <span (click)="loginOPT()" *ngIf="!loading['login']" class="nav-link"
        >Resend OTP</span
      >
      <img
        [ngStyle]="{ display: loading['login'] ? 'inline' : 'none' }"
        alt=""
        class="width-14"
        src="./assets/img/loading_2.svg"
      />
    </div>
    <div class="login-actions">
      <button *ngIf="!loading['otp']" class="btn btn-minfy-color" type="submit">
        Validate
      </button>
      <img
        [ngStyle]="{ display: loading['otp'] ? 'inline' : 'none' }"
        alt=""
        class="width-18"
        src="./assets/img/loading_2.svg"
      />
    </div>
  </form>
</ng-container>
