import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

export interface back_value {
  value: string;
}
export interface location_value {
  name: string;
  code: string;
}
export interface DialogData {
  location: string;
  PC: string;
  kit: string;
  operator: string;
  password: string;
}
export interface PrepareData {
  variable: DialogData;
  step: number;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class PreRaceComponent implements OnInit {
  title = 'Prepare';
  form!: FormGroup;
  submitted = false;

  locations: back_value[] = [{ value: 'a' }, { value: 'b' }];
  PCs: back_value[] = [{ value: 'c' }, { value: 'd' }];
  kits: back_value[] = [{ value: 'e' }, { value: 'f' }];
  brands: string[] = ['g', 'f'];
  location_backup: location_value[] = [];
  operators: back_value[] = [{ value: 'a' }, { value: 'b' }];
  config: any = {};

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    public dialog: MatDialog,
    public router: Router
  ) {
    this.config = this.route.snapshot.data['config'];
  }

  async ngOnInit(): Promise<void> {
    this.form = this.formBuilder.group({
      brand: ['', Validators.required],
      location: ['', Validators.required],
      PC: ['', Validators.required],
      kit: ['', Validators.required],
      operator: ['', Validators.required],
      password: ['', Validators.required],
    });
    this.locations = [];
    this.PCs = [];
    this.kits = [];
    this.location_backup = [];
    this.brands = [];
    this.operators = [];
    // get ranges from backend
    var res = await this.http
      .get<any>(`${this.config.serverAPI}/get_locations`)
      .toPromise();

    var location_range = res.locations;
    location_range.forEach((e: any) => {
      var bb = { value: e.name } as back_value;
      this.locations.push(bb);
      this.location_backup.push({ name: e.name, code: e.code });
    });
    this.location_backup.forEach((e) => {
      if (this.brands.indexOf(e.code) < 0) {
        this.brands.push(e.code);
      }
    });
    this.callAPis();
  }


  callAPis() {
    this.http
      .get<any>(`${this.config.serverAPI}/get_pcs`)
      .subscribe((data) => {
        var pc_range = data.PCs;
        pc_range.forEach((e: any) => {
          var bb = { value: e.name } as back_value;
          this.PCs.push(bb);
        });
      });
    this.http
      .get<any>(`${this.config.serverAPI}/get_kits`)
      .subscribe((data) => {
        var kit_range = data.kits;
        kit_range.forEach((e: any) => {
          var bb = { value: e.name } as back_value;
          this.kits.push(bb);
        });
      });
    this.http
      .get<any>(`${this.config.serverAPI}/get_operators`)
      .subscribe((data) => {
        var operator_range = data.operators;
        operator_range.forEach((e: any) => {
          var bb = { value: e.name } as back_value;
          this.operators.push(bb);
        });
      });
  }

  onTypeChange(o: any) {
    this.locations = [];
    this.location_backup.forEach((e: any) => {
      if (e.code == o.value) {
        this.locations.push({ value: e.name } as back_value);
      }
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onSubmit(): void {
    (document.getElementById('login') as HTMLButtonElement).disabled = true;
    this.submitted = true;

    if (this.form.invalid) {
      //console.log("failed");
      (document.getElementById('login') as HTMLButtonElement).disabled = false;
      return;
    }

    //console.log(JSON.stringify(this.form.value, null, 2));
    const dialogRef = this.dialog.open(prepare_loading_dialog, {
      data: {
        location: this.form.value.location,
        PC: this.form.value.PC,
        kit: this.form.value.kit,
        operator: this.form.value.operator,
        password: this.form.value.password,
        type: this.form.value.brand,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      //do redirect based on location &code
      this.location_backup.forEach((e) => {
        if (e.name == result) {
          if (e.code == 'harness') {
            window.location.href = `http://13.236.117.119`;
          } else if (e.code == 'thoroughbred') {
            window.location.href = 'http://172.18.1.10:5000';
          }
        }
      });
    });
    (document.getElementById('login') as HTMLButtonElement).disabled = false;
  }

  onLogoff(): void {
    (document.getElementById('logout') as HTMLButtonElement).disabled = true;
    this.submitted = true;

    if (this.form.invalid) {
      //console.log("failed");
      (document.getElementById('logout') as HTMLButtonElement).disabled = false;
      return;
    }

    //console.log(JSON.stringify(this.form.value, null, 2));
    const dialogRef = this.dialog.open(prepare_loading_dialog_out, {
      data: {
        location: this.form.value.location,
        PC: this.form.value.PC,
        kit: this.form.value.kit,
        operator: this.form.value.operator,
        password: this.form.value.password,
        type: this.form.value.brand,
      },
    });
    (document.getElementById('logout') as HTMLButtonElement).disabled = false;
  }
}
// ----------------------------login dialog--------------------------
@Component({
  selector: 'prepare_loading',
  templateUrl: 'prepare_loading.html',
})
export class prepare_loading_dialog {
  finish: boolean = false;
  inProgress: number = 0;
  failed_step: number[] = [];
  failed_check: string = '';
  config: any;
  constructor(
    public dialogRef: MatDialogRef<prepare_loading_dialog>,
    private route: ActivatedRoute,
    private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.config = this.route.snapshot.data['config'];
    dialogRef.disableClose = true;
  }

  async onYesClick(): Promise<void> {
    var res = await this.http
      .post<any>(`${this.config.serverAPI}/login/check`, this.data)
      .toPromise();

    if (res.status != true) {
      this.failed_check = res.reason;
      return;
    }

    this.inProgress += 1;
    while (this.inProgress < 10) {
      //--------------9 steps in login
      var sending: PrepareData = { variable: this.data, step: this.inProgress };
      var res = await this.http
        .post<any>(`${this.config.serverAPI}/login/`, sending)
        .toPromise();

      if (res.status != true) {
        this.failed_step.push(this.inProgress);
      }

      this.inProgress += 1;
    }
    this.finish = true;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onReloadClick(): void {
    window.location.reload();
  }

  onRerunClick(): void {
    this.finish = false;
    var copy_failed: number[] = this.failed_step;
    this.failed_step = [];
    copy_failed.forEach(async (e) => {
      this.inProgress = e;
      var sending: PrepareData = { variable: this.data, step: this.inProgress };
      var res = await this.http
        .post<any>(`${this.config.serverAPI}/login/`, sending)
        .toPromise();

      if (res.status != true) {
        this.failed_step.push(this.inProgress);
      }
    });
    this.inProgress = 10; //--------------9 steps in login
    this.finish = true;
  }
}

//---------------------logout dialog----------------------
@Component({
  selector: 'prepare_loading_out',
  templateUrl: 'prepare_loading_out.html',
})
export class prepare_loading_dialog_out {
  finish: boolean = false;
  inProgress: number = 0;
  failed_step: number[] = [];
  failed_check: string = '';
  small_file: boolean = false;
  config: any;
  constructor(
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<prepare_loading_dialog>,
    private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.config = this.route.snapshot.data['config'];
    dialogRef.disableClose = true;
  }

  async onYesClick(): Promise<void> {
    var res = await this.http
      .post<any>(`${this.config.serverAPI}/logout/check`, this.data)
      .toPromise();

    if (res.status != true) {
      this.failed_check = res.reason;
      return;
    }

    this.inProgress += 1;
    while (this.inProgress < 6) {
      //--------------5 steps in logout
      var sending: PrepareData = { variable: this.data, step: this.inProgress };
      var res = await this.http
        .post<any>(`${this.config.serverAPI}/logout/`, sending)
        .toPromise();

      if (res.status != true) {
        if (this.inProgress == 2) {
          this.small_file = true;
        } else {
          this.failed_step.push(this.inProgress);
        }
      }

      this.inProgress += 1;
    }
    this.finish = true;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onReloadClick(): void {
    window.location.reload();
  }

  onRerunClick(): void {
    this.finish = false;
    var copy_failed: number[] = this.failed_step;
    this.failed_step = [];
    copy_failed.forEach(async (e) => {
      this.inProgress = e;
      var sending: PrepareData = { variable: this.data, step: this.inProgress };
      var res = await this.http
        .post<any>(`${this.config.serverAPI}/logout/`, sending)
        .toPromise();

      if (res.status != true) {
        this.failed_step.push(this.inProgress);
      }
    });
    this.inProgress = 6; //--------------5 steps in logout
    this.finish = true;
  }
}
