import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APIService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';

@Component({
  selector: 'app-race-details',
  templateUrl: './race-details.component.html',
  styleUrls: ['./race-details.component.css'],
})
export class RaceDetailsComponent implements OnInit {
  raceId: string = '';
  event: any = {};
  loading: boolean = false;
  horses: any = [];
  traceList: any = [];
  statuses: any = [
    {
      type: 'success',
      value: 'FINISHED',
    },
    {
      type: 'pending',
      value: 'Planned',
    },
  ];

  headers: any = [
    {
      id: 'RaceStateDNS',
      name: 'DNS',
      type: 'checkbox',
      show: true,
      filter: true,
    },
    {
      id: 'StartNumber',
      name: 'SCN',
      type: 'string',
      show: true,
      filter: true,
      // link: {
      //   href: '',
      //   queryParamsKey: ''
      // }
    },
    {
      id: 'imagePath',
      name: 'Silk',
      type: 'image',
      show: true,
      filter: true,
    },
    {
      id: 'selectedTagId',
      name: 'Tag ID',
      type: 'multi-select',
      dropdown: {
        placeholder: 'Tag ID',
        multiple: false,
      },
      show: true,
      filter: true,
    },
    {
      id: 'AnimalName',
      name: 'Horse Name',
      type: 'string',
      show: true,
      filter: true,
    },
    {
      id: 'selectedTrainer',
      name: 'Trainer',
      type: 'multi-select',
      dropdown: {
        placeholder: 'Trainer',
        multiple: false,
        onchange: true
      },
      show: true,
      filter: true,
    },
    {
      id: 'selectedJockey',
      name: 'Driver',
      type: 'multi-select',
      dropdown: {
        placeholder: 'Driver',
        multiple: false,
        onchange: true
      },
      show: true,
      filter: true,
    },
    {
      id: 'ExternalAnimalID',
      name: 'Horse ID',
      type: 'string',
      show: true,
      filter: true,
    },
    {
      id: 'ExternalSilkID',
      name: 'Silk ID',
      type: 'string',
      show: true,
      filter: true,
    },
  ];

  config: any;

  constructor(
    private router: ActivatedRoute,
    private apiService: APIService,
    private notifier: NotifierService
  ) {
    this.config = this.router.snapshot.data['config'];
  }

  ngOnInit(): void {
    this.router.queryParams.subscribe((params: any) => {
      this.raceId = params['race_id'];
      this.load();
    });
  }

  async load(): Promise<void> {
    this.notifier.loading(true);
    this.event = {};
    try {
      await this.loadHorsesBasedOnRace();
    } catch(err) {
      this.notifier.loading(false);
      return;
    }
    await this.getTracts();
    this.notifier.loading(false);
  }

  getNearestNumbers(input: number): number[] {
    const nearestBigWholeNumber = Math.floor((input - 1) / 10) * 10 + 1;
    const numbersList: number[] = [];
  
    for (let i = nearestBigWholeNumber; i <= nearestBigWholeNumber + 29; i++) {
      numbersList.push(i);
    }

    this.numbers = numbersList;
  
    return numbersList;
  }

  numbers: number[] = [];

  captureEvent(event: any) {
    if(!event['event']) {
      event['item']['dropdown']['options'][event['id']] = event['item']['dropdown']['defaultOptions'][event['id']];
    } else {
      if(event.id == 'selectedTrainer') {
          this.loadTrainers(event['event'], event['item'], event['id'])
      }
      if(event.id == 'selectedJockey') {
          this.loadJockeys(event['event'], event['item'], event['id'])
      }
    }
    
  }

  async loadTrainers(key: string, item: any, id: string) {
    let apiURL: string = `${this.config.raceAPI}/trainer-jockey-name?table=trainer&mode=3&name=${key}`;

    let result: any = await this.apiService.getDataPromis(apiURL, {}, {});

    item.dropdown.options[id] = [...item.dropdown.defaultOptions[id], ...result.map((trainer: any) => {
      return {
        item_id: trainer['id'],
        item_text: trainer['name'],
      }
    })];
  }

  async loadJockeys(key: string, item: any, id: string) {
    let apiURL: string = `${this.config.raceAPI}/trainer-jockey-name?table=jockey&mode=3&name=${key}`;

    let result: any = await this.apiService.getDataPromis(apiURL, {}, {});

    item.dropdown.options[id] = [...item.dropdown.defaultOptions[id], ...result.map((trainer: any) => {
      return {
        item_id: trainer['id'],
        item_text: trainer['name'],
      }
    })];
  }

  error: string | null = null;
  async loadHorsesBasedOnRace(): Promise<boolean> {
    return new Promise(async (resolve: any, reject: any) => {
      let apiURL: string = `${this.config.raceAPI}/race-entrant-details?ExternalRaceId=${this.raceId}`;

      let result: any = await this.apiService.getDataPromis(apiURL, {}, {});

      if(typeof result == 'string') {
        this.error = result;
        this.notifier.alert('Info', '', result, 'info', 5000);
        this.horses = [];
        this.event = null;
        reject(false);
        return;
      }

      this.event = result;

      await this.getRaceResult();

      if (
        result?.entrants &&
        Array.isArray(result.entrants) &&
        result.entrants.length > 0
      ) {
        result.entrants.forEach((animal: any) => {
          animal['RaceStateDNS'] = animal['RaceState'] == 'DNS';
          animal['rowClassConfition'] = (animal: any) => {
            return animal['RaceStateDNS'] ? 'line-through bg-grey' : null;
          };
          let numbers: any = this.numbers;
          if (numbers.length == 0) {
            numbers = this.getNearestNumbers(animal['DeviceID']);
          }
          animal['dropdown'] = {
            options: {
              selectedTagId: this.numbers,
              selectedTrainer: [
                {
                  item_id: animal['ExternalTrainerID'],
                  item_text: animal['TrainerName'],
                },
              ],
              selectedJockey: [
                {
                  item_id: animal['ExternalJockeyID'],
                  item_text: animal['JockeyName'],
                },
              ],
            },
            defaultOptions: {
              selectedTagId: this.numbers,
              selectedTrainer: [
                {
                  item_id: animal['ExternalTrainerID'],
                  item_text: animal['TrainerName'],
                },
              ],
              selectedJockey: [
                {
                  item_id: animal['ExternalJockeyID'],
                  item_text: animal['JockeyName'],
                },
              ],
            },
            selected: {
              selectedTagId: animal['DeviceID']
                ? [
                    {
                      item_id: animal['DeviceID'],
                      item_text: animal['DeviceID'],
                    },
                  ]
                : [],
              selectedTrainer: [
                {
                  item_id: animal['ExternalTrainerID'],
                  item_text: animal['TrainerName'],
                },
              ],
              selectedJockey: [
                {
                  item_id: animal['ExternalJockeyID'],
                  item_text: animal['JockeyName'],
                },
              ],
            },
          };
          animal['imagePath'] =
            'HARNESS' === this.event.code_type
              ? `https://tsd-prd01-silks.s3.ap-southeast-2.amazonaws.com/Harness_Racing_Australia/${animal.StartNumber}.png`
              : this.event.venue_id.includes('STC')
              ? `https://tsd-prd01-silks.s3.ap-southeast-2.amazonaws.com/singapore/${animal.ExternalSilkID}.png`
              : `https://tsd-prd01-silks.s3.ap-southeast-2.amazonaws.com/Racing_Australia/${animal.ExternalSilkID}.png`;
        });
        result = result.entrants.sort((a: any, b: any) => {
          return a['StartNumber'] - b['StartNumber'];
        });
      }

      resolve(true);
    });
  }

  async getRaceResult() {
    return new Promise(async (resolve: any) => {
      let apiURL: string = `${this.config.raceAPI}/getraceresults?ExternalEventId=${this.event.event_id}&ExternalVenueId=${this.event.venue_id}&raceIds%5B%5D=${this.raceId}`;

      let result: any = await this.apiService.getDataPromis(apiURL, {}, {});

      if (result && typeof result == 'object') {
        this.event['race_status'] = result[this.raceId];
      }

      resolve(true);
    });
  }

  async getTracts() {
    return new Promise(async (resolve: any) => {
      let apiURL: string = `${this.config.raceAPI}/geteqtrack?ExternalVenueId=${this.event.venue_id}`;
      let header: any = {
        'X-Api-Key': 'e2bvuAoslY1VJvFMqhr5x7nxoEhFVRii9jRngS3U',
      };
      let result: any = await this.apiService.getDataPromis(apiURL, {}, header);
      if (result.venue && result.venue.TrackList) {
        this.traceList = result.venue.TrackList.map((trace: any) => {
          return {
            name: trace['EQTrackName'],
            value: trace['TrackID'],
          };
        });
      }
      resolve(true);
    });
  }
}
