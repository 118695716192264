import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { ConfigService } from './config.service';
@Injectable({ providedIn: 'root' })
export class ConfigResolver implements Resolve<any> {
  constructor(private ConfigService: ConfigService) {}
  resolve(): Observable<any> {
    return this.ConfigService.loadConfig();
  }
}
