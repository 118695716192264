<h1 *ngIf = "inProgress == 0" mat-dialog-title style = "color:red">WARNING</h1>
<h1 *ngIf = "inProgress != 0 && finish == false" mat-dialog-title style = "color:#1976d2">Logout In Progress</h1>
<h1 *ngIf = "inProgress != 0 && finish == true && failed_step.length ==0" mat-dialog-title style = "color:#1976d2">Logout Done</h1>
<h1 *ngIf = "inProgress != 0 && finish == true && failed_step.length !=0" mat-dialog-title style = "color:red">Logout Failed</h1>
<div mat-dialog-content>
  <p *ngIf = "inProgress == 0">Please make sure that EQTraCe is not open</p>
  <p *ngIf = "inProgress != 0 && finish == false">Do not refresh or close this page, it will cause serious problem!</p>
  <p *ngIf = "inProgress != 0 && finish == true && failed_step.length ==0 && small_file == false">Logout progress done! You are now good to go!</p>
  <p *ngIf = "inProgress != 0 && finish == true && failed_step.length ==0 && small_file == true" style ="color: red;">The database file is too small! thus I didn't upload it. But you are still logged out.<br> If You know about it you are good to go, otherwise please tell the manager</p>

  <p *ngIf = "inProgress>0 && inProgress<2 && !failed_step.includes(1) && finish == false" style = "margin-top: 10px; color:rgb(209, 178, 0)">Restore original EQTraCe...</p>
  <p *ngIf = "failed_step.includes(1) && failed_step.length !=0" style ="color: red;">Restore back up EQTraCe failed! please contact someone for help</p>
  <p *ngIf = "inProgress>1 && !failed_step.includes(1) && finish == false" style = "margin-top: 10px; color:rgb(0, 255, 34)">Restore original EQTraCe...<mat-icon aria-hidden="false" aria-label="Example done icon" fontIcon="done"></mat-icon></p>
  
  <p *ngIf = "inProgress>0 && inProgress<3 && !failed_step.includes(2) && finish == false" style = "margin-top: 10px; color:rgb(209, 178, 0)">Back up and upload database...</p>
  <p *ngIf = "failed_step.includes(2) && failed_step.length !=0" style ="color: red;">Backup SQL failed! please contact someone for help</p>
  <p *ngIf = "inProgress>2 && !failed_step.includes(2) && finish == false" style = "margin-top: 10px; color:rgb(0, 255, 34)">Back up and upload database...<mat-icon aria-hidden="false" aria-label="Example done icon" fontIcon="done"></mat-icon></p>
  
  <p *ngIf = "inProgress>0 && inProgress<4 && !failed_step.includes(3) && finish == false" style = "margin-top: 10px; color:rgb(209, 178, 0)">Clean up backup folder...</p>
  <p *ngIf = "failed_step.includes(3) && failed_step.length !=0" style ="color: red;">Clean up backup folder failed! please contact someone for help</p>
  <p *ngIf = "inProgress>3 && !failed_step.includes(3) && finish == false" style = "margin-top: 10px; color:rgb(0, 255, 34)">Clean up backup folder...<mat-icon aria-hidden="false" aria-label="Example done icon" fontIcon="done"></mat-icon></p>
  
  <p *ngIf = "inProgress>0 && inProgress<5 && !failed_step.includes(4) && finish == false" style = "margin-top: 10px; color:rgb(209, 178, 0)">Clean up data folder...</p>
  <p *ngIf = "failed_step.includes(4) && failed_step.length !=0" style ="color: red;">Clean up data folder failed! please contact someone for help</p>
  <p *ngIf = "inProgress>4 && !failed_step.includes(4) && finish == false" style = "margin-top: 10px; color:rgb(0, 255, 34)">Clean up data folder...<mat-icon aria-hidden="false" aria-label="Example done icon" fontIcon="done"></mat-icon></p>
  
  <p *ngIf = "inProgress>0 && inProgress<6 && !failed_step.includes(5) && finish == false" style = "margin-top: 10px; color:rgb(209, 178, 0)">Clean up files in AWS s3...</p>
  <p *ngIf = "failed_step.includes(5) && failed_step.length !=0" style ="color: red;">Clean up files in s3 failed! please contact someone for help</p>
  <p *ngIf = "inProgress>5 && !failed_step.includes(5) && finish == false" style = "margin-top: 10px; color:rgb(0, 255, 34)">Clean up files in AWS s3...<mat-icon aria-hidden="false" aria-label="Example done icon" fontIcon="done"></mat-icon></p>
  


  <mat-progress-bar *ngIf = "inProgress == 1 && finish == false" mode="determinate" value="10"></mat-progress-bar>
  <mat-progress-bar *ngIf = "inProgress == 2 && finish == false" mode="determinate" value="50"></mat-progress-bar>
  <mat-progress-bar *ngIf = "inProgress == 3 && finish == false" mode="determinate" value="60"></mat-progress-bar>
  <mat-progress-bar *ngIf = "inProgress == 4 && finish == false" mode="determinate" value="80"></mat-progress-bar>
  <mat-progress-bar *ngIf = "inProgress == 5 && finish == false" mode="determinate" value="100"></mat-progress-bar>


  <div *ngIf = "inProgress == 0" style = "height: 20px;"></div>
  <p *ngIf = "failed_check.length != 0" style="color:red">You can't logout now! because {{failed_check}}, please close this window and recheck</p>
</div>
<div mat-dialog-actions>
  <button *ngIf = "inProgress == 0 && failed_check.length == 0" mat-button (click)="onYesClick()" cdkFocusInitial>Yes I'm ready</button>
  <button *ngIf = "inProgress == 0 && failed_check.length == 0" mat-button (click)="onNoClick()">No I'm not</button>
  <button *ngIf = "inProgress == 0 && failed_check.length != 0" mat-button (click)="onNoClick()">close</button>

  <button mat-button *ngIf = "finish && failed_step.length ==0" (click)="onReloadClick()">Ok</button>
  <button *ngIf = "finish && failed_step.length !=0" mat-button (click)="onRerunClick()">Rerun</button>
</div>
