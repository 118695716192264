<ng-container *ngIf="addcom || isEditMode">
  <!-- <div *ngIf="addcom"> -->
  <!-- <h3 class="text-center">
    {{ isEditMode ? "Update Vehicle" : "New Vehicle" }}
  </h3>
  <div class="flex-start">
    <button class="btn btn-light" (click)="clearForm()">< Back</button>
  </div> -->
  <div class="flex-start ml-4 mt-3" *ngIf="!showModal">
    <button class="btn btn-light" data-bs-toggle="modal"
            data-bs-target="#staticBackdrop" (click)="goBack()">
      <i class="fa fa-arrow-left " aria-hidden="true"></i>
    </button>
    <h4 class="mb-0 pb-0 font-weight-bold">
      {{ isEditMode ? "Update Vehicle" : "New Vehicle" }}
    </h4>
  </div>
  <div class="flex-start ml-4 mt-3" *ngIf="showModal">
    <button class="btn btn-light" (click)="clearForm()">
      <i class="fa fa-arrow-left " aria-hidden="true"></i>
    </button>
    <h4 class="mb-0 pb-0 font-weight-bold">
      {{ "Vehicle Details" }}
    </h4>
  </div>
  
  <div class="card">
    <form [formGroup]="myForm" (ngSubmit)="onSubmit()" autocomplete="off">
      <!-- <h5 class="card-header">Vehicle Information</h5> -->
      <div class="card-body">
        <div class="row">
          <div class="col-4">
            <div class="row">
              <div class="col">
                <div class="form-group mb-2 fl">
                  <label for="reg_num" class="label-color lato-font">
                    Registration Number*
                  </label>
                  <input
                    [readonly]="isEditMode"
                    placeholder="TS01BG1234"
                    class="inputOfForm"
                    type="text"
                    id="reg_num"
                    formControlName="reg_num"
                    (change)="vehicle_name_auto_fill('reg_num')"
                    maxlength="10"
                  />
                  <div
                    *ngIf="myForm.get('reg_num')?.invalid && (myForm.get('reg_num')?.touched || submitted)"
                    class="error-message"
                  >
                    <span *ngIf="myForm.get('reg_num')?.errors?.required" class="error-message">
                      Registration Number is mandatory.
                    </span>
                    <span *ngIf="myForm.get('reg_num')?.errors?.maxlength" class="error-message">
                      Registration Number must not exceed 10 characters.
                    </span>
                    <span *ngIf="myForm.get('reg_num')?.errors?.invalidRegistrationNumber" class="error-message">
                      Must start with two letters and should not contain special characters.
                    </span>
                  </div>
                </div>
              </div>
              

              <div class="col" *ngIf="!bulkFileUpload">
                <label for="reg_plate_photo" (click)="prevent($event)" class="label-color"
                  >Registration Plate</label
                >
                <div>
                  <span
                    [ngStyle]="{
                      display: !(
                        isEditMode && !updated['registration_plate_photo']
                      )
                        ? 'inline'
                        : 'none'
                    }"
                  >
                    <input
                      class="upldFile"
                      type="file"
                      id="reg_plate_photo"
                      (change)="
                        onFileSelected(
                          'reg_plate_photo',
                          'registration_plate_photo',
                          $event
                        );
                        regTouched = true
                      "
                      #regIn
                    />
                    <button
                      *ngIf="
                        (regIn.files || []).length == 0 &&
                        !updated['registration_plate_photo'] || (this.fileNames === undefined &&
                        !updated['registration_plate_photo'])
                      "
                      class="btn btn-primary mr-l-10"
                      type="button"
                      (click)="initiateRegIn()"
                    >
                      <i class="fa-solid fa-magnifying-glass mrgn-r-10"></i
                      >Browse File
                    </button>
                    <div *ngIf="!updateForm">
                      <div *ngIf="(regIn.files || []).length > 0 && updated['registration_plate_photo'] && progressBar">
                        <div class="progress">
                          <div class="progress-bar" role="progressbar"  aria-valuemin="0" aria-valuemax="100" [style.width.%]="progressValue">
                          </div>
                        </div>
                        <p class="upldTxt">uploading...</p>
                      </div>
                      <div class="button-container" *ngIf="(regIn.files || []).length > 0 && updated['registration_plate_photo'] && !progressBar">
                        <span class="del" (click)="deleteDocument()">Delete</span>
                        <div class="line"></div>
                        <span class="pre" (click)="previewDocument(regIn, 'registration_plate_photo')">Preview</span>
                      </div>
                    </div>
                    
                  </span>
                  
                  <ng-container *ngIf="isEditMode">
                    <input
                      class="upldFile"
                      type="file"
                      id="reg_plate_photo"
                      (change)="
                        onFileSelected(
                          'reg_plate_photo',
                          'registration_plate_photo',
                          $event
                        );
                        regTouched = true
                      "
                      #regIn
                    />
                    <button
                      *ngIf="
                        this.fileNames[0] === 'NA' &&
                        !updated['registration_plate_photo']
                      "
                      class="btn btn-primary mr-l-10"
                      type="button"
                      (click)="initiateRegIn()"
                    >
                      <i class="fa-solid fa-magnifying-glass mrgn-r-10"></i
                      >Browse File
                    </button>
                    <span
                      
                      *ngIf="
                        this.fileNames[0] !== 'NA' ||
                        updated['registration_plate_photo']
                      "
                    >
                      <div *ngIf="updateForm">
                        <div *ngIf="progressBar">
                          <div class="progress">
                            <div class="progress-bar" role="progressbar"  aria-valuemin="0" aria-valuemax="100" [style.width.%]="progressValue">
                            </div>
                          </div>
                          <p class="upldTxt">uploading...</p>
                        </div>
                        <div class="button-container" *ngIf="!progressBar">
                          <span class="del" (click)="deleteDocumentInUpdate()">Delete</span>
                          <div class="line"></div>
                          <span class="pre" (click)="previewDocument(regIn, 'registration_plate_photo')">Preview</span>
                        </div>
                      </div>
                    </span>

                    
                  </ng-container>
                  
                </div>
                <span
                  *ngIf="documentInputErrors['reg_plate_photo']"
                  class="error-message"
                  >{{documentInputErrors["reg_plate_photo"] }}</span
                >
              </div>

              <div class="col" *ngIf="bulkFileUpload">
                <label for="reg_plate_photo" class="label-color"
                  >Registration Plate</label
                >
                <div>
                  <ng-container *ngIf="isEditMode && bulkFileUpload">
                    <input
                      class="upldFile"
                      type="file"
                      id="reg_plate_photo"
                      (change)="
                        onFileSelectedForBulkUpload(
                          'reg_plate_photo',
                          'registration_plate_photo',
                          $event
                        );
                        regTouched = true
                      "
                      #regIn
                    />
                    <button
                      *ngIf="
                        !contentList[index]['Registration Plate Photo'] &&
                        !updated['registration_plate_photo']
                      "
                      class="btn btn-primary mr-l-10"
                      type="button"
                      (click)="initiateRegIn()"
                    >
                      <i class="fa-solid fa-magnifying-glass mrgn-r-10"></i
                      >Browse File
                    </button>
                    <span
                      *ngIf="updated['registration_plate_photo']"
                    >
                      
                      <div *ngIf="updated['registration_plate_photo'] && progressBar">
                        <div class="progress">
                          <div class="progress-bar" role="progressbar"  aria-valuemin="0" aria-valuemax="100" [style.width.%]="progressValue">
                          </div>
                        </div>
                        <p class="upldTxt">uploading...</p>
                      </div>
                      <div class="button-container" *ngIf="updated['registration_plate_photo'] && !progressBar">
                        <span class="del" (click)="deleteDocumentForBulkUpload()">Delete</span>
                        <span>|</span>
                        <span class="pre" (click)="previewDocument(regIn, 'registration_plate_photo')">Preview</span>
                      </div>
                    </span>
                    
                    <div class="button-container" *ngIf="contentList[index]['Registration Plate Photo'] && 
                    !updated['registration_plate_photo'] && !progressBar">
                      <span class="del" (click)="deleteDocumentForBulkUpload()">Delete</span>
                      <span>|</span>
                      <span class="pre" (click)="previewDocument(regIn, 'registration_plate_photo')">Preview</span>
                    </div>
                  </ng-container>
                </div>
                <span
                  *ngIf="documentInputErrors['reg_plate_photo']"
                  class="error-message"
                  >{{ documentInputErrors["reg_plate_photo"] }}</span
                >
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="form-group mb-2 fl">
              <label for="veh_name" class="label-color">Vehicle Name*</label>
              <input
                readonly
                class="inputOfForm"
                type="text"
                id="veh_name"
                formControlName="veh_name"
                [value]="
                  'Auto-fill with Last 4 digits of Reg number and This is read-only text'
                "
              />
              <!-- <div
                *ngIf="
                  myForm.get('veh_name')?.invalid &&
                  myForm.get('veh_name')?.touched
                "
                class="error-message"
              >
                Vehicle Name is Mandatory
              </div> -->
            </div>
          </div>
          <div class="col-4">
            <div class="form-group mb-2 fl">
              <label for="veh_own" class="label-color"
                >Client Name*</label
              >
              <select
                id="veh_own"
                formControlName="veh_own"
                class="form-control inputOfForm"
                multiple
                data-live-search="true"
                placeholder="Please Select"
              >
                <option *ngFor="let code of clientNamesList" [attr.value]="code">
                  {{ code }}
                </option>
              </select>
              <!-- <select
                id="veh_own"
                formControlName="veh_own"
                class="form-control slectInForm"
                multiple
                (change)="onOptionsSelected($event)"
              >
                <option *ngFor="let code of clientNamesList" [value]="code">
                  {{ code }}
                </option>
              </select> -->
              <div
                *ngIf="
                  myForm.get('veh_own')?.invalid &&
                  myForm.get('veh_own')?.touched
                "
                class="error-message"
              >
                Client Name is mandatory
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-4">
            <!-- Model Drop Menu -->
            <div class="form-group mb-2 fl">
              <label for="model" class="label-color">Engine Type*</label>
              <select
                id="model"
                formControlName="model"
                class="inputOfForm"
              >
                <!-- <option value="">Select Model</option> -->
                <option value="BS3">BS3</option>
                <option value="BS4">BS4</option>
                <option value="BS6">BS6</option>
                <option value="BS6.2">BS6.2</option>
              </select>
              <div
                *ngIf="
                  myForm.get('model')?.invalid && myForm.get('model')?.touched
                "
                class="error-message"
              >
              Engine Type is mandatory
              </div>
            </div>
          </div>

          <div class="col-4">
            <!-- Manufacturer Drop Menu -->
            <div class="form-group mb-2 fl">
              <label for="manufacturer" class="label-color"
                >Manufacturer*</label
              >
              <select
                id="manufacturer"
                formControlName="manufacturer"
                class="inputOfForm"
              >
                <!-- <option value="">Select Manufacturer</option> -->
                <option value="TATA">TATA</option>
                <option value="EICHER">EICHER</option>
                <option value="ASHOK LEYLAND">ASHOK LEYLAND</option>
                <option value="SML">SML</option>
                <option value="MAHINDRA">MAHINDRA</option>
                <option value="BHARAT BENZ">BHARAT BENZ</option>
                <option value="OTHERS">OTHERS</option>
              </select>
              <div
                *ngIf="
                  myForm.get('manufacturer')?.invalid &&
                  myForm.get('manufacturer')?.touched
                "
                class="error-message"
              >
                Manufacturer is mandatory
              </div>
            </div>
          </div>

          <div class="col-4">
            <!-- Model Year -->
            <div class="form-group mb-2 fl">
              <label for="man_year" class="label-color"
                >Model Year*</label
              >
              <!-- <input type="text" id="man_year" formControlName="man_year"> -->
              <select
                id="man_year"
                formControlName="man_year"
                class="inputOfForm"
              >
                <!-- <option value="">Select Manufacturer Year/option></option> -->
                <option value="2000">2000</option>
                <option value="2001">2001</option>
                <option value="2002">2002</option>
                <option value="2003">2003</option>
                <option value="2004">2004</option>
                <option value="2005">2005</option>
                <option value="2006">2006</option>
                <option value="2007">2007</option>
                <option value="2008">2008</option>
                <option value="2009">2009</option>
                <option value="2010">2010</option>
                <option value="2011">2011</option>
                <option value="2012">2012</option>
                <option value="2013">2013</option>
                <option value="2014">2014</option>
                <option value="2015">2015</option>
                <option value="2016">2016</option>
                <option value="2017">2017</option>
                <option value="2018">2018</option>
                <option value="2019">2019</option>
                <option value="2020">2020</option>
                <option value="2021">2021</option>
                <option value="2022">2022</option>
                <option value="2023">2023</option>
                <option value="2024">2024</option>
              </select>
              <div
                *ngIf="
                  myForm.get('man_year')?.invalid &&
                  myForm.get('man_year')?.touched
                "
                class="error-message"
              >
                Model Year is mandatory
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-4">
            <div class="form-group mb-2 fl">
              <label for="veh_col" class="label-color">Vehicle Colour*</label>
              <select
                id="veh_col"
                formControlName="veh_col"
                class="inputOfForm"
              >
                <!-- <option value="">Select Vehicle Color</option> -->
                <option value="Yellow">Yellow</option>
                <option value="White">White</option>
                <option value="Black">Black</option>
              </select>
              <div
                *ngIf="
                  myForm.get('veh_col')?.invalid && myForm.get('veh_col')?.touched
                "
                class="error-message"
              >
                Vehicle Colour is mandatory
              </div>
            </div>
          </div>

          <div class="col-4">
            <div class="form-group mb-2 fl">
              <label for="veh_mode" class="label-color">AC/Non AC*</label>
              <select
                id="veh_mode"
                formControlName="veh_mode"
                class="inputOfForm"
              >
                <!-- <option value="">Select Vehicle Mode</option> -->
                <option value="AC">AC</option>
                <option value="NON-AC">NON-AC</option>
              </select>
              <div
                *ngIf="
                  myForm.get('veh_mode')?.invalid &&
                  myForm.get('veh_mode')?.touched
                "
                class="error-message"
              >
                AC/NON AC field are Mandatory
              </div>
            </div>
          </div>

          <!-- AC Manufacturer Drop Menu -->
          <div class="col-4">
            <div class="form-group mb-2 fl">
              <label for="ac_manufacturer" class="label-color"
                >AC Manufacturer*</label
              >
              <select
                id="ac_manufacturer"
                formControlName="ac_manufacturer"
                class="inputOfForm"
              >
                <!-- <option value="">Select AC Manufacturer</option> -->
                <option value="Company Fitted">Company Fitted</option>
                <option value="Vendor">Vendor</option>
              </select>
              <div
                *ngIf="
                  myForm.get('ac_manufacturer')?.invalid &&
                  myForm.get('ac_manufacturer')?.touched
                "
                class="error-message"
              >
                AC Manufacturer is mandatory
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-4">
            <div class="form-group mb-2 fl">
              <label for="seating_cap" class="label-color"
                >Seating Capacity*</label
              >
              <select
                id="seating_cap"
                formControlName="seating_cap"
                class="inputOfForm"
              >
                <!-- <option value="">Select Seating Capacity</option> -->
                <option value="5">5</option>
                <option value="20">20</option>
                <option value="40">40</option>
                <option value="60">60</option>
                <option value="70">70</option>
                <!-- Populate options as needed -->
              </select>
              <div
                *ngIf="
                  myForm.get('seating_cap')?.invalid &&
                  myForm.get('seating_cap')?.touched
                "
                class="error-message"
              >
                Seating Capacity is mandatory
              </div>
            </div>
          </div>

          <div class="col-4">
            <div class="form-group mb-2 fl">
              <label for="seating_type" class="label-color">Seating Type*</label>
              <select
                id="seating_type"
                formControlName="seating_type"
                class="inputOfForm"
              >
                <!-- <option value="">Select Seating Type</option> -->
                <option value="High Back">High Back</option>
                <option value="Push Back">Push Back</option>
                <option value="Bench Seat">Bench Seat</option>
              </select>
              <div
                *ngIf="
                  myForm.get('seating_type')?.invalid &&
                  myForm.get('seating_type')?.touched
                "
                class="error-message"
              >
                Seating Type is mandatory
              </div>
            </div>
          </div>

          <div class="col-4">
            <div class="form-group mb-2 fl">
              <label for="fuel_type" class="label-color">Fuel Type*</label>
              <select
                id="fuel_type"
                formControlName="fuel_type"
                class="inputOfForm"
              >
                <!-- <option value="">Select Fuel Type</option> -->
                <option value="Diesel">Diesel</option>
                <option value="CNG">CNG</option>
                <option value="Petrol">Petrol</option>
                <option value="Electric">Electric</option>
                <option value="Hybrid">Hybrid</option>
              </select>
              <div
                *ngIf="
                  myForm.get('fuel_type')?.invalid &&
                  myForm.get('fuel_type')?.touched
                "
                class="error-message"
              >
                Fuel Type is mandatory
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-2">
          <!-- Fuel Tank Capacity Drop Menu -->
          <div class="col-4">
            <div class="form-group mb-2 fl">
              <label for="fuel_tank_cap" class="label-color"
                >Fuel Tank Capacity*</label
              >
              <select
                id="fuel_tank_cap"
                formControlName="fuel_tank_cap"
                class="inputOfForm"
              >
                <!-- <option value="">Select Fuel Tank Capacity</option> -->
                <option value="8">8</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <!-- Populate options as needed -->
              </select>
              <div
                *ngIf="
                  myForm.get('fuel_tank_cap')?.invalid &&
                  myForm.get('fuel_tank_cap')?.touched
                "
                class="error-message"
              >
                Fuel Tank Capacity is mandatory
              </div>
            </div>
          </div>

          <div class="col-4">
            <div class="form-group mb-2 fl">
              <label for="fav" class="label-color">FAV</label>
              <input
                type="text"
                id="fav"
                formControlName="fav"
                class="inputOfForm"
                maxlength="12"
              />
            </div>
          </div>

          <div class="col-4">
            <div class="form-group mb-2 fl">
              <label for="compatible_type" class="label-color"
                >Compatible Tyre</label
              >
              <select
                id="compatible_type"
                formControlName="compatible_type"
                class="inputOfForm"
              >
                <!-- <option value="">Select Compatible Tyre</option> -->
                <option value="4">4</option>
                <option value="8">8</option>
                <option value="10">10</option>
                <option value="12">12</option>
                <!-- Populate options as needed -->
              </select>
              <div
                *ngIf="
                  myForm.get('compatible_type')?.invalid &&
                  myForm.get('compatible_type')?.touched
                "
                class="error-message"
              >
                Compatible Tyre is mandatory
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-2">
          <!-- Number of Tyres -->
          <div class="col-4">
            <div class="form-group mb-2 fl">
              <label for="no_of_tyers" class="label-color"
                >No of Tyres(with Spare Tyre)</label
              >
              <select
                id="no_of_tyers"
                formControlName="no_of_tyers"
                class="inputOfForm"
              >
                <!-- <option value="">Select Compatible Tyre</option> -->
                <option value="4">4</option>
                <option value="8">8</option>
                <option value="10">10</option>
                <option value="12">12</option>
                <!-- Populate options as needed -->
              </select>
              <!-- <input type="text" id="no_of_tyers" formControlName="no_of_tyers" /> -->
              <div
                *ngIf="
                  myForm.get('no_of_tyers')?.invalid &&
                  myForm.get('no_of_tyers')?.touched
                "
                class="error-message"
              >
              Spare Tyre is mandatory
              </div>
            </div>
          </div>

          
          <div class="col-4">
            <div class="form-group mb-2 fl">
              <label for="veh_type" class="label-color">Vehicle Type*</label>
              <select
                id="veh_type"
                formControlName="veh_type"
                class="inputOfForm"
              >
                <option value="School Bus">School Bus</option>
                <option value="School LMV">School LMV</option>
                <option value="Company Bus">Company Bus</option>
                <option value="Others">Others</option>
              </select>
              <div
                *ngIf="
                  myForm.get('veh_type')?.invalid &&
                  myForm.get('veh_type')?.touched
                "
                class="error-message"
              >
                Vehicle Type is mandatory
              </div>
            </div>
          </div>



        </div>
      </div>
      <!-- Submit Button -->
      <div class="margin-left mb-5" style="right: 0" *ngIf="!showModal">
        <div>
          <button
            class="btn m-6 clrbttn"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
            (click)="clearMessage()"
          >
            Clear
          </button>
        </div>
        <div>
          <button class="btn svbtn m-6" type="submit">
            {{ isEditMode ? "Update" : "Save" }}
          </button>
        </div>
      </div>
    </form>
  </div>

</ng-container>

<!-- Modal -->
<div
  class="modal fade"
  id="staticBackdrop"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog top-40 modl-clear-pop">
    <div class="modal-content left-20">
      <div class="modal-header">
        <h5 class="modal-title fs-5" id="staticBackdropLabel">
          {{this.modalMessage}}
        </h5>
        <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
      </div>
      <!-- <div class="modal-body">
            ...
          </div> -->
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary okaybtn" data-bs-dismiss="modal">
          No
        </button>
        <button
          type="button"
          class="btn btn-primary okaybtn"
          data-bs-dismiss="modal"
          (click)="clearForm()"
        >
          Yes
        </button>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="!addcom && !isEditMode && !bulkFileUpload"
  class="card"
  style="
    box-shadow: -8px 0 8px rgba(0, 0, 0, 0.1), 8px 0 8px rgba(0, 0, 0, 0.1),
      0 10px 20px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  "
>
  <div class="card-header flex-between" style="border-radius: 10px">
    <h3>Your Vehicles</h3>
    <div class="flex-start">
      <!-- <button class="btn btn-light" (click)="fetchvehicles()">
        <i class="fa-solid fa-arrows-rotate"></i> Refresh
      </button> -->
      <button class="btn btn-light" (click)="refershFunction()">
        <i class="fa-solid fa-arrows-rotate"></i>
      </button>
      <div class="d-flex search-hold">
        <i class="fa-solid fa-magnifying-glass navy-icon"></i>
        <input
          type="text"
          placeholder="Search"
          name=""
          (keyup)="queryResult($event)"
          class="form-control search"
        />
      </div>
      <!-- <button class="opt-btn" (click)="get_excel_url()">Download File</button> -->
      <!-- <button class="btn export-btn" (click)="openFileExplorer()">
        Bulk Upload
      </button>
      <input
        type="file"
        (change)="fileSelected($event)"
        #fileInput
        style="display: none"
        accept=".xlsx, .csv, .pdf"
      /> -->
      <div class="options-container">
        <!-- <i class="fa-solid fa-ellipsis-vertical options-btn"(click)="toggleOptionsExport()"></i> -->
        <button *ngIf="access==='readandwrite'" class="blkbtn" type="button" (click)="toggleOptionsExport()">Bulk Upload</button>
        <!-- <div class="options-menu" *ngIf="expandedButton" > -->
          <div class="options-menu" [ngClass]="{ expanded: expandedButton }">

          <button class="opt-btn" (click)="get_excel_url()"><i class="fa-solid fa-download"></i>&nbsp; Sample File</button>
          <button class="opt-btn" (click)="openFileExplorer()"><i class="fa-solid fa-upload"></i>&nbsp; Upload File </button>
          <input type="file" (change)="fileSelected($event)" #fileInput style="display: none" accept=".xlsx, .csv, .pdf"/>
        </div>
      </div>
      <button *ngIf="access==='readandwrite'" class="btn btn-success-soft svbtn" (click)="addCompany()">
        <i class="fa-solid fa-plus"></i> New
      </button>
      
      <button class="btn export-btn" (click)="exportEach(exportVar)">
        Export
      </button>
      <!-- <input
        type="file"
        (change)="fileSelected($event)"
        #fileInput
        style="display: none"
        accept=".xlsx, .csv, .pdf"
      /> -->
    </div>
  </div>
  <!-- Your existing code -->
  <div class="card-body">
    <div class="overflow-auto">
      <table class="table table-docs table-hover table-striped">
        <!-- Table headers -->
        <thead>
          <tr>
            <th>S.N.</th>
            <th *ngFor="let h of headers">
              {{ h.name }}
              <span
                *ngIf="h.sort && h.sort.sort"
                (click)="notifier.sortTable(h, filteredData)"
              >
                <i
                  class="fa fa-sort-amount-desc"
                  aria-hidden="true"
                  *ngIf="h.sort.direction == 'Des'"
                ></i>
                <i
                  class="fa fa-sort-amount-asc"
                  aria-hidden="true"
                  *ngIf="h.sort.direction == 'Asc'"
                ></i>
              </span>

              <span *ngIf="h.filter" class="inputseach">
                <input
                  autocomplete="off"
                  type="text"
                  [(ngModel)]="h.filterText"
                />
                <i class="fa fa-search" aria-hidden="true"></i>
              </span>
            </th>
            <th colspan="2" *ngIf="access==='readandwrite'">Actions</th>
          </tr>
        </thead>
        <tbody
          [ngStyle]="{
            display: filteredData.length > 0 ? 'table-row-group' : 'none'
          }"
        >
          <ng-container
            *ngFor="
              let i of filteredData
                | paginate
                  : {
                      itemsPerPage: pagination['perPage'],
                      currentPage: pagination['page'],
                      totalItems: filteredData.length
                    };
              let j = index
            "
          >
            <tr *ngIf="notifier.filterTableColums(headers, i)">
              <td>
                {{ (pagination["page"] - 1) * pagination["perPage"] + j + 1 }}
              </td>
              <td *ngFor="let header of headers">
                
                <span *ngIf="header.name === 'Registration Number'" class="record-click" (click)="openModal(i) ">
                  {{header.id.includes("vehicles_details")
                  ? i.vehicles_details[header.id.split(".")[1]]
                  : i[header.id] }}
                </span >
                <span *ngIf="header.name !== 'Registration Number'">
                  {{header.id.includes("vehicles_details")
                  ? i.vehicles_details[header.id.split(".")[1]]
                  : i[header.id] }}
                </span >
              </td>
              <td *ngIf="access==='readandwrite'" class="text-center">
                <i class="fas fa-edit edit-icon" (click)="editvehicle(i)"></i>
              </td>
            </tr>
          </ng-container>
        </tbody>
        <tbody
          [ngStyle]="{
            display: filteredData.length == 0 ? 'table-row-group' : 'none'
          }"
        >
          <tr>
            <td colspan="12" class="text-center">
              <div class="no_data text-center">No Vehicles Found</div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="paginator">
        <div class="flex-start">
          <span class="no-wrap">Per Page</span>
          <select
            class="form-select brd-rds-5"
            [(ngModel)]="pagination['perPage']"
            (change)="pagination['page'] = 1"
          >
            <option value="{{ page }}" *ngFor="let page of pages">
              {{ page }}
            </option>
          </select>
        </div>
        <pagination-controls
          class="pagination"
          previousLabel="Prev"
          nextLabel="Next"
          (pageChange)="pagination['page'] = $event"
        >
        </pagination-controls>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="bulkFileUpload && !isEditMode"
  class="card"
  style="
    box-shadow: -8px 0 8px rgba(0, 0, 0, 0.1), 8px 0 8px rgba(0, 0, 0, 0.1),
      0 10px 20px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  "
>
  <div class="card-header flex-between" style="border-radius: 10px">
    <h3>Bulk Upload Vehicles</h3>
    <div class="flex-start">
      <button class="btn btn-light" (click)="gotToHomePage()">
        <i class="fa-solid"></i>Home Page
      </button>
      <button class="btn svbtn" (click)="onSubmitAllDataBulkUp()">
        <i class="fa-solid fa-plus"></i> Submit All
      </button>
    </div>
  </div>
  <!-- Your existing code -->
  <div class="card-body">
    <div class="overflow-auto">
      <table class="table table-docs table-hover table-striped">
        <!-- Table headers -->
        <thead>
          <tr>
            <th>S.N.</th>
            <th *ngFor="let h of headers2">
              {{ h.name }}
              <span
                *ngIf="h.sort && h.sort.sort"
                (click)="notifier.sortTable(h, contentList)"
              >
                <i
                  class="fa fa-sort-amount-desc"
                  aria-hidden="true"
                  *ngIf="h.sort.direction == 'Des'"
                ></i>
                <i
                  class="fa fa-sort-amount-asc"
                  aria-hidden="true"
                  *ngIf="h.sort.direction == 'Asc'"
                ></i>
              </span>

              <span *ngIf="h.filter" class="inputseach">
                <input
                  autocomplete="off"
                  type="text"
                  [(ngModel)]="h.filterText"
                />
                <i class="fa fa-search" aria-hidden="true"></i>
              </span>
            </th>
            <th colspan="2" *ngIf="access==='readandwrite'">Actions</th>
          </tr>
        </thead>
        <tbody
          [ngStyle]="{
            display: contentList.length > 0 ? 'table-row-group' : 'none'
          }"
        >
          <ng-container
            *ngFor="
              let i of contentList
                | paginate
                  : {
                      itemsPerPage: pagination['perPage'],
                      currentPage: pagination['page'],
                      totalItems: contentList.length
                    };
              let j = index
            "
          >
            <tr *ngIf="notifier.filterTableColums(headers2, i)">
              <td>
                {{ (pagination["page"] - 1) * pagination["perPage"] + j + 1 }}
              </td>

              <td *ngFor="let header of headers2">
                {{
                  header.id.includes("vehicles_details")
                    ? i.vehicles_details[header.id.split(".")[1]]
                    : i[header.id]
                }}
              </td>
              <td *ngIf="access==='readandwrite'">
                <i class="fas fa-edit edit-icon" (click)="editVehicleForBulkUpload(i)"></i>
              </td>
            </tr>
          </ng-container>
        </tbody>
        <!-- Additional tbody to display "No Users Found" message -->
        <tbody
          [ngStyle]="{
            display: contentList.length == 0 ? 'table-row-group' : 'none'
          }"
        >
          <tr>
            <td colspan="12" class="text-center">
              <div class="no_data text-center">No Vehicles Found</div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="paginator">
        <div class="flex-start">
          <span class="no-wrap">Per Page</span>
          <select
            class="form-select"
            [(ngModel)]="pagination['perPage']"
            (change)="pagination['page'] = 1"
          >
            <option value="{{ page }}" *ngFor="let page of pages">
              {{ page }}
            </option>
          </select>
        </div>
        <pagination-controls
          class="pagination"
          previousLabel="Prev"
          nextLabel="Next"
          (pageChange)="pagination['page'] = $event"
        >
        </pagination-controls>
      </div>
    </div>
  </div>
</div>

<app-submit-popup *ngIf="popUp" (hideModa)="hidepopup($event)" [header]="popup_header" [subtitle]="popup_subtitle"></app-submit-popup>