import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APIService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css'],
})
export class ClientsComponent implements OnInit, OnDestroy {
  userId: string | null = localStorage.getItem('eId');
  selectedClient: any | null = null;
  clients: any = [];

  headers: any = [
    {
      id: 'userName',
      name: 'Name',
      click: 'updateUserCaller'
    },
    {
      id: 'email',
      name: 'Email',
    },
    {
      id: 'company',
      name: 'Company',
    },
    {
      id: 'mobile',
      name: 'Mobile',
    }
  ];

  router: any;
  type: string | null = null;
  config: any;

  constructor(
    private apiServive: APIService,
    public notifier: NotifierService,
    private activeRoute: ActivatedRoute
  ) {
    this.config = this.activeRoute.snapshot.data['config'];
  }

  ngOnInit(): void {
    this.router = this.activeRoute.params.subscribe((values: any) => {
      if (values['type']) {
        this.type = values['type'];
        this.load();
      } else {
        this.type == null;
        this.clients = [];
      }
    });
  }

  async load() {
    this.notifier.loading(true);
    this.clients = [];
    // let data: any = { a: this.type == 'admins' ? 'fetchAdmins' : 'fetchClient', requester: this.userId, email: this.userId };
    let data: any = { a: 'fetchAdmins', requester: this.userId, email: this.userId };


    let headers: any = {
      Authorization: localStorage.getItem('sessiontoken'),
      'X-Api-Key': localStorage.getItem('clientid'),
    };

    let apiURL: string = `${this.config.apiURL}/sa/clients`;

    let result: any = await this.apiServive.postDataPromis(
      apiURL,
      data,
      headers
    );

    if (result.s == '1' || result.status == '1') {
      this.clients = result.adminData;
      this.clients.forEach((client: any) => {
        Object.keys(client['mList'] || {}).forEach((menu: any) => {
          if(client['mList'][menu].length == 1 && client['mList'][menu][0] == menu) {
            client['mList'][menu] = [];
          }
        })
        client['menuStr'] = Object.keys(client['mList'] || {}).join(', ');
      });
    } else {
      this.notifier.alert(
        'Info',
        '',
        result.error || result.error_message || result.message,
        'info',
        5000
      );
    }

    this.notifier.loading(false);
  }

  updateUser: any = null;
  action: string | null = null;

  inviteUser() {
    this.action = 'add';
    this.updateUser = {
      email: '',
      userName: '',
      company: localStorage.getItem('cmp') || '',
      mobile: '',
      mList: {},
      otpReq: true
    };
    
  }

  updateUserCaller(ref: any, value: any) {
    ref.updateUser = value;
    ref.action = 'update';
  }

  close(event: boolean) {
    if(event) {
      this.load();
    }
    this.updateUser = null;
  }

  funRef: any = {
    updateUserCaller: this.updateUserCaller
  };
  callFunction(id: any, value: any) {
    this.funRef[id](this, value);
  }

  ngOnDestroy(): void {
    this.router.unsubscribe();
  }
}
