import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-disable-component',
  templateUrl: './disable-component.component.html',
  styleUrls: ['./disable-component.component.css'],
})
export class DisableComponentComponent implements OnInit {
  @Input('email') email: any;
  @Input('action') action: any;
  @Output('hideModal') hideModal: any = new EventEmitter<boolean>();

  whitelabelData: any = {
    pd: {
      appName: '',
      logoDark: '',
      logoLight: '',
    },
  };

  constructor(private route: ActivatedRoute) {
    let whitelabelData = localStorage.getItem('whitelabelData');
    if (whitelabelData) {
      this.whitelabelData = JSON.parse(whitelabelData);
    }
  }

  ngOnInit(): void {}
}
