<div class="card">
  <div class="card-body">
    <ng-container *ngIf="event">
      <a
        class="p-1 btn btn-light flex-start d-inline-flex"
        routerLink="/dash/venue-details"
        [queryParams]="{
        event_id: event['event_id'],
        venue_id: event['venue_id'],
        date: event['date'],
        event_name: event['event_name'],
      }"
      >
        <i class="fa fa-angle-left" aria-hidden="true"></i>
        <span>Back to Race Page</span>
      </a>
      <div class="flex-center head-container">
        <div>
          <img
            src="https://tsd-prd01-raceday-operations.s3.ap-southeast-2.amazonaws.com/old_frontend/tripleSLogo.png"
            alt=""
          />
        </div>
        <div class="flex-1 center">
          <h2 class="fw-bold">{{ event["venue"] }}</h2>
          <h3 class="fw-bold">
            Race {{ event["race_number"] }}: {{ event["race_name"] }} -
            {{ event["race_length"] }}m
          </h3>
          <h4 class="p-1 fw-bold">{{ event["race_time"] }}</h4>
          <h5>
            Race Status -
            <app-status-view
              [statuses]="statuses"
              [selected]="event['race_status']"
            ></app-status-view>
          </h5>
          <div class="flex-center p-2 pb-3">
            <h5>Current EQ Trace Track:</h5>
            <div>
              <select
                class="form-select"
                [(ngModel)]="event['current_eq_trace_track']"
              >
                <option
                  value="{{ trace['value'] }}"
                  *ngFor="let trace of traceList"
                >
                  {{ trace["name"] }}
                </option>
              </select>
            </div>
          </div>
          <h5>Current Kit - {{ event["tag_id"] }}</h5>
        </div>
        <div>
          <img
            src="https://tsd-prd01-logos.s3.ap-southeast-2.amazonaws.com/venues/{{
              event['venue_id']
            }}.png"
            alt=""
          />
        </div>
      </div>
      <div class="flex-between">
        <div>
          <a
            class="btn btn-success-soft"
            *ngIf="event['previous_race_exists']"
            routerLink="/dash/race-details"
            [queryParams]="{ race_id: event['previous_race_id'] }"
            >Previous Race</a
          >
        </div>
        <div>
          <a
            class="btn btn-success-soft"
            *ngIf="event['next_race_exists']"
            routerLink="/dash/race-details"
            [queryParams]="{ race_id: event['next_race_id'] }"
            >Next Race</a
          >
        </div>
      </div>
      <div *ngIf="event">
        <table-view
          [headers]="headers"
          [data]="event.entrants"
          [loading]="loading"
          (sendEvent)="captureEvent($event)"
        ></table-view>
      </div>
    </ng-container>
    <ng-container *ngIf="event == null">
      <h2 class="center">{{ error }}</h2>
    </ng-container>
  </div>
</div>
