import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-status-view',
  templateUrl: './status-view.component.html',
  styleUrls: ['./status-view.component.css']
})
export class StatusViewComponent implements OnChanges{
  @Input('statuses') statuses: any;
  @Input('selected') selected: string | null = null;
  selectedType: string | null = null;

  getSelectedState() {
    let dt: any = this.statuses.find((status: any) => {
      return status['value'] == this.selected;
    })
    this.selectedType = dt ? dt['type'] : null;

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getSelectedState();
  }
}
