import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

import { APIService, tokenExpired } from 'src/app/services/api.service';
import * as moment from 'moment';
import { NotifierService } from 'src/app/services/notifier.service';
import { environment } from 'src/environments/environment';

declare var window: any;

@Component({
  selector: 'auth-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit, OnDestroy {
  otpRequired: boolean = false;
  tokenExpired: any = tokenExpired;
  userId: string | null = localStorage.getItem('eId');
  whitelabelData: any = {
    pd: {
      appName: '',
      logoDark: '',
      logoLight: '',
    },
  };

  passwordUpdated: boolean = false;
  registered: boolean = false;

  expiresIn: number | null = null;

  register: boolean = false;

  loading: any = {
    login: false,
    otp: false,
  };

  username: string = '';
  password: string = '';
  otpText: string = '';

  forgotPassword: boolean = false;

  uid: string | null = localStorage.getItem('t');
  t: string | null = localStorage.getItem('t');
  returnUrl: string = '';
  role: string = 'Superadmin';

  hideRoleSelection: boolean = false;

  isInvalid = false;
  error = '';
  validations: any = {
    username: {
      policy: {
        check: {
          key: 'otpRequired',
          value: false,
        },
        required: true,
        regex: (text: string) => {
          let regexFormat: RegExp =
            /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
          return regexFormat.test(text);
        },
      },
      name: 'Email ID',
    },
    password: {
      policy: {
        check: {
          key: 'otpRequired',
          value: false,
        },
        required: true,
      },
      name: 'Password',
    },
    otpText: {
      policy: {
        required: true,
        check: {
          key: 'otpRequired',
          value: true,
        },
      },
      name: 'OTP',
    },
  };
  validate(): boolean {
    let ref: any = this;
    let check: boolean = true;
    Object.keys(this.validations).forEach((key: string) => {
      this.errors[key]['error'] = false;
      if (
        (this.validations[key]['policy']['check'] &&
          ref[this.validations[key]['policy']['check']['key']] ==
          this.validations[key]['policy']['check']['value']) ||
        !this.validations[key]['policy']['check']
      ) {
        if (
          this.validations[key]['policy']['required'] &&
          (!ref[key].trim() || ref[key].trim() == '')
        ) {
          this.errors[key]['error'] = true;
          this.errors[key][
            'message'
          ] = `${this.validations[key]['name']} is a mandatory field`;
          check = false;
        } else if (
          this.validations[key]['policy']['regex'] &&
          this.validations[key]['policy']['regex'](ref[key]) === false
        ) {
          this.errors[key]['error'] = true;
          this.errors[key][
            'message'
          ] = `Entered ${this.validations[key]['name']} is not valid`;
          check = false;
        } else {
          this.errors[key]['error'] = false;
        }
      } else {
        this.errors[key]['error'] = false;
      }
    });

    return check;
  }
  errors: any = {
    username: {
      error: false,
      message: '',
    },
    password: {
      error: false,
      message: '',
    },
    otpText: {
      error: false,
      message: '',
    },
  };
  showPassword: boolean = false;
  passwordInterval: any = null;

  showpopOTP = false;
  otp = '';
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';

  redirectURL: any;

  alterPassword() {
    this.showPassword = !this.showPassword;
    if (this.showPassword) {
      this.passwordInterval = setTimeout(() => {
        this.showPassword = false;
        clearInterval(this.passwordInterval);
        this.passwordInterval = null;
      }, 5000);
    } else if (this.passwordInterval) {
      clearInterval(this.passwordInterval);
      this.passwordInterval = null;
    }
  }

  config: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiServer: APIService,
    private notifier: NotifierService
  ) {
    this.whitelabelData = this.route.snapshot.data['data'];
    this.config = this.route.snapshot.data['config'];
  }

  async ngOnInit() {
    this.route.queryParams.subscribe((res) => {
      if (res['pass']) {
        this.passwordUpdated = true;
        this.router.navigate(['/auth/login']);
        return true;
      }
      if (res['register']) {
        this.registered = true;
        this.router.navigate(['/auth/login']);
        return true;
      }
      this.redirectURL = JSON.parse(JSON.stringify(res));
      if (
        this.redirectURL['redirectURL'] &&
        this.redirectURL['redirectURL'].indexOf('?') > -1
      ) {
        this.redirectURL['redirectURL']
          .split('?')[1]
          .split('&')
          .map((query: any) => {
            this.redirectURL[query.split('=')[0]] = query.split('=')[1];
          });
      }
      if (this.redirectURL['redirectURL']) {
        this.redirectURL['redirectURL'] =
          this.redirectURL['redirectURL'].split('?')[0];
      } else {
        this.redirectURL = null;
      }
      return true;
    });
    //localStorage.clear();

    if (
      window.location.href.indexOf('app.') != -1 ||
      window.location.href.indexOf('www.app.') != -1
    ) {
      this.role = 'Admin';
      this.hideRoleSelection = true;
    } else if (
      window.location.href.indexOf('client.') != -1 ||
      window.location.href.indexOf('www.client.') != -1
    ) {
      this.role = 'Client';
      this.hideRoleSelection = true;
    } else if (
      window.location.href.indexOf('sa.') != -1 ||
      window.location.href.indexOf('www.sa.') != -1
    ) {
      this.role = 'Superadmin';
      this.hideRoleSelection = true;
    }

    setTimeout(() => {
      this.ifTokenIsValid();
    }, 1000);
  }

  async ifTokenIsValid() {
    if (
      localStorage.getItem('rt') == null &&
      localStorage.getItem('t') == null
    ) {
      return false;
    }
    this.tokenExpired['checking'] = true;
    let data = {
      //fromIndex: 1,
      //toIndex: '10',
      a: 'check',
    };

    let header: any = {
      Authorization: localStorage.getItem('t'),
    };

    if (this.role == 'Superadmin') {
      header['Authorization'] = localStorage.getItem('sessiontoken');
      header['X-Api-Key'] = localStorage.getItem('eId');
      this.tokenExpired['checking'] = false;
      return;
    }

    let apiURL: string = `${this.config.apiURL}/${this.urlPrefix}/Settings/usermanagement`;

    if (this.role == 'Superadmin') {
      apiURL = `${this.config.apiURL}/sa/clients`;
    }

    let result = await this.apiServer.postDataPromisNew(apiURL, data, header);
    this.tokenExpired['checking'] = false;
    if (result.s == '1' || result.status == '1') {
      //this.router.navigate(['/dash']);
      if (this.redirectURL) {
        let temp = this.redirectURL['redirectURL'];
        if (!temp) {
          if (this.role == 'Admins') {
            this.router.navigate(['/admin/company']);
            // this.router.navigate(["/dash/tell-me/alerts"]);
            return true;
          } else if (this.role == 'Admin') {
            let storage: any = localStorage.getItem('menuList');
            if (storage) {
              storage = JSON.parse(storage);
              if (Object.keys(storage).includes('Dashboard')) {
                this.router.navigate(['/admin/company']);
                // this.router.navigate(["/dash/tell-me/alerts"]);
                return true;
              } else {
                this.router.navigate(['/admin/company']);
                return true;
              }
            } else {
              this.router.navigate(['/admin/company']);
              return true;
            }
          } else if (this.role == 'Superadmin') {
            this.router.navigate(['/superadmin/users/admins']);
            return true;
          }
          return false;
        }
        delete this.redirectURL['redirectURL'];
        this.router.navigate([temp], { queryParams: this.redirectURL });
        return true;
      } else {
        if (this.role == 'Admin') {
          this.router.navigate(['/admin/company']);
          // this.router.navigate(["/dash/tell-me/alerts"]);
          return true;
        } else if (this.role == 'Client') {
          let storage: any = localStorage.getItem('menuList');
          if (storage) {
            storage = JSON.parse(storage);
            if (Object.keys(storage).includes('Dashboard')) {
              this.router.navigate(['/dash/settings/profile']);
              // this.router.navigate(["/dash/tell-me/alerts"]);
              return true;
            } else {
              this.router.navigate(['/dash/settings/profile']);
              return true;
            }
          } else {
            this.router.navigate(['/dash/settings/profile']);
            return true;
          }
        } else if (this.role == 'Superadmin') {
          this.router.navigate(['/superadmin/users/admins']);
          return true;
        }
      }
    } else {
      //localStorage.clear();
    }

    return true;
  }

  async login(validate: boolean = true) {
    // console.log("inside login");
    if (this.tokenExpired['checking']) {
      // console.log("inside login_");
      return false;
    }
    this.otpText = '';
    //this.notifier.alert("Info", "", "selector as an optional parameter", "info");
    //this.notifier.alert("Success", "", "selector as an optional parameter", "success");
    //this.notifier.alert("Warning", "", "selector as an optional parameter", "warning");
    //this.notifier.alert("Error", "", "selector as an optional parameter", "error");

    localStorage.clear();

    if (validate && !this.validate()) {
      return false;
    }
    // console.log("inside login#");

    /*    this.loading = true;*/

    // if (this.username == '' || this.password == '') {
    //   this.error = 'Please Enter User Name and Password!';
    //   this.notifier.alert(
    //     'Error',
    //     '',
    //     'Please Enter User Name and Password',
    //     'error',
    //     5000
    //   );
    //   this.isInvalid = true;
    //   this.notifier.loading(false);
    //   return false;
    // }

    this.loading['login'] = true;

    // if (this.username == "" || this.password == "") {
    //     this.error = "Please Enter User Name and Password!";
    //     this.notifier.alert(
    //         "Error",
    //         "",
    //         "Please Enter User Name and Password",
    //         "error",
    //         5000
    //     );
    //     this.isInvalid = true;
    //     this.notifier.loading(false);
    //     return false;
    // }
    // console.log("inside login 1");
    let data: any = {
      a: 'login',
      email: this.username.toLowerCase(),
      password: this.password,
    };
    // console.log("inside login 2");

    let apiURL = `${this.config.apiURL
      }/${this.role.toLowerCase()}/globals/user`;

    if (this.role == 'Superadmin') {
      data = {
        action: 'login',
        userId: this.username.toLowerCase(),
        password: this.password,
      };

      apiURL = `${this.config.apiURL}/sa/user`;
    }
    // console.log("API call in inside login");
    let result = await this.apiServer.postDataPromis(apiURL, data);

    if (result.status == '1' || result.s == '1') {
      if (this.role == 'Admin') {
        result = { ...result, ...result.admin };
        result.mfaRequired = true;
      } else if (this.role == 'Client') {
        result = { ...result, ...result.client };
        result['user'] = { ...result };
        result['user']['eId'] = result['email'];
        result['user']['un'] = result['userName'];
        result['user']['desig'] = result['designation'];
        result['user']['com'] = result['company'];
        result['user']['ph'] = result['mobile'];
        result['user']['ut'] = result['userType'];
        result['user']['customerType'] = result['customerType'];
        result['user']['expiresIn'] = result['expiresIn'];
        result['user']['accountStatus'] = result['status'];
      } else if (this.role == 'Superadmin') {
        localStorage.setItem('un', 'superadmin');
        localStorage.setItem('eId', this.username);
        localStorage.setItem('role', 'Superadmin');
        this.otpRequired = true;
      }
      this.resend = result.otpMail || 'no-reply@swayam.cloud';
      //this.showpopOTP = true;

      if (this.role == 'Client') {
        let menus = Object.keys(result.user.mList || {});
        if (menus.length > 0) {
          menus = menus.sort((a: any, b: any) => {
            let a_str = Number(a.split('_')[1]);
            let b_str = Number(b.split('_')[1]);
            return a_str - b_str;
          });
          let menuList: any = {};
          menus.forEach((menu: any) => {
            let menu_str = menu.split('_')[0];
            menuList[menu_str] = result.user.mList[menu];
          });
          result.user.mList = menuList;
        }

        localStorage.setItem('menuList', JSON.stringify(result.user.mList));

        localStorage.setItem('ut', result.user.userType);
        localStorage.setItem('un', result.user.un);
        localStorage.setItem('ph', result.user.ph);

        localStorage.setItem('masterId', result.user.masterId);

        localStorage.setItem('com', result.user.com);
        localStorage.setItem('des', result.user.des);
        localStorage.setItem('accountStatus', result.user.accountStatus);
        localStorage.setItem('eId', result.email);
        localStorage.setItem('expires', result.user.expires);
        localStorage.setItem('productType', result.user.productType);
        localStorage.setItem('expiresIn', result.user.expiresIn || '');
        localStorage.setItem('customerId', result.user.customerId);
        localStorage.setItem('clientId', result.user.customerId);
        localStorage.setItem('subPeriod', result.user.subPeriod);
        localStorage.setItem('subType', result.user.subType);
        localStorage.setItem('tagsList', JSON.stringify(result.user.tagsList));
        localStorage.setItem('tagsEnabled', result.user.tagsEnabled);
        if (result.user.expires) {
          let period = moment(result.user.expires, 'YYYY-MM-DD');
          let diffObj: any;
          if (result.user.subPeriod) {
            let sub = moment(result.user.expires, 'YYYY-MM-DD').subtract(
              result.user.subPeriod,
              'months'
            );
            diffObj = period.diff(sub, 'days');
          } else {
            diffObj = result.user.expiresIn > 7 ? 15 : 7;
          }
          localStorage.setItem('totalPeriod', diffObj);
        }
        // this.expiresIn = result.user.expiresIn || 0;
        // if (result.user.customerType == 'mp') {
        //   if (result.user.expiresIn != undefined) {
        //     localStorage.setItem('accessType', 'Free Trial');
        //   }
        //   if (result.user.expiresIn == undefined) {
        //     localStorage.setItem('accessType', 'Billing');
        //   }
        // } else {
        //   localStorage.setItem('accessType', 'Managed');
        // }
        localStorage.setItem('subscription', result.user.subscription);
        localStorage.setItem('acT', result.admin.accessType);
        console.log('@@@@@@@@@@@', result.admin.accessType)
        localStorage.setItem('role', this.role);
        localStorage.setItem('customerType', result.user.customerType);

        result.mfaRequired = result.user.mfaRequired;
      } else if (this.role == 'Admin') {
        localStorage.setItem('accessType', 'Admin');
        localStorage.removeItem('accountGroupdetails');

        // localStorage.setItem('menuList', JSON.stringify(result.ml));

        localStorage.setItem('clientId', result.cid);
        // localStorage.setItem('t', result.at);

        localStorage.setItem('ut', result.userType);
        localStorage.setItem('un', result.userName);
        localStorage.setItem('ph', result.mobile);
        let menus = Object.keys(result.mList || {});
        if (menus.length > 0) {
          menus = menus.sort((a: any, b: any) => {
            let a_str = Number(a.split('_')[1]);
            let b_str = Number(b.split('_')[1]);
            return a_str - b_str;
          });
          let menuList: any = {};
          menus.forEach((menu: any) => {
            let menu_str = menu.split('_')[0];
            menuList[menu_str] = result.mList[menu];
          });
          result.mList = menuList;
        }
        localStorage.setItem('menuList', JSON.stringify(result.mList));

        localStorage.setItem('com', result.company);
        localStorage.setItem('des', result.designation);
        localStorage.setItem('eId', result.email);
        localStorage.setItem('acT', result.admin.accessType);
        console.log('###############',result.admin.accessType);
        localStorage.setItem('role', this.role);
        localStorage.setItem('accountDetailsList', result.accountDetailsList);
      } else if (this.role == 'Superadmin') {
      }

      try {
        window.analyticsMapUser(result.eId);
      } catch (e) {
        // console.log(e);
      }
      localStorage.setItem('otpReq', result.mfaRequired ? '1' : '0');
      if (!result.mfaRequired) {
        if (localStorage.getItem('accountStatus') == 'Inactive') {
          this.otpRequired = false;
          this.username = '';
          this.password = '';
          // this.router.navigate(["/dash/accounts/aws"]);
          this.notifier.alert(
            'Info',
            '',
            'Account is Disabled/Expired. Please check with admin',
            'info',
            5000
          );
        }
        if (localStorage.getItem('ut') == 'userAdmin') {
          localStorage.setItem('ut', 'client');
          localStorage.setItem('isUserAdmin', '1');
        }
        localStorage.setItem('t', result.at);
        localStorage.setItem('rt', result.rt);
        let cbsCheck =
          window.location.host == 'cbs.swayam.cloud' ||
          window.location.host == 'cbsadmin.swayam.cloud';
        if (cbsCheck) {
          this.router.navigate(['/cbs/mastersummary']);
          return true;
        } else {
          if (this.redirectURL) {
            let temp = this.redirectURL['redirectURL'];
            if (!temp) {
              if (this.role == 'Admin') {
                this.router.navigate(['/admin/company']);
                // this.router.navigate(["/dash/tell-me/alerts"]);
                return true;
              } else if (this.role == 'Client') {
                let storage: any = localStorage.getItem('menuList');
                if (storage) {
                  storage = JSON.parse(storage);
                  if (Object.keys(storage).includes('Dashboard')) {
                    this.router.navigate(['/admin/company']);
                    // this.router.navigate(["/dash/tell-me/alerts"]);
                    return true;
                  } else {
                    this.router.navigate(['/admin/company']);
                    return true;
                  }
                } else {
                  this.router.navigate(['/admin/company']);
                  return true;
                }
              }
              return false;
            }
            delete this.redirectURL['redirectURL'];
            this.router.navigate([temp], { queryParams: this.redirectURL });
            return true;
          } else {
            if (this.role == 'Admin') {
              this.router.navigate(['/admin/company']);
              // this.router.navigate(["/dash/tell-me/alerts"]);
              return true;
            } else if (this.role == 'Client') {
              let storage: any = localStorage.getItem('menuList');
              if (storage) {
                storage = JSON.parse(storage);
                if (Object.keys(storage).includes('Dashboard')) {
                  this.router.navigate(['/dash/settings/profile']);
                  // this.router.navigate(["/dash/tell-me/alerts"]);
                  return true;
                } else {
                  this.router.navigate(['/dash/settings/profile']);
                  return true;
                }
              } else {
                this.router.navigate(['/dash/settings/profile']);
                return true;
              }
            }
          }
        }
      } else {
        if (result.msg.toLowerCase() == 'success') {
          // await this.sendOPT();
          this.otpRequired = true;
        }
      }
    } else {
      if (result.error.toLowerCase().includes('password')) {
        this.errors['password'] = {
          error: true,
          message: result.error,
        };
      } else if (result.error.toLowerCase().includes('email')) {
        this.errors['username'] = {
          error: true,
          message: result.error,
        };
      } else {
        this.notifier.alert('Info', '', result.error, 'info', 5000);
      }
    }

    this.loading['login'] = false;
    return false;
  }

  // async sendOPT() {
  //   this.notifier.loading(true);
  //   let data = {
  //     ac: 'sendOtp',
  //     un: localStorage.getItem('un'),
  //     eId: localStorage.getItem('eId'),
  //     ut: localStorage.getItem('ut'),
  //   };

  //   let apiURL = `${this.config.apiURL}/admin/users/loginv2`;

  //   // if (this.role == 'Admin') {
  //   //   //apiURL = `${APIService.API_ENDPOINT}/login`;
  //   //   apiURL = `${this.config.apiURL}/admin/users/login`;

  //   //   data['ut'] = 'msops';
  //   // }

  //   let result = await this.apiServer.postDataPromis(apiURL, data);

  //   if (result.status == '1' || result.s == '1') {
  //   } else {
  //     this.notifier.alert('Error', '', result.msg, 'error', 5000);
  //   }
  //   this.notifier.loading(false);
  // }

  showExpired: boolean = false;
  validating: boolean = false;

  async validateOTP(event: any = null) {
    if (this.tokenExpired['checking']) {
      return false;
    }
    if ((!event && this.otpText.trim().length < 6) || this.validating)
      return false;
    if (!this.validate()) {
      return false;
    }
    this.validating = true;
    this.loading['otp'] = true;
    let data: any = {
      a: 'validatelogin',
      email: this.username.toLowerCase(),
      otp: this.otpText,
      password: this.password,
      userType: localStorage.getItem('ut'),
    };
    let apiURL: string = `${this.config.apiURL}/admin/globals/user`;

    if (this.role == 'Client') {
      data['ut'] = localStorage.getItem('ut');
      apiURL = `${this.config.apiURL}/${this.role.toLowerCase()}/globals/user`;
    } else if (this.role == 'Admin') {
      apiURL = `${this.config.apiURL}/admin/globals/user`;
    } else if (this.role == 'Superadmin') {
      apiURL = `${this.config.apiURL}/sa/user`;
      data = {
        action: 'validateOtp',
        userId: this.username,
        otp: this.otpText,
      };
    }

    // if (this.role == 'Admin') {
    //   //apiURL = `${APIService.API_ENDPOINT}/login`;
    //   apiURL = `${this.config.apiURL}/admin/users/login`;

    //   data['ut'] = 'msops';
    // }

    let result = await this.apiServer.postDataPromis(apiURL, data);

    if (result.status == '1' || result.s == '1') {
      // console.log("success login");
      localStorage.setItem('role', this.role);

      if (result.msg == 'Please try again with Valid OTP') {
        this.errors['otpText'] = {
          error: true,
          message: result.msg,
        };
        // this.notifier.alert("Error", "", result.msg, "error", 5000);
      } else if (this.role == 'Admin' || this.role == 'Client') {
        if (localStorage.getItem('ut') == 'userAdmin') {
          localStorage.setItem('ut', 'client');
          localStorage.setItem('isUserAdmin', '1');
        }
        localStorage.setItem('t', result.at);
        localStorage.setItem('rt', result.rt);
        localStorage.setItem('idT', result.id);

        if (localStorage.getItem('accountStatus') == 'Inactive') {
          this.otpRequired = false;
          this.username = '';
          this.password = '';
          this.otpText = '';
          // this.router.navigate(["/dash/accounts/aws"]);
          this.notifier.alert(
            'Info',
            '',
            'Account is Disabled/Expired. Please check with admin',
            'info',
            5000
          );
        } else {
          if (this.redirectURL) {
            let temp = this.redirectURL['redirectURL'];
            if (temp) {
              delete this.redirectURL['redirectURL'];

              this.router.navigate([temp], { queryParams: this.redirectURL });
              return true;
            }
          }
          if (this.role == 'Admin') {
            this.router.navigate(['/admin/company']);
            // this.router.navigate(["/dash/tell-me/alerts"]);
            return true;
          } else if (this.role == 'Client') {


            let storage: any = localStorage.getItem('menuList');
            if (storage) {
              storage = JSON.parse(storage);
              if (Object.keys(storage).includes('Dashboard')) {
                this.router.navigate(['/dash/settings/profile']);
                // this.router.navigate(["/dash/tell-me/alerts"]);
                return true;
              } else {
                // this.router.navigate(['/dash/settings/profile']);
                return true;
              }
            } else {
              // this.router.navigate(['/dash/settings/profile']);
              return true;
            }
          }
        }
      } else if (this.role == 'Superadmin') {
        localStorage.setItem('clientid', result.clientid);
        localStorage.setItem('sessiontoken', result.sessiontoken);
        localStorage.setItem('usertype', result.usertype);
        this.router.navigate(['/superadmin/users/admins']);
      }
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
    this.loading['otp'] = false;
    this.validating = false;
    return false;
  }

  resend: string = '';
  async loginOPT() {
    let data = {
      a: 'resendLogin',
      userName: localStorage.getItem('un'),
      email: this.username.toLowerCase(),
      userType: localStorage.getItem('ut'),
    };

    let apiURL = `${this.config.apiURL}/${this.urlPrefix}/globals/user`;

    this.otpText = '';

    // if (this.role == 'Admin') {
    //   // this.loading['otp'] = false;
    //   // this.loading['login'] = false;
    //   // this.resend = true;
    //   this.login(false);
    //   return false;
    //   // return false;
    //   //apiURL = `${APIService.API_ENDPOINT}/login`;
    //   // apiURL = `${this.config.apiURL}/admin/users/loginv2`;
    // } else {
    //   // this.loading['otp'] = true
    // }
    this.loading['login'] = true;

    let result = await this.apiServer.postDataPromis(apiURL, data);

    if (result.status == '1' || result.s == '1') {
    } else {
      this.notifier.alert(
        'Error',
        '',
        result.msg || result.error,
        'error',
        5000
      );

      this.error = result.msg;
    }

    this.resend = result.otpMail || 'no-reply@swayam.cloud';
    this.loading['otp'] = false;
    this.loading['login'] = false;

    return false;
  }

  ngOnDestroy() { }
}
