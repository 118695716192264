<div class="login-banner">Forgot Password</div>
<!-- <div class="login-short-info">
              Welcome back!
            </div> -->
<ng-container *ngIf="!otpSent">
  <div class="login-cred">
    <div class="line"></div>
    <div class="login-cred-text">Enter details</div>
    <div class="line"></div>
  </div>
  <form (ngSubmit)="sendOTP()">
    <div class="margin-bottom-10">
      <div class="email-container">
        <i class="fa fa-envelope left-align"></i>
        <input
          [(ngModel)]="username"
          [attr.disabled]="loading['forgot'] ? true : null"
          [ngModelOptions]="{ standalone: true }"
          autocomplete="off"
          class="form-control"
          placeholder="Email"
          type="email"
        />
      </div>
      <div *ngIf="errors['username']['error']" class="error-message">
        <i aria-hidden="true" class="fa fa-exclamation-triangle"></i>
        {{ errors["username"]["message"] }}
      </div>
    </div>
    <div class="flex-end">
      <a class="nav-link" routerLink="/auth/login">Login</a>
    </div>
    <div class="login-actions">
      <button
        *ngIf="!loading['forgot']"
        class="btn btn-minfy-color"
        type="submit"
        [attr.disabled]="tokenExpired['checking'] ? true : null"
      >
        Send OTP
      </button>
      <img
        [ngStyle]="{ display: loading['forgot'] ? 'inline' : 'none' }"
        alt=""
        class="width-18"
        src="./assets/img/loading_2.svg"
      />
    </div>
  </form>
  <div class="flex-center font-0-8">
    New to Intercity Transport?
    <a class="nav-link" routerLink="/auth/register">Register</a>
  </div>
</ng-container>
<ng-container *ngIf="otpSent">
  <div class="info">
    Please enter the OTP that you have received from <br />
    <span class="text-bold">{{ resend }}</span>
  </div>
  <form (ngSubmit)="resetPassword()">
    <div class="margin-bottom-10">
      <div class="email-container">
        <i class="fa fa-key left-align"></i>
        <input
          [(ngModel)]="otp"
          [attr.disabled]="loading['otp'] ? true : null"
          [ngModelOptions]="{ standalone: true }"
          autocomplete="off"
          class="form-control"
          placeholder="OTP"
          type="text"
        />
      </div>
      <div *ngIf="errors['otp']['error']" class="error-message">
        <i aria-hidden="true" class="fa fa-exclamation-triangle"></i>
        {{ errors["otp"]["message"] }}
      </div>
    </div>
    <div class="flex-end">
      <span (click)="sendOTP()" *ngIf="!loading['forgot']" class="nav-link"
        >Resend OTP</span
      >
      <img
        [ngStyle]="{ display: loading['forgot'] ? 'inline' : 'none' }"
        alt=""
        class="width-14"
        src="./assets/img/loading_2.svg"
      />
    </div>
    <div class="margin-bottom-10 margin-top-10">
      <div class="password-container">
        <i class="fa fa-lock left-align"></i>
        <input
          [(ngModel)]="newPassword"
          [attr.disabled]="loading['register'] ? true : null"
          [attr.type]="showPassword ? 'text' : 'password'"
          [ngModelOptions]="{ standalone: true }"
          autocomplete="off"
          class="form-control paddingleft"
          placeholder="Password"
        />
        <i
          (click)="alterPassword()"
          *ngIf="!showPassword"
          aria-hidden="true"
          class="fa fa-eye right-align"
        ></i>
        <i
          (click)="alterPassword()"
          *ngIf="showPassword"
          aria-hidden="true"
          class="fa fa-eye-slash right-align"
        ></i>
      </div>
      <div *ngIf="errors['newPassword']['error']" class="error-message">
        <i aria-hidden="true" class="fa fa-exclamation-triangle"></i>
        {{ errors["newPassword"]["message"] }}
      </div>
    </div>
    <div class="password-policy-container">
      <div
        *ngFor="
          let policy_key of getKeys(passwordChecker(newPassword)['policies']);
          let i = index
        "
        [ngClass]="{
          correct: passwordChecker(newPassword)['policies'][policy_key]
        }"
        class="password-policy flex-start flex-start-center"
      >
        <i
          *ngIf="!passwordChecker(newPassword)['policies'][policy_key]"
          aria-hidden="true"
          class="fa fa-times-circle"
        ></i>
        <i
          *ngIf="passwordChecker(newPassword)['policies'][policy_key]"
          aria-hidden="true"
          class="fa fa-check-circle"
        ></i>
        <span>
          {{ policy_key }}
        </span>
      </div>
    </div>
    <div class="margin-bottom-10">
      <div class="password-container">
        <i class="fa fa-lock left-align"></i>
        <input
          [(ngModel)]="newConfirmPassword"
          [attr.disabled]="loading['register'] ? true : null"
          [attr.type]="showPassword ? 'text' : 'password'"
          [ngModelOptions]="{ standalone: true }"
          autocomplete="off"
          class="form-control paddingleft"
          placeholder="Confirm Password"
        />
        <i
          (click)="alterPassword()"
          *ngIf="!showPassword"
          aria-hidden="true"
          class="fa fa-eye right-align"
        ></i>
        <i
          (click)="alterPassword()"
          *ngIf="showPassword"
          aria-hidden="true"
          class="fa fa-eye-slash right-align"
        ></i>
      </div>
      <div *ngIf="errors['newConfirmPassword']['error']" class="error-message">
        <i aria-hidden="true" class="fa fa-exclamation-triangle"></i>
        {{ errors["newConfirmPassword"]["message"] }}
      </div>
    </div>
    <div class="login-actions">
      <button
        *ngIf="!loading['otp']"
        class="btn btn-minfy-color"
        type="submit"
        [attr.disabled]="tokenExpired['checking'] ? true : null"
      >
        Validate
      </button>
      <img
        [ngStyle]="{ display: loading['otp'] ? 'inline' : 'none' }"
        alt=""
        class="width-18"
        src="./assets/img/loading_2.svg"
      />
    </div>
  </form>
</ng-container>
