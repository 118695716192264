import { Component, OnInit, Inject, NgModule  } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators, FormsModule } from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';


export interface back_value {
  value: string
}

export interface device_value {
  serialNumber: string;
  currentDevice:string;
  kit:string;
  tagid:string;
  jurisdiction:string;
  client:string;
  status:string;
  numberOfRaces:number;
  EndOfWarranty:boolean;
  hardwareVersion: string;
  currentFirmwareVersion: string;
  numberOfRepair:number;
  noteField:string;
  scheduledBatteryReplacement:string;
  amountOfBatteryReplacements:number;
  edit:boolean;
}


@Component({
  selector: 'app-device-list',
  templateUrl: './device-list.component.html',
  styleUrls: ['./device-list.component.css']
})
export class DeviceListComponent implements OnInit{
  title = 'device list';
  form!: FormGroup;
  form_add!: FormGroup;
  submitted = false;
  add = false;
  confirm_delete = false;
  displayedColumns: string[] = ['serialNumber', 'currentDevice', 'kit', 'tagid', 'jurisdiction', 'client', 'status', 'numberOfRaces', 'EndOfWarranty', 'hardwareVersion', 'currentFirmwareVersion', 'numberOfRepair', 'noteField', 'scheduledBatteryReplacement', 'amountOfBatteryReplacements', 'actions']

  device_property: any = {
    serialNumber: '',
    currentDevice: '',
    kit: '',
    tagid: '',
    jurisdiction: '',
    client: '',
    status: '',
    numberOfRaces: '',
    EndOfWarranty: '',
    hardwareVersion: '',
    currentFirmwareVersion: '',
    numberOfRepair: '',
    noteField: '',
    scheduledBatteryReplacement: '',
    amountOfBatteryReplacements: ''
  }
  jurisdictions: back_value[] = [{value: "a"}, {value: "b"}];
  jurisdictions_all: back_value[] = [];
  clients: back_value[] = [{value: "c"}, {value: "d"}];
  clients_all: back_value[] = [];
  kits: back_value[] = [{value: "e"}, {value: "f"}];
  devices: device_value[] = [];

  config: any = {};
  
  constructor(private formBuilder: FormBuilder, private http: HttpClient, public dialog: MatDialog, public router: Router, private route: ActivatedRoute) {
    this.config = this.route.snapshot.data['config'];
  }

  async ngOnInit(): Promise<void> {
    this.form_add = this.formBuilder.group(
      {
        serialNumber: [''],
        currentDevice: [''],
        kit: [''],
        tagid: [''],
        jurisdiction: [''],
        client: [''],
        status: [''],
        numberOfRaces: ['0'],
        EndOfWarranty: ['true'],
        hardwareVersion: [''],
        currentFirmwareVersion: [''],
        numberOfRepair: ['0'],
        noteField: [''],
        scheduledBatteryReplacement: [''],
        amountOfBatteryReplacements: ['0']
      }
    );
    this.form = this.formBuilder.group(
      {
        serialNumber: [''],
        jurisdiction: [''],
        client: [''],
        kit: [''],
      }
    );
    this.jurisdictions = [];
    this.http.get<any>(`${this.config['serverAPI']}/get_jurisdictions`).subscribe(data => {
        var jurisdiction_range = data.jurisdictions;
        jurisdiction_range.forEach((e: any) => {
          var bb = {value: e} as back_value
          this.jurisdictions.push(bb)
          this.jurisdictions_all.push(bb)
        });
    })
    this.clients = [];
    let queryParams = new HttpParams().append("jurisdiction","%");
    this.http.get<any>(`${this.config['serverAPI']}/get_clients`, {params:queryParams}).subscribe(data => {
        var client_range = data.clients;
        client_range.forEach((e: any) => {
          var bb = {value: e} as back_value
          this.clients.push(bb)
          this.clients_all.push(bb)
        });
    })
    this.kits = [];
    let queryParams2 = new HttpParams().append("jurisdiction","%").append("client","%");
    this.http.get<any>(`${this.config['serverAPI']}/get_device_kits`, {params:queryParams2}).subscribe(data => {
        var kit_range = data.kits;
        kit_range.forEach((e: any) => {
          var bb = {value: e} as back_value
          this.kits.push(bb)
        });
    })

  }
  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  editDevice(o: any){
    o.edit = true;
  }
  onAdd(){
    if(this.add){
      this.add = false;
    }else{
      this.add = true;
    }
    
  }
  onPDF(){
    let serialNumbers: string[] = [];

    for (let i = 0; i < this.devices.length; i++) {
      serialNumbers.push(this.devices[i].serialNumber);
    }

    let sending = {
      serialNumbers: serialNumbers
    };
    console.log(sending);
    this.http.post<any>(`${this.config['serverAPI']}/pdf_devices`, sending).toPromise();
  }
  firstdeleteDevice(o: any){
    this.confirm_delete = true;
  }
  deleteDevice(o: any){
    var todelete = o.serialNumber;
    console.log(todelete);
    let queryParams = new HttpParams().append("serialNumber", todelete);
    this.http.get<any>(`${this.config['serverAPI']}/delete_device`, {params:queryParams}).subscribe(data => {
      console.log(data)
      
    })
    this.confirm_delete = false;
  }
  async saveDevice(o: any){
    o.edit = false;
    //console.log(this.device_property.serialNumber);
    const sending: any = {};
    sending["original_serial"] = o.serialNumber;
    for (const key in this.device_property) {
      if (this.device_property.hasOwnProperty(key) && this.device_property[key] !== '') {
        const capitalizedKey = key.slice(0, 1).toUpperCase() + key.slice(1);
        sending[capitalizedKey] = this.device_property[key];
      }
    }

    console.log(sending);
    await this.http.post<any>(`${this.config['serverAPI']}/edit_devices/`, sending).toPromise();
    this.device_property = {
      serialNumber: '',
      currentDevice: '',
      kit: '',
      tagid: '',
      jurisdiction: '',
      client: '',
      status: '',
      numberOfRaces: '',
      EndOfWarranty: '',
      hardwareVersion: '',
      currentFirmwareVersion: '',
      numberOfRepair: '',
      noteField: '',
      scheduledBatteryReplacement: '',
      amountOfBatteryReplacements: ''
    }
  }

  onJurisChange(o: any){
    this.clients = [];
    let queryParams = new HttpParams().append("jurisdiction",o.value);
    this.http.get<any>(`${this.config['serverAPI']}/get_clients`, {params:queryParams}).subscribe(data => {
        var client_range = data.clients;
        client_range.forEach((e: any) => {
          var bb = {value: e} as back_value
          this.clients.push(bb)
        });
    })
    this.kits = [];
    let queryParams2 = new HttpParams().append("jurisdiction",o.value).append("client","%");
    this.http.get<any>(`${this.config['serverAPI']}/get_device_kits`, {params:queryParams2}).subscribe(data => {
        var kit_range = data.kits;
        kit_range.forEach((e: any) => {
          var bb = {value: e} as back_value
          this.kits.push(bb)
        });
    })
  }

  onClientChange(o: any){
    this.kits = [];
    if (this.form.value.jurisdiction == ''){
      this.form.value.jurisdiction = '%';
    }
    let queryParams2 = new HttpParams().append("jurisdiction",this.form.value.jurisdiction).append("client",o.value);
    this.http.get<any>(`${this.config['serverAPI']}/get_device_kits`, {params:queryParams2}).subscribe(data => {
        var kit_range = data.kits;
        kit_range.forEach((e: any) => {
          var bb = {value: e} as back_value
          this.kits.push(bb)
        });
    })
  }

  log(val: any) { console.log(val); }


  async addDevice(){
    this.add = false;
    //console.log(this.form_add.value);
    const sending: any = {};

    for (const key in this.form_add.controls) {
      
      const capitalizedKey = key.slice(0, 1).toUpperCase() + key.slice(1);
      sending[capitalizedKey] = this.form_add.controls[key].value;
      
    }
    await this.http.post<any>(`${this.config['serverAPI']}/add_device`, sending).toPromise();
    
  }


  onSubmit(): void {
    if (this.form.value.serialNumber != ''){
      this.devices = [];
      let queryParams = new HttpParams().append("SerialNumber",this.form.value.serialNumber);
      this.http.get<any>(`${this.config['serverAPI']}/get_serialNumber`, {params:queryParams}).subscribe(data => {
          var device_range = data.devices;
          device_range.forEach((e: any) => {
            var bb = {serialNumber: e[0],
              currentDevice:e[1],
              kit:e[2],
              tagid:e[3],
              jurisdiction:e[4],
              client:e[5],
              status:e[6],
              numberOfRaces:e[7],
              EndOfWarranty:e[8],
              hardwareVersion: e[9],
              currentFirmwareVersion: e[10],
              numberOfRepair:e[11],
              noteField:e[12],
              scheduledBatteryReplacement:e[13],
              amountOfBatteryReplacements:e[14],edit:false} as device_value
            this.devices.push(bb)
          });
      });

      (document.getElementById("search") as HTMLButtonElement).disabled = false;
      return;
    }

    this.devices = [];
    (document.getElementById("search") as HTMLButtonElement).disabled = true;
    this.submitted = true;
    if (this.form.value.jurisdiction == ''){
      this.form.value.jurisdiction = '%';
    }
    if (this.form.value.client == ''){
      this.form.value.client = '%';
    }
    if (this.form.value.kit == ''){
      this.form.value.kit = '%';
    }
    let queryParams = new HttpParams().append("jurisdiction",this.form.value.jurisdiction).append("client",this.form.value.client).append("kit",(this.form.value.kit));
    this.http.get<any>(`${this.config['serverAPI']}/get_devices`, {params:queryParams}).subscribe(data => {
        var device_range = data.devices;
        device_range.forEach((e: any) => {
          var bb = {serialNumber: e[0],
            currentDevice:e[1],
            kit:e[2],
            tagid:e[3],
            jurisdiction:e[4],
            client:e[5],
            status:e[6],
            numberOfRaces:e[7],
            EndOfWarranty:e[8],
            hardwareVersion: e[9],
            currentFirmwareVersion: e[10],
            numberOfRepair:e[11],
            noteField:e[12],
            scheduledBatteryReplacement:e[13],
            amountOfBatteryReplacements:e[14],edit:false} as device_value
          this.devices.push(bb)
        });
    });

   (document.getElementById("search") as HTMLButtonElement).disabled = false;
   //console.log(this.devices);
  }
}