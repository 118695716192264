<div *ngIf="addcom || isEditMode">
  <!-- <h3 class="text-center">{{ isEditMode ? 'Update Client Routes' : 'New Client Routes' }}</h3>
  <div class="flex-start">
    <button class="btn btn-light" (click)="  clearForm()">
      < Back </button>
  </div> -->
  <div class="flex-start ml-4 mt-3" *ngIf="!showModal">
    <button
      class="btn btn-light"
      data-bs-toggle="modal"
      data-bs-target="#staticBackdrop"
      (click)="goBack()"
    >
      <i class="fa fa-arrow-left" aria-hidden="true"></i>
    </button>
    <h4 class="mb-0 pb-0 font-weight-bold">
      {{ isEditMode ? "Update Client Routes" : "New Client Routes" }}
    </h4>
  </div>
  <div class="flex-start ml-4 mt-3" *ngIf="showModal">
    <button class="btn btn-light" (click)="clearForm()">
      <i class="fa fa-arrow-left" aria-hidden="true"></i>
    </button>
    <h4 class="mb-0 pb-0 font-weight-bold">
      {{ "Client Route Details" }}
    </h4>
  </div>
  <div class="card">
    <form [formGroup]="myForm" (ngSubmit)="onSubmit()" autocomplete="off">
      <!-- <h5 class="card-header">Client Information</h5> -->
      <div class="card-body">
        <div class="row mt-2">
          <!-- <div class="col-4">
            <div class="form-group mb-2 fl">
              <label for="clientName" class="label-color">Client Name*</label>
              <select
                id="clientName"
                [ngClass]="{ disabled: isEditMode }"
                [disabled]="showModal && isEditMode"
                class="inputOfForm"
                formControlName="clientName"
                (change)="client_details_auto_fill('clientName')"
              >
                <option [ngValue]="null">Select Client Name</option>
                <option *ngFor="let code of clientNamesList" [value]="code">
                  {{ code }}
                </option>
                Add more options as needed
              </select>
              <div
                *ngIf="
                  myForm.get('clientName')?.invalid &&
                  myForm.get('clientName')?.touched
                "
              >
                <span class="error-message">Client Name is required</span>
              </div>
            </div>
          </div> -->


          <div class="col-4">
            <!-- Client Name -->
            <div class="form-group mb-2 fl">
              <label for="clientName" class="label-color">Client Name*</label>
              <select
                id="clientName"
                class="inputOfForm"
                formControlName="clientName"
                (change)="client_details_auto_fill('clientName')"
              >
                <option [ngValue]="null">Select Client Name</option>
                <option *ngFor="let code of clientNamesList" [value]="code">
                  {{ code }}
                </option>
                <!-- Add more options as needed -->
              </select>
              <div *ngIf="myForm.get('clientName')?.invalid && myForm.get('clientName')?.touched">
                <span class="error-message">Client Name is required</span>
              </div>
            </div>
          </div>
          



          <div class="col-4">
            <!-- Client Code (Auto Fill) -->
            <div class="form-group mb-2 fl">
              <label for="clientCode" class="label-color">Client Code*</label>
              <input
                readonly
                type="text"
                class="inputOfForm"
                style="display: none"
              />
              <input
                readonly
                type="text"
                id="clientCode"
                class="inputOfForm"
                formControlName="clientCode"
                [value]="'Auto-generated Value'"
                autocomplete="nope"
              />
              <div
                *ngIf="
                  myForm.get('clientCode')?.invalid &&
                  myForm.get('clientCode')?.touched
                "
              >
                <span class="error-message">Client ID is required</span>
              </div>
            </div>
          </div>

          <div class="col-4">
            <div class="form-group mb-2 fl">
              <label for="client_location" class="label-color"
                >Client Location*</label
              >
              <input
                readonly
                type="text"
                class="inputOfForm"
                id="client_location"
                formControlName="client_location"
                [value]="'Auto-filled Location'"
                autocomplete="nope"
              />
              <div
                *ngIf="
                  myForm.get('client_location')?.invalid &&
                  myForm.get('client_location')?.touched
                "
              >
                <span class="error-message">Client Location is required</span>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-4">
            <!-- Route Name -->
            <div class="form-group mb-2 fl">
              <label for="route_name" class="label-color">Route Name*</label>
              <input
                type="text"
                [readonly]="isEditMode"
                class="inputOfForm"
                id="route_name"
                formControlName="route_name"
                autocomplete="off"
              />
              <div
                *ngIf="
                  myForm.get('route_name')?.invalid &&
                  myForm.get('route_name')?.touched
                "
              >
                <span
                  class="error-message"
                  *ngIf="myForm.get('route_name')?.errors?.['required']"
                  >Route Name is required</span
                >
              </div>
            </div>
          </div>

          <div class="col-4">
            <!-- Shift Type -->
            <div class="form-group mb-2 fl">
              <label for="shift_type" class="label-color">Shifts*</label>
              <select
                id="shift_type"
                formControlName="shift_type"
                class="inputOfForm"
              >
                <option value="Gen">Gen</option>
                <option value="Shift A">Shift A</option>
                <option value="Shift B">Shift B</option>
                <option value="Shift C">Shift C</option>
                <option value="Shift D">Shift D</option>
              </select>
              <div
                *ngIf="
                  myForm.get('shift_type')?.invalid &&
                  myForm.get('shift_type')?.touched
                "
              >
                <span
                  class="error-message"
                  *ngIf="myForm.get('shift_type')?.errors?.['required']"
                  >Shift is required</span
                >
              </div>
            </div>
          </div>

          <div class="col-4">
            <!-- One Way Kilometers -->
            <div class="form-group mb-2 fl">
              <label for="one_way_kms" class="label-color"
                >One Way Kilometers*</label
              >
              <input
                type="number"
                min="0"
                class="inputOfForm"
                id="one_way_kms"
                formControlName="one_way_kms"
                autocomplete="nope"
              />
              <div
                *ngIf="
                  myForm.get('one_way_kms')?.invalid &&
                  myForm.get('one_way_kms')?.touched
                "
              >
                <span
                  class="error-message"
                  *ngIf="myForm.get('one_way_kms')?.errors?.['required']"
                  >One Way Kilometers is required</span
                >
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-4">
            <!-- Empty Kilometers -->
            <div class="form-group mb-2 fl">
              <label for="empty_kms" class="label-color"
                >Empty Kilometers*</label
              >
              <input
                type="number"
                min="0"
                class="inputOfForm"
                id="empty_kms"
                formControlName="empty_kms"
                autocomplete="nope"
              />
              <div
                *ngIf="
                  myForm.get('empty_kms')?.invalid &&
                  myForm.get('empty_kms')?.touched
                "
              >
                <span
                  class="error-message"
                  *ngIf="myForm.get('empty_kms')?.errors?.['required']"
                  >Empty Kilometers is required</span
                >
              </div>
            </div>
          </div>

          <div class="col-4">
            <!-- Working Days -->
            <div class="form-group mb-2 fl">
              <label for="wor_days" class="label-color">Working Days*</label>
              <input
                readonly
                type="text"
                class="inputOfForm"
                id="wor_days"
                formControlName="wor_days"
                autocomplete="nope"
              />
              <!-- Implement auto-fill functionality here -->
            </div>
          </div>

          <div class="col-4">
            <!-- Parking Kilometers -->
            <div class="form-group mb-2 fl">
              <label for="park_kms" class="label-color"
                >Parking Kilometers*</label
              >
              <input
                type="number"
                min="0"
                class="inputOfForm"
                id="park_kms"
                formControlName="park_kms"
                autocomplete="nope"
              />
              <div
                *ngIf="
                  myForm.get('park_kms')?.invalid &&
                  myForm.get('park_kms')?.touched
                "
              >
                <span
                  class="error-message"
                  *ngIf="myForm.get('park_kms')?.errors?.['required']"
                  >Parking Kilometers is required</span
                >
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-4">
            <!-- Total Kilometers -->
            <div class="form-group mb-2 fl">
              <label for="tot_kms" class="label-color">Total Kilometers*</label>
              <input
                type="number"
                readonly
                class="inputOfForm"
                id="tot_kms"
                formControlName="tot_kms"
                [value]="calculateTotalKms()"
                autocomplete="nope"
              />
              <div
                *ngIf="
                  myForm.get('tot_kms')?.invalid &&
                  myForm.get('tot_kms')?.touched
                "
              >
                <span
                  class="error-message"
                  *ngIf="myForm.get('tot_kms')?.errors?.['required']"
                  >Total Kilometers is required</span
                >
              </div>
            </div>
          </div>

          <div class="col-4">
            <!-- Parking Location -->
            <div class="form-group mb-2 fl">
              <label for="park_loc" class="label-color"
                >Parking Location*</label
              >
              <input
                type="text"
                class="inputOfForm"
                id="park_loc"
                formControlName="park_loc"
                autocomplete="off"
              />
              <div
                *ngIf="
                  myForm.get('park_loc')?.invalid &&
                  myForm.get('park_loc')?.touched
                "
              >
                <span
                  class="error-message"
                  *ngIf="myForm.get('park_loc')?.errors?.['required']"
                  >Parking Location is required</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="!showModal" class="margin-left mb-5" style="right: 0">
        <div>
          <button
            class="btn m-6 clrbttn"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
            (click)="clearMessage()"
          >
            Clear
          </button>
        </div>
        <div>
          <button class="btn svbtn m-6" type="submit">
            {{ isEditMode ? "Update" : "Save" }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<!-- Modal -->
<div
  class="modal fade"
  id="staticBackdrop"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog top-40 modl-clear-pop">
    <div class="modal-content left-20">
      <div class="modal-header">
        <h5 class="modal-title fs-5" id="staticBackdropLabel">
          {{ this.modalMessage }}
        </h5>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary okaybtn"
          data-bs-dismiss="modal"
        >
          No
        </button>
        <button
          type="button"
          class="btn btn-primary okaybtn"
          data-bs-dismiss="modal"
          (click)="clearForm()"
        >
          Yes
        </button>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="!addcom && !isEditMode && !bulkFileUpload"
  class="card"
  style="
    box-shadow: -8px 0 8px rgba(0, 0, 0, 0.1), 8px 0 8px rgba(0, 0, 0, 0.1),
      0 10px 20px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  "
>
  <div class="card-header flex-between" style="border-radius: 10px">
    <h3>Client Routes</h3>
    <div class="flex-start">
      <button class="btn btn-light" (click)="refershFunction()">
        <i class="fa-solid fa-arrows-rotate"></i>
      </button>
      <!-- <div class="d-flex search-hold">
        <i class="fa-solid fa-magnifying-glass"></i>
        <input
          type="text"
          placeholder="Search"
          name=""
          (keyup)="queryResult($event)"
          class="form-control search"
        />
      </div> -->
      <div class="d-flex search-hold">
        <i class="fa-solid fa-magnifying-glass navy-icon"></i>
        <input
          type="text"
          placeholder="Search"
          name=""
          (keyup)="queryResult($event)"
          class="form-control search"
        />
      </div>
      <div class="options-container">
        <!-- <i class="fa-solid fa-ellipsis-vertical options-btn"(click)="toggleOptionsExport()"></i> -->
        <button
          *ngIf="access === 'readandwrite'"
          class="blkbtn"
          type="button"
          (click)="toggleOptionsExport()"
        >
          Bulk Upload
        </button>
        <!-- <div class="options-menu" *ngIf="expandedButton" > -->
        <div class="options-menu" [ngClass]="{ expanded: expandedButton }">
          <button class="opt-btn" (click)="get_excel_url()">
            <i class="fa-solid fa-download"></i>&nbsp; Sample File
          </button>
          <button class="opt-btn" (click)="openFileExplorer()">
            <i class="fa-solid fa-upload"></i>&nbsp; Upload File
          </button>
          <input
            type="file"
            (change)="fileSelected($event)"
            #fileInput
            style="display: none"
            accept=".xlsx, .csv, .pdf"
          />
        </div>
      </div>
      <button
        *ngIf="access === 'readandwrite'"
        class="btn btn-success-soft svbtn"
        (click)="addcom = true"
      >
        <i class="fa-solid fa-plus"></i> New
      </button>
      <button class="btn export-btn" (click)="exportEach(exportVar)">
        Export
      </button>
    </div>
  </div>
  <!-- Your existing code -->
  <div class="card-body">
    <div class="overflow-auto">
      <table class="table table-docs table-hover table-striped">
        <!-- Table headers -->
        <thead>
          <tr>
            <th>S.N.</th>
            <th *ngFor="let h of headers">
              {{ h.name }}
              <span
                *ngIf="h.sort && h.sort.sort"
                (click)="notifier.sortTable(h, filteredData)"
              >
                <i
                  class="fa fa-sort-amount-desc"
                  aria-hidden="true"
                  *ngIf="h.sort.direction == 'Des'"
                ></i>
                <i
                  class="fa fa-sort-amount-asc"
                  aria-hidden="true"
                  *ngIf="h.sort.direction == 'Asc'"
                ></i>
              </span>

              <span *ngIf="h.filter" class="inputseach">
                <input
                  autocomplete="off"
                  type="text"
                  [(ngModel)]="h.filterText"
                />
                <i class="fa fa-search" aria-hidden="true"></i>
              </span>
            </th>
            <th colspan="2" *ngIf="access === 'readandwrite'">Actions</th>
          </tr>
        </thead>
        <tbody
          [ngStyle]="{
            display: filteredData.length > 0 ? 'table-row-group' : 'none'
          }"
        >
          <ng-container
            *ngFor="
              let i of filteredData
                | paginate
                  : {
                      itemsPerPage: pagination['perPage'],
                      currentPage: pagination['page'],
                      totalItems: filteredData.length
                    };
              let j = index
            "
          >
            <tr *ngIf="notifier.filterTableColums(headers, i)">
              <td>
                {{ (pagination["page"] - 1) * pagination["perPage"] + j + 1 }}
              </td>
              <td *ngFor="let header of headers">
                <span
                  *ngIf="header.name === 'Client Name'"
                  class="record-click"
                  (click)="openModal(i)"
                >
                  {{
                    header.id.includes("client_routes")
                      ? i.client_routes[header.id.split(".")[1]]
                      : i[header.id]
                  }}
                </span>
                <span *ngIf="header.name !== 'Client Name'">
                  {{
                    header.id.includes("client_routes")
                      ? i.client_routes[header.id.split(".")[1]]
                      : i[header.id]
                  }}
                </span>
              </td>
              <td *ngIf="access === 'readandwrite'" class="text-center">
                <i
                  class="fas fa-edit edit-icon"
                  (click)="editClientroute(i)"
                ></i>
              </td>
            </tr>
          </ng-container>
        </tbody>

        <tbody
          [ngStyle]="{
            display: filteredData.length == 0 ? 'table-row-group' : 'none'
          }"
        >
          <tr>
            <td colspan="12" class="text-center">
              <div class="no_data text-center">No Client Routes Found</div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="paginator">
        <div class="flex-start">
          <span class="no-wrap">Per Page</span>
          <select
            class="form-select"
            [(ngModel)]="pagination['perPage']"
            (change)="pagination['page'] = 1"
          >
            <option value="{{ page }}" *ngFor="let page of pages">
              {{ page }}
            </option>
          </select>
        </div>
        <pagination-controls
          class="pagination"
          previousLabel="Prev"
          nextLabel="Next"
          (pageChange)="pagination['page'] = $event"
        >
        </pagination-controls>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="bulkFileUpload && !isEditMode"
  class="card"
  style="
    box-shadow: -8px 0 8px rgba(0, 0, 0, 0.1), 8px 0 8px rgba(0, 0, 0, 0.1),
      0 10px 20px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  "
>
  <div class="card-header flex-between" style="border-radius: 10px">
    <h3>Bulk Upload Client Routes</h3>
    <div class="flex-start">
      <button class="btn btn-light" (click)="gotToHomePage()">
        <i class="fa-solid"></i>Home Page
      </button>
      <button class="btn svbtn" (click)="onSubmitAllDataBulkUp()">
        <i class="fa-solid fa-plus"></i> Submit All
      </button>
    </div>
  </div>
  <!-- Your existing code -->
  <div class="card-body">
    <div class="overflow-auto">
      <table class="table table-docs table-hover table-striped">
        <!-- Table headers -->
        <thead>
          <tr>
            <th>S.N.</th>
            <th *ngFor="let h of headers">
              {{ h.name }}
              <span
                *ngIf="h.sort && h.sort.sort"
                (click)="notifier.sortTable(h, contentList)"
              >
                <i
                  class="fa fa-sort-amount-desc"
                  aria-hidden="true"
                  *ngIf="h.sort.direction == 'Des'"
                ></i>
                <i
                  class="fa fa-sort-amount-asc"
                  aria-hidden="true"
                  *ngIf="h.sort.direction == 'Asc'"
                ></i>
              </span>

              <span *ngIf="h.filter" class="inputseach">
                <input
                  autocomplete="off"
                  type="text"
                  [(ngModel)]="h.filterText"
                />
                <i class="fa fa-search" aria-hidden="true"></i>
              </span>
            </th>
            <th colspan="2">Actions</th>
          </tr>
        </thead>
        <tbody
          [ngStyle]="{
            display: contentList.length > 0 ? 'table-row-group' : 'none'
          }"
        >
          <ng-container
            *ngFor="
              let i of contentList
                | paginate
                  : {
                      itemsPerPage: pagination['perPage'],
                      currentPage: pagination['page'],
                      totalItems: contentList.length
                    };
              let j = index
            "
          >
            <tr *ngIf="notifier.filterTableColums(headers2, i)">
              <td>
                {{ (pagination["page"] - 1) * pagination["perPage"] + j + 1 }}
              </td>
              <td *ngFor="let header of headers2">
                {{
                  header.id.includes("client_routes")
                    ? i.client_routes[header.id.split(".")[1]]
                    : i[header.id]
                }}
              </td>
              <td>
                <i
                  class="fas fa-edit edit-icon"
                  (click)="editClientRouteForBulkUpload(i)"
                ></i>
              </td>
            </tr>
          </ng-container>
        </tbody>
        <tbody
          [ngStyle]="{
            display: contentList.length == 0 ? 'table-row-group' : 'none'
          }"
        >
          <tr>
            <td [attr.colspan]="headers.length + 2" class="text-center">
              <div class="no_data text-center">No Client Routes Found</div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="paginator">
        <div class="flex-start">
          <span class="no-wrap">Per Page</span>
          <select
            class="form-select"
            [(ngModel)]="pagination['perPage']"
            (change)="pagination['page'] = 1"
          >
            <option value="{{ page }}" *ngFor="let page of pages">
              {{ page }}
            </option>
          </select>
        </div>
        <pagination-controls
          class="pagination"
          previousLabel="Prev"
          nextLabel="Next"
          (pageChange)="pagination['page'] = $event"
        >
        </pagination-controls>
      </div>
    </div>
  </div>
</div>

<app-submit-popup
  *ngIf="popUp"
  (hideModa)="hidepopup($event)"
  [header]="popup_header"
  [subtitle]="popup_subtitle"
></app-submit-popup>
