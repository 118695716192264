import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { APIService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';

declare let $: any;

@Component({
  selector: 'app-race-overview',
  templateUrl: './race-overview.component.html',
  styleUrls: ['./race-overview.component.css'],
})
export class RaceOverviewComponent implements OnInit, AfterViewInit {
  upcoming: any = [];
  previous: any = {
    date: '',
    events: [],
  };

  config: any;

  constructor(
    private apiService: APIService,
    private notifier: NotifierService,
    private router: ActivatedRoute
  ) {
    this.config = this.router.snapshot.data['config'];
  }

  async ngOnInit(): Promise<void> {
    this.notifier.loading(true);
    for (let index = 0; index < 3; index++) {
      let date: string = moment().add(index, 'days').format('YYYY-MM-DD');
      let dt: any = {
        date: date,
        events: await this.loadEventsBasedOnDates(date),
      };
      this.upcoming.push(dt);
    }

    let date: string = moment().subtract(1, 'days').format('YYYY-MM-DD');
    this.previous['date'] = date;
    this.previous['events'] = await this.loadEventsBasedOnDates(date);
    this.notifier.loading(false);
  }

  async ngAfterViewInit(): Promise<void> {
    $('.datepicker').val(this.previous['date']);
    $('.datepicker')
      .datepicker({
        autoclose: true,
        // minViewMode: 1,
        format: 'yyyy-mm-dd',
        endDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
        orientation: 'bottom auto',
      })
      .on('changeDate', async (selected: any) => {
        this.notifier.loading(true);
        this.previous['date'] = $('.datepicker').val();
        this.previous['events'] = await this.loadEventsBasedOnDates(
          $('.datepicker').val()
        );
        this.notifier.loading(false);
      });
  }

  async loadEventsBasedOnDates(date: string): Promise<any> {
    return new Promise(async (resolve: any) => {
      let apiURL: string = `${this.config.raceAPI}/event-list?formatted_date=${date}`;

      let result: any = await this.apiService.getDataPromis(apiURL, {}, {});

      if (result.eventNames && result.eventNames.length > 0) {
        resolve(
          result.eventNames.map((event: any) => {
            event['dateStr'] = moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY');
            event['name'] = event['name'].split('--')[0];
            return event;
          })
        );
      } else {
        resolve([]);
      }
    });
  }
}
