import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { WhitelabelService } from './whitelabel.service';
@Injectable({ providedIn: 'root' })
export class DataResolver implements Resolve<any> {
  constructor(private whitelabelService: WhitelabelService) {}
  resolve(): Observable<any> {
    return this.whitelabelService.loadSettings();
  }
}
