import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APIService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { environment } from 'src/environments/environment';

declare let $: any;

@Component({
  selector: 'invite-client',
  templateUrl: './invite-client.component.html',
  styleUrls: ['./invite-client.component.css'],
})
export class InviteClientComponent implements OnInit, OnDestroy {
  @Input('userDetails') userDetails: any;
  @Input('action') action: any;
  @Input('type') type: any;
  userId = localStorage.getItem('eId');
  role: string | null = localStorage.getItem('role');
  urlPrefix: string | null = null;
  polices: any = [];
  policyName: string = '';
  policyType: string = 'predefined';
  viewPolicy: any = null;

  @Output() hideModel = new EventEmitter<boolean>();
  config: any;

  constructor(
    private apiService: APIService,
    private notifier: NotifierService,
    private route: ActivatedRoute
  ) {
    this.config = this.route.snapshot.data['config'];
  }

  ngOnInit(): void {
    if (this.role == 'Admin') {
      this.urlPrefix = 'admin';
    }
    if (this.role == 'Client') {
      this.urlPrefix = 'client';
    }
    if (this.role == 'Superadmin') {
      this.urlPrefix = 'sa';
    }
    if (this.userDetails.policyEnabled) {
      this.policyType = 'predefined';
      this.policyName = this.userDetails.policyName;
      setTimeout(() => {
        $('#policyList').selectpicker('refresh');
      }, 100);
    } 
    // else {
    //   this.policyType = 'manual';
    // }
    this.load();
  }

  loading: boolean = false;
  services: any = [];
  twowayfactor: boolean = true;

  selectPolicy() {
    this.viewPolicy = this.polices.find((policy: any) => {
      return policy['policyName'] == this.userDetails.policyName;
    });
  }

  async load() {
    //  else {
    //   this.loadAdminAccountGroups();
    //   return;
    // }
    // let accounts: any = localStorage.getItem('accountIdData');
    // if (accounts) {
    //   let accountsObj: any = JSON.parse(accounts);
    //   if (accountsObj['list'].length > 0) {
    //     this.accounts = accountsObj['list'];
    //     setTimeout(() => {
    //       $('#instancesList').selectpicker('refresh');
    //       $('#instancesList').val(this.userDetails['accountsList']);
    //       $('#instancesList').selectpicker('refresh');
    //       this.fetchAccountGroups();
    //     }, 100);
    //   }
    // }
    // if (this.urlPrefix == 'client') {
    //   if (this.action == 'update') {
    //     this.notifier.loading(true);
    //   }
    // }
    // this.notifier.loading(true);
    // let data = {
    //   emailId: this.userId,
    // };
    // let header = {
    //   Authorization: localStorage.getItem("t"),
    // };
    // let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/listawsaccounts`;
    // let result = await this.apiService.postDataPromis(apiURL, data, header);
    // if (result.s == "1" || result.status == "1") {
    //   this.services = result.menuList;
    //   $("#serviceList").selectpicker("refresh");
    //   setTimeout(() => {
    //     $("#serviceList").val(this.userDetails.menuList);
    //     $("#serviceList").selectpicker("refresh");
    //   }, 100);
    // } else {
    //   this.notifier.alert("Info", "", result.msg, "info", 5000);
    // }
    // this.notifier.loading(false);
    this.notifier.loading(true);
    let promises: Promise<any>[] = [];
    promises.push(this.fetchMenuList());
    promises.push(this.fetchPolicyDetails());
    Promise.all(promises).then((values: any) => {
      this.notifier.loading(false);
    });
  }

  async fetchPolicyDetails() {
    return new Promise(async (resolve: any) => {
      let data: any = {
        a: 'fetchAll',
        clientId: this.userId,
        userType: this.urlPrefix,
      };
      let header: any = {
        Authorization: localStorage.getItem('t'),
      };

      let apiURL: string | null = null;

      if (this.urlPrefix == 'sa') {
        header['Authorization'] = localStorage.getItem('sessiontoken');
        header['X-Api-Key'] = localStorage.getItem('clientid');
        apiURL = `${this.config.apiURL}/${this.urlPrefix}/policies`;
      } else {
        apiURL = `${this.config.apiURL}/${this.urlPrefix}/Settings/policies`;
      }

      let result = await this.apiService.postDataPromis(apiURL, data, header);

      if (result.s == '1' || result.status == '1') {
        this.polices = result.policies;
        setTimeout(() => {
          $('#policyList').selectpicker('refresh');
          $('#policyList').selectpicker('val', this.userDetails.policyName);
        }, 100);
      } else {
        this.notifier.alert('Info', '', result.error, 'info', 5000);
      }

      resolve(true);
    });
  }

  async fetchMenuList() {
    return new Promise(async (resolve: any) => {
      let data: any = {
        a: 'fetchmenus',
      };

      let header = {
        Authorization: localStorage.getItem('sessiontoken'),
        'X-Api-Key': localStorage.getItem('clientid'),
      };
      let apiURL: string = `${this.config.apiURL}/${this.urlPrefix}/clients`;

      let result = await this.apiService.postDataPromis(apiURL, data, header);

      if (result.status == '1' || result.s == '1') {
        this.services = Object.keys(result.mList || {}).map((menu: any) => {
          if (result.mList[menu].length == 1 && result.mList[menu][0] == menu) {
            result.mList[menu] = [];
          }
          return {
            label: menu,
            selected: false,
            expanded: false,
            submenus: result.mList[menu].map((menu_: any) => {
              return {
                label: menu_,
                selected: false,
              };
            }),
          };
        });
        this.services = this.services
          .sort((a: any, b: any) => {
            let a_str = Number(a['label'].split('_')[1]);
            let b_str = Number(b['label'].split('_')[1]);
            return a_str - b_str;
          })
          .map((menu: any) => {
            menu['label'] = menu['label'].split('_')[0];
            return menu;
          });
      }

      resolve(true);
    });
  }

  errors: any = {
    userName: {
      policy: {
        required: true,
        type: 'text',
      },
      error: false,
      msg: 'Name is Mandatory Field',
    },
    policy: {
      policy: {
        required: false,
      },
      error: false,
      msg: 'Select Policy',
    },
    mobile: {
      policy: {
        type: 'text',
        required: true,
      },
      error: false,
      msg: 'Phone Number is Mandatory Field',
    },
    company: {
      policy: {
        type: 'text',
        required: true,
      },
      error: false,
      msg: 'Phone Number is Mandatory Field',
    },
    email: {
      policy: {
        required: true,
        type: 'text',
      },
      error: false,
      msg: 'Email ID is Mandatory Field',
    },
  };

  phoneNumberValidate(e: any) {
    if (
      [46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+C
      (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+V
      // (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+X
      (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)
    ) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if (
      (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
      (e.keyCode < 96 || e.keyCode > 105)
    ) {
      e.preventDefault();
    }
  }

  validation() {
    let check = true;
    Object.keys(this.errors).forEach((error: any) => {
      if (this.errors[error]['policy']['required']) {
        if (
          this.errors[error]['policy']['type'] == 'text' &&
          (!this.userDetails[error] ||
            this.userDetails[error].toString().trim() == '')
        ) {
          check = false;
          this.errors[error]['error'] = true;
        } else {
          this.errors[error]['error'] = false;
        }
      } else {
        this.errors[error]['error'] = false;
      }
    });
    if (
      this.policyType == 'predefined' &&
      (!this.userDetails['policyName'] ||
        this.userDetails['policyName'].trim() == '')
    ) {
      this.errors['policy']['error'] = true;
      check = false;
    } else {
      this.errors['policy']['error'] = false;
    }
    return check;
  }

  getSelectedMenusLength(menu: any) {
    return menu['submenus']
      ? menu['submenus'].filter((menu_: any) => {
          return menu_['selected'];
        }).length
      : 0;
  }

  async updateEntry() {
    if (!this.validation()) {
      return;
    }
    if (this.action == 'local') {
      this.close(true);
      return;
    }

    let menuList: any = {};
    this.services.forEach((menu: any) => {
      if (menu['selected'] || this.getSelectedMenusLength(menu) > 0) {
        menuList[menu['label']] = [];
        menuList[menu['label']] = menu['submenus']
          ? menu['submenus']
              .filter((menu_: any) => {
                return menu_['selected'] || menu['selected'];
              })
              .map((menu_: any) => {
                return menu_['label'];
              })
          : [];
        if (menu['submenus'].length == 0) {
          menuList[menu['label']] = [menu['label']];
        }
      }
    });

    if(this.policyType == 'manual' && Object.keys(menuList).length == 0) {
      this.notifier.alert('Info', '', `Atleast one menu is required to ${this.action} a user`, 'info', 5000);
      return;
    }

    this.notifier.loading(true);

    // console.log("action name",this.action);
    // console.log("type value", this.type);
    let action: string = '';

    if (this.action == 'add') {
      if (this.type == 'admins') {
        action = 'inviteAdmin';
      }
      else {
        action = 'inviteUser';
      }
    }

    if (this.action == 'update') {
      if (this.type == 'admins') {
        action = 'updateAdmin';
      }
      else{
        action = 'updateUser';
      }
    }
  

    let data: any = {
      a: action,
      clientId: this.userDetails['email'].trim(),
      email: this.userDetails['email'].trim(),
      phoneNumber: this.userDetails['mobile'].toString().trim(),
      cmpName: this.userDetails['company'].trim(),
      requester: this.userId,
      ut: 'admin',
      clientName: this.userDetails['userName'].trim(),
      policyName:
        this.policyType == 'predefined'
          ? this.userDetails.policyName
          : undefined,
      updatePolicy:
        this.action == 'update' && this.policyType == 'manual' ? false : true,
      mlist: this.policyType == 'manual' ? menuList : undefined,
      otpReq: this.type == 'clients' ? this.userDetails['otpReq'] : undefined,
    };

    let header = {
      Authorization: localStorage.getItem('sessiontoken'),
      'X-Api-Key': localStorage.getItem('clientid'),
    };
    let apiURL: string = `${this.config.apiURL}/${this.urlPrefix}/clients`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.notifier.alert(
        'Success',
        '',
        result.msg || result.m,
        'success',
        5000
      );
      this.close(true);
    } else {
      this.notifier.alert(
        'Error',
        '',
        result.m || result.error || result.msg,
        'error',
        5000
      );
    }
    this.notifier.loading(false);
  }

  destroyed: boolean = false;
  ngOnDestroy(): void {
    this.destroyed = true;
  }

  close(sendData: boolean = false) {
    if (sendData) {
      this.hideModel.emit(true);
    } else {
      this.hideModel.emit(false);
    }
  }
}
