import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-submit-popup',
  templateUrl: './submit-popup.component.html',
  styleUrls: ['./submit-popup.component.css']
})
export class SubmitPopupComponent {
  showModal: boolean = false;
  @Output() hideModa = new EventEmitter<any>();
  @Input() header:any='';
  @Input() subtitle:any='';
  


  no(){
    this.hideModa.emit({status:0});
  }


  hideModal() {
    this.showModal = false;
  }

}
