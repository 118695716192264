import { Component, EventEmitter, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APIService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { environment } from 'src/environments/environment';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ElementRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { from } from 'rxjs';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
} from '@angular/common/http';
import { FileUploadService } from 'src/app/services/fileupload.service';
// import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import * as XLSX from 'xlsx';
declare let Jhxlsx: any;
declare let $: any;

@Component({
  selector: 'app-ad-client',
  templateUrl: './ad-client.component.html',
  styleUrls: ['./ad-client.component.css'],
})
export class AdClientComponent {
  @ViewChild('fileInput', { read: ElementRef }) fileInput!: ElementRef;
  myForm: any;
  minEndDate: string = '';
  documentInputErrors: { [key: string]: string | null } = {};
  isOpen: boolean = false;
  isSubmitted: boolean = false;
  urlPrefix: string | null = null;
  config: any;
  addcom: boolean = false;
  result: any;
  clientVehicleResult: any;
  selectedCompany: any;
  showModal: boolean = false;
  showRole: boolean = false;
  role: any = {};
  isEditMode: boolean = false;
  loading: boolean = false;
  companyCodes: any;
  matchingUrls: any;
  fetchUrls: any;
  fileNames: any = [];
  orderedUrls: any[] = [];
  sendUrls: any[] = [];
  updated: any = {};
  submitted: boolean = false;
  agreementTouched: boolean = false;
  pagination: any = { perPage: 10, page: 1 };
  pagination2: any = { perPage: 10, page: 1 };
  pages: any = [10, 50, 100, 200];
  pageChange: EventEmitter<number> | undefined;
  expandedButton: boolean = false;
  filteredData: any = [];
  bulkFileUpload: boolean = false;
  contentList: any[] = [];
  selectedTab: any = 'client-details';
  @ViewChild('agreementIn') agreementIn: any;
  updateForm: boolean = false;
  progressBar: boolean = false;
  progressValue: any = 0;
  nameOfFile: any;
  selectedArrayForEdit: any = [];
  index: any = 0;
  selectedClientFilePath: any = ''
  uploadedVar: boolean = false;
  popUp: boolean = false;
  popup_header: any = '';
  popup_subtitle: any = '';
  exportVar: any = [];
  clientVehicle: boolean = false;
  clientName: any = ''
  modalMessage: string = '';
  tabs: string[] = [
    'client-details',
    'administration-details',
    'operations-details',
    'hr-details',
    'account-details'
  ];
  selectedTableTab: string = 'Active Clients';
  // readandwrite, readonly
  access = localStorage.getItem('acT');
  selectedIndexForRole: any = null;  
  selectedHeaderTab: any = null;

  constructor(
    private fb: FormBuilder,
    private apiService: APIService,
    private uploadFile: FileUploadService,
    public notifier: NotifierService,
    private route: ActivatedRoute
  ) {
    this.config = this.route.snapshot.data['config'];
  }

  headers: any = [
    {
      "id": "client_details.client_name",
      "name": "Client Name",
      "sort": { "sort": true, "direction": "Asc" }
    },
    {
      "id": "client_contact_details[1].contact_name",
      "name": "Administration",
      "sort": { "sort": true, "direction": "Asc" },
      click2: (client: any, index: any, index2: any) => {
        this.selectedIndexForRole = index;
        this.selectedHeaderTab = index2;
        return this.openRole(client, 1);
      },
    },
    {
      "id": "client_contact_details[2].contact_name",
      "name": "HR",
      "sort": { "sort": true, "direction": "Asc" },
      click3: (client: any, index: any, index2: any) => {
        this.selectedIndexForRole = index;
        this.selectedHeaderTab = index2;
        return this.openRole(client, 2);
      }
    },
    {
      "id": "client_contact_details[0].contact_name",
      "name": "Accounts & Finance",
      "sort": { "sort": true, "direction": "Asc" },
      click4: (client: any, index: any, index2: any) => {
        this.selectedIndexForRole = index;
        this.selectedHeaderTab = index2;
        return this.openRole(client, 0);
      },
    },
    {
      "id": "client_details.company_address",
      "name": "Company/Unit Address",
      "sort": { "sort": true, "direction": "Asc" }
    },
    {
      "id": "client_details.fuel_supplier",
      "name": "Fuel Station",
      "sort": { "sort": true, "direction": "Asc" }
    },
    {
      "id": "client_details.no_routes",
      "name": "No of Routes",
      "sort": { "sort": true, "direction": "Asc" }
    },
    {
      "id": "client_details.staff_details",
      "name": "All Staff Details",
      "sort": { "sort": true, "direction": "Asc" }
    },
    {
      id: 'client_details.no_buses',
      name: 'Total Count of Buses',
      // click: true,
      click: (client: any) => {
        return this.showVehicles(client);
      },
      // filter: true
      sort: { sort: true, direction: 'Asc' },
    },
  ];

  headers2: any = [
    {
      id: 'Client Name',
      name: 'Client Name',
      // filter: true
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'Administration Name',
      name: 'Administration',
      // filter: true,
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'HR Name',
      name: 'HR',
      // filter: true,
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'AccountsAndFinance Name',
      name: 'Accounts & Finance',
      // filter: true,
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'Company Address',
      name: 'Company/Unit Address',
      // filter: true,
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'Fuel Station',
      name: 'FUEL Station',
      // filter: true
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'Number of Routes',
      name: 'NO OF ROUTES',
      // filter: true
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'All Staff Details',
      name: 'ALL STAFF DETAILS',
      // filter: true
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'Total Number of Buses',
      name: 'Total Count Of Buses',
      // filter: true
      sort: { sort: true, direction: 'Asc' },
    },
  ];

  headers4: any = [
    {
      id: 'registration_number',
      name: 'Registration Number',
      // filter: true,
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'vehicles_details.vehicle_owner',
      name: 'Client Name',
      // filter: true,
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'vehicles_details.vehicle_manufacturer',
      name: 'Manufacturer',
      // filter: true,
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'vehicles_details.vehicle_manufacturer_year',
      name: 'Model Year',
      // filter: true,
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'vehicles_details.seating_capacity',
      name: 'Seating Capacity',
      // filter: true,
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'vehicles_details.vehicle_mode',
      name: 'AC/Non AC',
      // filter: true,
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'vehicles_details.vehicle_type',
      name: 'Vehicle Type',
      // filter: true,vehicle_type
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'vehicles_details.seating_type',
      name: 'Seating Type',
      // filter: true,vehicle_type
      sort: { sort: true, direction: 'Asc' },
    }];

  async showVehicles(client: any){
    if (client.client_details.no_buses){
    this.pagination['page'] = 1
    console.log('clientTTTT', client);
    console.log("this.addcom, this.isEditMode, this.bulkFileUpload")
    console.log(this.addcom, this.isEditMode, this.bulkFileUpload)
    this.clientName = client.client_details.client_name
    await this.clientVehicleFetch(client.client_id)
    this.clientVehicle = true
    }
    
  }

  resolvePath(obj: any, path: string): any {
    return path.split('.').reduce((acc, part) => {
      if (acc && typeof acc === 'object') {
        const arrayMatch = part.match(/(\w+)\[(\d+)\]/);
        if (arrayMatch) {
          return acc[arrayMatch[1]][arrayMatch[2]];
        }
        return acc[part];
      }
      return undefined;
    }, obj);
  }

  selectTab(tab: string): void {
    this.selectedTableTab = tab;
    this.pagination = { perPage: 10, page: 1 };
    this.pagination2 = { perPage: 10, page: 1 };
    if (tab === 'Active Clients'){
      this.fetchcomp()
    }else{
      this.fetchInactiveClient()
    }
  }

  funRef: any;
  ngOnInit() {
    this.assignVehicleCountToClient()
    this.fetchcomp();
    this.company_Codes();
    this.myForm = this.fb.group({
      clientName: ['', [Validators.required, Validators.maxLength(100)]],
      clientId: ['', Validators.required],
      agreementStartDate: [''],
      agreementRenewalDate: [''],
      sttus: [''],
      companyAddress: ['', [Validators.required, Validators.maxLength(500)]],
      companyBillingAddress:[''],
      vendor_email: ['', Validators.required],
      // preff_cont: this.fb.array([], Validators.required),
      preff_cont: ['', Validators.required],
      no_of_wrkdays: [
        '',
        [Validators.required, Validators.pattern('^[0-9]{1,20}$')],
      ],
      billing_per_from: ['', Validators.required],
      billing_per_to: ['', Validators.required],
      package_det: ['kms', Validators.required],
      pay_teerms: ['Immediate', Validators.required],
      un_flmn_name: [''],
      no_of_routes: ['', Validators.required],
      // no_of_buses: [0, Validators.required],
      no_of_buses: [0, [Validators.required, Validators.pattern('^[0-9]*$')]],
      staf_det: ['', Validators.required],
      fuel_sup: [''],
      garage_det: [''],
      adm_op_Name: ['', [Validators.required, Validators.maxLength(100)]],
      adm_op_designation: [
        '',
        [Validators.required, Validators.maxLength(100)],
      ],
      adm_op_Cont_Number: [
        '',
        [Validators.required, Validators.pattern('^[0-9]{10,13}$')],
      ],
      adm_op_email: ['', [Validators.required, Validators.email]],
      op_Name: ['', [Validators.required, Validators.maxLength(100)]],
      op_designation: ['', [Validators.required, Validators.maxLength(100)]],
      op_Cont_Number: [
        '',
        [Validators.required, Validators.pattern('^[0-9]{10,13}$')],
      ],
      op_email: ['', [Validators.required, Validators.email]],
      hr_name: ['', [Validators.required, Validators.maxLength(100)]],
      hr_desg: ['', [Validators.required, Validators.maxLength(100)]],
      hr_cont: [
        '',
        [Validators.required, Validators.pattern('^[0-9]{10,13}$')],
      ],
      hr_email: ['', [Validators.required, Validators.email]],
      acc_fin_name: ['', [Validators.required, Validators.maxLength(100)]],
      acc_fin_desig: ['', [Validators.required, Validators.maxLength(100)]],
      acc_fin_cont: [
        '',
        [Validators.required, Validators.pattern('^[0-9]{10,13}$')],
      ],
      acc_fin_email: ['', [Validators.required, Validators.email]],
    });
    console.log('IDs from headers:');
    this.headers.forEach((headers: any) => {
      console.log(headers.id);
    });

    // this.addCheckboxes();
  }
  async company_Codes() {
    // this.notifier.loading(true);

    let data: any = {
      action: 'fetch_company_codes',
    };
    let header = {
      Authorization: localStorage.getItem('t'),
      // 'X-Api-Key': localStorage.getItem('clientid'),
    };

    //https://api.swayam.cloud/v3/admin/users/fetchaccountgroupsv2
    let apiURL = `${this.config.apiURL}/admin/companies`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.s == '1' || result.status == '1') {
      // console.log(result);

      this.companyCodes = result.data;
      console.log("comapny id's", this.companyCodes);

      // this.notifier.alert('Success', '', result.data, 'success', 5000);
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
    // this.notifier.loading(false);
  }

  get preffContArray() {
    return this.myForm.controls.preff_cont as FormArray;
  }

  private addCheckboxes() {
    ['whatsapp', 'email', 'office number'].forEach(() =>
      this.preffContArray.push(this.fb.control(false))
    );
  }

  updateEndDateMin(startFieldName: string, endFieldName: string): void {
    const startDate = this.myForm.get(startFieldName)?.value;
    if (startDate) {
      const endDateControl = this.myForm.get(endFieldName);
      if (endDateControl) {
        endDateControl.enable(); // Enable the end date field if it was previously disabled
        endDateControl.setValidators([Validators.min(startDate)]); // Set minimum selectable date
        endDateControl.updateValueAndValidity(); // Update validity of the end date field
      }
    }
  }

  findDeptIndex(contactList: any, deptName: string) {
    const formattedDeptName = deptName.toLowerCase().replace(/\s|_&_/g, '');

    for (let i = 0; i < contactList.length; i++) {
      const formattedListDeptName = contactList[i].dept_name
        .toLowerCase()
        .replace(/\s|_&_/g, 'and');
      console.log(formattedListDeptName, formattedDeptName);
      if (formattedListDeptName === formattedDeptName) {
        const index = i;
        return index; // Return the index if department name matches
      }
    }
    const index = -1;
    return index; // Return -1 if department name is not found
  }

  presenceList(list: string[], targetValues: string[]): boolean[] {
    const presence: boolean[] = [];

    // Iterate through the target values
    for (const value of targetValues) {
      presence.push(list.includes(value));
    }

    return presence;
  }

  async fetch(file_paths: Array<any>) {
    console.log('inside fetch file fn');
    let data: any = {
      action: 'fetch_file',
      // company_code: this.myForm.get('companyCode').value,
      file_names: file_paths,
    };
    let header = {
      Authorization: localStorage.getItem('t'),
      // 'X-Api-Key': localStorage.getItem('clientid'),
    };

    let apiURL = `${this.config.apiURL}/admin/companies`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.s == '1' || result.status == '1') {
      // console.log(result);
      this.fetchUrls = result.data;
      // this.filteredData = result.data;
      // this.mapFetchedFileNames(this.fetchUrls)
      // interface MatchingUrls {
      //     [key: string]: string;
      // }
      // const fileNames
      this.fileNames = [];
      this.fileNames = Object.keys(this.fetchUrls);

      // Now keysArray contains only the keys of this.fetchUrls
      console.log('keys: ', this.fileNames);

      // console.log("Presigned urls for files:",this.fetchUrls)
      // Assuming this.matchingUrls is declared somewhere in your code
      this.matchingUrls = {};

      for (const key in this.fetchUrls) {
        if (Object.prototype.hasOwnProperty.call(this.fetchUrls, key)) {
          if (key.includes('client_agreement_amendment')) {
            this.matchingUrls['client_agreement_amendment'] =
              this.fetchUrls[key];
          }
        }
      }

      console.log('Matching URL:', this.matchingUrls);

      const order = ['client_agreement_amendment'];

      // Create an array of objects based on the defined order
      this.orderedUrls = order.map((key) => ({
        [key]: this.matchingUrls[key],
      }));

      console.log('Ordered URLs:', this.orderedUrls);
    }

    // console.log("File URLs: ", this.fetchUrls)
    else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
  }

  async populateForm(selectedCompany: any) {
    this.notifier.loading(true);
    console.log('inside the populate form');
    console.log(selectedCompany);
    if (selectedCompany) {
      let [from, to] =
        selectedCompany.client_details.billing_period.split(' to ');
      if (selectedCompany.client_details.agreement_amendments_s3_path &&
        selectedCompany.client_details.agreement_amendments_s3_path !== 'NA'){
        this.sendUrls = [];
        this.sendUrls.push(
          selectedCompany.client_details.agreement_amendments_s3_path
        );
        this.selectedClientFilePath = selectedCompany.client_details.agreement_amendments_s3_path
        await this.fetch(this.sendUrls);
        console.log('Send urls', this.sendUrls);
      }

      const str_chkbox: string = selectedCompany.client_details.contact_method;

      if (selectedCompany.client_id){
        await this.clientVehicleFetch(selectedCompany.client_id);
      }
      let total_no_of_busses = this.clientVehicleResult.length
      console.log('total_no_of_busses',total_no_of_busses)

      console.log('#####################', selectedCompany);
      console.log(
        this.findDeptIndex(
          selectedCompany.client_contact_details,
          'Administration'
        )
      );
      console.log(
        selectedCompany.client_details.unit_billing,
        selectedCompany.client_details.garage_details
      );
      this.myForm.patchValue({
        clientName: selectedCompany.client_details.client_name,
        clientId: selectedCompany.client_id,
        agreementStartDate: selectedCompany.client_details.agreement_start_date,
        agreementRenewalDate:
          selectedCompany.client_details.agreement_renewal_date,
        sttus: selectedCompany.client_details.client_status,
        agreementAmendments: this.selectedClientFilePath,
        companyAddress: selectedCompany.client_details.company_address,
        companyBillingAddress: selectedCompany.client_details.unit_billing,
        preff_cont: str_chkbox,
        no_of_wrkdays: selectedCompany.client_details.working_days,
        billing_per_from: from,
        billing_per_to: to,
        package_det: selectedCompany.client_details.package_details,
        pay_teerms: selectedCompany.client_details.payments_terms,
        un_flmn_name:
          selectedCompany.client_details.assigned_company_manager_name,
        no_of_routes: selectedCompany.client_details.no_routes,
        no_of_buses: total_no_of_busses,
        vendor_email: selectedCompany.client_details.vendor_email,
        staf_det: selectedCompany.client_details.staff_details,
        fuel_sup: selectedCompany.client_details.fuel_supplier,
        garage_det: selectedCompany.client_details.garage_details,
        hr_name:
          selectedCompany.client_contact_details[
            this.findDeptIndex(selectedCompany.client_contact_details, 'HR')
          ].contact_name,
        hr_desg:
          selectedCompany.client_contact_details[
            this.findDeptIndex(selectedCompany.client_contact_details, 'HR')
          ].contact_designation,
        hr_cont:
          selectedCompany.client_contact_details[
            this.findDeptIndex(selectedCompany.client_contact_details, 'HR')
          ].contact_number,
        hr_email:
          selectedCompany.client_contact_details[
            this.findDeptIndex(selectedCompany.client_contact_details, 'HR')
          ].contact_email,
        adm_op_Name:
          selectedCompany.client_contact_details[
            this.findDeptIndex(
              selectedCompany.client_contact_details,
              'Administration'
            )
          ].contact_name,
        adm_op_designation:
          selectedCompany.client_contact_details[
            this.findDeptIndex(
              selectedCompany.client_contact_details,
              'Administration'
            )
          ].contact_designation,
        adm_op_Cont_Number:
          selectedCompany.client_contact_details[
            this.findDeptIndex(
              selectedCompany.client_contact_details,
              'Administration'
            )
          ].contact_number,
        adm_op_email:
          selectedCompany.client_contact_details[
            this.findDeptIndex(
              selectedCompany.client_contact_details,
              'Administration'
            )
          ].contact_email,
        acc_fin_name:
          selectedCompany.client_contact_details[
            this.findDeptIndex(
              selectedCompany.client_contact_details,
              'AccountsAndFinance'
            )
          ].contact_name,
        acc_fin_desig:
          selectedCompany.client_contact_details[
            this.findDeptIndex(
              selectedCompany.client_contact_details,
              'AccountsAndFinance'
            )
          ].contact_designation,
        acc_fin_cont:
          selectedCompany.client_contact_details[
            this.findDeptIndex(
              selectedCompany.client_contact_details,
              'AccountsAndFinance'
            )
          ].contact_number,
        acc_fin_email:
          selectedCompany.client_contact_details[
            this.findDeptIndex(
              selectedCompany.client_contact_details,
              'AccountsAndFinance'
            )
          ].contact_email,
        op_Name:
          selectedCompany.client_contact_details[
            this.findDeptIndex(
              selectedCompany.client_contact_details,
              'Operations'
            )
          ].contact_name,
        op_designation:
          selectedCompany.client_contact_details[
            this.findDeptIndex(
              selectedCompany.client_contact_details,
              'Operations'
            )
          ].contact_designation,
        op_Cont_Number:
          selectedCompany.client_contact_details[
            this.findDeptIndex(
              selectedCompany.client_contact_details,
              'Operations'
            )
          ].contact_number,
        op_email:
          selectedCompany.client_contact_details[
            this.findDeptIndex(
              selectedCompany.client_contact_details,
              'Operations'
            )
          ].contact_email,
      });
    }
    console.log('this.myForm:', this.myForm);
    this.notifier.loading(false);
  }

  clearForm(): void {
    this.myForm.reset();
    this.myForm.patchValue({
      package_det: 'kms',
      ypay_teerms: 'Immediate',
      no_of_buses: 0
    });
    this.isEditMode = false;
    this.addcom = false;
    this.submitted = false;
    this.updateForm = false;
    this.fileNames = [];
    this.updated = {};
    this.agreementTouched = false;
    this.showModal= false;
    this.enableForModal()
  }
  
  callFunction(name: any, param: any) {
    return;
  }

  toggleSidenav() {
    this.isOpen = !this.isOpen;
  }

  previewDocument(input: HTMLInputElement, doc: string) {
    const file = input.files?.[0];
    if (file) {
      const fileType = file.name.split('.').pop()?.toLowerCase() || '';
      if (fileType === 'pdf' || this.isImageFile(fileType)) {
        const reader = new FileReader();
        reader.onload = () => {
          const blob = new Blob([reader.result as ArrayBuffer], {
            type: file.type,
          });
          const url = URL.createObjectURL(blob);
          const viewer = document.createElement('iframe');
          viewer.src = url;
          viewer.style.width = '100%';
          viewer.style.height = '100%';
          viewer.style.border = 'none';
          const newWindow = window.open();
          if (newWindow) {
            newWindow.document.body.appendChild(viewer);
            newWindow.document.title = file.name;
            newWindow.focus();
          } else {
            console.log('Failed to open preview window');
          }
        };
        reader.readAsArrayBuffer(file);
      } else {
        console.log('Unsupported file type for preview');
      }
    } else {
      // If no file is uploaded, show file from URL
      if (this.matchingUrls && this.matchingUrls[doc] && this.isEditMode) {
        const url = this.matchingUrls[doc];
        const viewer = document.createElement('iframe');
        viewer.style.width = '100%';
        viewer.style.height = '100%';
        viewer.style.border = 'none';
        const newWindow = window.open();
        if (newWindow) {
          newWindow.document.title = doc;
          viewer.src = url;
          newWindow.document.body.appendChild(viewer.cloneNode(true));
          newWindow.focus();
        } else {
          console.log('Failed to open preview window');
        }
      } else {
        console.log('No file selected and no URL available for preview');
      }
    }
  }

  reset(element: any, filename: string) {
    element.value = '';
    this.updated[filename] = undefined;
  }

  fileInfoArray: {
    file_name: string;
    ext: string;
    file: File;
    fieldName: string;
  }[] = [];

  onFileSelected(fieldName: string, filename: string, event: any) {
    console.log('Field Name:', fieldName);
    console.log('Event:', event);
    const file = event.target.files?.[0];
    console.log('Selected File:', file);

    console.log('this.isEditMode:',this.isEditMode)
    console.log('&&&&&&&&&&updated', this.updated)
    this.progressBar = true;
    this.progressValue=0;
    console.log('this.updated', this.updated);
    console.log('@@@@@@@@@@@@@@@@this.fileNames', this.fileNames);
    

    if (file) {
      // Get the file name without extension
      const fileNameWithoutExtension = file.name
        .split('.')
        .slice(0, -1)
        .join('.');
      console.log('File Name:', fileNameWithoutExtension);

      // Get the file extension
      const fileExtension = file.name.split('.').pop()?.toLowerCase() || '';
      console.log('File Extension:', fileExtension);

      if (file.size > 20 * 1024 * 1024) {
        this.documentInputErrors[fieldName] =
          'File size exceeds the limit (20MB)';
      } else {
        if (
          !this.isImageFile(fileExtension) &&
          file.type !== 'application/pdf'
        ) {
          this.documentInputErrors[fieldName] =
            'Unsupported file type. Only PDF and images are allowed.';
        } else {
          
          this.documentInputErrors[fieldName] = null; // Clear error if file is valid
          this.updated[filename] = true;
          console.log('@@@@@@@@@@@this.updated', this.updated)          // Check if an item with the same fieldName already exists
          const existingIndex = this.fileInfoArray.findIndex(
            (item) => item.fieldName === fieldName
          );
          if (existingIndex !== -1) {
            // Replace the existing item with the new one
            this.fileInfoArray[existingIndex] = {
              file_name: filename,
              ext: fileExtension,
              file: file,
              fieldName: fieldName,
            };
          } else {
            // Push the new item into the fileInfoArray
            this.fileInfoArray.push({
              file_name: filename,
              ext: fileExtension,
              file: file,
              fieldName: fieldName,
            });
          }
          this.progressValue=45;
          setTimeout(async () => {
            await this.file_uploadWhenBrowse();
          }, 700);

          // Log the entire fileInfoArray
          console.log('File Info Array:', this.fileInfoArray);
        }
      }
    } else {
      this.documentInputErrors[fieldName] = 'No file selected';
      console.log('No file selected');
    }
  }

  async file_uploadWhenBrowse() {
    if (this.fileInfoArray.length !== 0) {
      this.progressValue=80;
      let data: any = {
        action: 'file_upload',
        client_id: this.myForm.get('clientId').value,
        files: this.fileInfoArray.map((file: any) => {
          return { file_name: file.file_name, ext: file.ext };
        }),
      };
      let header = {
        Authorization: localStorage.getItem('t'),
        // 'X-Api-Key': localStorage.getItem('clientid'),
      };

      let apiURL = `${this.config.apiURL}/admin/client`;

      let result = await this.apiService.postDataPromis(apiURL, data, header);

      if (result.s == '1' || result.status == '1') {
        this.uploadedVar = true
        console.log('RESULT:', result);
        this.progressValue=100;
        console.log('100%')
        setTimeout(() => {
          this.progressBar = false;
          console.log("myVariable is now false after 2 seconds");
        }, 2000);
        // setTimeout(() => {
        //   this.progressBar = false;
        // }, 5000);
        this.uploadFilesWhenBrowse(result.data, this.fileInfoArray);
      } else {
        this.notifier.alert('Info', '', result.error, 'info', 5000);
      }
    }  
  }


  uploadFilesWhenBrowse(data: any, files: any) {
    console.log('inside the upload file after browse the file.');
    
      let promises: Promise<any>[] = [];
      Object.keys(data).forEach((file_name: string, index: number) => {
        files[index]['formcontro_name'] = file_name;
        console.log('@@@@@@@@@@files:', files)
        files[index]['status'] = 'pending';
        console.log(data[file_name]['presigned_url']);
        promises.push(
          this.uploadFileAPIWhenBrowse(files[index], data[file_name]['presigned_url'])
        );
      });
  }


  uploadFileAPIWhenBrowse(file: any, url: string): Promise<any> {
    console.log('inside the upload file API after browse file.');

    return new Promise(async (resolve: any, reject: any) => {
      this.uploadFile
        .uploadFile(url, file['file'])
        .subscribe(async (event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              break;
            case HttpEventType.ResponseHeader:
              break;
            case HttpEventType.UploadProgress:
              file['status'] = 'uploading';
              let progress = Math.round(
                (event.loaded / (event.total || file['file']['size'])) * 100
              );
              file.loaded = event.loaded;
              file.percentage = progress;
              console.log('#####file',file)
              break;
            case HttpEventType.Response:
              file['status'] = 'completed';
              resolve(true);
          }
        });
    });
  }


  isImageFile(fileType: string): boolean {
    return ['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(fileType);
  }

  onSubmit() {
    this.submitted = true;
    this.pagination = { perPage: 10, page: 1 };
    this.pagination2 = { perPage: 10, page: 1 };
    Object.values(this.myForm.controls).forEach((control: any) => {
      control.markAsTouched();
    });

    this.isSubmitted = true;
    console.log('Forms values', this.myForm.value);
    console.log('Form', this.myForm);
    console.log('Form Status', this.myForm.status);
    if (this.bulkFileUpload && this.isEditMode) {
      if (this.myForm.valid) {
        this.updateValue(this.myForm.value);
        console.log(this.fileInfoArray.length);
        this.fileInfoArray = [];
        this.isEditMode = false;
        this.addcom = false;
        this.updated = {};
        this.agreementTouched = false;
        this.documentInputErrors = {};
      } else {
        this.notifier.alert(
          'Info',
          '',
          'Please provide the mandatory fields',
          'info',
          5000
        );
      }
    }else if (this.isEditMode) {
      if (Object.values(this.updated).some((value) => value === true)) {
        this.file_upload();
      } else {
        this.updateClient();
      }
      // this.updateForm = false
    } else {
      // this.file_upload();
      console.log('QQQQQQQQQQ', this.fileInfoArray);
      this.addclient(this.fileInfoArray);
    }
  }

  async file_upload() {
    this.notifier.loading(true)
    if (this.myForm.valid) {
      let data: any = {
        action: 'file_upload',
        client_id: this.myForm.get('clientId').value,
        files: this.fileInfoArray.map((file: any) => {
          return { file_name: file.file_name, ext: file.ext };
        }),
      };
      let header = {
        Authorization: localStorage.getItem('t'),
        // 'X-Api-Key': localStorage.getItem('clientid'),
      };

      let apiURL = `${this.config.apiURL}/admin/client`;

      let result = await this.apiService.postDataPromis(apiURL, data, header);

      if (result.s == '1' || result.status == '1') {
        // console.log(result);

        this.uploadFiles(result.data, this.fileInfoArray);
      } else {
        this.notifier.alert('Info', '', result.error, 'info', 5000);
      }
    } else {
      this.notifier.alert(
        'Info',
        '',
        'Please provide the mandatory fields',
        'info',
        5000
      );
    }
  }

  uploadFiles(data: any, files: any) {
    console.log('inside the upload file');
    let promises: Promise<any>[] = [];
    Object.keys(data).forEach((file_name: string, index: number) => {
      // const key = Object.keys(data).find(key => files[index].file_name.includes(key));
      // if (key) {
      //   console.log("object as filename : ", key);
      // }
      files[index]['formcontro_name'] = file_name;

      files[index]['status'] = 'pending';
      console.log(data[file_name]['presigned_url']);
      promises.push(
        this.uploadFileAPI(files[index], data[file_name]['presigned_url'])
      );
    });

    Promise.all(promises).then((values: any) => {
      // Save Company
      if (this.isEditMode) {
        this.updateClient(files);
      } else {
        this.addclient(files);
      }
    });
  }

  uploadFileAPI(file: any, url: string): Promise<any> {
    console.log('inside the upload file API');

    return new Promise(async (resolve: any, reject: any) => {
      this.uploadFile
        .uploadFile(url, file['file'])
        .subscribe(async (event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              break;
            case HttpEventType.ResponseHeader:
              break;
            case HttpEventType.UploadProgress:
              file['status'] = 'uploading';
              let progress = Math.round(
                (event.loaded / (event.total || file['file']['size'])) * 100
              );
              file.loaded = event.loaded;
              file.percentage = progress;
              break;
            case HttpEventType.Response:
              file['status'] = 'completed';
              resolve(true);
          }
        });
    });
  }

  async addclient(files: any) {
    this.notifier.loading(true)
    if (this.myForm.valid) {
      const formValues = this.myForm.value;
      let contact_mthd = ''
      if (Array.isArray(formValues.preff_cont)) {
        // If input is an array, join its elements with ", "
        contact_mthd =  formValues.preff_cont.join(", ");
      } else{
        // If input is already a string, return it as is
        contact_mthd =  formValues.preff_cont;
      }
      let payload_files: any = {};
      files.forEach((file: any) => {
        // Iterate over each field in the form
        payload_files[file.fieldName] = file.formcontro_name;
      });
      console.log('44444444444', payload_files);
      let data: any = {
        action: 'add',
        client_name: formValues.clientName,
        client_id: formValues.clientId,
        agreement_start_date: formValues.agreementStartDate,
        agreement_renewal_date: formValues.agreementRenewalDate,
        client_status: 'Active',
        agreement_amendments_s3_path: payload_files['agreementamendment'], // need presign_url
        company_address: formValues.companyAddress,
        unit_billing: formValues.companyBillingAddress,
        contact_method: contact_mthd,
        working_days: formValues.no_of_wrkdays,
        billing_period: `${formValues.billing_per_from} to ${formValues.billing_per_to}`,
        package_details: formValues.package_det,
        payments_terms: formValues.pay_teerms,
        assigned_company_manager: {
          name: formValues.un_flmn_name,
        },
        no_routes: formValues.no_of_routes,
        vendor_email: formValues.vendor_email,
        staff_details: formValues.staf_det,
        no_buses: formValues.no_of_buses,
        fuel_supplier: formValues.fuel_sup,
        garage_details: formValues.garage_det,
        contact_details: {
          Administration: {
            name: formValues.adm_op_Name,
            designation: formValues.adm_op_designation,
            contact_number: formValues.adm_op_Cont_Number,
            email: formValues.adm_op_email,
          },
          Operations: {
            name: formValues.op_Name,
            designation: formValues.op_designation,
            contact_number: formValues.op_Cont_Number,
            email: formValues.op_email,
          },
          HR: {
            name: formValues.hr_name,
            designation: formValues.hr_desg,
            contact_number: formValues.hr_cont,
            email: formValues.hr_email,
          },
          AccountsAndFinance: {
            name: formValues.acc_fin_name,
            designation: formValues.acc_fin_desig,
            contact_number: formValues.acc_fin_cont,
            email: formValues.acc_fin_email,
          },
        },
      };

      // let data: any = {}

      let header = {
        Authorization: localStorage.getItem('t'),
        // 'X-Api-Key': localStorage.getItem('clientid'),
      };

      //https://api.swayam.cloud/v3/admin/users/fetchaccountgroupsv2

      let apiURL = `${this.config.apiURL}/admin/client`;

      let result = await this.apiService.postDataPromis(apiURL, data, header);

      if (result.s == '1' || result.status == '1') {
        console.log(result);
        this.submitted = false;
        this.addcom = false;
        this.isEditMode = false;
        this.fileInfoArray = [];
        this.clearForm();
        this.fetchcomp();
        this.updateForm = false
        this.showPopup(
          'Client Added',
          'The client has been successfully Added.'
        );

        // this.notifier.alert('Success', '', result.message, 'success', 5000);
      } else {
        this.notifier.alert('Info', '', result.error_message, 'info', 5000);
      }
    } else {
      this.notifier.alert(
        'Info',
        '',
        'Please provide the mandatory fields',
        'info',
        5000
      );
    }
    this.notifier.loading(false)
  }

  addCompany() {
    this.addcom = true;
    this.selectedTab = 'client-details';
    // console.log('RRRRRRR',this.fileNames.length == 0 && !this.updated['client_agreement_amendment'])
    console.log(this.fileNames)
    console.log(this.updated)
    setTimeout(() => {
      $("#preff_cont").selectpicker()
    }, 10);
    
  }

  async updateClient(files?: any) {
    this.notifier.loading(true)
    if (this.myForm.valid) {
      let payload_files: any = {};
      if (files) {
        files.forEach((file: any) => {
          // Iterate over each field in the form
          payload_files[file.fieldName] = file.formcontro_name;
        });
      }

      let agreement_amendments: any;
      const formValues = this.myForm.value;
      // console.log('formValues.preff_cont',formValues.preff_cont)
      let contact_mthd = ''
      if (Array.isArray(formValues.preff_cont)) {
        // If input is an array, join its elements with ", "
        contact_mthd =  formValues.preff_cont.join(", ");
      } else{
        // If input is already a string, return it as is
        contact_mthd =  formValues.preff_cont;
      }
      if (this.isEditMode) {
        console.log('this.fileNames', this.fileNames)
        console.log('payload files', payload_files);
        console.log(Object.keys(this.updated).length);
        if (Object.keys(this.updated).length){
          agreement_amendments = this.fileNames[0]
          this.nameOfFile = this.fileNames[0]
        }else{
          // agreement_amendments = this.selectedClientFilePath
          // this.nameOfFile = this.selectedClientFilePath
          agreement_amendments = ''
          this.nameOfFile = ''
        }
        console.log(agreement_amendments);
        console.log('this.updated is empty', Object.keys(this.updated).length);

        if (Object.keys(this.updated).length !== 0) {
          const updatedKeys = Object.keys(this.updated);
          console.log('Updated files', updatedKeys);
          updatedKeys.forEach((key) => {
            // if (payload_files.hasOwnProperty(key)) {
            switch (key) {
              case 'client_agreement_amendment':
                agreement_amendments = payload_files['agreementamendment'];
                break;
            }
            // }
          });

          const remainingKeys = Object.keys(this.fileNames).filter(
            (key) => !payload_files.hasOwnProperty(key)
          );

          remainingKeys.forEach((key, index) => {
            switch (key) {
              case 'client_agreement_amendment':
                agreement_amendments = this.fileNames[0];
                break;
            }
          });
        }
      }
      console.log('the path should be sent to DB', agreement_amendments);

      let data: any = {
        action: 'update',
        client_name: formValues.clientName,
        client_id: formValues.clientId,
        agreement_start_date: formValues.agreementStartDate,
        agreement_renewal_date: formValues.agreementRenewalDate,
        client_status: formValues.sttus,
        agreement_amendments_s3_path: agreement_amendments, // need presign_url
        agreement_amendments_comments: formValues.amendmentsComments,
        company_address: formValues.companyAddress,
        unit_billing: formValues.companyBillingAddress,
        vendor_email: formValues.vendor_email,
        contact_method: contact_mthd,
        working_days: formValues.no_of_wrkdays,
        billing_period: `${formValues.billing_per_from} to ${formValues.billing_per_to}`,
        package_details: formValues.package_det,
        payment_methods: formValues.accept_pay_method,
        payments_terms: formValues.pay_teerms,
        staff_details: formValues.staf_det,
        assigned_company_manager: {
          name: formValues.un_flmn_name,
        },
        no_routes: formValues.no_of_routes,
        no_buses: formValues.no_of_buses,
        fuel_supplier: formValues.fuel_sup,
        garage_details: formValues.garage_det,
        contact_details: {
          Administration: {
            name: formValues.adm_op_Name,
            designation: formValues.adm_op_designation,
            contact_number: formValues.adm_op_Cont_Number,
            email: formValues.adm_op_email,
          },
          Operations: {
            name: formValues.op_Name,
            designation: formValues.op_designation,
            contact_number: formValues.op_Cont_Number,
            email: formValues.op_email,
          },
          HR: {
            name: formValues.hr_name,
            designation: formValues.hr_desg,
            contact_number: formValues.hr_cont,
            email: formValues.hr_email,
          },
          AccountsAndFinance: {
            name: formValues.acc_fin_name,
            designation: formValues.acc_fin_desig,
            contact_number: formValues.acc_fin_cont,
            email: formValues.acc_fin_email,
          },
        },
      };

      let header = {
        Authorization: localStorage.getItem('t'),
        // 'X-Api-Key': localStorage.getItem('clientid'),
      };

      //https://api.swayam.cloud/v3/admin/users/fetchaccountgroupsv2
      let apiURL = `${this.config.apiURL}/admin/client`;

      let result = await this.apiService.postDataPromis(apiURL, data, header);

      if (result.s == '1' || result.status == '1') {
        // console.log(result);
        this.submitted = false;
        this.updated = {};
        this.addcom = false;
        this.isEditMode = false;
        this.fileInfoArray = [];
        this.clearForm();
        this.fetchcomp();
        this.updateForm = false
        this.showPopup(
          'Client Updated',
          'The client has been successfully updated.'
        );

        // this.notifier.alert('Success', '', result.data, 'success', 5000);
      } else {
        this.notifier.alert('Info', '', result.error, 'info', 5000);
      }
    } else {
      this.notifier.alert(
        'Info',
        '',
        'Please provide the mandatory fields',
        'info',
        5000
      );
    }
    this.notifier.loading(false)
  }

  // async fetchcomp() {
  //   this.notifier.loading(true);

  //   let data: any = {
  //     action: 'fetch_all',
  //   };
  //   let header = {
  //     Authorization: localStorage.getItem('t'),
  //     // 'X-Api-Key': localStorage.getItem('clientid'),
  //   };

  //   //https://api.swayam.cloud/v3/admin/users/fetchaccountgroupsv2
  //   let apiURL = `${this.config.apiURL}/admin/client`;

  //   let result = await this.apiService.postDataPromis(apiURL, data, header);

  //   if (result.s == '1' || result.status == '1') {
  //     // console.log(result);

  //     this.result = result.data;
  //     console.log('fetch in companies', result);
  //     this.exportVar = result.data;
  //     this.filteredData = result.data;

  //     // this.notifier.alert('Success', '', result.data, 'success', 5000);
  //   } else {
  //     this.notifier.alert('Info', '', result.error, 'info', 5000);
  //   }
  //   this.notifier.loading(false);
  // }


  async fetchcomp() {
    if(this.pagination2.page === 1){
      this.notifier.loading(true);
      this.filteredData = []
    }

    let data: any = {
      action: 'fetch_by_pagination',
      page: this.pagination2.page,
      perPage: this.pagination2.perPage
    };
    let header = {
      Authorization: localStorage.getItem('t'),
      // 'X-Api-Key': localStorage.getItem('clientid'),
    };

    //https://api.swayam.cloud/v3/admin/users/fetchaccountgroupsv2
    let apiURL = `${this.config.apiURL}/admin/client`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.s == '1' || result.status == '1') {
      this.pagination2.nextPage = result.nextPage;
      this.filteredData = this.filteredData.concat(result.data);
      this.result = this.filteredData
      this.exportVar = this.filteredData
      if (this.pagination2.page === 1){
        this.notifier.loading(false);
      }
      if (this.pagination2.nextPage) {
        this.pagination2.page = this.pagination2.nextPage;
        this.fetchcomp();
      }
      else{
        this.pagination2 = { perPage: 10, page: 1 };
        this.pagination = { perPage: 10, page: 1 };
      }
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
  }

  async fetchInactiveClient() {
    
    this.notifier.loading(true);

    let data: any = {
      action: 'fetch_all_inactive',
    };
    let header = {
      Authorization: localStorage.getItem('t'),
      // 'X-Api-Key': localStorage.getItem('clientid'),
    };

    //https://api.swayam.cloud/v3/admin/users/fetchaccountgroupsv2
    let apiURL = `${this.config.apiURL}/admin/client`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.s == '1' || result.status == '1') {
      // console.log(result);

      this.result = result.data;
      console.log('fetch in companies', result);
      this.exportVar = result.data;
      this.filteredData = result.data;

      // this.notifier.alert('Success', '', result.data, 'success', 5000);
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
    this.notifier.loading(false);
  }


  async assignVehicleCountToClient() {
    // this.notifier.loading(true);

    let data: any = {
      action: 'get_total_vehicle',
    };
    let header = {
      Authorization: localStorage.getItem('t'),
      // 'X-Api-Key': localStorage.getItem('clientid'),
    };

    //https://api.swayam.cloud/v3/admin/users/fetchaccountgroupsv2
    let apiURL = `${this.config.apiURL}/admin/client`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.s == '1' || result.status == '1') {
      console.log(result)
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
    // this.notifier.loading(false);
  }


  openModal(company: any) {
    this.updateForm = true
    this.isEditMode = true
    console.log('HHHTTTGGG', company)
    this.selectedCompany = company;
    this.populateForm(this.selectedCompany);
    $('#preff_cont').selectpicker();
      setTimeout(() => {
        $('#preff_cont').val(this.selectedCompany.client_details.contact_method.split(', '));
        setTimeout(() => {
          $('#preff_cont').selectpicker('refresh');
        }, 20);
      }, 50);
    this.showModal = true;
    this.disableForModal()
  }

  disableForModal() {
    Object.keys(this.myForm.controls).forEach(control => {
      this.myForm.get(control)?.disable();
    });
  }

  // Function to enable all form fields
  enableForModal() {
    Object.keys(this.myForm.controls).forEach(control => {
      this.myForm.get(control)?.enable();
    });
  }

  hideModal() {
    this.showModal = false;
    console.log('showModal++',this.showModal)
  }

  openRole2(company: any) {
    this.selectedCompany = company;
    this.role = this.selectedCompany.client_contact_details[1]
    this.showRole = true;
  }

  openRole3(company: any) {
    this.selectedCompany = company;
    this.role = this.selectedCompany.client_contact_details[2]
    this.showRole = true;
  }

  openRole4(company: any) {
    this.selectedCompany = company;
    this.role = this.selectedCompany.client_contact_details[0]
    this.showRole = true;
  }

  openRole(company: any, index: any) {
    this.selectedCompany = company;
    this.role = this.selectedCompany.client_contact_details[index]
    this.showRole = true;
  }

  hideRole() {
    this.showRole = false;
  }


  toggleEditMode(): void {
    this.isEditMode = !this.isEditMode;
  }
  async editClient(selectedCompany: any): Promise<void> {
    this.selectedTab = 'client-details'; 
    console.log('selectedCompany:', selectedCompany)
    this.updateForm = true
    this.notifier.loading(true);
    // this.isEditMode = true;
    console.log('inside edit client');
    console.log('edit mode ', this.isEditMode);
    this.isEditMode = true;
    console.log('@@@@@@@, isEditMode', this.isEditMode);
    // this.addcom = true;
    // if (this.isEditMode){
    setTimeout(async () => {
      console.log('This message is delayed by 15 milliseconds');
      await this.populateForm(selectedCompany);
      $('#preff_cont').selectpicker();
      setTimeout(() => {
        $('#preff_cont').val(selectedCompany.client_details.contact_method.split(', '));
        setTimeout(() => {
          $('#preff_cont').selectpicker('refresh');
        }, 20);
      }, 50);
    }, 15);
     // Pass selected company object to populateForm
    // this.addcom = true;
    // }
    console.log('@@@@@@@, isEditMode', this.isEditMode);
    console.log('updated',this.updated)
    console.log('agreementIn', this.agreementIn)
    // console.log((this.agreementIn.files || []).length == 0)
    console.log('fileNames',this.fileNames)
    console.log('updated',this.updated)
    // updated['client_agreement_amendment'] && (fileNames!== [] && fileNames !== undefined
    
    this.notifier.loading(false);
  }

  expandedIndex: number | null = null;

  toggleOptions(index: number) {
    this.expandedIndex = this.expandedIndex === index ? null : index;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    if (!target.closest('.options-container')) {
      this.expandedIndex = null;
    }
  }



  toggleOptionsExport() {
    console.log('toggling..');
    this.expandedButton = !this.expandedButton;
    console.log('this.expandedButton', this.expandedButton);
  }

  
  queryResult(event: any) {
    let queryString: string = (event.target.value || '').toLowerCase();
    if (!queryString) {
        this.filteredData = this.result;
        return;
    }

    this.filteredData = this.result.filter((item: any) => {
        return this.headers.some((h: any) => {
            let value: string;
            if (h.id.includes('client_details')) {
                const route = h.id.split('.')[1];
                value = item.client_details[route];
            } else if (h.id.includes('client_contact_details')) {
                const match = h.id.match(/client_contact_details\[(\d+)\]\.(\w+)/);
                if (match) {
                    const index = parseInt(match[1]);
                    const field = match[2];
                    value = item.client_contact_details[index][field];
                } else {
                    value = '';
                }
            } else {
                value = item[h.id];
            }
            return value?.toString().toLowerCase().includes(queryString);
        });
    });
}



  async get_excel_url() {
    this.notifier.loading(true);

    let data: any = {
      action: 'get_client_csv',
    };
    let header = {
      Authorization: localStorage.getItem('t'),
      // 'X-Api-Key': localStorage.getItem('clientid'),
    };

    //https://api.swayam.cloud/v3/admin/users/fetchaccountgroupsv2
    let apiURL = `${this.config.apiURL}/admin/client`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.s == '1' || result.status == '1') {
      // console.log(result);

      let pre_url = result.data;
      console.log('Presign Url for', pre_url);
      let a = document.createElement('a');
      a.href = pre_url;
      a.download = 'true';
      a.setAttribute('target', '_blank');
      a.click();
      // this.notifier.alert('Success', '', result.data, 'success', 5000);
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
    this.notifier.loading(false);
  }

  openFileExplorer() {
    this.fileInput.nativeElement.click();
  }

  fileSelected(event: any) {
    console.log('event is ', event);
    const selected_File: File = event.target.files[0];
    this.fileInput.nativeElement.value = '';
    console.log('selected File is ', selected_File);

    if (selected_File) {
      console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!');
      // Get the file name and extension
      const fileName: string = selected_File.name;
      const fileExtension: string = fileName.split('.').pop() || '';

      // Check if the file extension is valid
      if (fileExtension === 'xlsx' || fileExtension === 'xls') {
        console.log('Valid Excel file selected');
        // Read the Excel file
        const reader = new FileReader();
        reader.onload = (e: ProgressEvent<FileReader>) => {
          const data_of_file: any | ArrayBuffer | null = e.target?.result;
          if (typeof data_of_file === 'string') {
            // Parse the Excel file content
            const workbook = XLSX.read(data_of_file, { type: 'binary' });
            // Assuming there's only one sheet, you can change this according to your requirement
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];
            // Convert the sheet to JSON
            const jsonData = XLSX.utils.sheet_to_json(worksheet);
            // Now you can validate the content as per your requirements
            console.log('Excel content:', jsonData);
            let isnotValid = this.validateData(jsonData);
            console.log('isnotValid', isnotValid);
            if (isnotValid) {
              this.notifier.alert(
                'Info',
                '',
                'Please provide all Fields and with correct Information',
                'info',
                5000
              );
              //   return;
            } else {
              this.pagination['page'] = 1
              this.bulkFileUpload = true;
              this.updated = {};
              this.fileNames = [];
              console.log('&&&&&&&&&&&&&&&');
              // this.assingValueforExcel(jsonData);
              // this.contentList = jsonData;
              this.contentList = this.assingValueforExcel(jsonData);
              console.log('this.contentList', this.contentList);
              // for (let i of this.contentList) {
              //   console.log(typeof i);
              // }
              // this.bulkUpload(jsonData)
            }
            // Here you can perform validation on jsonData
            // For example, check if certain columns or values are present
          } else {
            console.error('Failed to read the file.');
          }
        };
        reader.readAsBinaryString(selected_File);
      } else {
        console.error('Invalid file format. Please select an Excel file.');
      }
      // this.fileInput.nativeElement.value = '';
    } else {
      console.error('No file selected.');
    }
  }

  validateData(data: any[]){
    let errors : boolean;
    const requiredAttributes = [
      "Client Name", "Client Id", "Company Address", "Administration Name", "Administration Designation", 
      "Administration Contact Number", "Administration Email", "Operations Name", "Operations Designation", 
      "Operations Contact Number", "Operations Email", "HR Name", "HR Designation", "HR Contact Number", 
      "HR Email", "AccountsAndFinance Name", "AccountsAndFinance Designation", "AccountsAndFinance Contact Number", 
      "AccountsAndFinance Email", "Vendor Email", "Preferred Contact Method", "No of Working Days", 
      "Billing Period From", "Billing Period To", "Package Details", "Payment Terms", "Number of Routes", 
      "All Staff Details"
    ];
    if (data.length !== 0){
      for (let i = 0; i < data.length; i++) {
        let item = data[i];
        for (let attribute of requiredAttributes) {
          if (!item.hasOwnProperty(attribute) || item[attribute] === null || item[attribute] === undefined || item[attribute] === '') {
            console.log('&&&&&&&&&&&&&', attribute)
            return true
          }
        }
      }
    }else{
      return true
    }
    return false
  }

  assingValueforExcel(data: any) {
    console.log('inside assingValueforExcel.')
    let lst_dt = ['Billing Period From', 'Billing Period To', 'Agreement Start Date', 'Agreement Renewal Date']
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      console.log('item',item)
      for (let index in lst_dt){
        console.log('index',index)
        if (lst_dt[index] in item){
          console.log('TTTTTTTTTTTTTTT', this.stringDate(item[lst_dt[index]]))
          item[lst_dt[index]] = this.stringDate(item[lst_dt[index]])
        }
      }

      if ('Package Details' in item){
        console.log('inside')
        console.log(item['Package Details'] === "Days Wise")
        if (item['Package Details']=== "km's"){
          item['Package Details'] = "kms"
        }else if (item['Package Details'] === "Month Wise"){
          item['Package Details'] = "month"
        }else if (item['Package Details'] === "Days Wise"){
          item['Package Details'] = "days"
        }
      }

      if ('Preferred Contact Method' in item){
        if (item['Preferred Contact Method'] === 'Whatsapp'){
          item['Preferred Contact Method'] = 'whatsapp';
        }
      }
      item['Total Number of Buses'] = 0
      data[i] = item
    }
    return data;
  }

  onTabChange(tab: string) {
    this.selectedTab = tab;
  }

  prevTab() {
    const currentIndex = this.tabs.indexOf(this.selectedTab);
    if (currentIndex > 0) {
      this.selectedTab = this.tabs[currentIndex - 1];
    }
  }

  nextTab() {
    const currentIndex = this.tabs.indexOf(this.selectedTab);
    if (currentIndex < this.tabs.length - 1) {
      this.selectedTab = this.tabs[currentIndex + 1];
    }
  }

  isFirstTab(): boolean {
    return this.selectedTab === this.tabs[0];
  }

  isLastTab(): boolean {
    return this.selectedTab === this.tabs[this.tabs.length - 1];
  }





  initiateAgreementIn() {
    if (!this.showModal){
      if (this.myForm.value.clientId !== '') {
        this.agreementIn.nativeElement.value = '';
        this.agreementIn.nativeElement.click();
      } else {
        this.showPopup(
          'Please provide Client ID before uploading the document',
          ''
        );
      }
    }
  }

  deleteDocument() {
    this.fileNames = [];
    this.updated = {};
    this.agreementIn.nativeElement.value = ''
    
    // this.myForm.value.reg_plate_photo = ''
    // console.log(this.myForm.value)
    console.log('###############', this.updated, this.fileNames);
  }

  deleteDocumentInUpdate() {
    if (!this.showModal){
      this.fileNames = [];
      this.updated = {};
      this.agreementIn.nativeElement.value = ''
    }
  }

  gotToHomePage() {
    this.addcom = false;
    this.isEditMode = false;
    this.submitted = false;
    this.clearForm();
    this.fetchcomp();
    this.bulkFileUpload = false;
    this.updateForm = false;
    this.clientVehicleResult = '';
    this.clientName = '';
    this.clientVehicle = false;
  }

 
  validateContentList(data: any){
    const validatedData = [];
  
    let error :any = '';
    for (let item of data) {
  
      // Validate required fields
      if (!item['Client Name'] || typeof item['Client Name'] !== 'string' || item['Client Name'].length > 100) {
        console.error('Invalid Client Name');
        error = 'Invalid Client Name'
        return error;
      }
      let Client_Name= item['Client Name'];
  
      if (!item['Client Id'] || typeof item['Client Id'] !== 'number') {
        error = 'Invalid Client Id of ' + Client_Name
        return error;
      }
      if (!item['Company Address'] || typeof item['Company Address'] !== 'string' || item['Company Address'].length > 500) {
        console.error('Invalid Company Address');
        error = 'Invalid Company Address of ' + Client_Name
        return error;
      }
      if (!item['Vendor Email'] || !this.isValidEmail(item['Vendor Email'])) {
        console.error('Invalid Vendor Email');
        error = 'Invalid Vendor Email of ' + Client_Name
        return error;
      }
      if (!item['Preferred Contact Method'] || typeof item['Preferred Contact Method'] !== 'string') {
        console.error('Invalid Preferred Contact Method');
        error = 'Invalid Preferred Contact Method of ' + Client_Name
        return error;
      }
      if (!item['No of Working Days'] || typeof item['No of Working Days'] !== 'number') {
        console.error('Invalid No of Working Days');
        error = 'Invalid No of Working Days of ' + Client_Name
        return error;
      }
      if (!item['Total Number of Buses'] || typeof item['Total Number of Buses'] !== 'number') {
        console.error('Invalid Total Number of Buses');
        error = 'Invalid Total Number of Buses of ' + Client_Name
        return error;;
      }if (!item['All Staff Details'] || typeof item['All Staff Details'] !== 'string') {
        console.error('Invalid All Staff Details');
        error = 'Invalid All Staff Details of ' + Client_Name
        return error;
      }
      if (!item['Administration Name'] || typeof item['Administration Name'] !== 'string' || item['Administration Name'].length > 100) {
        console.error('Invalid Administration Name');
        error = 'Invalid Administration Name of ' + Client_Name
        return error;
      }
      if (!item['Administration Designation'] || typeof item['Administration Designation'] !== 'string' || item['Administration Designation'].length > 100) {
        console.error('Invalid Administration Designation');
        error = 'Invalid Administration Designation of ' + Client_Name
        return error;
      }
      if (!item['Administration Contact Number'] || !/^\d{10,13}$/.test(item['Administration Contact Number'].toString())) {
        console.error('Invalid Administration Contact Number');
        error = 'Invalid Administration Contact Number of ' + Client_Name
        return error;
      }
      if (!item['Administration Email'] || !this.isValidEmail(item['Administration Email'])) {
        console.error('Invalid Administration Email');
        error = 'Invalid Administration Email of ' + Client_Name
        return error;
      }
      if (!item['Operations Name'] || typeof item['Operations Name'] !== 'string' || item['Operations Name'].length > 100) {
        console.error('Invalid Operations Name');
        error = 'Invalid Operations Name of ' + Client_Name
        return error;
      }
      if (!item['Operations Designation'] || typeof item['Operations Designation'] !== 'string' || item['Operations Designation'].length > 100) {
        console.error('Invalid Operations Designation');
        error = 'Invalid Operations Designation of ' + Client_Name
        return error;
      }
      if (!item['Operations Contact Number'] || !/^\d{10,13}$/.test(item['Operations Contact Number'].toString())) {
        console.error('Invalid Operations Contact Number');
        error = 'Invalid Operations Contact Number of ' + Client_Name
        return error;
      }
      if (!item['Operations Email'] || !this.isValidEmail(item['Operations Email'])) {
        console.error('Invalid Operations Email');
        error = 'Invalid Operations Email of ' + Client_Name
        return error;
      }
      if (!item['HR Name'] || typeof item['HR Name'] !== 'string' || item['HR Name'].length > 100) {
        console.error('Invalid HR Name');
        error = 'Invalid HR Name of ' + Client_Name
        return error;
      }
      if (!item['HR Designation'] || typeof item['HR Designation'] !== 'string' || item['HR Designation'].length > 100) {
        console.error('Invalid HR Designation');
        error = 'Invalid HR Designation of ' + Client_Name
        return error;
      }
      if (!item['HR Contact Number'] || !/^\d{10,13}$/.test(item['HR Contact Number'].toString())) {
        console.error('Invalid HR Contact Number');
        error = 'Invalid HR Contact Number of ' + Client_Name
        return error;
      }
      if (!item['HR Email'] || !this.isValidEmail(item['HR Email'])) {
        console.error('Invalid HR Email');
        error = 'Invalid HR Email of ' + Client_Name
        return error;
      }
      if (!item['AccountsAndFinance Name'] || typeof item['AccountsAndFinance Name'] !== 'string' || item['AccountsAndFinance Name'].length > 100) {
        console.error('Invalid AccountsAndFinance Name');
        error = 'Invalid AccountsAndFinance Name of ' + Client_Name
        return error;
      }
      if (!item['AccountsAndFinance Designation'] || typeof item['AccountsAndFinance Designation'] !== 'string' || item['AccountsAndFinance Designation'].length > 100) {
        console.error('Invalid AccountsAndFinance Designation');
        error = 'Invalid AccountsAndFinance Designation of ' + Client_Name
        return error;
      }
      if (!item['AccountsAndFinance Contact Number'] || !/^\d{10,13}$/.test(item['AccountsAndFinance Contact Number'].toString())) {
        console.error('Invalid AccountsAndFinance Contact Number');
        error = 'Invalid AccountsAndFinance Contact Number of ' + Client_Name
        return error;
      }
      if (!item['AccountsAndFinance Email'] || !this.isValidEmail(item['AccountsAndFinance Email'])) {
        console.error('Invalid AccountsAndFinance Email');
        error = 'Invalid AccountsAndFinance Email of ' + Client_Name
        return error;
      }
      // validatedData.push(validatedItem);
    }
  
    return error;
  }
  
  // Helper function to validate email format
  isValidEmail(email: any) {
    // Simple email validation regex
    const emailRegex = /\S+@\S+\.\S+/;
    return emailRegex.test(email);
  }

  async bulkUpload(clients_info: any) {
    this.notifier.loading(true)
    let data: any = {
      action: 'bulk_upload',
      clients: clients_info
    };
    let header = {
      Authorization: localStorage.getItem('t'),
      // 'X-Api-Key': localStorage.getItem('clientid'),
    };
    let apiURL = `${this.config.apiURL}/admin/client`;
    let result = await this.apiService.postDataPromis(apiURL, data, header);
    if (result.s == '1' || result.status == '1') {
      console.log(result);
      this.submitted = false;
      this.addcom = false;
      this.isEditMode = false;
      this.bulkFileUpload = false
      this.fileInfoArray = [];
      this.clearForm();
      this.fetchcomp();
      this.updateForm = false

      this.notifier.alert('Success', '', result.message, 'success', 5000);
    } else {
      this.notifier.alert('Info', '', result.message, 'info', 5000);
    }
    this.notifier.loading(false);
  }

  async editClientForBulkUpload(selectedCompany: any): Promise<void> {
    this.notifier.loading(true);
    console.log('inside edit client');
    console.log(selectedCompany)
    this.isEditMode = true;
    this.selectedArrayForEdit = selectedCompany;
    let client_id = selectedCompany['Client Id'];
    for (var i = 0; i < this.contentList.length; i++) {
      if (this.contentList[i]['Client Id'] === client_id) {
        this.index = i;
        break;
      }
    }
    setTimeout(async () => {
      console.log('This message is delayed by 15 milliseconds');
      await this.populateFormForBulkUpload(selectedCompany);
      $('#preff_cont').selectpicker();
      setTimeout(() => {
        $('#preff_cont').val(selectedCompany['Preferred Contact Method']);
        setTimeout(() => {
          $('#preff_cont').selectpicker('refresh');
        }, 20);
      }, 50);
    }, 15);
     // Pass selected company object to populateForm
    // this.addcom = true;
    // }
    console.log('WWWWWWWWWwindex', this.index)
    this.notifier.loading(false);
  }


  async populateFormForBulkUpload(selectedCompany: any) {
    this.notifier.loading(true);
    console.log('inside the populate form of bulk upload');
    console.log(selectedCompany);
    if (selectedCompany) {
      if (selectedCompany['Agreement Amendment']){
        this.sendUrls = [];
        this.sendUrls.push(
          selectedCompany['Agreement Amendment'][0]['formcontro_name']
        );
        await this.fetch(this.sendUrls);
        console.log('Send urls', this.sendUrls);
      }
      
      this.myForm.patchValue({
        clientName: selectedCompany['Client Name'],
        clientId: selectedCompany['Client Id'],
        agreementStartDate: selectedCompany['Agreement Start Date'],
        agreementRenewalDate:
          selectedCompany['Agreement Renewal Date'],
        // agreementAmendments: this.fileNames[0],
        companyAddress: selectedCompany['Company Address'],
        companyBillingAddress: selectedCompany['Company/ Unit Billing Address'],
        preff_cont: selectedCompany['Preferred Contact Method'],
        // vendor_email
        no_of_wrkdays: selectedCompany['No of Working Days'],
        billing_per_from: selectedCompany['Billing Period From'],
        billing_per_to: selectedCompany['Billing Period To'],
        package_det: selectedCompany['Package Details'],
        pay_teerms: selectedCompany['Payment Terms'],
        un_flmn_name:
          selectedCompany['Assigned Company Manager Name'],
        no_of_routes: selectedCompany['Number of Routes'],
        no_of_buses: selectedCompany['Total Number of Buses'],
        vendor_email: selectedCompany['Vendor Email'],
        staf_det: selectedCompany['All Staff Details'],
        fuel_sup: selectedCompany['Fuel Station'],
        garage_det: selectedCompany['Garage Details'],
        hr_name: selectedCompany['HR Name'],
        hr_desg:
          selectedCompany['HR Designation'],
        hr_cont:
          selectedCompany['HR Contact Number'],
        hr_email:
          selectedCompany['HR Email'],
        adm_op_Name:
          selectedCompany['Administration Name'],
        adm_op_designation:
          selectedCompany['Administration Designation'],
        adm_op_Cont_Number:
          selectedCompany['Administration Contact Number'],
        adm_op_email:
          selectedCompany['Administration Email'],
        acc_fin_name:
          selectedCompany['AccountsAndFinance Name'],
        acc_fin_desig:
          selectedCompany['AccountsAndFinance Designation'],
        acc_fin_cont:
          selectedCompany['AccountsAndFinance Contact Number'],
        acc_fin_email:
          selectedCompany['AccountsAndFinance Email'],
        op_Name:
          selectedCompany['Operations Name'],
        op_designation:
          selectedCompany['Operations Designation'],
        op_Cont_Number:
          selectedCompany['Operations Contact Number'],
        op_email:
          selectedCompany['Operations Email'],
      });
    }
    console.log('this.myForm:', this.myForm);
    this.notifier.loading(false);
  }


  // serialToDate(serial: number): Date {
  //   const baseDate = new Date('1899-12-30');
  //   const millisecondsInDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
  //   const daysToAdd = serial - 1; // Excel uses 1-based indexing
  //   const millisecondsToAdd = daysToAdd * millisecondsInDay;
  //   return new Date(baseDate.getTime() + millisecondsToAdd);
  // }

  stringDate(serial: number): string {
    // Excel date value
    var excelDate = serial + 1;

    // Convert Excel date to standard date format
    var standardDate = new Date(
      (excelDate - 1) * 24 * 60 * 60 * 1000 + Date.UTC(1899, 11, 30, 0, 0, 0)
    );

    // Adjust the date according to local timezone
    var localDate = new Date(
      standardDate.getTime() + standardDate.getTimezoneOffset() * 60 * 1000
    );

    // Extract year, month, and day from the standard date
    var year = localDate.getFullYear();
    var month = ('0' + (localDate.getMonth() + 1)).slice(-2);
    var day = ('0' + localDate.getDate()).slice(-2);

    // Form the string date in "YYYY-MM-DD" format
    var stringDate = year + '-' + month + '-' + day;

    return stringDate; // Output: 2024-03-01
  }


  updateValue(data: any) {
    const formValues = this.myForm.value;
      // console.log('formValues.preff_cont',formValues.preff_cont)
      let contact_mthd = ''
      if (Array.isArray(formValues.preff_cont)) {
        // If input is an array, join its elements with ", "
        contact_mthd =  formValues.preff_cont.join(", ");
      } else{
        // If input is already a string, return it as is
        contact_mthd =  formValues.preff_cont;
      }
    this.contentList[this.index]['Client Name'] = data.clientName;
    this.contentList[this.index]['Client Id'] = data.clientId;
    this.contentList[this.index]['Agreement Start Date'] = data.agreementStartDate;
    this.contentList[this.index]['Agreement Renewal Date'] = data.agreementRenewalDate;
    this.contentList[this.index]['Agreement Amendments S3 Path'] = data.agreement_amendments; // need presign_url
    this.contentList[this.index]['Company Address'] = data.companyAddress;
    this.contentList[this.index]['Company/ Unit Billing Address'] = data.companyBillingAddress;
    this.contentList[this.index]['Vendor Email'] = data.vendor_email;
    this.contentList[this.index]['Preferred Contact Method'] = contact_mthd;
    this.contentList[this.index]['No of Working Days'] = data.no_of_wrkdays;
    this.contentList[this.index]['Billing Period From'] = data.billing_per_from;
    this.contentList[this.index]['Billing Period To'] = data.billing_per_to;
    this.contentList[this.index]['Package Details'] = data.package_det;
    this.contentList[this.index]['Payment Terms'] = data.pay_teerms;
    this.contentList[this.index]['All Staff Details'] = data.staf_det;
    this.contentList[this.index]['Assigned Company Manager Name'] = data.un_flmn_name;
    this.contentList[this.index]['Number of Routes'] = data.no_of_routes;
    this.contentList[this.index]['Total Number of Buses'] = data.no_of_buses;
    this.contentList[this.index]['Fuel Station'] = data.fuel_sup;
    this.contentList[this.index]['Garage Details'] = data.garage_det;
    this.contentList[this.index]['Administration Name'] = data.adm_op_Name;
    this.contentList[this.index]['Administration Designation'] = data.adm_op_designation;
    this.contentList[this.index]['Administration Contact Number'] = data.adm_op_Cont_Number;
    this.contentList[this.index]['Administration Email'] = data.adm_op_email;
    this.contentList[this.index]['Operations Name'] = data.op_Name;
    this.contentList[this.index]['Operations Designation'] = data.op_designation;
    this.contentList[this.index]['Operations Contact Number'] = data.op_Cont_Number;
    this.contentList[this.index]['Operations Email'] = data.op_email;
    this.contentList[this.index]['HR Name'] = data.hr_name;
    this.contentList[this.index]['HR Designation'] = data.hr_desg;
    this.contentList[this.index]['HR Contact Number'] = data.hr_cont;
    this.contentList[this.index]['HR Email'] = data.hr_email;
    this.contentList[this.index]['AccountsAndFinance Name'] = data.acc_fin_name;
    this.contentList[this.index]['AccountsAndFinance Designation'] = data.acc_fin_desig;
    this.contentList[this.index]['AccountsAndFinance Contact Number'] = data.acc_fin_cont;
    this.contentList[this.index]['AccountsAndFinance Email'] = data.acc_fin_email;
  }


  onFileSelectedForBulkUpload(fieldName: string, filename: string, event: any) {
    this.progressBar = true;
    this.progressValue = 0;
    const file = event.target.files?.[0];
    console.log('Selected File:', file);
    if (file) {
      // Get the file name without extension
      const fileNameWithoutExtension = file.name
        .split('.')
        .slice(0, -1)
        .join('.');
      console.log('File Name:', fileNameWithoutExtension);

      // Get the file extension
      const fileExtension = file.name.split('.').pop()?.toLowerCase() || '';
      console.log('File Extension:', fileExtension);

      if (file.size > 20 * 1024 * 1024) {
        this.documentInputErrors[fieldName] =
          'File size exceeds the limit (20MB)';
      } else {
        if (
          !this.isImageFile(fileExtension) &&
          file.type !== 'application/pdf'
        ) {
          this.documentInputErrors[fieldName] =
            'Unsupported file type. Only PDF and images are allowed.';
        } else {
          this.updated[filename] = true;
          this.documentInputErrors[fieldName] = null; // Clear error if file is valid

          // Check if an item with the same fieldName already exists
          const existingIndex = this.fileInfoArray.findIndex(
            (item) => item.fieldName === fieldName
          );
          this.fileInfoArray = [];
          this.fileNames = [];
          // Push the new item into the fileInfoArray
          this.fileInfoArray.push({
            file_name: filename,
            ext: fileExtension,
            file: file,
            fieldName: fieldName,
          });
          Object.values(this.myForm.controls).forEach((control: any) => {
            control.markAsTouched();
          });
          this.assignAmendmentToBulkUp(this.myForm.value);
          let object = this.contentList[this.index]
          if (this.validateUploadFiles(object)) {
            // this.file_uploadForBulkUpload(object);
            this.progressValue=45;
            setTimeout(async () => {
              await this.file_uploadForBulkUpload(object);;
            }, 700);
          }
          console.log('File Info Array:', this.fileInfoArray);
          console.log('qqqqqqqqqqqqqqqqqqqq', this.fileInfoArray[0].file.name);
        }
      }
    } else {
      this.documentInputErrors[fieldName] = 'No file selected';
      console.log('No file selected');
    }
    console.log('contentList', this.contentList);
  }


  async assignAmendmentToBulkUp(formValues: any, files?: any) {
    console.log('fileInfoArray', this.fileInfoArray);
    console.log('this.contentList', this.contentList);
    console.log('formValues:', formValues);
    console.log('files:', files);
    this.contentList[this.index]['Agreement Amendment'] =
      this.fileInfoArray;
    this.selectedArrayForEdit = this.contentList[this.index];
    console.log('this.contentList2', this.contentList);
  }

  validateUploadFiles(object: any) {
    // object['Vehicle Name'] =
    console.log('rrrrrrrrrrrrrrrrrrrrrr', this.index);
    if (object['Agreement Amendment']) {
      let item = object['Agreement Amendment'][0];
      console.log('item===>', item);
      if (
        !(
          typeof item.file_name === 'string' &&
          typeof item.ext === 'string' &&
          typeof item.file === 'object' &&
          typeof item.fieldName === 'string'
        )
      ) {
        console.log('yyyyyyyyyyyyyyyyyyyyy');
        return false;
      }
      // }
      console.log('uuuuuuuuuuuuuuuuuuuuu');
      return true;
    } else {
      return false;
    }
  }


  async file_uploadForBulkUpload(object: any) {
    this.progressValue=80;
    return new Promise(async (resolve: any, reject: any) => {
      console.log('Inside file upload of bulk upload.');
      // console.log('this.clientNamesInfo', this.clientNamesInfo);
      console.log('this.myForm', this.myForm);
      let data: any = {
        action: 'file_upload',
        client_id: this.myForm.get('clientId').value,
        files: object['Agreement Amendment'].map((file: any) => {
          return { file_name: file.file_name, ext: file.ext };
        }),
      };
      let header = {
        Authorization: localStorage.getItem('t'),
        // 'X-Api-Key': localStorage.getItem('clientid'),
      };

      let apiURL = `${this.config.apiURL}/admin/client`;

      let result = await this.apiService.postDataPromis(apiURL, data, header);
      console.log('QQQQQQQQQQQQQresult', result);

      if (result.s == '1' || result.status == '1') {
        console.log(result);
        this.uploadFilesWhenBrowse(result.data, object['Agreement Amendment']);
        this.progressValue=100;
        this.progressBar = false;
      } else {
        this.notifier.alert('Info', '', result.error, 'info', 5000);
      }
      resolve(true);
    });
  }


  deleteDocumentForBulkUpload(){
    // !contentList[index]['Agreement Amendment'] &&
    //                     !updated['registration_plate_photo']
    delete this.contentList[this.index]['Agreement Amendment'];
    this.updated = {};
  }


  async onSubmitAllDataBulkUp(files?: any) {
    this.notifier.loading(true);
    for (const object of this.contentList) {
      this.validateUploadFiles(object)
    }
    try {
      console.log('-------------------before');
      console.log('All uploads completed successfully');
      console.log('this.contentList', this.contentList);
      this.bulkUpload(this.contentList);
    } catch (error) {
      console.error('Error during file upload:', error);
      this.notifier.alert(
        'Error',
        '',
        'Error during file upload',
        'error',
        5000
      );
    } finally {
      // this.notifier.loading(false); // Hide loading indicator regardless of upload success or failure
    }
  }

  showPopup(header: any, title: any) {
    this.popup_header = header;
    this.popup_subtitle = title;
    this.popUp = true;
  }


  hidepopup(event: any) {
    this.popUp = false;
  }



  // headers3: any = [
  //   { "id": "company_code", "name": "Company Code" },
  //   { "id": "client_id", "name": "Client ID" },
  //   { "id": "client_name", "name": "Client Name" },
  //   { "id": "client_location", "name": "Client Location" },
  //   { "id": "route_name", "name": "Route Name" },
  //   { "id": "shift_type", "name": "Shift Type" },
  //   { "id": "one_way_kms", "name": "One Way Kms" },
  //   { "id": "empty_kms", "name": "Empty Kms" },
  //   { "id": "working_days", "name": "Working Days" },
  //   { "id": "parking_kms", "name": "Parking Kms" },
  //   { "id": "total_kms", "name": "Total Kms" },
  //   { "id": "parking_location", "name": "Parking Location" },
  //   {
  //     "sub":"admin[0]","id":"admin_name","name":"Admin Name"
  //   },
  //   {
  //     "sub":"admin","id":"admin_contact_number","name":"Admin Contact Number"
  //   },
  //   {
  //     "sub":"admin","id":"admin_email","name":"Admin Email"
  //   },
  //   {
  //     "sub":"operations","id":"operations_name","name":"Operations Name"
  //   },
  //   {
  //     "sub":"operations","id":"operations_contact_number","name":"Operations Contact Number"
  //   },
  //   {
  //     "sub":"operations","id":"operations_email","name":"Operations Email"
  //   },
  //   { "id": "unit_billing", "name": "Unit Billing" }
  // ]
  


  // exportEach(maps: any) {
  //   this.notifier.loading(true);
  //   let client_detail: any;
  //   let data: any = [
  //     {
  //       sheetName: 'Clients',
  //       data: [
  //         this.headers3.map((h: any) => {
  //           return { text: h.name };
  //         }),
  //       ],
  //     },
  //   ];
  //   let sheetData: any = maps;
  //   let headers: any = this.headers3;

  //   sheetData.map((item: any) => {
  //     client_detail = item.client_routes;
  //     let data_: any = [];
  //     headers.forEach((h: any) => {
  //       // data_.push({ text: h.alter ? h.alter(item, h.id) : item[h.id] });
  //       if(h.sub){
  //         data_.push({ text: client_detail[h.sub][h.id] });
  //       }else{
  //         data_.push({ text: client_detail[h.id] });
  //       }
  //     });

  //     console.log('each data',data_);
  //     data[0]['data'].push(data_);
  //   });

  //   var options = {
  //     fileName: 'Client Routes Details',
  //   };
  //   console.log('data' + JSON.stringify(data));

  //   Jhxlsx.export(data, options);
  //   this.notifier.loading(false);
  // }


  

headers3 = [
    { id: "client_id", name: "Client ID" },
    { id: "client_details.client_name", name: "Client Name" },
    // { id: "client_details.client_email", name: "Client Email" },
    { id: "client_details.agreement_start_date", name: "Agreement Start Date" },
    { id: "client_details.agreement_renewal_date", name: "Agreement Renewal Date" },
    // { id: "client_details.client_status", name: "Client Status" },
    { id: "client_details.agreement_amendments_s3_path", name: "Agreement Amendments S3 Path" },
    { id: "client_details.agreement_amendments_comments", name: "Agreement Amendments Comments" },
    { id: "client_details.company_address", name: "Company Address" },
    { id: "client_details.unit_billing", name: "Unit Billing" },
    { id: "client_details.contact_method", name: "Contact Method" },
    { id: "client_details.working_days", name: "Working Days" },
    { id: "client_details.billing_period", name: "Billing Period" },
    { id: "client_details.package_details", name: "Package Details" },
    // { id: "client_details.payment_methods", name: "Payment Methods" },
    { id: "client_details.payments_terms", name: "Payments Terms" },
    { id: "client_details.assigned_company_manager_name", name: "Assigned Company Manager Name" },
    { id: "client_details.no_routes", name: "No of Routes" },
    { id: "client_details.no_buses", name: "No of Buses" },
    { id: "client_details.fuel_supplier", name: "Fuel Station" },
    { id: "client_details.garage_details", name: "Garage Details" },
    { id: "client_details.staff_details", name: "Staff Details" },
    { id: "client_details.vendor_email", name: "Vendor Email" },
    { id: "client_contact_details[0].contact_name", name: "Accounts & Finance Contact Name" },
    { id: "client_contact_details[0].contact_designation", name: "Accounts & Finance Contact Designation" },
    { id: "client_contact_details[0].contact_number", name: "Accounts & Finance Contact Number" },
    { id: "client_contact_details[0].contact_email", name: "Accounts & Finance Contact Email" },
    { id: "client_contact_details[1].contact_name", name: "Administration Contact Name" },
    { id: "client_contact_details[1].contact_designation", name: "Administration Contact Designation" },
    { id: "client_contact_details[1].contact_number", name: "Administration Contact Number" },
    { id: "client_contact_details[1].contact_email", name: "Administration Contact Email" },
    { id: "client_contact_details[2].contact_name", name: "HR Contact Name" },
    { id: "client_contact_details[2].contact_designation", name: "HR Contact Designation" },
    { id: "client_contact_details[2].contact_number", name: "HR Contact Number" },
    { id: "client_contact_details[2].contact_email", name: "HR Contact Email" },
    { id: "client_contact_details[3].contact_name", name: "Operations Contact Name" },
    { id: "client_contact_details[3].contact_designation", name: "Operations Contact Designation" },
    { id: "client_contact_details[3].contact_number", name: "Operations Contact Number" },
    { id: "client_contact_details[3].contact_email", name: "Operations Contact Email" },
  ];
  

exportEach(maps: any) {
  this.notifier.loading(true);

  let data: any = [
    {
      sheetName: 'Clients',
      data: [
        this.headers3.map((h: any) => {
          return { text: h.name };
        }),
      ],
    },
  ];

  let sheetData: any = maps;
  let headers: any = this.headers3;

  sheetData.forEach((item: any) => {
    let data_: any = [];
    headers.forEach((h: any) => {
      let value = this.getNestedValue(item, h.id);
      data_.push({ text: value ? value : '' });
    });
    data[0]['data'].push(data_);
  });

  var options = {
    fileName: 'Clients Details',
  };

  Jhxlsx.export(data, options);
  this.notifier.loading(false);
}

getNestedValue(obj: any, path: string): any {
  return path.split('.').reduce((acc, part) => {
    if (part.includes('[')) {
      const [base, index] = part.split(/\[|\]/).filter(Boolean);
      return acc && acc[base] ? acc[base][index] : undefined;
    }
    return acc ? acc[part] : undefined;
  }, obj);
}


async clientVehicleFetch(selectedClientId:any) {
  this.notifier.loading(true);

  let data: any = {
    action: 'client_vehicles',
    client_id: selectedClientId
  };
  let header = {
    Authorization: localStorage.getItem('t'),
    // 'X-Api-Key': localStorage.getItem('clientid'),
  };

  //https://api.swayam.cloud/v3/admin/users/fetchaccountgroupsv2
  let apiURL = `${this.config.apiURL}/admin/vehicles`;

  let result = await this.apiService.postDataPromis(apiURL, data, header);

  if (result.s == '1' || result.status == '1') {
    // console.log(result);

    this.clientVehicleResult = result.data;
    console.log('fetch in client vehicle', result);
    // this.exportVar = result.data;

    // this.notifier.alert('Success', '', result.data, 'success', 5000);
  } else {
    this.notifier.alert('Info', '', result.error, 'info', 5000);
  }
  this.notifier.loading(false);
}



headers5: any = [
  {
    id: 'client_id',
    name: 'Client ID',
  },
  {
    id: 'registration_number',
    name: 'Registration Number',
  },
  {
    id: 'registration_image_s3_path',
    name: 'Registration Image S3 Path',
  },
  {
    id: 'vehicle_name',
    name: 'Vehicle Name',
  },
  {
    id: 'vehicle_owner',
    name: 'Vehicle Owner',
  },
  {
    id: 'engine_type',
    name: 'Vehicle Model',
  },
  {
    id: 'vehicle_manufacturer',
    name: 'Vehicle Manufacturer',
  },
  {
    id: 'vehicle_manufacturer_year',
    name: 'Vehicle Manufacturer Year',
  },
  {
    id: 'vehicle_registration_date',
    name: 'Vehicle Registration Date',
  },
  {
    id: 'vehicle_registration_expiry_date',
    name: 'Vehicle Registration Expiry Date',
  },
  {
    id: 'vehicle_type',
    name: 'Vehicle Type',
  },
  {
    id: 'vehicle_color',
    name: 'Vehicle Color',
  },
  {
    id: 'vehicle_mode',
    name: 'Vehicle Mode',
  },
  {
    id: 'vehicle_status',
    name: 'Vehicle Status',
  },
  {
    id: 'ac_manufacturer',
    name: 'AC Manufacturer',
  },
  {
    id: 'seating_capacity',
    name: 'Seating Capacity',
  },
  {
    id: 'seating_type',
    name: 'Seating Type',
  },
  {
    id: 'fuel_type',
    name: 'Fuel Type',
  },
  {
    id: 'fuel_tank_capacity',
    name: 'Fuel Tank Capacity',
  },
  {
    id: 'fav',
    name: 'Favorite',
  },
  {
    id: 'compatible_tyre',
    name: 'Compatible Tyre',
  },
  {
    id: 'no_tyres',
    name: 'Number of Tyres',
  },
];

exportEach2(maps: any) {
  this.notifier.loading(true);
  let vech_detail: any;
  let data: any = [
    {
      sheetName: 'Vehicle Details',
      data: [
        this.headers5.map((h: any) => {
          return { text: h.name };
        }),
      ],
    },
  ];
  let sheetData: any = maps;
  let headers: any = this.headers5;

  sheetData.map((item: any) => {
    vech_detail = item.vehicles_details;
    let data_: any = [];
    headers.forEach((h: any) => {
      // data_.push({ text: h.alter ? h.alter(item, h.id) : item[h.id] });
      data_.push({ text: vech_detail[h.id] });
    });

    data[0]['data'].push(data_);
  });

  var options = {
    fileName: 'Vehicle Details',
  };
  console.log('data' + JSON.stringify(data));

  Jhxlsx.export(data, options);
  this.notifier.loading(false);
}

goBack(){
  this.modalMessage = 'Are you sure you want to discard the changes?';
  this.selectedTab = 'client-details';
}

clearMessage(){
  this.modalMessage = 'This will discarded the changes?';
}

// &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
sortTable(header: any, data: any[]) {
  const direction = header.sort.direction;
  const isAscending = direction === 'Asc';

  data.sort((a: any, b: any) => {
      let aValue = this.getNestedValueForSort(a, header.id);
      let bValue = this.getNestedValueForSort(b, header.id);

      if (aValue < bValue) {
          return isAscending ? -1 : 1;
      } else if (aValue > bValue) {
          return isAscending ? 1 : -1;
      } else {
          return 0;
      }
  });

  // Toggle sort direction
  header.sort.direction = isAscending ? 'Des' : 'Asc';
}

// Helper function to get the nested value
getNestedValueForSort(obj: any, path: string) {
  return path.split('.').reduce((acc, part) => {
      // Check for array indices in the path
      const match = part.match(/^(\w+)\[(\d+)\]$/);
      if (match) {
          const arrayKey = match[1];
          const index = parseInt(match[2], 10);
          return acc && acc[arrayKey] && acc[arrayKey][index] !== undefined ? acc[arrayKey][index] : undefined;
      }
      return acc && acc[part] !== undefined ? acc[part] : undefined;
  }, obj);
}

refershFunction(){
  this.pagination = {perPage: 10, page: 1, };
  this.pagination2 = {perPage: 10, page: 1, };
  this.fetchcomp()
}




}
