import { Component, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
//import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { APIService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { tokenExpired } from 'src/app/services/api.service';
// import * as $ from 'jquery';

declare var $: any;
declare var window: any;

export let internetActive: boolean;

export let regions: any = {
  enabled: [],
  disabled: [],
};


export interface CookieOptions {
  expires?: number | Date;
  path?: string;
  domain?: string;
  secure?: boolean;
}

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent {

  isOpen: boolean = false;
  userId = localStorage.getItem('eId');
  username = localStorage.getItem('un');
  cbsAccess: boolean = false;
  started: boolean = false;
  showITAS: boolean = false;
  masterEmailId: string | null = localStorage.getItem('masterId');
  currentPageLink: string | null = null;
  hideAccountTab: boolean = true;
  subType: string = localStorage.getItem('subType') || 'freetrial';
  isUserAdmin: boolean = localStorage.getItem('isUserAdmin') == '1';
  config: any;
  hamMenu: boolean = false;
  menuList = JSON.parse(localStorage.getItem('menuList') ?? '{}');



  currentRoute: string | null = null;

  constructor(
    private router: Router,
    private apiServer: APIService,
    private notifier: NotifierService,
    private route: ActivatedRoute,
    private renderer: Renderer2
  ) {
    this.config = this.route.snapshot.data['config'];
  }

  showExpired: boolean = false;

  dashServiveMessage: any;

  token: any;


  async ngOnInit() {

    console.log("tokenExpired", tokenExpired);

    this.token = tokenExpired;
    console.log("this.token", this.token);
    // this.notifier.loading(true);

    // window.addEventListener("online", () => {
    //   alert("Internet is active");
    //   internetActive = true;
    // });
    // window.addEventListener("offline", () => {
    //   alert("Internet is down");
    //   internetActive = false;
    // });

    if (localStorage.getItem('accountStatus') == 'Inactive') {
      return false;
    }
    // this.fetchRecentSearch();
    // if (this.ut == 'user') {
    //   this.urlPrefix = 'client';
    // }

    this.started = true;

    // await this.loadHeader();
    this.notifier.changeAccountIdNotification('1234567890');
    //localStorage.setItem('agl', JSON.stringify(this.agl));
    // if (!localStorage.getItem('selectedRegion')) {
    //   localStorage.setItem('selectedRegion', this.regions[0].name);
    //   localStorage.setItem('selectedRegionId', this.regions[0].id);
    //   localStorage.setItem('regionId', this.regions[0].id);
    //   this.notifier.changeRegionNotification(
    //     localStorage.getItem('selectedRegionId')
    //   );
    // } else {
    //   this.notifier.changeRegionNotification(
    //     localStorage.getItem('selectedRegionId')
    //   );
    // }
    return true;
  }

  // async copyToClipboard(event: any, text: string) {
  //   event.preventDefault();
  //   event.stopPropagation();
  //   if (navigator.clipboard?.writeText) {
  //     try {
  //       await navigator.clipboard.writeText(text);
  //     } catch (e) {
  //       console.error('Error while copying code', e);
  //     }
  //   }
  // }


  redirectToLogin() {
    // Logic to redirect to login page
    localStorage.clear()
    window.location.href = '/auth/login';
  }


  async logout() {
    this.notifier.loading(true);
    let data = {
      a: 'logout',
      userId: this.userId || localStorage.getItem('eId'),
      email: this.userId || localStorage.getItem('eId'),
      stoken: localStorage.getItem('t')
    };

    let header = {
      Authorization: localStorage.getItem('t'),
      // 'X-Api-Key': localStorage.getItem('clientid'),
    };

    //https://api.swayam.cloud/v3/admin/users/fetchaccountgroupsv2
    let apiURL = `${this.config.apiURL}/admin/Settings/usermanagement`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.notifier.alert('Success', '', result.msg, 'success', 5000);
    }

    localStorage.clear();
    setTimeout(() => {
      this.router.navigateByUrl('/auth/login');
      this.notifier.loading(false);
    }, 100);
  }

  hideLeftMenu() {
    $('.leftmenu').addClass('leftmenuhidden');
    $('.rightcontent').addClass('leftmenuhidden');
    $('#menuClosebtn').hide();
    $('#menuShowbtn').show();
  }

  showLeftMenu() {
    $('.leftmenu').removeClass('leftmenuhidden');
    $('.rightcontent').removeClass('leftmenuhidden');
    $('#menuClosebtn').show();
    $('#menuShowbtn').hide();
  }


  toggleSidenav() {
    this.isOpen = !this.isOpen;
  }

  togglehamburgerMenu() {
    this.hamMenu = !this.hamMenu;
  }


}
