<ng-container *ngIf="addcom || isEditMode">
  <div class="flex-start ml-4 mt-3" *ngIf="!showModal">
    <button class="btn btn-light" data-bs-toggle="modal"
    data-bs-target="#staticBackdrop" (click)="goBack()">
      <i class="fa fa-arrow-left" aria-hidden="true"></i>
    </button>
    <h3 class="mb-0 pb-0 font-weight-bold">
      {{ isEditMode ? "Update Client" : "New Client" }}
    </h3>
  </div>
  <div class="flex-start ml-4 mt-3" *ngIf="showModal">
    <button class="btn btn-light" (click)="clearForm()">
      <i class="fa fa-arrow-left " aria-hidden="true"></i>
    </button>
    <h4 class="mb-0 pb-0 font-weight-bold">
      {{ "Client Details" }}
    </h4>
  </div>
  <div class="card">
    <form [formGroup]="myForm" (ngSubmit)="onSubmit()" autocomplete="off">
      <!-- Company Information -->
      
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item">
          <a
            class="nav-link"
            id="client-details-tab"
            (click)="onTabChange('client-details')"
            [class.active]="selectedTab === 'client-details'"
            data-toggle="tab"
            role="tab"
            aria-controls="client-details"
            aria-selected="selectedTab === 'client-details'"
            >Client Details</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            id="administration-details-tab"
            (click)="onTabChange('administration-details')"
            [class.active]="selectedTab === 'administration-details'"
            data-toggle="tab"
            role="tab"
            aria-controls="administration-details"
            aria-selected="selectedTab === 'administration-details'"
            >Administration Details</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            id="operations-details-tab"
            (click)="onTabChange('operations-details')"
            [class.active]="selectedTab === 'operations-details'"
            data-toggle="tab"
            role="tab"
            aria-controls="operations-details"
            aria-selected="selectedTab === 'operations-details'"
            >Operation Details</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            id="hr-details-tab"
            (click)="onTabChange('hr-details')"
            [class.active]="selectedTab === 'hr-details'"
            data-toggle="tab"
            role="tab"
            aria-controls="hr-details"
            aria-selected="selectedTab === 'hr-details'"
            >HR Details</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            id="account-details-tab"
            (click)="onTabChange('account-details')"
            [class.active]="selectedTab === 'account-details'"
            data-toggle="tab"
            role="tab"
            aria-controls="account-details"
            aria-selected="selectedTab === 'account-details'"
            >Account&Finance Details</a
          >
        </li>
      </ul>

      <!-- <div *ngIf="selectedTab==='client-details'"> -->
      <div [ngStyle]="{display: selectedTab === 'client-details'? 'block' : 'none'}">
        <div class="card-body">


          <div class="row mt-2">
            <div class="col-4">
              <div class="form-group mb-2 fl">
                <label class="label-color" for="clientName">Client Name*</label>
                <input
                  type="text"
                  class="inputOfForm"
                  id="clientName"
                  formControlName="clientName"
                  autocomplete="nope"
                />
                <div
                *ngIf="
                    myForm.get('clientName')?.invalid &&
                    myForm.get('clientName')?.touched
                  "
                >
                  <span
                  *ngIf="myForm.get('clientName')?.errors?.['required']"
                    class="error-message"
                    >Client Name is required</span
                  >
                  <span
                  *ngIf="myForm.get('clientName')?.errors?.['maxlength']"
                    class="error-message"
                    >Max length of 100.</span
                  >
                </div>
              </div>
            </div>
      
            <div class="col-4">
              <div class="form-group mb-2 fl">
                <label class="label-color" for="clientId">Client ID*</label>
                <input
                  type="text"
                  class="inputOfForm"
                  id="clientId"
                  formControlName="clientId"
                  autocomplete="off"
                />
                <div
                *ngIf="
                    myForm.get('clientId')?.invalid && myForm.get('clientId')?.touched
                  "
                >
                  <span class="error-message">Client Id is required</span>
                </div>
              </div>
            </div>

            <div class="col-4">
              <div class="form-group mb-2 fl">
                <label class="label-color" for="agreementStartDate">Agreement Start Date</label>
                <input
                  type="date"
                  class="inputOfForm"
                  id="agreementStartDate"
                  formControlName="agreementStartDate"
                  (change)="
                    updateEndDateMin('agreementStartDate', 'agreementRenewalDate')
                  "
                />
              </div>
            </div>
            
            
          </div>
          
          <div class="row mt-2">
            <div class="col-4">
              <div class="form-group mb-2 fl">
                <label class="label-color" for="agreementRenewalDate">Agreement Renewal Date</label>
                <input
                  type="date"
                  class="inputOfForm"
                  id="agreementRenewalDate"
                  formControlName="agreementRenewalDate"
                  [min]="myForm.get('agreementStartDate')?.value"
                />
              </div>
            </div>
  
            <div class="col-4" *ngIf="!bulkFileUpload">
              <div class="form-group mb-2 fl">
                <label class="label-color" for="agreementAmendments">Agreement Amendments</label>
                <span>
                <input
                  type="file"
                  class="upldFile"
                  id="agreementamendment"
                  style="display: none;"
                  (change)="
                    onFileSelected(
                      'agreementamendment',
                      'client_agreement_amendment',
                      $event
                    );
                    agreementTouched = true
                  "
                  #agreementIn
                />
                <button
                      *ngIf="
                        (agreementIn.files || []).length == 0 &&
                        (!updated['client_agreement_amendment'] && fileNames.length == 0)
                      "
                      class="btn btn-primary mr-l-10"
                      type="button"
                      (click)="initiateAgreementIn()"
                    >
                      <i class="fa-solid fa-magnifying-glass mrgn-r-10"></i
                      >Browse File
                    </button>

                    <div *ngIf="!updateForm">
                      <div *ngIf="(agreementIn.files || []).length > 0 && updated['client_agreement_amendment'] && progressBar">
                        <div class="progress">
                          <div class="progress-bar" role="progressbar"  aria-valuemin="0" aria-valuemax="100" [style.width.%]="progressValue">
                          </div>
                          <p class="upldTxt">uploading...</p>
                        </div>
                      </div>
                      <div class="button-container" *ngIf="(agreementIn.files || []).length > 0 && updated['client_agreement_amendment'] && !progressBar">
                        <span class="del" (click)="deleteDocument()">Delete</span>
                        <div class="line"></div>
                        <span class="pre" (click)="previewDocument(agreementIn, 'client_agreement_amendment')">Preview</span>
                      </div>
                    </div>
                </span>
                
                <div
                  *ngIf="
                    addcom &&
                    (submitted || agreementTouched) &&
                    agreementIn.files?.length == 0 &&
                    !isEditMode
                  "
                >
                  <span class="error-message">Please upload document.</span>
                </div>
                <span
                  *ngIf="documentInputErrors['agreementamendment']"
                  class="error-message"
                  >{{ documentInputErrors["agreementamendment"] }}</span
                >
                  <div *ngIf="updateForm">
                    <div *ngIf="updated['client_agreement_amendment'] || fileNames?.length > 0">
                      <div *ngIf="progressBar">
                        <div class="progress">
                          <div class="progress-bar" role="progressbar"  aria-valuemin="0" aria-valuemax="100" [style.width.%]="progressValue">
                          </div>
                          <p class="upldTxt">uploading...</p>
                        </div>
                      </div>
                      <div class="button-container" *ngIf="!progressBar">
                        <span class="del" (click)="deleteDocumentInUpdate()">Delete</span>
                        <div class="line"></div>
                        <span class="pre" (click)="previewDocument(agreementIn, 'client_agreement_amendment')">Preview</span>
                      </div>
                    </div>
                  </div>
                <div
                  *ngIf="
                    !isEditMode &&
                    myForm.get('agreementamendment')?.invalid &&
                    myForm.get('agreementamendment')?.touched
                  "
                >
                  <span class="error-message">Upload Licence</span>
                </div>
              </div>
            </div>

            
            <div class="col-4" *ngIf="bulkFileUpload">
              <div class="form-group mb-2 fl">
                <label class="label-color" for="agreementAmendments">Agreement Amendments</label>
                  
                <ng-container *ngIf="isEditMode && bulkFileUpload">
                  <input
                    class="upldFile"
                    type="file"
                    id="agreementamendment"
                    (change)="
                      onFileSelectedForBulkUpload(
                        'agreementamendment',
                        'client_agreement_amendment',
                        $event
                      );
                      agreementTouched = true
                    "
                    #agreementIn
                  />
                  <button
                    *ngIf="
                      !contentList[index]['Agreement Amendment'] &&
                      !updated['client_agreement_amendment']
                    "
                    class="btn btn-primary mr-l-10 width-50"
                    type="button"
                    (click)="initiateAgreementIn()"
                  >
                    <i class="fa-solid fa-magnifying-glass mrgn-r-10"></i
                    >Browse File
                  </button>
                  <span
                    *ngIf="updated['client_agreement_amendment']"
                  >
                    
                    <div *ngIf="updated['client_agreement_amendment'] && progressBar">
                      <div class="progress">
                        <div class="progress-bar" role="progressbar"  aria-valuemin="0" aria-valuemax="100" [style.width.%]="progressValue">
                        </div>
                        <p class="upldTxt">uploading...</p>
                      </div>
                    </div>
                    <div class="button-container" *ngIf="updated['client_agreement_amendment'] && !progressBar">
                      <span class="del" (click)="deleteDocumentForBulkUpload()">Delete</span>
                      <span>|</span>
                      <span class="pre" (click)="previewDocument(agreementIn, 'client_agreement_amendment')">Preview</span>
                    </div>
                  </span>
                  
                  <div class="button-container" *ngIf="contentList[index]['Agreement Amendment'] && 
                  !updated['client_agreement_amendment'] && !progressBar">
                    <span class="del" (click)="deleteDocumentForBulkUpload()">Delete</span>
                    <span>|</span>
                    <span class="pre" (click)="previewDocument(agreementIn, 'client_agreement_amendment')">Preview</span>
                  </div>
                </ng-container>
                <div
                  *ngIf="
                    addcom &&
                    (submitted || agreementTouched) &&
                    agreementIn.files?.length == 0 &&
                    !isEditMode
                  "
                >
                </div>
              </div>
            </div>

            <div class="col-4">
              <div class="form-group mb-2 fl">
                <label class="label-color" for="companyAddress">Company/ Unit Address*</label>
                <input
                  type="text"
                  class="inputOfForm"
                  id="companyAddress"
                  formControlName="companyAddress"
                  autocomplete="nope"
                />
                <div
                *ngIf="
                    myForm.get('companyAddress')?.invalid &&
                    myForm.get('companyAddress')?.touched
                  "
                >
                  <span
                  *ngIf="myForm.get('companyAddress')?.errors?.['required']"
                    class="error-message"
                    >Company Address is required</span
                  >
                  <span
                  *ngIf="myForm.get('companyAddress')?.errors?.['maxlength']"
                    class="error-message"
                    >Max length of 100.</span
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-4">
              <div class="form-group mb-2 fl">
                <label class="label-color" for="companyBillingAddress"
                  >Company/ Unit Billing Address</label
                >
                <input
                  type="text"
                  class="inputOfForm"
                  id="companyBillingAddress"
                  formControlName="companyBillingAddress"
                  autocomplete="nope"
                />
              </div>
            </div>

            <div class="col-4">
              <div class="form-group mb-2 fl">
                <label class="label-color" for="vendor_email">Vendor Email*</label>
                <input type="text" style="display: none" />
                <input
                  type="text"
                  class="inputOfForm"
                  id="vendor_email"
                  formControlName="vendor_email"
                  autocomplete="nope"
                />
                <div
                *ngIf="
                    myForm.get('vendor_email')?.invalid &&
                    myForm.get('vendor_email')?.touched
                  "
                >
                  <span
                  *ngIf="myForm.get('vendor_email')?.errors?.['required']"
                    class="error-message"
                    >Vendor Email is required</span
                  >
                  <span
                  *ngIf="myForm.get('vendor_email')?.errors?.['email']"
                    class="error-message"
                    >Email os not valid.</span
                  >
                </div>
              </div>
            </div>

            <div class="col-4">
              <div class="form-group mb-2 fl">
                <label for="preff_cont" class="label-color"
                  >Preferred Contact*</label
                >
                <select
                  id="preff_cont"
                  formControlName="preff_cont"
                  class="form-control slectInForm"
                  multiple
                  data-live-search="true"
                  placeholder="Please Select"
                >
                  <!-- <option *ngFor="let code of ['whatsapp', 'Email', 'Office number']" [attr.value]="code">
                    {{ code }}
                  </option> -->
                  <option [attr.value]="'whatsapp'">Whatsapp</option>
                  <option [attr.value]="'Email'">Email</option>
                  <option [attr.value]="'Office number'">Office number</option>
                </select>
                <div
                  *ngIf="
                    myForm.get('preff_cont')?.invalid &&
                    myForm.get('preff_cont')?.touched
                  "
                  class="error-message"
                >
                Preferred Contact is mandatory
                </div>
              </div>
            </div>

          </div>

          <div class="row mt-2">
            <div class="col-4">
              <div class="form-group mb-2 fl">
                <label class="label-color" for="no_of_wrkdays">No of Working Days*</label>
                <input
                  type="text"
                  class="inputOfForm"
                  id="no_of_wrkdays"
                  formControlName="no_of_wrkdays"
                  autocomplete="off"
                />
                <div
                *ngIf="
                    myForm.get('no_of_wrkdays')?.invalid &&
                    myForm.get('no_of_wrkdays')?.touched
                  "
                >
                  <span
                    class="error-message"
                    *ngIf="myForm.get('no_of_wrkdays')?.errors?.['required']"
                    >No of Working Days is required</span
                  >
                  <span
                    class="error-message"
                    *ngIf="myForm.get('no_of_wrkdays')?.errors?.['pattern']"
                    >Only Numbers are allowed.</span
                  >
                </div>
              </div>
            </div>
            
            <div class="col-4">
              <div class="form-group mb-2 fl">
                <label class="label-color" for="billing_per_from">Billing Period From*</label>
                <input
                  type="date"
                  class="inputOfForm"
                  id="billing_per_from"
                  formControlName="billing_per_from"
                  (change)="updateEndDateMin('billing_per_from', 'billing_per_to')"
                />
                <div
                *ngIf="
                    myForm.get('billing_per_from')?.invalid &&
                    myForm.get('billing_per_from')?.touched
                  "
                >
                  <span class="error-message">Billing Period From is required</span>
                </div>
              </div>
            </div>

            <div class="col-4">
              <div class="form-group mb-2 fl">
                <label class="label-color" for="billing_per_to">Billing Period To*</label>
                <input
                  type="date"
                  class="inputOfForm"
                  id="billing_per_to"
                  formControlName="billing_per_to"
                  [min]="myForm.get('billing_per_from')?.value"
                />
                <div
                *ngIf="
                    myForm.get('billing_per_to')?.invalid &&
                    myForm.get('billing_per_to')?.touched
                  "
                >
                  <span class="error-message">Billing Period To is required</span>
                </div>
              </div>
            </div>
          </div>
          
          <div class="row mt-2">
            <div class="col-4">
              <div class="form-group mb-2 fl">
                <label class="label-color" for="package_det">Package Details*</label>
                <select formControlName="package_det" class="inputOfForm">
                  <option value="kms">Km’s</option>
                  <option value="month">Month Wise</option>
                  <option value="days">Days Wise</option>
                </select>
                <div
                *ngIf="
                    myForm.get('package_det')?.invalid &&
                    myForm.get('package_det')?.touched
                  "
                >
                  <span class="error-message">Package Details is required</span>
                </div>
              </div>
            </div>

            <div class="col-4">
              <div class="form-group mb-2 fl">
                <label class="label-color" for="pay_teerms">Payment Terms*</label>
                <select id="pay_teerms" formControlName="pay_teerms" class="inputOfForm">
                  <option value="Immediate">Immediate</option>
                  <option value="7 Days">7 Days</option>
                  <option value="15 Days">15 Days</option>
                  <option value="30 Days">30 Days</option>
                </select>
                <div
                *ngIf="
                    myForm.get('pay_teerms')?.invalid &&
                    myForm.get('pay_teerms')?.touched
                  "
                >
                  <span class="error-message">Payment Terms is required</span>
                </div>
              </div>
            </div>

            <div class="col-4">
              <div class="form-group mb-2 fl">
                <label class="label-color" for="un_flmn_name">Assigned Company/Unit Fleet Manager</label>
                <input
                  type="text"
                  class="inputOfForm"
                  id="un_flmn_name"
                  formControlName="un_flmn_name"
                  autocomplete="nope"
                />
                <div
                *ngIf="
                    myForm.get('un_flmn_name')?.invalid &&
                    myForm.get('un_flmn_name')?.touched
                  "
                >
                  <span class="error-message"> Name is required</span>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-4">
              <div class="form-group mb-2 fl">
                <label class="label-color" for="no_of_routes">Number of Routes*</label>
      
                <input
                  type="number"
                  class="inputOfForm"
                  min="0"
                  id="no_of_routes"
                  formControlName="no_of_routes"
                  autocomplete="off"
                />
                <div
                *ngIf="
                    myForm.get('no_of_routes')?.invalid &&
                    myForm.get('no_of_routes')?.touched
                  "
                >
                  <span class="error-message">Number of Routes is required</span>
                  <span
                    class="error-message"
                    *ngIf="myForm.get('no_of_routes')?.errors?.['pattern']"
                    >Only Numbers
                  </span>
                </div>
              </div>
            </div>
  
            <div class="col-4">
              <div class="form-group mb-2 fl">
                <label class="label-color" for="no_of_buses">Total Count of Buses*</label>
                <input
                  type="number"
                  class="inputOfForm"
                  id="no_of_buses"
                  formControlName="no_of_buses"
                  autocomplete="off"
                  readonly
                />
                <div
                *ngIf="
                    myForm.get('no_of_buses')?.invalid &&
                    myForm.get('no_of_buses')?.touched
                  "
                >
                  <span class="error-message">Number of Buses is required</span>
                  <span
                    class="error-message"
                    *ngIf="myForm.get('no_of_buses')?.errors?.['pattern']"
                    >Only Numbers
                  </span>
                </div>
              </div>
            </div>

            <div class="col-4">
              <div class="form-group mb-2 fl">
                <label class="label-color" for="staf_det">All Staff Details*</label>
                <input
                  type="text"
                  class="inputOfForm"
                  id="staf_det"
                  formControlName="staf_det"
                  autocomplete="nope"
                />
                <div
                *ngIf="
                    myForm.get('staf_det')?.invalid &&
                    myForm.get('staf_det')?.touched
                  "
                >
                  <span class="error-message">Staff details are required</span>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-2">
            
            <div class="col-4">
              <div class="form-group mb-2 fl">
                <label class="label-color" for="fuel_sup">Fuel Station</label>
      
                <input
                  type="text"
                  class="inputOfForm"
                  id="fuel_sup"
                  formControlName="fuel_sup"
                  autocomplete="off"
                />
                <div
                *ngIf="
                    myForm.get('fuel_sup')?.invalid && myForm.get('fuel_sup')?.touched
                  "
                >
                  <span class="error-message">Fuel Station is required</span>
                </div>
              </div>
            </div>
  
            <div class="col-4">
              <div class="form-group mb-2 fl">
                <label class="label-color" for="garage_det">Garage Details</label>
                <input
                  type="text"
                  class="inputOfForm"
                  id="garage_det"
                  formControlName="garage_det"
                  autocomplete="off"
                />
                <div
                *ngIf="
                    myForm.get('garage_det')?.invalid &&
                    myForm.get('garage_det')?.touched
                  "
                >
                  <span class="error-message">Garage Details is required</span>
                </div>
              </div>
            </div>


            <div class="col-4" *ngIf="isEditMode && !bulkFileUpload">
              <div class="form-group mb-2 fl">
                <label class="label-color" for="sttus">Status</label>
                <select id="sttus" formControlName="sttus" class="inputOfForm">
                  <option value="Active" selected>Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
              </div>
            </div>

          </div>
        </div> 
      </div>      
        

      <!-- <div *ngIf="selectedTab==='administration-details'"> -->
      <div [ngStyle]="{display: selectedTab === 'administration-details'? 'block' : 'none'}">
        <h5 class="card-header" >Administration Contact Details</h5>
        <div class="card-body">
          <div class="row mt-2">
            <div class="col-4">
              <div class="form-group mb-2 fl">
                <label class="label-color" for="adm_op_Name">Name*</label>
                <input
                  type="text"
                  class="inputOfForm"
                  id="adm_op_Name"
                  formControlName="adm_op_Name"
                  autocomplete="nope"
                />
                <div
                  *ngIf="
                    myForm.get('adm_op_Name')?.invalid &&
                    myForm.get('adm_op_Name')?.touched
                  "
                >
                  <span class="error-message"
                    >Administrative Operations Name is required</span
                  >
                </div>
              </div>
            </div>

            <div class="col-4">
              <div class="form-group mb-2 fl">
                <label class="label-color" for="adm_op_designation">Designation*</label>
                <input
                  type="text"
                  class="inputOfForm"
                  id="adm_op_designation"
                  formControlName="adm_op_designation"
                  autocomplete="off"
                />
                <div
                  *ngIf="
                    myForm.get('adm_op_designation')?.invalid &&
                    myForm.get('adm_op_designation')?.touched
                  "
                >
                  <span class="error-message"
                    >Administrative Operations Designation is required</span
                  >
                </div>
              </div>
            </div>

            <div class="col-4">
              <div class="form-group mb-2 fl">
                <label class="label-color" for="adm_op_Cont_Number">Contact Number*</label>
                <input
                  type="text"
                  class="inputOfForm"
                  id="adm_op_Cont_Number"
                  formControlName="adm_op_Cont_Number"
                  autocomplete="nope"
                  maxlength="10"
                />
                <div
                  *ngIf="
                    myForm.get('adm_op_Cont_Number')?.invalid &&
                    myForm.get('adm_op_Cont_Number')?.touched
                  "
                >
                  <span
                    class="error-message"
                    *ngIf="myForm.get('adm_op_Cont_Number')?.errors?.['required']"
                    >Administrative Operations Contact Number is required</span
                  >
                  <span
                    class="error-message"
                    *ngIf="myForm.get('adm_op_Cont_Number')?.errors?.['pattern']"
                    >Please provide correct number
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-4">
              <div class="form-group mb-2 fl">
                <label class="label-color" for="adm_op_email">Email*</label>
                <input
                  type="email"
                  class="inputOfForm"
                  id="adm_op_email"
                  formControlName="adm_op_email"
                  autocomplete="nope"
                />
                <div
                  *ngIf="
                    myForm.get('adm_op_email')?.invalid &&
                    myForm.get('adm_op_email')?.touched
                  "
                >
                  <span
                    class="error-message"
                    *ngIf="myForm.get('adm_op_email')?.errors?.['required']"
                    >Email is required</span
                  >
                  <span
                    class="error-message"
                    *ngIf="myForm.get('adm_op_email')?.errors?.['email']"
                    >Email is not valid required</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div *ngIf="selectedTab==='operations-details'"> -->
      <div [ngStyle]="{display: selectedTab === 'operations-details'? 'block' : 'none'}">
        <h5 class="card-header">Operations Contact Details</h5>
        <div class="card-body">
          <div class="row mt-2">
            <div class="col-4">
              <div class="form-group mb-2 fl">
                <label class="label-color" for="op_Name">Name*</label>
                <input
                  type="text"
                  class="inputOfForm"
                  id="op_Name"
                  formControlName="op_Name"
                  autocomplete="nope"
                />
                <div
                  *ngIf="
                    myForm.get('op_Name')?.invalid && myForm.get('op_Name')?.touched
                  "
                >
                  <span class="error-message">Operations Name is required</span>
                </div>
              </div>
            </div>

            <div class="col-4">
              <div class="form-group mb-2 fl">
                <label class="label-color" for="op_designation">Designation*</label>
                <input
                  type="text"
                  class="inputOfForm"
                  id="op_designation"
                  formControlName="op_designation"
                  autocomplete="off"
                />
                <div
                  *ngIf="
                    myForm.get('op_designation')?.invalid &&
                    myForm.get('op_designation')?.touched
                  "
                >
                  <span class="error-message"
                    >Operations Designation is required</span
                  >
                </div>
              </div>
            </div>

            <div class="col-4">
              <div class="form-group mb-2 fl">
                <label class="label-color" for="op_Cont_Number">Contact Number*</label>
                <input
                  type="text"
                  class="inputOfForm"
                  id="op_Cont_Number"
                  formControlName="op_Cont_Number"
                  autocomplete="nope"
                  maxlength="10"
                />
                <div
                  *ngIf="
                    myForm.get('op_Cont_Number')?.invalid &&
                    myForm.get('op_Cont_Number')?.touched
                  "
                >
                  <span
                    class="error-message"
                    *ngIf="myForm.get('op_Cont_Number')?.errors?.['required']"
                    >Operations Contact Number is required</span
                  >
                  <span
                    class="error-message"
                    *ngIf="myForm.get('op_Cont_Number')?.errors?.['pattern']"
                    >Please provide correct number
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-4">
              <div class="form-group mb-2 fl">
                <label class="label-color" for="op_email">Email*</label>
                <input
                  type="email"
                  class="inputOfForm"
                  id="op_email"
                  formControlName="op_email"
                  autocomplete="off"
                  autocomplete="nope"
                />
                <div
                  *ngIf="
                    myForm.get('op_email')?.invalid && myForm.get('op_email')?.touched
                  "
                >
                  <span
                    class="error-message"
                    *ngIf="myForm.get('op_email')?.errors?.['required']"
                    >Email is required</span
                  >
                  <span
                    class="error-message"
                    *ngIf="myForm.get('op_email')?.errors?.['email']"
                    >Email is not valid</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div *ngIf="selectedTab==='hr-details'"> -->
      <div [ngStyle]="{display: selectedTab === 'hr-details'? 'block' : 'none'}">
        <h5 class="card-header">HR Contact Details</h5>
        <div class="card-body">
          <div class="row mt-2">
            <div class="col-4">
              <div class="form-group mb-2 fl">
                <label class="label-color" for="hr_name">Name*</label>
                <input
                  type="text"
                  class="inputOfForm"
                  id="hr_name"
                  formControlName="hr_name"
                  autocomplete="nope"
                />
                <div
                  *ngIf="
                    myForm.get('hr_name')?.invalid && myForm.get('hr_name')?.touched
                  "
                >
                  <span class="error-message">HR Name is required</span>
                </div>
              </div>
            </div>

            <div class="col-4">
              <div class="form-group mb-2 fl">
                <label class="label-color" for="hr_desg">Designation*</label>
                <input
                  type="text"
                  class="inputOfForm"
                  id="hr_desg"
                  formControlName="hr_desg"
                  autocomplete="off"
                />
                <div
                  *ngIf="
                    myForm.get('hr_desg')?.invalid && myForm.get('hr_desg')?.touched
                  "
                >
                  <span class="error-message">HR Designation is required</span>
                </div>
              </div>
            </div>

            <div class="col-4">
              <div class="form-group mb-2 fl">
                <label class="label-color" for="hr_cont">Contact Number*</label>
                <input
                  type="text"
                  class="inputOfForm"
                  id="hr_cont"
                  formControlName="hr_cont"
                  autocomplete="nope"
                  maxlength="10"
                />
                <div
                  *ngIf="
                    myForm.get('hr_cont')?.invalid && myForm.get('hr_cont')?.touched
                  "
                >
                  <span
                    class="error-message"
                    *ngIf="myForm.get('hr_cont')?.errors?.['required']"
                    >HR Contact Number is required</span
                  >
                  <span
                    class="error-message"
                    *ngIf="myForm.get('hr_cont')?.errors?.['pattern']"
                    >Please provide correct number
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-4">
              <div class="form-group mb-2 fl">
                <label class="label-color" for="hr_email">Email*</label>
                <input
                  type="email"
                  class="inputOfForm"
                  id="hr_email"
                  formControlName="hr_email"
                  autocomplete="nope"
                />
                <div
                  *ngIf="
                    myForm.get('hr_email')?.invalid && myForm.get('hr_email')?.touched
                  "
                >
                  <span
                    class="error-message"
                    *ngIf="myForm.get('hr_email')?.errors?.['required']"
                    >HR Email is required</span
                  >
                  <span
                    class="error-message"
                    *ngIf="myForm.get('hr_email')?.errors?.['email']"
                    >Email is not Valid</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div *ngIf="selectedTab==='account-details'"> -->
      <div [ngStyle]="{display: selectedTab === 'account-details'? 'block' : 'none'}">
        <h5 class="card-header">Accounts & Finance</h5>
        <div class="card-body">
          <div class="row mt-2">
            <div class="col-4">
              <div class="form-group mb-2 fl">
                <label class="label-color" for="acc_fin_name">Name*</label>
                <input
                  type="text"
                  class="inputOfForm"
                  id="acc_fin_name"
                  formControlName="acc_fin_name"
                  autocomplete="nope"
                />
                <div
                  *ngIf="
                    myForm.get('acc_fin_name')?.invalid &&
                    myForm.get('acc_fin_name')?.touched
                  "
                >
                  <span class="error-message"
                    >Accounts and Finance Name is required</span
                  >
                </div>
              </div>
            </div>

            <div class="col-4">
              <div class="form-group mb-2 fl">
                <label class="label-color" for="acc_fin_desig">Designation*</label>
                <input
                  type="text"
                  class="inputOfForm"
                  id="acc_fin_desig"
                  formControlName="acc_fin_desig"
                  autocomplete="off"
                />
                <div
                  *ngIf="
                    myForm.get('acc_fin_desig')?.invalid &&
                    myForm.get('acc_fin_desig')?.touched
                  "
                >
                  <span class="error-message"
                    >Accounts and Finance Designation is required</span
                  >
                </div>
              </div>
            </div>

            <div class="col-4">
              <div class="form-group mb-2 fl">
                <label class="label-color" for="acc_fin_cont">Contact Number*</label>
                <input
                  type="text"
                  class="inputOfForm"
                  id="acc_fin_cont"
                  formControlName="acc_fin_cont"
                  autocomplete="nope"
                  maxlength="10"
                />
                <div
                  *ngIf="
                    myForm.get('acc_fin_cont')?.invalid &&
                    myForm.get('acc_fin_cont')?.touched
                  "
                >
                  <span
                    class="error-message"
                    *ngIf="myForm.get('acc_fin_cont')?.errors?.['required']"
                    >Accounts and Finance Contact Number is required</span
                  >
                  <span
                    class="error-message"
                    *ngIf="myForm.get('acc_fin_cont')?.errors?.['pattern']"
                    >Please provide correct number
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-4">
              <div class="form-group mb-2 fl">
                <label class="label-color" for="acc_fin_email">Email*</label>
                <input
                  type="email"
                  class="inputOfForm"
                  id="acc_fin_email"
                  formControlName="acc_fin_email"
                  autocomplete="nope"
                />
                <div
                  *ngIf="
                    myForm.get('acc_fin_email')?.invalid &&
                    myForm.get('acc_fin_email')?.touched
                  "
                >
                  <span
                    class="error-message"
                    *ngIf="myForm.get('acc_fin_email')?.errors?.['required']"
                    >Email is required</span
                  >
                  <span
                    class="error-message"
                    *ngIf="myForm.get('acc_fin_email')?.errors?.['email']"
                    >Email is not valid</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="margin-left mb-5" style="right: 0" *ngIf="!showModal">
          <div>
            <button
              class="btn m-6 clrbttn"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop"
              (click)="clearMessage()"
            >
              Clear
            </button>
          </div>
          <div>
            <button class="btn svbtn m-6" type="submit">
              {{ isEditMode ? "Update" : "Submit" }}
            </button>
          </div>
        </div>
      </div>
    </form>
    <div class="tab-navigation-buttons">
      <button  [disabled]="isFirstTab()"  (click)="prevTab()"><i class="fa-solid fa-chevron-left"></i>&nbsp;&nbsp;Prev</button>
      <button [disabled]="isLastTab()" (click)="nextTab()">Next&nbsp;&nbsp;<i class="fa-solid fa-chevron-right"></i></button>
    </div>
  </div>
  <!-- </div> -->
</ng-container>

<!-- Modal -->
<div
  class="modal fade"
  id="staticBackdrop"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>

  <div class="modal-dialog top-40 modl-clear-pop">
    <div class="modal-content left-20">
      <div class="modal-header">
        <h5 class="modal-title fs-5" id="staticBackdropLabel">
          {{this.modalMessage}}
        </h5>
        
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary okaybtn" data-bs-dismiss="modal">
          No
        </button>
        <button
          type="button"
          class="btn btn-primary okaybtn"
          data-bs-dismiss="modal"
          (click)="clearForm()"
        >
          Yes
        </button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!addcom && !isEditMode && !bulkFileUpload && !clientVehicle"
  class="card"
  style="
    box-shadow: -8px 0 8px rgba(0, 0, 0, 0.1), 8px 0 8px rgba(0, 0, 0, 0.1),
      0 10px 20px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  ">
  <div class="card-header flex-between">
    <h3>Clients</h3>
    <div class="flex-start">
      
      <div class="flex-start">
        <button class="btn btn-light" (click)="refershFunction()">
          <i class="fa-solid fa-arrows-rotate"></i>
        </button>
        <div class="d-flex search-hold">
          <i class="fa-solid fa-magnifying-glass navy-icon"></i>
          <input
            type="text"
            placeholder="Search"
            name=""
            (keyup)="queryResult($event)"
            class="form-control search"
          />
        </div>
        <div class="options-container">
          <button *ngIf="access==='readandwrite'" class="blkbtn" type="button" (click)="toggleOptionsExport()">
            Bulk Upload
          </button>
          <div class="options-menu" [ngClass]="{ expanded: expandedButton }">
            <button class="opt-btn" (click)="get_excel_url()">
              <i class="fa-solid fa-download"></i>&nbsp; Sample File
            </button>
            <button class="opt-btn" (click)="openFileExplorer()">
              <i class="fa-solid fa-upload"></i>&nbsp; Upload File
            </button>
            <input
              type="file"
              (change)="fileSelected($event)"
              #fileInput
              style="display: none"
              accept=".xlsx, .csv, .pdf"
            />
          </div>
        </div>
        <button *ngIf="access==='readandwrite'" class="btn btn-success-soft svbtn" (click)="addCompany()">
          <i class="fa-solid fa-plus"></i> New
        </button>
        <button class="btn export-btn" (click)="exportEach(exportVar)">
          Export
        </button>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <ul class="nav nav-tabs nav-tabs-edit">
      <li class="nav-item ">
        <a class="nav-link nav-link-edit" [ngClass]="{'active': selectedTableTab === 'Active Clients', 'inactive': selectedTab !== 'Active Clients'}" (click)="selectTab('Active Clients')">Active Clients</a>
      </li>
      <li class="nav-item">
        <a class="nav-link nav-link-edit" [ngClass]="{'active': selectedTableTab === 'Inactive Clients', 'inactive': selectedTab !== 'Inactive Clients'}" (click)="selectTab('Inactive Clients')">Inactive Clients</a>
      </li>
    </ul>
    
    <div *ngIf="selectedTableTab === 'Active Clients'" class="tab-content">
      <div class="card-body card-body-edit">
        <div class="overflow-auto">
          <table class="table table-docs table-hover table-striped">
            <thead>
              <!-- <tr class="height-fr">
                <td></td>
                <td class="td-sticky"></td>
                <td colspan="3" class="text-center gray-bg">CONTACT PERSON</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr> -->
              <tr>
                <th>S.N.</th>
                <ng-container *ngFor="let h of headers; let n = index" class="text-center">
                  <th [ngClass]="{'sticky-header': n === 0}">
                    {{ h.name }}
                    <span *ngIf="h.sort && h.sort.sort" (click)="sortTable(h, filteredData)">
                      <i class="fa fa-sort-amount-desc" aria-hidden="true" *ngIf="h.sort.direction === 'Des'"></i>
                      <i class="fa fa-sort-amount-asc" aria-hidden="true" *ngIf="h.sort.direction === 'Asc'"></i>
                    </span>
                    <span *ngIf="h.filter" class="inputseach">
                      <input autocomplete="off" type="text" [(ngModel)]="h.filterText" />
                      <i class="fa fa-search" aria-hidden="true"></i>
                    </span>
                  </th>
                </ng-container>
                <th colspan="2" *ngIf="access === 'readandwrite'">Actions</th>
              </tr>
            </thead>
            <tbody [ngStyle]="{ display: filteredData.length > 0 ? 'table-row-group' : 'none' }">
              <ng-container *ngFor="let i of filteredData | paginate: { itemsPerPage: pagination.perPage, currentPage: pagination.page, totalItems: filteredData.length }; let j = index">
                <tr *ngIf="notifier.filterTableColums(headers, i)">
                  <td>{{ (pagination.page - 1) * pagination.perPage + j + 1 }}</td>
                  <ng-container *ngFor="let header of headers; let k = index">
                    <td [ngClass]="{ 'td-sticky': k === 0 }" style="position: relative;">
                      <span *ngIf="header.click" class="totl-click" (click)="header.click(i)">
                        {{ resolvePath(i, header.id) }}
                      </span>
                      <!-- <span *ngIf="header.click2" class="record-click" (click)="header.click2(i)">
                        {{ resolvePath(i, header.id) }} -->
                      <span *ngIf="header.click2" class="record-click" (mouseenter)="header.click2(i, j, k)" (mouseleave)="selectedIndexForRole = null" style="position: relative;">
                        {{ resolvePath(i, header.id) }}
                        <app-role-popup [role]="role" *ngIf="selectedIndexForRole === j && selectedHeaderTab === k"></app-role-popup>
                      </span>
                      <span *ngIf="header.click3" class="record-click" (mouseenter)="header.click3(i, j, k)" (mouseleave)="selectedIndexForRole = null" style="position: relative;">
                        {{ resolvePath(i, header.id) }}
                        <app-role-popup [role]="role" *ngIf="selectedIndexForRole === j && selectedHeaderTab === k" style="position: absolute;"></app-role-popup>
                      </span>
                      <span *ngIf="header.click4" class="record-click" (mouseenter)="header.click4(i, j, k)" (mouseleave)="selectedIndexForRole = null" style="position: relative;">
                        {{ resolvePath(i, header.id) }}
                        <app-role-popup [role]="role" *ngIf="selectedIndexForRole === j && selectedHeaderTab === k" style="position: absolute;"></app-role-popup>
                      </span>
                      <span *ngIf="!header.click && !header.click2 && !header.click3 && !header.click4 && header.name === 'Client Name'" class="record-click" (click)="openModal(i)">
                        {{ resolvePath(i, header.id) }}
                      </span>
                      <span *ngIf="!header.click && !header.click2 && !header.click3 && !header.click4 && header.name !== 'Client Name'">
                        {{ resolvePath(i, header.id) }}
                      </span>
                      
                    </td>
                  </ng-container>
                  <td *ngIf="access === 'readandwrite'" class="text-center">
                    <i class="fas fa-edit edit-icon" (click)="editClient(i)"></i>
                  </td>
                </tr>
              </ng-container>
            </tbody>
            <tbody [ngStyle]="{ display: filteredData.length === 0 ? 'table-row-group' : 'none' }">
              <tr>
                <td [attr.colspan]="headers.length + 2" class="text-center">
                  <div class="no_data">No Data Found</div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="paginator">
            <div class="flex-start">
              <span class="no-wrap">Per Page</span>
              <select class="form-select" [(ngModel)]="pagination.perPage" (change)="pagination.page = 1">
                <option value="{{ page }}" *ngFor="let page of pages">{{ page }}</option>
              </select>
            </div>
            <pagination-controls class="pagination" previousLabel="Prev" nextLabel="Next" (pageChange)="pagination.page = $event">
            </pagination-controls>
          </div>
        </div>
      </div>
    </div>
    
    <div *ngIf="selectedTableTab === 'Inactive Clients'" class="tab-content">
      <div class="card-body card-body-edit">
        <div class="overflow-auto">
          <table class="table table-docs table-hover table-striped">
            <thead>
              <!-- <tr class="height-fr">
                <td></td>
                <td class="td-sticky"></td>
                <td colspan="3" class="text-center gray-bg">CONTACT PERSON</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr> -->
              <tr>
                <th>S.N.</th>
                <ng-container *ngFor="let h of headers; let n = index" class="text-center">
                  <th [ngClass]="{'sticky-header': n === 0}">
                    {{ h.name }}
                    <span *ngIf="h.sort && h.sort.sort" (click)="sortTable(h, filteredData)">
                      <i class="fa fa-sort-amount-desc" aria-hidden="true" *ngIf="h.sort.direction === 'Des'"></i>
                      <i class="fa fa-sort-amount-asc" aria-hidden="true" *ngIf="h.sort.direction === 'Asc'"></i>
                    </span>
                    <span *ngIf="h.filter" class="inputseach">
                      <input autocomplete="off" type="text" [(ngModel)]="h.filterText" />
                      <i class="fa fa-search" aria-hidden="true"></i>
                    </span>
                  </th>
                </ng-container>
                <th colspan="2" *ngIf="access === 'readandwrite'">Actions</th>
              </tr>
            </thead>
            <tbody [ngStyle]="{ display: filteredData.length > 0 ? 'table-row-group' : 'none' }">
              <ng-container *ngFor="let i of filteredData | paginate: { itemsPerPage: pagination.perPage, currentPage: pagination.page, totalItems: filteredData.length }; let j = index">
                <tr *ngIf="notifier.filterTableColums(headers, i)">
                  <td>{{ (pagination.page - 1) * pagination.perPage + j + 1 }}</td>
                  <ng-container *ngFor="let header of headers; let k = index">
                    <td [ngClass]="{ 'td-sticky': k === 0 }">
                      <span *ngIf="header.click" class="totl-click" (click)="header.click(i)">
                        {{ resolvePath(i, header.id) }}
                      </span>
                      <span *ngIf="header.click2" class="record-click" (mouseenter)="header.click2(i, j, k)" (mouseleave)="selectedIndexForRole = null" style="position: relative;">
                        {{ resolvePath(i, header.id) }}
                        <app-role-popup [role]="role" *ngIf="selectedIndexForRole === j && selectedHeaderTab === k"></app-role-popup>
                      </span>
                      <span *ngIf="header.click3" class="record-click" (mouseenter)="header.click3(i, j, k)" (mouseleave)="selectedIndexForRole = null" style="position: relative;">
                        {{ resolvePath(i, header.id) }}
                        <app-role-popup [role]="role" *ngIf="selectedIndexForRole === j && selectedHeaderTab === k" style="position: absolute;"></app-role-popup>
                      </span>
                      <span *ngIf="header.click4" class="record-click" (mouseenter)="header.click4(i, j, k)" (mouseleave)="selectedIndexForRole = null" style="position: relative;">
                        {{ resolvePath(i, header.id) }}
                        <app-role-popup [role]="role" *ngIf="selectedIndexForRole === j && selectedHeaderTab === k" style="position: absolute;"></app-role-popup>
                      </span>
                      <span *ngIf="!header.click && !header.click2 && !header.click3 && !header.click4 && header.name === 'Client Name'" class="record-click" (click)="openModal(i)">
                        {{ resolvePath(i, header.id) }}
                      </span>
                      <span *ngIf="!header.click && !header.click2 && !header.click3 && !header.click4 && header.name !== 'Client Name'">
                        {{ resolvePath(i, header.id) }}
                      </span>
                    </td>
                  </ng-container>
                  <td *ngIf="access === 'readandwrite'" class="text-center">
                    <i class="fas fa-edit edit-icon" (click)="editClient(i)"></i>
                  </td>
                </tr>
              </ng-container>
            </tbody>
            <tbody [ngStyle]="{ display: filteredData.length === 0 ? 'table-row-group' : 'none' }">
              <tr>
                <td [attr.colspan]="headers.length + 2" class="text-center">
                  <div class="no_data">No Data Found</div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="paginator">
            <div class="flex-start">
              <span class="no-wrap">Per Page</span>
              <select class="form-select" [(ngModel)]="pagination.perPage" (change)="pagination.page = 1">
                <option value="{{ page }}" *ngFor="let page of pages">{{ page }}</option>
              </select>
            </div>
            <pagination-controls class="pagination" previousLabel="Prev" nextLabel="Next" (pageChange)="pagination.page = $event">
            </pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div *ngIf="!addcom && !isEditMode && bulkFileUpload"
  class="card"
  style="
    box-shadow: -8px 0 8px rgba(0, 0, 0, 0.1), 8px 0 8px rgba(0, 0, 0, 0.1),
      0 10px 20px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  ">
  <div class="card-header flex-between">
    <h3>Bulk Upload Clients</h3>
    <div class="flex-start">
      <button class="btn btn-light" (click)="gotToHomePage()">
        <i class="fa-solid"></i>Home Page
      </button>
      <button class="btn svbtn" (click)="onSubmitAllDataBulkUp()">
        <i class="fa-solid fa-plus"></i> Submit All
      </button>
    </div>
  </div>
  <!-- Your existing code -->
  <div class="card-body">
    <div class="overflow-auto">
      <table class="table table-docs table-hover table-striped">
        <!-- Table headers -->
        <thead>

          <tr>
            <!-- <th>S.N.</th> -->
            <ng-container
              *ngFor="let h of headers2; let n = index"
              class="text-center"
            >
              <!-- <th
                [ngClass]="{
                  'gray-bg': n === 1 || n === 2 || n === 3,
                  'sticky-header': n === 0
                }"
              > -->
              <th
                [ngClass]="{
                  'sticky-header': n === 0
                }"
              >
                {{ h.name }}
                <span
                  *ngIf="h.sort && h.sort.sort"
                  (click)="notifier.sortTable(h, filteredData)"
                >
                  <i
                    class="fa fa-sort-amount-desc"
                    aria-hidden="true"
                    *ngIf="h.sort.direction == 'Des'"
                  ></i>
                  <i
                    class="fa fa-sort-amount-asc"
                    aria-hidden="true"
                    *ngIf="h.sort.direction == 'Asc'"
                  ></i>
                </span>

                <span *ngIf="h.filter" class="inputseach">
                  <input
                    autocomplete="off"
                    type="text"
                    [(ngModel)]="h.filterText"
                  />
                  <i class="fa fa-search" aria-hidden="true"></i>
                </span>
              </th>
            </ng-container>
            <th colspan="2">Actions</th>
          </tr>
        </thead>
        <tbody
          [ngStyle]="{
            display: filteredData.length > 0 ? 'table-row-group' : 'none'
          }"
        >
          <ng-container
            *ngFor="
              let i of contentList
                | paginate
                  : {
                      itemsPerPage: pagination['perPage'],
                      currentPage: pagination['page'],
                      totalItems: contentList.length
                    };
              let j = index
            "
          >
            <tr *ngIf="notifier.filterTableColums(headers2, i)">
              <ng-container *ngFor="let header of headers2; let k = index">
               <td
                  [ngClass]="{
                    'td-sticky': k === 0
                  }"
                >                
                  {{ resolvePath(i, header.id) }}
                </td>
              </ng-container>
              <td>
                <i class="fas fa-edit edit-icon" (click)="editClientForBulkUpload(i)"></i>
              </td>
            </tr>
          </ng-container>
        </tbody>
        <tbody
          [ngStyle]="{
            display: filteredData.length == 0 ? 'table-row-group' : 'none'
          }"
        >
          <tr>
            <td [attr.colspan]="headers.length + 2">
              <div class="no_data">No Data Found</div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="paginator">
        <div class="flex-start">
          <span class="no-wrap">Per Page</span>
          <select
            class="form-select"
            [(ngModel)]="pagination['perPage']"
            (change)="pagination['page'] = 1"
          >
            <option value="{{ page }}" *ngFor="let page of pages">
              {{ page }}
            </option>
          </select>
        </div>
        <pagination-controls
          class="pagination"
          previousLabel="Prev"
          nextLabel="Next"
          (pageChange)="pagination['page'] = $event"
        >
        </pagination-controls>
      </div>
    </div>
  </div>
</div>




<div *ngIf="!addcom && !isEditMode && !bulkFileUpload && clientVehicle"
  class="card"
  style="
    box-shadow: -8px 0 8px rgba(0, 0, 0, 0.1), 8px 0 8px rgba(0, 0, 0, 0.1),
      0 10px 20px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  ">
  <div class="card-header flex-between">
    <button class="btn btn-light" (click)="gotToHomePage()">
      <i class="fa fa-arrow-left" aria-hidden="true"></i>
    </button>
    <h3>Vehicle Details - {{clientName}}</h3>
    <div class="flex-start">
      <div class="flex-start">
        <button class="btn export-btn" (click)="exportEach2(clientVehicleResult)">
          Export
        </button>
      </div>
    </div>
  </div>
  <!-- Your existing code -->
  <div class="card-body">
    <div class="overflow-auto">
      <table class="table table-docs table-hover table-striped">
        <!-- Table headers -->
        <thead>
          <tr>
            <ng-container
              *ngFor="let h of headers4; let n = index"
              class="text-center"
            >
              <th>
                {{ h.name }}
                <span
                  *ngIf="h.sort && h.sort.sort"
                  (click)="notifier.sortTable(h, clientVehicleResult)"
                >
                  <i
                    class="fa fa-sort-amount-desc"
                    aria-hidden="true"
                    *ngIf="h.sort.direction == 'Des'"
                  ></i>
                  <i
                    class="fa fa-sort-amount-asc"
                    aria-hidden="true"
                    *ngIf="h.sort.direction == 'Asc'"
                  ></i>
                </span>

                <span *ngIf="h.filter" class="inputseach">
                  <input
                    autocomplete="off"
                    type="text"
                    [(ngModel)]="h.filterText"
                  />
                  <i class="fa fa-search" aria-hidden="true"></i>
                </span>
              </th>
            </ng-container>
          </tr>
        </thead>
        <tbody
          [ngStyle]="{
            display: clientVehicleResult.length > 0 ? 'table-row-group' : 'none'
          }"
        >
          <ng-container
            *ngFor="
              let i of clientVehicleResult
                | paginate
                  : {
                      itemsPerPage: pagination['perPage'],
                      currentPage: pagination['page'],
                      totalItems: clientVehicleResult.length
                    };
              let j = index
            "
          >
            <tr *ngIf="notifier.filterTableColums(headers4, i)">
              <ng-container *ngFor="let header of headers4; let k = index">
                <td>
                {{ resolvePath(i, header.id) }}
                </td>
              </ng-container>
            </tr>
          </ng-container>
        </tbody>
        <tbody
          [ngStyle]="{
            display: clientVehicleResult.length == 0 ? 'table-row-group' : 'none'
          }"
        >
          <tr>
            <td [attr.colspan]="headers4.length + 2" class="text-center">
              <div class="no_data">No Data Found</div>
            </td>
          </tr>
        </tbody>

      </table>
      <div class="paginator">
        <div class="flex-start">
          <span class="no-wrap">Per Page</span>
          <select
            class="form-select"
            [(ngModel)]="pagination['perPage']"
            (change)="pagination['page'] = 1"
          >
            <option value="{{ page }}" *ngFor="let page of pages">
              {{ page }}
            </option>
          </select>
        </div>
        <pagination-controls
          class="pagination"
          previousLabel="Prev"
          nextLabel="Next"
          (pageChange)="pagination['page'] = $event"
        >
        </pagination-controls>
      </div>
    </div>
  </div>
</div>









<app-submit-popup *ngIf="popUp" (hideModa)="hidepopup($event)" [header]="popup_header" [subtitle]="popup_subtitle"></app-submit-popup>
<!-- <app-role-popup [role]="role" [showRole]="showRole" ></app-role-popup> -->