<ng-container *ngIf="addcom || isEditMode">
  <!-- <div *ngIf="addcom"> -->
  <div class="flex-start ml-4 mt-3">
    <button class="btn btn-light" (click)="closeEditMode()" *ngIf="readOnlyVar">
      <i class="fa fa-arrow-left " aria-hidden="true"></i>
    </button>
    <button class="btn btn-light" (click)="openModalForConfirm('staticBackdrop')" *ngIf="!readOnlyVar">
      <i class="fa fa-arrow-left " aria-hidden="true"></i>
    </button>
    <h4 class="mb-0 pb-0 font-weight-bold">
      {{ isEditMode ? "Update Fuel Station" : "New Fuel Station" }}
    </h4>
  </div>
  <div class="card mt-3 ml-4 mr-0 ">
    <form [formGroup]="myForm" (ngSubmit)="onSubmit()" autocomplete="off">
      <!-- <h5 class="card-header">Client Information</h5> -->
      <div class="card-body box-shadow">

        <div class="form-row justify-content-start">


          <!-- Fuel Station Name -->
          <div class="form-group mb-2 col-md-3 fl mr-5">
            <label for="fs_name" class="no-wrap">Fuel Station*</label>
            <input type="text" id="fs_name" formControlName="fs_name" class="inputOfForm" (input)="onFsStatChange()"
              [readonly]="isEditMode" />
            <div *ngIf="
              myForm.get('fs_name').invalid && myForm.get('fs_name').touched
            " class="error-message">
              <span class="error-message" *ngIf="myForm.get('fs_name')?.errors?.['required']">Fuel Station Name is
                required.</span>
              <span class="error-message"
                *ngIf="!myForm.get('fs_name')?.errors?.['required'] && myForm.get('fs_name')?.errors?.['alphabetsAndSpaces']">Name
                can only contain alphabets and spaces</span>
            </div>
          </div>

          <div class="form-group mb-2 col-md-3 fl mr-5">
            <label for="fuel_company">Fuel Company*</label>
            <input type="text" id="fuel_company" formControlName="fuel_company" (input)="onFsCmpChange()"
              class="inputOfForm" [readonly]="isEditMode" />
            <div *ngIf="
                        myForm.get('fuel_company').invalid &&
                        myForm.get('fuel_company').touched
                      " class="error-message">
              Fuel Company is required.
            </div>
          </div>
          <div class="form-group mb-2 col-md-3 fl mr-5">
            <label for="fs_id">Fuel Station ID*</label>
            <input type="text" id="fs_id" formControlName="fs_id" [readonly]="isEditMode" class="inputOfForm" />
            <!-- Error message if needed -->
            <div *ngIf="myForm.get('fs_id').invalid && myForm.get('fs_id').touched" class="error-message">
              Fuel Station ID is required.
            </div>
          </div>
        </div>

        <div class="form-row justify-content-start">
          <!-- Fuel Types -->
          <div class="form-group mb-2 col-md-3 fl mr-5">
            <label for="fuel_types">Fuel Types Available*</label>
            <select id="fuel_types" formControlName="fuel_types">
              <!-- <option [ngValue]="null">Select Fuel Type</option> -->
              <option value="Diesel">Diesel</option>
              <option value="Petrol">Petrol</option>
              <option value="CNG">CNG</option>
              <option value="LPG">LPG</option>
            </select>
            <div *ngIf="
              myForm.get('fuel_types').invalid &&
              myForm.get('fuel_types').touched
              " class="error-message">
              Fuel Type is required.
            </div>
          </div>
        </div>

        <hr>

        <h5 for="wrk_hrs" class="mt-2 mb-3">Working Hours</h5>
        <!-- Working Hours -->
        <div class="form-row justify-content-start">
          <div class="form-group mb-2 col-md-2 fl mr-5">
            <label for="startWeekday">Start Weekday:</label>
            <select formControlName="start_weekday" id="startWeekday">
              <!-- <option [ngValue]="null">Start Weekday</option> -->
              <option value="Monday">Monday</option>
              <option value="Tuesday">Tuesday</option>
              <option value="Wednesday">Wednesday</option>
              <option value="Thursday">Thursday</option>
              <option value="Friday">Friday</option>
              <option value="Saturday">Saturday</option>
              <option value="Sunday">Sunday</option>
            </select>
          </div>

          <div class="form-group mb-2 col-md-2 fl mr-5">
            <label for="startTime">Start Time</label>
            <input type="time" formControlName="start_time" id="startTime" class="inputOfForm" />
            <div *ngIf="
              myForm.get('start_time').invalid &&
              myForm.get('start_time').touched
            " class="error-message">
              Working Hours from time is required.
            </div>
          </div>


          <div class="form-group mb-2 col-md-2 fl mr-5">
            <label for="endWeekday">End Weekday</label>
            <select formControlName="end_weekday" id="endWeekday">
              <!-- <option [ngValue]="null">End Weekday</option> -->
              <option value="Monday">Monday</option>
              <option value="Tuesday">Tuesday</option>
              <option value="Wednesday">Wednesday</option>
              <option value="Thursday">Thursday</option>
              <option value="Friday">Friday</option>
              <option value="Saturday">Saturday</option>
              <option value="Sunday">Sunday</option>
            </select>
          </div>

          <div class="form-group mb-2 col-md-2 fl mr-5">
            <label for="endTime">End Time</label>
            <input type="time" formControlName="end_time" id="endTime" class="inputOfForm" />

            <!-- Implement a day and time picker component here -->
            <div *ngIf="
              myForm.get('end_time').invalid && myForm.get('end_time').touched
            " class="error-message">
              Working Hours End time is required.
            </div>
          </div>
        </div>

        <hr>
        <!-- Location -->
        <div class="form-row justify-content-start">
          <div class="form-group mb-2 col-md-3 fl mr-5">
            <label for="location">Location*</label>
            <textarea id="location" formControlName="location" maxlength="500"></textarea>
            <div *ngIf="
              myForm.get('location').invalid && myForm.get('location').touched
            " class="error-message">
              Location is required and should not exceed 500 characters.
            </div>
          </div>

          <!-- Status -->
          <div class="form-group mb-2 col-md-3 fl mr-5">
            <label for="status">Status</label>
            <select id="status" formControlName="status">
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </select>
            <div *ngIf="myForm.get('status').invalid && myForm.get('status').touched" class="error-message">
              Status is required.
            </div>
          </div>

          <!-- Contact Person -->
          <div class="form-group mb-2 col-md-3 fl mr-5">
            <label for="contact_person">Contact Person*</label>
            <input type="text" id="contact_person" formControlName="contact_person" class="inputOfForm" />
            <div *ngIf="
              myForm.get('contact_person').invalid &&
              myForm.get('contact_person').touched
            " class="error-message">
              Contact Person is required.
            </div>
          </div>
        </div>

        <!-- Contact Number -->
        <div class="form-row justify-content-start">
          <div class="form-group mb-2 col-md-3 fl mr-5">
            <label for="contact_no">Contact Number*</label>
            <input type="text" id="contact_no" formControlName="contact_no" class="inputOfForm" />
            <div *ngIf="
              myForm.get('contact_no').invalid &&
              myForm.get('contact_no').touched
            " class="error-message">
              <span class="error-message" *ngIf="myForm.get('contact_no')?.errors?.['required']">Contact
                Number is required</span>
              <span class="error-message" *ngIf="myForm.get('contact_no')?.errors?.['pattern']">Enter a
                valid contact number
              </span>
            </div>
          </div>

          <!-- Email Address -->
          <div class="form-group mb-2 col-md-3 fl mr-5">
            <label for="email">Email Address</label>
            <input type="email" id="email" formControlName="email" class="inputOfForm" />
            <div *ngIf="myForm.get('email').invalid && myForm.get('email').touched" class="error-message">
              Please enter a valid email address.
              <span class="error-message" *ngIf="myForm.get('email')?.errors?.['required']">Email is
                required</span>
              <span class="error-message" *ngIf="myForm.get('email')?.errors?.['pattern']">Enter a valid
                Email
              </span>
            </div>
          </div>

          <!-- Security Advance Deposit -->
          <div class="form-group mb-2 col-md-3 fl mr-5">
            <label for="sec_adv_deposite">Security Advance Deposit</label>
            <input type="text" id="sec_adv_deposite" formControlName="sec_adv_deposite" class="inputOfForm" />
            <div *ngIf="
              myForm.get('sec_adv_deposite').invalid &&
              myForm.get('sec_adv_deposite').touched
            " class="error-message">
              Security Advance Deposit is required.
            </div>
          </div>
        </div>

        <!-- Accepted Payment Methods -->
        <div class="form-row justify-content-start">
          <div class="form-group mb-2 col-md-3 fl mr-5 mt-3">
            <label class="">Accepted Payment Method:</label>
            <div class="d-flex align-items-center" formArrayName="payment_method">
              <div *ngFor=" let option of ['Bank Transfer', 'Fleet card']; let i = index ">
                <label class="wid-auto m-3">
                  <input class="wid-auto" type="checkbox" [formControlName]="i" />
                  {{ option }}
                </label>
              </div>
            </div>
          </div>
        </div>


        <!-- Bank Transfer Fields -->
        <div *ngIf="myForm.get('payment_method').value[0] === true">
          <div class="table-responsive mb-4">
            <table class="table">
              <thead>
                <tr>
                  <th>Account Number</th>
                  <th>IFSC Code</th>
                  <th>Bank Name</th>
                  <th>Bank Address</th>
                  <th>Name as per Bank Account</th>
                  <th></th>
                </tr>
              </thead>
              <tbody formArrayName="bank_account_details">
                <tr *ngFor="let bank of bankAccountDetailsArray.controls; let i = index" [formGroupName]="i">
                  <td>
                    <input type="text" id="acc_no" formControlName="acc_no" class="inputOfForm" />
                    <div *ngIf="bank.get('acc_no')?.invalid && bank.get('acc_no')?.touched">
                      <span class="error-message" *ngIf="bank.get('acc_no')?.errors?.['required']">Account Number is
                        required.</span>
                    </div>
                  </td>
                  <td>
                    <input type="text" id="ifsc_code" formControlName="ifsc_code" class="inputOfForm" />
                    <div *ngIf="bank.get('ifsc_code')?.invalid && bank.get('ifsc_code')?.touched">
                      <span class="error-message" *ngIf="bank.get('ifsc_code')?.errors?.['required']">IFSC Code is
                        required.</span>
                    </div>
                  </td>
                  <td>
                    <input type="text" id="bank_name" formControlName="bank_name" class="inputOfForm" />
                    <div *ngIf="bank.get('bank_name')?.invalid && bank.get('bank_name')?.touched">
                      <span class="error-message" *ngIf="bank.get('bank_name')?.errors?.['required']">Bank Name is
                        required.</span>
                    </div>
                  </td>
                  <td>
                    <input type="text" id="bank_address" formControlName="bank_address" class="inputOfForm" />
                    <div *ngIf="bank.get('bank_address')?.invalid && bank.get('bank_address')?.touched">
                      <span class="error-message" *ngIf="bank.get('bank_address')?.errors?.['required']">Bank Address is
                        required.</span>
                    </div>
                  </td>
                  <td>
                    <input type="text" id="name" formControlName="name" class="inputOfForm" />
                    <div *ngIf="bank.get('name')?.invalid && bank.get('name')?.touched">
                      <span class="error-message" *ngIf="bank.get('name')?.errors?.['required']">Name is
                        required.</span>
                    </div>
                  </td>
                  <td>
                    <button type="button" class="btn btn-danger" (click)="removeBankAccount(i)">Remove</button>
                  </td>
                </tr>
              </tbody>
            </table>
            <button type="button" class="btn btn-primary ml-3" (click)="addBankAccount()">
              Add Bank Account
            </button>
          </div>
        </div>

        <div *ngIf="myForm.get('payment_method').value[1] === true">
          <div class="form-group mb-2 col-md-2 fl mr-2">
            <label>Upload</label>
            <div>
              <span [ngStyle]="{display: !(isEditMode && !updated['fleet_card']) ? 'inline' : 'none'}">
                <input type="file" id="fleet_card_upload" #fleetIn
                  (change)="onFileSelected('fleet_card_upload', 'fleet_card', $event, true, fleetIn); fleetTouched = true"
                  class="upldFile" style="display: none;" />
                <ng-container *ngIf="(isEditMode && !bulkFileUpload) || addcom">
                  <button *ngIf="(fleetIn.files)?.length == 0 && !isEditMode" class="btn btn-primary ml-4" type="button"
                    (click)="fleetIn.click()" [disabled]="readOnlyVar">
                    <i class="fa-solid fa-magnifying-glass mrgn-r-10"></i>Browse File
                  </button>
                  <div *ngIf="!updateForm">
                    <div *ngIf="(fleetIn.files || []).length > 0 && updated['fleet_card'] && progress['fleet_card']">
                      <div class="progress">
                        <div class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100"
                          [style.width.%]="progressValue"></div>
                      </div>
                      <p class="upldTxt">uploading...</p>
                    </div>
                    <div class="button-container"
                      *ngIf="(fleetIn.files || []).length > 0 && updated['fleet_card'] && !progress['fleet_card']">
                      <span class="del" (click)="deleteDocument('fleet_card', fleetIn)">Delete</span>
                      <div class="line"></div>
                      <span class="pre" (click)="previewDocument(fleetIn, 'fleet_card')">Preview</span>
                    </div>
                  </div>
                </ng-container>
              </span>
              <ng-container *ngIf="isEditMode && !bulkFileUpload">
                <input class="upldFile" type="file" id="fleet_card"
                  (change)="onFileSelected('fleet_card_upload', 'fleet_card', $event, true, fleetIn); fleetTouched = true"
                  #fleetIn style="display: none;" />
                <button *ngIf="this.fileNames[0] === undefined && !updated['fleet_card']" class="btn btn-primary ml-4"
                  type="button" (click)="fleetIn.click()" [disabled]="readOnlyVar">
                  <i class="fa-solid fa-magnifying-glass mrgn-r-10"></i>Browse File
                </button>
                <span *ngIf="this.fileNames[0] !== undefined || updated['fleet_card']">
                  <div *ngIf="updateForm">
                    <div *ngIf="progress['fleet_card']">
                      <div class="progress">
                        <div class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100"
                          [style.width.%]="progressValue"></div>
                      </div>
                      <p class="upldTxt">uploading...</p>
                    </div>
                    <div class="button-container" *ngIf="!progress['fleet_card']">
                      <span class="del" (click)="deleteDocumentInUpdate('fleet_card')">Delete</span>
                      <div class="line"></div>
                      <span class="pre" (click)="previewDocument(fleetIn, 'fleet_card')">Preview</span>
                    </div>
                  </div>
                </span>
              </ng-container>
              <ng-container *ngIf="myForm.get('payment_method').value[1] === true && bulkFileUpload">
                <input class="upldFile" type="file" id="fleet_card"
                  (change)="onFileSelected('fleet_card_upload', 'fleet_card', $event, true, fleetIn); fleetTouched = true"
                  #fleetIn style="display: none;" />
                <button *ngIf="this.fileInfoArray[0] === undefined" class="btn btn-primary ml-4" type="button"
                  (click)="fleetIn.click()" [disabled]="readOnlyVar">
                  <i class="fa-solid fa-magnifying-glass mrgn-r-10"></i>Browse File
                </button>
                <span *ngIf="this.fileInfoArray[0] !== undefined">
                  <div *ngIf="updateForm">
                    <div *ngIf="progress['fleet_card']">
                      <div class="progress">
                        <div class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100"
                          [style.width.%]="progressValue"></div>
                      </div>
                      <p class="upldTxt">uploading...</p>
                    </div>
                    <div class="button-container" *ngIf="!progress['fleet_card']">
                      <span class="del" (click)="deleteDocumentInUpdateForBulkUp('fleet_card')">Delete</span>
                      <div class="line"></div>
                      <span class="pre" (click)="previewDocument(fleetIn, 'fleet_card')">Preview</span>
                    </div>
                  </div>
                </span>
              </ng-container>

              <div>
                <span class="file-size-info ml-4">Max File size is 20MB</span>
              </div>
            </div>
            <span *ngIf="documentInputErrors['fleet_card_upload']" class="error-message">
              {{ documentInputErrors["fleet_card_upload"] }}
            </span>
            <div
              *ngIf="!isEditMode && myForm.get('fleet_card_upload')?.invalid && myForm.get('fleet_card_upload')?.touched">
              <span class="error-message">Upload Fleet Card</span>
            </div>
          </div>
        </div>


        <!-- 
        <div *ngIf="myForm.get('payment_method').value[1] === true && bulkFileUpload">
          <div class="form-group mb-2 col-md-3 fl mr-2">
            <label for="fleet_card_upload">Upload</label>
            <input type="file" id="fleet_card_upload"
              (change)="onFileSelectedForBulkUp('fleet_card_upload', 'fleet_card', $event); fleetTouched = true"
              [ngStyle]="{ display:  'none'  }" #fleetIn class="upldFile" />
            <span class="mb-2">
              <button *ngIf="isEditMode && !(this.transformedData[index]?.fleet_card_s3_path || this.fileInfoArray)"
                class="btn btn-primary" type="button" (click)="fleetIn.click()">
                <i class="fa-solid fa-magnifying-glass mrgn-r-10"></i>Browse File
              </button>
              <button type="button" class="btn btn-primary" (click)="previewDocument(fleetIn, 'fleet_card')"
                *ngIf="isEditMode && (this.transformedData[index]?.fleet_card_s3_path || this.fileInfoArray)">
                Preview
              </button>
              <i class="fa fa-pencil pencil-icon" (click)="fleetIn.click()"
                *ngIf="isEditMode && (this.transformedData[index]?.fleet_card_s3_path || this.fileInfoArray) "></i>
            </span>
            <span class="file-size-info">Max File size is 20MB</span>
            <div *ngIf="addcom && (submitted || fleetTouched) && fleetIn.files?.length == 0 && !isEditMode">
              <span class="error-message">Please upload document.</span>
            </div>
            <span *ngIf="documentInputErrors['fleet_card_upload']" class="error-message">{{
              documentInputErrors["fleet_card_upload"] }}</span>
            <div
              *ngIf="!isEditMode && myForm.get('fleet_card_upload')?.invalid && myForm.get('fleet_card_upload')?.touched">
              <span class="error-message">Upload Fleet Card</span>
            </div>
          </div>
        </div> -->


        <!-- Payment Terms -->
        <div class="form-row justify-content-start mb-5">
          <div class="form-group mb-2 col-md-3 fl mr-5">
            <label for="payment_term">Payment Terms</label>
            <select id="payment_term" formControlName="payment_term">
              <!-- <option [ngValue]="null">Select Payment Terms</option> -->
              <option value="Immediate">Immediate</option>
              <option value="7 days">7 Days</option>
              <option value="15 days">15 Days</option>
              <option value="30 days">30 Days</option>
            </select>
            <div *ngIf="
              myForm.get('payment_term').invalid &&
              myForm.get('payment_term').touched
            " class="error-message">
              Payment Terms are required.
            </div>
          </div>

          <!-- Billing Period -->
          <div class="form-group mb-2 col-md-3 fl mr-5">
            <label for="billing_period">Billing Period</label>
            <select id="billing_period" formControlName="billing_period">
              <!-- <option [ngValue]="null">Select Billing Period</option> -->
              <option value="Immediate">Immediate</option>
              <option value="7 days">7 Days</option>
              <option value="15 days">15 Days</option>
              <option value="30 days">30 Days</option>
            </select>
            <div *ngIf="
              myForm.get('billing_period').invalid &&
              myForm.get('billing_period').touched
            " class="error-message">
              Billing Period is required.
            </div>
          </div>
        </div>

        <!-- Submit Button -->
        <div class="margin-left mb-5" *ngIf="!readOnlyVar">
          <div>
            <button class="btn btn-danger-soft m-6 clrbttn" type="button" data-bs-toggle="modal"
              data-bs-target="#staticBackdrop">
              Clear
            </button>
          </div>

          <div>
            <button class="btn btn-success-soft m-6 svbtn" type="submit">
              {{
              isEditMode && bulkFileUpload
              ? "Save"
              : isEditMode
              ? "Update"
              : "Save"
              }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <!-- </div> -->
</ng-container>

<!-- Modal -->
<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog top-40">
    <div class="modal-content left-30 width-40">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel">
          <b>Are you sure you want to discard the changes?</b>
        </h1>
        <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
      </div>
      <!-- <div class="modal-body">
            ...
          </div> -->
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary okaybtn" data-bs-dismiss="modal">
          No
        </button>
        <button type="button" class="btn btn-primary okaybtn" data-bs-dismiss="modal" (click)="clearForm()">
          Yes
        </button>
      </div>
    </div>
  </div>
</div>


<div *ngIf="!addcom && !isEditMode && !bulkFileUpload" class="box-shadow-darker card">
  <div class=" card-header flex-between">
    <h4 class="font-weight-bold">Fuel Stations</h4>
    <div class="flex-start">
      <button class="btn btn-light" (click)="fetchfuel()">
        <i class="fa-solid fa-arrows-rotate"></i>
      </button>
      <div class="search-container">
        <i class="fas fa-search p-2 icon-blue"></i>
        <input type="text" placeholder="Search" name="" (keyup)="queryResult($event)">
      </div>
      <div class="options-container">
        <!-- <i class="fa-solid fa-ellipsis-vertical options-btn"(click)="toggleOptionsExport()"></i> -->
        <button class="blkbtn" type="button" appClickedOutside (clickedOutside)="toggleOptionsExportFalse()"
          (click)="toggleOptionsExport()" *ngIf="access==='readandwrite'">Bulk Upload</button>
        <!-- <div class="options-menu" *ngIf="expandedButton" > -->
        <div class="options-menu" [ngClass]="{ expanded: expandedButton }">

          <button class="opt-btn" (click)="get_excel_url()"><i class="fa-solid fa-download"></i>&nbsp; Sample
            File</button>
          <button class="opt-btn" (click)="openFileExplorer()"><i class="fa-solid fa-upload"></i>&nbsp; Upload File
          </button>
          <input type="file" (change)="fileSelected($event)" #fileInput style="display: none"
            accept=".xlsx, .csv, .pdf" />
        </div>
      </div>
      <button class="btn btn-success-soft svbtn" (click)="addCom()" *ngIf="access==='readandwrite'">
        <i class="fa-solid fa-plus"></i> New
      </button>
      <button class="btn btn-success-soft export" (click)="fetchDataAndExport()">Export
      </button>
    </div>
  </div>

  <!-- <h2>Location</h2>
  <button (click)="openGoogleMaps()">Open in Google Maps</button> -->
  <!-- Your existing code -->
  <div class="card-body">
    <div class="overflow-auto">
      <table class="table table-docs table-hover table-striped">
        <!-- Table headers -->
        <thead>
          <tr>
            <th>S.N.</th>
            <th *ngFor="let h of headers">
              {{h.name}}
              <span *ngIf="h.sort && h.sort.sort" (click)="notifier.sortTable(h,result)">

                <i class="fa fa-sort-amount-desc" aria-hidden="true" *ngIf="h.sort.direction == 'Des'"></i>
                <i class="fa fa-sort-amount-asc" aria-hidden="true" *ngIf="h.sort.direction == 'Asc'"></i>
              </span>

              <span *ngIf="h.filter " class="inputseach">
                <input type="text" [(ngModel)]="h.filterText" />
                <i class="fa fa-search" aria-hidden="true"></i>
              </span>
            </th>
            <th colspan="2" *ngIf="access==='readandwrite'">Actions</th>
          </tr>
        </thead>
        <tbody [ngStyle]="{'display': filteredData.length > 0 ? 'table-row-group': 'none'}">
          <!-- <tr *ngFor="let i of s3List; let j = index"> -->
          <ng-container
            *ngFor="let i of filteredData | paginate : { itemsPerPage: pagination['perPage'], currentPage: pagination['page'], totalItems: filteredData.length }; let j = index">
            <tr *ngIf="notifier.filterTableColums(headers,i)">
              <td>{{(pagination['page']-1) * (pagination['perPage']) + j+1}}</td>
              <td *ngFor="let header of headers" [ngClass]="{'status-cell': header.name === 'Status'}">
                <span (click)="header.name === 'Fuel Station' ? readOnly(i) : null"
                  [ngClass]="{'status-active': header.id === 'status' && i[header.id] === 'Active', 'first-column': header.name === 'Fuel Station'}">
                  {{ i[header.id] }}
                </span>
              </td>
              <td class="text-center">
                <i class="fas fa-edit edit-icon" (click)="editfuel(i)" *ngIf="access==='readandwrite'"></i>
              </td>

            </tr>
          </ng-container>
        </tbody>

        <tbody [ngStyle]="{display: filteredData.length == 0 ? 'table-row-group' : 'none'}">
          <tr>
            <td colspan="12" class="text-center">
              <div class="no_data text-center">No Fuel Stations Found</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="paginator">
      <div class="flex-start">
        <span class="no-wrap">Per Page</span>
        <select class="form-select" [(ngModel)]="pagination['perPage']" (change)="pagination['page'] = 1">
          <option value="{{page}}" *ngFor="let page of pages">{{ page }}</option>
        </select>
      </div>
      <pagination-controls class="pagination" previousLabel="Prev" nextLabel="Next"
        (pageChange)="pagination['page'] = $event">
      </pagination-controls>
    </div>
  </div>
</div>


<div *ngIf="showModal" class="modal fade show" tabindex="-1" role="dialog" style="display: block">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"><strong>Fuel Station Details</strong></h5>
        <button type="button" class="close" aria-label="Close" (click)="hideModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="overflow-y: auto">
        <div class="card-body">
          <div class="modal-section">
            <div class="mod">
              <span> Fuel Station Name: </span><span>{{
                selectedCompany.fs_name }}</span>
            </div>
            <div class="mod">
              <span> Fuel Station ID: </span><span>{{
                selectedCompany.fs_id }}</span>
            </div>
            <div class="mod">
              <span> Fuel Company: </span><span>{{
                selectedCompany.fuel_company }}</span>
            </div>
            <!-- <div class="mod"><span> Incorporation
                  Date: </span><span>{{selectedCompany.company_details.incorporation_date}}</span></div> -->
            <div class="mod">
              <span> Fuel Types: </span><span>{{
                selectedCompany.fuel_types }}</span>
            </div>
            <div class="mod">
              <span> Working Hours: </span><span>{{
                selectedCompany.working_hrs }}</span>
            </div>
            <div class="mod">
              <span> Location: </span><span>{{
                selectedCompany.location }}</span>
            </div>
            <div class="mod">
              <span> Status: </span><span>{{
                selectedCompany.status }}</span>
            </div>
            <div class="mod">
              <span> Contact Person: </span><span>{{
                selectedCompany.contact_person }}</span>
            </div>
            <div class="mod">
              <span> Contact Number: </span><span>{{
                selectedCompany.contact_no }}</span>
            </div>
            <div class="mod">
              <span> Email Address: </span><span>{{
                selectedCompany.email }}</span>
            </div>
            <div class="mod">
              <span> Security Advance Deposit: </span><span>{{
                selectedCompany.sec_adv_deposite }}</span>
            </div>
            <div class="mod"><span> Accepted Payment
                Methods: </span><span>{{selectedCompany.payment_method}}</span></div>
            <!-- Loop through the bank account details -->

            <div class="card-body mt-2 p-2" *ngIf="selectedCompany.payment_method.includes('Bank Transfer')">
              <h6 class="mb-3"><strong>Bank Account Details</strong></h6>
              <div class="modal-section">
                <ul>
                  <li *ngFor="let bank of selectedCompany.bank_acc_details; let i = index">
                    <h6>Bank Account {{ i + 1 }}</h6>
                    <div class="mod">
                      <span>Bank Account Number:</span><span>{{ bank.acc_no }}</span>
                    </div>
                    <div class="mod">
                      <span>IFSC Code:</span><span>{{ bank.ifsc_code }}</span>
                    </div>
                    <div class="mod">
                      <span>Bank Name:</span><span>{{ bank.bank_name }}</span>
                    </div>
                    <div class="mod">
                      <span>Bank Address:</span><span>{{ bank.bank_address }}</span>
                    </div>
                    <div class="mod">
                      <span>Name as per Bank:</span><span>{{ bank.name_as_per_bank ? bank.name_as_per_bank : bank.name
                        }}</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div class="mod">
              <span> Payment Terms: </span><span>{{
                selectedCompany.payment_term }}</span>
            </div>
            <div class="mod">
              <span> Billing Period: </span><span>{{
                selectedCompany.billing_period }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="bulkFileUpload && !isEditMode" class="box-shadow-darker card">
  <div class="card-header flex-between">
    <h4 class="font-weight-bold">Fuel Stations Bulk Upload</h4>
    <div class="flex-start">
      <button class="btn btn-light home-page" (click)="toggleToHome()">
        Home Page
      </button>
      <button class="btn btn-success-soft svbtn" (click)="onSubmitAllDataBulkUp()">
        Submit All
      </button>
    </div>
  </div>
  <!-- Your existing code -->
  <div class="card-body">
    <div class="overflow-auto">
      <table class="table table-docs table-hover table-striped">
        <!-- Table headers -->
        <thead>
          <tr>
            <th>Fuel Station</th>
            <th>Fuel Company</th>
            <th>Fuel Types Available</th>
            <th>Location</th>
            <th>Contact Person</th>
            <th>Contact Number</th>
            <th colspan="2">Actions</th>
          </tr>
        </thead>
        <tbody>
          <!-- Table body with ngFor loop to display company data -->
          <tr *ngFor="let data of transformedData">
            <td>{{ data.fs_name }}</td>
            <td>{{ data.fuel_company }}</td>
            <td>{{ data.fuel_types }}</td>
            <td>{{ data.location }}</td>
            <td>{{ data.contact_person }}</td>
            <td>{{ data.contact_no }}</td>
            <td class="text-center">
              <i class="fas fa-edit edit-icon" (click)="editfuel(data)"></i>
            </td>
          </tr>
        </tbody>
        <!-- Additional tbody to display "No Users Found" message -->
        <tbody [ngStyle]="{
            display: transformedData.length == 0 ? 'table-row-group' : 'none'
          }">
          <tr>
            <td [attr.colspan]="12">
              <div class="no_data text-center">No Fuel Station Found</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<app-submit-popup *ngIf="popUp" (hideModa)="hidepopup($event)" [header]="popup_header"
  [subtitle]="popup_subtitle"></app-submit-popup>