<!-- toggler -->
<!--<a class="navbar-brand" >

</a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>-->
<!--<input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
<button class="btn btn-primary" type="submit">Search</button> *ngIf="searchOnList(i.groupName,accountGroupSearchTerm)" -->
<!--<button class="btn float-end" data-bs-toggle="offcanvas" data-bs-target="#offcanvas" role="button">
  <i class="bi bi-arrow-left-square-fill fs-3" data-bs-toggle="offcanvas" data-bs-target="#offcanvas"></i>
</button>-->
<!--<button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>-->
<!--<div class="leftmenu offcanvas offcanvas-start  show" tabindex="-1" id="offcanvas" data-bs-keyboard="false" data-bs-backdrop="false">
  <div class="offcanvas-header">
    <h6 class="offcanvas-title d-none d-sm-block" id="offcanvas" data-bs-toggle="offcanvas" data-bs-target="#offcanvas" role="button">
      <img style="width:50px" src="../assets/img/logo.png" />
    </h6>
  </div>-->

  <div class="leftmenu" *ngIf="started">
    <div class="offcanvas-body px-0 max-overflow">
      <button
        class="menuCloseButton ground-border"
        (click)="hideLeftMenu()"
        id="menuClosebtn"
      >
        <i class="fa fa-arrow-circle-right fa-rotate-180"></i>
      </button>
  
      <button class="ground-border" (click)="showLeftMenu()" id="menuShowbtn">
        <i class="fa fa-arrow-circle-right"></i>
      </button>
  
      <ul
        class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-start"
        id="menu"
      >
        <ng-container *ngFor="let menu of menuList">
          <li class="nav-item" *ngIf="menu['submenus'].length == 0">
            <a class="nav-link text-truncate" title="Feedback">
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Dashboard%403x.png"
                class="monitoringimg"
                alt=""
              />
              {{ menu["label"] }}
            </a>
          </li>
          <li class="dropdown" *ngIf="menu['submenus'].length > 0">
            <a
              href="#"
              class="nav-link dropdown-toggle text-truncate"
              id="dropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Operations+(Manage+Cloud+Resources).svg"
                alt=""
                class="wid-40"
              />
              {{ menu["label"] }}
            </a>
  
            <ul
              class="dropdown-menu text-small shadow sub-menu"
              aria-labelledby="dropdown"
            >
              <li>
                <h3 class="sub-menu-title">{{ menu["label"] }}</h3>
                <hr />
              </li>
  
              <li>
                <a class="dropdown-item" *ngFor="let submenu of menu['submenus']">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Operations+(Manage+EC2+Instances).svg"
                    alt=""
                    class="wid-40"
                  />
                  <span>{{ submenu["label"] }}</span>
                </a>
              </li>
              <!-- <li *ngIf="checkIfAvaForUser('Chatbot Solutions:Mp3')">
                <a class="dropdown-item" routerLink="/dash/pages/chatbot">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Operations+RDS.svg"
                    class="wid-40"
                    alt=""
                  /><span>Mp3</span>
                </a>
              </li>
              <li *ngIf="checkIfAvaForUser('Chatbot Solutions:PDF')">
                <a class="dropdown-item" routerLink="/dash/pages/chatbot">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Operations+RDS.svg"
                    class="wid-40"
                    alt=""
                  /><span>PDF</span>
                </a>
              </li>
              <li *ngIf="checkIfAvaForUser('Chatbot Solutions:Tabular')">
                <a class="dropdown-item" routerLink="/dash/pages/chatbot">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Operations+RDS.svg"
                    class="wid-40"
                    alt=""
                  /><span>Tabular</span>
                </a>
              </li>
              <li *ngIf="checkIfAvaForUser('Chatbot Solutions:Ticket')">
                <a class="dropdown-item" routerLink="/dash/pages/chatbot">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Operations+RDS.svg"
                    class="wid-40"
                    alt=""
                  /><span>Ticket</span>
                </a>
              </li> -->
              <!-- <li *ngIf="checkIfAvaForUser('Operations:ServiceIntelligence')">
                <a class="dropdown-item" routerLink="/dash/operations/itsi">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/Blue/Operations+Service+Intelligence.svg"
                    style="width: 40px;" alt=""><span>Service Intelligence</span>
                </a>
              </li> -->
            </ul>
          </li>
        </ng-container>
  
        <li class="dropdown">
          <a
            href="#"
            class="nav-link dropdown-toggle text-truncate"
            id="dropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Settings.svg"
              class="wid-40"
              alt=""
            />
            Settings
          </a>
          <ul
            class="dropdown-menu text-small shadow sub-menu"
            aria-labelledby="dropdown"
          >
            <li>
              <h3 class="sub-menu-title">Settings</h3>
              <hr />
            </li>
            <li *ngIf="ut == 'admin' || isUserAdmin">
              <a class="dropdown-item" routerLink="/dash/settings/users">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/User%404x.png"
                  class="wid-40"
                  alt=""
                />
                <span>Users</span>
              </a>
            </li>
            <!-- <li *ngIf="
                  checkIfAvaForUser('Settings:operations/ec2groups') &&
                  urlPrefix == 'client' &&
                  ut == 'admin'
                ">
                <a class="dropdown-item" routerLink="/dash/settings/ec2group/view">
                  <i class="fa fa-server"></i><span> EC2 Groups </span>
                </a>
              </li> -->
            <li *ngIf="ut == 'admin' || isUserAdmin">
              <a class="dropdown-item" routerLink="/dash/settings/policies">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Policies%404x.png"
                  class="wid-40"
                  alt=""
                />
                <span>Policies</span>
              </a>
            </li>
            <!-- <li *ngIf="checkIfAvaForUser('Settings:audit trail')">
              <a class="dropdown-item" routerLink="/dash/settings/audittrail">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/Audit+Trail%404x.png"
                  class="wid-40"
                  alt=""
                />
                <span> Audit Trail </span>
              </a>
            </li> -->
            <li>
              <a class="dropdown-item" routerLink="/dash/settings/profile">
                <img
                  src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/PNG/Blue/4x/User+Management%404x.png"
                  class="wid-40"
                  alt=""
                /><span>User Management</span>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
  
  <div class="position-wid-99">
    <div class="row">
      <div class="col pad-right">
        <nav class="navbar navbar-expand-lg top-menu">
          <div class="container-fluid">
            <div class="nav-item wid-175">
              <span class="nav-link text-truncate padd-0">
                <img class="wid-115" src="../assets/img/Intercity Logo.jpeg" />
              </span>
            </div>
  
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav ms-auto">
                <li class="nav-item dropdown searchbox min-width-auto">
                  <a
                    class="d-block text-decoration-none dropdown-toggle color-top"
                    id="dropdownUser1"
                    data-bs-toggle="dropdown"
                    aria-expanded="true"
                  >
                    <!--<img src="../../assets/img/passport.png" class="rounded-circle" width="32" height="32" />-->
                    <i class="fa fa-user" aria-hidden="true"></i>
                    <!-- <span class="color-family">{{ username }}</span> -->
                  </a>
                  <ul
                    class="dropdown-menu text-small"
                    aria-labelledby="dropdownUser1"
                    data-popper-placement="bottom-start"
                  >
                    <!--<li><a class="dropdown-item" href="#">Settings</a></li>
                      <li><a class="dropdown-item" href="#">Profile</a></li>-->
                    <li>
                      <span class="color-family text-bold">{{ username }}</span>
                      <hr class="dropdown-divider" />
                    </li>
                    <li>
                      <button class="dropdown-item" (click)="logout()">
                        Sign out
                      </button>
                    </li>
                  </ul>
                </li>
              </ul>

              <div class="dropdown text-end"></div>
  
              <div class="wid-75"></div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
  
  <div
    class="content min-vh-100 rightcontent"
    [ngStyle]="{
      marginLeft: !started ? '26px' : undefined
    }"
  >
    <router-outlet></router-outlet>
  </div>
  
  <div class="cft-error-container" *ngIf="checkingCFT">
    <div class="popup-container">
      <div class="image-container">
        <img src="./assets/img/Intercity Logistics - ICTL Logo1.svg" alt="" />
      </div>
      <div class="popup-description">
        Validating Integration with your account...
      </div>
      <div class="mt-2">
        <img
          src="../assets/img/loading_2.svg"
          alt="loading scans"
          class="wid-mar"
        />
      </div>
    </div>
  </div>
  
  <!-- <app-global-search
      *ngIf="showSearchResult"
      (hideModal)="showSearchResult = false"
    ></app-global-search> -->
  <!--<cft-viewer></cft-viewer>-->
  <!-- <dash-expired *ngIf="expiresIn && expiresIn == 0"></dash-expired> -->
  <!-- <router-outlet></router-outlet> -->
  
  <!-- Chatbot -->
  