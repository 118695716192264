import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './modules/auth/components/auth/auth.component';
import { DataResolver } from 'src/app/services/whtielabel.resolver';
import { DashComponent } from './modules/globals/dash/dash.component';
import { SuperadminComponent } from './modules/globals/superadmin/superadmin.component';
import { ConfigResolver } from './services/config.resolver';
import { ForgotPasswordComponent } from './modules/auth/components/forgot-password/forgot.component';
import { LoginComponent } from './modules/auth/components/login/login.component';
import { RegisterComponent } from './modules/auth/components/register/register.component';
import { ClientsComponent } from './modules/globals/superadmin/components/clients/clients.component';
import { AdminClientsComponent } from './modules/globals/admin/clients/clients.component';
import { NewMenusComponent } from './modules/globals/superadmin/components/new-menus/new-menus.component';
import { PoliciesComponenet } from './shared/components/policies/manage.policies.component';
import { feedbackComponent } from './modules/globals/dash/components/feedback/feedback.component';
import { HomeComponent } from './modules/globals/dash/modules/pages/components/home/home.component';
import { PreRaceComponent } from './modules/globals/dash/modules/pages/components/pre-race/app.component';
import { DeputyComponent } from './modules/globals/dash/modules/pages/components/pre-race/deputy/deputy.component';
import { DeviceListComponent } from './modules/globals/dash/modules/pages/components/pre-race/device-list/device-list.component';
import { RaceDetailsComponent } from './modules/globals/dash/modules/pages/components/race-details/race-details.component';
import { RaceOverviewComponent } from './modules/globals/dash/modules/pages/components/race-overview/race-overview.component';
import { VenueDetailsComponent } from './modules/globals/dash/modules/pages/components/venve-details/venve-details.component';
import { AuthGuard } from './services/auth.guard';
import { UserProfileComponent } from './modules/globals/dash/modules/settings/components/user-profile/user-profile.component';
import { UsersComponent } from './modules/globals/dash/modules/settings/components/users/users.component';
import { AdminComponent } from './modules/globals/admin/admin.component';
import { CompanyComponent } from './modules/globals/admin/company/company.component';
import { DriversComponent } from './modules/globals/admin/drivers/drivers.component';
import { FuelStationsComponent } from './modules/globals/admin/fuel-stations/fuel-stations.component';
import { VehiclesComponent } from './modules/globals/admin/vehicles/vehicles.component';
import { ClientRoutesComponent } from './modules/globals/admin/client-routes/client-routes.component';
import { AdClientComponent } from './modules/globals/admin/ad-client/ad-client.component';
import { AttendersComponent } from './modules/globals/admin/attenders/attenders.component';
import { MechanicsComponent } from './modules/globals/admin/mechanics/mechanics.component';
import { StaffComponent } from './modules/globals/admin/staff/staff.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'auth/login',
  },
  {
    path: 'auth',
    component: AuthComponent,
    resolve: { data: DataResolver },
    children: [
      {
        path: 'login',
        component: LoginComponent,
        title: 'Login',
        resolve: { data: DataResolver, config: ConfigResolver },
      },
      {
        path: 'register',
        component: RegisterComponent,
        title: 'Register',
        resolve: { data: DataResolver, config: ConfigResolver },
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        title: 'Forgot Password',
        resolve: { data: DataResolver, config: ConfigResolver },
      },
    ],
  },
  {
    path: 'superadmin',
    canActivate: [AuthGuard],
    component: SuperadminComponent,
    resolve: { config: ConfigResolver },
    children: [
      {
        path: 'users/:type',
        component: ClientsComponent,
        title: 'Users',
        resolve: { config: ConfigResolver },
      },
      {
        path: 'roles',
        component: PoliciesComponenet,
        title: 'Roles',
        resolve: { config: ConfigResolver },
      },
      {
        path: 'modules',
        component: NewMenusComponent,
        title: 'Modules',
        resolve: { config: ConfigResolver },
      },
    ],
  },
  // {
  //   path: 'dash',
  //   component: DashComponent,
  //   children: [
  //     {
  //       path: 'feedback',
  //       component: feedbackComponent,
  //       title: 'Feedback',
  //       canActivate: [AuthGuard],
  //     },
  //     {
  //       path: 'home',
  //       component: HomeComponent,
  //       canActivate: [AuthGuard],
  //       resolve: { config: ConfigResolver },
  //     },
  //     {
  //       path: 'venue-details',
  //       component: VenueDetailsComponent,
  //       canActivate: [AuthGuard],
  //       resolve: { config: ConfigResolver },
  //     },
  //     {
  //       path: 'race-details',
  //       component: RaceDetailsComponent,
  //       canActivate: [AuthGuard],
  //       resolve: { config: ConfigResolver },
  //     },
  //     {
  //       path: 'race-overview',
  //       component: RaceOverviewComponent,
  //       canActivate: [AuthGuard],
  //       resolve: { config: ConfigResolver },
  //     },
  //     {
  //       path: 'pre-race',
  //       component: PreRaceComponent,
  //       canActivate: [AuthGuard],
  //       resolve: { config: ConfigResolver },
  //     },
  //     {
  //       path: 'device-list',
  //       component: DeviceListComponent,
  //       resolve: {cnnfig: ConfigResolver},
  //       canActivate: [AuthGuard],
  //     },
  //     {
  //       path: 'deputy',
  //       component: DeputyComponent,
  //       resolve: { config: ConfigResolver },
  //     },
  //     {
  //       path: 'settings',
  //       children: [
  //         {
  //           path: 'users',
  //           component: UsersComponent,
  //           title: 'Settings - Users',
  //           resolve: { data: DataResolver, config: ConfigResolver },
  //         },
  //         {
  //           path: 'profile',
  //           component: UserProfileComponent,
  //           title: 'Settings - Profile',
  //           resolve: { config: ConfigResolver },
  //         },
  //         {
  //           path: 'policies',
  //           component: PoliciesComponenet,
  //           title: 'Settings - Policies',
  //           resolve: { config: ConfigResolver },
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthGuard],
    resolve: { config: ConfigResolver },
    children: [
      {
        path: 'company',
        component: CompanyComponent,
        canActivate: [AuthGuard],
        title: 'company',
        resolve: { config: ConfigResolver },
      },
      {
        path: 'admin_client',
        component: AdClientComponent,
        canActivate: [AuthGuard],
        title: 'client',
        resolve: { config: ConfigResolver },
      },
      {
        path: 'driver',
        component: DriversComponent,
        canActivate: [AuthGuard],
        title: 'driver',
        resolve: { config: ConfigResolver },
      },
      {
        path: 'fuel_stations',
        component: FuelStationsComponent,
        canActivate: [AuthGuard],
        title: 'fuel_station',
        resolve: { config: ConfigResolver },
      },
      {
        path: 'vehicles',
        component: VehiclesComponent,
        canActivate: [AuthGuard],
        title: 'vehicles',
        resolve: { config: ConfigResolver },
      },
      {
        path: 'client_routes',
        component: ClientRoutesComponent,
        canActivate: [AuthGuard],
        title: 'client_routes',
        resolve: { config: ConfigResolver },
      },
      {
        path: 'attenders',
        component: AttendersComponent,
        canActivate: [AuthGuard],
        title: 'attenders',
        resolve: { config: ConfigResolver },
      },
      {
        path: 'mechanics',
        component: MechanicsComponent,
        canActivate: [AuthGuard],
        title: 'mechanics',
        resolve: { config: ConfigResolver },
      },
      {
        path: 'staff',
        component: StaffComponent,
        canActivate: [AuthGuard],
        title: 'staff',
        resolve: { config: ConfigResolver },
      },

    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
