<h1 *ngIf = "inProgress == 0" mat-dialog-title style = "color:red">WARNING</h1>
<h1 *ngIf = "inProgress != 0 && finish == false" mat-dialog-title style = "color:#1976d2">Prepare In Progress</h1>
<h1 *ngIf = "inProgress != 0 && finish == true && failed_step.length ==0" mat-dialog-title style = "color:#1976d2">Prepare Done</h1>
<h1 *ngIf = "inProgress != 0 && finish == true && failed_step.length !=0" mat-dialog-title style = "color:red">Prepare Failed</h1>
<div mat-dialog-content>
  <p *ngIf = "inProgress == 0">Please make sure that EQTraCe is not open</p>
  <p *ngIf = "inProgress != 0 && finish == false">Do not refresh or close this page, it will cause serious problem!</p>
  <p *ngIf = "inProgress != 0 && finish == true && failed_step.length ==0">Prepare progress done! click OK to redirect to Race Management!</p>

  <p *ngIf = "inProgress>0 && inProgress<2 && !failed_step.includes(1) && finish == false" style = "margin-top: 10px; color:rgb(209, 178, 0)">Downloading essential files from AWS s3...</p>
  <p *ngIf = "failed_step.includes(1)  && failed_step.length !=0 " style ="color: red;">Downloading failed! please contact someone for help</p>
  <p *ngIf = "inProgress>1 && !failed_step.includes(1) && finish == false" style = "margin-top: 10px; color:rgb(0, 255, 34)">Downloading essential files from AWS s3...<mat-icon aria-hidden="false" aria-label="Example done icon" fontIcon="done"></mat-icon></p>
  
  <p *ngIf = "inProgress>0 && inProgress<3 && !failed_step.includes(2) && finish == false" style = "margin-top: 10px; color:rgb(209, 178, 0)">Back up original EQTraCe on the PC...</p>
  <p *ngIf = "failed_step.includes(2) && failed_step.length !=0 " style ="color: red;">Backup failed! please contact someone for help</p>
  <p *ngIf = "inProgress>2 && !failed_step.includes(2) && finish == false" style = "margin-top: 10px; color:rgb(0, 255, 34)">Back up original EQTraCe on the PC...<mat-icon aria-hidden="false" aria-label="Example done icon" fontIcon="done"></mat-icon></p>
  
  <p *ngIf = "inProgress>0 && inProgress<4 && !failed_step.includes(3)  && finish == false" style = "margin-top: 10px; color:rgb(209, 178, 0)">Setting up config file...</p>
  <p *ngIf = "failed_step.includes(3) && failed_step.length !=0 " style ="color: red;">Setting config file failed! please contact someone for help</p>
  <p *ngIf = "inProgress>3 && !failed_step.includes(3)  && finish == false" style = "margin-top: 10px; color:rgb(0, 255, 34)">Setting up config file...<mat-icon aria-hidden="false" aria-label="Example done icon" fontIcon="done"></mat-icon></p>
  
  <p *ngIf = "inProgress>0 && inProgress<5 && !failed_step.includes(4) && finish == false " style = "margin-top: 10px; color:rgb(209, 178, 0)">Moving files to root folder of config file...</p>
  <p *ngIf = "failed_step.includes(4) && failed_step.length !=0 " style ="color: red;">Moving config files to root folder failed! please contact someone for help</p>
  <p *ngIf = "inProgress>4 && !failed_step.includes(4) && finish == false " style = "margin-top: 10px; color:rgb(0, 255, 34)">Moving files to root folder of config file...<mat-icon aria-hidden="false" aria-label="Example done icon" fontIcon="done"></mat-icon></p>
  
  <p *ngIf = "inProgress>0 && inProgress<6 && !failed_step.includes(5) && finish == false " style = "margin-top: 10px; color:rgb(209, 178, 0)">Editing database string...</p>
  <p *ngIf = "failed_step.includes(5) && failed_step.length !=0 " style ="color: red;">Editing database string failed! please contact someone for help</p>
  <p *ngIf = "inProgress>5 && !failed_step.includes(5) && finish == false " style = "margin-top: 10px; color:rgb(0, 255, 34)">Editing database string...<mat-icon aria-hidden="false" aria-label="Example done icon" fontIcon="done"></mat-icon></p>

  <p *ngIf = "inProgress>0 && inProgress<7 && !failed_step.includes(6) && finish == false " style = "margin-top: 10px; color:rgb(209, 178, 0)">Download and Restore bak file into database...</p>
  <p *ngIf = "failed_step.includes(6) && failed_step.length !=0 " style ="color: red;">SQL failed! maybe the database already exist. Please contact someone for help</p>
  <p *ngIf = "inProgress>6 && !failed_step.includes(6) && finish == false " style = "margin-top: 10px; color:rgb(0, 255, 34)">Download and Restore bak file into database...<mat-icon aria-hidden="false" aria-label="Example done icon" fontIcon="done"></mat-icon></p>

  <p *ngIf = "inProgress>0 && inProgress<8 && !failed_step.includes(7) && finish == false " style = "margin-top: 10px; color:rgb(209, 178, 0)">Setting up HRExporter file...</p>
  <p *ngIf = "failed_step.includes(7) && failed_step.length !=0 " style ="color: red;">Setting up HRExporter file failed! please contact someone for help</p>
  <p *ngIf = "inProgress>7 && !failed_step.includes(7) && finish == false " style = "margin-top: 10px; color:rgb(0, 255, 34)">Setting up HRExporter file...<mat-icon aria-hidden="false" aria-label="Example done icon" fontIcon="done"></mat-icon></p>

  <p *ngIf = "inProgress>0 && inProgress<9 && !failed_step.includes(8) && finish == false " style = "margin-top: 10px; color:rgb(209, 178, 0)">Setting up LTS and Mastering file...</p>
  <p *ngIf = "failed_step.includes(8) && failed_step.length !=0 " style ="color: red;">Setting up Mastering file failed! please contact someone for help</p>
  <p *ngIf = "inProgress>8 && !failed_step.includes(8) && finish == false " style = "margin-top: 10px; color:rgb(0, 255, 34)">Setting up LTS and Mastering file...<mat-icon aria-hidden="false" aria-label="Example done icon" fontIcon="done"></mat-icon></p>
 
  <p *ngIf = "inProgress>0 && inProgress<10 && !failed_step.includes(9) && finish == false " style = "margin-top: 10px; color:rgb(209, 178, 0)">Clean up Cache...</p>
  <p *ngIf = "failed_step.includes(9) && failed_step.length !=0 " style ="color: red;">Clean up failed! please contact someone for help</p>
  <p *ngIf = "inProgress>9 && !failed_step.includes(9) && finish == false " style = "margin-top: 10px; color:rgb(0, 255, 34)">Clean up Cache...<mat-icon aria-hidden="false" aria-label="Example done icon" fontIcon="done"></mat-icon></p>

  <mat-progress-bar *ngIf = "inProgress == 1 && finish == false" mode="determinate" value="10"></mat-progress-bar>
  <mat-progress-bar *ngIf = "inProgress == 2 && finish == false" mode="determinate" value="20"></mat-progress-bar>
  <mat-progress-bar *ngIf = "inProgress == 3 && finish == false" mode="determinate" value="40"></mat-progress-bar>
  <mat-progress-bar *ngIf = "inProgress == 4 && finish == false" mode="determinate" value="50"></mat-progress-bar>
  <mat-progress-bar *ngIf = "inProgress == 5 && finish == false" mode="determinate" value="60"></mat-progress-bar>
  <mat-progress-bar *ngIf = "inProgress == 6 && finish == false" mode="determinate" value="70"></mat-progress-bar>
  <mat-progress-bar *ngIf = "inProgress == 7 && finish == false" mode="determinate" value="80"></mat-progress-bar>
  <mat-progress-bar *ngIf = "inProgress == 8 && finish == false" mode="determinate" value="90"></mat-progress-bar>
  <mat-progress-bar *ngIf = "inProgress == 9 && finish == false" mode="determinate" value="100"></mat-progress-bar>

  <div *ngIf = "inProgress == 0" style = "height: 20px;"></div>
  <p *ngIf = "failed_check.length != 0" style="color:red">You can't login now! because {{failed_check}}, please close this window and recheck</p>
</div>
<div mat-dialog-actions>
  <button *ngIf = "inProgress == 0 && failed_check.length == 0" mat-button (click)="onYesClick()" cdkFocusInitial>Yes I'm ready</button>
  <button *ngIf = "inProgress == 0 && failed_check.length == 0" mat-button (click)="onNoClick()">No I'm not</button>
  <button *ngIf = "inProgress == 0 && failed_check.length != 0" mat-button (click)="onNoClick()">close</button>

  <button mat-button *ngIf = "finish && failed_step.length ==0" [mat-dialog-close]="data.location" cdkFocusInitial>Ok</button>
  <button *ngIf = "finish && failed_step.length !=0" mat-button (click)="onRerunClick()">Rerun</button>
</div>
