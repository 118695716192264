import { Component, EventEmitter, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APIService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { environment } from 'src/environments/environment';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ElementRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { FileUploadService } from 'src/app/services/fileupload.service';
import * as XLSX from 'xlsx';
import { getLocaleFirstDayOfWeek } from '@angular/common';
declare var bootstrap: any;
@Component({
  selector: 'app-fuel-stations',
  templateUrl: './fuel-stations.component.html',
  styleUrls: ['./fuel-stations.component.css'],
})
export class FuelStationsComponent {
  myForm: any;
  // minEndDate: string = '';
  documentInputErrors: { [key: string]: string | null } = {};
  isOpen: boolean = false;
  isSubmitted: boolean = false;
  urlPrefix: string | null = null;
  config: any;
  addcom: boolean = false;
  result: any;
  selectedCompany: any;
  showModal: boolean = false;
  isEditMode: boolean = false;
  loading: boolean = false;
  matchingUrls: any;
  fetchUrls: any;
  fileNames: any[] = [];
  orderedUrls: any[] = [];
  sendUrls: any[] = [];
  updated: any = {};
  submitted: boolean = false;
  fleetTouched: boolean = false;
  clientData: any;
  uniqueFirstColumnValues = new Set<string>();
  company_code: string = ''; // Declare cmpCodeSet as a Set
  clientIds: any[] = [];
  @ViewChild('fileInput') fileInput!: ElementRef;
  transfer: any;
  transformedData: any[] = [];
  // bulkUpload: boolean = false;
  start_time: any;
  end_time: any;
  bulkFileUpload: boolean = false;
  bulkUploadFormValues: any[] = [];
  isButtonDisabled: boolean = true;
  pagination: any = { perPage: 10, page: 1 };
  pages: any = [10, 50, 100, 200];
  pageChange: EventEmitter<number> | undefined;
  access = localStorage.getItem('acT');

  constructor(
    private fb: FormBuilder,
    private apiService: APIService,
    public notifier: NotifierService,
    private uploadFile: FileUploadService,
    private route: ActivatedRoute
  ) {
    this.config = this.route.snapshot.data['config'];
  }

  headers: any = [
    { id: 'fs_name', name: 'Fuel Station', sort: { sort: true, direction: 'Asc' } },
    { id: 'fuel_company', name: 'Fuel Company', sort: { sort: true, direction: 'Asc' } },
    { id: 'fuel_types', name: 'Fuel Types Available', sort: { sort: true, direction: 'Asc' } },
    { id: 'location', name: 'Location', sort: { sort: true, direction: 'Asc' } },
    { id: 'contact_person', name: 'Contact Person', sort: { sort: true, direction: 'Asc' } },
    { id: 'contact_no', name: 'Contact Number', sort: { sort: true, direction: 'Asc' } },
    // { id: 'status', name: 'Status', sort: { sort: true, direction: 'Asc' } },
  ];


  async ngOnInit() {
    await this.fetchfuel();



    this.myForm = this.fb.group({
      fs_name: ['', [Validators.required, this.alphabetsAndSpacesValidator()]],
      fs_id: ['', Validators.required],
      fuel_company: ['', [Validators.required]],
      fuel_types: ['', [Validators.required]],
      // wrk_hrs: ['', [Validators.required]],
      location: ['', [Validators.required, Validators.maxLength(500)]],
      status: [''],
      contact_person: ['', [Validators.required]],
      contact_no: [
        '',
        [Validators.required, Validators.pattern('^[0-9]{10,13}$')],
      ],
      email: ['', [Validators.email]],
      sec_adv_deposite: [''],
      payment_method: this.fb.array([]),
      bank_account_details: this.fb.array([this.createBankAccount()]),
      fleet_card_upload: [''],
      payment_term: [''],
      billing_period: [''],
      start_weekday: [''],
      start_time: [''],
      end_weekday: [''],
      end_time: [''],
    });
    this.addCheckboxes();
  }

  createBankAccount() {
    return this.fb.group({
      acc_no: [''],
      ifsc_code: [''],
      bank_name: [''],
      bank_address: [''],
      name: [''],
    });
  }

  alphabetsAndSpacesValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const valid = /^[A-Za-z\s]*$/.test(control.value);
      return valid ? null : { alphabetsAndSpaces: true };
    };
  }

  markBankAccountDetailsArray() {
    this.bankAccountDetailsArray.controls.forEach((bank) => {
      bank.markAllAsTouched();
    });
  }

  get bankAccountDetailsArray(): FormArray {
    return this.myForm.get('bank_account_details') as FormArray;
  }

  onAddBank: boolean = false;
  addBankAccount(): void {
    this.onAddBank = true;
    this.bankAccountDetailsArray.push(this.createBankAccount());
    console.log('after adding a bank account', this.bankAccountDetailsArray);
  }

  removeBankAccount(index: number): void {
    this.bankAccountDetailsArray.removeAt(index);
  }


  get paymentArray() {
    return this.myForm.controls.payment_method as FormArray;
  }

  private addCheckboxes() {
    ['Bank Transfer', 'Fleet Card'].forEach(() =>
      this.paymentArray.push(this.fb.control(false))
    );
  }

  presenceList(list: string[], targetValues: string[]): boolean[] {
    const presence: boolean[] = [];

    // Iterate through the target values
    for (const value of targetValues) {
      presence.push(list.includes(value));
    }

    return presence;
  }

  counter: number = 1;
  generateRandomAlphanumeric(length: number): string {
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  }

  onFsCmpChange() {
    const fuelStation = this.myForm.get('fs_name')?.value || '';
    const fuelCompany = this.myForm.get('fuel_company')?.value || '';
    const fsId = `${fuelStation}_${fuelCompany}`;
    this.myForm.get('fs_id')?.setValue(fsId);
  }

  onFsStatChange() {
    const fuelStation = this.myForm.get('fs_name')?.value || '';
    const fuelCompany = this.myForm.get('fuel_company')?.value || '';
    const fsId = `${fuelStation}_${fuelCompany}`;
    this.myForm.get('fs_id')?.setValue(fsId);
  }

  popUp: boolean = false;
  popup_header: any = '';
  popup_subtitle: any = '';

  showPopup(header: any, title: any) {
    this.popup_header = header;
    this.popup_subtitle = title;
    this.popUp = true;
  }

  hidepopup(event: any) {
    this.popUp = false;
  }

  async get_excel_url() {
    this.notifier.loading(true);

    let data: any = {
      action: 'get_presign_url_of_excel',
    };
    let header = {
      Authorization: localStorage.getItem('t'),
      // 'X-Api-Key': localStorage.getItem('clientid'),
    };

    //https://api.swayam.cloud/v3/admin/users/fetchaccountgroupsv2
    let apiURL = `${this.config.apiURL}/admin/fuelstations`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.s == '1' || result.status == '1') {
      // console.log(result);

      let pre_url = result.data;
      console.log('Presign Url for', pre_url);
      let a = document.createElement('a');
      a.href = pre_url;
      a.download = 'true';
      a.setAttribute('target', '_blank');
      a.click();
      // this.notifier.alert('Success', '', result.data, 'success', 5000);
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
    this.notifier.loading(false);
  }

  openFileExplorer() {
    this.fileInput.nativeElement.click();
  }

  async fileSelected(event: any) {
    console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@');
    const selected_File: File = event.target.files[0];
    console.log('selected File is ', selected_File);

    if (selected_File) {
      console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!');
      // Get the file name and extension
      const fileName: string = selected_File.name;
      const fileExtension: string = fileName.split('.').pop() || '';

      // Check if the file extension is valid
      if (fileExtension === 'xlsx' || fileExtension === 'xls') {
        console.log('Valid Excel file selected');
        this.bulkFileUpload = true;
        // Read the Excel file
        const reader = new FileReader();
        reader.onload = async (e: ProgressEvent<FileReader>) => {
          const data_of_file: any | ArrayBuffer | null = e.target?.result;
          if (typeof data_of_file === 'string') {
            // Parse the Excel file content
            const workbook = XLSX.read(data_of_file, { type: 'binary' });
            // Assuming there's only one sheet, you can change this according to your requirement
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];
            // Convert the sheet to JSON
            const jsonData = XLSX.utils.sheet_to_json(worksheet);
            // Now you can validate the content as per your requirements
            console.log('Excel content:', jsonData);

            await this.transformData(jsonData);
            // console.log('transformed data:', this.transformedData);
            // this.clearFileInput()
            // let isnotValid = this.validateData(jsonData)
            // console.log('isValid', isnotValid)
            // if (isnotValid.length !== 0) {
            //   this.notifier.alert('Info', '', 'Please provide all Fields and with correct Information', 'info', 5000);
            //   return
            // }
            // else {
            //   console.log('')
            //   this.bulkUpload(jsonData)
            // }
            // Here you can  jsonData
            // For example, check if certain columns or values are present
          } else {
            console.error('Failed to read the file.');
          }
        };
        reader.readAsBinaryString(selected_File);
      } else {
        console.error('Invalid file format. Please select an Excel file.');
        this.notifier.alert(
          'Info',
          '',
          'Please Select valid Excel file',
          'info',
          5000
        );
        this.clearFileInput();
      }
    } else {
      console.error('No file selected.');
    }
  }

  clearFileInput() {
    this.fileInput.nativeElement.value = '';
  }

  isFractionalTime(time: string): boolean {
    const fractionalTimeRegex = /^\d+(\.\d+)?$/;
    return fractionalTimeRegex.test(time);
  }

  fractionalTimeToAMPM(fractionalTime: number): string {
    const totalMinutesInDay = 24 * 60;
    const totalMinutes = fractionalTime * totalMinutesInDay;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = Math.floor(totalMinutes % 60);
    const period = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const formattedTime = `${formattedHours}:${formattedMinutes}${period}`;
    return formattedTime;
  }

  formatWorkingHours(startWeekday: string, endWeekday: string): string {
    console.log(startWeekday);

    if (startWeekday && endWeekday) {
      return `${startWeekday} ${this.start_time.toString()} to ${endWeekday} ${this.end_time.toString()}`;

    } else {
      console.log("format working hours");
      return '';
    }
  }

  async transformData(jsonData: any) {
    // Extract values from the jsonData
    console.log('Inside transformData fn');
    this.transformedData = [];
    jsonData.forEach((object: any, index: number) => {
      // Access properties of each object
      console.log(`Object ${index + 1}:`);
      console.log(`${object['Start Time']}  ${object['End Time']}`);

      // console.log('Property 1:', object['Company Code* | Client ID*'].split(' | ')[0]); {
      if (
        (object['Start Time'] && object['End Time']) ||
        object['Start Time'] == 0 ||
        object['End Time'] == 0
      ) {
        console.log('aaaaa');

        if (
          this.isFractionalTime(object['Start Time']) &&
          this.isFractionalTime(object['End Time'])
        ) {
          this.start_time = this.fractionalTimeToAMPM(object['Start Time']);
          this.end_time = this.fractionalTimeToAMPM(object['End Time']);
          console.log('bbbbb');
        } else {
          // Check if the start time is in the format of '10AM'
          if (/^\d{1,2}(AM|PM)$/i.test(object['Start Time'])) {
            // Extract hour and AM/PM indicator from the start time
            let startTimeComponents =
              object['Start Time'].match(/^(\d{1,2})(AM|PM)$/i);
            let startHour = parseInt(startTimeComponents[1]);
            let startAmPm = startTimeComponents[2];
            // Format the start time as 'HH:00 AM/PM'
            this.start_time =
              (startHour < 10 ? '0' : '') +
              startHour +
              ':00' +
              startAmPm.toUpperCase();
          } else {
            // Otherwise, keep the start time as it is
            this.start_time = object['Start Time'];
          }

          // Check if the end time is in the format of '10AM' or '10PM'
          if (/^\d{1,2}(AM|PM)$/i.test(object['End Time'])) {
            // Extract hour and AM/PM indicator from the end time
            let endTimeComponents =
              object['End Time'].match(/^(\d{1,2})(AM|PM)$/i);
            let endHour = parseInt(endTimeComponents[1]);
            let endAmPm = endTimeComponents[2];
            // Format the end time as 'HH:00 AM/PM'
            this.end_time =
              (endHour < 10 ? '0' : '') +
              endHour +
              ':00' +
              endAmPm.toUpperCase();
          } else {
            // Otherwise, keep the end time as it is
            this.end_time = object['End Time'];
          }
        }
      } else {
        this.start_time = '';
        this.end_time = '';
      }

      console.log(`${this.start_time}  ${this.end_time}`);

      // console.log(`${this.start_time} ${this.end_time}`)
      this.transformedData.push({
        action: 'add_fuel_station',
        fs_name: object['Fuel Station Name*'].toString(),
        fuel_company: object['Fuel Company*'].toString(),
        fs_id: `${object['Fuel Station Name*'].toString()}_${object['Fuel Company*'].toString()}`,
        fuel_types: object['Fuel Types*'].toString(),
        working_hrs: this.formatWorkingHours(
          object['Start Weekday (Working hours)'],
          object['End Weekday']
        ), // Ensure working hours are formatted correctly
        location: object['Location*'].toString(),
        status: object['Status'] ? object['Status'].toString() : '',
        contact_person: object['Contact Person*'].toString(),
        contact_no: object['Contact Number*'].toString(),
        email: object['Email Address']
          ? object['Email Address'].toString()
          : '',
        sec_adv_deposite: object['Security Advance Deposit']
          ? object['Security Advance Deposit'].toString()
          : '',
        acc_no: object['Account Number']
          ? object['Account Number'].toString()
          : '',
        payment_method: object['Accepted Payment Methods*'],
        bank_acc_details: [{
          acc_no: object['Account Number'] ? object['Account Number'] : '',
          ifsc_code: object['IFSC Code'] ? object['IFSC Code'].toString() : '',
          bank_name: object['Bank Name'] ? object['Bank Name'].toString() : '',
          bank_address: object['Bank Address']
            ? object['Bank Address'].toString()
            : '',
          name: object['Name As Per Bank Account']
            ? object['Name As Per Bank Account'].toString()
            : '',
        }], // Assuming this.transfer is already a string
        payment_term: object['Payment Terms']
          ? object['Payment Terms'].toString()
          : '',
        billing_period: object['Billing Period']
          ? object['Billing Period'].toString()
          : '',
      });
      if (object['Start Weekday(Working hours)'] && object['End Weekday']) {
        this.transformedData[
          this.transformedData.length - 1
        ].working_hrs = `${object[
          'Start Weekday(Working hours)'
        ].toString()} ${this.start_time.toString()} to ${object[
          'End Weekday'
        ].toString()} ${this.end_time.toString()}`;
      }
    });

    console.log(this.fileInfoArray);
    console.log(this.transformedData);
  }

  async fetch(file_paths: Array<any>) {
    console.log('inside fetch file fn');
    let data: any = {
      action: 'fetch_file',
      // company_code: this.myForm.get('companyCode').value,
      file_names: file_paths,
    };
    let header = {
      Authorization: localStorage.getItem('t'),
      // 'X-Api-Key': localStorage.getItem('clientid'),
    };

    let apiURL = `${this.config.apiURL}/admin/fuelstations`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.s == '1' || result.status == '1') {
      // console.log(result);
      this.fetchUrls = result.data;
      console.log(this.fetchUrls);
      // this.mapFetchedFileNames(this.fetchUrls)
      // interface MatchingUrls {
      //     [key: string]: string;
      // }
      // const fileNames
      this.fileNames = [];
      this.fileNames = Object.keys(this.fetchUrls);

      // Now keysArray contains only the keys of this.fetchUrls
      console.log('keys: ', this.fileNames);

      // console.log("Presigned urls for files:",this.fetchUrls)
      // Assuming this.matchingUrls is declared somewhere in your code
      this.matchingUrls = {};

      for (const key in this.fetchUrls) {
        if (Object.prototype.hasOwnProperty.call(this.fetchUrls, key)) {
          if (key.includes('fleet_card')) {
            this.matchingUrls['fleet_card'] = this.fetchUrls[key];
          }
        }
      }

      console.log('Matching URL:', this.matchingUrls);

      const order = ['fleet_card'];

      // Create an array of objects based on the defined order
      this.orderedUrls = order.map((key) => ({
        [key]: this.matchingUrls[key],
      }));

      console.log('Ordered URLs:', this.orderedUrls);
    }

    // console.log("File URLs: ", this.fetchUrls)
    else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
  }

  index: number = 0;

  addCom() {
    this.addcom = true;
    this.fileNames = [];
    this.bankAccountDetailsArray.clear();
    // this.myForm.get('company_code').enable({ onlySelf: true });
  }

  async populateForm(selectedCompany: any) {
    console.log('inside the populate form');
    console.log(selectedCompany);
    const working_hrs = selectedCompany.working_hrs
      ? selectedCompany.working_hrs.split(' ')
      : '';
    console.log('Working hrs', working_hrs);
    // console.log(selectedCompany.payment_method);

    if (this.bulkFileUpload) {
      console.log('inside the populate form');
      const startTime = working_hrs && working_hrs[1] !== ''
        ? this.convertTo24HourFormat(working_hrs[1])
        : '';
      const endTime = working_hrs && working_hrs[4] !== ''
        ? this.convertTo24HourFormat(working_hrs[4])
        : '';
      this.index = this.transformedData.indexOf(selectedCompany);
      console.log('index', this.index);
      this.fileInfoArray = this.transformedData[this.index].fleet_card_s3_path ? this.transformedData[this.index].fleet_card_s3_path : '';
      // this.fileNames = this.transformedData[this.index].fleet_card_s3_path ? this.transformedData[this.index].fleet_card_s3_path : '';
      console.log('this.fileInfoArray', this.fileInfoArray);
      const str_chkbox: string = selectedCompany.payment_method;
      const delimiter: string = ', '; // Delimiter can be any character or string
      const list_chkbox: string[] = str_chkbox.split(delimiter);
      const presence: boolean[] = this.presenceList(list_chkbox, ['Bank Transfer', 'Fleet Card']);
      console.log('lang known:', presence)

      // console.log('Send urls', this.sendUrls);
      this.myForm.patchValue({
        // company_code: selectedCompany.company_code,
        // client_id: selectedCompany.client_id,
        fs_name: selectedCompany.fs_name,
        fs_id: selectedCompany.fs_id,
        fuel_company: selectedCompany.fuel_company,
        fuel_types: selectedCompany.fuel_types,
        start_weekday: working_hrs ? working_hrs[0] : '',
        start_time: startTime,
        end_weekday: working_hrs ? working_hrs[3] : '',
        end_time: endTime,
        location: selectedCompany.location,
        status: selectedCompany.status,
        contact_person: selectedCompany.contact_person,
        contact_no: selectedCompany.contact_no,
        email: selectedCompany.email,
        sec_adv_deposite: selectedCompany.sec_adv_deposite,
        // bank_acc_details: {
        //   acc_no: selectedCompany.acc_no,
        //   ifsc_code: selectedCompany.ifsc_code,
        //   bank_name: selectedCompany.bank_name,
        //   bank_address: selectedCompany.bank_address,
        //   name: selectedCompany.name,
        // },
        payment_method: presence,
        fleet_card_s3_path:
          selectedCompany.payment_method.includes('Fleet Card')
            ? this.fileInfoArray[0] : '',
        payment_term: selectedCompany.payment_term,
        billing_period: selectedCompany.billing_period,
        // fleet_card_upload: this.fileNames[0]
      });
      const bankFormArray = this.myForm.get('bank_account_details') as FormArray;
      bankFormArray.clear();
      // console.log(
      //   'On edit length of bank',
      //   selectedCompany.bank_acc_details.length
      // );
      console.log("selectedCompany.bank_acc_details", selectedCompany.bank_acc_details);

      if (selectedCompany.bank_acc_details && selectedCompany.bank_acc_details.length > 0) {
        const bankFormArray = this.myForm.get('bank_account_details') as FormArray;
        selectedCompany.bank_acc_details.forEach((bank: any) => {
          const bankGroup = this.createBankAccount();
          bankGroup.patchValue({
            acc_no: bank.acc_no,
            ifsc_code: bank.ifsc_code,
            bank_name: bank.bank_name,
            bank_address: bank.bank_address,
            name: bank.name,
          });
          bankFormArray.push(bankGroup);
        });
      }
    } else {
      this.sendUrls = [];
      this.sendUrls.push(selectedCompany.fleet_card_s3_path);
      console.log(this.sendUrls);
      console.log("selectedCompany", selectedCompany);

      // const payment_method_split = selectedCompany.payment_method.split(', ')
      const str_chkbox: string = selectedCompany.payment_method;
      const delimiter: string = ', '; // Delimiter can be any character or string
      const list_chkbox: string[] = str_chkbox.split(delimiter);
      const presence: boolean[] = this.presenceList(list_chkbox, ['Bank Transfer', 'Fleet Card']);
      console.log('lang known:', presence)
      // console.log("URL:", this.fetchUrls);
      selectedCompany.payment_method.includes('Fleet Card') === true
        ? await this.fetch(this.sendUrls)
        : '';
      const startTime = working_hrs
        ? this.convertTo24HourFormat(working_hrs[1])
        : '';
      const endTime = working_hrs
        ? this.convertTo24HourFormat(working_hrs[4])
        : '';

      await this.myForm.patchValue({
        fs_name: selectedCompany.fs_name,
        fs_id: selectedCompany.fs_id,
        fuel_company: selectedCompany.fuel_company,
        fuel_types: selectedCompany.fuel_types,
        start_weekday: working_hrs ? working_hrs[0] : '',
        start_time: startTime,
        end_weekday: working_hrs ? working_hrs[3] : '',
        end_time: endTime,
        location: selectedCompany.location,
        status: selectedCompany.status,
        contact_person: selectedCompany.contact_person,
        contact_no: selectedCompany.contact_no,
        email: selectedCompany.email,
        sec_adv_deposite: selectedCompany.sec_adv_deposite,
        // acc_no: selectedCompany.acc_no,
        // ifsc_code: selectedCompany.ifsc_code,
        // bank_name: selectedCompany.bank_name,
        // bank_address: selectedCompany.bank_address,
        // name: selectedCompany.name,
        payment_method: presence,
        fleet_card_s3_path:
          selectedCompany.payment_method.includes('Fleet Card')
            ? this.fileNames[0]
            : '',
        payment_term: selectedCompany.payment_term,
        billing_period: selectedCompany.billing_period,
      });

      const bankFormArray = this.myForm.get('bank_account_details') as FormArray;
      bankFormArray.clear();
      // console.log(
      //   'On edit length of bank',
      //   selectedCompany.bank_acc_details.length
      // );
      console.log("selectedCompany.bank_acc_details", selectedCompany.bank_acc_details);

      if (selectedCompany.bank_acc_details && selectedCompany.bank_acc_details.length > 0) {
        const bankFormArray = this.myForm.get('bank_account_details') as FormArray;
        selectedCompany.bank_acc_details.forEach((bank: any) => {
          const bankGroup = this.createBankAccount();
          bankGroup.patchValue({
            acc_no: bank.acc_no,
            ifsc_code: bank.ifsc_code,
            bank_name: bank.bank_name,
            bank_address: bank.bank_address,
            name: bank.name_as_per_bank,
          });
          bankFormArray.push(bankGroup);
        });
      }
    }
  }

  clearForm(): void {
    this.myForm.reset();
    this.addcom = false;
    this.isEditMode = false;
    this.submitted = false;
    if (this.bulkFileUpload === true) {
      this.bulkFileUpload === true;
    }
    this.fileInfoArray = [];
    this.orderedUrls = [];
    this.updateForm = false;
  }

  previewDocument(input: HTMLInputElement, doc: string) {
    const file = input.files?.[0];
    console.log(file);
    if (file) {
      const fileType = file.name.split('.').pop()?.toLowerCase() || '';
      if (fileType === 'pdf' || this.isImageFile(fileType)) {
        const reader = new FileReader();
        reader.onload = () => {
          const blob = new Blob([reader.result as ArrayBuffer], {
            type: file.type,
          });
          const url = URL.createObjectURL(blob);
          const viewer = document.createElement('iframe');
          viewer.src = url;
          viewer.style.width = '100%';
          viewer.style.height = '100%';
          viewer.style.border = 'none';
          const newWindow = window.open();
          if (newWindow) {
            newWindow.document.body.appendChild(viewer);
            newWindow.document.title = file.name;
            newWindow.focus();
          } else {
            console.log('Failed to open preview window');
          }
        };
        reader.readAsArrayBuffer(file);
      } else {
        console.log('Unsupported file type for preview');
      }
    } else if (this.matchingUrls && this.matchingUrls[doc] && this.isEditMode) {
      // If no file is uploaded, show file from URL
      const url = this.matchingUrls[doc];
      const viewer = document.createElement('iframe');
      viewer.style.width = '100%';
      viewer.style.height = '100%';
      viewer.style.border = 'none';
      const newWindow = window.open();
      if (newWindow) {
        newWindow.document.title = doc;
        viewer.src = url;
        newWindow.document.body.appendChild(viewer.cloneNode(true));
        newWindow.focus();
      } else {
        console.log('Failed to open preview window');
      }
    } else if (this.bulkFileUpload && this.isEditMode) {
      console.log(
        'file',
        this.transformedData[this.index].fleet_card_s3_path[0].file.name
      );
      const fileType =
        this.transformedData[this.index].fleet_card_s3_path[0].file.name
          .split('.')
          .pop()
          ?.toLowerCase() || '';
      if (fileType === 'pdf' || this.isImageFile(fileType)) {
        const reader = new FileReader();
        reader.onload = () => {
          const blob = new Blob([reader.result as ArrayBuffer], {
            type: this.transformedData[this.index].fleet_card_s3_path[0].file
              .type,
          });
          const url = URL.createObjectURL(blob);
          const viewer = document.createElement('iframe');
          viewer.src = url;
          viewer.style.width = '100%';
          viewer.style.height = '100%';
          viewer.style.border = 'none';
          const newWindow = window.open();
          if (newWindow) {
            newWindow.document.body.appendChild(viewer);
            newWindow.document.title =
              this.transformedData[this.index].fleet_card_s3_path[0].file.name;
            newWindow.focus();
          } else {
            console.log('Failed to open preview window');
          }
        };
        reader.readAsArrayBuffer(
          this.transformedData[this.index].fleet_card_s3_path[0].file
        );
      } else {
        console.log('Unsupported file type for preview');
      }
    } else {
      console.log('No file selected');
    }
  }

  reset(element: any, filename: string) {
    element.value = '';
    this.updated[filename] = undefined;
  }

  fileInfoArray: {
    file_name: string;
    ext: string;
    file: File;
    fieldName: string;
  }[] = [];

  filesArray: File[] = [];
  updateForm: boolean = false;
  progressValue: number = 0;
  progress: any = {};

  onFileSelected(fieldName: string, filename: string, event: any, progress_state?: boolean, tempRefVar?: any) {
    this.updated[filename] = true;
    this.progress[filename] = true;
    console.log(this.updated);
    // console.log('Field Name:', fieldName);
    // console.log('Event:', event);
    const file = event.target.files?.[0];
    // this.filesArray = []
    // this.filesArray.push(file)
    if (!this.fileInfoArray) {
      this.fileInfoArray = [];
    }


    console.log('Selected File:', file);
    if (file) {
      // Get the file name without extension
      const fileNameWithoutExtension = file.name
        .split('.')
        .slice(0, -1)
        .join('.');
      console.log('File Name:', fileNameWithoutExtension);

      // Get the file extension
      const fileExtension = file.name.split('.').pop()?.toLowerCase() || '';
      console.log('File Extension:', fileExtension);

      if (file.size > 20 * 1024 * 1024) {
        this.documentInputErrors[fieldName] =
          'File size exceeds the limit (20MB)';
        tempRefVar.value = '';
        this.updated[filename] = false;
      } else {
        if (
          !this.isImageFile(fileExtension) &&
          file.type !== 'application/pdf'
        ) {
          this.documentInputErrors[fieldName] =
            'Unsupported file type. Only PDF and images are allowed.';
          tempRefVar.value = '';
          this.updated[filename] = false;
        } else {
          this.documentInputErrors[fieldName] = null;
          this.progressValue = 0;
          this.fileInfoArray = [];
          this.fileInfoArray.push({
            file_name: filename,
            ext: fileExtension,
            file: file,
            fieldName: fieldName,
          });
          this.progressValue = 45;

          setTimeout(() => {
            this.progressValue = 80;
            setTimeout(() => {
              this.progressValue = 100;
              setTimeout(() => {
                console.log("fileExist is now false after 1.5 seconds");
                this.progress[filename] = false;
              }, 500);

            }, 500);

          }, 500);
          console.log('File Info Array:', this.fileInfoArray);
        }
      }
    } else {
      this.documentInputErrors[fieldName] = 'No file selected';
      console.log('No file selected');
    }
  }


  deleteDocument(filename: string, temRefVar?: any) {
    // this.fileNames[0] = undefined;
    // this.updated[filename] = {}
    console.log("filename", filename);

    this.updated[filename] = false;
    temRefVar.value = '';
    console.log('this.fileNames', this.fileNames);

    // this.delete[doc_name] = true;

  }


  deleteDocumentInUpdate(filename: string) {
    // this.fileNames[0] = undefined;
    if (!this.readOnlyVar) {
      for (let i = 0; i < this.fileNames.length; i++) {
        console.log("this.fileNames", this.fileNames);

        if (this.fileNames.length > 0) {
          if (this.fileNames[i] && (this.fileNames[i]?.file_name?.includes(filename) || this.fileNames[i]?.includes(filename))) {
            // Set the array element to undefined if filename matches
            this.fileNames[i] = undefined;
            break;
          }
        }
      }
      console.log("filename", filename);

      this.updated[filename] = false;

      console.log('this.fileNames', this.fileNames);

    }

  }
  deleteDocumentInUpdateForBulkUp(filename: string) {
    // this.fileNames[0] = undefined;
    if (!this.readOnlyVar) {
      for (let i = 0; i < this.fileInfoArray.length; i++) {
        if (this.fileInfoArray[i] && this.fileInfoArray[i].file_name!.includes(filename)) {
          // Set the array element to undefined if filename matches
          this.fileInfoArray = [];
          break;
        }
      }
      console.log("filename", filename);

      this.updated[filename] = false;

      console.log('this.fileNames', this.fileInfoArray);

    }

  }

  isImageFile(fileType: string): boolean {
    return ['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(fileType);
  }

  toggleSidenav() {
    this.isOpen = !this.isOpen;
  }

  fleetClicked: boolean = false;

  // Function to set fleetClicked to true
  setFleetClickedTrue() {
    this.fleetClicked = false;
  }

  onFileSelectedForBulkUp(fieldName: string, filename: string, event: any) {
    this.updated[filename] = true;
    this.fleetClicked = true;
    console.log('this.updated', this.updated);
    const file = event.target.files?.[0];
    console.log('Selected File:', file);
    if (file) {
      const fileNameWithoutExtension = file.name
        .split('.')
        .slice(0, -1)
        .join('.');
      console.log('File Name:', fileNameWithoutExtension);

      // Get the file extension
      const fileExtension = file.name.split('.').pop()?.toLowerCase() || '';
      console.log('File Extension:', fileExtension);

      if (file.size > 20 * 1024 * 1024) {
        this.documentInputErrors[fieldName] =
          'File size exceeds the limit (20MB)';
      } else {
        if (
          !this.isImageFile(fileExtension) &&
          file.type !== 'application/pdf'
        ) {
          this.documentInputErrors[fieldName] =
            'Unsupported file type. Only PDF and images are allowed.';
        } else {
          this.documentInputErrors[fieldName] = null;

          this.fileInfoArray = [];
          this.fileInfoArray.push({
            file_name: filename,
            ext: fileExtension,
            file: file,
            fieldName: fieldName,
          });
          // }

          // Log the entire fileInfoArray
          console.log('File Info Array:', this.fileInfoArray);
        }
      }
    } else {
      this.documentInputErrors[fieldName] = 'No file selected';
      console.log('No file selected');
    }
  }

  async onSaveAddBulkUpValues(formValues: any, files?: any) {
    const paymentMethod = this.myForm.value.payment_method.some((element: boolean) => element === true);
    console.log('paymentMethod', paymentMethod);

    if (!paymentMethod) {
      this.notifier.alert(
        'Info',
        '',
        'Please fill all the mandatory fields and choose at least one payment method',
        'info',
        5000
      );
    }
    else if (
      this.myForm.value.payment_method[0] == true &&
      this.bankAccountDetailsArray.value.some((details: { acc_no: any; ifsc_code: any; bank_name: any; bank_address: any; name: any; }) =>
        !details.acc_no ||
        !details.ifsc_code ||
        !details.bank_name ||
        !details.bank_address ||
        !details.name)) {
      this.notifier.alert(
        'Info',
        '',
        'Please fill all the fields in Bank Transfer',
        'info',
        5000
      );
    }
    else if (this.myForm.value.payment_method[1] === true && this.fileInfoArray.length == 0) {
      this.notifier.alert(
        'Info',
        '',
        'Please upload file for Fleet Card',
        'info',
        5000
      );
    }
    else if (this.myForm.valid && paymentMethod) {
      this.fleetClicked = false;
      const formValues = this.myForm.value;
      console.log("this.transformedData", this.transformedData);

      let indexToDelete = this.transformedData.findIndex((obj) => obj.fs_id == formValues.fs_id);
      // Delete element from the array
      if (indexToDelete !== -1) {
        this.transformedData.splice(indexToDelete, 1);
      }
      let payload_files: any = {};
      if (files) {
        files.forEach((file: any) => {
          // Iterate over each field in the form
          payload_files[file.fieldName] = file.formcontro_name;
        });
      }
      // console.log(formValues.start);
      let data: any;
      if (formValues.start_time != '') {
        this.formattedStartTime = this.convertTo12HourFormat(
          formValues.start_time
        );
      }
      if (formValues.end_time != '') {
        this.formattedEndTime = this.convertTo12HourFormat(formValues.end_time);
      }
      const selectedOptions = this.myForm.value.payment_method
        .map((checked: boolean, index: number) =>
          checked ? ['Bank Transfer', 'Fleet Card'][index] : null
        )
        .filter((value: string | null) => value !== null);
      let concatenatedOptions: string = selectedOptions.join(', ');
      console.log(concatenatedOptions);
      this.transformedData.splice(indexToDelete, 0, {
        action: 'add_fuel_station',
        // company_code: formValues.company_code,
        // client_id: formValues.client_id,
        fs_id: formValues.fs_id,
        fs_name: formValues.fs_name,
        fuel_company: formValues.fuel_company,
        fuel_types: formValues.fuel_types,
        working_hrs:
          formValues.start_weekday || formValues.end_weekday
            ? `${formValues.start_weekday} ${this.formattedStartTime} to ${formValues.end_weekday} ${this.formattedEndTime}`
            : '',
        location: formValues.location,
        status: formValues.status ? formValues.status : '',
        contact_person: formValues.contact_person,
        contact_no: formValues.contact_no,
        email: formValues.email ? formValues.email : '',
        sec_adv_deposite: formValues.sec_adv_deposite
          ? formValues.sec_adv_deposite
          : '',
        payment_method: concatenatedOptions
          ? concatenatedOptions
          : '',
        fleet_card_s3_path:
          formValues.payment_method[1] === true
            ? this.fileInfoArray
            : '',
        bank_acc_details: formValues.bank_account_details.map((bank: any) => {
          return {
            acc_no: bank.acc_no,
            ifsc_code: bank.ifsc_code,
            bank_name: bank.bank_name,
            bank_address: bank.bank_address,
            name: bank.name
          };
        }),
        payment_term: formValues.payment_term ? formValues.payment_term : '',
        billing_period: formValues.billing_period
          ? formValues.billing_period
          : '',
      })
      this.isEditMode = false;
      this.addcom = false;
      this.bulkFileUpload = true;

    } else {
      this.notifier.alert(
        'Info',
        '',
        'Please fill all the mandatory fields',
        'info',
        5000
      );
    }
    console.log('After pushing to array', this.transformedData);
  }

  data: any[] = [];
  async onSubmitAllDataBulkUp(files?: any) {
    const allValid = this.transformedData.every((object: any) =>
      object['payment_method'].includes('Fleet Card') || object['payment_method'].includes('Bank Transfer')
    );

    if (!allValid) {
      this.notifier.alert(
        'Info',
        '',
        'Please fill all the mandatory fields',
        'info',
        5000
      );
      return;
    }

    // Clear data before populating
    this.data = [];

    for (const object of this.transformedData) {
      if (object['payment_method'].includes('Fleet Card')) {
        try {
          await this.file_upload(object);
          console.log(`Successfully uploaded file for: ${object}`);
        } catch (error) {
          console.error(`Error uploading file for: ${object}`, error);
        }
      } else {
        this.data.push(object);
        console.log("data", this.data);

      }
    }

    let header = {
      Authorization: localStorage.getItem('t'),
      // 'X-Api-Key': localStorage.getItem('clientid'),
    };

    let apiURL = `${this.config.apiURL}/admin/fuelstations`;
    if (this.data.length > 0) {
      try {
        let result = await this.apiService.postDataPromis(
          apiURL,
          this.data,
          header
        );
        if (result.s == '1' || result.status == '1') {
          this.submitted = false;
          this.isEditMode = false;
          this.addcom = false;
          this.clearForm();
          this.fileInfoArray = [];
          this.fetchfuel();
          this.fileNames = [];
          this.updated = {};
          this.bulkFileUpload = false;
          console.log(result);
          this.data = [];
          this.notifier.alert('Success', '', result.data, 'success', 5000);
        } else {
          this.submitted = false;
          this.isEditMode = false;
          this.addcom = false;
          this.clearForm();
          this.fileInfoArray = [];
          this.fetchfuel();
          this.fileNames = [];
          this.updated = {};
          this.bulkFileUpload = false;
          console.log(result);
          this.data = [];
          this.notifier.alert('Info', '', result.error, 'info', 5000);

        }
      } catch (error) {
        this.submitted = false;
        this.isEditMode = false;
        this.addcom = false;
        this.clearForm();
        this.fileInfoArray = [];
        this.fetchfuel();
        this.fileNames = [];
        this.updated = {};
        this.bulkFileUpload = false;
        console.error('API error:', error);
        this.notifier.alert('Info', '', "", 'info', 5000);
      }
    }
  }


  async onSubmit() {
    this.submitted = true;
    Object.values(this.myForm.controls).forEach((control: any) => {
      control.markAsTouched();
    });
    this.counter++;
    this.isSubmitted = true;
    console.log('Forms values', this.myForm.value);
    console.log('Form', this.myForm);
    console.log('Form Status', this.myForm.status);
    console.log('isEditMode', this.isEditMode);

    if (this.bulkFileUpload && this.isEditMode) {
      // console.log(this.myForm.get('company_code').value)
      await this.onSaveAddBulkUpValues(this.myForm.value);
      // console.log('form values:', this.myForm.value);
    } else if (this.isEditMode) {
      if (this.myForm.get('payment_method').value[1] === true) {
        console.log(this.myForm.get('payment_method').value);
        this.notifier.loading(true);
        await this.file_upload();
        this.notifier.loading(false);
      } else {
        this.notifier.loading(true);
        await this.updateFuel();
        this.notifier.loading(false);
      }
    } else {
      if (this.myForm.get('payment_method').value[1] === true) {
        console.log(this.myForm.get('payment_method').value);
        console.log('file_upload');

        this.file_upload();
      } else {
        console.log(this.myForm.get('payment_method').value);
        this.addfuel();
      }
    }
  }

  // onSubmit() {
  //   Object.values(this.myForm.controls).forEach((control: any) => {
  //     control.markAsTouched();
  //   });

  //   this.isSubmitted = true;
  //   console.log("Forms values", this.myForm.value);
  //   console.log("Form", this.myForm);
  //   console.log("Form Status", this.myForm.status);
  //   if (this.isEditMode) {
  //     this.updateClientroute();
  //   } else {
  //     this.addfuel(files);
  //   }
  //   this.fetchfuel();
  // }

  async file_upload(object?: any) {
    // console.log(`${this.bulkFileUpload}   ${this.isEditMode}`)
    console.log('this.fileInfoArray', this.fileInfoArray);
    console.log('this.fileNames', this.fileNames);

    if (this.myForm.value.payment_method[1] == true && (this.fileInfoArray.length === 0 && this.fileNames.length === 0)) {
      this.notifier.alert(
        'Info',
        '',
        'Please Upload file for Fleet Card',
        'info',
        5000
      );
    }
    else if (this.bulkFileUpload && !this.isEditMode) {
      console.log('object', object);
      let data: any = {
        action: 'file_upload',
        // company_code: object['company_code'],
        fs_id: object['fs_id'],
        files: object.fleet_card_s3_path.map((file: any) => {
          return { file_name: file.file_name, ext: file.ext };
        }),
      };
      console.log("data", data)
      let header = {
        Authorization: localStorage.getItem('t'),
        // 'X-Api-Key': localStorage.getItem('clientid'),
      };

      let apiURL = `${this.config.apiURL}/admin/fuelstations`;

      let result = await this.apiService.postDataPromis(apiURL, data, header);

      if (result.s == '1' || result.status == '1') {
        console.log("result", result);

        this.uploadFiles(result.data, object.fleet_card_s3_path, object);
      } else {
        this.notifier.alert('Info', '', result.error, 'info', 5000);
      }
    } else if (this.myForm.valid) {
      let data: any = {
        action: 'file_upload',
        // company_code: this.myForm.get('company_code').value,
        fs_id: this.myForm.get('fs_id').value,
        files: this.fileInfoArray.map((file: any) => {
          return { file_name: file.file_name, ext: file.ext };
        }),
      };
      console.log(data);
      let header = {
        Authorization: localStorage.getItem('t'),
        // 'X-Api-Key': localStorage.getItem('clientid'),
      };

      let apiURL = `${this.config.apiURL}/admin/fuelstations`;

      let result = await this.apiService.postDataPromis(apiURL, data, header);

      if (result.s == '1' || result.status == '1') {
        // console.log(result);

        this.uploadFiles(result.data, this.fileInfoArray, object);
      } else {
        this.notifier.alert('Info', '', result.error, 'info', 5000);
      }
    } else {
      this.notifier.alert('Info', '', 'Form is not Valid', 'info', 5000);
    }
  }

  fileUploaded: boolean = false;

  uploadFiles(data: any, files: any, object?: any) {
    console.log('inside the upload file');
    let promises: Promise<any>[] = [];
    Object.keys(data).forEach((file_name: string, index: number) => {
      // const key = Object.keys(data).find(key => files[index].file_name.includes(key));
      // if (key) {
      //   console.log("object as filename : ", key);
      // }
      files[index]['formcontro_name'] = file_name;

      files[index]['status'] = 'pending';
      console.log(data[file_name]['presigned_url']);
      promises.push(
        this.uploadFileAPI(files[index], data[file_name]['presigned_url'])
      );
    });

    Promise.all(promises).then((values: any) => {
      // Save Company
      if (this.isEditMode) {
        this.updateFuel(files);
      } else if (!this.isEditMode && this.bulkFileUpload) {
        this.fleetCardObjectUpload(object, files);
        console.log('files', files);
      } else {
        this.addfuel(files);
      }
    });
  }

  uploadFileAPI(file: any, url: string): Promise<any> {
    console.log('inside the upload file API');

    return new Promise(async (resolve: any, reject: any) => {
      this.uploadFile
        .uploadFile(url, file['file'])
        .subscribe(async (event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              break;
            case HttpEventType.ResponseHeader:
              break;
            case HttpEventType.UploadProgress:
              file['status'] = 'uploading';
              let progress = Math.round(
                (event.loaded / (event.total || file['file']['size'])) * 100
              );
              file.loaded = event.loaded;
              file.percentage = progress;
              break;
            case HttpEventType.Response:
              file['status'] = 'completed';
              resolve(true);
          }
        });
    });
  }

  async fleetCardObjectUpload(object: any, files: any) {
    let payload_files: any = {};
    if (files) {
      files.forEach((file: any) => {
        // Iterate over each field in the form
        payload_files[file.fieldName] = file.formcontro_name;
      });
    }
    object['fleet_card_s3_path'] = payload_files['fleet_card_upload'];
    console.log('object', object);
    let data = []
    data = [object];
    let header = {
      Authorization: localStorage.getItem('t'),
      // 'X-Api-Key': localStorage.getItem('clientid'),
    };

    let apiURL = `${this.config.apiURL}/admin/fuelstations`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.s == '1' || result.status == '1') {
      console.log(result);
      this.submitted = false;
      this.isEditMode = false;
      this.addcom = false;
      this.clearForm();
      this.fileInfoArray = [];
      this.fetchfuel();
      this.fileNames = [];
      this.updated = {};
      this.bulkFileUpload = false;
      this.notifier.alert('Success', '', result.data, 'success', 5000);
    } else {
      this.submitted = false;
      this.isEditMode = false;
      this.addcom = false;
      this.clearForm();
      this.fileInfoArray = [];
      this.fetchfuel();
      this.fileNames = [];
      this.updated = {};
      this.bulkFileUpload = false;
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
    // console.log(object)
  }

  convertTo12HourFormat(time: string): string {
    const [hours, minutes] = time.split(':');
    let hour = parseInt(hours, 10);
    const period = hour >= 12 ? 'PM' : 'AM';
    hour = hour % 12 || 12;
    const formattedHour = hour < 10 ? '0' + hour : hour;
    return `${formattedHour}:${minutes}${period}`;
  }


  convertTo24HourFormat(time12Hour: string): string {
    const [time, period] = time12Hour.split(/(?<=\d)(AM|PM)/);
    let [hour, minute] = time.split(':').map(Number);
    if (period === 'PM' && hour < 12) {
      hour += 12;
    } else if (period === 'AM' && hour === 12) {
      hour = 0;
    }
    return `${hour.toString().padStart(2, '0')}:${minute
      .toString()
      .padStart(2, '0')}`;
  }

  formattedStartTime: string = '';
  formattedEndTime: string = '';

  async addfuel(files?: any) {
    console.log('Inside add Fuel fn');

    const paymentMethod = this.myForm.value.payment_method.some((element: boolean) => element === true);
    console.log('paymentMethod', paymentMethod);

    if (!paymentMethod) {
      // this.notifier.alert(
      //   'Info',
      //   '',
      //   'Please fill all the mandatory fields and choose at least one payment method',
      //   'info',
      //   5000
      // );
    }
    else if (
      this.myForm.value.payment_method[0] == true &&
      this.bankAccountDetailsArray.value.some((details: { acc_no: any; ifsc_code: any; bank_name: any; bank_address: any; name: any; }) =>
        !details.acc_no ||
        !details.ifsc_code ||
        !details.bank_name ||
        !details.bank_address ||
        !details.name)) {
      this.notifier.alert(
        'Info',
        '',
        'Please fill all the fields in Bank Transfer',
        'info',
        5000
      );
    }
    else if (this.myForm.valid && paymentMethod) {
      const formValues = this.myForm.value;
      let payload_files: any = {};
      if (files) {
        files.forEach((file: any) => {
          payload_files[file.fieldName] = file.formcontro_name;
        });
      }
      let data: any;
      console.log('this.start_time', formValues.start_time);
      console.log('this.end_time', formValues.end_time);

      if (formValues.start_time) {
        this.formattedStartTime = this.convertTo12HourFormat(
          formValues.start_time
        );
      }
      if (formValues.end_time) {
        this.formattedEndTime = this.convertTo12HourFormat(formValues.end_time);
      }
      const selectedOptions = this.myForm.value.payment_method
        .map((checked: boolean, index: number) =>
          checked ? ['Bank Transfer', 'Fleet Card'][index] : null
        )
        .filter((value: string | null) => value !== null);
      let concatenatedOptions: string = selectedOptions.join(', ');
      console.log(concatenatedOptions);
      data = [
        {
          action: 'add_fuel_station',
          fs_id: formValues.fs_id,
          fs_name: formValues.fs_name,
          fuel_company: formValues.fuel_company,
          fuel_types: formValues.fuel_types,
          working_hrs:
            formValues.start_weekday || formValues.end_weekday
              ? `${formValues.start_weekday} ${this.formattedStartTime} to ${formValues.end_weekday} ${this.formattedEndTime}`
              : '',
          location: formValues.location,
          status: formValues.status ? formValues.status : '',
          contact_person: formValues.contact_person,
          contact_no: formValues.contact_no,
          email: formValues.email ? formValues.email : '',
          sec_adv_deposite: formValues.sec_adv_deposite
            ? formValues.sec_adv_deposite
            : '',
          payment_method: concatenatedOptions
            ? concatenatedOptions
            : '',
          fleet_card_s3_path:
            formValues.payment_method[1] === true
              ? payload_files['fleet_card_upload']
              : '',
          bank_acc_details: formValues.bank_account_details.map((bank: any) => {
            return {
              acc_no: bank.acc_no,
              ifsc_code: bank.ifsc_code,
              bank_name: bank.bank_name,
              bank_address: bank.bank_address,
              name: bank.name
            };
          }),
          payment_term: formValues.payment_term ? formValues.payment_term : '',
          billing_period: formValues.billing_period
            ? formValues.billing_period
            : '',
        },
      ];
      console.log('payment_method', this.myForm.get('payment_method').value);

      let header = {
        Authorization: localStorage.getItem('t'),
      };

      let apiURL = `${this.config.apiURL}/admin/fuelstations`;

      let result = await this.apiService.postDataPromis(apiURL, data, header);

      console.log("added fuel station");

      if (result.s == '1' || result.status == '1') {
        this.showPopup(
          'Fuel Station Added',
          'The Fuel Station has been successfully added.'
        );
        this.submitted = false;
        this.isEditMode = false;
        this.addcom = false;
        this.clearForm();
        this.fileInfoArray = [];
        this.fetchfuel();
        this.fileNames = [];
        this.updated = {};
      } else {
        this.notifier.alert('Info', '', result.error, 'info', 5000);
      }
    }
    else {
      this.notifier.alert(
        'Info',
        '',
        'Please fill all the mandatory fields',
        'info',
        5000
      );
    }
    // this.notifier.loading(false);
  }

  async updateFuel(files?: any) {
    console.log('this.bankAccountDetailsArray.value', this.bankAccountDetailsArray.value);

    const paymentMethod = this.myForm.value.payment_method.some((element: boolean) => element === true);
    console.log('paymentMethod', paymentMethod);

    if (!paymentMethod) {
      this.notifier.alert(
        'Info',
        '',
        'Please fill all the mandatory fields and choose at least one payment method',
        'info',
        5000
      );
    }
    else if (
      this.myForm.value.payment_method[0] == true &&
      this.bankAccountDetailsArray.value.some((details: { acc_no: any; ifsc_code: any; bank_name: any; bank_address: any; name: any; }) =>
        !details.acc_no ||
        !details.ifsc_code ||
        !details.bank_name ||
        !details.bank_address ||
        !details.name
      )) {
      this.notifier.alert(
        'Info',
        '',
        'Please fill all the fields in Bank Transfer',
        'info',
        5000
      );
    }
    else if (this.myForm.valid) {
      let payload_files: any = {};
      if (files) {
        if (files.length > 0) {
          files.forEach((file: any) => {
            payload_files[file.fieldName] = file.formcontro_name;
          });
        } else {
          payload_files = { fleet_card_upload: this.fileNames[0] };
          console.log(this.fileNames[0]);
          console.log(payload_files);
        }
      }

      const formValues = this.myForm.getRawValue();
      const selectedOptions = this.myForm.value.payment_method
        .map((checked: boolean, index: number) =>
          checked ? ['Bank Transfer', 'Fleet Card'][index] : null
        )
        .filter((value: string | null) => value !== null);
      let concatenatedOptions: string = selectedOptions.join(', ');
      console.log(concatenatedOptions);
      let data: any;
      if (formValues.start_time != '') {
        this.formattedStartTime = this.convertTo12HourFormat(
          formValues.start_time
        );
      }
      if (formValues.end_time != '') {
        this.formattedEndTime = this.convertTo12HourFormat(formValues.end_time);
      }
      data = [
        {
          action: 'update_fuel_station_data',
          fs_id: formValues.fs_id,
          fs_name: formValues.fs_name,
          fuel_company: formValues.fuel_company,
          fuel_types: formValues.fuel_types,
          working_hrs:
            formValues.start_weekday || formValues.end_weekday
              ? `${formValues.start_weekday} ${this.formattedStartTime} to ${formValues.end_weekday} ${this.formattedEndTime}`
              : '',
          location: formValues.location,
          status: formValues.status ? formValues.status : '',
          contact_person: formValues.contact_person,
          contact_no: formValues.contact_no,
          email: formValues.email ? formValues.email : '',
          sec_adv_deposite: formValues.sec_adv_deposite
            ? formValues.sec_adv_deposite
            : '',
          payment_method: concatenatedOptions
            ? concatenatedOptions
            : '',
          fleet_card_s3_path:
            formValues.payment_method[1] === true
              ? payload_files['fleet_card_upload']
              : '',
          bank_acc_details: formValues.bank_account_details.map((bank: any) => {
            return {
              acc_no: bank.acc_no,
              ifsc_code: bank.ifsc_code,
              bank_name: bank.bank_name,
              bank_address: bank.bank_address,
              name: bank.name
            };
          }),
          payment_term: formValues.payment_term ? formValues.payment_term : '',
          billing_period: formValues.billing_period
            ? formValues.billing_period
            : '',
        },
      ];
      console.log('payment_method', this.myForm.get('payment_method').value);


      let header = {
        Authorization: localStorage.getItem('t'),
        // 'X-Api-Key': localStorage.getItem('clientid'),
      };

      //https://api.swayam.cloud/v3/admin/users/fetchaccountgroupsv2
      let apiURL = `${this.config.apiURL}/admin/fuelstations`;

      let result = await this.apiService.postDataPromis(apiURL, data, header);

      if (result.s == '1' || result.status == '1') {
        // console.log(result);
        // this.submitted = false;
        // this.isEditMode = false;
        // this.addcom = false;
        // this.clearForm();
        // this.fileInfoArray = [];
        // this.fetchfuel();
        this.showPopup(
          'Fuel Station Updated',
          'The Fuel Station has been successfully updated.'
        );
        this.updated = {};
        this.submitted = false;
        this.isEditMode = false;
        this.addcom = false;
        this.fileInfoArray = [];
        this.fetchfuel();
        this.clearForm();

        // this.notifier.alert('Success', '', result.data, 'success', 5000);
        // this.fetchfuel();
      } else {
        this.notifier.alert('Info', '', result.error, 'info', 5000);
      }
    } else {
      this.notifier.alert(
        'Info',
        '',
        'Please fill all the mandatory fields',
        'info',
        5000
      );
    }
  }


  backend_pagination: any = { page: 1, per_page: 10 };

  async fetchfuel() {
    this.notifier.loading(true);

    let allData: any[] = [];
    let hasMoreData = true;

    this.backend_pagination.page = 1; // Ensure pagination starts from the first page

    while (hasMoreData) {
      let data = {
        action: 'fetch_fuel_stations_data',
        page: this.backend_pagination.page,
        per_page: this.backend_pagination.per_page,
      };

      let header = {
        Authorization: localStorage.getItem('t'),
      };

      let apiURL = `${this.config.apiURL}/admin/fuelstations`;

      try {
        let result = await this.apiService.postDataPromis(apiURL, data, header);

        if (result.s == '1' || result.status == '1') {
          let fetchedData = result.data;

          allData = allData.concat(fetchedData);
          this.backend_pagination.page++;

          hasMoreData = fetchedData.length === this.backend_pagination.per_page;
          this.filteredData = allData;
          this.notifier.loading(false);

        } else {
          this.notifier.alert('Info', '', result.error, 'info', 5000);
          hasMoreData = false;
        }
      } catch (error) {
        this.notifier.alert('Error', '', 'Failed to fetch data', 'error', 5000);
        hasMoreData = false;
      }
    }

    this.backend_pagination.page = 1; // Reset the page counter
    this.result = allData;
    this.filteredData = allData;
    console.log('fetch in fuel stations', allData);
  }

  openModal(company: any) {
    this.selectedCompany = company;
    this.showModal = true;
    this.expandedIndex = null;
  }

  hideModal() {
    this.showModal = false;
  }

  toggleEditMode(): void {
    this.isEditMode = !this.isEditMode;
  }

  filteredData: any = [];
  queryResult(event: any) {
    let queryString: string = (event.target.value || '').toLowerCase();
    if (!queryString) {
      this.filteredData = this.result;
      return;
    }

    this.filteredData = this.result.filter((item: any) => {
      return this.headers.filter((h: any) => {
        return (
          h.id.includes('vehicles_details')
            ? item.vehicles_details[h.id.split('.')[1]]
            : item[h.id]
        )
          .toLowerCase()
          .includes(queryString);
      }).length;
    });
  }
  expandedButton: boolean = false;

  toggleOptionsExport() {
    console.log('toggling..');
    this.expandedButton = !this.expandedButton;
    console.log('this.expandedButton', this.expandedButton);
  }

  toggleOptionsExportFalse() {
    // console.log('toggling..');
    this.expandedButton = false;
    // console.log('this.expandedButton', this.expandedButton);
  }

  // toggleOptionsFalse(index: number) {
  //   console.log('clicked outside for toggle options');
  //   this.expandedIndex = this.expandedIndex === index ? null : index;

  //   // this.expandedIndex = null;
  // }

  exportToExcel(
    data: any[],
    filename: string,
    excludedColumns: string[] = []
  ): void {
    const flattenedData: any[] = [];
    const customHeaders: { [key: string]: string } = {
      fs_name: 'Fuel Station Name',
      fs_id: 'Fuel Station ID',
      fuel_company: 'Fuel Company',
      fuel_types: 'Fuel Types',
      working_hrs: 'Working Hours',
      location: 'Location',
      status: 'Status',
      contact_person: 'Contact Person',
      contact_no: 'Contact Number',
      email: 'Email',
      sec_adv_deposite: 'Security Advance Deposit',
      payment_method: 'Payment Method',
      payment_term: 'Payment Term',
      billing_period: 'Billing Period',
      acc_no: 'Account Number',
      ifsc_code: 'IFSC Code',
      bank_name: 'Bank Name',
      bank_address: 'Bank Address',
      name: 'Name As Per Bank Account',
      bank_acc_details: 'Bank Account'
      // Add custom headers for other fields as needed
    };
    // console.log(excludedColumns);

    // Flatten the nested objects and arrays
    data.forEach((item) => {
      const flattenedItem: any = {};

      Object.keys(item).forEach((key) => {
        if (!excludedColumns.includes(key)) {
          // Exclude the column if it's specified in excludedColumns
          // console.log(`${key}   ${excludedColumns}   ${excludedColumns.includes(key)}`);

          if (typeof item[key] === 'object' && !Array.isArray(item[key])) {
            Object.keys(item[key]).forEach((subKey) => {
              if (!excludedColumns.includes(subKey)) {
                // console.log(`${subKey}   ${excludedColumns}   ${!excludedColumns.includes(subKey)}`);
                flattenedItem[customHeaders[subKey] || subKey] =
                  item[key][subKey];
              }
            });
          } else if (Array.isArray(item[key])) {
            item[key].forEach((subItem: any, index: number) => {
              Object.keys(subItem).forEach((subKey) => {
                flattenedItem[
                  `${customHeaders[key] || key} ${index + 1} - ${customHeaders[subKey] || subKey
                  }`
                ] = subItem[subKey];
              });
            });
          } else {
            flattenedItem[customHeaders[key] || key] = item[key];
          }
        }
      });

      flattenedData.push(flattenedItem);
    });

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(flattenedData);

    // Create workbook and add the worksheet
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Fuel Stations Data');

    // Save the workbook to a file
    XLSX.writeFile(wb, `${filename}.xlsx`);
  }

  toggleToHome() {
    this.bulkFileUpload = false;
  }

  fetchDataAndExport(): void {
    // Simulate fetching data from the backend
    const fetchedData = this.result; // Your fetched data array here
    let excludedColumns = ['fleet_card_s3_path'];
    // Call exportToExcel function with fetched data and desired filename
    this.exportToExcel(fetchedData, 'Fuel Stations Details', excludedColumns);
  }

  expandedIndex: number | null = null;

  toggleOptions(index: number) {
    this.expandedIndex = this.expandedIndex === index ? null : index;
    console.log(`${this.expandedIndex}    ${index}`);

  }

  openModalForConfirm(modalId: string) {
    const modalElement = document.getElementById(modalId);
    if (modalElement) {
      const modal = new bootstrap.Modal(modalElement);
      modal.show();
    }
  }
  // latitude: number = 0;
  // longitude: number = 0;
  // openGoogleMaps(): void {
  //   this.latitude = 15.39369974029548
  //   this.longitude = 75.09087298157161
  //   const url = `https://www.google.com/maps?q=${this.latitude},${this.longitude}`;
  //   const url = `https://maps.app.goo.gl/B5cFSQVME8kQV38i7`;
  //   window.open(url, '_blank');
  // }
  // handleOutsideClick() {
  //   console.log('clicked outside of edit');
  //   this.expandedIndex = null;
  // }

  closeEditMode() {
    this.myForm.enable();
    this.myForm.reset();
    this.isEditMode = false;
    this.readOnlyVar = false;
  }

  readOnlyVar: boolean = false;

  async readOnly(selectedCompany: any) {
    this.notifier.loading(true);
    this.readOnlyVar = true;

    console.log("this.myForm", this.myForm);
    console.log('inside edit company');

    this.updateForm = true;
    this.fileNames = []
    this.isEditMode = true;
    this.expandedIndex = null;

    console.log("this.bulkFileUpload", this.bulkFileUpload)

    await this.populateForm(selectedCompany);

    this.myForm.disable();
    console.log("this.progress", this.progress);
    console.log("this.fileNames", this.fileNames);
    this.notifier.loading(false);
  }


  async editfuel(selectedCompany: any): Promise<void> {
    this.notifier.loading(true);
    // this.bulkFileUpload = true
    // this.myForm.get('company_code').enable({ onlySelf: true });
    this.fileNames = []
    this.expandedIndex = null;
    this.updateForm = true;
    console.log('inside edit client');
    this.isEditMode = true;
    await this.populateForm(selectedCompany); // Pass selected company object to populateForm
    this.addcom = true; // Set flag to indicate edit mode
    this.notifier.loading(false);
  }
}
