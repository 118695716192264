<div class="pophold">
  <span class="heading">{{ role.dept_name }} Details</span>
  <table class="table">
    <thead><tr>
      <th class="text-nowrap">Contact Name</th>
      <th class="text-nowrap">Contact Designation</th>
      <th class="text-nowrap">Contact Number</th>
      <th class="text-nowrap">Contact Email</th></tr>
    </thead>
    <tbody>
      <tr>
        <td class="tdStyle">{{ role.contact_name }}</td>
        <td class="tdStyle">{{ role.contact_designation }}</td>
        <td class="tdStyle">{{ role.contact_number }}</td>
        <td class="tdStyle">{{ role.contact_email }}</td>
      </tr>
    </tbody>
  </table>
</div>
