<div class="card">
  <div class="card-header flex-between">
    <div class="flex-start">
      <button class="btn btn-light" (click)="close(false)">
        <div class="flex-start padding-0">
          <i class="fa fa-angle-left" aria-hidden="true"></i>
          <span>Back</span>
        </div>
      </button>
      <div class="flex-start padding-0">
        <div>
          Users
        </div>
        <div>
          <i class="fa fa-angle-right" aria-hidden="true"></i>
        </div>
        <div>
          {{ user['emaiId'] }}
        </div>
        <ng-container *ngIf="selectedUser">
          <div>
            <i class="fa fa-angle-right" aria-hidden="true"></i>
          </div>
          <div>
            {{ userDetails['emaiId'] }}
          </div>
        </ng-container>
      </div>
    </div>
    <div class="flex-start" *ngIf="userDetails['userType'] != 'superAdmin'">
      <!-- <button *ngIf="!loading['resend']" class="btn btn-light" (click)="sendResendLink(userDetails)">
        Resend Link
      </button> -->
      <div *ngIf="loading['resend']">
        <img src="../assets/img/loading_2.svg" alt="loading scans" class="width-18 margin-0" />
      </div>
      <button class="btn" *ngIf="!loading['status']"
        [ngClass]="{'btn-danger-soft': userDetails['accountStatus'] == 'active', 'btn-success-soft': userDetails['accountStatus'] != 'active'}"
        (click)="disableUser = userDetails['emaiId']">
        {{ userDetails['accountStatus'] == 'active' ? 'Disable' : 'Enable' }}
      </button>
      <div *ngIf="loading['status']">
        <img src="../assets/img/loading_2.svg" alt="loading scans" class="width-18 margin-0" />
      </div>
      <button class="btn btn-primary-soft" (click)="setUpdateUser()">Edit</button>
      <!-- <button class="btn btn-primary-soft" (click)="viewAudit()" *ngIf="ut == 'admin'">View Audit</button> -->
    </div>
  </div>
  <div class="card-body">
    <nav>
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <button (click)="currentPage = 'details'" class="nav-link" [ngClass]="{active: currentPage == 'details'}"
          id="details-tab" data-bs-toggle="tab" data-bs-target="#details" type="button" role="tab"
          aria-controls="details" aria-selected="true">Details</button>
        <button (click)="currentPage = 'users'" *ngIf="userDetails['userType'] != 'user'" class="nav-link"
          [ngClass]="{active: currentPage == 'users'}" id="users-tab" data-bs-toggle="tab" data-bs-target="#users"
          type="button" role="tab" aria-controls="users" aria-selected="false">Users</button>
      </div>
    </nav>
    <div class="tab-content" id="nav-tabContent">
      <div class="tab-pane show active" [ngClass]="{show: currentPage == 'details', active: currentPage == 'details'}"
        id="details" role="tabpanel" aria-labelledby="details-tab">
        <div class="cert">
          <div class="cert-body">
            <div class="grid-3">
              <div class="validation-method">
                <div class="text-bold font-size-1">Name</div>
                <div class="small-text font-size-1">
                  {{userDetails['clientName']}}
                </div>
              </div>
              <div class="validation-method">
                <div class="text-bold font-size-1">Email ID</div>
                <div class="small-text font-size-1">
                  {{userDetails['emaiId']}}
                </div>
              </div>
              <div class="validation-method">
                <div class="text-bold font-size-1">Phone Number</div>
                <div class="small-text font-size-1">
                  {{userDetails['mobileNumber']}}
                </div>
              </div>
              <div class="validation-method">
                <div class="text-bold font-size-1">Company Name</div>
                <div class="small-text font-size-1">
                  {{userDetails['clientCmpName']}}
                </div>
              </div>
              <div class="validation-method">
                <div class="text-bold font-size-1">User Type</div>
                <div class="small-text font-size-1">
                  {{userTypes[userDetails['userType']]}}
                </div>
              </div>
              <div class="validation-method">
                <div class="text-bold font-size-1">Created By</div>
                <div class="small-text font-size-1">
                  {{userDetails['masterClientId'] || userId}}
                </div>
              </div>
              <div class="validation-method">
                <div class="text-bold font-size-1">MFA Status</div>
                <div class="small-text font-size-1 flex-start padding-0"
                  [ngClass]="{red_fancy: !userDetails['otpReq'], green_fancy: userDetails['otpReq']}">
                  <div *ngIf="!userDetails['otpReq']">
                    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                  </div>
                  <div *ngIf="userDetails['otpReq']">
                    <i class="fa fa-lock" aria-hidden="true"></i>
                  </div>
                  <div>
                    {{userDetails['otpReq'] ? 'Enabled': 'Disabled'}}
                  </div>
                </div>
              </div>
              <div class="validation-method">
                <div class="text-bold font-size-1">Account Status</div>
                <div class="small-text font-size-1 flex-start padding-0"
                  [ngClass]="{red_fancy: userDetails['accountStatus'] != 'active', green_fancy: userDetails['accountStatus'] == 'active'}">
                  <div *ngIf="userDetails['accountStatus'] != 'active'">
                    <i class="fa fa-times" aria-hidden="true"></i>
                  </div>
                  <div *ngIf="userDetails['accountStatus'] == 'active'">
                    <i class="fa fa-check" aria-hidden="true"></i>
                  </div>
                  <div>
                    {{userDetails['accountStatus'] == 'active' ? 'Active': 'Disabled'}}
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="loading['details']" class="center">
              <img *ngIf="loading['details']" src="../assets/img/loading_2.svg" alt="loading scans" class="width-18" />
            </div>
          </div>
        </div>
      </div>
      <div class="tab-pane" id="users" role="tabpanel" aria-labelledby="users-tab"
        *ngIf="userDetails['userType'] != 'user'" [ngClass]="{active: currentPage == 'users'}">
        <div class="flex-end">
          <button class="btn btn-success-soft" (click)="export()" *ngIf="userList.length > 0">Export</button>
          <input autocomplete="off" type="text" class="form-control width-250" placeholder="Search..."
            [(ngModel)]="filterText">
        </div>
        <table class="table table-docs table-hover" id="users-table">
          <thead>
            <tr>
              <th>S.N.</th>
              <th *ngFor="let h of urlPrefix == 'client' ? userHeaders: adminUsersheader">{{h.name}}</th>
              <th *ngIf="urlPrefix == 'client' && ut == 'admin'">User Type</th>
              <th *ngIf="urlPrefix == 'client' && ut == 'admin'">Created By</th>
            </tr>
          </thead>

          <tbody *ngIf="filteredUserList().length > 0">
            <tr *ngFor="let i of filteredUserList(); let j = index">
              <td>{{j+1}}</td>
              <td *ngFor="let h of urlPrefix == 'client' ? userHeaders: adminUsersheader">
                <span *ngIf="h.click" class="nav-link" (click)="callFunction(h.click, i)">
                  <span (click)="callFunction(h.click,i)">
                    {{i[h.id]}}
                  </span>
                </span>
                <span *ngIf="!h.click">
                  {{i[h.id]}}
                </span>
              </td>

              <td *ngIf="urlPrefix == 'client' && ut == 'admin'">{{ userTypes[i['userType']] || '-' }}</td>
              <td *ngIf="urlPrefix == 'client' && ut == 'admin'">{{ i['masterClientId'] || '-' }}</td>
            </tr>
          </tbody>
          <tbody *ngIf="loading['users']">
            <tr>
              <td class="text-center"
                [attr.colspan]="(urlPrefix == 'client' ? userHeaders.length: adminUsersheader.length) + 3 + (urlPrefix == 'client' && ut == 'admin' ? 2 : 0)">
                Looking for Users...
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="!loading['users'] && filteredUserList().length == 0">
            <tr>
              <td class="text-center"
                [attr.colspan]="(urlPrefix == 'client' ? userHeaders.length: adminUsersheader.length) + 3 + (urlPrefix == 'client' && ut == 'admin' ? 2 : 0)">
                No Users Found
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

</div>

<update-user *ngIf="writeAccess && updateUser" [action]="'update'" [userDetails]="updateUser"
  (hideModel)="closeModal($event)"></update-user>

<app-disable-component [email]="disableUser"
  [action]="this.userDetails['accountStatus'] == 'active' ? 'Disable': 'Enable'" *ngIf="disableUser"
  (hideModal)="switchStatus($event)"></app-disable-component>