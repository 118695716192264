<style>
    :host {
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 14px;
      color: #333;
      box-sizing: border-box;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 8px 0;
    }
  
    p {
      margin: 0;
    }
  
    .demo-table {
    width: 100%;
    }
    .spacer {
      flex: 1;
    }
  
    .wide-column {
        width: 150px !important;
    }
    .toolbar {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 60px;
      display: flex;
      align-items: center;
      background-color: #1976d2;
      color: white;
      font-weight: 600;
    }
  
    .toolbar img {
      margin: 0 16px;
    }
  
    .toolbar #twitter-logo {
      height: 40px;
      margin: 0 8px;
    }
  
    .toolbar #youtube-logo {
      height: 40px;
      margin: 0 16px;
    }
  
    .toolbar #twitter-logo:hover,
    .toolbar #youtube-logo:hover {
      opacity: 0.8;
    }
  
    .content {
      display: flex;
      margin: 82px auto 32px;
      padding: 0 16px;
      max-width: 960px;
      flex-direction: column;
      align-items: center;
    }
  
    svg.material-icons {
      height: 24px;
      width: auto;
    }
  
    svg.material-icons:not(:last-child) {
      margin-right: 8px;
    }
  
    .card svg.material-icons path {
      fill: #888;
    }
  
    .card-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 16px;
    }
  
    .card {
      all: unset;
      border-radius: 4px;
      border: 1px solid #eee;
      background-color: #fafafa;
      height: 40px;
      width: 200px;
      margin: 0 8px 16px;
      padding: 16px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      transition: all 0.2s ease-in-out;
      line-height: 24px;
    }
  
    .card-container .card:not(:last-child) {
      margin-right: 0;
    }
  
    .card.card-small {
      height: 16px;
      width: 168px;
    }
  
    .card-container .card:not(.highlight-card) {
      cursor: pointer;
    }
  
    .card-container .card:not(.highlight-card):hover {
      transform: translateY(-3px);
      box-shadow: 0 4px 17px rgba(0, 0, 0, 0.35);
    }
  
    .card-container .card:not(.highlight-card):hover .material-icons path {
      fill: rgb(105, 103, 103);
    }
  
    .card.highlight-card {
      background-color: #1976d2;
      color: white;
      font-weight: 600;
      border: none;
      width: auto;
      min-width: 30%;
      position: relative;
    }
  
    .card.card.highlight-card span {
      margin-left: 60px;
    }
  
    svg#rocket {
      width: 80px;
      position: absolute;
      left: -10px;
      top: -24px;
    }
  
    svg#rocket-smoke {
      height: calc(100vh - 95px);
      position: absolute;
      top: 10px;
      right: 180px;
      z-index: -10;
    }
  
    a,
    a:visited,
    a:hover {
      color: #1976d2;
      text-decoration: none;
    }
  
    a:hover {
      color: #125699;
    }
  
    .terminal {
      position: relative;
      width: 80%;
      max-width: 600px;
      border-radius: 6px;
      padding-top: 45px;
      margin-top: 8px;
      overflow: hidden;
      background-color: rgb(15, 15, 16);
    }
  
    .terminal::before {
      content: "\2022 \2022 \2022";
      position: absolute;
      top: 0;
      left: 0;
      height: 4px;
      background: rgb(58, 58, 58);
      color: #c2c3c4;
      width: 100%;
      font-size: 2rem;
      line-height: 0;
      padding: 14px 0;
      text-indent: 4px;
    }
  
    .terminal pre {
      font-family: SFMono-Regular,Consolas,Liberation Mono,Menlo,monospace;
      color: white;
      padding: 0 1rem 1rem;
      margin: 0;
    }
  
    .circle-link {
      height: 40px;
      width: 40px;
      border-radius: 40px;
      margin: 8px;
      background-color: white;
      border: 1px solid #eeeeee;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      transition: 1s ease-out;
    }
  
    .circle-link:hover {
      transform: translateY(-0.25rem);
      box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
    }
  
    footer {
      margin-top: 8px;
      display: flex;
      align-items: center;
      line-height: 20px;
    }
  
    footer a {
      display: flex;
      align-items: center;
    }
  
    .github-star-badge {
      color: #24292e;
      display: flex;
      align-items: center;
      font-size: 12px;
      padding: 3px 10px;
      border: 1px solid rgba(27,31,35,.2);
      border-radius: 3px;
      background-image: linear-gradient(-180deg,#fafbfc,#eff3f6 90%);
      margin-left: 4px;
      font-weight: 600;
    }
  
    .github-star-badge:hover {
      background-image: linear-gradient(-180deg,#f0f3f6,#e6ebf1 90%);
      border-color: rgba(27,31,35,.35);
      background-position: -.5em;
    }
  
    .github-star-badge .material-icons {
      height: 16px;
      width: 16px;
      margin-right: 4px;
    }
  
    svg#clouds {
      position: fixed;
      bottom: -160px;
      left: -230px;
      z-index: -10;
      width: 1920px;
    }
  
    /* Responsive Styles */
    @media screen and (max-width: 767px) {
      .card-container > *:not(.circle-link) ,
      .terminal {
        width: 100%;
      }
  
      .card:not(.highlight-card) {
        height: 16px;
        margin: 8px 0;
      }
  
      .card.highlight-card span {
        margin-left: 72px;
      }
  
      svg#rocket-smoke {
        right: 120px;
        transform: rotate(-5deg);
      }
    }
  
    @media screen and (max-width: 575px) {
      svg#rocket-smoke {
        display: none;
        visibility: hidden;
      }
    }
  </style>
<div style = "margin-top:60px"></div>

<div class="content" role="main">
  <h1 style="font-weight: bold; font-size: 36px; color:#1976d2;">Prepare For Devices</h1>
  <div style="margin-top: 40px"></div>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="form-group">
    <label style="font-size: larger;color:#1976d2;">Serial Number Search</label>
    <input
      type="serialNumber"
      formControlName="serialNumber"
      class="form-control"
    />
  </div>


    <div class="form-group">
      <label style="font-size: larger; color:#1976d2;">Jurisdiction</label>
      <div></div>
      <mat-form-field appearance="fill">
        <mat-label>Choose Jurisdiction</mat-label>
        <mat-select 
          disableOptionCentering 
          [ngClass]="{ 'is-invalid': submitted }" 
          formControlName="jurisdiction" 
          name="jurisdiction"
          (selectionChange)="onJurisChange($event)"
          panelClass="dropDown">
          <mat-option *ngFor="let j of jurisdictions" [value]="j.value">
            {{j.value}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>


    <div class="form-group">
      <label style="font-size: larger; color:#1976d2;">Client</label>
      <div></div>
      <mat-form-field appearance="fill">
        <mat-label>Choose Client</mat-label>
        <mat-select 
          [ngClass]="{ 'is-invalid': submitted}" 
          formControlName="client" 
          (selectionChange)="onClientChange($event)"
          name="client">
          <mat-option *ngFor="let c of clients" [value]="c.value">
            {{c.value}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="form-group">
      <label style="font-size: larger;color:#1976d2;">Kit</label>
    <div></div>
      <mat-form-field appearance="fill">
        <mat-label>Choose Kit</mat-label>
        <mat-select 
          [ngClass]="{ 'is-invalid': submitted }" 
          formControlName="kit" 
          name="kit">
          <mat-option *ngFor="let k of kits" [value]="k.value">
            {{k.value}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="form-group">
      <button type="submit" class="btn btn-primary" id="search">Search</button>
      <button
        type="button"
        (click)="onAdd()"
        class="btn btn-warning float-right"
        id="add"
      >
        Add
      </button>
    </div>
  </form>


  <!-- ################ add button ############################# -->
  <form *ngIf = "add" [formGroup]="form_add" (ngSubmit)="addDevice()">
    <div class="form-group">
      <label style="font-size: larger;color:#1976d2;">Serial Number</label>
      <input
        type="serialNumber"
        formControlName="serialNumber"
        class="form-control"
      />
    </div>
  
    <div class="form-group">
      <label style="font-size: larger;color:#1976d2;">Current Device</label>
      <input
        type="currentDevice"
        formControlName="currentDevice"
        class="form-control"
      />
    </div>

    <div class="form-group">
      <label style="font-size: larger;color:#1976d2;">Kit</label>
      <input
        type="kit"
        formControlName="kit"
        class="form-control"
      />
    </div>

    <div class="form-group">
      <label style="font-size: larger;color:#1976d2;">Tag ID</label>
      <input
        type="tagid"
        formControlName="tagid"
        class="form-control"
      />
    </div>

    <div class="form-group">
      <label style="font-size: larger; color:#1976d2;">Jurisdiction</label>
      <div></div>
      <mat-form-field appearance="fill">
        <mat-label>Choose Jurisdiction</mat-label>
        <mat-select 
          [ngClass]="{ 'is-invalid': submitted }" 
          formControlName="jurisdiction" 
          name="jurisdiction"
          panelClass="dropDown">
          <mat-option *ngFor="let j of jurisdictions_all" [value]="j.value">
            {{j.value}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="form-group">
      <label style="font-size: larger; color:#1976d2;">Client</label>
      <div></div>
      <mat-form-field appearance="fill">
        <mat-label>Choose Client</mat-label>
        <mat-select 
          [ngClass]="{ 'is-invalid': submitted}" 
          formControlName="client" 
          name="client"
          panelClass="dropDown">
          <mat-option *ngFor="let c of clients_all" [value]="c.value">
            {{c.value}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="form-group">
      <label style="font-size: larger;color:#1976d2;">Status</label>
      <div></div>
      <mat-form-field appearance="fill">
      <mat-label>Status</mat-label>
      <mat-select formControlName="status" [ngClass]="{ 'is-invalid': submitted}" panelClass="dropDown" name="status">
        <mat-option value="Ready">Ready</mat-option>
        <mat-option value="InUse">In Use</mat-option>
        <mat-option value="Warranty">Warranty</mat-option>
      </mat-select>
    </mat-form-field>
    </div>

    <div class="form-group">
      <label style="font-size: larger;color:#1976d2;">Number Of Races</label>
      <input
        type="numberOfRaces"
        formControlName="numberOfRaces"
        class="form-control"
      />
    </div>
    
    <div class="form-group">
      <label style="font-size: larger;color:#1976d2;">End Of Warranty</label>
      <div></div>
      <mat-form-field appearance="fill">
      <mat-label>Status</mat-label>
      <mat-select formControlName="EndOfWarranty">
        <mat-option value="true">True</mat-option>
        <mat-option value="false">False</mat-option>
      </mat-select>
    </mat-form-field>
    </div>
    
    <div class="form-group">
      <label style="font-size: larger;color:#1976d2;">Hardware Version</label>
      <input
        type="hardwareVersion"
        formControlName="hardwareVersion"
        class="form-control"
      />
    </div>

    <div class="form-group">
      <label style="font-size: larger;color:#1976d2;">Current Firmware Version</label>
      <input
        type="currentFirmwareVersion"
        formControlName="currentFirmwareVersion"
        class="form-control"
      />
    </div>

    <div class="form-group">
      <label style="font-size: larger;color:#1976d2;">Number Of Repair</label>
      <input
        type="numberOfRepair"
        formControlName="numberOfRepair"
        class="form-control"
      />
    </div>

    <div class="form-group">
      <label style="font-size: larger;color:#1976d2;">Note Field</label>
      <input
        type="noteField"
        formControlName="noteField"
        class="form-control"
      />
    </div>

    <div class="form-group">
      <label style="font-size: larger;color:#1976d2;">Scheduled Battery Replacement</label>
      <input
        type="scheduledBatteryReplacement"
        formControlName="scheduledBatteryReplacement"
        class="form-control"
      />
    </div>

    <div class="form-group">
      <label style="font-size: larger;color:#1976d2;">Amount Of Battery Replacements</label>
      <input
        type="amountOfBatteryReplacements"
        formControlName="amountOfBatteryReplacements"
        class="form-control"
      />
    </div>
  
      <div class="form-group">
        <button type="submit" class="btn btn-primary" id="submit">Submit</button>
      </div>
    </form>

  <!-- ################ devices.component.html ############################# -->
<div style = "width:1800px;">
  <label *ngIf = "devices.length>0" style="font-size: larger;color:#ff0000;">If you are editing a device, please do it one by one.</label>
  <button
        *ngIf = "devices.length>0"
        type="button"
        (click)="onPDF()"
        class="btn btn-warning float-right"
        id="pdf"
      >
      Generate PDF
      </button>
<table *ngIf = "devices.length>0" mat-table [dataSource]="devices" class="mat-elevation-z8 demo-table">
    <!-- Serial Number Column -->
    <ng-container matColumnDef="serialNumber">
      <th mat-header-cell *matHeaderCellDef > Serial Number </th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf = "!element.edit">
          {{element.serialNumber}} 
        </div>
        <div *ngIf = "element.edit">
          <input [(ngModel)]="device_property.serialNumber" [placeholder]="element.serialNumber">
        </div>
      </td>
    </ng-container>
  
    <!-- Current Device Column -->
    <ng-container matColumnDef="currentDevice">
      <th mat-header-cell *matHeaderCellDef > Current Device </th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf = "!element.edit">
          {{element.currentDevice}} 
        </div>
        <div *ngIf = "element.edit">
          <input [(ngModel)]="device_property.currentDevice" [placeholder]="element.currentDevice">
        </div>
      </td>
    </ng-container>
  
    <!-- Kit Column -->
    <ng-container matColumnDef="kit">
      <th mat-header-cell *matHeaderCellDef > Kit </th>
      <td mat-cell *matCellDef="let element">
         <div *ngIf = "!element.edit">
          {{element.kit}} 
        </div>
        <div *ngIf = "element.edit">
          <input [(ngModel)]="device_property.kit" [placeholder]="element.kit">
        </div>
      </td>
    </ng-container>
  
    <!-- Kit Number Column -->
    <ng-container matColumnDef="tagid">
      <th mat-header-cell *matHeaderCellDef > Tag ID </th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf = "!element.edit">
          {{element.tagid}}
       </div>
       <div *ngIf = "element.edit">
         <input [(ngModel)]="device_property.tagid" [placeholder]="element.tagid">
       </div>
     </td>
    </ng-container>
  
    <!-- Jurisdiction Column -->
    <ng-container matColumnDef="jurisdiction">
      <th mat-header-cell *matHeaderCellDef > Jurisdiction </th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf = "!element.edit">
          {{element.jurisdiction}}
        </div>
        <div *ngIf = "element.edit" style = "width: 150px;">
            <mat-select 
              [(ngModel)]="device_property.jurisdiction"
              name="jurisdiction"
              panelClass="dropDown">
            <mat-option *ngFor="let j of jurisdictions_all" [value]="j.value">
              {{j.value}}
            </mat-option>
          </mat-select>
        </div>
     </td>
    </ng-container>
  
    <!-- Client Column -->
    <ng-container matColumnDef="client">
      <th mat-header-cell *matHeaderCellDef  class="wide-column"> Client </th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf = "!element.edit">
          {{element.client}}
       </div>
       <div *ngIf = "element.edit" style = "width: 250px;">
        <mat-select 
            [(ngModel)]="device_property.client"
            name="client"
            panelClass="dropDown">
          <mat-option *ngFor="let c of clients_all" [value]="c.value">
            {{c.value}}
          </mat-option>
        </mat-select>
       </div>
     </td>
    </ng-container>
  
    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef > Status </th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf = "!element.edit">
          {{element.status}}
       </div>
       <div *ngIf = "element.edit" style = "width: 250px;">
        <mat-select [(ngModel)]="device_property.status">
          <mat-option value="Ready">Ready</mat-option>
          <mat-option value="InUse">In Use</mat-option>
          <mat-option value="Warranty">Warranty</mat-option>
        </mat-select>
       </div>
     </td>
    </ng-container>
  
    <!-- Number of Races Column -->
    <ng-container matColumnDef="numberOfRaces">
      <th mat-header-cell *matHeaderCellDef > Number of Races </th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf = "!element.edit">
          {{element.numberOfRaces}}
       </div>
       <div *ngIf = "element.edit">
         <input [(ngModel)]="device_property.numberOfRaces" [placeholder]="element.numberOfRaces">
       </div>
     </td>
    </ng-container>
  
    <!-- End Of Warrenty Column -->
    <ng-container matColumnDef="EndOfWarranty">
      <th mat-header-cell *matHeaderCellDef > End Of Warranty </th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf = "!element.edit">
          {{element.EndOfWarranty}}
       </div>
       <div *ngIf = "element.edit" style = "width: 150px;">
        <mat-select [(ngModel)]="device_property.EndOfWarranty">
          <mat-option value="true">True</mat-option>
          <mat-option value="false">False</mat-option>
        </mat-select>
       </div>
     </td>
    </ng-container>
  
    <!-- Hardware Version Column -->
    <ng-container matColumnDef="hardwareVersion">
      <th mat-header-cell *matHeaderCellDef > Hardware Version </th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf = "!element.edit">
          {{element.hardwareVersion}}
       </div>
       <div *ngIf = "element.edit">
         <input [(ngModel)]="device_property.hardwareVersion" [placeholder]="element.hardwareVersion">
       </div>
     </td>
    </ng-container>
  
    <!-- Current Firmware Version Column -->
    <ng-container matColumnDef="currentFirmwareVersion">
      <th mat-header-cell *matHeaderCellDef > Current Firmware Version </th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf = "!element.edit">
          {{element.currentFirmwareVersion}}
       </div>
       <div *ngIf = "element.edit">
         <input [(ngModel)]="device_property.currentFirmwareVersion" [placeholder]="element.currentFirmwareVersion">
       </div>
     </td>
    </ng-container>
      <!-- Number of Repair Column -->
  <ng-container matColumnDef="numberOfRepair">
    <th mat-header-cell *matHeaderCellDef > Number of Repair </th>
    <td mat-cell *matCellDef="let element">
      <div *ngIf = "!element.edit">
        {{element.numberOfRepair}}
     </div>
     <div *ngIf = "element.edit">
       <input [(ngModel)]="device_property.numberOfRepair" [placeholder]="element.numberOfRepair">
     </div>
   </td>
  </ng-container>

  <!-- Note Field Column -->
  <ng-container matColumnDef="noteField">
    <th mat-header-cell *matHeaderCellDef > Note Field </th>
    <td mat-cell *matCellDef="let element">
      <div *ngIf = "!element.edit">
        {{element.noteField}}
     </div>
     <div *ngIf = "element.edit">
       <input [(ngModel)]="device_property.noteField" [placeholder]="element.noteField">
     </div>
   </td>
  </ng-container>

  <!-- Scheduled Battery Replacement Column -->
  <ng-container matColumnDef="scheduledBatteryReplacement">
    <th mat-header-cell *matHeaderCellDef > Scheduled Battery Replacement </th>
    <td mat-cell *matCellDef="let element">
      <div *ngIf = "!element.edit">
        {{element.scheduledBatteryReplacement}}
     </div>
     <div *ngIf = "element.edit">
       <input [(ngModel)]="device_property.scheduledBatteryReplacement" [placeholder]="element.scheduledBatteryReplacement">
     </div>
   </td>
  </ng-container>

  <!-- Amount Of Battery Replacements Column -->
  <ng-container matColumnDef="amountOfBatteryReplacements">
    <th mat-header-cell *matHeaderCellDef > Amount Of Battery Replacements </th>
    <td mat-cell *matCellDef="let element">
      <div *ngIf = "!element.edit">
        {{element.amountOfBatteryReplacements}}
     </div>
     <div *ngIf = "element.edit">
       <input [(ngModel)]="device_property.amountOfBatteryReplacements" [placeholder]="element.amountOfBatteryReplacements">
     </div>
   </td>
  </ng-container>

  <!-- Actions Column -->
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef> Actions </th>
    <td mat-cell *matCellDef="let element"> 
      <button class="btn btn-primary" style="width: 100%;" (click)="editDevice(element)">
        <mat-icon style="width: 100%;">edit</mat-icon>
      </button>
      <button *ngIf = "element.edit" class="btn btn-primary" style="width: 100%;" (click)="saveDevice(element)">
        <mat-icon style="width: 100%;">save</mat-icon>
      </button>
      <button *ngIf = "!confirm_delete" class="btn btn-warning" style="width: 100%;" (click)="firstdeleteDevice(element)">
        <mat-icon style="width: 100%;">delete</mat-icon>
      </button>
      <button *ngIf = "confirm_delete" class="btn btn-warning" style="width: 100%;" (click)="deleteDevice(element)">
        <mat-icon style="width: 100%;">confirm delete</mat-icon>
      </button>
    </td>
  </ng-container>

  <!-- Table Rows -->
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

</table>
</div>
  
  
</div>
