import { Component, EventEmitter, HostListener, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APIService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { environment } from 'src/environments/environment';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ElementRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { from } from 'rxjs';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
} from '@angular/common/http';

@Component({
  selector: 'app-role-popup',
  templateUrl: './role-popup.html',
  styleUrls: ['./role-popup.css']
})
export class AppRoleComponent {
  header = {
    hover2: (index: any, isVisible: boolean) => this.togglePopup(isVisible)
  };
  isPopupVisible = false;
  @Input() role:any = {};
  @Input() showRole: boolean = false;

  togglePopup(isVisible: boolean) {
    this.isPopupVisible = isVisible;
  }

  resolvePath(i: any, id: any): any {
    // Your resolvePath logic
  }

  hideRole() {
    this.showRole = false;
  }

  
}


