<!-- toggler -->
<!--<a class="navbar-brand" >

</a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>-->
<!--<input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
<button class="btn btn-primary" type="submit">Search</button> *ngIf="searchOnList(i.groupName,accountGroupSearchTerm)" -->
<!--<button class="btn float-end" data-bs-toggle="offcanvas" data-bs-target="#offcanvas" role="button">
  <i class="bi bi-arrow-left-square-fill fs-3" data-bs-toggle="offcanvas" data-bs-target="#offcanvas"></i>
</button>-->
<!--<button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>-->
<!--<div class="leftmenu offcanvas offcanvas-start  show" tabindex="-1" id="offcanvas" data-bs-keyboard="false" data-bs-backdrop="false">
  <div class="offcanvas-header">
    <h6 class="offcanvas-title d-none d-sm-block" id="offcanvas" data-bs-toggle="offcanvas" data-bs-target="#offcanvas" role="button">
      <img style="width:50px" src="../assets/img/logo.png" />
    </h6>
  </div>-->

<div class="leftmenu" *ngIf="started">
  <div class="offcanvas-body px-0 max-overflow">
    <button class="menuCloseButton ground-border" (click)="hideLeftMenu()" id="menuClosebtn">
      <i class="fa fa-arrow-circle-right fa-rotate-180"></i>
    </button>

    <button class="ground-border" (click)="showLeftMenu()" id="menuShowbtn">
      <i class="fa fa-arrow-circle-right"></i>
    </button>

    <ul class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-start" id="menu">
      <!-- <li class="nav-item">
          <a
            routerLink="/superadmin/users/clients"
            class="nav-link text-truncate"
            title="Clients"
          >
            <img
              src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Feedback+white.svg"
              class="monitoringimg"
              alt=""
            />
            Clients
          </a>
        </li> -->
      <li class="nav-item" *ngIf="menuList['Companies']">
        <a routerLink="/admin/company" class="nav-link text-truncate" title="Company">
          <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Feedback+white.svg"
            class="monitoringimg" alt="" />
          Company
        </a>
      </li>
      <li class="nav-item" *ngIf="menuList['Clients']">
        <a routerLink="/admin/admin_client" class="nav-link text-truncate" title="Clients">
          <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Feedback+white.svg"
            class="monitoringimg" alt="" />
          Client
        </a>
      </li>
      <li class="nav-item" *ngIf="menuList['Client Routes']">
        <a routerLink="/admin/client_routes" class="nav-link text-truncate" title="Clients Routes">
          <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Feedback+white.svg"
            class="monitoringimg" alt="" />
          Client Routes
        </a>
      </li>

      <li class="nav-item" *ngIf="menuList['Drivers']">
        <a routerLink="/admin/driver" class="nav-link text-truncate" title="Drivers">
          <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Feedback+white.svg"
            class="monitoringimg" alt="" />
          Drivers
        </a>
      </li>
      <li class="nav-item" *ngIf="menuList['Vehicles']">
        <a routerLink="/admin/vehicles" class="nav-link text-truncate" title="Vehicles">
          <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Feedback+white.svg"
            class="monitoringimg" alt="" />
          Vehicles
        </a>
      </li>
      <li class="nav-item" *ngIf="menuList['Fuel Stations']">
        <a routerLink="/admin/fuel_stations" class="nav-link text-truncate" title="Fuel Stations">
          <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Feedback+white.svg"
            class="monitoringimg" alt="" />
          Fuel Stations
        </a>
      </li>
      <li class="nav-item" *ngIf="menuList['Attenders']">
        <a routerLink="/admin/attenders" class="nav-link text-truncate" title="Attenders">
          <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Feedback+white.svg"
            class="monitoringimg" alt="" />
          Attenders
        </a>
      </li>
      <li class="nav-item" *ngIf="menuList['Mechanics']">
        <a routerLink="/admin/mechanics" class="nav-link text-truncate" title="Mechanics">
          <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Feedback+white.svg"
            class="monitoringimg" alt="" />
          Mechanics
        </a>
      </li>
      <li class="nav-item" *ngIf="menuList['Staff']">
        <a routerLink="/admin/staff" class="nav-link text-truncate" title="Staff">
          <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Feedback+white.svg"
            class="monitoringimg" alt="" />
          Staff
        </a>
      </li>
      <!-- <li class="nav-item">
          <a
            routerLink="/superadmin/policies"
            class="nav-link text-truncate"
            title="Policies"
          >
            <img
              src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Feedback+white.svg"
              class="monitoringimg"
              alt=""
            />
            Role
          </a>
        </li>
        <li class="nav-item">
          <a
            routerLink="/superadmin/menus"
            class="nav-link text-truncate"
            title="Menus"
          >
            <img
              src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Feedback+white.svg"
              class="monitoringimg"
              alt=""
            />
            Modules
          </a>
        </li> -->
    </ul>
  </div>
</div>

<!-- div -->

<div class="position-wid-99">
  <div class="row">
    <div class="col pad-right">
      <nav class="navbar navbar-expand-lg top-menu">
        <div class="container-fluid">
          <div class="nav-item wid-175">
            <span class="nav-link text-truncate padd-0">
              <img class="wid-115" src="../assets/img/Intercity Logo.jpeg" />
            </span>
          </div>
          <div class="hamburger" (click)="togglehamburgerMenu()">
            <i class="fa-solid fa-bars"></i>
          </div>


          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ms-auto">
              <li class="nav-item dropdown searchbox min-width-auto">
                <a class="d-block text-decoration-none dropdown-toggle color-top" id="dropdownUser1"
                  data-bs-toggle="dropdown" aria-expanded="true">
                  <!--<img src="../../assets/img/passport.png" class="rounded-circle" width="32" height="32" />-->
                  <i class="fa fa-user" aria-hidden="true"></i>
                  <!-- <span class="color-family">{{ username }}</span> -->
                </a>
                <ul class="dropdown-menu text-small" aria-labelledby="dropdownUser1"
                  data-popper-placement="bottom-start">
                  <!--<li><a class="dropdown-item" href="#">Settings</a></li>
                        <li><a class="dropdown-item" href="#">Profile</a></li>-->
                  <li>
                    <span class="color-family text-bold">{{ username }}</span>
                    <hr class="dropdown-divider" />
                  </li>
                  <li>
                    <button class="dropdown-item" (click)="logout()">
                      Sign out
                    </button>
                  </li>
                </ul>
              </li>
            </ul>

            <div class="dropdown text-end"></div>

            <!-- <div class="wid-75"></div> -->
          </div>
        </div>
      </nav>
    </div>
  </div>
</div>
<div *ngIf="hamMenu" class="hamMen">

  <ul class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-start" id="menu">

    <li class="nav-item col-white" (click)="togglehamburgerMenu()">
      <a routerLink="/admin/company" class="nav-link text-truncate col-white" title="Company">
        <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Feedback+white.svg"
          class="monitoringimg" alt="" />
        Company
      </a>
    </li>
    <li class="nav-item col-white" (click)="togglehamburgerMenu()">
      <a routerLink="/admin/admin_client" class="nav-link text-truncate col-white" title="Clients">
        <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Feedback+white.svg"
          class="monitoringimg" alt="" />
        Client
      </a>
    </li>
    <li class="nav-item col-white" (click)="togglehamburgerMenu()">
      <a routerLink="/admin/client_routes" class="nav-link text-truncate col-white" title="Clients Routes">
        <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Feedback+white.svg"
          class="monitoringimg" alt="" />
        Client Routes
      </a>
    </li>

    <li class="nav-item col-white" (click)="togglehamburgerMenu()">
      <a routerLink="/admin/driver" class="nav-link text-truncate col-white" title="Drivers">
        <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Feedback+white.svg"
          class="monitoringimg" alt="" />
        Drivers
      </a>
    </li>
    <li class="nav-item col-white" (click)="togglehamburgerMenu()">
      <a routerLink="/admin/vehicles" class="nav-link text-truncate col-white" title="Vehicles">
        <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Feedback+white.svg"
          class="monitoringimg" alt="" />
        Vehicles
      </a>
    </li>
    <li class="nav-item col-white" (click)="togglehamburgerMenu()">
      <a routerLink="/admin/fuel_stations" class="nav-link text-truncate col-white" title="Fuel Stations">
        <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Feedback+white.svg"
          class="monitoringimg" alt="" />
        Fuel Stations
      </a>
    </li>
    <li class="nav-item col-white" (click)="togglehamburgerMenu()">
      <a routerLink="/admin/attenders" class="nav-link text-truncate col-white" title="Attenders">
        <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Feedback+white.svg"
          class="monitoringimg" alt="" />
        Attenders
      </a>
    </li>
    <li class="nav-item col-white" (click)="togglehamburgerMenu()">
      <a routerLink="/admin/mechanics" class="nav-link text-truncate col-white" title="Mechanics">
        <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Feedback+white.svg"
          class="monitoringimg" alt="" />
        Mechanics
      </a>
    </li>
    <li class="nav-item col-white" (click)="togglehamburgerMenu()">
      <a routerLink="/admin/staff" class="nav-link text-truncate col-white" title="Staff">
        <img src="https://s3.ap-south-1.amazonaws.com/v3.assets.swayam.cloud/v3.icons/SVG/White/Feedback+white.svg"
          class="monitoringimg" alt="" />
        Staff
      </a>
    </li>
    <li class="nav-item col-white">
      <button class="dropdown-item" (click)="logout()">
        <span class="col-white"><i class="fa-solid fa-right-from-bracket" style="padding-right:15px;"></i> Sign
          out</span>
      </button>
  </ul>

</div>
<div class="content min-vh-100 rightcontent" [ngStyle]="{
      marginLeft: !started ? '26px' : undefined
    }">
  <ng-container>
    <router-outlet></router-outlet>
  </ng-container>
</div>
<!-- admin.component.html -->
<div class="container" *ngIf="token.expired">
  <!-- Modal -->
  <div class="modal fade show confirm-box" id="sessionExpiredModal" tabindex="-1" role="dialog"
    aria-labelledby="sessionExpiredModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content width-40">
        <div class="modal-header ">
          <h5 class="modal-title font-weight-bold" id="sessionExpiredModalLabel">Session Expired</h5>
        </div>
        <div class="modal-body">
          Your session has expired. Please log in again.
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="redirectToLogin()">Login</button>
        </div>
      </div>
    </div>
  </div>
</div>




<!-- <app-global-search
        *ngIf="showSearchResult"
        (hideModal)="showSearchResult = false"
      ></app-global-search> -->
<!--<cft-viewer></cft-viewer>-->
<!-- <dash-expired *ngIf="expiresIn && expiresIn == 0"></dash-expired> -->
<!-- <router-outlet></router-outlet> -->

<!-- Chatbot -->