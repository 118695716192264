import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APIService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { environment } from 'src/environments/environment';

@Component({
  //moduleId: module.id.toString(),
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css'],
})
export class UserProfileComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  userDetails: any = {};
  changePassword: any = {
    oldPass: '',
    newPass: '',
    newConfPass: '',
  };

  accountId = localStorage.getItem('accountId');
  regionId = localStorage.getItem('regionId');
  currentMessage: any;
  config: any;

  constructor(
    private apiServer: APIService,
    private notifier: NotifierService,
    private route: ActivatedRoute
  ) {
    this.config = this.route.snapshot.data['config'];
  }
  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
    });
  }

  ngOnDestroy(): void {
    this.currentMessage.unsubscribe();
  }

  passwordChecker(password: string) {
    let passwordObj: any = {
      strength: 0,
      policies: {
        'Must have atleast 8 characters': password.length > 7,
        'Must have atleast 1 uppercase letter, 1 lowercase letter, and 1 number':
          false,
        'Must have atleast 1 Special Character': false,
        'Longer Password': false,
      },
    };
    if (passwordObj.policies['Must have atleast 8 characters']) {
      passwordObj.strength += 1;
    }
    if (password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{0,}$/gm)) {
      passwordObj.strength += 1;
      passwordObj.policies[
        'Must have atleast 1 uppercase letter, 1 lowercase letter, and 1 number'
      ] = true;
    }
    if (password.length >= 10) {
      passwordObj.strength += 1;
      passwordObj.policies['Longer Password'] = true;
    }
    if (password.match(/[^A-z\s\d][\\\^]?/gm)) {
      passwordObj.strength += 1;
      passwordObj.policies['Must have atleast 1 Special Character'] = true;
    }
    return passwordObj;
  }

  async changePasswordAction() {
    if (this.changePassword.oldPass.trim() == '') {
      this.errors['oldPassword'] = {
        error: true,
        message: 'Old Password is a mandatory field',
      };
    } else {
      this.errors['oldPassword'] = {
        error: false,
        message: '',
      };

    }
    if (this.passwordChecker(this.changePassword.newPass)['strength'] < 3) {
      this.errors['password'] = {
        error: true,
        message: "Entered Password doesn't meet the minimum password policy",
      };
      return;
    } else {
      this.errors['password'] = {
        error: false,
        message: '',
      };
    }
    if (this.changePassword.newPass != this.changePassword.newConfPass) {
      this.errors['confPassword'] = {
        error: true,
        message: 'Passwords do not match',
      };
      return;
    } else {
      this.errors['confPassword'] = {
        error: false,
        message: ''
      }
    }
    this.notifier.loading(true);
    let data = {
      a: 'changepassword',
      email: this.userId,
      oldPassword: this.changePassword.oldPass,
      password: this.changePassword.newPass,
      ut: this.urlPrefix,
      at: localStorage.getItem('t'),
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    let apiURL = `${this.config.apiURL}/${this.urlPrefix}/Settings/usermanagement`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.s == '1' || result.status == '1') {
      this.changePassword = {
        oldPass: '',
        newPass: '',
        newConfPass: '',
      };
      this.notifier.alert('Success', '', result.msg, 'success', 5000);
      this.load();
    } else {
      this.notifier.alert('Info', '', result.msg || result.error, 'info', 5000);
    }

    this.notifier.loading(false);
  }

  async load() {
    this.userDetails = {
      un: localStorage.getItem('un'),
      ph: localStorage.getItem('ph'),
      com: localStorage.getItem('com'),
      des:
        localStorage.getItem('des') != 'undefined'
          ? localStorage.getItem('des')
          : '',
    };
  }

  validations: any = {
    un: {
      policy: {
        required: true,
      },
      name: 'Username',
    },
    com: {
      policy: {
        required: true,
      },
      name: 'Company',
    },
    des: {
      policy: {
        required: true,
      },
      name: 'Designation',
    },
    ph: {
      policy: {
        required: true,
      },
      name: 'Phone Number',
    },
  };

  errors: any = {
    un: {
      error: false,
      message: '',
    },
    ph: {
      error: false,
      message: '',
    },
    com: {
      error: false,
      message: '',
    },
    des: {
      error: false,
      message: '',
    },
    password: {
      error: false,
      message: '',
    },
    oldPassword: {
      error: false,
      message: '',
    },
    confPassword: {
      error: false,
      message: '',
    },
  };

  validate(): boolean {
    let ref: any = this;
    let check: boolean = true;
    Object.keys(this.validations).forEach((key: string) => {
      console.log(key);

      this.errors[key]['error'] = false;
      if (
        this.validations[key]['policy']['required'] &&
        (!ref.userDetails[key].trim() || ref.userDetails[key].trim() == '')
      ) {
        this.errors[key]['error'] = true;
        this.errors[key][
          'message'
        ] = `${this.validations[key]['name']} is a mandatory field`;
        check = false;
      } else if (
        this.validations[key]['policy']['regex'] &&
        this.validations[key]['policy']['regex'](ref.userDetails[key]) === false
      ) {
        this.errors[key]['error'] = true;
        this.errors[key][
          'message'
        ] = `Entered ${this.validations[key]['name']} is not valid`;
        check = false;
      } else {
        this.errors[key]['error'] = false;
      }
    });

    return check;
  }

  getKeys(obj: any) {
    return Object.keys(obj);
  }

  async updateUser() {
    if (!this.validate()) {
      return;
    }
    this.notifier.loading(true);
    let data = {
      email: this.userId,
      un: this.userDetails.un,
      mobile: this.userDetails.ph,
      companyName: this.userDetails.com,
      designation: this.userDetails.des,
      a: 'update',
      ut: this.urlPrefix,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `${this.config.apiURL}/${this.urlPrefix}/Settings/usermanagement`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.s == '1' || result.status == '1') {
      this.notifier.alert('Success', '', result.msg, 'success', 5000);
      localStorage.setItem('un', this.userDetails.un);
      localStorage.setItem('ph', this.userDetails.ph);
      localStorage.setItem('com', this.userDetails.com);
      localStorage.setItem('des', this.userDetails.des);
      this.load();
    } else {
      this.notifier.alert('Info', '', result.error || result.msg, 'info', 5000);
    }

    this.notifier.loading(false);
  }

  callFunction(name: any, param: any) {}
}
