<div class="pb-2 pt-2 flex-end">
  <button
    type="button"
    (click)="default($event)"
    class="btn btn-light relative"
  >
    <i class="fa fa-cog"></i>
    <div class="floating-button">
      <div *ngFor="let h of headers" class="flex-between">
        <div class="pl-2">{{ h.name }}</div>
        <div (click)="hideMenu($event, h)">
          <label class="switch" style="pointer-events: none">
            <input
              autocomplete="off"
              type="checkbox"
              style="pointer-events: none"
              [ngModel]="h.show"
            />
            <span class="slider round"></span>
          </label>
        </div>
      </div>
    </div>
  </button>
</div>
<table class="table table-docs">
  <thead>
    <tr>
      <th
        *ngFor="let h of headers"
        [ngStyle]="{ display: h.show ? 'table-cell' : 'none' }"
      >
        <div>
          {{ h.name }}
          <span *ngIf="h.filter" class="inputseach">
            <input type="text" [(ngModel)]="h.filterText" />
            <i class="fa fa-search" aria-hidden="true"></i>
          </span>
        </div>
        <ng-container *ngIf="h.datepicker">
          <div>
            <input type="text" class="{{ h.datepicker?.class }}" />
          </div>
        </ng-container>
      </th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let item of data; let i = index">
      <tr
        *ngIf="notifier.filterTableColums(headers, item)"
        class="{{
          item['rowClassConfition'] && item['rowClassConfition'](item)
        }}"
      >
        <td
          *ngFor="let h of headers"
          [ngStyle]="{ display: h.show ? 'table-cell' : 'none' }"
        >
          <ng-container *ngIf="h.type == 'string'">
            <a
              *ngIf="h.link"
              [routerLink]="[h?.link?.href]"
              [queryParams]="item[h.link.queryParamsKey]"
            >
              {{ item[h.id] || h?.or?.text || "-" }}
            </a>
            <span *ngIf="!h.link">
              {{ item[h.id] || h?.or?.text || "-" }}
            </span>
          </ng-container>
          <ng-container *ngIf="h.type == 'dropdown'">
            <select class="form-select" [(ngModel)]="item[h.dropdown.key]">
              <option
                value="{{ option.value }}"
                *ngFor="let option of h.dropdown.getOptions()"
              >
                {{ option.name }}
              </option>
            </select>
          </ng-container>
          <ng-container *ngIf="h.type == 'component'">
            <app-status-view
              *ngIf="h.component.name == 'StatusViewComponent'"
              [statuses]="h.component.data"
              [selected]="item[h.id]"
            ></app-status-view>
          </ng-container>

          <ng-container *ngIf="h.type == 'image'">
            <img src="{{ item[h.id] }}" class="width-50" alt="{{ h.name }}" />
          </ng-container>
          <ng-container *ngIf="h.type == 'checkbox'">
            <input type="checkbox" [(ngModel)]="item[h.id]" />
          </ng-container>

          <ng-container *ngIf="h.type == 'multi-select'">
            <ng-multiselect-dropdown
              *ngIf="item?.dropdown?.options"
              [placeholder]="h.dropdown.placeholder"
              [settings]="{
                singleSelection: !h.dropdown.multiple,
                idField: 'item_id',
                textField: 'item_text',
                selectAllText: 'Select All',
                unSelectAllText: 'UnSelect All',
                itemsShowLimit: 3,
                allowSearchFilter: true
              }"
              (onFilterChange)="
                onFilterChange($event, item, h.dropdown.onchange, h.id)
              "
              [data]="item.dropdown.options[h.id]"
              [(ngModel)]="item.dropdown.selected[h.id]"
            >
            </ng-multiselect-dropdown>
          </ng-container>
        </td>
      </tr>
    </ng-container>
    <tr *ngIf="data.length == 0 && !loading">
      <td class="center" [attr.colSpan]="headers.length + 1">
        <span>No Date Found</span>
      </td>
    </tr>
  </tbody>
</table>
