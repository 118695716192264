import { EventEmitter, Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APIService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import {
  HttpEvent,
  HttpEventType
} from '@angular/common/http';
import { FileUploadService } from 'src/app/services/fileupload.service';
import * as XLSX from 'xlsx';
import { compileNgModule } from '@angular/compiler';
import { audit } from 'rxjs/operators';
// import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

declare let Jhxlsx: any;
declare let $: any;
declare var bootstrap: any;
@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css'],
})
export class CompanyComponent {
  myForm: any;
  minEndDate: string = '';
  documentInputErrors: { [key: string]: string | null } = {};
  isOpen: boolean = false;
  isSubmitted: boolean = false;
  urlPrefix: string | null = null;
  config: any;
  addcom: boolean = false;
  result: any;
  selectedCompany: any;
  showModal: boolean = false;
  isEditMode: boolean = false;
  loading: boolean = false;
  fetchUrls: any;
  sendUrls: any[] = [];
  matchingUrls: any;
  orderedUrls: any[] = [];
  fileNames: any;
  updated: any = {};
  labourLicenseTouched = false;
  ptCertificateTouched = false;
  udyamCertificateTouched = false;
  incorporationCertificateTouched = false;
  epfoCertificateTouched = false;
  gstCertificateTouched = false;
  panCardTouched = false;
  mtwCertificateTouched = false;
  statutoryCompliancesTouched = false;
  esiCertificateTouched = false;
  submitted: boolean = false;
  pagination: any = { perPage: 10, page: 1, };
  pages: any = [10, 50, 100, 200];
  pageChange: EventEmitter<number> | undefined;
  selectedTab: string = 'company-details';
  selectedSubTab: string = 'bank';

  onAddAuditor: boolean = false
  onAddCA: boolean = false
  exportVar: any = []

  shareholdersForm: FormGroup;

  newBankAccountForm: FormGroup;
  editBankAccount: boolean = false;
  bankAccountTitle: string = 'New Bank Account'; // Default value
  editBankAccountIndex: number | null = null;
  searchBankAccountQuery: string = '';

  editEmi: boolean = false;
  emiTitle: string = '';
  editEmiIndex: number | null = null;
  searchEmiQuery: string = '';
  newEmiForm: FormGroup;

  newAuditorForm: FormGroup;
  editAuditor: boolean = false;
  editAuditorIndex: number | null = null;
  auditorTitle: string = 'New Auditor';
  searchAuditorQuery: string = '';

  newCAForm: FormGroup;
  editCA: boolean = false;
  editCAIndex: number | null = null;
  caTitle: string = 'New Chartered Accountant';
  searchCAQuery: string = '';

  progressValue: any = 0;
  // fileExist: boolean = false;
  uploadedVar: boolean = false;
  updateForm: boolean = false;

  popUp: boolean = false;
  popup_header: any = '';
  popup_subtitle: any = '';

  backend_pagination: any = { page: 1, per_page: 10 };
  companyNamesList: any[] = [];

  access = localStorage.getItem('acT');

  showPopup(header: any, title: any) {
    this.popup_header = header;
    this.popup_subtitle = title;
    this.popUp = true;
  }

  hidepopup(event: any) {
    this.popUp = false;
  }
  constructor(
    private fb: FormBuilder,
    private apiService: APIService,
    private uploadFile: FileUploadService,
    public notifier: NotifierService,
    private route: ActivatedRoute
  ) {
    this.config = this.route.snapshot.data['config'];
    this.shareholdersForm = this.fb.group({
      company_shareholders: this.fb.array([this.createShareholder()])
    });

    this.newShareholderForm = this.fb.group({
      // company_code: ['', Validators.required],
      shareholder_name: ['', Validators.required],
      primary_contact_num: ['', [Validators.required, Validators.pattern('^[0-9]{10,13}$')]],
      secondary_contact_num: ['', Validators.pattern('^[0-9]{10,13}$')],
      din_no: ['', [Validators.required, this.dinValidator()]],
      pan_no: ['', [Validators.required, this.panValidator()]],
      aadhar_no: ['', [Validators.required, this.aadhaarValidator()]],
      status: ['Active', Validators.required]
    });

    this.newBankAccountForm = this.fb.group({
      account_number: ['', [Validators.required, Validators.minLength(9), Validators.maxLength(18), Validators.pattern(/^\d+$/)]],
      ifsc_code: ['', [Validators.required, Validators.pattern('^[A-Z]{4}0[A-Z0-9]{6}$')]],
      bank_name: ['', Validators.required],
      bank_address: ['', Validators.required],
      name_as_per_bank_account: ['', Validators.required],
    });

    this.newEmiForm = this.fb.group({
      vehicle_numbers: ['', Validators.required],
      total_vehicles: ['', [Validators.required, this.digitsOnlyValidator()]],
      financer: ['', [Validators.required, this.alphabetOnlyValidator()]],
      loan_number: ['', [Validators.required, this.digitsOnlyValidator()]],
      bank_name: ['', [Validators.required, this.alphabetOnlyValidator()]],
      account_number: ['', [Validators.required, this.digitsOnlyValidator()]],
      loan_amount: ['', [Validators.required, this.digitsOnlyValidator()]],
      interest_amount: ['', [Validators.required, this.digitsOnlyValidator()]],
      total_amount: ['', [Validators.required, this.digitsOnlyValidator()]],
      flat_rate: ['', Validators.required],
      no_of_paid_months: ['', [Validators.required, this.digitsOnlyValidator()]],
      no_of_due_months: ['', [Validators.required, this.digitsOnlyValidator()]],
      total_installments: ['', [Validators.required, this.digitsOnlyValidator()]],
      emi_starting_date: ['', Validators.required],
      emi_ending_date: ['', Validators.required],
      total_emi: ['', [Validators.required, this.digitsOnlyValidator()]],
      total_outstanding: ['', [Validators.required, this.digitsOnlyValidator()]],
      emi_date: ['', Validators.required],
    });

    this.newAuditorForm = this.fb.group({
      auditorsName: ['', Validators.required],
      auditorsContactNumber: ['', [Validators.required, Validators.pattern('^[0-9]{10,13}$')]],
      auditorsEmail: ['', [Validators.required, Validators.email]],
      auditorsCompanyName: ['', Validators.required],
      auditorsWorkingPeriodFrom: ['', Validators.required],
      auditorsWorkingPeriodTo: ['', Validators.required],
      auditorsAssignCompany: [[], Validators.required],
      auditorsCheckFeasibility: ['', Validators.required]
    });


    this.newCAForm = this.fb.group({
      charteredAccountantName: ['', Validators.required],
      charteredAccountantContactNumber: ['', [Validators.required, Validators.pattern('^[0-9]{10,13}$')]],
      charteredAccountantEmail: ['', [Validators.required, Validators.email]],
      charteredAccountantCompanyName: ['', Validators.required],
      charteredAccountantWorkingPeriodFrom: ['', Validators.required],
      charteredAccountantWorkingPeriodTo: ['', Validators.required],
      charteredAccountantAssignCompany: [[], Validators.required],
      charteredAccountantCheckFeasibility: ['', Validators.required]
    });
  }

  headers: any = [
    { id: 'company_name', name: 'Company Name', sort: { sort: true, direction: 'Asc' } },
    { id: 'company_details.company_code', name: 'Company Code', sort: { sort: true, direction: 'Asc' } },
    { id: 'company_details.mtw_cert_expiry', name: 'Date of Incorporation', sort: { sort: true, direction: 'Asc' } },
    { id: 'company_details.company_type', name: 'Type of Company', sort: { sort: true, direction: 'Asc' } },
    { id: 'shareholders.shareholder_name', name: 'Share Holders', sort: { sort: true, direction: 'Asc' } },
    { id: 'company_details.company_contactnum', name: 'Company Contact Number', sort: { sort: true, direction: 'Asc' } },
    { id: 'company_details.pan_number', name: 'Company Pan Card', sort: { sort: true, direction: 'Asc' } },
    { id: 'company_details.gst_number', name: 'Company GST Number', sort: { sort: true, direction: 'Asc' } },
    { id: 'bank_accounts.account_number', name: 'Bank Account Details', sort: { sort: true, direction: 'Asc' } },
    { id: 'company_details.company_status', name: 'Status', sort: { sort: true, direction: 'Asc' } }
  ];


  getCellValue(headerId: string, i: any): any {
    if (headerId.includes('company_details')) {
      return i.company_details[headerId.split('.')[1]];
    } else if (headerId.includes('bank_accounts')) {
      return this.bankAccounts(i.bank_accounts);
    } else if (headerId.includes('shareholders')) {
      return this.getShareholderNames(i.shareholders);
    } else {
      return i[headerId];
    }
  }

  private getShareholderNames(shareholders: any[]): string {
    if (shareholders && shareholders.length > 0) {
      return shareholders.map(shareholder => shareholder.shareholder_name).join(', ');
    }
    return '';
  }

  private bankAccounts(bankAccounts: any[]): string {
    if (bankAccounts && bankAccounts.length > 0) {
      return bankAccounts.map(bankAccount => bankAccount.account_number).join(', ');
    }
    return '';
  }

  newShareholderForm: FormGroup;

  dinValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const valid = /^\d{8}$/.test(control.value);
      return valid ? null : { 'invalidDIN': { value: control.value } };
    };
  }

  digitsOnlyValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const valid = /^\d+$/.test(control.value);
      return valid ? null : { 'digitsOnly': { value: control.value } };
    };
  }

  ngOnInit() {
    this.fetchcomp();

    this.myForm = this.fb.group({
      companyName: ['', [Validators.required, Validators.maxLength(100)]],
      companyCode: ['', Validators.required],
      dateOfIncorporation: ['', [Validators.required]],
      typeOfCompany: ['', Validators.required],
      status: ['Active', Validators.required],
      shareholders: [''],
      companyContactNumber: ['', [Validators.required, Validators.pattern('^[0-9]{10,13}$')]],
      // Bank Account Details
      accountNumber: ['',],
      ifscCode: [''],
      bankName: [''],
      bankAddress: [''],
      nameAsPerBank: [''],
      labourLicenseDateOfIssue: ['', Validators.required],
      labourLicenseRegistrationNumber: ['', Validators.required],
      labourLicenseExpiryDate: ['', Validators.required],
      ptNumber: ['', Validators.required],
      ptExpiryDate: ['', Validators.required],
      udyamRegistrationNumber: ['', Validators.required],
      udyamExpiryDate: ['', Validators.required],
      incorporationCertificateDateOfIssue: ['', Validators.required],
      incorpCertNo: ['', Validators.required],
      gstNumber: ['', [Validators.required, this.gstinValidator()]],
      panNumber: ['', [Validators.required, this.panValidator()]],
      mtwRegistrationNumber: ['', Validators.required],
      mtwDateOfIssue: ['', Validators.required],
      mtwExpiryDate: ['', Validators.required],

      charteredAccountants: this.fb.array([this.createCharteredAccountant()]),
      auditors: this.fb.array([this.createAuditor()]),
      company_bank_accounts: this.fb.array([this.createBankAccount()]),
      company_emis: this.fb.array([this.createEmi()]),
      company_shareholders: this.fb.array([this.createShareholder()]),

      vehicleNumber: [''],
      emisFrom: [''],
      emisTo: [''],
      companyPfNumber: ['', Validators.required],
      epfoDateOfIssue: ['', Validators.required],

      esiNumber: ['', Validators.required],
      esiDateOfIssue: ['', Validators.required],

      tdsNumber: ['', Validators.required],
      tanDateOfIssue: ['', Validators.required],
    }, { validators: this.atLeastOneRecordValidator() });
  }

  atLeastOneRecordValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const charteredAccountants = control.get('charteredAccountants') as FormArray;
      const auditors = control.get('auditors') as FormArray;
      const company_bank_accounts = control.get('company_bank_accounts') as FormArray;
      const company_emis = control.get('company_emis') as FormArray;
      const company_shareholders = control.get('company_shareholders') as FormArray;

      if (
        charteredAccountants.length === 0 &&
        auditors.length === 0 &&
        company_bank_accounts.length === 0 &&
        company_emis.length === 0 &&
        company_shareholders.length === 0
      ) {
        return { atLeastOneRecordRequired: true };
      }

      return null;
    };
  }

  panValidator() {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
      const valid = panRegex.test(control.value);
      return valid ? null : { invalidPan: { value: control.value } };
    };
  }

  aadhaarValidator() {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const aadhaarRegex = /^[0-9]{12}$/;
      const valid = aadhaarRegex.test(control.value);
      return valid ? null : { invalidAadhaar: { value: control.value } };
    };
  }

  alphabetOnlyValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      const alphabetRegex = /^[A-Za-z\s]+$/;
      if (value && !alphabetRegex.test(value)) {
        return { alphabetOnly: true };
      }
      return null;
    };
  }

  gstinValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      const gstinRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;
      if (value && !gstinRegex.test(value)) {
        return { invalidGstin: true };
      }
      return null;
    };
  }

  deleteDocument(filename: string, temRefVar?: any) {
    console.log("filename", filename);

    this.updated[filename] = false;
    temRefVar.value = '';
    console.log('this.fileNames', this.fileNames);

  }


  deleteDocumentInUpdate(filename: string) {
    if (!this.readOnlyVar) {
      for (let i = 0; i < this.fileNames.length; i++) {
        if (this.fileNames[i] && this.fileNames[i]!.includes(filename)) {
          this.fileNames[i] = undefined;
          break;
        }
      }
      console.log("filename", filename);

      this.updated[filename] = false;

      console.log('this.fileNames', this.fileNames);
    }
  }

  closeEditMode() {
    this.myForm.enable();
    this.myForm.reset();
    this.isEditMode = false;
    this.readOnlyVar = false;
    this.selectedTab = 'company-details';
    this.selectedSubTab = 'bank'
  }


  updateEndDateMin(startFieldName: string, endFieldName: string): void {
    const startDate = this.myForm.get(startFieldName)?.value;
    if (startDate) {
      const endDateControl = this.myForm.get(endFieldName);
      if (endDateControl) {
        endDateControl.enable(); // Enable the end date field if it was previously disabled
        endDateControl.setValidators([Validators.min(startDate)]); // Set minimum selectable date
        endDateControl.updateValueAndValidity(); // Update validity of the end date field
      }
    }
  }




  createAuditor(): FormGroup {
    return this.fb.group({
      auditorsName: ['', Validators.required],
      auditorsContactNumber: [
        '',
        [Validators.required, Validators.pattern('^[0-9]{10,13}$')],
      ],
      auditorsEmail: ['', [Validators.required, Validators.email]],
      auditorsCompanyName: ['', Validators.required],
      auditorsWorkingPeriodFrom: ['', Validators.required],
      auditorsWorkingPeriodTo: ['', Validators.required],
      auditorsAssignCompany: [[], Validators.required],
      auditorsCheckFeasibility: ['', Validators.required]
    });
  }

  createCharteredAccountant(): FormGroup {
    return this.fb.group({
      charteredAccountantName: ['', Validators.required],
      charteredAccountantContactNumber: ['', [Validators.required, Validators.pattern('^[0-9]{10,13}$')]],
      charteredAccountantEmail: ['', [Validators.required, Validators.email]],
      charteredAccountantCompanyName: ['', Validators.required],
      charteredAccountantWorkingPeriodFrom: ['', Validators.required],
      charteredAccountantWorkingPeriodTo: ['', Validators.required],
      charteredAccountantAssignCompany: [[], Validators.required],
      charteredAccountantCheckFeasibility: ['', Validators.required]
    });
  }




  createEmi(): FormGroup {
    return this.fb.group({
      // emi_id: [null], // Assuming this will be auto-incremented by the backend
      company_code: [''],
      vehicle_numbers: ['', Validators.required],
      total_vehicles: ['', [Validators.required, this.digitsOnlyValidator()]],
      financer: ['', [Validators.required, this.alphabetOnlyValidator()]],
      loan_number: ['', [Validators.required, this.digitsOnlyValidator()]],
      bank_name: ['', [Validators.required, this.alphabetOnlyValidator()]],
      account_number: ['', [Validators.required, this.digitsOnlyValidator()]],
      loan_amount: ['', [Validators.required, this.digitsOnlyValidator()]],
      interest_amount: ['', [Validators.required, this.digitsOnlyValidator()]],
      total_amount: ['', [Validators.required, this.digitsOnlyValidator()]],
      flat_rate: ['', Validators.required],
      no_of_paid_months: ['', [Validators.required, this.digitsOnlyValidator()]],
      no_of_due_months: ['', [Validators.required, this.digitsOnlyValidator()]],
      total_installments: ['', [Validators.required, this.digitsOnlyValidator()]],
      emi_starting_date: ['', Validators.required],
      emi_ending_date: ['', Validators.required],
      total_emi: ['', [Validators.required, this.digitsOnlyValidator()]],
      total_outstanding: ['', [Validators.required, this.digitsOnlyValidator()]],
      emi_date: ['', Validators.required],
    });
  }

  createBankAccount(): FormGroup {
    return this.fb.group({
      // account_id: [null], // Assuming this will be auto-incremented by the backend
      account_number: ['', Validators.required],
      ifsc_code: ['', Validators.required],
      bank_name: ['', Validators.required],
      bank_address: ['', Validators.required],
      name_as_per_bank_account: ['', Validators.required],
    });
  }

  createShareholder(): FormGroup {
    return this.fb.group({
      // shareholder_id: [null], // Assuming this will be auto-incremented by the backend
      company_code: [''],
      shareholder_name: ['', Validators.required],
      primary_contact_num: [
        '',
        [Validators.required, Validators.pattern('^[0-9]{10,20}$')]
      ],
      secondary_contact_num: [
        '',
        [Validators.pattern('^[0-9]{10,20}$')]
      ],
      din_no: ['', Validators.required],
      pan_no: ['', [Validators.required, this.panValidator()]],
      aadhar_no: ['', [Validators.required, this.aadhaarValidator()]],
      status: ['Active', Validators.required],
    });
  }
  markAuditorsAsTouched() {
    this.auditorsArray.controls.forEach((auditor) => {
      auditor.markAllAsTouched();
    });
  }
  markCreateCharteredAsTouched() {
    this.charteredAccountantsArray.controls.forEach((accountant) => {
      accountant.markAllAsTouched();
    });
  }
  markEmisAsTouched() {
    this.companyEmisArray.controls.forEach((emi) => {
      emi.markAllAsTouched();
    });
  }
  markBankAccountsAsTouched() {
    this.bankAccountsArray.controls.forEach((account) => {
      account.markAllAsTouched();
    });
  }
  markShareholdersAsTouched() {
    this.shareholdersArray.controls.forEach((shareholder) => {
      shareholder.markAllAsTouched();
    });
  }
  get auditorsArray() {
    return this.myForm.get('auditors') as FormArray;
  }
  addAuditor() {
    this.onAddAuditor = true
    this.auditorsArray.push(this.createAuditor());
  }

  addEmi() {
    this.companyEmisArray.push(this.createEmi());
  }

  // Method to remove an EMI entry from the form array
  removeEmi(index: number) {
    this.companyEmisArray.removeAt(index);
  }

  get charteredAccountantsArray(): FormArray {
    return this.myForm.get('charteredAccountants') as FormArray;
  }
  addacharaccount() {
    this.onAddCA = true;
    this.charteredAccountantsArray.push(this.createCharteredAccountant());
  }

  get companyEmisArray(): FormArray {
    return this.myForm.get('company_emis') as FormArray;
  }
  removeAuditor(i: number) {
    // const control = <FormArray>this.myForm.controls['auditors'];
    // control.removeAt(i);
    this.auditorsArray.removeAt(i);
  }

  removechartedaccount(i: number) {
    this.charteredAccountantsArray.removeAt(i);
  }

  get bankAccountsArray(): FormArray {
    return this.myForm.get('company_bank_accounts') as FormArray;
  }

  addBankAccount() {
    this.bankAccountsArray.push(this.createBankAccount());
  }

  // Method to remove a bank account entry from the form array
  removeBankAccount(index: number) {
    this.bankAccountsArray.removeAt(index);
  }

  get shareholdersArray(): FormArray {
    return this.myForm.get('company_shareholders') as FormArray;
  }




  async fetch(file_paths: Array<any>) {
    console.log("inside fetch file fn")
    let data: any = {
      action: 'fetch_file',
      // company_code: this.myForm.get('companyCode').value,
      file_names: file_paths
    };
    let header = {
      Authorization: localStorage.getItem('t'),
      // 'X-Api-Key': localStorage.getItem('clientid'),
    };

    let apiURL = `${this.config.apiURL}/admin/companies`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.s == '1' || result.status == '1') {
      // console.log(result);
      this.fetchUrls = result.data;
      console.log('this.fetchUrls', this.fetchUrls);

      // this.mapFetchedFileNames(this.fetchUrls)
      // interface MatchingUrls {
      //     [key: string]: string;
      // }
      // const fileNames
      this.fileNames = []
      this.fileNames = Object.keys(this.fetchUrls);

      // Now keysArray contains only the keys of this.fetchUrls
      console.log('keys: ', this.fileNames);



      // console.log("Presigned urls for files:",this.fetchUrls)
      // Assuming this.matchingUrls is declared somewhere in your code
      this.matchingUrls = {};

      for (const key in this.fetchUrls) {
        if (Object.prototype.hasOwnProperty.call(this.fetchUrls, key)) {
          if (key.includes('gst_cert')) {
            this.matchingUrls['gst_cert'] = this.fetchUrls[key];
          } else if (key.includes('pan_card')) {
            this.matchingUrls['pan_card'] = this.fetchUrls[key];
          } else if (key.includes('tds_cert')) {
            this.matchingUrls['tds_cert'] = this.fetchUrls[key];
          } else if (key.includes('prof_tax_cert')) {
            this.matchingUrls['prof_tax_cert'] = this.fetchUrls[key];
          } else if (key.includes('udyam_cert')) {
            this.matchingUrls['udyam_cert'] = this.fetchUrls[key];
          } else if (key.includes('comp_incorp_cert')) {
            this.matchingUrls['comp_incorp_cert'] = this.fetchUrls[key];
          } else if (key.includes('mtw_card')) {
            this.matchingUrls['mtw_card'] = this.fetchUrls[key];
          } else if (key.includes('comp_labr_lic')) {
            this.matchingUrls['comp_labr_lic'] = this.fetchUrls[key];
          } else if (key.includes('epfo_cert')) {
            this.matchingUrls['epfo_cert'] = this.fetchUrls[key];
          } else if (key.includes('esi_cert')) {
            this.matchingUrls['esi_cert'] = this.fetchUrls[key];
          }
        }
      }

      console.log('Matching URL:', this.matchingUrls);

      const order = [
        'comp_labr_lic',
        'prof_tax_cert',
        'udyam_cert',
        'comp_incorp_cert',
        'gst_cert',
        'pan_card',
        'mtw_card',
        'tds_cert',
        'epfo_cert',
        'esi_cert'
      ];

      // Create an array of objects based on the defined order
      this.orderedUrls = order.map(key => ({
        [key]: this.matchingUrls[key]
      }));

      const extractIdentifier = (fileName: string): string | null => {
        const match = fileName.match(/.*?_([a-z_]+)\./);
        return match ? match[1] : null;
      };

      // Reordering the fileNames array based on the order array
      const orderedFileNames = order.map(identifier => {
        const found = this.fileNames.find((fileName: string) => extractIdentifier(fileName) === identifier);
        return found ? found : null;
      });

      // Filter out null values to get only the matched files
      this.fileNames = orderedFileNames.filter(fileName => fileName !== null);

      // console.log('matchedFileNames', matchedFileNames);
      console.log('Ordered URLs:', this.orderedUrls);
      console.log("fileNames", this.fileNames)


    }


    else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }

  }


  async populateForm(selectedCompany: any) {
    console.log('inside the populate form');
    console.log(selectedCompany);
    console.log(selectedCompany.company_details);
    if (selectedCompany) {
      // let companyDetails = this.selectedCompany.company_details;
      // console.log(companyDetails);
      // let bankDetails = this.selectedCompany.bank_details;
      // let licenseDetails = this.selectedCompany.license_details;
      // console.log("inside the populate form");
      console.log();

      const { company_details, license_details } =
        selectedCompany;
      console.log(company_details);
      console.log('inside the populate form');
      console.log(selectedCompany);
      // const [emisF, emisT] = company_details.vehicle_emi_duration.split(' to ');
      // console.log('EMI duration', emisF, emisT);
      this.sendUrls = []
      this.sendUrls.push(company_details.gst_s3_path)
      this.sendUrls.push(company_details.pan_s3_path)
      this.sendUrls.push(company_details.tds_s3_path)
      this.sendUrls.push(company_details.prof_tax_cert_upload)
      this.sendUrls.push(company_details.udyam_cert_upload)
      this.sendUrls.push(company_details.incorp_cert_upload)
      this.sendUrls.push(company_details.mtw_cert_upload)
      this.sendUrls.push(company_details.epfo_s3_path)
      this.sendUrls.push(company_details.esi_s3_path)
      //Labour license
      this.sendUrls.push(license_details.doc_s3_link)
      console.log(this.sendUrls)
      // console.log("URL:", this.fetchUrls);
      await this.fetch(this.sendUrls);
      console.log('onEDit', this.fileNames);

      console.log("Send urls", this.sendUrls)
      await this.myForm.patchValue({
        companyName: selectedCompany.company_name,
        companyCode: selectedCompany.company_code,
        dateOfIncorporation: company_details.incorporation_date,
        typeOfCompany: company_details.company_type,
        status: company_details.company_status,
        companyContactNumber: company_details.company_contactnum,
        gstNumber: company_details.gst_number,
        panNumber: company_details.pan_number,
        vehicleNumber: company_details.vehicle_number,
        companyPfNumber: company_details.pf_number,
        esiNumber: company_details.esi_number,
        tdsNumber: company_details.tds_number,
        labourLicenseDateOfIssue: license_details.issue_date,
        labourLicenseRegistrationNumber: license_details.registration_number,
        labourLicenseExpiryDate: license_details.expiry_date,
        ptNumber: company_details.prof_tax_cert_num,
        ptExpiryDate: company_details.prof_tax_cert_expiry,
        udyamRegistrationNumber: company_details.udyam_cert_reg_num,
        udyamExpiryDate: company_details.udyam_cert_expiry,
        incorporationCertificateDateOfIssue: company_details.incorp_cert_date,
        mtwRegistrationNumber: company_details.mtw_cert_reg_num,
        mtwDateOfIssue: company_details.mtw_cert_issue,
        mtwExpiryDate: company_details.mtw_cert_expiry,
        incorpCertNo: company_details.incorp_cert_no,
        epfoDateOfIssue: company_details.epfo_cert_issue,
        esiDateOfIssue: company_details.esi_cert_issue,
        tanDateOfIssue: company_details.tan_cert_issue,
      });

      const auditorsFormArray = this.myForm.get('auditors') as FormArray;
      auditorsFormArray.clear();
      console.log(
        'On edit length of auditors',
        selectedCompany.auditors.length
      );

      if (selectedCompany.auditors && selectedCompany.auditors.length > 0) {
        const auditorsFormArray = this.myForm.get('auditors') as FormArray;
        selectedCompany.auditors.forEach((auditor: any) => {
          const auditorGroup = this.createAuditor();
          auditorGroup.patchValue({
            auditorsName: auditor.auditor_name,
            auditorsContactNumber: auditor.contact_number,
            auditorsEmail: auditor.email_id,
            auditorsCompanyName: auditor.company_name,
            auditorsWorkingPeriodFrom: auditor.working_period_from ? auditor.working_period_from : '',
            auditorsWorkingPeriodTo: auditor.working_period_to ? auditor.working_period_to : '',
            auditorsAssignCompany: auditor.assigned_company_name,
            auditorsCheckFeasibility: auditor.check_feasibility
          });
          auditorsFormArray.push(auditorGroup);
        });
      }

      const charteredAccountantsFormArray = this.myForm.get('charteredAccountants') as FormArray;
      charteredAccountantsFormArray.clear();

      console.log('On edit length of accountants', selectedCompany.chartered_accountants.length);

      if (selectedCompany.chartered_accountants && selectedCompany.chartered_accountants.length > 0) {
        selectedCompany.chartered_accountants.forEach((ca: any) => {
          const caGroup = this.createCharteredAccountant();

          console.log('2222');

          caGroup.patchValue({
            charteredAccountantName: ca.ca_name,
            charteredAccountantContactNumber: ca.contact_number,
            charteredAccountantEmail: ca.email_id,
            charteredAccountantCompanyName: ca.company_name,
            charteredAccountantWorkingPeriodFrom: ca.ca_working_period_from || '',
            charteredAccountantWorkingPeriodTo: ca.ca_working_period_to || '',
            charteredAccountantAssignCompany: ca.ca_assigned_company_name,
            charteredAccountantCheckFeasibility: ca.ca_check_feasibility
          });
          console.log('3333');
          console.log(caGroup);

          charteredAccountantsFormArray.push(caGroup);
        });
      }


      const bankAccountsFormArray = this.myForm.get('company_bank_accounts') as FormArray;
      bankAccountsFormArray.clear();

      if (selectedCompany.bank_accounts && selectedCompany.bank_accounts.length > 0) {
        const bankAccountsFormArray = this.myForm.get('company_bank_accounts') as FormArray;
        selectedCompany.bank_accounts.forEach((bankAccount: any) => {
          const bankAccountGroup = this.createBankAccount();

          bankAccountGroup.patchValue({
            // company_code: payload.company_code,
            account_number: bankAccount.account_number,
            ifsc_code: bankAccount.ifsc_code,
            bank_name: bankAccount.bank_name,
            bank_address: bankAccount.bank_address,
            name_as_per_bank_account: bankAccount.name_as_per_bank_account,
          });

          bankAccountsFormArray.push(bankAccountGroup);
        });
      }


      const shareholdersFormArray = this.myForm.get('company_shareholders') as FormArray;
      shareholdersFormArray.clear();

      if (selectedCompany.shareholders && selectedCompany.shareholders.length > 0) {
        console.log('selectedCompany.shareholders', selectedCompany.shareholders);

        const shareholdersFormArray = this.myForm.get('company_shareholders') as FormArray;
        selectedCompany.shareholders.forEach((shareholder: any) => {
          const shareholderGroup = this.createShareholder();

          shareholderGroup.patchValue({
            shareholder_id: shareholder.shareholder_id,
            company_code: shareholder.company_code,
            shareholder_name: shareholder.shareholder_name,
            primary_contact_num: shareholder.primary_contact_num,
            secondary_contact_num: shareholder.secondary_contact_num,
            din_no: shareholder.din_no,
            pan_no: shareholder.pan_no,
            aadhar_no: shareholder.aadhar_no,
            status: shareholder.status,
          });

          shareholdersFormArray.push(shareholderGroup);
        });
      }

      const emisFormArray = this.myForm.get('company_emis') as FormArray;
      emisFormArray.clear();

      if (selectedCompany.emis && selectedCompany.emis.length > 0) {
        const emisFormArray = this.myForm.get('company_emis') as FormArray;
        selectedCompany.emis.forEach((emi: any) => {
          const emiGroup = this.createEmi();

          emiGroup.patchValue({
            emi_id: emi.emi_id,
            company_code: emi.company_code,
            vehicle_numbers: emi.vehicle_numbers,
            total_vehicles: emi.total_vehicles,
            financer: emi.financer,
            loan_number: emi.loan_number,
            bank_name: emi.bank_name,
            account_number: emi.account_number,
            loan_amount: emi.loan_amount,
            interest_amount: emi.interest_amount,
            total_amount: emi.total_amount,
            flat_rate: emi.flat_rate,
            no_of_paid_months: emi.no_of_paid_months,
            no_of_due_months: emi.no_of_due_months,
            total_installments: emi.total_installments,
            emi_starting_date: emi.emi_starting_date,
            emi_ending_date: emi.emi_ending_date,
            total_emi: emi.total_emi,
            total_outstanding: emi.total_outstanding,
            emi_date: emi.emi_date,
          });

          emisFormArray.push(emiGroup);
        });
      }


    }
  }

  clearForm(): void {

    this.myForm.reset();
    // this.myForm.patchValue({
    this.isEditMode = false;
    this.addcom = false;
    this.submitted = false;
    this.editShareholder = false;
    this.updateForm = false;
    this.editBankAccount = false;
    this.editEmi = false;
    this.editCA = false;
    this.editAuditor = false;
    this.selectedTab = 'company-details';
    this.selectedSubTab = 'bank';
  }



  toggleSidenav() {
    this.isOpen = !this.isOpen;
  }
  previewDocument(input: HTMLInputElement, doc: string) {
    console.log('input', input);

    const file = input.files?.[0];
    console.log("preview file", input.files?.[0]);

    if (file) {
      const fileType = file.name.split('.').pop()?.toLowerCase() || '';
      if (fileType === 'pdf' || this.isImageFile(fileType)) {
        const reader = new FileReader();
        reader.onload = () => {
          const blob = new Blob([reader.result as ArrayBuffer], {
            type: file.type,
          });
          const url = URL.createObjectURL(blob);
          const viewer = document.createElement('iframe');
          viewer.src = url;
          viewer.style.width = '100%';
          viewer.style.height = '100%';
          viewer.style.border = 'none';
          const newWindow = window.open();
          if (newWindow) {
            newWindow.document.body.appendChild(viewer);
            newWindow.document.title = file.name;
            newWindow.focus();
          } else {
            console.log('Failed to open preview window');
          }
        };
        reader.readAsArrayBuffer(file);
      } else {
        console.log('Unsupported file type for preview');
      }
    } else {
      // If no file is uploaded, show file from URL
      if (this.matchingUrls && this.matchingUrls[doc] && this.isEditMode) {
        const url = this.matchingUrls[doc];
        console.log('url', url);

        const viewer = document.createElement('iframe');
        viewer.style.width = '100%';
        viewer.style.height = '100%';
        viewer.style.border = 'none';
        const newWindow = window.open();
        if (newWindow) {
          newWindow.document.title = doc;
          viewer.src = url;
          newWindow.document.body.appendChild(viewer.cloneNode(true));
          newWindow.focus();
        } else {
          console.log('Failed to open preview window');
        }
      } else {
        console.log('No file selected and no URL available for preview');
      }
    }
  }



  reset(element: any, filename: string) {
    element.value = '';
    this.updated[filename] = undefined;
  }

  fileExist: boolean = false;
  progressBarStatus(fileName: string) {
    this.fileExist = this.fileInfoArray.find(file => file.file_name === fileName) !== undefined;
    // this.fileValue = fileExist ? fileExist : null;
    console.log('this.fileValue', this.fileExist);
    return this.fileExist;
  }

  progress: any = {};
  onFileSelected(fieldName: string, filename: string, event: any, progress_state?: boolean, tempRefVar?: any) {
    this.updated[filename] = true;
    this.progress[filename] = true; // Safely assign progress as a property
    console.log('this.progress', this.progress);

    console.log('this.updated', this.updated);

    console.log('Field Name:', fieldName);
    console.log('Event:', event);
    // this.progressBarStatus(filename)
    // this.fileExist = true;

    this.progressValue = 0;
    const file = event.target.files?.[0];
    console.log('Selected File:', file);
    if (file) {
      // Get the file name without extension
      const fileNameWithoutExtension = file.name
        .split('.')
        .slice(0, -1)
        .join('.');
      console.log('File Name:', fileNameWithoutExtension);

      // Get the file extension
      const fileExtension = file.name.split('.').pop()?.toLowerCase() || '';
      console.log('File Extension:', fileExtension);

      // Check file size and type
      if (file.size > 20 * 1024 * 1024) {
        // 20MB limit
        this.documentInputErrors[fieldName] =
          'File size exceeds the limit (20MB)';
        this.progress[filename] = false;
        tempRefVar.value = '';
        this.updated[filename] = false;
      } else {
        if (
          !this.isImageFile(fileExtension) &&
          file.type !== 'application/pdf'
        ) {
          this.documentInputErrors[fieldName] =
            'Unsupported file type. Only PDF and images are allowed.';
          this.progress[filename] = false;
          tempRefVar.value = '';
          this.updated[filename] = false;
        } else {
          this.documentInputErrors[fieldName] = null; // Clear error if file is valid

          // Check if an item with the same fieldName already exists
          const existingIndex = this.fileInfoArray.findIndex(item => item.fieldName === fieldName);
          if (existingIndex !== -1) {
            // Replace the existing item with the new one
            this.fileInfoArray[existingIndex] = {
              file_name: filename,
              ext: fileExtension,
              file: file,
              fieldName: fieldName,
            };
          } else {
            // Push the new item into the fileInfoArray
            this.fileInfoArray.push({
              file_name: filename,
              ext: fileExtension,
              file: file,
              fieldName: fieldName,
            });
          }

          this.progressValue = 45;

          setTimeout(() => {
            this.progressValue = 80;
            setTimeout(() => {
              this.progressValue = 100;
              setTimeout(() => {
                this.progress[filename] = false;
                console.log("fileExist is now false after 1.5 seconds");
              }, 500); // Delay for 0.5 seconds to simulate reaching 100

            }, 500);

          }, 500); // Delay for 0.5 seconds for value 45


          // Log the entire fileInfoArray
          console.log('File Info Array:', this.fileInfoArray);
          // this.progress[filename] = false;
        }
      }
    } else {
      this.documentInputErrors[fieldName] = 'No file selected';
      console.log('No file selected');
    }
  }

  isImageFile(fileType: string): boolean {
    return ['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(fileType);
  }

  fileInfoArray: { file_name: string; ext: string; file: File; fieldName: string, }[] = [];
  onSubmit() {
    // this.notifier.loading(true);
    this.submitted = true;
    console.log("Submitte value", this.submitted);
    console.log("touched value", this.udyamCertificateTouched);
    // console.log('Udyam Certificate files:', udyamCertificateInput.files);

    Object.values(this.myForm.controls).forEach((control: any) => {
      control.markAsTouched();
    });

    this.markAuditorsAsTouched();
    this.isSubmitted = true;
    console.log('Forms values', this.myForm.value);
    console.log('Form', this.myForm);
    console.log('Form Status', this.myForm.status);
    if (this.isEditMode) {
      if (Object.values(this.updated).some(value => value === true)) {
        this.file_upload();
        console.log('gggg');

      } else {
        this.updateCompany();
      }
    }
    else {
      if (this.fileInfoArray.length != 10) {
        this.notifier.alert('Info', '', 'Please fill all the mandatory fields', 'info', 5000);
      } else {
        this.file_upload();
      }
    }
    // this.fetchcomp();
  }

  async file_upload() {
    this.notifier.loading(true);
    if (this.myForm.valid) {
      let data: any = {
        action: 'file_upload',
        company_code: this.myForm.get('companyCode').value,
        files: this.fileInfoArray.map((file: any) => {
          return { file_name: file.file_name, ext: file.ext };
        }),
      };
      let header = {
        Authorization: localStorage.getItem('t'),
        // 'X-Api-Key': localStorage.getItem('clientid'),
      };

      let apiURL = `${this.config.apiURL}/admin/companies`;

      let result = await this.apiService.postDataPromis(apiURL, data, header);

      if (result.s == '1' || result.status == '1') {
        // console.log(result);

        this.uploadFiles(result.data, this.fileInfoArray);
      } else {
        this.notifier.alert('Info', '', result.error, 'info', 5000);
      }
    } else {
      this.notifier.alert('Info', '', 'Please fill all the mandatory fields', 'info', 5000);
    }
    this.notifier.loading(false);

  }

  uploadFiles(data: any, files: any) {
    this.notifier.loading(true);

    console.log("inside the upload file");
    let promises: Promise<any>[] = [];
    Object.keys(data).forEach((file_name: string, index: number) => {
      files[index]['formcontro_name'] = file_name;

      files[index]['status'] = 'pending';
      console.log(data[file_name]['presigned_url']);
      promises.push(
        this.uploadFileAPI(files[index], data[file_name]['presigned_url'])
      );
    });

    Promise.all(promises).then((values: any) => {
      if (this.isEditMode) {
        this.updateCompany(files);
      }
      else {
        this.addcompany(files);
      }

    });
    this.notifier.loading(false);

  }

  uploadFileAPI(file: any, url: string): Promise<any> {
    console.log("inside the upload file API");

    return new Promise(async (resolve: any, reject: any) => {
      this.uploadFile
        .uploadFile(url, file['file'])
        .subscribe(async (event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              break;
            case HttpEventType.ResponseHeader:
              break;
            case HttpEventType.UploadProgress:
              file['status'] = 'uploading';
              let progress = Math.round(
                (event.loaded / (event.total || file['file']['size'])) * 100
              );
              file.loaded = event.loaded;
              file.percentage = progress;
              break;
            case HttpEventType.Response:
              file['status'] = 'completed';
              resolve(true);
          }
        });
    });
  }

  mapFileNameToControlName(fileName: string): string | null {
    if (fileName.includes('tds_cert')) {
      return 'statutoryCompliancesUpload';
    } else if (fileName.includes('comp_labr_lic')) {
      return 'labourLicenseUpload';
    } else if (fileName.includes('prof_tax_cert')) {
      return 'ptCertificateUpload';
    } else if (fileName.includes('udyam_cert')) {
      return 'udyamCertificateUpload';
    } else if (fileName.includes('comp_incorp_cert')) {
      return 'incorporationCertificateUpload';
    } else if (fileName.includes('gst_cert')) {
      return 'gstCertificateUpload';
    } else if (fileName.includes('pan_card')) {
      return 'panCardUpload';
    } else if (fileName.includes('mtw_card')) {
      return 'mtwCertificateUpload';
    } else if (fileName.includes('epfo_cert')) {
      return 'epfoCertificateUpload';
    } else if (fileName.includes('esi_cert')) {
      return 'esiCertificateUpload';
    } else {
      return null;
    }
  }

  async addcompany(files: any) {
    this.notifier.loading(true);

    if (this.myForm.valid) {
      let formValues = this.myForm.value;
      let payload_files: any = {};

      files.forEach((file: any) => {
        payload_files[file.fieldName] = file.formcontro_name;
      });
      console.log("payload_files", payload_files);

      console.log('Inside the api call', formValues);


      let data: any = {

        action: "add_company_data",
        company_name: formValues.companyName,
        company_code: formValues.companyCode,
        date_of_incorporation: formValues.dateOfIncorporation,
        type_of_company: formValues.typeOfCompany,
        status: formValues.status,
        ph_no: formValues.companyContactNumber,
        bank_acc_details: {
          acc_no: formValues.accountNumber ? formValues.accountNumber : '',
          IFSC_code: formValues.ifscCode ? formValues.ifscCode : '',
          bank_name: formValues.bankName ? formValues.bankName : '',
          bank_address: formValues.bankAddress ? formValues.bankAddress : '',
          name: formValues.nameAsPerBank ? formValues.nameAsPerBank : ''
        },
        company_labour_license: {
          date_of_issue: formValues.labourLicenseDateOfIssue,
          reg_no: formValues.labourLicenseRegistrationNumber,
          expiry_date: formValues.labourLicenseExpiryDate,
          upload_license: payload_files['labourLicenseUpload']
        },
        pro_tax_certificate: {
          pt_no: formValues.ptNumber,
          expiry_date: formValues.ptExpiryDate,
          tax_cert: payload_files['ptCertificateUpload']
        },
        udyam_cer: {
          reg_no: formValues.udyamRegistrationNumber,
          expiry_date: formValues.udyamExpiryDate,
          udyam_cert: payload_files['udyamCertificateUpload']
        },
        comp_incorp_cert: {
          incorp_cert_no: formValues.incorpCertNo,
          date_of_issue: formValues.incorporationCertificateDateOfIssue,
          incorp_cert: payload_files['incorporationCertificateUpload']
        },
        comp_gst: {
          gst_no: formValues.gstNumber,
          gst_file: payload_files['gstCertificateUpload']
        },
        company_pan: {
          pan_no: formValues.panNumber,
          pan_file: payload_files['panCardUpload']
        },
        mtw_cert: {
          reg_no: formValues.mtwRegistrationNumber,
          date_of_issue: formValues.mtwDateOfIssue,
          expiry_date: formValues.mtwExpiryDate,
          mtw_file: payload_files['mtwCertificateUpload']
        },
        auditors: formValues.auditors.map((auditor: any) => {
          return {
            auditor_name: auditor.auditorsName,
            ph_no: auditor.auditorsContactNumber,
            email: auditor.auditorsEmail,
            company_name: auditor.auditorsCompanyName,
            working_period_from: auditor.auditorsWorkingPeriodFrom,
            working_period_to: auditor.auditorsWorkingPeriodTo,
            assigned_company_name: auditor.auditorsAssignCompany,
            check_feasibility: auditor.auditorsCheckFeasibility
          };
        }),
        ca_details: formValues.charteredAccountants.map((ca: any) => {
          return {
            ca_name: ca.charteredAccountantName,
            ph_no: ca.charteredAccountantContactNumber,
            email_id: ca.charteredAccountantEmail,
            company_name: ca.charteredAccountantCompanyName,
            ca_working_period_from: ca.charteredAccountantWorkingPeriodFrom,
            ca_working_period_to: ca.charteredAccountantWorkingPeriodTo,
            ca_assigned_company_name: ca.charteredAccountantAssignCompany,
            ca_check_feasibility: ca.charteredAccountantCheckFeasibility
          };
        }),
        bank_accounts: formValues.company_bank_accounts.map((bank_account: any) => {
          return {
            account_number: bank_account.account_number,
            ifsc_code: bank_account.ifsc_code,
            bank_name: bank_account.bank_name,
            bank_address: bank_account.bank_address,
            name_as_per_bank_account: bank_account.name_as_per_bank_account
          };
        }),
        emis: formValues.company_emis.map((emi: any) => {
          return {
            vehicle_numbers: emi.vehicle_numbers,
            total_vehicles: emi.total_vehicles,
            financer: emi.financer,
            loan_number: emi.loan_number,
            bank_name: emi.bank_name,
            account_number: emi.account_number,
            loan_amount: emi.loan_amount,
            interest_amount: emi.interest_amount,
            total_amount: emi.total_amount,
            flat_rate: emi.flat_rate,
            no_of_paid_months: emi.no_of_paid_months,
            no_of_due_months: emi.no_of_due_months,
            total_installments: emi.total_installments,
            emi_starting_date: emi.emi_starting_date,
            emi_ending_date: emi.emi_ending_date,
            total_emi: emi.total_emi,
            total_outstanding: emi.total_outstanding,
            emi_date: emi.emi_date
          };
        }),
        shareholders: formValues.company_shareholders.map((shareholder: any) => {
          return {
            shareholder_name: shareholder.shareholder_name,
            primary_contact_num: shareholder.primary_contact_num,
            secondary_contact_num: shareholder.secondary_contact_num ? shareholder.secondary_contact_num : '',
            din_no: shareholder.din_no,
            pan_no: shareholder.pan_no,
            aadhar_no: shareholder.aadhar_no,
            status: shareholder.status

          };
        }),
        fin_info: {
          vehicle_no: formValues.vehicleNumber,
          emis: `${formValues.emisFrom} to ${formValues.emisTo}`
        },
        statuatory_compliance: {
          comp_pf_no: formValues.companyPfNumber,
          epfo_cert_issue: formValues.epfoDateOfIssue,
          epfo_file: payload_files['epfoCertificateUpload'],
          esi_no: formValues.esiNumber,
          esi_cert_issue: formValues.esiDateOfIssue,
          esi_file: payload_files['esiCertificateUpload']
        },
        tds: {
          tds_no: formValues.tdsNumber,
          tan_cert_issue: formValues.tanDateOfIssue,
          tds_file: payload_files['statutoryCompliancesUpload']
        }
      };

      let header = {
        Authorization: localStorage.getItem('t'),
        // 'X-Api-Key': localStorage.getItem('clientid'),
      };

      //https://api.swayam.cloud/v3/admin/users/fetchaccountgroupsv2
      let apiURL = `${this.config.apiURL}/admin/companies`;

      let result = await this.apiService.postDataPromis(apiURL, data, header);

      if (result.s == '1' || result.status == '1') {
        this.submitted = false;
        this.isEditMode = false;
        this.addcom = false;
        this.clearForm();
        this.fileInfoArray = [];
        this.fetchcomp();
        this.notifier.alert('Success', '', result.data, 'success', 5000);

      } else {
        this.notifier.alert('Info', '', result.error, 'info', 5000);
      }

    }
    else {
      this.notifier.alert('Info', '', 'Please fill all the mandatory fields', 'info', 5000);
    }
    this.notifier.loading(false);

  }

  headers3: any = [
    {
      id: 'company_name',
      name: 'Company Name'
    }
  ];


  exportEach(maps: any) {
    this.notifier.loading(true);
    let vech_detail: any;
    let data: any = [
      {
        sheetName: 'Vehicle Details',
        data: [
          this.headers3.map((h: any) => {
            return { text: h.name };
          }),
        ],
      },
    ];
    let sheetData: any = maps;
    let headers: any = this.headers3;

    sheetData.map((item: any) => {
      vech_detail = item.company_details;
      let data_: any = [];
      headers.forEach((h: any) => {
        data_.push({ text: vech_detail[h.id] });
      });

      data[0]['data'].push(data_);
    });

    var options = {
      fileName:
        'Vehicle Details'
    };
    console.log('data' + JSON.stringify(data));

    Jhxlsx.export(data, options);
    this.notifier.loading(false);
  }


  exportToExcel(data: any[], filename: string, excludedColumns: string[] = []): void {
    const flattenedData: any[] = [];
    const customHeaders: { [key: string]: string } = {
      company_code: 'Company Code',
      company_name: 'Company Name',
      incorporation_date: 'Incorporation Date',
      company_type: 'Company Type',
      company_status: 'Company Status',
      company_shareholders: 'Company Shareholders',
      company_contactnum: 'Company Contact Number',
      gst_number: 'GST Number',
      pan_number: 'PAN Number',
      vehicle_number: 'Vehicle Number',
      vehicle_emi_duration: 'Vehicle Emi Duration',
      pf_number: 'PF Number',
      esi_number: 'ESI Number',
      tds_number: 'TDS Number',
      prof_tax_cert_num: 'Professional Tax Certificate Number',
      prof_tax_cert_expiry: 'Professional Tax Certificate Expiry',
      udyam_cert_reg_num: 'Udyam Certificate Registration Number',
      udyam_cert_expiry: 'Udyam Certificate Expiry',
      incorp_cert_date: 'Incorporation Certificate Date Of Issue',
      mtw_cert_reg_num: 'MTW Certificate Registration Number',
      mtw_cert_issue: 'MTW Certificate Date Of Issue',
      mtw_cert_expiry: 'MTW Certificate Expiry Date',
      account_number: 'Account Number',
      ifsc_code: 'IFSC Code',
      bank_name: 'Bank Name',
      bank_address: 'Bank Address',
      name_bank_account: 'Name As Per Bank Account',
      registration_number: 'Labour License Registration Number',
      issue_date: 'Labour License Issue Date',
      expiry_date: 'Labour License Expiry Date',
      auditors: 'Auditor',
      auditor_name: 'Auditor Name',
      contact_number: 'Contact Number',
      email_id: 'Email ID',
      ca_working_period_from: 'Working Period - From',
      ca_working_period_to: 'Working Period - To',
      chartered_accountants: 'CA',
      ca_name: 'CA Name',
      bank_accounts: 'Bank Account',
      shareholders: 'Shareholder',
      emis: 'EMI',
      name_as_per_bank_account: 'Name',
      vehicle_numbers: 'Vehicle Numbers',
      total_vehicles: 'Total Vehicles',
      financer: 'Financier',
      loan_number: 'Loan Number',
      loan_amount: 'Loan Amount',
      interest_amount: 'Interest Amount',
      total_amount: 'Total Amount',
      flat_rate: 'Flat Rate',
      no_of_paid_months: 'No. of Paid Months',
      no_of_due_months: 'No. of Due Months',
      total_installments: 'Total Installments',
      emi_starting_date: 'EMI Starting Date',
      emi_ending_date: 'EMI Ending Date',
      total_emi: 'Total EMI',
      total_outstanding: 'Total Outstanding',
      emi_date: 'EMI Date',
      shareholder_name: 'Shareholder Name',
      primary_contact_num: 'Primary Contact Number',
      secondary_contact_num: 'Secondary Contact Number',
      din_no: 'DIN Number',
      pan_no: 'PAN Number',
      aadhar_no: 'Aadhar Number',
      status: 'Status',
      incorp_cert_no: 'Incorporation Certificate Number',
      epfo_cert_issue: 'EPFO Certificate Issue Date',
      esi_cert_issue: 'ESI Certificate Issue Date',
      tan_cert_issue: 'TAN Certificate Issue Date',
      working_period_from: 'Working Period From',
      working_period_to: 'Working Period To'
    };

    data.forEach(item => {
      const flattenedItem: any = {};

      Object.keys(item).forEach(key => {
        if (!excludedColumns.includes(key)) {

          if (typeof item[key] === 'object' && !Array.isArray(item[key])) {
            Object.keys(item[key]).forEach(subKey => {
              if (!excludedColumns.includes(subKey)) {
                flattenedItem[customHeaders[subKey] || subKey] = item[key][subKey];
              }
            });
          } else if (Array.isArray(item[key])) {
            item[key].forEach((subItem: any, index: number) => {
              Object.keys(subItem).forEach(subKey => {
                if (!excludedColumns.includes(subKey)) {
                  flattenedItem[`${customHeaders[key] || key} ${index + 1} - ${customHeaders[subKey] || subKey}`] = subItem[subKey];
                }
              });
            });
          } else {
            flattenedItem[customHeaders[key] || key] = item[key];
          }
        }
      });

      flattenedData.push(flattenedItem);
    });

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(flattenedData);

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Companies Data');

    XLSX.writeFile(wb, `${filename}.xlsx`);
  }

  emiDataExport() {
    let emiData = this.object.emis;
    const excludedColumns = ['emi_id'];

    const customHeaders: { [key: string]: string } = {
      company_code: 'Company Code',
      vehicle_numbers: 'Vehicle Numbers',
      total_vehicles: 'Total Vehicles',
      financer: 'Financer',
      loan_number: 'Loan Number',
      bank_name: 'Bank Name',
      account_number: 'Account Number',
      loan_amount: 'Loan Amount',
      interest_amount: 'Interest Amount',
      total_amount: 'Total Amount',
      flat_rate: 'Flat Rate',
      no_of_paid_months: 'No of Paid Months',
      no_of_due_months: 'No of Due Months',
      total_installments: 'Total Installments',
      emi_starting_date: 'EMI Starting Date',
      emi_ending_date: 'EMI Ending Date',
      total_emi: 'Total EMI',
      total_outstanding: 'Total Outstanding',
      emi_date: 'EMI Date'
    };

    const flattenedData: any[] = [];

    emiData.forEach((item: any) => {
      const flattenedItem: any = {};

      Object.keys(item).forEach(key => {
        if (!excludedColumns.includes(key)) {
          if (typeof item[key] === 'object' && !Array.isArray(item[key])) {
            Object.keys(item[key]).forEach(subKey => {
              if (!excludedColumns.includes(subKey)) {
                flattenedItem[customHeaders[subKey] || subKey] = item[key][subKey];
              }
            });
          } else if (Array.isArray(item[key])) {
            item[key].forEach((subItem: any, index: number) => {
              Object.keys(subItem).forEach(subKey => {
                flattenedItem[`${customHeaders[key] || key} ${index + 1} - ${customHeaders[subKey] || subKey}`] = subItem[subKey];
              });
            });
          } else {
            flattenedItem[customHeaders[key] || key] = item[key];
          }
        }
      });

      flattenedData.push(flattenedItem);
    });

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(flattenedData);

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'EMIs');

    XLSX.writeFile(wb, 'EMI_Data.xlsx');
  }

  shareholdersDataExport() {
    let shareholdersData = this.object.shareholders;

    const customHeaders: { [key: string]: string } = {
      company_code: 'Company Code',
      shareholder_name: 'Shareholder Name',
      primary_contact_num: 'Primary Contact Number',
      secondary_contact_num: 'Secondary Contact Number',
      din_no: 'DIN Number',
      pan_no: 'PAN Number',
      aadhar_no: 'Aadhar Number',
      status: 'Status',
    };

    const flattenedData: any[] = [];

    shareholdersData.forEach((item: any) => {
      const flattenedItem: any = {};

      Object.keys(item).forEach(key => {
        if (item[key] && typeof item[key] === 'object' && !Array.isArray(item[key])) {
          Object.keys(item[key]).forEach(subKey => {
            flattenedItem[customHeaders[subKey] || subKey] = item[key][subKey];
          });
        } else if (Array.isArray(item[key])) {
          item[key].forEach((subItem: any, index: number) => {
            Object.keys(subItem).forEach(subKey => {
              flattenedItem[`${customHeaders[key] || key} ${index + 1} - ${customHeaders[subKey] || subKey}`] = subItem[subKey];
            });
          });
        } else {
          flattenedItem[customHeaders[key] || key] = item[key];
        }
      });

      flattenedData.push(flattenedItem);
    });

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(flattenedData);

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Shareholders');

    XLSX.writeFile(wb, 'Shareholders_Data.xlsx');
  }


  fetchDataAndExport(): void {
    const fetchedData = this.result;
    let excludedColumns = ['gst_s3_path', 'pan_s3_path', 'tds_s3_path', 'prof_tax_cert_upload', 'udyam_cert_upload', 'incorp_cert_upload', 'mtw_cert_upload', 'license_name', 'doc_s3_link', 'epfo_s3_path', 'esi_s3_path', 'emi_id', 'shareholder_id']
    this.exportToExcel(fetchedData, 'Companies Details', excludedColumns);
  }


  async updateCompany(files?: any) {
    this.notifier.loading(true);

    if (this.myForm.valid) {
      let payload_files: any = {};
      if (files) {
        files.forEach((file: any) => {
          // Iterate over each field in the form
          payload_files[file.fieldName] = file.formcontro_name;
        });
      }
      // console.log('Inside the api call', formValues);
      const formValues = this.myForm.value;
      let licence: any;
      let pro_tax: any;
      let udyam_certificate: any;
      let incorp_certificate: any;
      let gst_cert: any;
      let pan_cert: any;
      let mtw_cert: any;
      let tds_cert: any;
      let epfo_cert: any;
      let esi_cert: any;
      if (this.isEditMode) {
        console.log("payload files", payload_files);
        console.log(Object.keys(this.updated).length);
        // if (Object.keys(this.updated).length === 0) {
        licence = this.fileNames[0];
        gst_cert = this.fileNames[4];

        pro_tax = this.fileNames[1];
        incorp_certificate = this.fileNames[3];
        udyam_certificate = this.fileNames[2];
        pan_cert = this.fileNames[5];
        mtw_cert = this.fileNames[6];
        tds_cert = this.fileNames[7];
        epfo_cert = this.fileNames[8];
        esi_cert = this.fileNames[9];
        console.log(licence, pro_tax, udyam_certificate, incorp_certificate, gst_cert, pan_cert, mtw_cert, tds_cert);
        console.log('this.updated is empty');
        // } 
        if (Object.keys(this.updated).length !== 0) {
          const updatedKeys = Object.keys(this.updated);
          console.log("Updated files", updatedKeys);
          updatedKeys.forEach(key => {
            // if (payload_files.hasOwnProperty(key)) {
            switch (key) {
              case 'comp_labr_lic':
                licence = payload_files['labourLicenseUpload'];
                break;
              case 'prof_tax_cert':
                pro_tax = payload_files['ptCertificateUpload'];
                break;
              case 'udyam_cert':
                udyam_certificate = payload_files['udyamCertificateUpload'];
                break;
              case 'comp_incorp_cert':
                incorp_certificate = payload_files['incorporationCertificateUpload'];
                break;
              case 'gst_cert':
                gst_cert = payload_files['gstCertificateUpload'];
                break;
              case 'pan_card':
                pan_cert = payload_files['panCardUpload'];
                break;
              case 'mtw_card':
                mtw_cert = payload_files['mtwCertificateUpload'];
                break;
              case 'tds_cert':
                tds_cert = payload_files['statutoryCompliancesUpload'];
                break;
              case 'epfo_cert':
                epfo_cert = payload_files['epfoCertificateUpload'];
                break;
              case 'esi_cert':
                esi_cert = payload_files['esiCertificateUpload'];
                break;
            }
            // }
          });

          const remainingKeys = Object.keys(this.fileNames).filter(key => !payload_files.hasOwnProperty(key));

          console.log('remainingKeys', remainingKeys);

          remainingKeys.forEach((key, index) => {
            switch (key) {
              case 'licence':
                licence = this.fileNames[7];
                break;
              case 'pro_tax':
                pro_tax = this.fileNames[3];
                break;
              case 'udyam_certificate':
                udyam_certificate = this.fileNames[4];
                break;
              case 'incorp_certificate':
                incorp_certificate = this.fileNames[5];
                break;
              case 'gst_cert':
                gst_cert = this.fileNames[0];
                break;
              case 'pan_cert':
                pan_cert = this.fileNames[1];
                break;
              case 'mtw_cert':
                mtw_cert = this.fileNames[6];
                break;
              case 'tds_cert':
                tds_cert = this.fileNames[2];
                break;
              case 'epfo_cert':
                epfo_cert = this.fileNames[8];
                break;
              case 'esi_cert':
                esi_cert = this.fileNames[9];
                break;
            }
          });
        }
      }

      console.log('formValues.value', formValues.value);

      let data: any = {

        action: "update_company_data",
        company_name: formValues.companyName,
        company_code: formValues.companyCode,
        date_of_incorporation: formValues.dateOfIncorporation,
        type_of_company: formValues.typeOfCompany,
        status: formValues.status,
        // shareholders: formValues.shareholders,
        ph_no: formValues.companyContactNumber,
        bank_acc_details: {
          acc_no: formValues.accountNumber ? formValues.accountNumber : '',
          IFSC_code: formValues.ifscCode ? formValues.ifscCode : '',
          bank_name: formValues.bankName ? formValues.bankName : '',
          bank_address: formValues.bankAddress ? formValues.bankAddress : '',
          name: formValues.nameAsPerBank ? formValues.nameAsPerBank : ''
        },
        company_labour_license: {
          date_of_issue: formValues.labourLicenseDateOfIssue,
          reg_no: formValues.labourLicenseRegistrationNumber,
          expiry_date: formValues.labourLicenseExpiryDate,
          upload_license: licence
        },
        // gstCertificateUpload: this.fileNames[0],
        // panCardUpload: this.fileNames[1],
        // statutoryCompliancesUpload: this.fileNames[2],
        // ptCertificateUpload: this.fileNames[3],
        // udyamCertificateUpload: this.fileNames[4],
        // incorporationCertificateUpload: this.fileNames[5],
        // mtwCertificateUpload: this.fileNames[6],
        // labourLicenseUpload: this.fileNames[7],
        pro_tax_certificate: {
          pt_no: formValues.ptNumber,
          expiry_date: formValues.ptExpiryDate,
          tax_cert: pro_tax,
        },
        udyam_cer: {
          reg_no: formValues.udyamRegistrationNumber,
          expiry_date: formValues.udyamExpiryDate,
          udyam_cert: udyam_certificate,
        },
        comp_incorp_cert: {
          incorp_cert_no: formValues.incorpCertNo,
          date_of_issue: formValues.incorporationCertificateDateOfIssue,
          incorp_cert: incorp_certificate,
        },
        comp_gst: {
          gst_no: formValues.gstNumber,
          gst_file: gst_cert,
        },
        company_pan: {
          pan_no: formValues.panNumber,
          pan_file: pan_cert,
        },
        mtw_cert: {
          reg_no: formValues.mtwRegistrationNumber,
          date_of_issue: formValues.mtwDateOfIssue,
          expiry_date: formValues.mtwExpiryDate,
          mtw_file: mtw_cert,
        },
        auditors: formValues.auditors.map((auditor: any) => {

          return {
            auditor_name: auditor.auditorsName,
            ph_no: auditor.auditorsContactNumber,
            email: auditor.auditorsEmail,
            company_name: auditor.auditorsCompanyName,
            working_period_from: auditor.auditorsWorkingPeriodFrom,
            working_period_to: auditor.auditorsWorkingPeriodTo,
            assigned_company_name: auditor.auditorsAssignCompany,
            check_feasibility: auditor.auditorsCheckFeasibility

          };
        }),
        ca_details: formValues.charteredAccountants.map((ca: any) => {
          return {
            ca_name: ca.charteredAccountantName,
            ph_no: ca.charteredAccountantContactNumber,
            email_id: ca.charteredAccountantEmail,
            company_name: ca.charteredAccountantCompanyName,
            ca_working_period_from: ca.charteredAccountantWorkingPeriodFrom,
            ca_working_period_to: ca.charteredAccountantWorkingPeriodTo,
            ca_assigned_company_name: ca.charteredAccountantAssignCompany,
            ca_check_feasibility: ca.charteredAccountantCheckFeasibility
          };
        }),
        bank_accounts: formValues.company_bank_accounts.map((bank_account: any) => {
          return {
            account_number: bank_account.account_number,
            ifsc_code: bank_account.ifsc_code,
            bank_name: bank_account.bank_name,
            bank_address: bank_account.bank_address,
            name_as_per_bank_account: bank_account.name_as_per_bank_account
          };
        }),
        emis: formValues.company_emis.map((emi: any) => {
          return {
            vehicle_numbers: emi.vehicle_numbers,
            total_vehicles: emi.total_vehicles,
            financer: emi.financer,
            loan_number: emi.loan_number,
            bank_name: emi.bank_name,
            account_number: emi.account_number,
            loan_amount: emi.loan_amount,
            interest_amount: emi.interest_amount,
            total_amount: emi.total_amount,
            flat_rate: emi.flat_rate,
            no_of_paid_months: emi.no_of_paid_months,
            no_of_due_months: emi.no_of_due_months,
            total_installments: emi.total_installments,
            emi_starting_date: emi.emi_starting_date,
            emi_ending_date: emi.emi_ending_date,
            total_emi: emi.total_emi,
            total_outstanding: emi.total_outstanding,
            emi_date: emi.emi_date
          };
        }),
        shareholders: formValues.company_shareholders.map((shareholder: any) => {
          return {
            shareholder_name: shareholder.shareholder_name,
            primary_contact_num: shareholder.primary_contact_num,
            secondary_contact_num: shareholder.secondary_contact_num ? shareholder.secondary_contact_num : '',
            din_no: shareholder.din_no,
            pan_no: shareholder.pan_no,
            aadhar_no: shareholder.aadhar_no,
            status: shareholder.status

          };
        }),
        fin_info: {
          vehicle_no: formValues.vehicleNumber ? formValues.vehicleNumber : '',
          emis: (formValues.emisFrom || formValues.emisTo) ? `${formValues.emisFrom} to ${formValues.emisTo}` : ''
        },
        statuatory_compliance: {
          comp_pf_no: formValues.companyPfNumber,
          epfo_cert_issue: formValues.epfoDateOfIssue,
          epfo_file: epfo_cert,
          esi_no: formValues.esiNumber,
          esi_cert_issue: formValues.esiDateOfIssue,
          esi_file: esi_cert
        },
        tds: {
          tds_no: formValues.tdsNumber,
          tan_cert_issue: formValues.tanDateOfIssue,
          tds_file: tds_cert,
        }
      };

      let header = {
        Authorization: localStorage.getItem('t'),
        // 'X-Api-Key': localStorage.getItem('clientid'),
      };

      //https://api.swayam.cloud/v3/admin/users/fetchaccountgroupsv2
      let apiURL = `${this.config.apiURL}/admin/companies`;

      let result = await this.apiService.postDataPromis(apiURL, data, header);

      if (result.s == '1' || result.status == '1') {
        // console.log(result);
        this.updated = {};
        this.submitted = false;
        this.isEditMode = false;
        this.addcom = false;
        this.fileInfoArray = [];
        this.fetchcomp();
        this.clearForm();
        this.showPopup(
          'Company Updated',
          'The Company has been successfully updated.'
        );

      } else {
        this.notifier.alert('Info', '', result.error, 'info', 5000);
      }

    }
    else {
      this.notifier.alert('Info', '', 'Please fill all the mandatory fields', 'info', 5000);
    }
    this.notifier.loading(false);

  }
  editShareholder: boolean = false;

  onEditShareholder(shareholder: any, index: number): void {
    this.editShareholder = true;
    this.editIndex = index;
    this.newShareholderForm.patchValue(shareholder);
    this.shareholderTitle = 'Edit Share Holder';
  }

  goBackToSH() {
    this.editShareholder = false;
  }

  shareholderTitle: string = 'New Share Holder'; // Default value



  initiateAddShareholder(): void {
    this.editShareholder = true;
    this.newShareholderForm.reset();
    console.log('this.shareholdersArray', this.shareholdersArray);
    console.log(this.newShareholderForm);

    this.shareholderTitle = 'New Share Holder';
  }


  cancelAddShareholder(): void {
    this.editShareholder = false;
  }

  editBankAcc: boolean = false;
  // onEditBankAccount(bank_account: any) {
  //   console.log(bank_account);
  //   this.editBankAcc = true;
  // }

  goBackBA() {
    this.editBankAcc = false;
  }

  initiateAddBankAccount(): void {
    this.editBankAccount = true;
    this.newBankAccountForm.reset();
    this.bankAccountTitle = 'New Bank Account';
  }

  cancelAddBankAccount(): void {
    this.editBankAccount = false;
  }

  editIndex: number | null = null;
  onSubmitNewShareholder(): void {
    console.log('Edit Index:', this.editIndex);
    console.log('New Shareholder Form:', this.newShareholderForm.value);

    if (this.newShareholderForm.valid) {
      if (this.editIndex !== null && this.editIndex >= 0 && this.editIndex < this.shareholdersArray.length) {
        console.log('Updating shareholder at index', this.editIndex);
        const formGroup = this.shareholdersArray.at(this.editIndex) as FormGroup;

        // Ensure formGroup exists
        if (formGroup) {
          formGroup.patchValue(this.newShareholderForm.value);
        } else {
          console.error('Form group at index', this.editIndex, 'is not defined');
        }
      } else {
        console.log('Adding new shareholder');
        this.shareholdersArray.push(this.fb.group(this.newShareholderForm.value));
      }
      this.editShareholder = false;
      this.editIndex = null;
    } else {
      this.notifier.alert('Info', '', 'Please fill all the mandatory fields', 'info', 5000);
    }
    // console.log('Shareholder Group Value:', this.myForm.get('shareholderGroup').value);
  }

  onSubmitNewBankAccount(): void {
    console.log('Edit Bank Account Index:', this.editBankAccountIndex);
    console.log('New Bank Account Form:', this.newBankAccountForm.value);
    console.log('this.newBankAccountForm', this.newBankAccountForm);
    console.log('this.newBankAccountForm status', this.newBankAccountForm.status);
    console.log('this.newBankAccountForm valid', this.newBankAccountForm.valid);


    if (this.newBankAccountForm.valid) {
      if (this.editBankAccountIndex !== null && this.editBankAccountIndex >= 0 && this.editBankAccountIndex < this.bankAccountsArray.length) {
        console.log('Updating bank account at index', this.editBankAccountIndex);
        const formGroup = this.bankAccountsArray.at(this.editBankAccountIndex) as FormGroup;

        // Ensure formGroup exists
        if (formGroup) {
          formGroup.patchValue(this.newBankAccountForm.value);
        } else {
          console.error('Form group at index', this.editBankAccountIndex, 'is not defined');
        }
      } else {
        console.log('Adding new bank account');
        this.bankAccountsArray.push(this.fb.group(this.newBankAccountForm.value));
      }
      this.newBankAccountForm.reset();
      this.editBankAccount = false;
      this.editBankAccountIndex = null;
    } else {
      this.notifier.alert('Info', '', 'Please fill all the mandatory fields 55', 'info', 5000);
    }

    console.log('Updated Bank Accounts Array Value:', this.bankAccountsArray.value);
  }
  onEditBankAccount(bankAccount: any, index: number): void {
    this.editBankAccount = true;
    this.editBankAccountIndex = index;
    this.newBankAccountForm.patchValue(bankAccount);
    this.bankAccountTitle = 'Edit Bank Account';
  }


  initiateAddAuditor(): void {
    this.editAuditor = true;
    this.newAuditorForm.reset();
    this.auditorTitle = 'New Auditor';
  }

  cancelAddAuditor(): void {
    this.editAuditor = false;
    this.editAuditorIndex = null;
  }

  onSubmitNewAuditor(): void {
    if (this.newAuditorForm.valid) {
      if (this.editAuditorIndex !== null && this.editAuditorIndex >= 0 && this.editAuditorIndex < this.auditorsArray.length) {
        this.auditorsArray.at(this.editAuditorIndex).patchValue(this.newAuditorForm.value);
      } else {
        console.log("this.newAuditorForm.value", this.newAuditorForm.value);


        this.auditorsArray.push(this.fb.group({
          auditorsName: [this.newAuditorForm.value.auditorsName, Validators.required],
          auditorsContactNumber: [this.newAuditorForm.value.auditorsContactNumber, [Validators.required, Validators.pattern('^[0-9]{10}$')]],
          auditorsEmail: [this.newAuditorForm.value.auditorsEmail, [Validators.required, Validators.email]],
          auditorsCompanyName: [this.newAuditorForm.value.auditorsCompanyName, Validators.required],
          auditorsWorkingPeriodFrom: [this.newAuditorForm.value.auditorsWorkingPeriodFrom, Validators.required],
          auditorsWorkingPeriodTo: [this.newAuditorForm.value.auditorsWorkingPeriodTo, Validators.required],
          auditorsAssignCompany: [this.newAuditorForm.value.auditorsAssignCompany, Validators.required],
          auditorsCheckFeasibility: [this.newAuditorForm.value.auditorsCheckFeasibility, Validators.required]
        }));
      }
      this.cancelAddAuditor();
    }
  }

  onEditAuditor(auditor: any, index: number): void {
    this.editAuditor = true;
    this.editAuditorIndex = index;
    this.newAuditorForm.patchValue(auditor);
    this.auditorTitle = 'Edit Auditor';
  }

  searchQuery: string = '';
  filteredShareholders() {
    const query = this.searchQuery.toLowerCase();

    return this.shareholdersArray.value.filter((shareholder: any) =>
      Object.values(shareholder).some((val: any) =>
        String(val).toLowerCase().includes(query)
      )
    );
  }

  filteredEmis() {
    const query = this.searchEmiQuery.toLowerCase();
    // console.log(this.myForm);

    return this.myForm.get('company_emis').value.filter((emi: any) =>
      Object.values(emi).some((val: any) =>
        String(val).toLowerCase().includes(query)
      )
    );
  }

  filteredBankAccounts() {
    const query = this.searchBankAccountQuery.toLowerCase();
    // console.log(this.myForm.get('company_bank_accounts').value);
    // console.log(this.myForm);


    return this.myForm.get('company_bank_accounts').value.filter((account: any) =>
      Object.values(account).some((val: any) =>
        String(val).toLowerCase().includes(query)
      )
    );
  }

  filteredAuditors() {
    const query = this.searchAuditorQuery.toLowerCase();
    // console.log(this.myForm);

    return this.myForm.get('auditors').value.filter((auditor: any) =>
      Object.values(auditor).some((val: any) =>
        String(val).toLowerCase().includes(query)
      )
    );
  }

  onTabChange(tab: string) {
    this.selectedTab = tab;
    this.editAuditor = false;
    this.editBankAccount = false;
    this.editCA = false;
    this.editEmi = false;
    this.editShareholder = false;
    // Any additional logic for tab change
  }
  onSubTabChange(tab: string) {
    this.selectedSubTab = tab;
    // Any additional logic for tab change
  }
  cancelAddEmi(): void {
    this.editEmi = false;
  }

  initiateAddEmi(): void {
    this.editEmi = true;
    this.newEmiForm.reset();
    this.emiTitle = 'New EMI';
  }

  onSubmitNewEmi(): void {
    console.log('Edit EMI Index:', this.editEmiIndex);
    console.log('New EMI Form:', this.newEmiForm);

    if (this.newEmiForm.valid) {
      if (this.editEmiIndex !== null && this.editEmiIndex >= 0 && this.editEmiIndex < this.companyEmisArray.length) {
        console.log('Updating EMI at index', this.editEmiIndex);
        const formGroup = this.companyEmisArray.at(this.editEmiIndex) as FormGroup;

        if (formGroup) {
          formGroup.patchValue(this.newEmiForm.value);
        } else {
          console.error('Form group at index', this.editEmiIndex, 'is not defined');
        }
      } else {
        console.log('Adding new EMI');
        this.companyEmisArray.push(this.fb.group(this.newEmiForm.value));
      }
      this.editEmi = false;
      this.editEmiIndex = null;
    } else {
      this.notifier.alert('Info', '', 'Please fill in all required fields.');
    }
  }

  onEditEmi(emi: any, index: number): void {
    this.editEmi = true;
    this.emiTitle = 'Edit EMI';
    this.editEmiIndex = index;
    this.newEmiForm.patchValue(emi);
  }




  initiateAddCA(): void {
    this.editCA = true;
    this.newCAForm.reset();
    this.caTitle = 'New Chartered Accountant';
  }

  cancelAddCA(): void {
    this.editCA = false;
    this.editCAIndex = null;
  }

  onSubmitNewCA(): void {
    if (this.newCAForm.valid) {
      if (this.editCAIndex !== null && this.editCAIndex >= 0 && this.editCAIndex < this.charteredAccountantsArray.length) {
        this.charteredAccountantsArray.at(this.editCAIndex).patchValue(this.newCAForm.value);
      } else {
        this.charteredAccountantsArray.push(this.fb.group({
          charteredAccountantName: [this.newCAForm.value.charteredAccountantName, Validators.required],
          charteredAccountantContactNumber: [this.newCAForm.value.charteredAccountantContactNumber, [Validators.required, Validators.pattern('^[0-9]{10,13}$')]],
          charteredAccountantEmail: [this.newCAForm.value.charteredAccountantEmail, [Validators.required, Validators.email]],
          charteredAccountantCompanyName: [this.newCAForm.value.charteredAccountantCompanyName, Validators.required],
          charteredAccountantWorkingPeriodFrom: [this.newCAForm.value.charteredAccountantWorkingPeriodFrom, Validators.required],
          charteredAccountantWorkingPeriodTo: [this.newCAForm.value.charteredAccountantWorkingPeriodTo, Validators.required],
          charteredAccountantAssignCompany: [this.newCAForm.value.charteredAccountantAssignCompany, Validators.required],
          charteredAccountantCheckFeasibility: [this.newCAForm.value.charteredAccountantCheckFeasibility, Validators.required]
        }));
      }
      this.cancelAddCA();
    }
  }

  onEditCA(ca: any, index: number): void {
    this.editCA = true;
    this.editCAIndex = index;
    this.newCAForm.patchValue(ca);
    this.caTitle = 'Edit Chartered Accountant';
  }

  filteredCAs() {
    const query = this.searchCAQuery.toLowerCase();
    // console.log('this.myForm', this.myForm);

    return this.myForm.get('charteredAccountants').value.filter((ca: any) =>
      Object.values(ca).some((val: any) =>
        String(val).toLowerCase().includes(query)
      )
    );
  }



  async fetchcomp() {
    this.notifier.loading(true);

    let allData: any[] = [];
    let hasMoreData = true;

    while (hasMoreData) {
      let data = {
        action: 'fetch_all_companies_data',
        page: this.backend_pagination.page,
        per_page: this.backend_pagination.per_page,
      };

      let header = {
        Authorization: localStorage.getItem('t'),
      };

      let apiURL = `${this.config.apiURL}/admin/companies`;

      try {
        let result = await this.apiService.postDataPromis(apiURL, data, header);

        if (result.s == '1' || result.status == '1') {
          let fetchedData = result.data;

          allData = allData.concat(fetchedData);
          this.backend_pagination.page++;

          hasMoreData = fetchedData.length === this.backend_pagination.per_page;
          this.filteredData = allData;
          this.notifier.loading(false);

        } else {
          this.notifier.alert('Info', '', result.error, 'info', 5000);
          hasMoreData = false;
        }
      } catch (error) {
        this.notifier.alert('Error', '', 'Failed to fetch data', 'error', 5000);
        hasMoreData = false;
      }
    }
    this.backend_pagination.page = 1;
    this.result = allData;
    this.filteredData = allData;
    this.companyNamesList = allData.map((company) => company.company_name);
    console.log("this.companyNamesList", this.companyNamesList);
    console.log('fetch in companies', allData);

  }


  openModal(company: any) {
    this.selectedCompany = company;
    this.showModal = true;
    this.expandedIndex = null;
  }

  filteredData: any = [];
  queryResult(event: any) {
    let queryString: string = (event.target.value || '').toLowerCase();
    if (!queryString) {
      this.filteredData = this.result;
      return;
    }

    this.filteredData = this.result.filter((item: any) => {
      return this.headers.some((h: any) => {
        let value = this.getCellValue(h.id, item);
        if (value && typeof value === 'string') {
          return value.toLowerCase().includes(queryString);
        }
        return false;
      });
    });
  }


  hideModal() {
    this.showModal = false;
  }
  toggleEditMode(): void {
    this.isEditMode = !this.isEditMode;
  }

  addCom() {
    this.addcom = true;
    this.shareholdersArray.clear();
    this.bankAccountsArray.clear();
    this.companyEmisArray.clear();
    this.auditorsArray.clear();
    this.charteredAccountantsArray.clear()

  }


  openModalForConfirm(modalId: string) {
    const modalElement = document.getElementById(modalId);
    if (modalElement) {
      const modal = new bootstrap.Modal(modalElement);
      modal.show();
    }
  }

  readOnlyVar: boolean = false;

  async readOnly(selectedCompany: any) {
    this.notifier.loading(true);
    this.readOnlyVar = true;

    console.log("this.myForm", this.myForm);
    console.log('inside edit company');

    this.updateForm = true;
    this.fileNames = []
    this.isEditMode = true;
    this.expandedIndex = null;


    await this.populateForm(selectedCompany);

    this.myForm.disable();
    // this.charteredAccountantsArray.disable();
    // this.newCAForm.disable();
    console.log("this.progress", this.progress);
    console.log("this.fileNames", this.fileNames);
    this.notifier.loading(false);
  }

  object: any;

  async editCompany(selectedCompany: any): Promise<void> {
    this.notifier.loading(true);
    this.updateForm = true
    this.object = selectedCompany;
    this.expandedIndex = null;
    await this.populateForm(selectedCompany);

    this.isEditMode = true;
    this.notifier.loading(false);
  }

  expandedIndex: number | null = null;

  toggleOptions(index: number) {
    this.expandedIndex = (this.expandedIndex === index) ? null : index;
  }

}