import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NotifierService } from 'src/app/services/notifier.service';

@Component({
  selector: 'table-view',
  templateUrl: './table-view.component.html',
  styleUrls: ['./table-view.component.css']
})
export class TableViewComponent implements OnInit, OnDestroy{
  @Input('headers') headers: any;
  @Input('data') data: any;
  @Input('loading') loading: boolean = false;
  @Output() sendEvent: any = new EventEmitter<any>();

  constructor(public notifier: NotifierService) {}

  ngOnInit(): void {
      
  }

  default(event: Event) {
    // event.preventDefault();
    event.stopPropagation();
  }

  onFilterChange(event: any, item: any, onchange: boolean, id: string) {
    if(onchange) {
      this.sendEvent.emit({
        event: event,
        item: item,
        id: id
      })
    }
  }

  hideMenu(event: Event, h: any) {
    event.stopPropagation();
    h.show = !h.show;
  }

  ngOnDestroy(): void {
      
  }
}
