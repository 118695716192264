<div *ngIf="addcom || isEditMode">
  <div class="flex-start ml-4 mt-3">
    <button class="btn btn-light" (click)="closeEditMode()" *ngIf="readOnlyVar">
      <i class="fa fa-arrow-left " aria-hidden="true"></i>
    </button>
    <button class="btn btn-light" (click)="openModalForConfirm('staticBackdrop')" *ngIf="!readOnlyVar">
      <i class="fa fa-arrow-left " aria-hidden="true"></i>
    </button>
    <h4 class="mb-0 pb-0 font-weight-bold">
      {{ isEditMode ? "Update Staff" : "New Staff" }}
    </h4>
  </div>
  <div class="card mt-3 ml-4 mr-0 ">
    <form [formGroup]="myForm" (ngSubmit)="onSubmit()" autocomplete="off">
      <div class="card-body box-shadow">

        <div class="form-row justify-content-start mb-3">
          <div class="form-group mb-2 col-md-3 fl mr-5">
            <label class="no-wrap">First Name*</label>
            <input type="text" formControlName="first_name" class="inputOfForm" />
            <div *ngIf="
                    myForm.get('first_name')?.invalid &&
                    myForm.get('first_name')?.touched
                  ">
              <span *ngIf="myForm.get('first_name')?.errors?.['required']" class="error-message">First
                Name is
                required</span>
              <span class="error-message"
                *ngIf="!myForm.get('first_name')?.errors?.['required'] && myForm.get('first_name')?.errors?.['alphabetOnly']">Invalid
                input.
                Please enter alphabets only.</span>
            </div>
          </div>

          <div class="form-group mb-2 col-md-3 fl mr-5">
            <label class="no-wrap">Last Name*</label>
            <input type="text" formControlName="last_name" class="inputOfForm" />
            <div *ngIf="
                      myForm.get('last_name')?.invalid && myForm.get('last_name')?.touched
                    ">
              <span *ngIf="myForm.get('last_name')?.errors?.['required']" class="error-message">Last Name
                is
                required</span>
              <span class="error-message"
                *ngIf="!myForm.get('last_name')?.errors?.['required'] && myForm.get('last_name')?.errors?.['alphabetOnly']">Invalid
                input.
                Please enter alphabets only.</span>
            </div>
          </div>

          <div class="form-group mb-2 col-md-3 fl mr-5">
            <label class="no-wrap">Date of Birth*</label>
            <input type="date" formControlName="dob" (change)="calculateAge()" class="inputOfForm" />
            <div *ngIf="myForm.get('dob')?.invalid && myForm.get('dob')?.touched">
              <span *ngIf="myForm.get('dob')?.errors?.['required']" class="error-message">Date of Birth is
                required</span>
            </div>
          </div>

        </div>

        <div class="form-row justify-content-start mb-3">
          <div class="form-group mb-2 col-md-3 fl mr-5">
            <label class="no-wrap">Age*</label>
            <input type="number" formControlName="age" readonly class="inputOfForm" />
            <!-- <span>{{ age }}</span> -->
          </div>

          <div class="form-group mb-2 col-md-3 fl mr-5">
            <label for="gender">Gender*</label>
            <select formControlName="gender">
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="NA">NA</option>
            </select>
            <div *ngIf="myForm.get('gender')?.invalid && myForm.get('gender')?.touched">
              <span *ngIf="myForm.get('gender')?.errors?.['required']" class="error-message">Gender is
                required</span>
            </div>
          </div>

          <div class="form-group mb-2 col-md-3 fl mr-5">
            <label for="mar_status">Marital Status*</label>
            <select formControlName="marital_status">
              <option value="Married">Married</option>
              <option value="Unmarried">Unmarried</option>
            </select>
            <div *ngIf="
                                            myForm.get('marital_status')?.invalid &&
                                            myForm.get('marital_status')?.touched
                                          ">
              <span *ngIf="myForm.get('marital_status')?.errors?.['required']" class="error-message">Marital Status is
                required</span>
            </div>
          </div>

        </div>

        <div class="form-row justify-content-start mb-3">
          <div class="form-group mb-2 col-md-3 fl mr-5">
            <label class="no-wrap">Date of Joining*</label>
            <input type="date" formControlName="joining_date" class="inputOfForm" />
            <div *ngIf="
                    myForm.get('joining_date')?.invalid &&
                    myForm.get('joining_date')?.touched
                  ">
              <span *ngIf="myForm.get('joining_date')?.errors?.['required']" class="error-message">Joining
                Date is
                required</span>
            </div>
          </div>

          <div class="form-group mb-2 col-md-3 fl mr-5">
            <label class="no-wrap">Primary Contact Number*</label>
            <input type="text" formControlName="prim_cont_num" class="inputOfForm" />
            <div *ngIf="
                                        myForm.get('prim_cont_num')?.invalid &&
                                        myForm.get('prim_cont_num')?.touched
                                      ">
              <span *ngIf="myForm.get('prim_cont_num')?.errors?.['required']" class="error-message">Primary Contact
                Number
                is required</span>
              <span *ngIf="myForm.get('prim_cont_num')?.errors?.['pattern']" class="error-message">
                Primary Contact Number must be between 10 to 13 digits
              </span>
            </div>
          </div>


          <div class="form-group mb-2 col-md-3 fl mr-5">
            <label class="no-wrap">Secondary Contact Number*</label>
            <input type="text" formControlName="sec_cont_num" class="inputOfForm" />
            <div *ngIf="
                                    myForm.get('sec_cont_num')?.invalid &&
                                    myForm.get('sec_cont_num')?.touched
                                  ">
              <span *ngIf="myForm.get('sec_cont_num')?.errors?.['required']" class="error-message">Secondary Contact
                Number
                is required</span>
              <span *ngIf="myForm.get('sec_cont_num')?.errors?.['pattern']" class="error-message">
                Secondary Contact Number must be between 10 to 13 digits
              </span>
            </div>
          </div>
        </div>


        <h5 class="cur-address mb-4 mt-4"><b>Current Address</b></h5>

        <div class="form-row justify-content-start mb-3">
          <div class="form-group mb-2 col-md-3 fl mr-5">
            <label>State*</label>
            <input type="text" formControlName="cur_addr_state" class="inputOfForm" autocomplete="new-password">
            <div *ngIf="
                      myForm.get('cur_addr_state')?.invalid && myForm.get('cur_addr_state')?.touched
                    ">
              <span *ngIf="myForm.get('cur_addr_state')?.errors?.['required']" class="error-message">State is
                required</span>
            </div>
          </div>
          <div class="form-group mb-2 col-md-3 fl mr-5">
            <label>City*</label>
            <input formControlName="cur_addr_city" class="inputOfForm" autocomplete="new-password">
            <div *ngIf="
                      myForm.get('cur_addr_city')?.invalid && myForm.get('cur_addr_city')?.touched
                    ">
              <span *ngIf="myForm.get('cur_addr_city')?.errors?.['required']" class="error-message">City is
                required</span>
            </div>
          </div>
          <div class="form-group mb-2 col-md-3 fl mr-5">
            <label>District*</label>
            <input formControlName="cur_addr_district" class="inputOfForm" autocomplete="new-password">
            <div *ngIf="
                      myForm.get('cur_addr_district')?.invalid && myForm.get('cur_addr_district')?.touched
                    ">
              <span *ngIf="myForm.get('cur_addr_district')?.errors?.['required']" class="error-message">District is
                required</span>
            </div>
          </div>
          <div class="form-group mb-2 col-md-3 fl mr-5">
            <label>Street*</label>
            <input formControlName="cur_addr_street" class="inputOfForm" autocomplete="new-password">
            <div *ngIf="
                      myForm.get('cur_addr_street')?.invalid && myForm.get('cur_addr_street')?.touched
                    ">
              <span *ngIf="myForm.get('cur_addr_street')?.errors?.['required']" class="error-message">Street is
                required</span>
            </div>
          </div>
          <div class="form-group mb-2 col-md-3 fl mr-5">
            <label>House Number*</label>
            <input formControlName="cur_addr_house_no" class="inputOfForm" autocomplete="new-password">
            <div *ngIf="
                      myForm.get('cur_addr_house_no')?.invalid && myForm.get('cur_addr_house_no')?.touched
                    ">
              <span *ngIf="myForm.get('cur_addr_house_no')?.errors?.['required']" class="error-message">House Number is
                required</span>
            </div>
          </div>
          <div class="form-group mb-2 col-md-3 fl mr-5">
            <label>Pin Code*</label>
            <input formControlName="cur_addr_pin_code" class="inputOfForm" autocomplete="new-password">
            <div *ngIf="
                      myForm.get('cur_addr_pin_code')?.invalid && myForm.get('cur_addr_pin_code')?.touched
                    ">
              <span *ngIf="myForm.get('cur_addr_pin_code')?.errors?.['required']" class="error-message">Pin Code is
                required</span>
              <span
                *ngIf="!myForm.get('cur_addr_pin_code')?.errors?.['required'] && myForm.get('cur_addr_pin_code')?.errors?.['digitsOnly']"
                class="error-message">
                Invalid Pin Code
              </span>
            </div>
          </div>
        </div>



        <h5 class="cur-address mb-4 mt-4"><b>Permanent Address</b></h5>

        <div class="form-row justify-content-start mb-3">
          <div class="form-group mb-2 col-md-3 fl mr-5">
            <label>State*</label>
            <input type="text" formControlName="per_addr_state" class="inputOfForm" autocomplete="new-password">
            <div *ngIf="
                              myForm.get('per_addr_state')?.invalid && myForm.get('per_addr_state')?.touched
                            ">
              <span *ngIf="myForm.get('per_addr_state')?.errors?.['required']" class="error-message">State is
                required</span>
            </div>
          </div>
          <div class="form-group mb-2 col-md-3 fl mr-5">
            <label>City*</label>
            <input formControlName="per_addr_city" class="inputOfForm" autocomplete="new-password">
            <div *ngIf="
                              myForm.get('per_addr_city')?.invalid && myForm.get('per_addr_city')?.touched
                            ">
              <span *ngIf="myForm.get('per_addr_city')?.errors?.['required']" class="error-message">City is
                required</span>
            </div>
          </div>
          <div class="form-group mb-2 col-md-3 fl mr-5">
            <label>District*</label>
            <input formControlName="per_addr_district" class="inputOfForm" autocomplete="new-password">
            <div *ngIf="
                              myForm.get('per_addr_district')?.invalid && myForm.get('per_addr_district')?.touched
                            ">
              <span *ngIf="myForm.get('per_addr_district')?.errors?.['required']" class="error-message">District is
                required</span>
            </div>
          </div>
          <div class="form-group mb-2 col-md-3 fl mr-5">
            <label>Street*</label>
            <input formControlName="per_addr_street" class="inputOfForm" autocomplete="new-password">
            <div *ngIf="
                              myForm.get('per_addr_street')?.invalid && myForm.get('per_addr_street')?.touched
                            ">
              <span *ngIf="myForm.get('per_addr_street')?.errors?.['required']" class="error-message">Street is
                required</span>
            </div>
          </div>
          <div class="form-group mb-2 col-md-3 fl mr-5">
            <label>House Number*</label>
            <input formControlName="per_addr_house_no" class="inputOfForm" autocomplete="new-password">
            <div *ngIf="
                              myForm.get('per_addr_house_no')?.invalid && myForm.get('per_addr_house_no')?.touched
                            ">
              <span *ngIf="myForm.get('per_addr_house_no')?.errors?.['required']" class="error-message">House Number is
                required</span>
            </div>
          </div>
          <div class="form-group mb-2 col-md-3 fl mr-5">
            <label>Pin Code*</label>
            <input formControlName="per_addr_pin_code" class="inputOfForm" autocomplete="new-password">
            <div *ngIf="
                              myForm.get('per_addr_pin_code')?.invalid && myForm.get('per_addr_pin_code')?.touched
                            ">
              <span
                *ngIf="!myForm.get('per_addr_pin_code')?.errors?.['required'] && myForm.get('per_addr_pin_code')?.errors?.['digitsOnly']"
                class="error-message">
                Invalid Pin Code
              </span>

            </div>
          </div>
        </div>



        <div class="form-row justify-content-start mb-3">
          <div class="form-group mb-2 col-md-3 fl mr-5">
            <label>Client/Site Details*</label>
            <select formControlName="clientName">
              <option *ngFor="let client of clientData" [value]="client">{{ client }}</option>
            </select>
            <div *ngIf="myForm.get('clientName')?.invalid && myForm.get('clientName')?.touched">
              <span *ngIf="myForm.get('clientName')?.errors?.['required']" class="error-message">Client/Site Details is
                required</span>
            </div>
          </div>

          <div class="form-group mb-2 col-md-3 fl mr-5">
            <label>Languages Known*</label>
            <div class="d-flex" formArrayName="lang_known">
              <div *ngFor="let option of ['Hindi', 'English', 'Telugu', 'Other']; let i = index">
                <label class="wid-auto m-2">
                  <input class="wid-auto" type="checkbox" [formControlName]="i" />
                  {{ option }}
                </label>
              </div>
            </div>
            <div *ngIf="myForm.get('lang_known')?.invalid && myForm.get('lang_known')?.touched">
              <div *ngIf="myForm.get('lang_known').hasError('atLeastOneRequired')">
                <span class="error-message">Languages Known is
                  required</span>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row justify-content-start mb-3">
          <div class="form-group mb-2 col-md-3 fl mr-5">
            <label for="position">Position*</label>
            <select id="position" formControlName="position" (change)="onPositionChange($event)">
              <option *ngFor="let position of positionData" [value]="position">{{ position }}</option>
            </select>
            <div *ngIf="myForm.get('position')?.invalid && myForm.get('position')?.touched">
              <span *ngIf="myForm.get('position')?.errors?.['required']" class="error-message">Position is
                required</span>
            </div>
          </div>

          <div class="form-group mb-2 col-md-3 fl mr-5">
            <label for="manager">Manager*</label>
            <select *ngIf="managerData.length > 0" id="manager" formControlName="manager">
              <option *ngFor="let manager of managerData" [value]="manager">{{ manager }}</option>
            </select>
            <input *ngIf="managerData.length === 0" id="manager" class="inputOfForm" formControlName="manager"
              type="text">
            <div *ngIf="myForm.get('manager')?.invalid && myForm.get('manager')?.touched">
              <span *ngIf="myForm.get('manager')?.errors?.['required']" class="error-message">Manager is required</span>
            </div>
          </div>

          <div class="form-group mb-2 col-md-3 fl mr-5">
            <label for="emailId">Email ID*</label>
            <input type="email" id="emailId" formControlName="emailId" class="inputOfForm" />
            <div *ngIf="myForm.get('emailId')?.invalid && myForm.get('emailId')?.touched">
              <span *ngIf="myForm.get('emailId')?.errors?.['required']" class="error-message">Email ID is
                required</span>
              <span *ngIf="myForm.get('emailId')?.errors?.['email']" class="error-message">Invalid email ID</span>
            </div>
          </div>

          <div class="form-group mb-2 col-md-3 fl mr-5">
            <label for="departments">Departments*</label>
            <select id="departments" formControlName="departments">
              <option *ngFor="let department of departmentData" [value]="department">{{ department }}</option>
            </select>
            <div *ngIf="myForm.get('departments')?.invalid && myForm.get('departments')?.touched">
              <span *ngIf="myForm.get('departments')?.errors?.['required']" class="error-message">Departments is
                required</span>
            </div>
          </div>
        </div>





        <h5 class="cur-address mb-4 mt-4"><b>Experience</b></h5>

        <div class="form-row justify-content-start mb-3">
          <div class="form-group mb-2 col-md-3 fl mr-5">
            <label>Experience Years*</label>
            <input type="text" formControlName="tot_exp" class="inputOfForm" />
            <div *ngIf="myForm.get('tot_exp')?.invalid &&
                                                                myForm.get('tot_exp')?.touched
                                                              ">
              <span *ngIf="myForm.get('tot_exp')?.errors?.['required']" class="error-message">Experience
                Years is
                required</span>
            </div>
          </div>

          <div class="form-group mb-2 col-md-3 fl mr-5">
            <label>Experience Sector*</label>
            <input type="text" formControlName="exp_sec" class="inputOfForm" />
            <div *ngIf="myForm.get('exp_sec')?.invalid &&
                        myForm.get('exp_sec')?.touched
                        ">
              <span *ngIf="myForm.get('exp_sec')?.errors?.['required']" class="error-message">Experience
                sector is
                required</span>
            </div>
          </div>


        </div>



        <div class="form-row justify-content-start mb-3">
          <div class="form-group mb-2 col-md-3 fl mr-5">
            <label>AADHAR Number*</label>
            <input type="text" formControlName="adhar_num" class="inputOfForm">
            <div *ngIf="
                            myForm.get('adhar_num')?.invalid && myForm.get('adhar_num')?.touched
                          ">
              <span *ngIf="myForm.get('adhar_num')?.errors?.['required']" class="error-message">AADHAR
                Number is
                required</span>
              <span *ngIf="myForm.get('adhar_num')?.errors?.['pattern']" class="error-message">AADHAR
                Number should
                contain 12 digits
              </span>

            </div>
          </div>

          <div class="form-group mb-2 col-md-2 mr-5">
            <label>Upload*</label>
            <div>
              <span [ngStyle]="{display: !(isEditMode && !updated['aadhar_doc']) ? 'inline' : 'none'}">
                <input type="file" id="aadharUpload" #aadharUploadIn
                  (change)="onFileSelected('aadharUpload', 'aadhar_doc', $event, true, aadharUploadIn); aadharUploadTouched = true"
                  class="upldFile" style="display: none;" />
              </span>
              <ng-container *ngIf="(isEditMode && !bulkFileUpload) || addcom">
                <input class="upldFile" type="file" id="aadhar_doc"
                  (change)="onFileSelected('aadharUpload', 'aadhar_doc', $event, true, aadharUploadIn); aadharUploadTouched = true"
                  #aadharUploadIn style="display: none;" />
                <button *ngIf="this.fileNames[1] === undefined && !updated['aadhar_doc']" class="btn btn-primary ml-4"
                  type="button" (click)="aadharUploadIn.click()" [disabled]="readOnlyVar">
                  <i class="fa-solid fa-magnifying-glass mrgn-r-10"></i>Browse File
                </button>
                <span *ngIf="this.fileNames[1] !== undefined || updated['aadhar_doc']">
                  <div *ngIf="updateForm">
                    <div *ngIf="progress['aadhar_doc']">
                      <div class="progress">
                        <div class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100"
                          [style.width.%]="progressValue"></div>
                      </div>
                      <p class="upldTxt">uploading...</p>
                    </div>
                    <div class="button-container" *ngIf="!progress['aadhar_doc']">
                      <span class="del" (click)="deleteDocumentInUpdate('aadhar_doc')">Delete</span>
                      <div class="line"></div>
                      <span class="pre"
                        (click)="previewDocument(aadharUploadIn, 'aadhar_doc', 'aadhar_s3_path')">Preview</span>
                    </div>
                  </div>
                </span>
              </ng-container>
              <ng-container *ngIf="isEditMode && bulkFileUpload">
                <input class="upldFile" type="file" id="aadhar_doc"
                  (change)="onFileSelected('aadharUpload', 'aadhar_doc', $event, true, aadharUploadIn); aadharUploadTouched = true"
                  #aadharUploadIn style="display: none;" />
                <button *ngIf="this.fileNames[1] === '' && !updated['aadhar_doc']" class="btn btn-primary ml-4"
                  type="button" (click)="aadharUploadIn.click()" [disabled]="readOnlyVar">
                  <i class="fa-solid fa-magnifying-glass mrgn-r-10"></i>Browse File
                </button>
                <span *ngIf="this.fileNames[1] != '' || updated['aadhar_doc']">
                  <div *ngIf="updateForm">
                    <div *ngIf="progress['aadhar_doc']">
                      <div class="progress">
                        <div class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100"
                          [style.width.%]="progressValue"></div>
                      </div>
                      <p class="upldTxt">uploading...</p>
                    </div>
                    <div class="button-container" *ngIf="!progress['aadhar_doc']">
                      <span class="del" (click)="deleteDocumentInUpdateForBulkUpload('aadhar_doc')">Delete</span>
                      <div class="line"></div>
                      <span class="pre"
                        (click)="previewDocument(aadharUploadIn, 'aadhar_doc', 'aadhar_s3_path')">Preview</span>
                    </div>
                  </div>
                </span>
              </ng-container>
              <div>
                <span class="file-size-info ml-4">Max File size is 20MB</span>
              </div>
            </div>
            <span *ngIf="documentInputErrors['aadharUpload']" class="error-message">
              {{ documentInputErrors["aadharUpload"] }}
            </span>
            <div *ngIf="!isEditMode && myForm.get('aadharUpload')?.invalid && myForm.get('aadharUpload')?.touched">
              <span class="error-message">Upload Aadhar</span>
            </div>
          </div>



          <div class="form-group mb-2 col-md-3 fl mr-5">
            <label>PAN Number</label>
            <input type="text" formControlName="pan_num" class="inputOfForm">
            <div *ngIf="
                      myForm.get('pan_num')?.invalid && myForm.get('pan_num')?.touched
                    ">
              <span *ngIf="myForm.get('pan_num')?.errors?.['pattern']" class="error-message">PAN Number
                should be of
                pattern ABCDE1234F</span>
            </div>
          </div>

          <div class="form-group mb-2 col-md-2 mr-5">
            <label>Upload</label>
            <div>
              <span [ngStyle]="{display: !(isEditMode && !updated['pan_doc']) ? 'inline' : 'none'}">
                <input type="file" id="panUpload" #panUploadIn
                  (change)="onFileSelected('panUpload', 'pan_doc', $event, true, panUploadIn); panUploadTouched = true"
                  class="upldFile" style="display: none;" />
              </span>
              <ng-container *ngIf="(isEditMode && !bulkFileUpload) || addcom">
                <input class="upldFile" type="file" id="pan_doc"
                  (change)="onFileSelected('panUpload', 'pan_doc', $event, true, panUploadIn); panUploadTouched = true"
                  #panUploadIn style="display: none;" />
                <button *ngIf="this.fileNames[2] === undefined  && !updated['pan_doc']" class="btn btn-primary ml-4"
                  type="button" (click)="panUploadIn.click()" [disabled]="readOnlyVar">
                  <i class="fa-solid fa-magnifying-glass mrgn-r-10"></i>Browse File
                </button>
                <span *ngIf="this.fileNames[2] !== undefined || updated['pan_doc']">
                  <div *ngIf="updateForm">
                    <div *ngIf="progress['pan_doc']">
                      <div class="progress">
                        <div class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100"
                          [style.width.%]="progressValue"></div>
                      </div>
                      <p class="upldTxt">uploading...</p>
                    </div>
                    <div class="button-container" *ngIf="!progress['pan_doc']">
                      <span class="del" (click)="deleteDocumentInUpdate('pan_doc')">Delete</span>
                      <div class="line"></div>
                      <span class="pre" (click)="previewDocument(panUploadIn, 'pan_doc', 'pan_s3_path')">Preview</span>
                    </div>
                  </div>
                </span>
              </ng-container>
              <ng-container *ngIf="isEditMode && bulkFileUpload">
                <input class="upldFile" type="file" id="pan_doc"
                  (change)="onFileSelected('panUpload', 'pan_doc', $event, true, panUploadIn); panUploadTouched = true"
                  #panUploadIn style="display: none;" />
                <button *ngIf="this.fileNames[2] === '' && !updated['pan_doc']" class="btn btn-primary ml-4"
                  type="button" (click)="panUploadIn.click()" [disabled]="readOnlyVar">
                  <i class="fa-solid fa-magnifying-glass mrgn-r-10"></i>Browse File
                </button>
                <span *ngIf="this.fileNames[2] != '' || updated['pan_doc']">
                  <div *ngIf="updateForm">
                    <div *ngIf="progress['pan_doc']">
                      <div class="progress">
                        <div class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100"
                          [style.width.%]="progressValue"></div>
                      </div>
                      <p class="upldTxt">uploading...</p>
                    </div>
                    <div class="button-container" *ngIf="!progress['pan_doc']">
                      <span class="del" (click)="deleteDocumentInUpdateForBulkUpload('pan_doc')">Delete</span>
                      <div class="line"></div>
                      <span class="pre" (click)="previewDocument(panUploadIn, 'pan_doc', 'pan_s3_path')">Preview</span>
                    </div>
                  </div>
                </span>
              </ng-container>
              <div>
                <span class="file-size-info ml-4">Max File size is 20MB</span>
              </div>
            </div>
            <span *ngIf="documentInputErrors['panUpload']" class="error-message">
              {{ documentInputErrors["panUpload"] }}
            </span>
            <div *ngIf="!isEditMode && myForm.get('panUpload')?.invalid && myForm.get('panUpload')?.touched">
              <span class="error-message">Upload Pan Card</span>
            </div>
          </div>


        </div>


        <div class="table-responsive mb-4">
          <table class="table">
            <thead>
              <tr>
                <th>Account Number</th>
                <th>IFSC Code</th>
                <th>Bank Name</th>
                <th>Bank Address</th>
                <th>Name as per Bank Account</th>
                <th></th>
              </tr>
            </thead>
            <tbody formArrayName="bank_account_details">
              <tr *ngFor="let bank of bankAccountDetailsArray.controls; let i = index" [formGroupName]="i">
                <td>
                  <input type="text" id="acc_no" formControlName="acc_no" class="inputOfForm" />
                  <div *ngIf="bank.get('acc_no')?.invalid && bank.get('acc_no')?.touched">
                    <span class="error-message" *ngIf="bank.get('acc_no')?.errors?.['required']">Account Number is
                      required.</span>
                  </div>
                </td>
                <td>
                  <input type="text" id="ifsc_code" formControlName="ifsc_code" class="inputOfForm" />
                  <div *ngIf="bank.get('ifsc_code')?.invalid && bank.get('ifsc_code')?.touched">
                    <span class="error-message" *ngIf="bank.get('ifsc_code')?.errors?.['required']">IFSC Code is
                      required.</span>
                  </div>
                </td>
                <td>
                  <input type="text" id="bank_name" formControlName="bank_name" class="inputOfForm" />
                  <div *ngIf="bank.get('bank_name')?.invalid && bank.get('bank_name')?.touched">
                    <span class="error-message" *ngIf="bank.get('bank_name')?.errors?.['required']">Bank Name is
                      required.</span>
                  </div>
                </td>
                <td>
                  <input type="text" id="bank_address" formControlName="bank_address" class="inputOfForm" />
                  <div *ngIf="bank.get('bank_address')?.invalid && bank.get('bank_address')?.touched">
                    <span class="error-message" *ngIf="bank.get('bank_address')?.errors?.['required']">Bank Address is
                      required.</span>
                  </div>
                </td>
                <td>
                  <input type="text" id="name" formControlName="name" class="inputOfForm" />
                  <div *ngIf="bank.get('name')?.invalid && bank.get('name')?.touched">
                    <span class="error-message" *ngIf="bank.get('name')?.errors?.['required']">Name
                      is
                      required.</span>
                  </div>
                </td>
                <td>
                  <button type="button" class="btn btn-danger" (click)="removeBankAccount(i)"
                    [disabled]="readOnlyVar">Remove</button>
                </td>
              </tr>
            </tbody>
          </table>
          <button type="button" class="btn btn-primary ml-3" (click)="addBankAccount()" [disabled]="readOnlyVar">
            Add Bank Account
          </button>
        </div>

        <div class="form-group mb-2 col-md-2 mr-5">
          <label>Upload</label>
          <div>
            <span [ngStyle]="{display: !(isEditMode && !updated['passbook_doc']) ? 'inline' : 'none'}">
              <input type="file" id="passbookUpload" #passbookUploadIn
                (change)="onFileSelected('passbookUpload', 'passbook_doc', $event, true, passbookUploadIn); passbookUploadTouched = true"
                class="upldFile" style="display: none;" />
            </span>
            <ng-container *ngIf="(isEditMode && !bulkFileUpload) || addcom">
              <input class="upldFile" type="file" id="passbook_doc"
                (change)="onFileSelected('passbookUpload', 'passbook_doc', $event, true, passbookUploadIn); passbookUploadTouched = true"
                #passbookUploadIn style="display: none;" />
              <button *ngIf="this.fileNames[3] === undefined && !updated['passbook_doc']" class="btn btn-primary ml-4"
                type="button" (click)="passbookUploadIn.click()" [disabled]="readOnlyVar">
                <i class="fa-solid fa-magnifying-glass mrgn-r-10"></i>Browse File
              </button>
              <span *ngIf="this.fileNames[3] !== undefined || updated['passbook_doc']">
                <div *ngIf="updateForm">
                  <div *ngIf="progress['passbook_doc']">
                    <div class="progress">
                      <div class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100"
                        [style.width.%]="progressValue"></div>
                    </div>
                    <p class="upldTxt">uploading...</p>
                  </div>
                  <div class="button-container" *ngIf="!progress['passbook_doc']">
                    <span class="del" (click)="deleteDocumentInUpdate('passbook_doc')">Delete</span>
                    <div class="line"></div>
                    <span class="pre"
                      (click)="previewDocument(passbookUploadIn, 'passbook_doc', 'bank_passbook_s3_path')">Preview</span>
                  </div>
                </div>
              </span>
            </ng-container>
            <ng-container *ngIf="isEditMode && bulkFileUpload">
              <input class="upldFile" type="file" id="passbook_doc"
                (change)="onFileSelected('passbookUpload', 'passbook_doc', $event, true, passbookUploadIn); passbookUploadTouched = true"
                #passbookUploadIn style="display: none;" />
              <button *ngIf="this.fileNames[3] === '' && !updated['passbook_doc']" class="btn btn-primary ml-4"
                type="button" (click)="passbookUploadIn.click()" [disabled]="readOnlyVar">
                <i class="fa-solid fa-magnifying-glass mrgn-r-10"></i>Browse File
              </button>
              <span *ngIf="this.fileNames[3] != '' || updated['passbook_doc']">
                <div *ngIf="updateForm">
                  <div *ngIf="progress['passbook_doc']">
                    <div class="progress">
                      <div class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100"
                        [style.width.%]="progressValue"></div>
                    </div>
                    <p class="upldTxt">uploading...</p>
                  </div>
                  <div class="button-container" *ngIf="!progress['passbook_doc']">
                    <span class="del" (click)="deleteDocumentInUpdateForBulkUpload('passbook_doc')">Delete</span>
                    <div class="line"></div>
                    <span class="pre"
                      (click)="previewDocument(passbookUploadIn, 'passbook_doc', 'bank_passbook_s3_path')">Preview</span>
                  </div>
                </div>
              </span>
            </ng-container>
            <div>
              <span class="file-size-info ml-4">Max File size is 20MB</span>
            </div>
          </div>
          <span *ngIf="documentInputErrors['passbookUpload']" class="error-message">
            {{ documentInputErrors["passbookUpload"] }}
          </span>
          <div *ngIf="!isEditMode && myForm.get('passbookUpload')?.invalid && myForm.get('passbookUpload')?.touched">
            <span class="error-message">Upload Passbook</span>
          </div>
        </div>


        <div class="form-row justify-content-start mb-3">
          <div class="form-group mb-2 col-md-3 fl mr-5">
            <label class="no-wrap">Background Certificate Number</label>
            <input type="text" formControlName="back_cert_num" class="inputOfForm" />
          </div>


          <div class="form-group mb-2 col-md-2 mr-5">
            <label>Upload</label>
            <div>
              <span [ngStyle]="{display: !(isEditMode && !updated['bg_cert_doc']) ? 'inline' : 'none'}">
                <input type="file" id="bgCertUpload" #bgCertUploadIn
                  (change)="onFileSelected('bgCertUpload', 'bg_cert_doc', $event, true, bgCertUploadIn); bgCertUploadTouched = true"
                  class="upldFile" style="display: none;" />
              </span>
              <ng-container *ngIf="(isEditMode && !bulkFileUpload) || addcom">
                <input class="upldFile" type="file" id="bg_cert_doc"
                  (change)="onFileSelected('bgCertUpload', 'bg_cert_doc', $event, true, bgCertUploadIn); bgCertUploadTouched = true"
                  #bgCertUploadIn style="display: none;" />
                <button *ngIf="this.fileNames[4] === undefined && !updated['bg_cert_doc']" class="btn btn-primary ml-4"
                  type="button" (click)="bgCertUploadIn.click()" [disabled]="readOnlyVar">
                  <i class="fa-solid fa-magnifying-glass mrgn-r-10"></i>Browse File
                </button>
                <span *ngIf="this.fileNames[4] !== undefined || updated['bg_cert_doc']">
                  <div *ngIf="updateForm">
                    <div *ngIf="progress['bg_cert_doc']">
                      <div class="progress">
                        <div class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100"
                          [style.width.%]="progressValue"></div>
                      </div>
                      <p class="upldTxt">uploading...</p>
                    </div>
                    <div class="button-container" *ngIf="!progress['bg_cert_doc']">
                      <span class="del" (click)="deleteDocumentInUpdate('bg_cert_doc')">Delete</span>
                      <div class="line"></div>
                      <span class="pre"
                        (click)="previewDocument(bgCertUploadIn, 'bg_cert_doc', 'background_certificate_s3_path')">Preview</span>
                    </div>
                  </div>
                </span>
              </ng-container>
              <ng-container *ngIf="isEditMode && bulkFileUpload">
                <input class="upldFile" type="file" id="bg_cert_doc"
                  (change)="onFileSelected('bgCertUpload', 'bg_cert_doc', $event, true, bgCertUploadIn); bgCertUploadTouched = true"
                  #bgCertUploadIn style="display: none;" />
                <button *ngIf="this.fileNames[4] === '' && !updated['bg_cert_doc']" class="btn btn-primary ml-4"
                  type="button" (click)="bgCertUploadIn.click()" [disabled]="readOnlyVar">
                  <i class="fa-solid fa-magnifying-glass mrgn-r-10"></i>Browse File
                </button>
                <span *ngIf="this.fileNames[4] != '' || updated['bg_cert_doc']">
                  <div *ngIf="updateForm">
                    <div *ngIf="progress['bg_cert_doc']">
                      <div class="progress">
                        <div class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100"
                          [style.width.%]="progressValue"></div>
                      </div>
                      <p class="upldTxt">uploading...</p>
                    </div>
                    <div class="button-container" *ngIf="!progress['bg_cert_doc']">
                      <span class="del" (click)="deleteDocumentInUpdateForBulkUpload('bg_cert_doc')">Delete</span>
                      <div class="line"></div>
                      <span class="pre"
                        (click)="previewDocument(bgCertUploadIn, 'bg_cert_doc', 'background_certificate_s3_path')">Preview</span>
                    </div>
                  </div>
                </span>
              </ng-container>
              <div>
                <span class="file-size-info ml-4">Max File size is 20MB</span>
              </div>
            </div>
            <span *ngIf="documentInputErrors['bgCertUpload']" class="error-message">
              {{ documentInputErrors["bgCertUpload"] }}
            </span>
            <div *ngIf="!isEditMode && myForm.get('bgCertUpload')?.invalid && myForm.get('bgCertUpload')?.touched">
              <span class="error-message">Upload Background Certificate</span>
            </div>
          </div>




        </div>

        <h5 class="cur-address mb-4 mt-4"><b>Medical Certificate</b></h5>
        <div class="form-row justify-content-start mb-3">
          <div class="form-group mb-2 col-md-3 fl mr-5">
            <label>Date of Issue</label>
            <input type="date" formControlName="med_date_of_issue" class="inputOfForm" />
          </div>

          <div class="form-group mb-2 col-md-3 fl mr-5">
            <label> Date of Expiry</label>
            <input type="date" formControlName="med_date_of_expiry" class="inputOfForm" />
          </div>

          <div class="form-group mb-2 col-md-2 mr-2">
            <label>Upload</label>
            <div>
              <span [ngStyle]="{display: !(isEditMode && !updated['fitness_cert_doc']) ? 'inline' : 'none'}">
                <input type="file" id="fitnessCertUpload" #fitnessCertUploadIn
                  (change)="onFileSelected('fitnessCertUpload', 'fitness_cert_doc', $event, true, fitnessCertUploadIn); fitnessCertUploadTouched = true"
                  class="upldFile" style="display: none;" />
              </span>
              <ng-container *ngIf="(isEditMode && !bulkFileUpload) || addcom">
                <input class="upldFile" type="file" id="fitness_cert_doc"
                  (change)="onFileSelected('fitnessCertUpload', 'fitness_cert_doc', $event, true, fitnessCertUploadIn); fitnessCertUploadTouched = true"
                  #fitnessCertUploadIn style="display: none;" />
                <button *ngIf="this.fileNames[5] === undefined && !updated['fitness_cert_doc']"
                  class="btn btn-primary ml-4" type="button" (click)="fitnessCertUploadIn.click()"
                  [disabled]="readOnlyVar">
                  <i class="fa-solid fa-magnifying-glass mrgn-r-10"></i>Browse File
                </button>
                <span *ngIf="this.fileNames[5] !== undefined || updated['fitness_cert_doc']">
                  <div *ngIf="updateForm">
                    <div *ngIf="progress['fitness_cert_doc']">
                      <div class="progress">
                        <div class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100"
                          [style.width.%]="progressValue"></div>
                      </div>
                      <p class="upldTxt">uploading...</p>
                    </div>
                    <div class="button-container" *ngIf="!progress['fitness_cert_doc']">
                      <span class="del" (click)="deleteDocumentInUpdate('fitness_cert_doc')">Delete</span>
                      <div class="line"></div>
                      <span class="pre"
                        (click)="previewDocument(fitnessCertUploadIn, 'fitness_cert_doc', 'med_fit_cert_s3_path')">Preview</span>
                    </div>
                  </div>
                </span>
              </ng-container>
              <ng-container *ngIf="isEditMode && bulkFileUpload">
                <input class="upldFile" type="file" id="fitness_cert_doc"
                  (change)="onFileSelected('fitnessCertUpload', 'fitness_cert_doc', $event, true, fitnessCertUploadIn); fitnessCertUploadTouched = true"
                  #fitnessCertUploadIn style="display: none;" />
                <button *ngIf="this.fileNames[5] === '' && !updated['fitness_cert_doc']" class="btn btn-primary ml-4"
                  type="button" (click)="fitnessCertUploadIn.click()" [disabled]="readOnlyVar">
                  <i class="fa-solid fa-magnifying-glass mrgn-r-10"></i>Browse File
                </button>
                <span *ngIf="this.fileNames[5] != '' || updated['fitness_cert_doc']">
                  <div *ngIf="updateForm">
                    <div *ngIf="progress['fitness_cert_doc']">
                      <div class="progress">
                        <div class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100"
                          [style.width.%]="progressValue"></div>
                      </div>
                      <p class="upldTxt">uploading...</p>
                    </div>
                    <div class="button-container" *ngIf="!progress['fitness_cert_doc']">
                      <span class="del" (click)="deleteDocumentInUpdateForBulkUpload('fitness_cert_doc')">Delete</span>
                      <div class="line"></div>
                      <span class="pre"
                        (click)="previewDocument(fitnessCertUploadIn, 'fitness_cert_doc', 'med_fit_cert_s3_path')">Preview</span>
                    </div>
                  </div>
                </span>
              </ng-container>
              <div>
                <span class="file-size-info ml-4">Max File size is 20MB</span>
              </div>
            </div>
            <span *ngIf="documentInputErrors['fitnessCertUpload']" class="error-message">
              {{ documentInputErrors["fitnessCertUpload"] }}
            </span>
            <div
              *ngIf="!isEditMode && myForm.get('fitnessCertUpload')?.invalid && myForm.get('fitnessCertUpload')?.touched">
              <span class="error-message">Upload Medical Fitness Certificate</span>
            </div>
          </div>


        </div>


        <div class="form-row justify-content-start mb-3">
          <div class="form-group mb-2 col-md-3 fl mr-5">
            <label>ESI Number</label>
            <input type="text" formControlName="esi_num" class="inputOfForm">
          </div>

          <div class="form-group mb-2 col-md-3 fl mr-5">
            <label>PF Number</label>
            <input type="text" formControlName="pf_num" class="inputOfForm" />
          </div>

          <!-- <div class="form-group mb-2 fl mr-5 ">
            <label class="ml-5">Compliance</label>
            <input type="checkbox" formControlName="compliance" class="mr-130px">
            <small class="file-size-info mt-1">To be checked if all relevant information is filled in.</small>
          </div> -->
        </div>

        <div class="margin-left mb-5" *ngIf="!readOnlyVar">
          <div>
            <button class="btn btn-danger-soft m-6 clrbttn" type="button" data-bs-toggle="modal"
              data-bs-target="#staticBackdrop">
              Clear
            </button>
          </div>

          <div>
            <button class="btn btn-success-soft m-6 svbtn" type="submit">
              {{
              isEditMode && bulkFileUpload
              ? "Save"
              : isEditMode
              ? "Update"
              : "Save"
              }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>


<!-- Modal -->
<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog top-40">
    <div class="modal-content  left-30 width-40">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel">
          <b>Are you sure you want to discard the changes?</b>
        </h1>
        <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
      </div>
      <!-- <div class="modal-body">
            ...
          </div> -->
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary okaybtn" data-bs-dismiss="modal">
          No
        </button>
        <button type="button" class="btn btn-primary okaybtn" data-bs-dismiss="modal" (click)="clearForm()">
          Yes
        </button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!addcom && !isEditMode && !bulkFileUpload" class="box-shadow-darker card">
  <div class="card-header flex-between">
    <h4 class="font-weight-bold">Staffs</h4>
    <div class="flex-start">
      <button class="btn btn-light" (click)="fetchDrivers()">
        <i class="fa-solid fa-arrows-rotate"></i>
      </button>
      <div class="search-container">
        <i class="fas fa-search p-2 icon-blue"></i>
        <input type="text" placeholder="Search" name="" (keyup)="queryResult($event)">
      </div>
      <div class="options-container">
        <!-- <i class="fa-solid fa-ellipsis-vertical options-btn"(click)="toggleOptionsExport()"></i> -->
        <button class="blkbtn" type="button" appClickedOutside (clickedOutside)="toggleOptionsExportFalse()"
          (click)="toggleOptionsExport()" *ngIf="access==='readandwrite'">Bulk Upload</button>
        <!-- <div class="options-menu" *ngIf="expandedButton" > -->
        <div class="options-menu" [ngClass]="{ expanded: expandedButton }">

          <button class="opt-btn" (click)="get_excel_url()"><i class="fa-solid fa-download"></i>&nbsp; Sample
            File</button>
          <button class="opt-btn" (click)="openFileExplorer()"><i class="fa-solid fa-upload"></i>&nbsp; Upload
            File
          </button>
          <input type="file" (change)="fileSelected($event)" #fileInput style="display: none"
            accept=".xlsx, .csv, .pdf" />
        </div>
      </div>
      <button class="btn btn-success-soft svbtn" (click)="setAddDriver()" *ngIf="access==='readandwrite'">
        <i class="fa-solid fa-plus"></i> New
      </button>
      <!-- <button class="btn btn-success-soft export" (click)="fetchDataAndExport()">Export
      </button> -->
      <div class="options-container">
        <button class="export" type="button" appClickedOutside (clickedOutside)="toggleOptionsForExportFalse()"
          (click)="toggleOptionsForExport()">Export</button>
        <div class="options-menu" [ngClass]="{ expanded: expandedButtonForExport }">
          <button class="opt-btn width-250" (click)="fetchDataAndExport()"><i
              class="fas fa-file-export"></i>&nbsp;Export All
            Staff
            Data</button>
          <button class="opt-btn width-250" (click)="exportUnverifiedAttenders()"><i
              class="fas fa-file-export"></i>&nbsp;Export
            Unverified Staffs</button>
        </div>
      </div>


    </div>
  </div>
  <!-- Your existing code -->
  <div class="card-body">
    <div class="overflow-auto">
      <table class="table table-docs table-hover table-striped">
        <!-- Table headers -->
        <thead>
          <tr>
            <th>S.N.</th>
            <th *ngFor="let h of headers">
              {{h.name}}
              <span *ngIf="h.sort && h.sort.sort" (click)="notifier.sortTable(h,result)">

                <i class="fa fa-sort-amount-desc" aria-hidden="true" *ngIf="h.sort.direction == 'Des'"></i>
                <i class="fa fa-sort-amount-asc" aria-hidden="true" *ngIf="h.sort.direction == 'Asc'"></i>
              </span>

              <span *ngIf="h.filter " class="inputseach">
                <input type="text" [(ngModel)]="h.filterText" />
                <i class="fa fa-search" aria-hidden="true"></i>
              </span>
            </th>
            <th colspan="2" *ngIf="access==='readandwrite'">Actions</th>
          </tr>
        </thead>
        <tbody [ngStyle]="{'display': filteredData.length > 0 ? 'table-row-group': 'none'}">
          <!-- <tr *ngFor="let i of s3List; let j = index"> -->
          <ng-container
            *ngFor="let i of filteredData | paginate : { itemsPerPage: pagination['perPage'], currentPage: pagination['page'], totalItems: filteredData.length }; let j = index">
            <tr *ngIf="notifier.filterTableColums(headers,i)">
              <td>{{(pagination['page']-1) * (pagination['perPage']) + j+1}}</td>
              <td *ngFor="let header of headers" [ngClass]="{'status-cell': header.name === 'Status'}">
                <span (click)="header.name === 'First Name' ? readOnly(i) : null"
                  [ngClass]="{'status-inactive': getCellValue(header, i) === 'Non Compliant', 'status-active': getCellValue(header, i) === 'Compliant', 'first-column': header.name === 'First Name'}">
                  {{ getCellValue(header, i) }}
                </span>

                <span class="position-relative">
                  <i *ngIf="header.name === 'Compliance' && getCellValue(header, i) === 'Non Compliant'"
                    class="fas fa-info-circle" (mouseover)="showDetails(i, $event)" (mouseleave)="hideDetails()"></i>

                  <!-- Tooltip-like card -->
                  <div *ngIf="hoveredStaff === i" class="tooltip-card"
                    [ngStyle]="{'top.px': tooltipPosition.top, 'left.px': tooltipPosition.left}">

                    <div class="tooltip-arrow"></div> <!-- Arrow -->
                    <h5 class="card-title pl-3"><b>Compliance Status</b></h5>
                    <ul class="list-group list-group-flush">

                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        Background Certificate Number
                        <span class="badge">
                          <i
                            [ngClass]="i.background_certificate_number ? 'fas fa-check-circle fa-2x text-success' : 'fas fa-times-circle fa-2x text-danger'"></i>
                        </span>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        Background Certificate
                        <span class="badge">
                          <i
                            [ngClass]="i.background_certificate_s3_path != 'None' ? 'fas fa-check-circle fa-2x text-success' : 'fas fa-times-circle fa-2x text-danger'"></i>
                        </span>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        Medical Certificate Date of Issue
                        <span class="badge">
                          <i
                            [ngClass]="i.med_fit_cert_issuing_date ? 'fas fa-check-circle fa-2x text-success' : 'fas fa-times-circle fa-2x text-danger'"></i>
                        </span>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        Medical Certificate Date of Expiry
                        <span class="badge">
                          <i
                            [ngClass]="i.med_fit_cert_expiry_date ? 'fas fa-check-circle fa-2x text-success' : 'fas fa-times-circle fa-2x text-danger'"></i>
                        </span>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        Medical Certificate
                        <span class="badge">
                          <i
                            [ngClass]="i.med_fit_cert_s3_path != 'None' ? 'fas fa-check-circle fa-2x text-success' : 'fas fa-times-circle fa-2x text-danger'"></i>
                        </span>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        ESI Number
                        <span class="badge">
                          <i
                            [ngClass]="i.esi_number ? 'fas fa-check-circle fa-2x text-success' : 'fas fa-times-circle fa-2x text-danger'"></i>
                        </span>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        PF Number
                        <span class="badge">
                          <i
                            [ngClass]="i.pf_number ? 'fas fa-check-circle fa-2x text-success' : 'fas fa-times-circle fa-2x text-danger'"></i>
                        </span>
                      </li>
                    </ul>
                  </div>
                </span>
              </td>
              <td class="text-center" *ngIf="access==='readandwrite'">
                <i class="fas fa-edit edit-icon" (click)="editCompany(i)"></i>
              </td>
            </tr>
          </ng-container>
        </tbody>

        <tbody [ngStyle]="{display: filteredData.length == 0 ? 'table-row-group' : 'none'}">
          <tr>
            <td colspan="12" class="text-center">
              <div class="no_data text-center">No Staffs Found</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="paginator">
      <div class="flex-start">
        <span class="no-wrap">Per Page</span>
        <select class="form-select" [(ngModel)]="pagination['perPage']" (change)="pagination['page'] = 1">
          <option value="{{page}}" *ngFor="let page of pages">{{ page }}</option>
        </select>
      </div>
      <pagination-controls class="pagination" previousLabel="Prev" nextLabel="Next"
        (pageChange)="pagination['page'] = $event">
      </pagination-controls>
    </div>
  </div>
</div>
<!-- <div class="card-body">
    <div class="overflow-auto">
      <table class="table table-docs table-hover">
        <thead>
          <tr>
            <th>Company ID</th>
            <th>Employee Id</th>
            <th>Employee Name</th>
            <th>Experience</th>
            <th colspan="2">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let res of result">
            <td>{{ res.company_code }}</td>
            <td>{{ res.driver_id }}</td>
            <td>{{ res.first_name }}</td>
            <td>{{ res.total_exp }}</td>
            <td>
              <button type="button" class="btn btn-outline-primary" (click)="openModal(res)">
                <i class="fa-solid fa-eye"></i>
              </button>
            </td>
            <td>
              <button type="button" class="btn" (click)="editCompany(res)">
                <i class="fa-solid fa-pen-to-square"></i>
              </button>
            </td>
          </tr>
        </tbody>
        <tbody [ngStyle]="{
            display: result.length == 0 ? 'table-row-group' : 'none'
          }">
          <tr>
            <td [attr.colspan]="5">
              <div class="no_data text-center">No Drivers found</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div> -->

<div *ngIf="showModal" class="modal fade show" tabindex="-1" role="dialog" style="display: block">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"><strong>Staff Details</strong></h5>
        <button type="button" class="close" aria-label="Close" (click)="hideModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="overflow-y: auto">
        <div class="card-body">
          <div class="modal-section">
            <div class="mod">
              <span>First Name:</span><span>{{ selectedCompany.first_name }}</span>
            </div>
            <div class="mod">
              <span>Last Name:</span><span>{{ selectedCompany.last_name }}</span>
            </div>
            <div class="mod">
              <span>Date of Birth:</span><span>{{ selectedCompany.date_of_birth }}</span>
            </div>
            <div class="mod">
              <span>Age:</span><span>{{ selectedCompany.age }}</span>
            </div>
            <div class="mod">
              <span>Gender:</span><span>{{ selectedCompany.gender }}</span>
            </div>
            <div class="mod">
              <span>Marital Status:</span><span>{{ selectedCompany.marital_status }}</span>
            </div>
            <div class="mod">
              <span>Primary Phone Number:</span><span>{{ selectedCompany.primary_ph_no }}</span>
            </div>
            <div class="mod">
              <span>Secondary Phone Number:</span><span>{{ selectedCompany.secondary_ph_no }}</span>
            </div>
            <div class="mod">
              <span>Current Address:</span>
              <span>
                {{ selectedCompany.cur_addr_house_no }}, {{ selectedCompany.cur_addr_street }},
                {{ selectedCompany.cur_addr_city }}, {{ selectedCompany.cur_addr_district }},
                {{ selectedCompany.cur_addr_state }}, {{ selectedCompany.cur_addr_pin_code }}
              </span>
            </div>
            <div class="mod">
              <span>Permanent Address:</span>
              <span>
                {{ selectedCompany.per_addr_house_no }}, {{ selectedCompany.per_addr_street }},
                {{ selectedCompany.per_addr_city }}, {{ selectedCompany.per_addr_district }},
                {{ selectedCompany.per_addr_state }}, {{ selectedCompany.per_addr_pin_code }}
              </span>
            </div>
            <div class="mod">
              <span>Client Name:</span><span>{{ selectedCompany.client_name }}</span>
            </div>
            <div class="mod">
              <span>Languages Known:</span><span>{{ selectedCompany.languages_known }}</span>
            </div>
            <div class="mod">
              <span>Total Experience:</span><span>{{ selectedCompany.total_experience }}</span>
            </div>
            <div class="mod">
              <span>Experience Sector:</span><span>{{ selectedCompany.exp_sector }}</span>
            </div>
            <div class="mod">
              <span>Aadhar Number:</span><span (click)="previewDocument(aadharUploadIn, 'aadhar_doc', 'aadhar_s3_path')"
                class="pointer-link">{{ selectedCompany.aadhar_number }}</span>
            </div>
            <div class="mod">
              <span>PAN Number:</span><span (click)="previewDocument(panUploadIn, 'pan_doc', 'pan_s3_path')"
                [ngClass]="{'pointer-link': fileNames[2]}">{{ selectedCompany.pan_number }}</span>
            </div>
            <div class="mod">
              <span>Background Certificate Number:</span><span
                (click)="previewDocument(bgCertUploadIn, 'bg_cert_doc', 'background_certificate_s3_path')"
                [ngClass]="{'pointer-link': fileNames[4]}">{{ selectedCompany.background_certificate_number }}</span>
            </div>
            <div class="mod">
              <span>Medical Fitness Certificate Date of Issue:</span><span
                (click)="previewDocument(fitnessCertUploadIn, 'fitness_cert_doc', 'med_fit_cert_s3_path')"
                [ngClass]="{'pointer-link': fileNames[5]}">{{ selectedCompany.med_fit_cert_issuing_date }}</span>
            </div>
            <div class="mod">
              <span>Medical Fitness Certificate Date of Expiry:</span><span>{{ selectedCompany.med_fit_cert_expiry_date
                }}</span>
            </div>
            <div class="card-body mt-2 p-2" *ngIf="selectedCompany.bank_account.length">
              <h6 class="mb-3"><strong>Bank Account Details</strong></h6>
              <div class="modal-section">
                <ul>
                  <li *ngFor="let bank of selectedCompany.bank_account; let i = index">
                    <h6>Bank Account {{ i + 1 }}</h6>
                    <div class="mod">
                      <span>Bank Account Number:</span><span
                        (click)="previewDocument(passbookUploadIn, 'passbook_doc', 'bank_passbook_s3_path')"
                        [ngClass]="{'pointer-link': fileNames[3]}">{{ bank.acc_no }}</span>
                    </div>
                    <div class="mod">
                      <span>IFSC Code:</span><span>{{ bank.ifsc_code }}</span>
                    </div>
                    <div class="mod">
                      <span>Bank Name:</span><span>{{ bank.bank_name }}</span>
                    </div>
                    <div class="mod">
                      <span>Bank Address:</span><span>{{ bank.bank_address }}</span>
                    </div>
                    <div class="mod">
                      <span>Name as per Bank:</span><span>{{ bank.name_as_per_bank ?
                        bank.name_as_per_bank : bank.name
                        }}</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="mod">
              <span>ESI Number:</span><span>{{ selectedCompany.esi_number }}</span>
            </div>
            <div class="mod">
              <span>PF Number:</span><span>{{ selectedCompany.pf_number }}</span>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div *ngIf="bulkFileUpload && !isEditMode" class="box-shadow-darker card">
  <div class="card-header flex-between">
    <h4 class="font-weight-bold">Staff Bulk Upload</h4>
    <div class="flex-start">
      <button class="btn btn-light home-page" (click)="toggleToHome()">
        Home Page
      </button>
      <button class="btn btn-success-soft svbtn" (click)="onSubmitAllDataBulkUp()">
        Submit All
      </button>
    </div>
  </div>
  <!-- Your existing code -->
  <div class="card-body">
    <div class="overflow-auto">
      <table class="table table-docs table-hover">
        <!-- Table headers -->
        <thead>
          <tr>
            <th>FIRST NAME</th>
            <th>PRIMARY CONTACT NUMBER</th>
            <th>CURRENT ADDRESS</th>
            <th>CLIENT</th>
            <th>AADHAR NUMBER</th>
            <th>BACKGROUND CERTIFICATE NUMBER</th>
            <th>MEDICAL FITNESS CERTIFICATE EXPIRY DATE</th>
            <th>ESI NUMBER</th>
            <th>PF NUMBER</th>
            <th colspan="2">Actions</th>
          </tr>
        </thead>
        <tbody>
          <!-- Table body with ngFor loop to display company data -->
          <tr *ngFor="let data of transformedData">
            <td>{{ data.first_name }}</td>
            <td>{{ data.primary_ph_no }}</td>
            <td>{{ data.cur_addr_state }}</td>
            <td>{{ data.client_name }}</td>
            <td>{{ data.aadhar_number }}</td>
            <td>{{ data.background_certificate_number }}</td>
            <td>{{ data.med_fit_cert_expiry_date }}</td>
            <td>{{ data.esi_number }}</td>
            <td>{{ data.pf_number}}</td>
            <td class="text-center">
              <i class="fas fa-edit edit-icon" (click)="editCompany(data)"></i>
            </td>
          </tr>
        </tbody>
        <!-- Additional tbody to display "No Users Found" message -->
        <tbody [ngStyle]="{
            display: transformedData.length == 0 ? 'table-row-group' : 'none'
          }">
          <tr>
            <td [attr.colspan]="12">
              <div class="no_data text-center">No Staff Found</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<app-submit-popup *ngIf="popUp" (hideModa)="hidepopup($event)" [header]="popup_header"
  [subtitle]="popup_subtitle"></app-submit-popup>