import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APIService } from 'src/app/services/api.service';
import { RacesObject } from '../home/interfaces';
import { NotifierService } from 'src/app/services/notifier.service';

@Component({
  selector: 'app-venve-details',
  templateUrl: './venve-details.component.html',
  styleUrls: ['./venve-details.component.css'],
})
export class VenueDetailsComponent implements OnInit {
  /* 
=====queryparams=====
event_id
venue_id
date(yyyy-mm-dd)
event_name
*/
  eventId: string | undefined = undefined;
  venueId: string | undefined = undefined;
  date: string | undefined = undefined;
  eventName: string | undefined = undefined;
  event: any = {};
  loading: boolean = false;
  races: RacesObject[] = [];
  traceList: any = [];

  headers: any = [
    {
      id: 'ExternalRaceId',
      name: 'Race ID',
      type: 'string',
      show: true,
      filter: true,
      link: {
        href: '/dash/race-details',
        queryParamsKey: 'queryParamsRace',
      },
    },
    {
      id: 'SquentialRaceOrderNumber',
      name: 'Race Number',
      type: 'string',
      show: true,
      filter: true,
      // link: {
      //   href: '',
      //   queryParamsKey: ''
      // }
    },
    {
      id: 'RaceName',
      name: 'Race Name',
      type: 'string',
      show: true,
      filter: true,
    },
    {
      id: 'RaceTime',
      name: 'Race Time',
      type: 'string',
      show: true,
      filter: true,
    },
    {
      id: 'RaceLength',
      name: 'Race Length',
      type: 'string',
      show: true,
      filter: true,
    },
    {
      id: 'raceStatus',
      name: 'Race Status',
      type: 'component',
      filter: true,
      component: {
        name: 'StatusViewComponent',
        data: [
          {
            type: 'success',
            value: 'FINISHED',
          },
          {
            type: 'pending',
            value: 'Planned',
          },
        ],
      },
      show: true,
    },
    {
      id: 'Track',
      name: 'Current EQ Trace Track',
      type: 'string',
      filter: true,
      or: { type: 'string', text: 'Not assigned' },
      show: true,
    },
    {
      id: 'TrackID',
      name: 'EQ Trace Track',
      type: 'dropdown',
      show: true,
      dropdown: {
        getOptions: () => {
          return this.traceList || [];
        },
        key: 'TrackID',
      },
    },
  ];

  config: any;

  constructor(
    private router: ActivatedRoute,
    private apiService: APIService,
    private notifier: NotifierService
  ) {
    this.config = this.router.snapshot.data['config'];
  }

  ngOnInit(): void {
    this.router.queryParams.subscribe((params: any) => {
      this.eventId = params['event_id'];
      this.venueId = params['venue_id'];
      this.date = params['date'];
      this.eventName = params['event_name'];
      this.load();
    });
  }

  async load(): Promise<void> {
    this.notifier.loading(true);
    this.getTracts();
    await this.loadRacesBasedOnEvent();
    await this.loadEventDetails();
    this.getPDFDetails();
    this.notifier.loading(false);
  }

  async generateStartList() {
    this.event['pdf_status'] = 'GENERATING';
    let apiURL: string = `${this.config.raceAPI}/pdf-task?ExternalEventId=${this.eventId}`;

    let header: any = {
      'X-Api-Key': 'e2bvuAoslY1VJvFMqhr5x7nxoEhFVRii9jRngS3U',
    };

    let result: any = await this.apiService.postDataPromis(apiURL, {}, header);

    if (result.task_id) {
      this.notifier.alert('Success', '', 'Generating PDF...', 'success', 5000);
      setTimeout(() => {
        this.getPDFDetails();
      }, 5000);
    } else {
      this.event['pdf_status'] = 'FAILED';
    }
    // this.event = result.event;
  }

  async loadEventDetails() {
    let apiURL: string = `${this.config.raceAPI}/getevent?ExternalEventId=${this.eventId}`;

    let header: any = {
      'X-Api-Key': 'e2bvuAoslY1VJvFMqhr5x7nxoEhFVRii9jRngS3U',
    };

    let result: any = await this.apiService.getDataPromis(apiURL, {}, header);

    this.event = result.event;
  }

  async loadRacesBasedOnEvent(): Promise<boolean> {
    return new Promise(async (resolve: any) => {
      let apiURL: string = `${this.config.raceAPI}/getracedetails?ExternalVenueId=${this.venueId}&ExternalEventId=${this.eventId}`;

      let result: any = await this.apiService.getDataPromis(apiURL, {}, {});

      if (result && Array.isArray(result) && result.length > 0) {
        result.forEach((race: any) => {
          race['queryParamsRace'] = {
            race_id: race['ExternalRaceId'],
          };
        });
        result = result.sort((a: RacesObject, b: RacesObject) => {
          return a['SquentialRaceOrderNumber'] - b['SquentialRaceOrderNumber'];
        });
        await this.getRaceResult(result);
      }

      resolve(true);
    });
  }

  async getRaceResult(races: any) {
    return new Promise(async (resolve: any) => {
      let apiURL: string = `${
        this.config.raceAPI
      }/getraceresults?ExternalEventId=${this.eventId}&ExternalVenueId=${
        this.venueId
      }&${races
        .map((race: any) => {
          return `raceIds%5B%5D=${race['ExternalRaceId']}`;
        })
        .join('&')}`;

      let result: any = await this.apiService.getDataPromis(apiURL, {}, {});

      if (result && typeof result == 'object') {
        races.forEach((race: any) => {
          if (result[race['ExternalRaceId']] != undefined) {
            race['raceStatus'] = result[race['ExternalRaceId']];
          }
        });
      }

      this.races = [...this.races, ...races];
      resolve(true);
    });
  }

  async getPDFDetails() {
    return new Promise(async (resolve: any) => {
      let apiURL: string = `${this.config.raceAPI}/pdf-task?ExternalEventId=${this.eventId}`;

      let result: any = await this.apiService.getDataPromis(apiURL, {}, {});

      if (typeof result == 'string') {
        let obj: any = JSON.parse(result);
        if (obj['pdf_file_path']) {
          this.event['pdf_file_path'] = obj['pdf_file_path'];
          this.event['pdf_status'] = 'GENERATED';
        } else if(obj['is_running']){
          this.event['pdf_status'] = 'GENERATING';
        }  else if(!obj['is_running'] && obj['error_status']){
          this.event['pdf_status'] = 'FAILED';
          this.event['pdf_error'] = obj['error_status'];
        }
      } else if (typeof result == 'object') {
        if (
          result['error'] ==
          'No task found for the specified external event ID.'
        ) {
          this.event['pdf_status'] = 'NOT_GENERATED';
        }
      }
    });
  }

  async getTracts() {
    return new Promise(async (resolve: any) => {
      let apiURL: string = `${this.config.raceAPI}/geteqtrack?ExternalVenueId=${this.venueId}`;
      let header: any = {
        'X-Api-Key': 'e2bvuAoslY1VJvFMqhr5x7nxoEhFVRii9jRngS3U',
      };
      let result: any = await this.apiService.getDataPromis(apiURL, {}, header);
      if (result.venue && result.venue.TrackList) {
        this.traceList = result.venue.TrackList.map((trace: any) => {
          return {
            name: trace['EQTrackName'],
            value: trace['TrackID'],
          };
        });
      }
    });
  }
}
