import { Component, OnInit, Inject, NgModule  } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators, FormsModule } from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';


export interface back_value {
  value: string
}
export interface month_value {
  month: string,
  valid: boolean
}

@Component({
  selector: 'app-deputy',
  templateUrl: './deputy.component.html',
  styleUrls: ['./deputy.component.css']
})
export class DeputyComponent implements OnInit{
  title = 'Shift Prepare'
  login = false
  form!: FormGroup;
  submitted = false;
  months: month_value[] = [];
  users: back_value[] = [{value: "a"}, {value: "b"}];

  constructor(private formBuilder: FormBuilder, private http: HttpClient, public dialog: MatDialog, public router: Router) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group(
      {
        username: ['', Validators.required],
        password: ['', Validators.required]
      }
    );
    this.users = [];
    this.months = [];
    this.http.get<any>('${/get_deputyusers').subscribe(data => {
        var operator_range = data.users;
        operator_range.forEach((e: any) => {
          var bb = {value: e.name} as back_value
          this.users.push(bb)
        });
    })
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  async onSubmit(): Promise<void> {
    (document.getElementById("login") as HTMLButtonElement).disabled = true;
    console.log()
    this.submitted = true;
    if (this.form.invalid) {
      //console.log("failed");
      (document.getElementById("login") as HTMLButtonElement).disabled = false;
      return;
    }

    let queryParams = new HttpParams().append("username",this.form.value.username).append("password",this.form.value.password);
    this.http.get<any>('${/deputy_login', {params:queryParams}).subscribe(data => {
      console.log(data);
      var state = data.state;
      if (state != false){
        this.login = true;
        this.months = data.months;
        
      }
    });

    (document.getElementById("login") as HTMLButtonElement).disabled = false;

  }
  generate(m: any): void {
    console.log(m)
    if (m.valid){
      const dialogRef = this.dialog.open(steps_dialog, {
        data: m,
      });
    }else{
      const dialogRef = this.dialog.open(invalid_dialog, {
        data: m,
      });
    }
    
  }

}
//---------------------------------warning dialog---------------------------
@Component({
  selector: 'invalid_dialog',
  templateUrl: 'invalid.html',
})
export class invalid_dialog {
  constructor(
    public dialogRef: MatDialogRef<invalid_dialog>,
    private http: HttpClient,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: month_value,
  ) {
    dialogRef.disableClose = true;
  }
  onYesClick(){
    const dialogRef = this.dialog.open(steps_dialog, {
      data: this.data,
    });
    this.dialogRef.close();
  }
  onNoClick(){
    this.dialogRef.close();
  }
}

//---------------------------------process dialog---------------------------
@Component({
  selector: 'steps_dialog',
  templateUrl: 'steps.html',
})
export class steps_dialog implements OnInit{
  done = false;
  steps:string="..."
  config: any = {};
  constructor(
    public dialogRef: MatDialogRef<steps_dialog>,
    private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: month_value,
    private route: ActivatedRoute
  ) {
    dialogRef.disableClose = true;
    this.config = this.route.snapshot.data['config'];
  }
  ngOnInit(): void {
    let queryParams = new HttpParams().append("month",this.data.month);
    this.http.get<any>('${/get_processes', {params:queryParams}).subscribe(data => {
      console.log(data);
      this.steps = data.state;
    });
  }
  async onYesClick(): Promise<void> {
    let queryParams = new HttpParams().append("month",this.data.month);
    var res = await this.http.get<any>(`${this.config['serverAPI']}/deputy`, {params:queryParams}).subscribe(data => {
      console.log(data);
      this.done = data.state;
    });
    
    
  }
  onNoClick(){
    this.dialogRef.close();
  }
}