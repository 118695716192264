<div class="confirm-box">

    <div class="box" >
        <div><button
            type="button"
            class="close"
            aria-label="Close"
            (click)="no()"
          >
            <span aria-hidden="true">&times;</span>
          </button></div>
        <div class="hh">
            <h4><b> {{header}} </b></h4>
            <span>{{subtitle}}</span>
            <div class="d-flex justify-content-end gp10">
                <!-- <button class="btn cnclbtn" (click)="no()">Cancel</button> -->
                <button class="btn btn-primary okaybtn" (click)="no()">Ok</button>
            </div>
        </div>
    </div>
</div>
