<section class="pt-8 pt-md-11 pb-8 pb-md-14 mt-5">
  <div class="container card p-5">
    <div class="row justify-content-center">
      <div class="col-12 col-md-10 col-lg-8 text-center">
        <!-- Heading -->
        <h2 class="fw-bold">Product Feature Request</h2>

        <!-- Text -->
        <p class="fs-lg text-muted mb-7 mb-md-9">
          Your insights are valuable! Let us know how we can better serve you,
          and we'll do our utmost to meet your expectations.
        </p>
      </div>
    </div>
    <!-- / .row -->
    <div class="row justify-content-center">
      <div class="col-12 col-md-12 col-lg-10">
        <!-- Form -->
        <div>
          <div class="row mt-3">
            <div class="col-12">
              <label class="form-label" for="txtqueryC"> I want &nbsp; </label>

              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  id="inlineCheckbox1"
                  value="Support"
                  name="iwant"
                  checked
                  (click)="feedbackType = 'Support'"
                />
                <label class="form-check-label" for="inlineCheckbox1"
                  >Support</label
                >
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  id="inlineCheckbox2"
                  value="New Feature"
                  name="iwant"
                  (click)="feedbackType = 'New Feature'"
                />
                <label class="form-check-label" for="inlineCheckbox2"
                  >New Feature</label
                >
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12">
              <div class="form-group mb-7 mb-md-9">
                <!-- Label -->
                <label class="form-label" for="txtqueryC"> Tell us more </label>

                <!-- Input -->
                <textarea
                  class="form-control"
                  id="txtqueryC"
                  rows="5"
                  placeholder="Your request"
                  [(ngModel)]="notes"
                ></textarea>
              </div>
            </div>
          </div>
          <!-- / .row -->

          <div class="row mt-3">
            <div class="col-12">
              <div class="form-group mb-7 mb-md-9">
                <!-- Label -->
                <label class="form-label" for="customFile">
                  Upload a Document/Screenshot (Optional):
                </label>

                <input
                  type="file"
                  class="form-control"
                  id="feedbackFile"
                  (change)="changeInput($event)"
                />
              </div>
            </div>
          </div>
          <!-- / .row -->

          <div class="row justify-content-center">
            <div class="col-auto">
              <!-- Submit -->
              <button
                type="button"
                class="btn btn-primary lift mt-5 px-5 py-3"
                (click)="submitFiles()"
              >
                Send
              </button>
            </div>
          </div>
          <!-- / .row -->
        </div>
      </div>
    </div>
    <!-- / .row -->
  </div>
  <!-- / .container -->
</section>
