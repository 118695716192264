
import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  Input,
  VERSION,
  Host,
} from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { APIService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { environment } from 'src/environments/environment';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ElementRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
} from '@angular/common/http';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError } from 'rxjs/internal/operators/catchError';
import { Observable } from 'rxjs';
import { FileUploadService } from 'src/app/services/fileupload.service';
import { SafeKeyedRead, SelectorListContext } from '@angular/compiler';
import { fromUnixTime, differenceInYears } from 'date-fns';
import * as XLSX from 'xlsx';
import { v4 as uuidv4 } from 'uuid';
// import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

declare var bootstrap: any;
declare let $: any;
@Component({
  selector: 'app-attenders',
  templateUrl: './attenders.component.html',
  styleUrls: ['./attenders.component.css']
})
export class AttendersComponent {
  [x: string]: any;



  myForm: any;
  minEndDate: string = '';
  documentInputErrors: { [key: string]: string | null } = {};
  isOpen: boolean = false;
  isSubmitted: boolean = false;
  urlPrefix: string | null = null;
  config: any;
  addcom: boolean = false;
  result: any;
  selectedCompany: any;
  showModal: boolean = false;
  isEditMode: boolean = false;
  loading: boolean = false;
  fileNames: any;
  submitted: boolean = false;
  matchingUrls: any;
  orderedUrls: any[] = [];
  sendUrls: any[] = [];
  fetchUrls: any;
  updated: any = {};
  clientData: String[] = [];
  uniqueCmpCodes = new Set<string>();
  selectedCmpCode: string = '';
  selectedClientID: string = '';
  clientIds: any[] = [];
  clientName: any[] = [];
  age: number = 0;
  dlUploadTouched: boolean = false;
  aadharUploadTouched: boolean = false;
  panUploadTouched: boolean = false;
  passbookUploadTouched: boolean = false;
  bgCertUploadTouched: boolean = false;
  fitnessCertUploadTouched: boolean = false;
  @ViewChild('fileInput') fileInput!: ElementRef;
  bulkFileUpload: boolean = false;
  transformedData: any[] = [];
  pagination: any = { perPage: 10, page: 1, };
  pages: any = [10, 50, 100, 200];
  pageChange: EventEmitter<number> | undefined;

  popUp: boolean = false;
  popup_header: any = '';
  popup_subtitle: any = '';

  access = localStorage.getItem('acT');

  showPopup(header: any, title: any) {
    this.popup_header = header;
    this.popup_subtitle = title;
    this.popUp = true;
  }

  hidepopup(event: any) {
    this.popUp = false;
  }
  constructor(
    private fb: FormBuilder,
    private apiService: APIService,
    private uploadFile: FileUploadService,
    public notifier: NotifierService,
    private route: ActivatedRoute
  ) {
    this.config = this.route.snapshot.data['config'];
  }

  headers: any = [
    {
      id: 'first_name',
      name: 'First Name',
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'primary_ph_no',
      name: 'Primary Contact Number',
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'cur_addr_state',
      name: 'Current Address',
      sort: { sort: true, direction: 'Asc' },
    },
    {
      id: 'client_name',
      name: 'Client',
      sort: { sort: true, direction: 'Asc' },
    },
    {
      'id': 'compliance',
      'name': 'Compliance',
      'sort': { 'sort': true, 'direction': 'Asc' },
    }
  ];


  ngOnInit() {
    this.fetchDrivers();
    this.clientDetails();

    this.myForm = this.fb.group({
      id: '',
      first_name: ['', [Validators.required, this.alphabetOnlyValidator()]],
      last_name: ['', [Validators.required, this.alphabetOnlyValidator()]],
      dob: ['', Validators.required],
      age: ['', Validators.required],
      gender: ['', Validators.required],
      marital_status: ['', Validators.required],
      joining_date: ['', Validators.required],
      prim_cont_num: ['', [Validators.required, Validators.pattern('^[0-9]{10,13}$')]],
      sec_cont_num: ['', [Validators.required, Validators.pattern('^[0-9]{10,13}$')]],

      cur_addr_state: ['', Validators.required],
      cur_addr_city: ['', Validators.required],
      cur_addr_district: ['', Validators.required],
      cur_addr_street: ['', Validators.required],
      cur_addr_house_no: ['', Validators.required],
      cur_addr_pin_code: ['', [Validators.required, this.digitsOnlyValidator()]],

      per_addr_state: ['', Validators.required],
      per_addr_city: ['', Validators.required],
      per_addr_district: ['', Validators.required],
      per_addr_street: ['', Validators.required],
      per_addr_house_no: ['', Validators.required],
      per_addr_pin_code: ['', [Validators.required, this.digitsOnlyValidator()]],

      clientName: ['', Validators.required],
      lang_known: this.fb.array([], this.atLeastOneCheckboxSelectedValidator()),

      tot_exp: ['', Validators.required],
      exp_sec: ['', Validators.required],
      adhar_num: ['', [Validators.required, Validators.pattern(/^\d{12}$/)]],
      pan_num: ['', [Validators.pattern(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/)]],

      bank_account_details: this.fb.array([this.createBankAccount()]),
      back_cert_num: [''],
      med_date_of_issue: [''],
      med_date_of_expiry: [''],
      esi_num: [''],
      pf_num: [''],
      compliance: [false]


    });
    this.addCheckboxes();

    this.myForm.disable()
  }

  atLeastOneCheckboxSelectedValidator(): ValidatorFn {
    return (formArray: AbstractControl): { [key: string]: any } | null => {
      const controls = (formArray as any).controls;
      const isAtLeastOneSelected = controls.some((control: AbstractControl) => control.value);
      return isAtLeastOneSelected ? null : { atLeastOneRequired: true };
    };
  }
  async onCmpCodeChange(event: any) {
    const value = event.target?.value;
    if (value) {
      this.selectedCmpCode = value; // Add selected value to cmpCodeSet
      this.clientIds = [];
      for (const array of this.clientData) {
        if (array[0] === this.selectedCmpCode) {
          this.clientIds.push(array[2]);
        }
      }
      console.log(this.selectedCmpCode);
      console.log(this.clientIds);
    }
  }

  alphabetOnlyValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      const alphabetRegex = /^[A-Za-z\s]+$/;
      if (value && !alphabetRegex.test(value)) {
        return { alphabetOnly: true };
      }
      return null;
    };
  }

  digitsOnlyValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const valid = /^\d+$/.test(control.value);
      return valid ? null : { 'digitsOnly': { value: control.value } };
    };
  }
  reset(element: any, filename: string) {
    element.value = '';
    this.updated[filename] = undefined;
  }


  async clientDetails() {
    // this.notifier.loading(true);

    let data: any = {
      action: 'fetch_client_details',
    };
    let header = {
      Authorization: localStorage.getItem('t'),
      // 'X-Api-Key': localStorage.getItem('clientid'),
    };

    //https://api.swayam.cloud/v3/admin/users/fetchaccountgroupsv2
    let apiURL = `${this.config.apiURL}/admin/drivers`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.s == '1' || result.status == '1') {
      // console.log(result);

      this.clientData = result.data;
      console.log('clients data', this.clientData);
      // const uniqueFirstColumnValues = new Set<string>();
      // for (const array of this.clientData) {
      //   this.uniqueCmpCodes.add(array[0]);
      // }
      // console.log(this.uniqueCmpCodes);
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
    // this.notifier.loading(false);
  }

  async get_excel_url() {
    this.notifier.loading(true);

    let data: any = {
      action: 'get_presign_url_of_excel_for_attenders',
    };
    let header = {
      Authorization: localStorage.getItem('t'),
      // 'X-Api-Key': localStorage.getItem('clientid'),
    };

    //https://api.swayam.cloud/v3/admin/users/fetchaccountgroupsv2
    let apiURL = `${this.config.apiURL}/admin/drivers`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.s == '1' || result.status == '1') {
      // console.log(result);

      let pre_url = result.data;
      console.log('Presign Url for', pre_url);
      let a = document.createElement('a');
      a.href = pre_url;
      a.download = 'true';
      a.setAttribute('target', '_blank');
      a.click();
      // this.notifier.alert('Success', '', result.data, 'success', 5000);
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
    this.notifier.loading(false);
  }

  openFileExplorer() {
    this.fileInput.nativeElement.click();
  }


  async fileSelected(event: any) {
    console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@')
    const selected_File: File = event.target.files[0];
    console.log('selected File is ', selected_File);
    this.bulkFileUpload = true
    if (selected_File) {
      console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
      // Get the file name and extension
      const fileName: string = selected_File.name;
      const fileExtension: string = fileName.split('.').pop() || '';

      // Check if the file extension is valid
      if (fileExtension === 'xlsx' || fileExtension === 'xls') {
        console.log('Valid Excel file selected');
        // Read the Excel file
        const reader = new FileReader();
        reader.onload = async (e: ProgressEvent<FileReader>) => {
          const data_of_file: any | ArrayBuffer | null = e.target?.result;
          if (typeof data_of_file === 'string') {
            // Parse the Excel file content
            const workbook = XLSX.read(data_of_file, { type: 'binary' });
            // Assuming there's only one sheet, you can change this according to your requirement
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];
            // Convert the sheet to JSON
            const jsonData = XLSX.utils.sheet_to_json(worksheet);
            // Now you can validate the content as per your requirements
            console.log('Excel content:', jsonData);

            await this.transformData(jsonData);
            // console.log('transformed data:', this.transformedData);
            // this.clearFileInput()
            // let isnotValid = this.validateData(jsonData)
            // console.log('isValid', isnotValid)
            // if (isnotValid.length !== 0) {
            //   this.notifier.alert('Info', '', 'Please provide all Fields and with correct Information', 'info', 5000);
            //   return
            // }
            // else {
            //   console.log('')
            //   this.bulkUpload(jsonData)
            // }
            // Here you can  jsonData
            // For example, check if certain columns or values are present
          } else {
            console.error('Failed to read the file.');
          }
        };
        reader.readAsBinaryString(selected_File);
      } else {
        console.error('Invalid file format. Please select an Excel file.');
        this.notifier.alert('Info', '', 'Please Select valid Excel file', 'info', 5000);
        // this.clearFileInput()
      }
    } else {
      console.error('No file selected.');
    }
  }

  // clearFileInput() {
  //   this.fileInput.nativeElement.value = '';
  // }


  createBankAccount() {
    return this.fb.group({
      acc_no: [''],
      ifsc_code: [''],
      bank_name: [''],
      bank_address: [''],
      name: [''],
    });
  }

  get bankAccountDetailsArray(): FormArray {
    return this.myForm.get('bank_account_details') as FormArray;
  }

  onAddBank: boolean = false;
  addBankAccount(): void {
    this.onAddBank = true;
    this.bankAccountDetailsArray.push(this.createBankAccount());
    console.log('after adding a bank account', this.bankAccountDetailsArray);
  }

  removeBankAccount(index: number): void {
    this.bankAccountDetailsArray.removeAt(index);
  }

  get licenseArray() {
    return this.myForm.controls.type_of_lic as FormArray;
  }

  addLicenseTypeControls() {
    ['LMV', 'HMV', 'Other'].forEach(() => this.licenseArray.push(this.fb.control(false)));
  }
  get langKnownArray() {
    return this.myForm.controls.lang_known as FormArray;
  }

  private addCheckboxes() {
    ['English', 'Hindi', 'Telugu', 'Other'].forEach(() =>
      this.langKnownArray.push(this.fb.control(false))
    );
  }


  toggleToHome() {
    this.bulkFileUpload = false;
  }

  presenceList(list: string[], targetValues: string[]): boolean[] {
    const presence: boolean[] = [];

    // Iterate through the target values
    for (const value of targetValues) {
      presence.push(list.includes(value));
    }

    return presence;
  }

  excelSerialToDate(serial: number): string {
    // Excel's epoch starts on December 30, 1899
    const excelEpoch = new Date('1899-12-30');

    // Calculate the date by adding the number of days to the Excel epoch
    const date = new Date(excelEpoch.getTime() + serial * 24 * 60 * 60 * 1000);

    // Format the date as DD-MM-YYYY
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
  }

  getCellValue(header: any, item: any): string {
    if (header.id === 'compliance') {
      return item.background_certificate_number && item.background_certificate_s3_path != "None" && item.med_fit_cert_issuing_date && item.med_fit_cert_expiry_date && item.med_fit_cert_s3_path != "None" && item.esi_number && item.pf_number ? 'Compliant' : 'Non Compliant';
    }
    return item[header.id];
  }

  async transformData(jsonData: any) {
    // Extract values from the jsonData
    console.log('Inside transformData fn')
    console.log('this.bulkFileUpload , this.isEditMode', `${this.bulkFileUpload}  ${this.isEditMode}`)
    this.transformedData = []
    jsonData.forEach(async (object: any, index: number) => {
      // Access properties of each object
      console.log(`Object ${index + 1}:`, object);
      // console.log('Property 1:', object['Company Code | Client ID'].split(' | ')[0]);
      // console.log(object)
      console.log("Languages Known", object["Languages Known"])
      let dob = this.excelSerialToDate(object['Date Of Birth*'])
      let license_expiry_date = this.excelSerialToDate(object["License Expiry Date*"])
      let med_fit_cert_issue_date = object['Medical Certificate Date of Issue'] ? this.excelSerialToDate(object['Medical Certificate Date of Issue']) : '';
      let med_fit_cert_expiry_date = object['Medical Certificate Date of Expiry'] ? this.excelSerialToDate(object['Medical Certificate Date of Expiry']) : '';
      let joining_date = this.excelSerialToDate(object['Date of Joining*'])
      let age = this.convertEpochToAge(object['Date Of Birth*'])
      const uniqueId = uuidv4();
      console.log(uniqueId);
      this.transformedData.push({
        "id": uniqueId,
        "action": "add_drivers_data",
        "first_name": object['First Name*'].toString(),
        "last_name": object['Last Name*'].toString(),
        "date_of_birth": dob.toString(),
        "age": age.toString(),
        "gender": object['Gender*'].toString(),
        "marital_status": object["Marital Status*"].toString(),
        "join_date": joining_date.toString(),
        "primary_ph_no": object['Primary Contact Number*'].toString(),
        "secondary_ph_no": object['Secondary Contact Number*'].toString(),

        "cur_addr_state": object["Current Address - State*"].toString(),
        "cur_addr_district": object["Current Address - District*"].toString(),
        "cur_addr_city": object["Current Address - City*"].toString(),
        "cur_addr_street": object["Current Address - Street*"].toString(),
        "cur_addr_house_no": object["Current Address - House Number*"].toString(),
        "cur_addr_pin_code": object["Current Address - Pin Code*"].toString(),

        "per_addr_state": object["Permanent Address - State*"].toString(),
        "per_addr_district": object["Permanent Address - District*"].toString(),
        'per_addr_city': object["Permanent Address - City*"].toString(),
        "per_addr_street": object["Permanent Address - Street*"].toString(),
        "per_addr_house_no": object["Permanent Address - House Number*"].toString(),
        'per_addr_pin_code': object["Permanent Address - Pin Code*"].toString(),

        "client_name": object["Client/Site Details*"].toString(),
        "languages_known": object["Languages Known*"].toString(),

        "total_experience": object["Year of Experience*"].toString(),
        "exp_sector": object["Sector of Experience*"].toString(),
        "driving_license_number": '',
        "driving_license_s3_path": '',

        "license_expiry_date": '',
        "type_of_license": '',
        "aadhar_number": object["Aadhar Number*"].toString(),
        "aadhar_s3_path": '',
        "pan_number": object["PAN Number*"] ? object["PAN Number*"].toString() : '',
        "pan_s3_path": '',

        "background_certificate_number": object["Background Certificate Number"] ? object["Background Certificate Number"].toString() : '',
        "background_certificate_s3_path": '',
        "med_fit_cert_issuing_date": med_fit_cert_issue_date ? med_fit_cert_issue_date.toString() : '',
        "med_fit_cert_expiry_date": med_fit_cert_expiry_date ? med_fit_cert_expiry_date.toString() : '',
        "med_fit_cert_s3_path": '',

        "bank_account": [{
          "acc_no": object["Bank Account Number"] ? object["Bank Account Number"].toString() : '',
          "ifsc_code": object["IFSC Code"] ? object["IFSC Code"].toString() : '',
          "bank_name": object["Bank Name"] ? object["Bank Name"].toString() : '',
          "bank_address": object["Bank Address"] ? object["Bank Address"].toString() : '',
          "name": object["Name as per Bank Account"] ? object["Name as per Bank Account"].toString() : '',
          "bank_passbook_s3_path": ''
        }],

        "esi_number": object['ESI Number'] ? object['ESI Number'].toString() : '',
        "pf_number": object['PF Number'] ? object['PF Number'].toString() : '',
      })
    });
    console.log('this.transformedData', this.transformedData)
  }


  async fetch(file_paths: Array<any>) {
    console.log("inside fetch file fn")
    console.log("file paths", file_paths);

    let data: any = {
      action: 'fetch_file',
      // company_code: this.myForm.get('companyCode').value,
      file_names: file_paths
    };
    let header = {
      Authorization: localStorage.getItem('t'),
      // 'X-Api-Key': localStorage.getItem('clientid'),
    };

    let apiURL = `${this.config.apiURL}/admin/drivers`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.s == '1' || result.status == '1') {
      // console.log(result);
      this.fetchUrls = result.data;
      this.fileNames = []
      this.fileNames = Object.keys(this.fetchUrls);

      // Now keysArray contains only the keys of this.fetchUrls
      console.log('keys: ', this.fileNames);

      // this.fileNames = this.fileNames.filter((element: string) => element !== "None");


      this.matchingUrls = {};

      for (const key in this.fetchUrls) {
        if (Object.prototype.hasOwnProperty.call(this.fetchUrls, key)) {
          if (key.includes('dl_doc')) {
            this.matchingUrls['dl_doc'] = this.fetchUrls[key];
          } else if (key.includes('aadhar_doc')) {
            this.matchingUrls['aadhar_doc'] = this.fetchUrls[key];
          } else if (key.includes('pan_doc')) {
            this.matchingUrls['pan_doc'] = this.fetchUrls[key];
          } else if (key.includes('passbook_doc')) {
            this.matchingUrls['passbook_doc'] = this.fetchUrls[key];
          } else if (key.includes('bg_cert_doc')) {
            this.matchingUrls['bg_cert_doc'] = this.fetchUrls[key];
          } else if (key.includes('fitness_cert_doc')) {
            this.matchingUrls['fitness_cert_doc'] = this.fetchUrls[key];
          }
        }
      }

      console.log('Matching URL:', this.matchingUrls);

      const order = [
        'dl_doc',
        'aadhar_doc',
        'pan_doc',
        'passbook_doc',
        'bg_cert_doc',
        'fitness_cert_doc'
      ];

      // Create an array of objects based on the defined order
      this.orderedUrls = order.map(key => ({
        [key]: this.matchingUrls[key]
      }));

      const extractIdentifier = (fileName: string): string | null => {
        const match = fileName.match(/.*?_([a-z_]+)\./);
        return match ? match[1] : null;
      };

      // Reordering the fileNames array based on the order array
      // const orderedFileNames = order.map(identifier => {
      //   const found = this.fileNames.find((fileName: string) => extractIdentifier(fileName) === identifier);
      //   return found !== undefined ? found : undefined;
      // });

      // Ensure all files from fileNames are included, inserting null for those not in the order
      this.fileNames = order.map(identifier => {
        const file = this.fileNames.find((fileName: string) => extractIdentifier(fileName) === identifier);
        return file !== 'None' ? file : undefined;
      });

      console.log('Ordered URLs:', this.orderedUrls);
      console.log("this.fileNames", this.fileNames);

    }


    else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }

  }

  index: number = 0;

  async populateForm(selectedCompany: any) {
    if (this.isEditMode && this.bulkFileUpload) {
      console.log('inside the populate form');
      console.log(selectedCompany);
      // console.log(selectedCompany.company_details);
      this.index = this.transformedData.indexOf(selectedCompany);
      console.log('this.index', this.index);

      this.fileInfoArray = []
      this.fileInfoArray.push(this.transformedData[this.index].driving_license_s3_path ? this.transformedData[this.index].driving_license_s3_path : '')
      this.fileInfoArray.push(this.transformedData[this.index].aadhar_s3_path ? this.transformedData[this.index].aadhar_s3_path : '')
      this.fileInfoArray.push(this.transformedData[this.index].pan_s3_path ? this.transformedData[this.index].pan_s3_path : '')
      this.fileInfoArray.push(this.transformedData[this.index].bank_passbook_s3_path ? this.transformedData[this.index].bank_passbook_s3_path : '')
      this.fileInfoArray.push(this.transformedData[this.index].background_certificate_s3_path ? this.transformedData[this.index].background_certificate_s3_path : '')
      this.fileInfoArray.push(this.transformedData[this.index].med_cert_s3_path ? this.transformedData[this.index].med_cert_s3_path : '')

      this.fileNames = []
      this.fileNames.push(this.transformedData[this.index].driving_license_s3_path ? this.transformedData[this.index].driving_license_s3_path : '')
      this.fileNames.push(this.transformedData[this.index].aadhar_s3_path ? this.transformedData[this.index].aadhar_s3_path : '')
      this.fileNames.push(this.transformedData[this.index].pan_s3_path ? this.transformedData[this.index].pan_s3_path : '')
      this.fileNames.push(this.transformedData[this.index].bank_passbook_s3_path ? this.transformedData[this.index].bank_passbook_s3_path : '')
      this.fileNames.push(this.transformedData[this.index].background_certificate_s3_path ? this.transformedData[this.index].background_certificate_s3_path : '')
      this.fileNames.push(this.transformedData[this.index].med_cert_s3_path ? this.transformedData[this.index].med_cert_s3_path : '')
      console.log("this.fileInfoArray", this.fileInfoArray);

      const str_chkbox: string = selectedCompany.languages_known;
      const delimiter: string = ', '; // Delimiter can be any character or string
      const list_chkbox: string[] = str_chkbox.split(delimiter);
      const presence: boolean[] = this.presenceList(list_chkbox, ['Hindi', 'English', 'Telugu', 'Other']);

      const str_chkbox_for_lic_type: string = selectedCompany.type_of_license;
      const list_chkbox_for_lic_type: string[] = str_chkbox_for_lic_type.split(delimiter);
      const presence_for_lic_type: boolean[] = this.presenceList(list_chkbox_for_lic_type, ['LMV', 'HMV', 'Other']);

      console.log('lang known:', presence)
      console.log("updated['dl_doc']", this.updated['dl_doc'])
      this.myForm.patchValue({

        id: selectedCompany.id,
        first_name: selectedCompany.first_name,
        last_name: selectedCompany.last_name,
        dob: selectedCompany.date_of_birth,
        age: selectedCompany.age,
        gender: selectedCompany.gender,
        marital_status: selectedCompany.marital_status,
        joining_date: selectedCompany.join_date,

        prim_cont_num: selectedCompany.primary_ph_no,
        sec_cont_num: selectedCompany.secondary_ph_no,

        cur_addr_state: selectedCompany.cur_addr_state,
        cur_addr_district: selectedCompany.cur_addr_district,
        cur_addr_city: selectedCompany.cur_addr_city,
        cur_addr_street: selectedCompany.cur_addr_street,
        cur_addr_house_no: selectedCompany.cur_addr_house_no,
        cur_addr_pin_code: selectedCompany.cur_addr_pin_code,

        per_addr_state: selectedCompany.per_addr_state,
        per_addr_district: selectedCompany.per_addr_district,
        per_addr_city: selectedCompany.per_addr_city,
        per_addr_street: selectedCompany.per_addr_street,
        per_addr_house_no: selectedCompany.per_addr_house_no,
        per_addr_pin_code: selectedCompany.per_addr_pin_code,

        clientName: selectedCompany.client_name,
        lang_known: presence,
        tot_exp: selectedCompany.total_experience,
        exp_sec: selectedCompany.exp_sector,

        driv_lic_num: selectedCompany.driving_license_number,
        lic_exp_date: selectedCompany.license_expiry_date,
        type_of_lic: presence_for_lic_type,

        adhar_num: selectedCompany.aadhar_number,
        pan_num: selectedCompany.pan_number,

        back_cert_num: selectedCompany.background_certificate_number,
        // pan_num: selectedCompany.pan_number,


        med_date_of_issue: selectedCompany.med_fit_cert_issuing_date,
        med_date_of_expiry: selectedCompany.med_fit_cert_expiry_date,

        esi_num: selectedCompany.esi_number,
        pf_num: selectedCompany.pf_number

      });
      const bankFormArray = this.myForm.get('bank_account_details') as FormArray;
      bankFormArray.clear();

      if (selectedCompany.bank_account && selectedCompany.bank_account.length > 0) {
        const bankFormArray = this.myForm.get('bank_account_details') as FormArray;
        selectedCompany.bank_account.forEach((bank: any) => {
          const bankGroup = this.createBankAccount();
          bankGroup.patchValue({
            acc_no: bank.acc_no,
            ifsc_code: bank.ifsc_code,
            bank_name: bank.bank_name,
            bank_address: bank.bank_address,
            name: bank.name,
          });
          bankFormArray.push(bankGroup);
        });
      }
    } else {
      console.log('selectedCompany', selectedCompany)
      const str_chkbox: string = selectedCompany.languages_known;
      const delimiter: string = ', '; // Delimiter can be any character or string
      const list_chkbox: string[] = str_chkbox.split(delimiter);
      const presence: boolean[] = this.presenceList(list_chkbox, ['Hindi', 'English', 'Telugu', 'Other']);
      console.log('lang known:', presence)

      this.sendUrls = []
      this.sendUrls.push(selectedCompany.aadhar_s3_path)
      this.sendUrls.push(selectedCompany.pan_s3_path)
      this.sendUrls.push(selectedCompany.bank_account[0]?.bank_passbook_s3_path ? selectedCompany.bank_account[0]?.bank_passbook_s3_path : 'None')
      this.sendUrls.push(selectedCompany.background_certificate_s3_path)
      this.sendUrls.push(selectedCompany.med_fit_cert_s3_path)
      console.log("this.sendUrls", this.sendUrls)
      // console.log("URL:", this.fetchUrls);
      await this.fetch(this.sendUrls);
      console.log("Send urls", this.sendUrls)
      this.myForm.patchValue({
        id: selectedCompany.id,
        first_name: selectedCompany.first_name,
        last_name: selectedCompany.last_name,
        dob: selectedCompany.date_of_birth,
        age: selectedCompany.age,
        gender: selectedCompany.gender,
        marital_status: selectedCompany.marital_status,
        joining_date: selectedCompany.join_date,

        prim_cont_num: selectedCompany.primary_ph_no,
        sec_cont_num: selectedCompany.secondary_ph_no,

        cur_addr_state: selectedCompany.cur_addr_state,
        cur_addr_district: selectedCompany.cur_addr_district,
        cur_addr_city: selectedCompany.cur_addr_city,
        cur_addr_street: selectedCompany.cur_addr_street,
        cur_addr_house_no: selectedCompany.cur_addr_house_no,
        cur_addr_pin_code: selectedCompany.cur_addr_pin_code,

        per_addr_state: selectedCompany.per_addr_state,
        per_addr_district: selectedCompany.per_addr_district,
        per_addr_city: selectedCompany.per_addr_city,
        per_addr_street: selectedCompany.per_addr_street,
        per_addr_house_no: selectedCompany.per_addr_house_no,
        per_addr_pin_code: selectedCompany.per_addr_pin_code,

        clientName: selectedCompany.client_name,
        lang_known: presence,
        tot_exp: selectedCompany.total_experience,
        exp_sec: selectedCompany.exp_sector,

        adhar_num: selectedCompany.aadhar_number,
        pan_num: selectedCompany.pan_number,

        back_cert_num: selectedCompany.background_certificate_number,
        // pan_num: selectedCompany.pan_number,


        med_date_of_issue: selectedCompany.med_fit_cert_issuing_date,
        med_date_of_expiry: selectedCompany.med_fit_cert_expiry_date,

        esi_num: selectedCompany.esi_number,
        pf_num: selectedCompany.pf_number,
        compliance: selectedCompany.compliance === "1" ? true : false

      });
      this.bankAccountDetailsArray.clear();

      // Check if selectedCompany has bank accounts
      if (selectedCompany.bank_account && selectedCompany.bank_account.length > 0) {
        // Iterate over each bank account and patch values
        selectedCompany.bank_account.forEach((bank: any) => {
          const bankGroup = this.createBankAccount();
          bankGroup.patchValue({
            acc_no: bank.acc_no,
            ifsc_code: bank.ifsc_code,
            bank_name: bank.bank_name,
            bank_address: bank.bank_address,
            name: bank.name,
          });
          this.bankAccountDetailsArray.push(bankGroup);
        });
      }
    }

  }


  validateMandatoryFiles(fileNames: (string | null)[], mandatoryFiles: number[]): boolean {
    for (const index of mandatoryFiles) {
      if (!fileNames[index]) {
        return false;
      }
    }
    return true;
  }

  async updateDriver(files?: any) {
    this.notifier.loading(true);
    const selectedOptions = this.myForm.value.lang_known
      .map((checked: boolean, index: number) => checked ? ['Hindi', 'English', 'Telugu', 'Other'][index] : null)
      .filter((value: string | null) => value !== null);
    let concatenatedOptions: string = selectedOptions.join(', ');
    console.log(concatenatedOptions);

    console.log('this.fileNames', this.fileNames);

    let mandatoryFiles = [1];

    if ((this.validateMandatoryFiles(this.fileNames, mandatoryFiles) || this.updated['aadhar_doc']) && this.myForm.valid) {
      let payload_files: any = {};
      if (files) {
        files.forEach((file: any) => {
          // Iterate over each field in the form
          payload_files[file.fieldName] = file.formcontro_name;
        });
      }
      // console.log('Inside the api call', formValues);
      const formValues = this.myForm.value;
      let dl: any;
      let aadhar: any;
      let pan: any;
      let passbook: any;
      let bg: any;
      let fitness: any;
      if (this.isEditMode) {
        console.log("payload files", payload_files);
        console.log(Object.keys(this.updated).length);
        // if (Object.keys(this.updated).length === 0) {
        dl = this.fileNames[0];
        aadhar = this.fileNames[1];
        pan = this.fileNames[2];
        passbook = this.fileNames[3];
        bg = this.fileNames[4];
        fitness = this.fileNames[5];
        console.log(dl, aadhar, pan, passbook, bg, fitness);
        // } 
        if (Object.keys(this.updated).length !== 0) {
          const updatedKeys = Object.keys(this.updated);
          console.log("Updated files", updatedKeys);
          updatedKeys.forEach(key => {
            // if (payload_files.hasOwnProperty(key)) {
            switch (key) {
              case 'dl_doc':
                dl = payload_files['dlUpload'];
                break;
              case 'aadhar_doc':
                aadhar = payload_files['aadharUpload'];
                break;
              case 'pan_doc':
                pan = payload_files['panUpload'];
                break;
              case 'passbook_doc':
                passbook = payload_files['passbookUpload'];
                break;
              case 'bg_cert_doc':
                bg = payload_files['bgCertUpload'];
                break;
              case 'fitness_cert_doc':
                fitness = payload_files['fitnessCertUpload'];
                break;
            }
            // }
          });

          const remainingKeys = Object.keys(this.fileNames).filter(key => !payload_files.hasOwnProperty(key));

          remainingKeys.forEach((key, index) => {
            switch (key) {
              case 'dl':
                dl = this.fileNames[0];
                break;
              case 'aadhar':
                aadhar = this.fileNames[1];
                break;
              case 'pan':
                pan = this.fileNames[2];
                break;
              case 'passbook':
                passbook = this.fileNames[3];
                break;
              case 'bg':
                bg = this.fileNames[4];
                break;
              case 'fitness':
                fitness = this.fileNames[5];
                break;
            }
          });
        }
      }

      let data: any = [{
        "action": "update_drivers_data",
        "id": formValues.id,
        "first_name": formValues.first_name,
        "last_name": formValues.last_name,
        "date_of_birth": formValues.dob,
        "age": formValues.age,
        "gender": formValues.gender,
        "marital_status": formValues.marital_status,
        "join_date": formValues.joining_date,
        "primary_ph_no": formValues.prim_cont_num,
        "secondary_ph_no": formValues.sec_cont_num,

        "cur_addr_state": formValues.cur_addr_state,
        "cur_addr_district": formValues.cur_addr_district,
        "cur_addr_city": formValues.cur_addr_city,
        "cur_addr_street": formValues.cur_addr_street,
        "cur_addr_house_no": formValues.cur_addr_house_no,
        "cur_addr_pin_code": formValues.cur_addr_pin_code,

        "per_addr_state": formValues.per_addr_state,
        "per_addr_district": formValues.per_addr_district,
        'per_addr_city': formValues.per_addr_city,
        "per_addr_street": formValues.per_addr_street,
        "per_addr_house_no": formValues.per_addr_house_no,
        'per_addr_pin_code': formValues.per_addr_pin_code,

        "client_name": formValues.clientName,
        "languages_known": concatenatedOptions,

        "total_experience": formValues.tot_exp,
        "exp_sector": formValues.exp_sec,

        "aadhar_number": formValues.adhar_num,
        "aadhar_s3_path": aadhar,
        "pan_number": formValues.pan_num,
        "pan_s3_path": pan ? pan : 'None',

        "background_certificate_number": formValues.back_cert_num,
        "background_certificate_s3_path": bg ? bg : 'None',
        "med_fit_cert_issuing_date": formValues.med_date_of_issue,
        "med_fit_cert_expiry_date": formValues.med_date_of_expiry,
        "med_fit_cert_s3_path": fitness ? fitness : 'None',
        "esi_number": formValues.esi_num,
        "pf_number": formValues.pf_num,
        "type_of_employee": 'attender',
        "compliance": formValues.compliance,

        "bank_account": formValues.bank_account_details.map((bank: any) => {
          return {
            acc_no: bank.acc_no,
            ifsc_code: bank.ifsc_code,
            bank_name: bank.bank_name,
            bank_address: bank.bank_address,
            name: bank.name,
            bank_passbook_s3_path: passbook ? passbook : 'None'
          };
        }),

      }]

      let header = {
        Authorization: localStorage.getItem('t'),
        // 'X-Api-Key': localStorage.getItem('clientid'),
      };

      //https://api.swayam.cloud/v3/admin/users/fetchaccountgroupsv2
      let apiURL = `${this.config.apiURL}/admin/drivers`;

      let result = await this.apiService.postDataPromis(apiURL, data, header);

      if (result.s == '1' || result.status == '1') {
        // console.log(result);
        this.updated = {};
        this.submitted = false;
        this.isEditMode = false;
        this.addcom = false;
        this.fileInfoArray = [];
        await this.fetchDrivers();

        this.clearForm();
        this.showPopup(
          'Attender Updated',
          'The Attender has been successfully updated.'
        );

      } else {
        this.notifier.alert('Info', '', result.error, 'info', 5000);
      }

    }
    else {
      this.notifier.alert('Info', '', 'Please fill all the mandatory fields', 'info', 5000);
    }
    this.notifier.loading(false);

  }


  clearForm(): void {
    this.myForm.reset();
    this.addcom = false;
    this.isEditMode = false;
    // this.submitted = false;
    this.submitted = false;
    this.fileInfoArray = [];
    this.orderedUrls = [];
    this.updateForm = false;
    this.myForm.enable()
  }

  toggleSidenav() {
    this.isOpen = !this.isOpen;
  }

  pathToFile: any;
  @ViewChild('aadharUploadIn', { static: false }) aadharUploadIn!: ElementRef;
  @ViewChild('panUploadIn', { static: false }) panUploadIn!: ElementRef;
  @ViewChild('bgCertUploadIn', { static: false }) bgCertUploadIn!: ElementRef;
  @ViewChild('fitnessCertUploadIn', { static: false }) fitnessCertUploadIn!: ElementRef;
  @ViewChild('passbookUploadIn', { static: false }) passbookUploadIn!: ElementRef;


  previewDocument(input: any, doc: string, docName?: any) {
    const file = input?.files?.[0];
    if (file) {
      const fileType = file.name.split('.').pop()?.toLowerCase() || '';
      if (fileType === 'pdf' || this.isImageFile(fileType)) {
        const reader = new FileReader();
        reader.onload = () => {
          const blob = new Blob([reader.result as ArrayBuffer], {
            type: file.type,
          });
          const url = URL.createObjectURL(blob);
          const viewer = document.createElement('iframe');
          viewer.src = url;
          viewer.style.width = '100%';
          viewer.style.height = '100%';
          viewer.style.border = 'none';
          const newWindow = window.open();
          if (newWindow) {
            newWindow.document.body.appendChild(viewer);
            newWindow.document.title = file.name;
            newWindow.focus();
          } else {
            console.log('Failed to open preview window');
          }
        };
        reader.readAsArrayBuffer(file);
      } else {
        console.log('Unsupported file type for preview');
      }
    } else if (this.matchingUrls && this.matchingUrls[doc] && (this.isEditMode || !this.addcom)) {
      // If no file is uploaded, show file from URL
      const url = this.matchingUrls[doc];
      const viewer = document.createElement('iframe');
      viewer.style.width = '100%';
      viewer.style.height = '100%';
      viewer.style.border = 'none';
      const newWindow = window.open();
      if (newWindow) {
        newWindow.document.title = doc;
        viewer.src = url;
        newWindow.document.body.appendChild(viewer.cloneNode(true));
        newWindow.focus();
      } else {
        console.log('Failed to open preview window');
      }
    } else if (this.bulkFileUpload && this.isEditMode) {
      console.log("doc", doc)
      if (docName == 'bank_passbook_s3_path') {
        this.pathToFile = this.transformedData[this.index].bank_account[0].bank_passbook_s3_path
      }
      else {
        let docN = docName.toString()
        this.pathToFile = this.transformedData[this.index][docN]
      }
      const fileType = this.pathToFile.file.name.split('.').pop()?.toLowerCase() || '';
      if (fileType === 'pdf' || this.isImageFile(fileType)) {
        const reader = new FileReader();
        reader.onload = () => {
          const blob = new Blob([reader.result as ArrayBuffer], {
            type: this.pathToFile.file.type,
          });
          const url = URL.createObjectURL(blob);
          const viewer = document.createElement('iframe');
          viewer.src = url;
          viewer.style.width = '100%';
          viewer.style.height = '100%';
          viewer.style.border = 'none';
          const newWindow = window.open();
          if (newWindow) {
            newWindow.document.body.appendChild(viewer);
            newWindow.document.title = this.pathToFile.file.name;
            newWindow.focus();
          } else {
            console.log('Failed to open preview window');
          }
        };
        reader.readAsArrayBuffer(this.pathToFile.file);
      } else {
        console.log('Unsupported file type for preview');
      }
    }
    else {
      console.log('No file selected and no URL available for preview');
    }
  }

  deleteDocumentForBulkUpload(updatedObject: any, filePath: any) {
    this.updated[updatedObject] = false;
    filePath = '';
  }

  fileInfoArray: any[] = [];

  // filesArray: File[] = [];
  updateForm: boolean = false;
  progressValue: number = 0;
  progress: any = {};

  onFileSelected(fieldName: string, filename: string, event: any, progress_state?: boolean, tempRefVar?: any) {
    console.log('Field Name:', fieldName);
    this.updated[filename] = true;
    this.progress[filename] = true;
    console.log('Event:', event);
    const file = event.target.files?.[0];
    console.log('Selected File:', file);
    if (file) {
      // Get the file name without extension
      const fileNameWithoutExtension = file.name
        .split('.')
        .slice(0, -1)
        .join('.');
      console.log('File Name:', fileNameWithoutExtension);

      // Get the file extension
      const fileExtension = file.name.split('.').pop()?.toLowerCase() || '';
      console.log('File Extension:', fileExtension);

      // Check file size and type
      if (file.size > 20 * 1024 * 1024) {
        // 20MB limit
        this.documentInputErrors[fieldName] =
          'File size exceeds the limit (20MB)';
        tempRefVar.value = '';
        this.updated[filename] = false;
      } else {
        if (
          !this.isImageFile(fileExtension) &&
          file.type !== 'application/pdf'
        ) {
          this.documentInputErrors[fieldName] =
            'Unsupported file type. Only PDF and images are allowed.';
          tempRefVar.value = '';
          this.updated[filename] = false;
        } else {
          this.documentInputErrors[fieldName] = null; // Clear error if file is valid

          this.progressValue = 0;
          const existingIndex = this.fileInfoArray.findIndex(
            (item) => item.fieldName === fieldName
          );
          if (existingIndex !== -1) {
            // Replace the existing item with the new one
            this.fileInfoArray[existingIndex] = {
              file_name: filename,
              ext: fileExtension,
              file: file,
              fieldName: fieldName,
            };
          } else {
            // Push the new item into the fileInfoArray
            this.fileInfoArray.push({
              file_name: filename,
              ext: fileExtension,
              file: file,
              fieldName: fieldName,
            });
          }
          this.progressValue = 45;

          // Set progressValue to 45 for 0.5 seconds
          setTimeout(() => {
            this.progressValue = 80; // Update to 80 after 0.5 seconds

            // Set progressValue to 80 for another 0.5 seconds
            setTimeout(() => {
              this.progressValue = 100; // Update to 100 after another 0.5 seconds

              // Set fileExist to false after reaching 100
              setTimeout(() => {
                console.log("fileExist is now false after 1.5 seconds");
                this.progress[filename] = false;
              }, 500); // Delay for 0.5 seconds to simulate reaching 100

            }, 500); // Delay for 0.5 seconds for value 80

          }, 500); // Delay for 0.5 seconds for value 45
          // Log the entire fileInfoArray
          console.log('File Info Array:', this.fileInfoArray);
        }
      }
    } else {
      this.documentInputErrors[fieldName] = 'No file selected';
      console.log('No file selected');
    }
  }

  dlClicked: boolean = false;

  setDlClicked() {
    this.dlClicked = false
  }

  clearArrayIfAllEmpty(arr: any): void {
    // Check if all elements in the array are empty strings
    console.log('arr', arr);

    const allEmpty = arr.every((element: string) =>
      element === '');

    // If all elements are empty strings, clear the array
    if (allEmpty) {
      arr.length = 0;
    }
  }

  onFileSelectedForBulkUp(fieldName: string, filename: string, event: any) {
    this.updated[filename] = true;
    this.dlClicked = true;
    console.log(this.updated);
    const file = event.target.files?.[0];
    console.log('Selected File:', file);
    if (file) {
      // Get the file name without extension
      const fileNameWithoutExtension = file.name.split('.').slice(0, -1).join('.');
      console.log('File Name:', fileNameWithoutExtension);

      // Get the file extension
      const fileExtension = file.name.split('.').pop()?.toLowerCase() || '';
      console.log('File Extension:', fileExtension);

      if (file.size > 20 * 1024 * 1024) {
        this.documentInputErrors[fieldName] = 'File size exceeds the limit (20MB)';
      } else {
        if (!this.isImageFile(fileExtension) && file.type !== 'application/pdf') {
          this.documentInputErrors[fieldName] = 'Unsupported file type. Only PDF and images are allowed.';
        } else {
          this.documentInputErrors[fieldName] = null;

          this.clearArrayIfAllEmpty(this.fileInfoArray);
          // Check if a file with the same filename is already selected
          const existingFileIndex = this.fileInfoArray.findIndex(info => {
            const comparisonResult = info?.file_name === filename;
            console.log(`Comparing ${info?.file_name} === ${filename}:`, comparisonResult);
            return comparisonResult;
          });
          console.log("existingFileIndex", existingFileIndex)
          if (existingFileIndex !== -1) {
            // Remove the existing file
            this.fileInfoArray.splice(existingFileIndex, 1);
          }

          // Add the new file to the fileInfoArray
          this.fileInfoArray.push({
            file_name: filename,
            ext: fileExtension,
            file: file,
            fieldName: fieldName,
          });

          // this.transformedData[this.index].fileNameAsPerObject = {
          //   file_name: filename,
          //   ext: fileExtension,
          //   file: file,
          //   fieldName: fieldName,
          // }
          // Log the entire fileInfoArray
          console.log('this.updated', this.updated);

          console.log('File Info Array:', this.fileInfoArray);
          console.log('this.transformData', this.transformedData);
          console.log(this.transformedData[this.index]?.aadhar_s3_path);

        }
      }
    } else {
      this.documentInputErrors[fieldName] = 'No file selected';
      console.log('No file selected');
    }
  }


  isImageFile(fileType: string): boolean {
    return ['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(fileType);
  }

  async onSaveAddBulkUpValues(formValues: any, files?: any) {

    if (this.myForm.valid && this.fileInfoArray.some((object) => this.fileInfoArray.some((object) => object.file_name === 'aadhar_doc'))) {
      let payload_files: any = {};
      this.updated = {};
      this.dlClicked = false;
      console.log("formValues.status", this.myForm.status)
      console.log("formValues", formValues)
      // if (files) {
      //   files.forEach((file: any) => {
      //     // Iterate over each field in the form
      //     payload_files[file.fieldName] = file.formcontro_name;
      //   });
      // }
      const selectedOptions = formValues.lang_known
        .map((checked: boolean, index: number) => checked ? ['Hindi', 'English', 'Telugu', 'Other'][index] : null)
        .filter((value: string | null) => value !== null);
      let concatenatedOptions: string = selectedOptions.join(', ');
      console.log(concatenatedOptions);


      // Iterate over the transformedData array
      let indexToDelete = this.transformedData.findIndex(obj => obj.id === formValues.id);
      // Delete element from the array
      if (indexToDelete !== -1) {
        this.transformedData.splice(indexToDelete, 1);
      }

      // console.log(dl_doc)
      console.log(indexToDelete)
      console.log("Before pushing elements to array", this.transformedData)
      this.transformedData.splice(indexToDelete, 0, {
        "id": formValues.id,
        "action": "add_drivers_data",
        "first_name": formValues.first_name,
        "last_name": formValues.last_name,
        "date_of_birth": formValues.dob,
        "age": formValues.age,
        "gender": formValues.gender,
        "marital_status": formValues.marital_status,
        "join_date": formValues.joining_date,
        "primary_ph_no": formValues.prim_cont_num,
        "secondary_ph_no": formValues.sec_cont_num,

        "cur_addr_state": formValues.cur_addr_state,
        "cur_addr_district": formValues.cur_addr_district,
        "cur_addr_city": formValues.cur_addr_city,
        "cur_addr_street": formValues.cur_addr_street,
        "cur_addr_house_no": formValues.cur_addr_house_no,
        "cur_addr_pin_code": formValues.cur_addr_pin_code,

        "per_addr_state": formValues.per_addr_state,
        "per_addr_district": formValues.per_addr_district,
        'per_addr_city': formValues.per_addr_city,
        "per_addr_street": formValues.per_addr_street,
        "per_addr_house_no": formValues.per_addr_house_no,
        'per_addr_pin_code': formValues.per_addr_pin_code,

        "client_name": formValues.clientName,
        "languages_known": concatenatedOptions,

        "total_experience": formValues.tot_exp,
        "exp_sector": formValues.exp_sec,
        "driving_license_number": "",
        "license_expiry_date": "",
        "driving_license_s3_path": "None",
        "type_of_license": "",
        "aadhar_number": formValues.adhar_num,
        "aadhar_s3_path": this.fileInfoArray.find((object: any) => object["file_name"] === "aadhar_doc"),
        "pan_number": formValues.pan_num,
        "pan_s3_path": this.fileInfoArray.find((object: any) => object["file_name"] === "pan_doc") ? this.fileInfoArray.find((object: any) => object["file_name"] === "pan_doc") : '',


        "background_certificate_number": formValues.back_cert_num,
        "background_certificate_s3_path": this.fileInfoArray.find((object: any) => object["file_name"] === "bg_cert_doc") ? this.fileInfoArray.find((object: any) => object["file_name"] === "bg_cert_doc") : '',
        "med_fit_cert_issuing_date": formValues.med_date_of_issue,
        "med_fit_cert_expiry_date": formValues.med_date_of_expiry,
        "med_fit_cert_s3_path": this.fileInfoArray.find((object: any) => object["file_name"] === "fitness_cert_doc") ? this.fileInfoArray.find((object: any) => object["file_name"] === "fitness_cert_doc") : '',

        "esi_number": formValues.esi_num ? formValues.esi_num : '',
        "pf_number": formValues.pf_num ? formValues.pf_num : '',
        "type_of_employee": 'attender',
        "bank_account": formValues.bank_account_details.map((bank: any) => {
          return {
            acc_no: bank.acc_no,
            ifsc_code: bank.ifsc_code,
            bank_name: bank.bank_name,
            bank_address: bank.bank_address,
            name: bank.name,
            bank_passbook_s3_path: this.fileInfoArray.find((object: any) => object["file_name"] === "passbook_doc") ? this.fileInfoArray.find((object: any) => object["file_name"] === "passbook_doc") : '',
          };
        }),
      });
      console.log("After pushing elements to array", this.transformedData)
      this.isEditMode = false
      this.addcom = false
      this.fileInfoArray = []
    } else {
      this.notifier.alert('Info', '', 'Please fill all the mandatory fields', 'info', 5000);
    }
  }

  async renameFilesWithS3Path(object: any, files: any) {
    console.log("object", object);
    console.log("files", files);
    let payload_files: any = {};

    files.forEach((file: any) => {
      // Iterate over each field in the form
      payload_files[file.fieldName] = file.formcontro_name;
    });

    object['driving_license_s3_path'] = "None";
    object['aadhar_s3_path'] = payload_files["aadharUpload"];
    object['pan_s3_path'] = payload_files["panUpload"] ? payload_files["panUpload"] : 'None';
    object['bank_account'][0]['bank_passbook_s3_path'] = payload_files["passbookUpload"] ? payload_files["passbookUpload"] : 'None';
    object['background_certificate_s3_path'] = payload_files["bgCertUpload"] ? payload_files["bgCertUpload"] : 'None';
    object['med_fit_cert_s3_path'] = payload_files["fitnessCertUpload"] ? payload_files["fitnessCertUpload"] : 'None';
  }

  async uploadDataPostFileUpload(object: any) {
    console.log("finally", this.transformedData)
    console.log('object', object);

    let data: any = [object];

    let header = {
      Authorization: localStorage.getItem('t'),
      // 'X-Api-Key': localStorage.getItem('clientid'),
    };

    //https://api.swayam.cloud/v3/admin/users/fetchaccountgroupsv2
    let apiURL = `${this.config.apiURL}/admin/drivers`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.s == '1' || result.status == '1') {

      this.showPopup(
        'Attender Added',
        'The Attender has been successfully added.'
      );
      this.submitted = false;
      this.isEditMode = false;
      this.addcom = false;
      this.clearForm();
      this.fileInfoArray = [];
      this.fetchDrivers();
      this.fileNames = [];
      this.updated = {};
      this.bulkFileUpload = false;
      this.notifier.alert('Success', '', result.data, 'success', 5000);

    } else {
      this.submitted = false;
      this.isEditMode = false;
      this.addcom = false;
      this.clearForm();
      this.fileInfoArray = [];
      this.fetchDrivers();
      this.fileNames = [];
      this.updated = {};
      this.bulkFileUpload = false;
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
  }


  hasRequiredDocuments(fileInfoArray: any) {
    const requiredDocs = ["aadhar_doc"];
    const presentDocs = fileInfoArray.map((file: any) => file.file_name);
    return requiredDocs.every(doc => presentDocs.includes(doc));
  }

  async onSubmit() {
    this.submitted = true;
    console.log("Submitte value", this.submitted);

    Object.values(this.myForm.controls).forEach((control: any) => {
      control.markAsTouched();
    });

    this.isSubmitted = true;
    console.log('Forms values', this.myForm.value);
    console.log('Form', this.myForm);
    console.log('Form Status', this.myForm.status);
    if (this.isEditMode && this.bulkFileUpload) {
      console.log("Saved")
      await this.onSaveAddBulkUpValues(this.myForm.value)
      console.log("form values:", this.myForm.value)
    }
    else if (this.isEditMode && !this.bulkFileUpload) {
      //on update if any new files are uploaded then this executes
      if (Object.values(this.updated).some(value => value === true)) {
        this.file_upload();
      }
      // on update this executes
      else {
        this.updateDriver();
      }
    }
    // on add this executes
    else {
      if (this.hasRequiredDocuments(this.fileInfoArray)) {
        console.log('this.hasRequiredDocuments', this.hasRequiredDocuments);
        this.file_upload();
      } else {
        this.notifier.alert('Info', '', 'Please fill all the mandatory fields', 'info', 5000);
      }
    }
  }

  async onSubmitAllDataBulkUp(files?: any) {
    const allObjectsContainRequiredFields = this.transformedData.every((object: any) => {
      return object.aadhar_s3_path;
    });

    if (!allObjectsContainRequiredFields) {
      return this.notifier.alert('Info', '', 'Please fill all the mandatory fields', 'info', 5000);
    }

    this.transformedData.forEach(async (object: any, index: number) => {
      console.log("index", index);
      await this.file_upload(object);
    });

  }

  filteredData: any = [];
  queryResult(event: any) {
    let queryString: string = (event.target.value || '').toLowerCase();
    if (!queryString) {
      this.filteredData = this.result;
      return;
    }

    this.filteredData = this.result.filter((item: any) => {
      return this.headers.some((h: any) => {
        let value: string;
        if (h.id.includes('vehicles_details')) {
          const key = h.id.split('.')[1];
          value = item.vehicles_details ? item.vehicles_details[key] : '';
        } else {
          value = item[h.id] || '';
        }
        return value.toLowerCase().includes(queryString);
      });
    });
  }

  expandedButton: boolean = false;

  toggleOptionsExport() {
    console.log('toggling..');
    this.expandedButton = !this.expandedButton;
    console.log('this.expandedButton', this.expandedButton);
  }

  toggleOptionsExportFalse() {
    // console.log('toggling..');
    this.expandedButton = false;
    // console.log('this.expandedButton', this.expandedButton);
  }

  expandedButtonForExport: boolean = false;

  toggleOptionsForExport() {
    this.expandedButtonForExport = !this.expandedButtonForExport;
  }

  toggleOptionsForExportFalse() {
    this.expandedButtonForExport = false;
  }

  openModalForConfirm(modalId: string) {
    const modalElement = document.getElementById(modalId);
    if (modalElement) {
      const modal = new bootstrap.Modal(modalElement);
      modal.show();
    }
  }


  closeEditMode() {
    this.myForm.enable();
    this.myForm.reset();
    this.isEditMode = false;
    this.readOnlyVar = false;
  }

  deleteDocument(filename: string, temRefVar?: any) {
    // this.fileNames[0] = undefined;
    // this.updated[filename] = {}
    console.log("filename", filename);

    this.updated[filename] = false;
    temRefVar.value = '';
    console.log('this.fileNames', this.fileNames);

    // this.delete[doc_name] = true;

  }

  deleteDocumentInUpdate(filename: string) {
    // this.fileNames[0] = undefined;
    if (!this.readOnlyVar) {
      for (let i = 0; i < this.fileNames.length; i++) {
        if (this.fileNames[i] && this.fileNames[i]!.includes(filename)) {
          // Set the array element to undefined if filename matches
          this.fileNames[i] = undefined;
          break;
        }
      }
      console.log("filename", filename);

      this.updated[filename] = false;

      console.log('this.updated', this.updated);


      console.log('this.fileNames', this.fileNames);
    }
    // this.delete[doc_name] = true;

  }


  deleteDocumentInUpdateForBulkUpload(fileName: string) {
    for (let i = 0; i < this.fileNames.length; i++) {
      if (this.fileNames[i].file_name === fileName) {
        this.fileNames[i] = ''; // Setting the object to an empty string
        break;
      }
    }
    for (let i = 0; i < this.fileInfoArray.length; i++) {
      if (this.fileInfoArray[i].file_name === fileName) {
        this.fileInfoArray[i] = ''; // Setting the object to an empty string
        break;
      }
    }

    console.log("filename", fileName);

    this.updated[fileName] = false;

    console.log('this.fileNames', this.fileNames);
    console.log('this.fileInfoArray', this.fileInfoArray);

  }

  filesArray: any[] = []
  async file_upload(object?: any) {
    if (this.bulkFileUpload && !this.isEditMode) {
      console.log("object", object)
      this.filesArray = []
      if (object['aadhar_s3_path'] !== '') {
        this.filesArray.push(object['aadhar_s3_path']);
      }
      if (object['background_certificate_s3_path'] !== '') {
        this.filesArray.push(object['background_certificate_s3_path']);
      }
      if (object.bank_account.length > 0) {
        if (object.bank_account[0]?.bank_passbook_s3_path !== '' || null || undefined) {
          this.filesArray.push(object?.bank_account[0]?.bank_passbook_s3_path);
        }
      }
      if (object['pan_s3_path'] !== '') {
        this.filesArray.push(object['pan_s3_path']);
      }
      if (object['med_fit_cert_s3_path'] !== '') {
        this.filesArray.push(object['med_fit_cert_s3_path']);
      }

      console.log("this.filesArray", this.filesArray)
      console.log('object', object);
      console.log('first name', object['first_name']);


      let data: any = {
        action: 'file_upload',
        first_name: object['first_name'],
        last_name: object['last_name'],
        files: this.filesArray.map((file: any) => {
          return { file_name: file.file_name, ext: file.ext };
        }),
      };
      console.log(data)
      let header = {
        Authorization: localStorage.getItem('t'),
        // 'X-Api-Key': localStorage.getItem('clientid'),
      };

      let apiURL = `${this.config.apiURL}/admin/drivers`;

      let result = await this.apiService.postDataPromis(apiURL, data, header);
      console.log(result);

      if (result.s == '1' || result.status == '1') {

        this.uploadFiles(result.data, this.filesArray, object);
      } else {
        this.notifier.alert('Info', '', result.error, 'info', 5000);
      }
    }
    else if (this.myForm.valid && !this.bulkFileUpload) {
      let data: any = {
        action: 'file_upload',
        first_name: this.myForm.get('first_name').value,
        last_name: this.myForm.get('last_name').value,
        files: this.fileInfoArray.map((file: any) => {
          return { file_name: file.file_name, ext: file.ext };
        }),
      };
      let header = {
        Authorization: localStorage.getItem('t'),
        // 'X-Api-Key': localStorage.getItem('clientid'),
      };

      let apiURL = `${this.config.apiURL}/admin/drivers`;

      let result = await this.apiService.postDataPromis(apiURL, data, header);

      console.log(result);
      if (result.s == '1' || result.status == '1') {

        this.uploadFiles(result.data, this.fileInfoArray);
      } else {
        this.notifier.alert('Info', '', result.error, 'info', 5000);
      }
    } else {
      this.notifier.alert('Info', '', 'Please fill all the mandatory fields', 'info', 5000);
    }
  }

  arrayOfFiles: any[] = []

  async uploadFiles(data: any, files: any, object?: any) {
    this.notifier.loading(true);
    console.log("inside the upload file");
    let promises: Promise<any>[] = [];
    console.log("data", data)
    // console.log("files", files)

    Object.keys(data).forEach((file_name: string, index: number) => {
      // const key = Object.keys(data).find(key => files[index].file_name.includes(key)); 
      // if (key) {
      //   console.log("object as filename : ", key);
      // }
      // console.log("files", files)
      console.log("file name", file_name)
      this.arrayOfFiles.push(file_name)
      files[index]['formcontro_name'] = file_name;
      files[index]['status'] = 'pending';
      console.log("files", files[index])
      console.log("files", files)
      // this.arrayOfFiles.push(files)
      // console.log(data[file_name]['presigned_url']);
      promises.push(
        this.uploadFileAPI(files[index], data[file_name]['presigned_url'])
      );
    });

    Promise.all(promises).then((values: any) => {
      // Save Company
      console.log("values", values)
      console.log("this.isEditMode", this.isEditMode)
      if (this.isEditMode) {
        this.updateDriver(files);
      } else if (!this.isEditMode && this.bulkFileUpload) {
        promises.push(this.renameFilesWithS3Path(object, files))
        console.log('after assigning filepaths to object', object);

        this.uploadDataPostFileUpload(object)
        // console.log("files Uploaded")
        // console.log("this.arrayOfFiles", this.arrayOfFiles)
      }
      else {
        this.addDriver(files);
      }
    });
    this.notifier.loading(false);
  }

  uploadFileAPI(file: any, url: string): Promise<any> {
    console.log("inside the upload file API");

    return new Promise(async (resolve: any, reject: any) => {
      this.uploadFile
        .uploadFile(url, file['file'])
        .subscribe(async (event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              break;
            case HttpEventType.ResponseHeader:
              break;
            case HttpEventType.UploadProgress:
              file['status'] = 'uploading';
              let progress = Math.round(
                (event.loaded / (event.total || file['file']['size'])) * 100
              );
              file.loaded = event.loaded;
              file.percentage = progress;
              break;
            case HttpEventType.Response:
              file['status'] = 'completed';
              resolve(true);
          }
        });
    });
  }

  mapFileNameToControlName(fileName: string): string | null {
    if (fileName.includes('tds_cert')) {
      return 'statutoryCompliancesUpload';
    } else if (fileName.includes('comp_labr_lic')) {
      return 'labourLicenseUpload';
    } else if (fileName.includes('prof_tax_cert')) {
      return 'ptCertificateUpload';
    } else if (fileName.includes('udyam_cert')) {
      return 'udyamCertificateUpload';
    } else if (fileName.includes('comp_incorp_cert')) {
      return 'incorporationCertificateUpload';
    } else if (fileName.includes('gst_cert')) {
      return 'gstCertificateUpload';
    } else if (fileName.includes('pan_card')) {
      return 'panCardUpload';
    } else if (fileName.includes('mtw_card')) {
      return 'mtwCertificateUpload';
    } else {
      return null;
    }
  }

  exportToExcel(
    data: any[],
    filename: string,
    excludedColumns: string[] = []
  ): void {
    const flattenedData: any[] = [];
    const customHeaders: { [key: string]: string } = {
      first_name: 'First Name',
      last_name: 'Last Name',
      date_of_birth: 'Date of Birth',
      age: 'Age',
      gender: 'Gender',
      marital_status: 'Marital Status',
      join_date: 'Joining Date',
      primary_ph_no: 'Primary Phone Number',
      secondary_ph_no: 'Secondary Phone Number',
      cur_addr_state: 'Current Address State',
      cur_addr_district: 'Current Address District',
      cur_addr_city: 'Current Address City',
      cur_addr_street: 'Current Address Street',
      cur_addr_house_no: 'Current Address House Number',
      cur_addr_pin_code: 'Current Address Pin Code',
      per_addr_state: 'Permanent Address State',
      per_addr_district: 'Permanent Address District',
      per_addr_city: 'Permanent Address City',
      per_addr_street: 'Permanent Address Street',
      per_addr_house_no: 'Permanent Address House Number',
      per_addr_pin_code: 'Permanent Address Pin Code',
      client_name: 'Client Name',
      languages_known: 'Languages Known',
      total_experience: 'Total Experience',
      exp_sector: 'Experience Sector',
      aadhar_number: 'Aadhar Number',
      pan_number: 'PAN Number',
      background_certificate_number: 'Background Certificate Number',
      med_fit_cert_issuing_date: 'Medical Fitness Certificate Issuing Date',
      med_fit_cert_expiry_date: 'Medical Fitness Certificate Expiry Date',
      esi_number: 'ESI Number',
      pf_number: 'PF Number',
      acc_no: 'Account Number',
      ifsc_code: 'IFSC Code',
      bank_name: 'Bank Name',
      bank_address: 'Bank Address',
      name: 'Name As Per Bank Account',
      bank_account: 'Bank account'
      // Add custom headers for other fields as needed
    };

    // Flatten the nested objects and arrays
    data.forEach((item) => {
      const flattenedItem: any = {};

      Object.keys(item).forEach((key) => {
        if (!excludedColumns.includes(key)) {
          // Exclude the column if it's specified in excludedColumns

          if (typeof item[key] === 'object' && item[key] !== null && !Array.isArray(item[key])) {
            Object.keys(item[key]).forEach((subKey) => {
              if (item[key][subKey] !== null && !excludedColumns.includes(subKey)) {
                flattenedItem[customHeaders[subKey] || subKey] = item[key][subKey];
              }
            });
          } else if (Array.isArray(item[key])) {
            item[key].forEach((subItem: any, index: number) => {
              if (subItem && typeof subItem === 'object') {
                Object.keys(subItem).forEach((subKey) => {
                  if (subItem[subKey] !== null && !excludedColumns.includes(subKey)) {
                    flattenedItem[
                      `${customHeaders[key] || key} ${index + 1} - ${customHeaders[subKey] || subKey}`
                    ] = subItem[subKey];
                  }
                });
              }
            });
          } else if (item[key] !== null) {
            flattenedItem[customHeaders[key] || key] = item[key];
          }
        }
      });

      flattenedData.push(flattenedItem);
    });

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(flattenedData);

    // Create workbook and add the worksheet
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Attender Data');

    // Save the workbook to a file
    XLSX.writeFile(wb, `${filename}.xlsx`);
  }



  fetchDataAndExport(): void {
    // Simulate fetching data from the backend
    const fetchedData = this.result; // Your fetched data array here
    let excludedColumns = [
      'id',
      'aadhar_s3_path',
      'pan_s3_path',
      'background_certificate_s3_path',
      'med_fit_cert_s3_path',
      'bank_passbook_s3_path',
      'type_of_employee',
      'driving_license_number',
      'driving_license_s3_path',
      'type_of_license'
    ];
    // Call exportToExcel function with fetched data and desired filename
    this.exportToExcel(fetchedData, 'Attender Details', excludedColumns);
  }

  calculateAge() {
    const dob = this.myForm.get('dob').value;
    if (dob) {
      const today = new Date();
      const birthDate = new Date(dob);
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      this.myForm.get('age').setValue(age);
    }
  }


  convertEpochToAge(epochTimestamp: number): number {
    // Get the current date
    console.log('epochTimestamp', epochTimestamp);

    const currentDate = new Date();

    // Convert the epoch timestamp to a date
    const birthDate = fromUnixTime(epochTimestamp);

    // Calculate the age 
    const age = differenceInYears(currentDate, birthDate);

    console.log('age', age);

    return age;
  };

  setAddDriver() {
    this.addcom = true;
    this.fileNames = [];
    this.updated = {};
    this.updateForm = true;
    this.bankAccountDetailsArray.clear();
    this.myForm.enable();
  }

  async addDriver(files?: any) {
    const selectedOptions = this.myForm.value.lang_known
      .map((checked: boolean, index: number) =>
        checked ? ['Hindi', 'English', 'Telugu', 'Other'][index] : null
      )
      .filter((value: string | null) => value !== null);
    let concatenatedOptions: string = selectedOptions.join(', ');
    console.log(concatenatedOptions);


    if (this.myForm.valid) {
      let formValues = this.myForm.value;
      let payload_files: any = {};

      files.forEach((file: any) => {
        // Iterate over each field in the form
        payload_files[file.fieldName] = file.formcontro_name;
      });
      console.log('Inside the api call', formValues);


      let data: any = [{
        "action": "add_drivers_data",
        "first_name": formValues.first_name,
        "last_name": formValues.last_name,
        "date_of_birth": formValues.dob,
        "age": formValues.age,
        "gender": formValues.gender,
        "marital_status": formValues.marital_status,
        "join_date": formValues.joining_date,
        "primary_ph_no": formValues.prim_cont_num,
        "secondary_ph_no": formValues.sec_cont_num,

        "cur_addr_state": formValues.cur_addr_state,
        "cur_addr_district": formValues.cur_addr_district,
        "cur_addr_city": formValues.cur_addr_city,
        "cur_addr_street": formValues.cur_addr_street,
        "cur_addr_house_no": formValues.cur_addr_house_no,
        "cur_addr_pin_code": formValues.cur_addr_pin_code,

        "per_addr_state": formValues.per_addr_state,
        "per_addr_district": formValues.per_addr_district,
        'per_addr_city': formValues.per_addr_city,
        "per_addr_street": formValues.per_addr_street,
        "per_addr_house_no": formValues.per_addr_house_no,
        'per_addr_pin_code': formValues.per_addr_pin_code,

        "client_name": formValues.clientName,
        "languages_known": concatenatedOptions,

        "total_experience": formValues.tot_exp,
        "exp_sector": formValues.exp_sec,
        "driving_license_number": "",
        "license_expiry_date": "",
        "driving_license_s3_path": "None",
        "type_of_license": "",
        "aadhar_number": formValues.adhar_num,
        "aadhar_s3_path": payload_files["aadharUpload"],
        "pan_number": formValues.pan_num,
        "pan_s3_path": payload_files["panUpload"] ? payload_files["panUpload"] : 'None',

        "background_certificate_number": formValues.back_cert_num,
        "background_certificate_s3_path": payload_files["bgCertUpload"] ? payload_files["bgCertUpload"] : 'None',

        "med_fit_cert_issuing_date": formValues.med_date_of_issue,
        "med_fit_cert_expiry_date": formValues.med_date_of_expiry,
        "med_fit_cert_s3_path": payload_files["fitnessCertUpload"] ? payload_files["fitnessCertUpload"] : 'None',

        "esi_number": formValues.esi_num,
        "pf_number": formValues.pf_num,
        "type_of_employee": 'attender',
        "compliance": formValues.compliance,
        "bank_account": formValues.bank_account_details.map((bank: any) => {
          return {
            acc_no: bank.acc_no,
            ifsc_code: bank.ifsc_code,
            bank_name: bank.bank_name,
            bank_address: bank.bank_address,
            name: bank.name,
            bank_passbook_s3_path: payload_files["passbookUpload"] ? payload_files["passbookUpload"] : 'None'
          };
        }),
      }]

      let header = {
        Authorization: localStorage.getItem('t'),
        // 'X-Api-Key': localStorage.getItem('clientid'),
      };

      //https://api.swayam.cloud/v3/admin/users/fetchaccountgroupsv2
      let apiURL = `${this.config.apiURL}/admin/drivers`;

      let result = await this.apiService.postDataPromis(apiURL, data, header);

      if (result.s == '1' || result.status == '1') {
        this.submitted = false;
        this.isEditMode = false;
        this.addcom = false;
        this.clearForm();
        this.fileInfoArray = [];
        this.fetchDrivers();
        this.fileNames = []
        this.updated = {}
        this.showPopup(
          'Attender Added',
          'The Attender has been successfully added.'
        );

      } else {
        this.notifier.alert('Info', '', result.error, 'info', 5000);
      }

    }
    else {
      this.notifier.alert('Info', '', 'Please fill all the mandatory fields', 'info', 5000);
    }
  }

  backend_pagination: any = { page: 1, per_page: 10 };

  async fetchDrivers() {
    this.notifier.loading(true);

    let allData: any[] = [];
    let hasMoreData = true;

    this.backend_pagination.page = 1; // Ensure pagination starts from the first page

    while (hasMoreData) {
      let data = {
        action: 'fetch_attenders_data',
        page: this.backend_pagination.page,
        per_page: this.backend_pagination.per_page,
      };

      let header = {
        Authorization: localStorage.getItem('t'),
      };

      let apiURL = `${this.config.apiURL}/admin/drivers`;

      try {
        let result = await this.apiService.postDataPromis(apiURL, data, header);

        if (result.s == '1' || result.status == '1') {
          let fetchedData = result.data;

          allData = allData.concat(fetchedData);
          this.backend_pagination.page++;

          hasMoreData = fetchedData.length === this.backend_pagination.per_page;
          this.filteredData = allData;

        } else {
          this.notifier.alert('Info', '', result.error, 'info', 5000);
          hasMoreData = false;
        }
      } catch (error) {
        this.notifier.alert('Error', '', 'Failed to fetch data', 'error', 5000);
        hasMoreData = false;
      }
    }

    this.backend_pagination.page = 1;
    this.result = allData;
    this.filteredData = allData;
    console.log('fetch in drivers', allData);

    this.notifier.loading(false);
  }

  async openModal(company: any) {
    this.selectedCompany = company;
    // this.selectedCompany.languages
    this.notifier.loading(true)
    this.sendUrls = []
    this.sendUrls.push(this.selectedCompany.aadhar_s3_path)
    this.sendUrls.push(this.selectedCompany.pan_s3_path)
    this.sendUrls.push(this.selectedCompany.bank_account[0]?.bank_passbook_s3_path ? this.selectedCompany.bank_account[0]?.bank_passbook_s3_path : 'None')
    this.sendUrls.push(this.selectedCompany.background_certificate_s3_path)
    this.sendUrls.push(this.selectedCompany.med_fit_cert_s3_path)
    console.log("this.sendUrls", this.sendUrls)
    // console.log("URL:", this.fetchUrls);
    await this.fetch(this.sendUrls);
    this.showModal = true;
    this.expandedIndex = null;
    this.notifier.loading(false)
  }

  hideModal() {
    this.showModal = false;
  }
  toggleEditMode(): void {
    this.isEditMode = !this.isEditMode;
  }

  hoveredAttender: any = null;
  tooltipPosition = { top: 0, left: 0 };

  showDetails(attender: any, event: MouseEvent) {
    this.hoveredAttender = attender;
    console.log('####', attender);


    // Calculate the position for the tooltip to appear on the left side of the info icon
    const element = (event.target as HTMLElement).getBoundingClientRect();
    this.tooltipPosition = {
      top: element.top + window.scrollY - 240,  // Correct positioning relative to the viewport
      left: element.left - 310            // Adjusted to leave space for the arrow and position the tooltip to the left
    };
  }

  hideDetails() {
    this.hoveredAttender = null;
  }

  exportUnverifiedAttenders() {
    const missingFieldsData = this.result.reduce((accumulator: any, driver: any) => {
      let missingFields = [];
      let missingFiles = [];

      if (!driver.background_certificate_number) {
        missingFields.push('Background Certificate Number');
      }
      if (!driver.background_certificate_s3_path || driver.background_certificate_s3_path === 'None') {
        missingFiles.push('Background Certificate');
      }
      if (!driver.med_fit_cert_issuing_date) {
        missingFields.push('Medical Fitness Certificate Issue Date');
      }
      if (!driver.med_fit_cert_expiry_date) {
        missingFields.push('Medical Fitness Certificate Expiry Date');
      }
      if (!driver.med_fit_cert_s3_path || driver.med_fit_cert_s3_path === 'None') {
        missingFiles.push('Medical Fitness Certificate');
      }
      if (!driver.esi_number) {
        missingFields.push('ESI Number');
      }
      if (!driver.pf_number) {
        missingFields.push('PF Number');
      }

      if (missingFields.length > 0 || missingFiles.length > 0) {
        accumulator.push({
          'First Name': driver.first_name,
          'Last Name': driver.last_name,
          'Missing Fields': missingFields.join(', '),
          'Missing Files': missingFiles.join(', ')
        });
      }

      return accumulator;
    }, []);

    this.exportToExcelUnverifiedAttenders(missingFieldsData, 'Unverified Attenders Report');
  }

  exportToExcelUnverifiedAttenders(data: any[], fileName: string) {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Report');

    XLSX.writeFile(wb, `${fileName}.xlsx`);
  }

  expandedIndex: number | null = null;

  toggleOptions(index: number) {
    this.expandedIndex = this.expandedIndex === index ? null : index;
    console.log(`${this.expandedIndex}    ${index}`);

  }

  readOnlyVar: boolean = false;

  async readOnly(selectedCompany: any) {
    this.notifier.loading(true);
    this.readOnlyVar = true;

    console.log("this.myForm", this.myForm);
    console.log('inside edit company');

    this.updateForm = true;
    this.fileNames = []
    this.isEditMode = true;
    this.expandedIndex = null;

    console.log("this.bulkFileUpload", this.bulkFileUpload)

    await this.populateForm(selectedCompany);

    this.myForm.disable();
    console.log("this.progress", this.progress);
    console.log("this.fileNames", this.fileNames);
    this.notifier.loading(false);
  }


  async editCompany(selectedCompany: any) {
    this.notifier.loading(true);
    this.myForm.enable()
    this.readOnlyVar = false;
    console.log('inside edit company');
    this.updateForm = true;
    this.fileNames = []
    this.isEditMode = true;
    this.expandedIndex = null;
    console.log("this.bulkFileUpload", this.bulkFileUpload)
    await this.populateForm(selectedCompany); // Pass selected company object to populateForm
    // this.addcom = true; // Set flag to indicate edit mode
    console.log("this.progress", this.progress);
    console.log("this.fileNames", this.fileNames);
    this.notifier.loading(false);
  }
}


